import { useObserver } from 'mobx-react';
import SecurityCheck from './SecurityCheck/SecurityCheck';
import { Route, Routes } from "react-router-dom";
import AwsCredit from './AwsCredit/AwsCredit';
import InternetAssociate from './InternetAssociate/InternetAssociate';

function Guest() {
    return useObserver(() => (
        <Routes>
            <Route path="/security" element={<SecurityCheck/>}/>
            <Route path="/awsCredit" element={<AwsCredit/>}/>
            <Route path="/internetAssociate" element={<InternetAssociate/>}/>
        </Routes>
    ));
}
export default Guest;
