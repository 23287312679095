import { useObserver } from 'mobx-react';
import HeaderSidebar from '@commonComponents/HeaderSidebar/HeaderSidebar';
import Content from '@commonComponents/Content/Content';
import Box1040 from '@commonComponents/Box1040/Box1040';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import PageTitle from '@commonComponents/PageTitle/PageTitle';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import CopyBtn from '@standby/common-ui/components/atoms/Button/CopyBtn/CopyBtn';
import st from './Information.module.scss';
import vm from './InformationVm';
import clsx from 'clsx';

function Information() {

    return useObserver(() => (
        <>  
            <HeaderSidebar companyName={vm.company?.companyName || ""} businessRegistrationActiveYn={true}/>
            <Content>
                <Box1040>
                    <PageTitle><span className={st.pageTitle}>사업자등록</span></PageTitle>    
                    {vm.documentType && 
                        <div className={st.companyInfoBox}>
                            <div className={st.companyInfo}>
                                <div className={st.companyTitle}>{vm.companyName}</div>
                                <div className={st.companyAddress}>{vm.companyName}의 요청으로 {vm.userName}님에게 제공되는 정보입니다. (열람기간:{vm.expireDate}까지)</div>
                            </div>
                        </div>
                    }
                    <div className={vm.documentType === 'BR__BANKBOOK' ? st.both : st.either}> 
                        {(vm.documentType === 'BR' || vm.documentType === 'BR__BANKBOOK') && 
                            <ContentBox title='사업자등록증' className={st.contentBox} titleStyle={{top:"15px", paddingLeft:"20px", fontSize:"18px"}}>
                                <div className={st.content}>
                                    <div className={st.businessBox}>
                                        <div className={st.downloadBox}>
                                            <div className={st.imgDownload}>
                                                <img src={`/images/informationBoard_businessRegistration${vm.documentType === 'BR__BANKBOOK' ? '_small' : ''}.svg`} alt='사업자등록증'/>
                                                <a href={vm.businessRegistration?.fileDownloadUrl} download>
                                                    <button type="button" className={st.downloadBtn}><span className={st.text}>다운로드</span></button>
                                                </a>
                                            </div>
                                            <div className={st.updatedAt}>{vm.businessRegistration?.issuedAt} 발급</div>
                                        </div>
                                        <div className={st.informationBox}>
                                            <div className={st.title}>기본정보</div>
                                            <div className={st.infoBox}>
                                                <div className={clsx(st.infoTitle, st.businessRegistrationNumber)}>사업자등록번호</div>
                                                <div className={st.info}>
                                                    {vm.businessRegistration?.businessRegistrationNumber}
                                                    <CopyBtn copyText={vm.businessRegistration?.businessRegistrationNumber} className={st.copyBtn}>복사</CopyBtn>
                                                </div>
                                            </div>
                                            <div className={st.infoBox}>
                                                <div className={clsx(st.infoTitle, st.companyName)}>상호</div>
                                                <div className={st.info}>
                                                    {vm.companyName}
                                                    <CopyBtn copyText={vm.companyName} className={st.copyBtn}>복사</CopyBtn>
                                                </div>
                                            </div>
                                            <div className={st.infoBox}>
                                                <div className={clsx(st.infoTitle, st.representativeName)}>성명 (대표)</div>
                                                <div className={st.info}>
                                                    {vm.company?.representativeName}
                                                    <CopyBtn copyText={vm.company?.representativeName} className={st.copyBtn}>복사</CopyBtn>
                                                </div>
                                            </div>
                                            <div className={st.infoBox}>
                                                <div className={clsx(st.infoTitle, st.email)}>세금계산서 발급 이메일</div>
                                                <div className={st.info}>
                                                    <div><CopyBtn copyText={vm.businessRegistration?.taxInvoiceIssuanceEmail} className={st.copyBtn}>이메일 전체 복사</CopyBtn></div>
                                                    <div className={st.copyBox}>
                                                        <CopyBtn copyText={vm.separateEmail.pre} icon={false}>{vm.separateEmail.pre}</CopyBtn> @ <CopyBtn copyText={vm.separateEmail.sur} icon={false}>{vm.separateEmail.sur}</CopyBtn>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                            </ContentBox>
                        }
                        {(vm.documentType === 'BANKBOOK' || vm.documentType === 'BR__BANKBOOK') && 
                            <ContentBox title='통장사본' className={st.contentBox} titleStyle={{top:"15px", paddingLeft:"20px", fontSize:"18px"}}>
                                <div className={st.content}>
                                    <div className={st.bankBookBox}>
                                        <div className={st.downloadBox}>
                                            <div className={st.imgDownload}>
                                                <img src={`/images/informationBoard_bankBook${vm.documentType === 'BR__BANKBOOK' ? '_small' : ''}.svg`} alt='통장사본'/>
                                                <a href={vm.bankbook?.fileDownloadUrl} download>
                                                    <button type="button" className={st.downloadBtn}><span className={st.text}>다운로드</span></button>
                                                </a>
                                            </div>
                                        </div>
                                        <div className={st.informationBox}>
                                            <div className={st.bankBookTitle}>
                                                기본정보
                                                <CopyBtn copyText={vm.accountInfoCopyText(vm.bankbook?.bankName || null, vm.bankbook?.accountNumber || "")} className={st.copyBtn}>계좌 정보 복사</CopyBtn>
                                            </div>
                                            <div className={st.infoBox}>
                                                <div className={clsx(st.infoTitle, st.bank)}>은행</div>
                                                <div className={st.info}>
                                                    {vm.bankbook?.bankName}
                                                    <CopyBtn copyText={vm.bankbook?.bankName || ""} className={st.copyBtn}>복사</CopyBtn>
                                                </div>
                                            </div>
                                            <div className={st.infoBox}>
                                                <div className={clsx(st.infoTitle, st.account)}>계좌번호</div>
                                                <div className={st.info}>
                                                    {vm.bankbook?.accountNumber}
                                                    <CopyBtn copyText={vm.bankbook?.accountNumber} className={st.copyBtn}>복사</CopyBtn>
                                                </div>
                                            </div>
                                            <div className={st.infoBox}>
                                                <div className={clsx(st.infoTitle, st.depositor)}>예금주</div>
                                                <div className={st.info}>
                                                    {vm.companyName}
                                                    <CopyBtn copyText={vm.companyName} className={st.copyBtn}>복사</CopyBtn>
                                                </div>
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                            </ContentBox>
                        }
                    </div>
                    <div className={st.oneStop}>
                        <img src='/images/informationBoard_oneStopStandby.svg?1' alt='원스톱 법인관리 시스템, 스탠바이 헬프미'/>
                        <SquareBtn className={st.joinBtn} arrow onClick={() => vm.joinStandby()}>스탠바이 헬프미 가입하기</SquareBtn>
                    </div>
                </Box1040>
            </Content>
        </>
    ));
}
export default Information;