import { useObserver } from 'mobx-react';
import st from '../AgendaDashboard.module.scss';
import Meetings from '@pages/Corp/Agenda/Step/FinalCheck/Final/Meetings/Meetings';
import Cst from './Schedule.module.scss';
import clsx from 'clsx';
import vm from './ScheduleVm';

function Schedule() {
    return useObserver(() => (
        <div className={st.item}>
            <div className={st.title}>일정 & 안건</div>
            <div className={clsx(st.content, Cst.content)}>
                <div className={Cst.meetingsWrap}>
                    {(vm.transmissionStatus.shareholderMeeting.type.includes('미진행') === false || vm.transmissionStatus.newStockAcquire.type.includes('미진행') === false) && 
                        <div className={Cst.notice}>
                            {vm.transmissionStatus.shareholderMeeting.type.includes('미진행') === false && 
                                <div className={Cst.item}>
                                    <span className={Cst.title}>- {vm.transmissionStatus.shareholderMeeting.title}</span>
                                    {vm.transmissionStatus.shareholderMeeting.type !== "소집통지_우편" && 
                                    <>
                                        <span className={Cst.count}>
                                            <span className={clsx(Cst.complete, vm.transmissionStatus.shareholderMeeting.count === vm.transmissionStatus.totalCount ? Cst.complete : Cst.progress)}>{vm.transmissionStatus.shareholderMeeting.count} </span>/{vm.transmissionStatus.totalCount}
                                        </span>
                                        <span> {vm.transmissionStatus.shareholderMeeting.count === vm.transmissionStatus.totalCount ? "완료" : "진행중"}</span>
                                    </>
                                    }
                                </div>
                            }
                            {vm.transmissionStatus.newStockAcquire.type.includes('미진행') === false && 
                                <div className={Cst.item}>
                                    <span className={Cst.title}>- {vm.transmissionStatus.newStockAcquire.title}</span>
                                    {vm.transmissionStatus.shareholderMeeting.type !== "신주발행공고" &&
                                    <>
                                        <span className={Cst.count}>
                                            <span className={clsx(Cst.complete, vm.transmissionStatus.newStockAcquire.count === vm.transmissionStatus.totalCount ? Cst.complete : Cst.progress)}>{vm.transmissionStatus.newStockAcquire.count} </span>/{vm.transmissionStatus.totalCount}
                                        </span>
                                        <span> {vm.transmissionStatus.newStockAcquire.count === vm.transmissionStatus.totalCount ? "완료" : "진행중"}</span>
                                    </>
                                    }
                                </div>
                            }
                        </div>
                    }
                    <Meetings opener="agendaDashboard"/>
                </div>
            </div>
        </div>
    ));
}
export default Schedule;