import { LoadFrameRs, Id } from './corpService.interface';

import { get, _delete } from '@common/module/httpRequest';
import { runInAction } from 'mobx'; 
import corpModel from '@model/Corp/corpModel';
import adminModel from '@model/Corp/AdminSetting/adminModel';

class corpService {
    async loadFrame(id : Id) {
        const res = await get<LoadFrameRs>(`/companyRegister/${id}/frame`);
        runInAction(() => {
            corpModel.company.companyName = res.data?.companyName || "";
            corpModel.company.companyAddress = res.data?.companyAddress || "";
            corpModel.company.corpType = res.data?.corpType || "";
            corpModel.company.prefixedYn = res.data?.prefixedYn ?? null;
            corpModel.companyRegisterStatus = res.data?.companyRegisterStatus || null;
            corpModel.stockholderListFileRegisterStatus = res.data?.stockholderListFileRegisterStatus || false;
            corpModel.companies = res.data?.companies.map((company) => {
                return {
                    companyName : company?.companyName || "",
                    companyId : String(company?.companyId) || "",
                    companyAddress : company?.companyAddress || "",
                    isDemoYn : company?.isDemoYn || false,
                    status: company?.status || null,
                    liquidationYn: company?.liquidationYn || false,
                }
            })
            corpModel.menu.businessRegistration.useYn = res.data?.menu?.businessRegistration.useYn || false;
            corpModel.menu.businessRegistration.tagName = res.data?.menu?.businessRegistration.tagName || "";
            corpModel.menu.articlesOfAssociationTagName = res.data?.menu?.articlesOfAssociationTagName || "";
            corpModel.menu.bookkeepingContractTagName = res.data?.menu?.bookkeepingContractTagName || "";
            corpModel.menu.copyFileTagName = res.data?.menu?.copyFileTagName || "";
            corpModel.menu.stockholderTagName = res.data?.menu?.stockholderTagName || "";
            corpModel.menu.corporateTaxTagName = res.data?.menu?.corporateTaxTagName || "";
            corpModel.menu.valueAddedTaxTagName = res.data?.menu?.valueAddedTaxTagName || "";
            corpModel.menu.internetAssociateTagName = res.data?.menu?.internetAssociateTagName || ""; 
            corpModel.menu.dashboardTagName = res.data?.menu?.dashboardTagName || ""; 
            corpModel.menu.agendaTagName = res.data?.menu?.agendaTagName || ""; 
            corpModel.alliancePopupShowYn = res.data?.alliancePopupShowYn || false;
            adminModel.setAuthority(res.data.authority);
        })
    }
    alliancePopupClose() {
        if(corpModel?.alliancePopupShowYn) {
            corpModel.alliancePopupShowYn = false;
            _delete(`/company/${corpModel.id}/alliance/popup`);
        }
    }

}
export default new corpService();