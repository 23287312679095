import { makeObservable, observable } from 'mobx';
import type {agendaModelInterface, Guide, ProgressAgenda, Agenda, CompletedAgenda, Highlight, AgendaCounts} from './agendaModel.interface';

class agendaModel implements agendaModelInterface {
    constructor() {
        makeObservable(this, {
            guides : observable,
            progressAgendas : observable,
            hasBoardOfDirectors : observable,
            agenda : observable,
            registrationRequiredYn : observable,
            capital : observable,
            totalNumOf_감사 : observable,
            totalNumOf_이사 : observable,
            highlights : observable,
            agendaCounts: observable,
        })
    }
    guides : Guide[] = [];
    progressAgendas : ProgressAgenda[] = [];
    completedAgendas : CompletedAgenda[] = [];
    hasBoardOfDirectors : boolean = false;
    agenda : Agenda = null;
    registrationRequiredYn : boolean = false;
    capital : number = 0;
    totalNumOf_감사 : number = 0;
    totalNumOf_이사 : number = 0;
    highlights : Highlight[] = [];
    agendaCounts: AgendaCounts[] = [];
}

export default new agendaModel();