import BoardOfDirectorsService from "@src/service/Corp/Agenda/Step/FinalCheck/BeforeFinal/BoardOfDirectors/BoardOfDirectorsService";
import BoardOfDirectorsModel from "@src/model/Corp/Agenda/Step/FinalCheck/BeforeFinal/BoardOfDirectors/BoardOfDirectorsModel";
import type {AgendaType} from "@src/model/Corp/Agenda/Step/FinalCheck/BeforeFinal/BoardOfDirectors/BoardOfDirectorsModel.interface";
import { runInAction, observable } from "mobx";
import formDataToJson from '@common/module/submit';
import { _alert } from "@model/dialogModel";

class StepVm {
    state : {
        tryVaild : boolean
    }
    constructor() {
        this.state = observable({
            tryVaild: false
        })
    }
    async submit(e : React.FormEvent<HTMLFormElement>, companyId : string, agendaProgressId : string) {
        e.preventDefault();
        let possible = true;
        let allCheck = true as boolean;
        for(let index = 0; index < this.step2.agendaList.length; index++) {
            if(this.attendanceYn(index) === false) {
                possible = false;
            }
        }
        if(this.state.tryVaild === false) {
            this.state.tryVaild = true;
        }
        
        this.step2.agendaList.forEach((agenda) => { //전부 체크하지 않은경우 저장실행안함
            if(agenda.executives.some((executive) => !executive.status)){
                allCheck = false;
            }
        })
        if(allCheck === false) return
        if(formDataToJson(e.target).error) {
            return
        } else {
            let param = formDataToJson(e.target).data as {
                minutesId : string,
                agendaList : {
                    type : AgendaType,
                    executives : {
                        id : string,
                        status : string
                    }[]
                }[]
            };
            if(possible === false) {
                return _alert('출석한 이사의 과반수(=50% 초과)가 찬성해야 해요.');
            }
            await BoardOfDirectorsService.saveStep2(param, companyId, agendaProgressId)
        }
    }
    load(companyId : string, agendaProgressId : string, minutesId : string) {
        BoardOfDirectorsService.step2Load(companyId, agendaProgressId, minutesId);
    }
    get step2() {
        return BoardOfDirectorsModel.step2; 
    }
    attendanceAll(status : string, index : number) {
        let isCheckAll = true;
        BoardOfDirectorsModel.step2.agendaList[index].executives.forEach((item) => {
            if(item.status !== status) {
                isCheckAll = false
            }
        })
        return isCheckAll;
    }
    setAttendancell(e : React.ChangeEvent<HTMLInputElement>, status : string, index : number) {
        const chekced = e.target.checked;
        runInAction(() => {
            this.step2.agendaList[index].executives.forEach((item) => {
                if(chekced) {
                    item.status = status
                }else{
                    item.status = ''
                }
            })
        })
    }
    status(e : React.ChangeEvent<HTMLInputElement>, index : number, index2 : number) {
        const chekced = e.target.checked;
        const value = e.target.value;
        runInAction(() => {
            if(this.state.tryVaild === false) {
                this.state.tryVaild = true;
            }
            if(chekced) {
                this.step2.agendaList[index].executives[index2].status = value
            }else{
                this.step2.agendaList[index].executives[index2].status = ''
            }
        })
    }
    attendanceYn(index : number) {
        let checkTotal = this.step2.agendaList[index].executives.filter(executive => executive.status === "찬성").length;
        let totalCount = this.step2.agendaList[index].executives.length;
        return totalCount/2 < checkTotal
    }
    allCheckAgenda() {
        this.step2.agendaList.forEach((agenda, index) => {
            if(index !== 0) {
                agenda.executives.forEach((executive, index2) => {
                    executive.status = this.step2.agendaList[0].executives[index2].status
                })
            }
        })
    }
    preStep() {
        BoardOfDirectorsModel.nowStep = '1';
    }
}
export default new StepVm();