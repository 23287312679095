import { useObserver } from 'mobx-react';
import { Route, withRouter, Link } from "react-router-dom";
import { useEffect } from 'react';
import st from './TermsModal.module.scss';

function TermsModal(props) {
    return useObserver(() => (
        <div className={st.termsContent}>
            {props.content}
        </div>
    ));
}
export default TermsModal;