import { useObserver } from 'mobx-react';
import React, { useEffect } from 'react';
import vm from './AdditionalAoAChangeVm';
import qst from '@pages/Corp/Agenda/Step/AgendaQuestion/commonQuestion.module.scss';
import st from './AdditionalAoAChange.module.scss';
import Table from '@standby/common-ui/components/atoms/Table/Table';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import { addCommas } from '@common/module/replaceNumber';
import type {SelectedAgenda, I등기신청을_위한_추가_정관변경} from '@model/Corp/Agenda/Step/agendaStepModel.interface';
import { _alert } from '@src/model/dialogModel';

interface AdditionalAoAChangeProps {
    agenda : SelectedAgenda<I등기신청을_위한_추가_정관변경>,
    agendaIndex : number
}

const AdditionalAoAChange = React.memo(function (props : AdditionalAoAChangeProps) {
    useEffect(() => {
        _alert('[정관변경]을 자동으로 넣어드렸어요.', '다른 안건 진행을 위해 정관변경이 필요해요. 회사법을 잘 몰라도 괜찮아요. 상황에 따라 필요한 업무를 시스템이 도와드려요.');
    }, [])
    return useObserver(() => (
        <div className={qst.questionForm}>
            <div className={qst.qusetion}>
                <div className={qst.questionTitle}>
                    정관변경 <span style={{fontWeight:"400"}}>(자동추가)</span>
                </div>
                <Table className={st.table}>
                    <colgroup>
                        <col width="160"/>
                        <col width="285"/>
                        <col />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>항목</th>
                            <th>기존</th>
                            <th>신규</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.agenda.agendaDetail.articlesOfAssociations.map((articlesOfAssociation, index) => (
                            <tr key={index}>
                                <td className={st.category}>
                                    <span style={{width:"18px", display:"inline-block"}}>{index+1}</span>{articlesOfAssociation.latest.category}
                                </td>
                                <td className={st.origin}>
                                    {articlesOfAssociation.origin || "없음"}
                                </td>
                                <td className={st.latest}>
                                    {articlesOfAssociation.latest.content} 
                                    {articlesOfAssociation.latest.description && 
                                        <> ({articlesOfAssociation.latest.description})</>
                                    }
                                    {articlesOfAssociation.latest.issueLimitYn === true &&
                                        <div className={st.subTextInput}>
                                            <div className={st.inputRow}>
                                                <span className={st.inputTitle}>보통주 발행한도</span>
                                                <InputText name="agendaDetail.보통주_issueLimit" value={addCommas(articlesOfAssociation.latest.보통주_issueLimit)} onChange={(e) => vm.보통주_issueLimit(e, props.agendaIndex, index)} size="small" style={{width:'120px'}} textAlign="right" errorText={<Error name="agendaDetail.보통주_issueLimit" value={(addCommas(articlesOfAssociation.latest.보통주_issueLimit)).toString()} errorCase={{
                                                    required: '금액을 입력하세요.'
                                                }} />}/><span className={st.won}>억 원</span>
                                            </div>
                                            <div className={st.inputRow}>
                                                <span className={st.inputTitle}>우선주 발행한도</span>
                                                <InputText name="agendaDetail.우선주_issueLimit" value={addCommas(articlesOfAssociation.latest.우선주_issueLimit)} onChange={(e) => vm.우선주_issueLimit(e, props.agendaIndex, index)} size="small" style={{width:'120px'}} textAlign="right" errorText={<Error name="agendaDetail.우선주_issueLimit" value={(addCommas(articlesOfAssociation.latest.우선주_issueLimit)).toString()} errorCase={{
                                                    required: '금액을 입력하세요.'
                                                }} />}/><span className={st.won}>억 원</span>
                                            </div>
                                        </div>
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
    ));
})
export default AdditionalAoAChange;