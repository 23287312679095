import InternetAssociateModel from '@model/Corp/InternetAssociate/InternetAssociateModel';
import { runInAction } from 'mobx';
//@ts-ignore
import { get, post, patch } from '@common/module/httpRequest';
//@ts-ignore
import {LoadStepRs, loadTermRs, LoadStep2Rs, CalcRq, CalcRs, Step2SubmitRq, LoadStep3Rs, MemoRq} from './InternetAssociateService.interface';
//@ts-ignore
import corpModel from '@model/Corp/corpModel';
class InternetAssociateService {
    async loadStep() {
        const res = await get<LoadStepRs>(`/company/${corpModel.id}/internetAssociate/step`);
        return res.data.step;
    }
    async consult() {
        await post(`/company/${corpModel.id}/internetAssociate/consult`);
    }
    async loadTerm() {
        const res = await get<loadTermRs>(`/term/INTERNET_ASSOCIATE`);
        runInAction(() => {
            InternetAssociateModel.step2.termInfos = res.data.userRegistrationTerms.map((userRegistrationTerms) => {
                return {
                    id : userRegistrationTerms.id,
                    title : userRegistrationTerms?.title || '',
                    content : userRegistrationTerms?.content || '',
                    required : userRegistrationTerms?.required ?? false,
                    agreeYn : false
                }
            });
        })
    }
    async loadStep2() {
        const res = await get<LoadStep2Rs>(`/company/${corpModel.id}/internetAssociate/step2`);
        runInAction(() => {
            InternetAssociateModel.step2.products = res.data.products;
            InternetAssociateModel.step2.selectedProductsDetail.TELEPHONE.id = res.data.products.TELEPHONE[0].id;
            InternetAssociateModel.step2.consultInfo = {
                name : res.data.consultInfo.name || "",
                phoneNumber : res.data.consultInfo.phoneNumber || "",
                address : res.data.consultInfo.address || "",
                content : "",
                email : res.data.consultInfo.email || ""
            };
        })
    }
    async calc(param:CalcRq) {
        const res = await post<CalcRs>(`/company/${corpModel.id}/internetAssociate/calc`, param);
        runInAction(() => {
            InternetAssociateModel.step2.estimate = {
                baseFee : res.data.baseFee || 0,
                bundleDiscount : res.data.bundleDiscount || 0,
                cashback : res.data.cashback || 0
            }
        })
    }
    async step2Submit(param : Step2SubmitRq) {
        await post(`/company/${corpModel.id}/internetAssociate`, param);
        window.location.href = `/corp/internetAssociate/result?corp=${corpModel.id}`;
    }
    async loadStep3() {
        const res = await get<LoadStep3Rs>(`/company/${corpModel.id}/internetAssociate/step3`);
        runInAction(() => {
            InternetAssociateModel.step3.status = res.data?.applicationDetails?.status || '';
            InternetAssociateModel.step3.date = res.data?.applicationDetails?.date || '';
            InternetAssociateModel.step3.products = res.data?.completionDetails?.products ? res.data?.completionDetails?.products.map((product) => {
                return {
                    id : product?.id || '',
                    type : product?.type || '',
                    name : product?.name || '',
                    option : product?.option || '',
                    contractYears : product?.contractYears || '',
                    contractPeriod : product?.contractPeriod || '',
                    circuit : product?.circuit || 0,
                    serialNumber : product?.serialNumber || '',
                    startDate : product?.startDate || '',
                    memo : product?.memo || ''
                }
            }) : [];
        })
    }
    async memo(param : MemoRq, id : string) {
        await patch(`/company/${corpModel.id}/internetAssociate/${id}/memo`, param);
        window.location.reload();
    }
}

export default new InternetAssociateService();