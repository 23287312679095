import { useObserver } from 'mobx-react';
import { Route, withRouter, Link } from "react-router-dom";
import { useEffect } from 'react';
import RegisteringModalVm from './RegisteringModalVm';
function RegisteringModal() {
    return useObserver(() => (
        <div>
            스탠바이에서 {RegisteringModalVm.companyName}의 정관을 등록하고 있어요. 등록이 끝나는 대로 알림 드릴게요.
            <Link to={`/corp/articlesOfAssociation?corp=${RegisteringModalVm.demoCorpId}`} style={{border: '1px solid black'}}>샘플 컴퍼니 미리 살펴보기</Link>
        </div>
    ));
}
export default RegisteringModal;