import { useObserver } from 'mobx-react';
import st from '../AgendaDashboard.module.scss';
import vm from './AoaVm';

function Aoa() {
    return useObserver(() => (
        <div className={st.item}>
            <div className={st.title}>정관</div>
            <div className={st.content}>
                <button type='button' className={st.likeLinkBtn} onClick={() => vm.openModal()}>정관 DB</button>
                <a className={st.downloadBtn} style={{marginLeft : "24px"}} href={vm.aoa.downloadUrl}>정관파일</a>
            </div>
        </div>
    ));
}
export default Aoa;