import { observable, runInAction, toJS } from 'mobx';
import formDataToJson from '@common/module/submit';
import unissuedStockCertificateService from '@service/Corp/Stock/UnissuedStockCertificate/unissuedStockCertificateService';
import unissuedStockCertificateModel from '@model/Corp/Stock/UnissuedStockCertificate/unissuedStockCertificateModel';
import corpModel from '@model/Corp/corpModel';
import { _alert, _confirm } from "@model/dialogModel";
import { pageRouter } from '@model/pageModel';
import regExp from '@common/module/regExp';

class SendVm {
    constructor() {
        this.shareholderId = '';
        this.state = observable({
            loadingYn: false
        })
    }
    async onSubmit(e, sendParams) {
        e.preventDefault();
        if(formDataToJson(e.target).error) {
            return
        } else {
            let param = sendParams;
            param.updateInfo.email = unissuedStockCertificateModel.shareholders[this.shareholderIdx].email;

            try {
                this.state.loadingYn = true;
                await unissuedStockCertificateService.send(param);
                this.state.loadingYn = false;
                await _alert('주권미발행확인서를 발송했어요.')
                pageRouter.replace(`/corp/stock/home?corp=${corpModel.id}`);
            } catch(error) {
                this.state.loadingYn = false;
            }
        }
    }
    load(sendParams) {
        this.shareholderId = sendParams.shareholderId;
    }
    get shareholderIdx() {
        return Number(unissuedStockCertificateModel.shareholders.findIndex((item) => Number(item.id) === Number(this.shareholderId)));
    }
    get existsEmailYn() {
        return unissuedStockCertificateModel.shareholders[this.shareholderIdx].existsEmailYn;
    }
    get shareholderName() {
        return unissuedStockCertificateModel.shareholders[this.shareholderIdx].name;
    }
    get email() {
        return unissuedStockCertificateModel.shareholders[this.shareholderIdx].email;
    }
    setEmail(e) {
        runInAction(() => {
            unissuedStockCertificateModel.shareholders[this.shareholderIdx].email = regExp.deleteSpace(e.target.value);
        })
    }
}

export default SendVm;