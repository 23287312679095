import { useObserver } from 'mobx-react';
import st from './MemoWriteModal.module.scss';
import DialogBtn from '@standby/common-ui/components/atoms/Button/DialogBtn/DialogBtn';
import { useEffect, useRef, useCallback } from 'react';
import vm from './MemoWriteModalVm';
import clsx from 'clsx';

interface Props {
    memo : string
}

function MemoWriteModal(props : Props) {
    const contentEditableRef = useRef<HTMLDivElement>(null);
    const isComposing = useRef(false);
    useEffect(() => {
        vm.load(props.memo)
        document.addEventListener('selectionchange', vm.setBoldActive);
    return () => {
        document.removeEventListener('selectionchange', vm.setBoldActive);
    };
    }, [])
    const handleCompositionStart = () => {
        isComposing.current = true;
    };
    
    const handleCompositionEnd = () => {
        isComposing.current = false;
    };
    
    const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter' && !isComposing.current) {
            e.preventDefault();
    
            const selection = window.getSelection();
            if (selection) {
                const range = selection.getRangeAt(0);
                const br = document.createElement('br');
                const dot = document.createTextNode('ㆍ');
        
                range.deleteContents();
                range.insertNode(br);
                range.setStartAfter(br);
                range.insertNode(dot);
                range.setStartAfter(dot);
                range.setEndAfter(dot);
                selection.removeAllRanges();
                selection.addRange(range);
            }
        }
    }, []);
    return useObserver(() => (
        <div className={st.MemoWriteModal}>
            <div className={st.toolbox}>
                <button className={clsx(st.boldBtn, vm.boldActive ? st.active : '')} onClick={() => vm.setBold(contentEditableRef)}>B</button>
                <div className={st.templates}>
                    <div className={st.title}>자동 템플릿</div>
                    <div className={st.btns}>
                        {vm.templates.map((template, index) => (
                            <button type="button" key={index} disabled={!template.isActive} onClick={() => vm.insertTemplate(template.content, contentEditableRef)}>{template.title}</button>
                        ))}
                    </div>
                </div>
            </div>
            <div 
                className={st.contentEdit}
                dangerouslySetInnerHTML={{__html: vm.memo}}
                suppressContentEditableWarning
                contentEditable
                ref={contentEditableRef}
                onKeyDown={(e) => handleKeyDown(e)}
                onCompositionStart={handleCompositionStart}
                onCompositionEnd={handleCompositionEnd}
            />
            <div className={st.btnArea}>
                <DialogBtn color="confirm" onClick={() => vm.saveMemo(contentEditableRef)}>저장</DialogBtn>
                <DialogBtn onClick={() => vm.closeModal()}>취소</DialogBtn>
            </div>
        </div>
    ));
}
export default MemoWriteModal;