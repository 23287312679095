import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { useEffect } from 'react';
import st from './Content.module.scss';
function Content(props) {
    return useObserver(() => (
        <article id={st.article}>
            {props.children}
        </article>
    ));
}
export default Content;