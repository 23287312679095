import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { useEffect } from 'react';
import st from './CMSAccountPopup.module.scss';
import vm from './CMSAccountPopupVm';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import Loading from '@commonComponents/Loading/Loading';
import DocumentPopup from '@commonComponents/DocumentPopup/DocumentPopup';

function CMSAccountPopup(props) {

    useEffect(() => {
        vm.load(props.param);
    }, []);

    return useObserver(() => (
        <>
            {vm.state.loadingYn && <Loading />}
            <DocumentPopup iframeTitle='CMS 자동이체 신청서' iframeSrc={vm.cmsDirectDebitApplicationLink}>
                <div className={st.btnArea}>
                    <SquareBtn className={st.agreeBtn} onClick={() => vm.electronicSignature(props.param)}>전자서명하기</SquareBtn>
                </div>
            </DocumentPopup>            
        </>
    ));

}

export default CMSAccountPopup;