import InternetAssociateService from '@service/Corp/InternetAssociate/InternetAssociateService';
import InternetAssociateModel from '@model/Corp/InternetAssociate/InternetAssociateModel';
import portalModel from '@model/portalModel';
import Step1 from '../Step1/Step1';
import { runInAction } from "mobx";
import corpModel from "@model/Corp/corpModel";
import UserSetting from "@pages/UserSetting/UserSetting";
import { _alert } from "@src/model/dialogModel";
import ProductDetail from './ProductDetail/ProductDetail';
import {Step3Product} from '@model/Corp/InternetAssociate/InternetAssociateModel.interface';

class Step3Vm {
    load() {
        InternetAssociateService.loadStep3();
    }
    get status() {
        return InternetAssociateModel.step3.status;
    }
    get date() {
        return InternetAssociateModel.step3.date;
    }
    get id() {
        return corpModel.id;
    }
    get products() {
        return InternetAssociateModel.step3.products;
    }
    openUserSetting() {
        runInAction(() => {
            portalModel.content = <UserSetting activeTab="약관동의"/>;
            portalModel.title = '나의 계정'
        })
    }
    previewModal() {
        runInAction(() => {
            portalModel.content = <Step1 preview={true}/>
        })
    }
    moreService() {
        if(this.status === "CONSULT_APPLICATION_COMPLETED" || this.status === "OPENING_APPLICATION_COMPLETED") { 
            return _alert("현재 진행중인 개통 건이 있어요!", "개통완료 후 추가 신청이 가능해요");
        }else{
            window.location.href = `/corp/internetAssociate/application?corp=${corpModel.id}`;
        }
    }
    totalCircuitCount(type : "INTERNET" | "TELEPHONE" | "ETC") {
        let total = 0;
        this.products.forEach((product) => {
            if(product.type === type) {
                total += product.circuit;
            }
        })
        return total;
    }
    productDetail(product : Step3Product) {
        runInAction(() => {
            portalModel.content = <ProductDetail product={product}/>;
            portalModel.title = ''
        })
    }
}
export default new Step3Vm();