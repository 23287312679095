import { useObserver } from 'mobx-react';
import LoginVm from './LoginVm';
import Theme from '@common/theme.scss';
import st from './Login.module.scss';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import regExp from '@common/module/regExp';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';

import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import Check from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import { useEffect } from 'react';

function Login(props) {
    let location = useLocation();
    const redirectToken = queryString.parse(location.search).redirectToken;

    return useObserver(() => (
        <form onSubmit={(e) => LoginVm.submit(e, redirectToken)} className={st.loginContent}>
            <div className={st.logoWrap}><a href='https://standby.kr/' className={st.logo}><img src='/images/logo_standby.svg?1'/></a></div>
            <div className={st.mainTitle}>법인관리 <span className={st.bold}>스탠바이 헬프미</span></div>
            <div className={st.emailBox}>
                <div className={st.title}>이메일</div>
                <InputText name="username" className={st.inputText} placeholder="example@logologo.com" value={LoginVm.email} onChange={LoginVm.setEmail} fullSize/>
            </div>
            <div className={st.passwordBox}>
                <div className={st.title}>비밀번호</div>
                <InputText type="password" name="password" className={st.inputText} placeholder="Password" value={LoginVm.password} onChange={LoginVm.setPassword} fullSize/>
            </div>
            <Check className={st.checkBox} name="autoLogin" value="true">자동 로그인</Check>
            <SquareBtn type="submit" className={st.squareBtn}>로그인</SquareBtn>
            <div className={st.likeBtnBox}>
                <Link to="/auth/findId" className={st.likeBtn}>아이디 찾기</Link>
                <Link to="/auth/findPassword" className={st.likeBtn}>비밀번호 찾기</Link>
                <Link to="/auth/joinMembership" className={st.likeBtn}>회원가입 하기</Link>
            </div>
        </form>
    ));
}
export default Login;