import { observable, runInAction, toJS } from 'mobx';
import corpModel from '@model/Corp/corpModel';

class GuideVm {
    constructor(guideDisplayYn) {
        this.state = observable({
            guideDisplayYn: guideDisplayYn
        })
    }
    get corpId() {
        return corpModel.id;
    }
    guideHideState(e, localStorageKey){
        this.state.guideDisplayYn = e.target.checked;
        let hideIdList = [];
        if(this.state.guideDisplayYn) {
            if(localStorage.getItem(localStorageKey)) {
                hideIdList = localStorage.getItem(localStorageKey).split(",");
                hideIdList = hideIdList.filter((elem) => elem !== this.corpId);
                localStorage.setItem(localStorageKey, hideIdList.join());
            }
        } else {
            if(localStorage.getItem(localStorageKey)) {
                hideIdList = localStorage.getItem(localStorageKey).split(",");
                if(!hideIdList.includes(this.corpId)) {
                    hideIdList.push(this.corpId);
                }
                localStorage.setItem(localStorageKey, hideIdList.join());
            } else {
                localStorage.setItem(localStorageKey, this.corpId)
            }
        }
    }
}
export default GuideVm;