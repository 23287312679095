import { useObserver } from 'mobx-react';
import st from '../OnlineAcceptancePopup.module.scss';
import vm from './Step1Vm';

function Step1() {
    return useObserver(() => (
        <div className={st.step1}>
            <div className={st.title}>
                우편이 아니라 <span className={st.important}>온라인</span>으로 보내려면, <span className={st.important}>동의 꼭</span> 받으세요!
            </div>
            <div className={st.steps}>
                <div className={st.stepWrap}>
                    <div className={st.step}>
                        <div className={st.stepTitle}>
                            소집통지는 <span className={st.bold}>우편발송</span>이<br/>
                            <span className={st.bold}>원칙</span>이에요
                        </div>
                    </div>
                </div>
                <div className={st.stepWrap}>
                    <div className={st.step}>
                        <div className={st.stepTitle}>
                            주주 <span className={st.bold}>동의를 받아야</span><br/>
                            <span className={st.bold}>온라인 발송</span>이 <span className={st.bold}>가능</span>해요
                        </div>
                        
                    </div>
                </div>
                <div className={st.stepWrap}>
                    <div className={st.step}>
                        <div className={st.stepTitle} style={{marginTop:'4px'}}>
                            <span className={st.bold}>사후동의도 인정</span>되므로,<br/>
                            지금 동의를 받아주세요
                        </div>
                        <div className={st.stepContent}>(최초 1회 동의 필요)</div>
                    </div>
                </div>
            </div>
            <span className={st.explain}>동의없이 온라인 발송하면 통지의 효과가 없으니 주의하세요.</span>
            <div className={st.btnArea}>
                <button type="button" className={st.directbtn} onClick={() => vm.next('Direct')}>
                    <div className={st.btnTitle}>이미 동의를 받으신 경우 정보 입력</div>
                    <div className={st.btnText}>동의여부 직접 입력하기</div>
                </button>
                <button type="button" className={st.standbyBtn} onClick={() => vm.next('Document')}>
                    {/* <ul className={st.btnTitle}>
                        스탠바이를 통해 온라인 소집통지 수신동의 받기
                    </ul> */}
                    <ul>
                        <li>아직 동의 안 받은 경우</li>
                        <li>증빙을 만들고 싶은 경우</li>
                    </ul>
                    <div className={st.btnText}>온라인으로 간편하게 동의받기</div>
                </button>
            </div>
        </div>
    ));
}
export default Step1;