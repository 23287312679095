import { useObserver } from 'mobx-react';
import { useEffect } from 'react';
import vm from './CorporateTaxVm';
import st from './CorporateTax.module.scss';
import PageTitle from '@commonComponents/PageTitle/PageTitle';
import Guide from '@commonComponents/Guide/Guide';
import GuideList from '@commonComponents/GuideList/GuideList';
import FirstPage from './FirstPage/FirstPage';
import StepPage from './StepPage/StepPage';

function CorporateTax() {

    useEffect(() => {
        vm.load();
    }, [])

    return useObserver(() => (
        <>  
            <PageTitle><span className={st.pageTitle}>법인세</span></PageTitle>
            <Guide title={<strong>법인세 가이드</strong>} localStorageKey="corporateTaxGuide" corpId={vm.corpId}>
                <GuideList items={vm.guides} fullLink="https://standby.kr/blog/group/%EB%B2%95%EC%9D%B8%EC%84%B8%20%ED%95%84%EC%88%98%EC%83%81%EC%8B%9D/"/>
            </Guide>
            {vm.state.loadYn &&
            <>
                {/* 법인세 첫 페이지 */}
                {(vm.nowStep === '안내문_발송전' || (!vm.nowStep && vm.corporateTaxes.length === 0)) && <FirstPage />}
                {/* 법인세 step 페이지 */}
                {(vm.nowStep && vm.nowStep !== '안내문_발송전') && <StepPage />}
            </>
            }
        </>
    ));
}
export default CorporateTax;