import type {CorporateTax, Guide, TaxFilingState, TaxFilingPreparationData, CorporateTaxes} from './corporateTaxModel.interface';

import { makeObservable, observable } from 'mobx';


class CorporateTaxModelClass implements CorporateTax {
    constructor() {
        makeObservable(this, {
            guides : observable,
            taxFilingState : observable,
            taxFilingPreparationData : observable,
            corporateTaxes : observable,
        });
    }
    guides: Guide[] = [];
    taxFilingState: TaxFilingState = {
        selectableCorporateTaxes: [],
        steps: [],
        settlementPeriod: {
            startAt: {
            year: "",
            month: "",
            day: ""
        },
        endAt: {
            year: "",
            month: "",
            day: ""
        }
        },
        taxFilingEndAt: {
            year: "",
            month: "",
            day: ""
        },
        faithfulnessTaxPayerYn: null,
        settlementMonth: "",
    };
    taxFilingPreparationData: TaxFilingPreparationData = {
        deadline: {
            year: "",
            month: "",
            day: ""
        },
        emailToSubmit: "",
    };
    corporateTaxes: CorporateTaxes[] = [];
}

export default new CorporateTaxModelClass();