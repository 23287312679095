import { useObserver } from 'mobx-react';
import { useCallback, useEffect, useState } from 'react';
import JoinBusinessRegistration from './JoinBusinessRegistration/JoinBusinessRegistration';
import LoginBusinessRegistration from './LoginBusinessRegistration/LoginBusinessRegistration';
import Router from './Router/Router';
import { Route, Routes, useNavigate } from "react-router-dom";
import vm from './AuthBusinessRegistrationVm';

function AuthBusinessRegistration() {
    let navigate = useNavigate();
    return useObserver(() => (
        <Routes>
            {/* 회원가입  */}
            <Route path="/join" element={<JoinBusinessRegistration/>}/>
            {/* 로그인 */}
            <Route path="/login" element={<LoginBusinessRegistration/>}/>
            {/* 사업자등록 관련 router 페이지 */}
            <Route path="/router" element={<Router/>}/>
        </Routes>
    ));
}
export default AuthBusinessRegistration;
