import { useObserver } from 'mobx-react';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import st from './Corp.module.scss';
import Side from './Side/Side';
import Dashboard from './Dashboard/Dashboard';
import Stock from './Stock/Stock';
import { Route, Routes, useLocation } from "react-router-dom";
import queryString from 'query-string';
import CorpVm from './Corpvm';
import BeforeRegister from './BeforeRegister/BeforeRegister';
import AdminSetting from './AdminSetting/AdminSetting';
import Register from './Register/Register';
import Agenda from './Agenda/Agenda';
import ArticlesOfAssociation from './ArticlesOfAssociation/ArticlesOfAssociation';
import Header from '@commonComponents/Header/Header';
import Content from '@commonComponents/Content/Content';
import Box1040 from '@commonComponents/Box1040/Box1040';
import BusinessRegistration from './BusinessRegistration/BusinessRegistration';
import CorporateTax from './CorporateTax/CorporateTax';
import Vat from './Vat/Vat';
import BookKeepingContract from './BookKeepingContract/BookKeepingContract';
import AdminAdd from './AdminAdd/AdminAdd';
import RedirectPage from './RedirectPage/RedirectPage';
import AwsCredit from './AwsCredit/AwsCredit';
import Alliance from './Alliance/Alliance';
import InternetAssociate from './InternetAssociate/InternetAssociate';

function Corp() {
    let location = useLocation();
    const id = queryString.parse(location.search).corp;

    useEffect(() => {
        CorpVm.load(id)
        return () => {
            CorpVm.removeCorp();
        };
    },[id]);

    useEffect(() => {
        if(location.pathname !== '/corp/redirect') {
            if(CorpVm.id) {
                CorpVm.popupLoad();
            }
        }
    }, [location])

    return useObserver(() => (
        <div className={st.wrap}>
            {(CorpVm.id && CorpVm.companyRegisterStatus !== null && CorpVm.nowDate) && 
                <>
                    <Header/>
                    <Side/>
                    <Content>
                        {CorpVm.isDemo === true && 
                            <Box1040 className={st.registerTop} a="a" contentClassName={st.content}>
                                <a href='/companyRegistration'>
                                    <img src='/images/registerTopText.svg' alt='법인등록하고, 온라인 주주총회 시작하세요!'/>
                                    <img src='/images/registerTopBtn.svg' alt='법인 등록하기'/>
                                </a>
                            </Box1040>
                        }
                        {CorpVm.liquidationYn === true && 
                            <Box1040 className={st.liquidationTop} a="a" contentClassName={st.content}>
                                <img src='/images/liquidationTop.svg'/>
                            </Box1040>
                        }
                        {(CorpVm.alliancePopupShowPath(location.pathname) && CorpVm.isDemo === false && CorpVm.liquidationYn === false && CorpVm.alliancePopupShowYn === true) && 
                            <Box1040 className={st.allianceTop} a="a" contentClassName={st.content}>
                                <div className={st.relative}>
                                    <img src='/images/allianceTopImg.svg' className={st.leftImg}/>
                                    <a href={`/corp/alliance?corp=${CorpVm.id}`}>
                                        <img src='/images/allianceTopContent.svg' alt="스탠바이 회원 단독 ‘기업용 인터넷&전화’ 캐시백 혜택"/>
                                    </a>
                                </div>
                                <button type='button' className={st.close} onClick={() => CorpVm.alliancePopupClose()}>닫기</button>
                            </Box1040>
                        }
                        <Box1040>
                                <Routes>
                                    {/* 대시보드 */}
                                    <Route path="/dashboard" element={<CorpComponent component={<Dashboard/>} pathType="dashboard"/>}/>
                                    {/* 주주/주식 */}
                                    <Route path="/stock/*" element={<CorpComponent component={<Stock/>} pathType="stock"/>}/>
                                    {/* 관리자권한설정 */}
                                    <Route path="/adminSetting" element={<AdminSetting/>}/>
                                    {/* 관리자초대 */}
                                    <Route path="/adminAdd" element={<AdminAdd/>}/>
                                    {/* 등기부 */}
                                    <Route path="/register" element={<CorpComponent component={<Register/>} pathType="register"/>}/>
                                    {/* 안건선택 */}
                                    <Route path="/agenda/*" element={<CorpComponent component={<Agenda/>} pathType="agenda"/>}/>
                                    {/* 정관 */}
                                    <Route path="/articlesOfAssociation" element={<CorpComponent component={<ArticlesOfAssociation/>} pathType="articlesOfAssociation"/>}/>
                                    {/* 무료 사업자등록 */}
                                    <Route path="/accounting/businessRegistration/*" element={<CorpComponent component={<BusinessRegistration pathType="businessRegistration"/>} pathType="businessRegistration"/>}/>
                                    {/* 법인세 */}
                                    <Route path="/accounting/corporateTax" element={<CorporateTax/>}/>
                                    {/* 부가세 */}
                                    <Route path="/accounting/vat" element={<Vat/>}/>
                                    {/* 기장계약 */}
                                    <Route path="/accounting/bookKeepingContract" element={<BookKeepingContract/>}/>
                                    {/* aws 크레딧 */}
                                    <Route path="/awsCredit/*" element={<AwsCredit/>}/>
                                    {/* 인터넷 제휴 */}
                                    <Route path="/internetAssociate/*" element={<InternetAssociate/>}/>
                                    {/* 제휴소개페이지 */}
                                    <Route path="/alliance" element={<Alliance/>}/>
                                    {/* 리다이렉트 페이지 */}
                                    <Route path="/redirect" element={<RedirectPage/>}/>
                                </Routes>
                        </Box1040>
                    </Content>
                    <div className={st.vocLinkWrap}>
                        <a className={st.vocLink} href='https://standbylab.featurebase.app/ko' target='_blank'>
                            <span className={st.tooltip}>개선요청/오류신고</span>
                        </a>
                    </div>
                </>
            }
        </div>
    ));
}

function CorpComponent(props) {
    return useObserver(() => (
        <>
            {CorpVm.companyRegisterStatus === "REGISTERED" && <>{props.component}</>}
            {(CorpVm.companyRegisterStatus !== "REGISTERED" && props.pathType === "agenda") && <>{props.component}</>}
            {(CorpVm.companyRegisterStatus !== "REGISTERED" && props.pathType !== "agenda") && <BeforeRegister pathType={props.pathType}/>}
        </>
    ));
}
export default Corp;
