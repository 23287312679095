import { observable, runInAction, toJS } from 'mobx';
import formDataToJson from '@common/module/submit';
import onlineMusterService from '@service/Corp/Stock/OnlineMuster/onlineMusterService';
import onlineMusterModel from '@model/Corp/Stock/OnlineMuster/onlineMusterModel';

class OnlineMusterBeforehandVm {
    constructor() {
        this.state = observable({
            mailRecipients: [],
            smsRecipients: [],  
        });
    }
    submit(e) {
        e.preventDefault();
        if(formDataToJson(e.target).error) {
            return
        } else {
            let param = formDataToJson(e.target).data;
            if(param.newShareholderEmails) {
                param.newShareholderEmails = param.newShareholderEmails.filter((elem) => elem !== undefined);
            }
            if(param.newShareholderSMSs) {
                param.newShareholderSMSs = param.newShareholderSMSs.filter((elem) => elem !== undefined);
            }
            param.shareholdersIdEmail = toJS(this.state.mailRecipients);
            param.shareholdersIdSMS = toJS(this.state.smsRecipients);
            onlineMusterService.onlineMusterBeforehand(param);
        }
    }
    load() {
        onlineMusterService.loadShareholdersData();
    }
    get shareholders() {
        return onlineMusterModel.shareholders;
    }
    setEmail(e) {
        if(e.target.checked) {
            if(this.state.mailRecipients.includes(e.target.value) === false) {
                this.state.mailRecipients.push(e.target.value);
            }
        } else {
            let idx = this.state.mailRecipients.indexOf(e.target.value);
            this.state.mailRecipients.splice(idx, 1);
        }
    }
    setSMS(e) {
        if(e.target.checked) {
            if(this.state.smsRecipients.includes(e.target.value) === false) {
                this.state.smsRecipients.push(e.target.value);
            }
        } else {
            let idx = this.state.smsRecipients.indexOf(e.target.value);
            this.state.smsRecipients.splice(idx, 1);
        }
    }
    get displayEmail() {
        let emptyEmailCnt = 0;
        this.shareholders.forEach((item, idx) => {
            if(item.onlineMusterNoticeStatus === 'WAITING' || item.onlineMusterNoticeStatus === 'UNCONFIRMED') {
                if(item.email === '') {
                    emptyEmailCnt++;
                }
            }
        })
        if(emptyEmailCnt > 0) {
            return true;
        } else {
            return false;
        }
    }
    get displayPhoneNumber() {
        let emptyPhoneNumberCnt = 0;
        this.shareholders.forEach((item, idx) => {
            if(item.onlineMusterNoticeStatus === 'WAITING' || item.onlineMusterNoticeStatus === 'UNCONFIRMED') {
                if(item.phoneNumber === '') {
                    emptyPhoneNumberCnt++;
                }
            }
        })
        if(emptyPhoneNumberCnt > 0) {
            return true;
        } else {
            return false;
        }
    }
    displayAgreeYn(status) {
        switch(status) {
            case 'UNCONFIRMED':
                return '정보없음';
            case 'WAITING':
                return '동의대기';
            case 'ACCEPTANCE':
                return '동의완료';
            default:
                return '';
        }
    }
}

export default new OnlineMusterBeforehandVm();
