import React from 'react';
import formDataToJson from '@common/module/submit';
import businessRegistrationModel from '@model/Corp/BusinessRegistration/businessRegistrationModel';
import businessRegistrationService from '@service/Corp/BusinessRegistration/businessRegistrationService';
import corpModel from '@model/Corp/corpModel';
import userModel from '@model/userModel';
import { observable, runInAction } from 'mobx';
import regExp from '@common/module/regExp';
import { _alert } from "@model/dialogModel";
import portalModel from '@model/portalModel';
import SendPreview from './SendPreview/SendPreview';
import AddAccount from '@pages/Corp/BusinessRegistration/BusinessRegistrationDocument/AddAccount/AddAccount';
import st from './SendBRAndBankbook.module.scss';

interface State {
    documentErrorCheckYn: boolean;
    sendMethodErrorCheckYn: boolean;
    businessRegistrationYn: boolean;
    bankbookYn: boolean;
    bankbookId: number;
    email: string;
    recipients: {
        emailCheckYn: boolean;
        phoneNumberYn: boolean;
        name: string;
        email?: string;
        phoneNumber?: string;
    }[];
    loadingYn: boolean;
}

class SendBRAndBankbookVm {
    state: State = observable({
        documentErrorCheckYn: false,
        sendMethodErrorCheckYn: false,
        businessRegistrationYn: false,
        bankbookYn: false,
        bankbookId: -1,
        email: "",
        recipients: [{
            emailCheckYn: false,
            phoneNumberYn: false,
            name: "",
            email: "",
            phoneNumber: ""
        }],
        loadingYn: false
    })
    async load() {
        await businessRegistrationService.document();
        this.state.email = userModel.user.email;
        if(this.bankbooks.length === 1) {
            this.state.bankbookId = this.bankbooks[0].id;
        }
    }
    get documentCheckBoxError() {
        if(this.state.bankbookYn === false && this.state.businessRegistrationYn === false) {
            return true;
        } else {
            return false;
        }
    }
    get sendMethodCheckBoxError() {
        let errorYn = false;
        for(let i=0; i<this.state.recipients.length; i++) {
            if(this.state.recipients[i].emailCheckYn === false && this.state.recipients[i].phoneNumberYn === false) {
                errorYn = true;
            }
        }
        return errorYn;
    }
    async submit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if(formDataToJson(e.target).error || this.documentCheckBoxError || this.sendMethodCheckBoxError) {
            if(this.documentCheckBoxError) {
                this.state.documentErrorCheckYn = true;
            }
            if(this.sendMethodCheckBoxError) {
                this.state.sendMethodErrorCheckYn = true;
            }
            return
        } else {
            let param: {
                bankbook?: string;
                businessRegistration?: string;
                bankbookId?: string;
                recipients?: {
                    name: string;
                    email?: string;
                    phoneNumber?:string; 
                }[];
                taxInvoiceIssuanceEmail?: string;
            } = formDataToJson(e.target).data;

            delete param.bankbook; delete param.businessRegistration;

            //shareType
            let _param: {
                shareType: string;
                bankbookId?: string;
                taxInvoiceIssuanceEmail?: string;
                recipients?: {
                    name: string;
                    email?: string;
                    phoneNumber?:string; 
                }[]
            } = { ...param, shareType: ""};
            if(this.state.businessRegistrationYn && this.state.bankbookYn) {
                _param.shareType = 'BR__BANKBOOK';
            } else if(this.state.businessRegistrationYn && this.state.bankbookYn === false) {
                _param.shareType = 'BR';
            } else if(this.state.businessRegistrationYn === false && this.state.bankbookYn) {
                _param.shareType = 'BANKBOOK';
            }
            try {
                this.state.loadingYn = true;
                await businessRegistrationService.documentShare(_param);
                this.state.loadingYn = false;

                let alertString = '';
                if(_param.shareType === 'BR__BANKBOOK') {
                    alertString = '사업자등록증 & 통장사본을 발송했어요.';
                } else if(_param.shareType === 'BR') {
                    alertString = '사업자등록증을 발송했어요.';
                } else if(_param.shareType === 'BANKBOOK') {
                    alertString = '통장사본을 발송했어요.';
                }

                await _alert(alertString, '', '', <ul className={st.alert}><li>발송내역은 오른쪽 위 알림에서 확인 가능해요.</li></ul>);
                window.location.href=`/corp/accounting/businessRegistration?corp=${corpModel.id}`;
            } catch(err) {
                this.state.loadingYn = false;
            }
        }
    }
    get businessRegistration() {
        return businessRegistrationModel.businessRegistration;
    }
    get bankbooks() {
        return businessRegistrationModel.bankbooks;
    }
    setBusinessRegistration(e: React.ChangeEvent<HTMLInputElement>) {
        if(e.target.checked) {
            this.state.documentErrorCheckYn = false;
        } else if(e.target.checked === false && this.state.bankbookYn === false) {
            this.state.documentErrorCheckYn = true;
        }
        this.state.businessRegistrationYn = e.target.checked;
    }
    setBankbook(e: React.ChangeEvent<HTMLInputElement>) {
        if(e.target.checked) {
            this.state.documentErrorCheckYn = false;
        }  else if(e.target.checked === false && this.state.businessRegistrationYn === false) {
            this.state.documentErrorCheckYn = true;
        }
        this.state.bankbookYn = e.target.checked;
    }
    setBankbookId(e: React.ChangeEvent<HTMLInputElement>) {
        this.state.bankbookId = Number(e.target.value);
    }
    setUserEmail(e: React.ChangeEvent<HTMLInputElement>) {
        this.state.email = regExp.deleteSpace(e.target.value);
    }
    addRecipient() {
        this.state.recipients.push({
            emailCheckYn: false,
            phoneNumberYn: false,
            name: "",
            email: "",
            phoneNumber: ""
        })
    }
    removeRecipient(idx: number) {
        this.state.recipients.splice(idx, 1);
    }
    allCheck(idx: number) {
        if(this.state.recipients[idx].emailCheckYn && this.state.recipients[idx].phoneNumberYn) {
            return true;
        } else {
            return false;
        }
    }
    setAgreeAll(e: React.ChangeEvent<HTMLInputElement>, idx: number) {
        if(e.target.checked) {
            this.state.sendMethodErrorCheckYn = false;
        } else {
            this.state.sendMethodErrorCheckYn = true;
        }
        this.state.recipients[idx].emailCheckYn = e.target.checked;
        this.state.recipients[idx].phoneNumberYn = e.target.checked;
    }
    setEmailCheck(e: React.ChangeEvent<HTMLInputElement>, idx: number) {
        if(e.target.checked) {
            this.state.sendMethodErrorCheckYn = false;
        } else if(e.target.checked === false && this.state.recipients[idx].phoneNumberYn === false) {
            this.state.sendMethodErrorCheckYn = true;   
        }
        this.state.recipients[idx].emailCheckYn = e.target.checked;
    }
    setPhoneNumberCheck(e: React.ChangeEvent<HTMLInputElement>, idx: number) {
        if(e.target.checked) {
            this.state.sendMethodErrorCheckYn = false;
        } else if(e.target.checked === false && this.state.recipients[idx].emailCheckYn === false) {
            this.state.sendMethodErrorCheckYn = true;
        }
        this.state.recipients[idx].phoneNumberYn = e.target.checked;
    }
    setName(e: React.ChangeEvent<HTMLInputElement>, idx: number) {
        this.state.recipients[idx].name = e.target.value;
    }
    setEmail(e: React.ChangeEvent<HTMLInputElement>, idx: number) {
        this.state.recipients[idx].email = regExp.deleteSpace(e.target.value);
    }
    setPhoneNumber(e: React.ChangeEvent<HTMLInputElement>, idx: number) {
        this.state.recipients[idx].phoneNumber = e.target.value;
    }
    sendPreview(){
        runInAction(() => {
            portalModel.title = '발송화면 미리보기';
            portalModel.content = <SendPreview />;
        })
    }
    changeBankbook() {
        window.open(`/corp/accounting/businessRegistration/document/bankbook?corp=${corpModel.id}`);
    }
    addBankbook() {
        runInAction(() => {
            portalModel.title = '새로운 계좌 추가하기';
            portalModel.content = <AddAccount />;
        })
    }
}

export default new SendBRAndBankbookVm();