
import React, { useEffect } from 'react';
import { useObserver } from 'mobx-react';
import clsx from 'clsx';
import st from './FindPassword.module.scss';
import FindPasswordVm from './FindPasswordVm';
import regExp from '@common/module/regExp';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import Icon from '@standby/common-ui/components/atoms/Icon/Icon';

function FindPassword(props) {
    useEffect(() => {
        FindPasswordVm.removeSessionStorage();
    }, []);

    return useObserver(() => (
        <form onSubmit={(e) => FindPasswordVm.submit(e)} className={st.FindPasswordContent}>
            <div className={st.logoWrap}><a href='https://standby.kr/' className={st.logo}><img src='/images/logo_standby.svg?1'/></a></div>
            <div className={st.mainTitle}>스탠바이에 가입된 이메일을 입력하세요.</div>
            <div className={st.info}><Icon type="exclamationHover" className={st.icon}/>입력한 이메일로 비밀번호 재설정 링크를 보내드려요.</div>
            <div className={st.emailBox}>
                <div className={st.title}>이메일</div>
                <InputText name="email" className={st.inputText} placeholder="example@logologo.com" value={FindPasswordVm.email} onChange={FindPasswordVm.setEmail} fullSize errorText={<Error name="email" value={FindPasswordVm.email} 
                errorCase={{
                    required: '이메일을 입력해 주세요',
                    pattern: {
                        value: regExp.email().test(FindPasswordVm.email),
                        message: '올바른 이메일 형식으로 입력해 주세요.'
                    }
                }}/>}/>
            </div>
            <SquareBtn type="submit" className={st.squareBtn} active={FindPasswordVm.email}>비밀번호 재설정하기</SquareBtn>
        </form>
    ));
}
export default FindPassword;