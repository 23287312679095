import AgendaDashboardService from "@service/AgendaDashboard/AgendaDashboardService";
import AgendaDashboardModel from "@model/AgendaDashboard/AgendaDashboardModel";
import portalModel from '@model/portalModel';
import { runInAction } from 'mobx';

class RnumberRemoveModalVm {
    removeRnumber(rNumberId : string, deleteFileYn : boolean) {
        AgendaDashboardService.removeRnumber(rNumberId, deleteFileYn, AgendaDashboardModel.company.cssn, AgendaDashboardModel.selectedAgendaProgressId);
        runInAction(() => {
            portalModel.title = "";
            portalModel.content = null;
        })
    }
}
export default new RnumberRemoveModalVm();