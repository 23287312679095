import { useObserver } from 'mobx-react';
import vm from './SelfVm';
import st from '../Final.module.scss';
import clsx from 'clsx';

function Self() {
    return useObserver(() => (
        <>
            <div className={clsx(st.registered, st.content)}>
                <div className={st.title}>셀프등기 진행</div>
                <div className={st.contentRow}>
                    <div className={st.progressSummary} style={{width:"510px", justifyContent:"flex-start", height:"215px"}}>
                        <div className={st.inside} style={{marginLeft:"50px"}}>
                            <img src='/images/progressSummary_SELF.svg'/>
                            <div className={st.texts} style={{width:"233px"}}>
                                <div className={st.item} style={{width:"82px"}}>
                                    <div className={st.text}>셀프등기 선택</div>
                                </div>
                                <div className={st.item}>
                                    <div className={clsx(st.text, st.important)}>등기완료</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={st.fileContent}>
                        <div className={clsx(st.title, st.autoUpdateIcon)}>업데이트 서류</div>
                        <div className={st.fileList}>
                            <div className={st.file}>
                                <div className={clsx(st.fileTitle, st.registerIcon)}>등기부</div>
                                {vm.completed ?
                                    <a href={`/corp/register?corp=${vm.corpId}`} className={st.link}><span>{vm.documents.companyCopyFile.issuedDate} 발급</span></a>
                                    :
                                    <span className={st.empty}>진행중</span>
                                }
                            </div>
                            <div className={st.file}>
                                <div className={clsx(st.fileTitle, st.aoaIcon)}>정관</div>
                                {vm.completed ?
                                    <>
                                        {vm.documents.aoaFile.issuedDate && 
                                            <a href={`/corp/articlesOfAssociation?corp=${vm.corpId}`} className={st.link}><span>{vm.documents.aoaFile.issuedDate} 변경</span></a>
                                        }
                                        {!vm.documents.aoaFile.issuedDate && 
                                            <span className={st.empty}>변동 없음</span>
                                        }
                                    </>
                                    :
                                    <span className={st.empty}>진행중</span>
                                }
                            </div>
                            <div className={st.file}>
                                <div className={clsx(st.fileTitle, st.shareholderIcon)}>주주명부</div>
                                {vm.completed ?
                                    <>
                                        {vm.documents.shareholderListFile.issuedDate && 
                                            <a href={`/corp/stock/home?corp=${vm.corpId}`} className={st.link}><span>{vm.documents.shareholderListFile.issuedDate} 변경</span></a>
                                        }
                                        {!vm.documents.shareholderListFile.issuedDate && 
                                            <span className={st.empty}>변동 없음</span>
                                        }
                                    </>
                                    :
                                    <span className={st.empty}>진행중</span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    ));
}
export default Self;