import { useObserver } from 'mobx-react';
import { useEffect } from 'react';
import st from './Basic.module.scss';
import vm from './BasicVm';
import InformationInput from './InformationInput/InformationInput';
import AccountInformation from './AccountInformation/AccountInformation';
import ProgressStatus from './ProgressStatus/ProgressStatus';
import OnlyFile from './OnlyFile/OnlyFile';
import Registering from './Registering/Registering';
import BeforeUploadFile from './BeforeUploadFile/BeforeUploadFile';
import CompleteHome from './CompleteHome/CompleteHome';
import BankBook from './BankBook/BankBook';
import FileUpload from './FileUpload/FileUpload';

function Basic() {
    useEffect(() => {
        vm.load();
    }, []);

    return useObserver(() => (
        vm.pageType ? 
        <>  
            {vm.pageType === "ONLY_FILE" && <OnlyFile/>}
            {vm.pageType === "INCLUDE_STEP" && 
                <div className={st.businessRegistration}>
                    <div className={st.mainTitle}><span className={st.bold}>무료 사업자등록</span>을 위한 간단 <span className={st.bold}>정보입력</span></div>
                    {(vm.statusType === '정보_입력' && vm.viewingPeriodFinishedYn === true) && 
                        <FileUpload />
                    }
                    <ProgressStatus />
                    {vm.statusType === '정보_입력' && <InformationInput /> }
                    {(vm.statusType === '사업자등록_완료' && vm.bankbooks.length !== 0) && <BankBook />}
                    {vm.statusType !== '정보_입력' && <AccountInformation />}
                </div>
            } 
            {vm.pageType === "REGISTERING" && <Registering/>}
            {vm.pageType === "BEFORE_UPLOAD_FILE" && <BeforeUploadFile/>} 
            {vm.pageType === "COMPLETE_HOME" && <CompleteHome/>}
        </>
        : <></>
    ));
}
export default Basic;