import { useObserver } from 'mobx-react';
import { Route, withRouter, useLocation } from "react-router-dom";
import { useEffect } from 'react';
import st from './ExpiredInvitedMembership.module.scss';
import ExpiredInvitedMembershipVm from './ExpiredInvitedMembershipVm';
import queryString from 'query-string';

function ExpiredInvitedMembership() {
    
    let location = useLocation();
    const token = queryString.parse(location.search).token;

    useEffect(() => {
        ExpiredInvitedMembershipVm.load(token);
    }, []);

    return useObserver(() => (
        <div className={st.expiredInvitedMembership}>
            <div className={st.logoWrap}><a href='https://standby.kr/' className={st.logo}><img src='/images/logo_standby.svg?1'/></a></div>
            <div className={st.title}>관리자 초대 기간 만료</div>
            <div className={st.content}>
                관리자 초대기간이 만료되었어요. (보안상조치)<br/>
                관리자 등록이 필요하다면, 초대한 분에게 다시 초대 발송을<br/>
                요청해주세요.            
            </div>
            <div className={st.dateBox}>
                <div className={st.dateInfo}>
                    <span>초대일자</span>
                    <span></span>
                    <span>{ExpiredInvitedMembershipVm.invitedDate}</span>
                </div>
                <div className={st.dateInfo}>
                    <span>만료일자</span>
                    <span></span>
                    <span>{ExpiredInvitedMembershipVm.expiredDate}</span>
                </div>
            </div>
        </div>
    ));
}
export default ExpiredInvitedMembership;