import { useObserver } from 'mobx-react';
import vm from './Step1Vm';
import st from './Step1.module.scss';
import clsx from 'clsx';

interface Step1Props {
    preview ?: boolean,
    guest ?: boolean
}

function Step1({preview = false, guest = false} : Step1Props) {
    return useObserver(() => (
        <>
            {preview === false &&
                <div className={st.top}>
                    <span className={st.title}>기업용 인터넷·전화</span>
                    <button type="button" className={st.moveStep2} onClick={() => vm.moveStep2(guest)}>캐시백 가능 상품 확인하기</button>
                </div>
            }
            <div className={clsx(st.promotion, preview === true ? st.preview : null)}>
                {preview === false ?
                    <img src='/images/internetAssociatePromotion.png?1'/>
                    :
                    <img src='/images/internetAssociatePromotion_preview.png?1'/>
                }
                <div className={st.faq}>
                    <div className={st.title}>자주 묻는 질문</div>
                    <ul>
                        {vm.state.tab.map((tab, index) => (
                            <li key={index} className={tab.opened ? st.opened : st.closed} onClick={() => vm.toggleTab(index)}>
                                <div className={st.title}>{tab.title}</div>
                                {tab.opened === true && 
                                    <div className={st.content} dangerouslySetInnerHTML={ {__html: tab.content} }></div>
                                }
                            </li>                                
                        ))}
                    </ul>
                </div>
                <div className={st.notice}>
                    <div className={st.title}>유의사항</div>
                    <ul>
                        <li>개통 후 1년 이내 해지 시 사은품 반환의무에 따라 환수금이 발생해요. </li>
                        <li>캐시백 정책은 매월 달라질 수 있어요. 캐시백은 실제 개통시점의 정책에 따라 KT대리점에서 직접 지급해드려요.</li>
                    </ul>
                </div>
                {preview === false &&
                    <button type="button" className={st.moveStep2} onClick={() => vm.moveStep2(guest)}>
                        <span className={st.btnContent}>캐시백 가능 상품 확인하기</span>
                    </button>
                }
            </div>
        </>
    ));
}
export default Step1;