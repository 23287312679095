import { observable, runInAction } from 'mobx';

const invitedModel = observable({
    email: '',
    name: "",
    password: "",
    passwordCheck: "",
    countryCode: "82",
    phoneNumber: "",
    companyName: '',
    setInvitedAuthorizzation(data) {
        runInAction(() => {
            this.email = data.email || '';
            this.companyName = data.companyName || '';
        })
    }
});

export default invitedModel;
