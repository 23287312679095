import { useObserver } from 'mobx-react';
import { useEffect } from 'react';
import DateListVm from './DateListVm';
import qst from '@pages/Corp/Agenda/Step/AgendaQuestion/commonQuestion.module.scss';
import st from './DateList.module.scss';
import clsx from 'clsx';
import Select from '@standby/common-ui/components/atoms/Input/Select/Select';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import ToolTip from '@standby/common-ui/components/molecules/ToolTip/ToolTip';
import DatePicker from '@standby/common-ui/components/molecules/DatePicker/DatePicker';
import TimePicker from '@standby/common-ui/components/molecules/TimePicker/TimePicker';
import Icon from '@standby/common-ui/components/atoms/Icon/Icon';
import QuestionTooltip from '@standby/common-ui/components/molecules/QuestionTooltip/QuestionTooltip';
import josa, { josaType } from '@common/module/josa';

interface DataListProps {
    name: string;
}

function DateList(props: DataListProps) {
    useEffect(() => {
        DateListVm.dateList?.forEach((_, idx) => {
            DateListVm.blurDate(idx);
            DateListVm.blurTime(idx);
        })
    }, [DateListVm.dateList?.length]);

    return useObserver(() => (
        <div className={qst.questionForm}> 
            <div className={qst.qusetion}>
                <div className={qst.questionTitle}>{props.name !== "schedules" && '일자를 입력하세요.'}</div>
                {DateListVm.dateList?.map((item, idx) => {
                    return (
                        <div className={st.meetingDetailBox} key={idx} style={{minHeight: `${DateListVm.minHeight(idx, item.type, item.agendas)}`}}>
                            {(DateListVm.state.autoChangeIdxList.includes(idx) && DateListVm.state.toastMessageYn) && <div className={st.toastMessage}>정관, 상법에 맞게 날짜를 변경해드렸어요!</div>}
                            <input type="hidden" name={`${props.name}[${idx}].type`} value={item.type}/>
                            <div className={st.titleContent} data-type={item.type}>
                                <div className={st.title}>{DateListVm.displayTitle(item.type, item.title)}</div>
                                {(item.type === '주주총회_개최_대표결정' || item.type === '정기주주총회_개최_대표결정' || item.type === '대표결정') &&
                                    <ToolTip className={st.toolTip} iconType="question" tooltipContentStyle={{width:'225px'}}>
                                        이사가 2인 이하이기 때문에, 이사회 대신 대표님이 본점주소 변경, 지점설치 등을<br/>결정해요.
                                    </ToolTip>
                                }
                            </div>
                            <div className={st.info}>
                                <div className={st.detailInfo}>
                                    <div className={st.decision}>
                                        <div className={st.dateAndTime}>
                                            {DateListVm.displayType(idx)?.date && 
                                                <div className={st.date}>
                                                    {(item.type === '정기주주총회_소집통지' || item.type === '주주총회_소집통지') && 
                                                        <div className={st.dateGuide}>
                                                            상법, 회사 정관에 따라 {DateListVm.displayChangeDate}일 간격을 넣어드렸어요. (통지 당일 포함X)
                                                        </div>
                                                    }
                                                    <input type="hidden" name={`${props.name}[${idx}].decisionAt.year`} value={DateListVm.decisionAt(idx).year} />
                                                    <input type="hidden" name={`${props.name}[${idx}].decisionAt.month`} value={DateListVm.decisionAt(idx).month} />
                                                    <input type="hidden" name={`${props.name}[${idx}].decisionAt.day`} value={DateListVm.decisionAt(idx).day} />
                                                    <DatePicker disabled={item.uneditableDateYn} className={clsx(st.datePicker, DateListVm.highLightYn(idx) ? st.highLight : null)} name="decisionDate" selected={DateListVm.decisionDate(idx)} onChange={(e) => DateListVm.setDecisionDate(e, idx)} />
                                                    <div>
                                                        <Error name="decisionDate" value={String(DateListVm.decisionDate(idx))} errorCase={{
                                                            required: '날짜를 선택하세요.'
                                                        }}
                                                        />
                                                    </div>
                                                </div>
                                            }     
                                            {DateListVm.displayType(idx)?.time && 
                                                <div className={st.time}>
                                                    <input type="hidden" name={`${props.name}[${idx}].decisionAt.hour`} value={DateListVm.decisionAt(idx)?.hour || ""} />
                                                    <input type="hidden" name={`${props.name}[${idx}].decisionAt.minute`} value={DateListVm.decisionAt(idx)?.minute || ""} />
                                                    <TimePicker className={st.timePicker} name="decisionTime" selected={DateListVm.decisionTime(idx)} onChange={(e) => DateListVm.setDecisionTime(e, idx)}/>
                                                    <div>
                                                        <Error name="decisionTime" value={String(DateListVm.decisionTime(idx))} errorCase={{
                                                            required: '시간을 선택하세요.'
                                                        }}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {DateListVm.displayType(idx)?.location && 
                                        <div className={st.location}>
                                            <div className={st.title}>개최장소</div>
                                            <div>
                                                <Select className={st.locationSelect} name={`${props.name}[${idx}].의결장소Type`} value={DateListVm.의결장소Type(idx)} onChange={(e) => DateListVm.set의결장소Type(e, idx)} style={{width:'220px'}} size="small" errorText={<Error name={`${props.name}[${idx}].의결장소Type`} value={DateListVm.의결장소Type(idx)} errorCase={{
                                                    required: '개최 장소를 선택하세요.'
                                                }} />}>
                                                    <option value="">-</option>
                                                    <option value="본점 회의실">본점 회의실</option>
                                                    <option value="직접입력">직접입력</option>
                                                </Select>
                                                {DateListVm.의결장소Type(idx) === '직접입력' && 
                                                    <InputText className={st.place} name={`${props.name}[${idx}].place`} style={{width:'220px'}} value={DateListVm.place(idx)} onChange={(e) => DateListVm.setPlace(e, idx)} errorText={<Error name={`${props.name}[${idx}].place`} value={DateListVm.place(idx)} errorCase={{
                                                        required: '개최 장소를 입력하세요.'
                                                    }}/>}/>
                                                }
                                            </div>
                                        </div>
                                    }    
                                </div>
                                {DateListVm.displayExplainBox(idx) && 
                                    <div className={st.detailInfoExplain}> 
                                        {DateListVm.소집통지Explain(item.type) && 
                                            <div className={st.explain}>
                                                <Icon type="exclamation" className={st.icon}/>소집통지는 생략 (주주 전원의 동의)
                                            </div>
                                        }
                                        {(DateListVm.hasRegistrationMattersYn(idx) && DateListVm.certificateYn(idx) === false) && 
                                            <div className={st.explain}>
                                                <Icon type="exclamation" className={st.icon}/><span className={st.bold}>등기변경일</span>은 {DateListVm.displayExplinType(item.type)} <span className={st.bold}>같은 날 또는 이후</span>로 가능
                                            </div>
                                        }
                                        {(DateListVm.hasRegistrationMattersYn(idx) && DateListVm.certificateYn(idx) && DateListVm.가수금증자Yn === false) && 
                                        <>
                                            <div className={clsx(st.explain, st.important)}>
                                                <span className={st.bold}>{josa(DateListVm.displayExplinType(item.type)?.slice(0, -1) || "", josaType.은는)} 잔고(잔액)증명일자와 같은 날</span>로 선택하세요.
                                            </div>
                                            <div style={{marginTop: '5px', marginBottom: ''}}>
                                                <QuestionTooltip title="잔고(잔액)증명일자란?">
                                                    <div className={st.tooltipContent}>
                                                        <img src='/images/certificate_example.png'/>
                                                        <div className={st.tooltipExplain}>잔고(잔액)증명서 형태는 은행마다 다를 수 있습니다.</div>
                                                    </div>
                                                </QuestionTooltip>
                                            </div>
                                        </>
                                        }
                                        {(DateListVm.certificateYn(idx) && DateListVm.가수금증자Yn) && 
                                            <div className={st.explain}>
                                                <Icon type="exclamation" className={st.icon}/><span className={st.bold}>{josa(DateListVm.displayExplinType(item.type)?.slice(0, -1) || "", josaType.은는)}</span> <span className={st.bold}>신주발행</span>을 희망하는 날 <span className={st.bold}>전날</span>로 선택하세요.
                                            </div>
                                        }
                                        {DateListVm.신주발행통지Explain(idx) && 
                                            <div className={st.explain}>
                                                <Icon type="exclamation" className={st.icon}/>신주발행통지는 생략 (주주 전원의 동의)
                                            </div>
                                        }
                                        {DateListVm.신주Explain(idx) && 
                                            <div className={st.explain}>
                                                <Icon type="exclamation" className={st.icon}/>통지일로부터 14일 후에 신주를 발행할 수 있어요. (통지 당일 포함X)
                                            </div>
                                        }
                                        {DateListVm.액면금분할합병Explain(item.agendas) && 
                                            <div className={st.explain}>
                                                <Icon type="exclamation" className={st.icon}/>액면금 분할/합병 등기는 1달 공고진행 후 등기가능
                                            </div>
                                        }
                                    </div>
                                }
                            </div> 
                            {(item?.agendas && item?.agendas.length !== 0) && 
                                <div className={clsx(st.agendaList, item.agendas.length > 3 ? st.more : null)}>
                                    <ul>
                                        {item.agendas.map((agenda, index) => {
                                            if(index < 3) {
                                                return (
                                                    <li key={index} className={st.agenda}><span>{index+1}.</span>{agenda.value}</li>
                                                )
                                            } else {
                                                if(DateListVm.state.agendaOpenYn) {
                                                    return (
                                                        <li key={index} className={st.agenda}><span>{index+1}.</span>{agenda.value}</li>
                                                    )
                                                }
                                            }
                                            
                                        })}
                                    </ul>  
                                    {item.agendas.length > 3 && 
                                        <button type="button" className={clsx(st.moreView, DateListVm.state.agendaOpenYn ? st.open : null)} onClick={() => DateListVm.moreView()}>{DateListVm.state.agendaOpenYn ? '접기' : '더보기'}</button>
                                    }
                                </div>
                            }
                        </div>
                    )
                })}
                {DateListVm.onlyRepresentativeChangedAddressYn &&  <div className={st.representativeExplain}>대표자주소변경 안건도 함께 진행해요. (전입일자 기준으로 처리)</div>}
            </div>
        </div>
    ));
}

export default DateList;