import corpModel from '@model/Corp/corpModel';
class RegisteringModalVm {
    get companyName () {
        return corpModel.company.companyName;
    }
    get demoCorpId() {
        let demoId = '';
        corpModel.companies?.forEach((item, idx) => {
            if(item.isDemoYn) {
                demoId = item.companyId;
            }
        })
        return demoId;
    }
}
export default new RegisteringModalVm();