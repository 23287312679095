import { useObserver } from 'mobx-react';
import type { SectionType, I주주동의_신주발행, NewStockAcquirerInfo } from '@src/model/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalModel.interface';
import vm from './NewStockIssueVm';
import st from './NewStockIssue.module.scss';
import bst from '@pages/Corp/Agenda/Step/FinalCheck/BeforeFinal/BeforeFinal.module.scss';
import clsx from 'clsx';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import QuestionTooltip from '@standby/common-ui/components/molecules/QuestionTooltip/QuestionTooltip';
import Table from '@standby/common-ui/components/atoms/Table/Table';
import ToolTip from '@standby/common-ui/components/molecules/ToolTip/ToolTip';
import DownloadBtn from '@standby/common-ui/components/atoms/Button/DownloadBtn/DownloadBtn';
import { addCommas } from '@common/module/replaceNumber';

interface NewStockIssueProps {
    index: number;
    type: SectionType;
    info: I주주동의_신주발행;
    agendaProgressId: string;
}

function NewStockIssue(props: NewStockIssueProps) {
    return useObserver(() => (
        <>
            <div className={bst.row}>
                <div className={clsx(bst.title, bst.section3)}>{props.index+1}. 주주동의 <span className={bst.light}>(신주발행)</span></div>
                <div className={bst.content}>
                    <div className={bst.contentTitleBox}>바로 신주를 발행하기 위해 주주 동의를 받으세요. <span className={bst.light}>(기간단축 동의)</span></div>
                    <QuestionTooltip title="주주 동의가 필요한 이유" style={{width:'460px'}}>
                        <div className={bst.tooltipContent}>
                            <div className={bst.qna}>
                                <div className={bst.qnaTitle}>원칙상 신주는 주주에게 발행해야 해요.</div>
                                <div className={bst.qnaContent}>
                                    <ul>
                                        <li>주주가 가진 주식수에 따라 신주를 배정받을 권리가 있어요. (상법 제418조 제1항)</li>
                                    </ul>
                                </div>
                            </div>
                            <div className={bst.qna}>
                                <div className={bst.qnaTitle}>다만 정당한 목적이 있는 경우 제3자에게 발행할 수 있어요.</div>
                                <div className={bst.qnaContent}>
                                    <ul>
                                        <li>신기술 도입, 재무구조 개선 등 경영상 목적을 달성하기 위해 필요한 경우에는 제3자에게 발행이 가능해요. (상법 제418조 제2항)</li>
                                    </ul>
                                </div>
                            </div>
                            <div className={bst.qna}>
                                <div className={bst.qnaTitle}>이 경우 주주에게 제3자에게 신주발행을 한다는 사실을 통지 또는 공고해야 해요.</div>
                                <div className={bst.qnaContent}>
                                    <ul>
                                        <li>신주발행을 통해 주주의 지분율이 낮아지므로, 최소 14일 전에 회사는 주주에게 신주발행 사실을 알려주도록 상법이 정하고 있어요. (상법 제418조 제4항)</li>
                                    </ul>
                                </div>
                            </div>
                            <div className={bst.qna}>
                                <div className={bst.qnaTitle}>다만 모든 주주가 동의하면 통지/공고를 생략하고 2주를 기다리지 않아도 돼요.</div>
                                <div className={bst.qnaContent}>
                                    <ul>
                                        <li>통지/공고는 주주의 권리를 보호하기 위한 것이니, 주주들의 동의하면 절차를 생략할 수 있어요.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </QuestionTooltip>
                    <Table className={clsx(bst.table, st.table)}>
                        <colgroup>
                            <col width="190" />
                            <col width="89" />
                            <col width="84" />
                            <col width="110" />
                            <col width="152" />
                            <col width="104" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>주주명</th>
                                <th>동의여부</th>
                                <th>동의일자</th>
                                <th style={{textAlign:'center'}} className={clsx(bst.download, props.info?.completed ? bst.active : bst.disabled)}>
                                    <span>다운로드</span>
                                    {props.info?.completed && 
                                        <button type="button" className={bst.allDownloadBtn} onClick={() => vm.allDownload(props.agendaProgressId, props.type)}>모두 다운로드</button>
                                    }
                                </th>
                                <th style={{textAlign:'left'}}>주식종류</th>
                                <th>주식수</th>
                                <th>지분율</th>
                            </tr>
                        </thead>
                        {props.info?.shareholders?.map((shareholder, idx) => {
                            if((idx < 6 || (idx > 5 && vm.state.moreViewYn))) {
                                return (
                                    <tbody key={idx}>
                                        {shareholder.stocks.map((stock, idx2) => {
                                            return (
                                                <tr key={idx2}>
                                                    {idx2 === 0 &&
                                                    <>
                                                        <td rowSpan={shareholder.stocks.length}><span>{idx+1}</span>{shareholder.name}</td>
                                                        <td rowSpan={shareholder.stocks.length}>
                                                            <div className={vm.status(shareholder.status)?._className}>
                                                                {vm.status(shareholder.status)?.text}
                                                                {shareholder.status === "발송실패" && 
                                                                    <ToolTip className={bst.tooltip}>
                                                                        일시적 오류가 발생하여 발송에 실패했어요. 이메일, 휴대번호가 정확한지 확인해보고, 재발송을 해주세요. 만약 재발송에도 실패한다면 오른쪽 아래 채팅버튼을 눌러 문의를 남겨주세요.
                                                                    </ToolTip>
                                                                }
                                                                {shareholder.status === "발송실패_알림톡" && 
                                                                    <ToolTip className={bst.tooltip}>
                                                                        일시적으로 오류가 발생하여 알림톡 발송에 실패했어요. (이메일은 발송성공) 휴대번호가 정확한지 확인해보고, 재발송을 해주세요. 만약 재발송에도 실패한다면 오른쪽 아래 채팅버튼을 눌러 문의를 남겨주세요.
                                                                    </ToolTip>
                                                                }
                                                                {shareholder.status === "발송실패_메일" && 
                                                                    <ToolTip className={bst.tooltip}>
                                                                        일시적 오류가 발생하여 메일 발송에 실패했어요. (알림톡은 발송성공) 이메일 주소가 정확한지 확인해보고, 재발송을 해주세요. 만약 재발송에도 실패한다면 오른쪽 아래 채팅버튼을 눌러 문의를 남겨주세요.
                                                                    </ToolTip>
                                                                }
                                                            </div>
                                                        </td>
                                                        <td rowSpan={shareholder.stocks.length}>{shareholder.completedDate}</td>
                                                        <td rowSpan={shareholder.stocks.length}>
                                                            <DownloadBtn disabled={!shareholder.downloadUrl} onClick={() => vm.download(shareholder.downloadUrl)}>동의서 다운로드</DownloadBtn>
                                                        </td>
                                                    </>
                                                    }
                                                    <td className={bst.stockName}>{stock.name}</td>
                                                    <td className={bst.stockNumber}>{addCommas(stock.number)} 주</td>
                                                    <td className={bst.stockRate}>{stock.rate} %</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                )
                            }                        
                        })}
                    </Table>
                    {props.info?.shareholders?.length > 6 && 
                        <button type="button" className={bst.moreViewBtn} onClick={() => vm.moreView()}><span className={vm.state.moreViewYn ? bst.open : ""}>{vm.state.moreViewYn ? '접기' : '더보기'}</span></button>
                    }
                    <div className={bst.btnArea}>
                        <SquareBtn className={bst.btn} onClick={() => vm.openModal(props.agendaProgressId, vm.newStockAcquirerInfo as NewStockAcquirerInfo)}>신주발행 동의 요청</SquareBtn>
                    </div>
                </div>
            </div>
        </>
    ));
}
export default NewStockIssue;