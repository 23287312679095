import { useObserver } from 'mobx-react';
import vm from './DecisionVm';
import st from './Decision.module.scss';
import clsx from 'clsx';

function Decision() {
    return useObserver(() => (
        <div className={st.wrap}>
            <div className={st.title}>주주총회 이사회</div>
            <ul className={st.decisionList}>
                <li>
                    <div className={st.title}>정기주주총회</div>
                    {vm.aoaRegistrationYn ? 
                        <div className={st.content}>
                            <div>매년 {vm.schedule?.정기_주주총회}에 1번 <span style={{fontWeight:"400"}}>개최</span></div>
                        </div> :
                        <div className={clsx(st.content, st.empty)}>
                            <div>정보 입력중</div>
                        </div>
                    }
                </li>
                <li>
                    <div className={st.title}>임시주주총회</div>
                    <div className={st.content}>
                        <div>필요할 때<span style={{fontWeight:"400"}}>마다 개최</span></div>
                    </div>
                </li>
                <li>
                    <div className={st.title}>이사회</div>
                    {vm.schedule?.이사회 ? 
                        <div className={st.content}>
                            <div>이사회 있음</div>
                        </div>
                        :
                        <div className={st.content}>
                            <div>이사회 없음</div>
                            <div className={st.subText}>* 이사가 3명부터 이사회가 생겨요.</div>
                        </div>
                    }
                </li>
            </ul>
        </div>
    ));
}
export default Decision;