import onlineAcceptancePopupModel from "@model/Corp/Popup/OnlineAcceptancePopup/onlineAcceptancePopupModel";
import { runInAction } from "mobx";

class Step1Vm {
    next(page: 'Direct' | 'Document') {
        runInAction(() => {
            onlineAcceptancePopupModel.nowStep = "2";
            onlineAcceptancePopupModel.nowPage = page;
        })
    }   
}

export default new Step1Vm();