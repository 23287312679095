import { useObserver } from 'mobx-react';
import { Link } from "react-router-dom";
import vm from './AoaVm';
import st from './Aoa.module.scss';
import ToolTip from '@standby/common-ui/components/molecules/ToolTip/ToolTip';
import clsx from 'clsx';
function Aoa() {
    return useObserver(() => (
        <div className={st.wrap}>
            <div className={st.title}>정관</div>
            <Link to={`/corp/articlesOfAssociation?corp=${vm.id}`} className={st.moreView} type='button'>더보기</Link>
            <button type='button' className={st.openModalBtn} onClick={vm.agendaLinkModal}>변경하기</button>
            {vm.aoaRegistrationYn ? 
                <div className={st.aoaInfoBox}>
                    <div className={st.aoaContent} style={{"backgroundImage":'url(/images/사업연도_icon.svg)'}}>
                        <div>사업연도는 <span className={st.stateBold}>{vm.aoa?.contents?.결산기간}</span></div>
                        <ToolTip iconType="question" leftOrRight="right">
                            사업연도란 회사의 1년간의 수입과 비용을 계산하여 정리하는 회계기간을 말해요. 사업연도는 1년 단위로 정하고, 정관으로 미리 정해두어야 해요.
                        </ToolTip>
                    </div>
                    <div className={st.aoaContent} style={{"backgroundImage":'url(/images/신주발행_icon.svg)'}}>
                        <div>
                            <span className={st.bold}>제3자 신주발행</span> 근거규정 <span className={clsx(st.stateBold, vm.aoa?.contents?.제3자_신주발행규정Yn ? st.yes : st.no)}>{vm.aoa?.contents?.제3자_신주발행규정Yn ? '있음': '없음'}</span>
                        </div>
                        <ToolTip iconType="question" leftOrRight="right">
                            상법상 신주는 주주에게 지분율에 맞게 발행하는 것이 원칙이에요. 만약 제3자에게 신주를 발행하는 경우, 반드시 정관상 "제3자에게도 특별한 경우 신주발행을 할 수 있다."는 내용의 근거규정이 있어야 해요. (상법 제418조 제2항)
                        </ToolTip>
                    </div>
                    <div className={st.aoaContent} style={{"backgroundImage":'url(/images/우선주_발행_icon.svg)'}}>
                        <div>
                            <span className={st.bold}>우선주 발행</span> 근거규정 <span className={clsx(st.stateBold, vm.aoa?.contents?.우선주_발행_근거규정Yn ? st.yes : st.no)}>{vm.aoa?.contents?.우선주_발행_근거규정Yn ? '있음': '없음'}</span>
                        </div>
                        <ToolTip iconType="question" leftOrRight="right">
                            상법상 우선주를 발행하려면 정관에 어떤 내용의 우선주를 얼마나 발행할 것인지 근거규정이 있어야 해요. (상법 제344조 제1항)
                        </ToolTip>
                    </div>
                    <div className={st.aoaContent} style={{"backgroundImage":'url(/images/stockoption_icon.svg)'}}>
                        <div>
                            <span className={st.bold}>스톡옵션</span> 규정 <span className={clsx(st.stateBold, vm.displayStockOption?.bool ? st.yes : st.no)}>{vm.displayStockOption?.bool ? '있음' : '없음'}</span>{vm.displayStockOption?.text && <span className={st.stockOptionTag}>{vm.displayStockOption?.text}</span>}
                        </div>
                        <ToolTip iconType="question" leftOrRight="right">
                            임직원에게 스톡옵션(=주식매수선택권)을 부여하려면 정관에 근거규정이 있어야 해요.
                        </ToolTip>
                    </div>
                    <div className={st.aoaContent} style={{"backgroundImage":'url(/images/제3자_icon.svg)'}}>
                        <div>
                            <span className={st.bold}>제3자 주식양도제한</span> 규정 <span className={clsx(st.stateBold, vm.aoa?.contents?.제3자_주식_양도_제한Yn ? st.yes : st.no)}>{vm.aoa?.contents?.제3자_주식_양도_제한Yn ? '있음': '없음'}</span>
                        </div>
                        <ToolTip iconType="question" leftOrRight="right">
                            주주는 원래 마음대로 주식을 남에게 팔 수 있어요. 다만 정관에 제3자에게 주식을 양도하는 경우, 이사회 또는 주주총회의 승인을 얻도록 제한규정을 넣어둘 수 있어요. 이 경우 주주는 그 정관규정을 지켜야 해요.
                        </ToolTip>
                    </div>
                </div> : 
                <div className={st.empty}>
                    정관 등록을 마친 후, 확인 가능해요!<br/>(최대 1영업일)
                </div>
            }
        </div>
    ));
}
export default Aoa;