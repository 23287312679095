import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { useEffect } from 'react';
import vm from './BookKeepingContractVm';
import st from './BookKeepingContract.module.scss';
import Check from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import FirstPage from './FirstPage/FirstPage';
import ProgressPage from './ProgressPage/ProgressPage';

function BookKeepingContract() {

    useEffect(() => {
        vm.load();
    }, []);

    return useObserver(() => (
        <>
            <div className={st.title}>기장계약 with 회계법인</div>
            {vm.state.loadYn && 
            <>
                {(vm.pageType === 'INIT_HELPME' || vm.pageType === 'INIT_OTHER') ? 
                    <FirstPage />
                    :
                    <ProgressPage />
                }
            </>
            }
        </>
    ));
}
export default BookKeepingContract;