import beforeFinalService from "@service/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalService";
import beforeFinalModel from "@model/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalModel";
import st from './ValidationModal.module.scss';
import portalModel from "@model/portalModel";
import { runInAction } from "mobx";
import loadingModel from '@model/loadingModel';

class ValidationModalVm {
    async submit(agendaProgressId: string) {
        try {
            loadingModel.showYn = true;
            await new Promise(resolve => setTimeout(resolve, 300)); //로딩 애니메이션 딜레이
            await beforeFinalService.submit(agendaProgressId);
            loadingModel.showYn = false;
        } catch(err) {
            loadingModel.showYn = false;
        }        
    }
    modalClose() {
        runInAction(() => {
            portalModel.title = '';
            portalModel.content = null;
            portalModel.closeBtn = true;
            portalModel.radius = false;
        })
    }
    get unValidatedList() {
        return beforeFinalModel.submitValidation.unValidatedList;
    }
    displayType(type: string | null) {
        switch(type) {
            case '주주동의_신주발행':
                return <span>주주동의<span className={st.type}> (신주발행)</span></span>;
            case '주주동의_주주총회':
                return <span>주주동의<span className={st.type}> (주주총회)</span></span>;
            case '주주동의_주주총회_신주발행':
                return <span>주주동의<span className={st.type}> (주주총회, 신주발행)</span></span>;
            case '소집통지_온라인':
                return <span>소집통지<span className={st.type}> (온라인)</span></span>;
            case '신주통지_온라인':
                return <span>신주통지<span className={st.type}> (온라인)</span></span>;
        }
    }
    displayStatus(status: string | null) {
        switch(status) {
            case 'SEND':
                return '발송전';
            case 'AGREE':
                return '미동의';
        }
    }
}

export default new ValidationModalVm();