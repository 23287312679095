import { useObserver } from 'mobx-react';
import { useLocation } from "react-router-dom";
import React, { useEffect } from 'react';
import AgendaVm from './AgendaVm';
import queryString from 'query-string';
import AgendaQuestion from '../AgendaQuestion/AgendaQuestion';
import AgendaInput from './AgendaInput/AgendaInput';
import PageTitle from '@commonComponents/PageTitle/PageTitle';
import st from './Agenda.module.scss';
import clsx from 'clsx';
import Check from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import ToolTip from '@standby/common-ui/components/molecules/ToolTip/ToolTip';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import { addCommas } from '@common/module/replaceNumber';
import { _alert } from "@model/dialogModel";

interface AgendaSelectCheckboxProps {
    item : {
        selectedYn : boolean,
        type : string,
        title : string,
        registrationRequiredYn : boolean,
        의결기관 : string
    },
    type : string,
    index : number
}

const AgendaSelectCheckbox = React.memo(function (props : AgendaSelectCheckboxProps) {
    return useObserver(() => (
        <div className={st.checkItem}>
            {props.item.selectedYn && <input type="hidden" name={`selectedAgendaTypes`} value={props.item.type}></input>}
            <Check checked={props.item.selectedYn} onChange={(e) => AgendaVm.agendaTypeSelect(e, props.type, props.index)} disabled={AgendaVm.disabledAgenda(props.item.type)} className={AgendaVm.disabledAgenda(props.item.type) ? st.disabeldAgenda : undefined}>
                <div className={st.checkTitle}>
                    {AgendaVm.title(props.item.type, props.item.title)}
                    {(props.item.type === "스톡옵션_부여" || props.item.type === "스톡옵션_취소" || props.item.type === "스톡옵션_행사") && 
                        <div className={st.badge}>출시예정</div>
                    }
                    {props.item.type === "등기가_불필요한_정관변경" && 
                        <ToolTip className={st.toolTip} upAndDown="up" leftOrRight="right" tooltipContentStyle={{width:'250px'}} iconType="question">
                            정관변경은 등기가 필요한 경우와 불필요한 경우 가 있어요. 등기가 필요한 정관변경의 경우, 다른 안건을 선택하면 자동으로 정관변경 안건을 넣어 드려요. 등기가 불필요한 정관변경인 경우에만 이 안건을 선택하세요.
                        </ToolTip>
                    }
                </div>
                <div className={st.checkboxSubInfo}>
                    <span>{props.item?.registrationRequiredYn ? "등기 필요" : "등기 불필요"}</span>
                    {props.item?.의결기관 && 
                        <span>{props.item?.의결기관}</span>
                    }
                </div>
                {(props.item.selectedYn && (props.item.type === "주주총회_안건_자유입력" || props.item.type === "이사회_안건_자유입력")) && 
                    <div className={st.freeAgendaCountBox}>
                        <div>자유안건 개수</div>
                        <div className={st.btnArea}>
                            <button type="button" className={clsx(st.minusBtn, AgendaVm.agendaTypeCount(props.item.type) === 1 && st.disabled)} onClick={() => AgendaVm.setMinusBtn(props.item.type)}/>
                            {AgendaVm.agendaTypeCount(props.item.type)}
                            <button type="button" className={clsx(st.plusBtn, AgendaVm.agendaTypeCount(props.item.type) === 5 && st.disabled)} onClick={() => AgendaVm.setPlusBtn(props.item.type)}/>
                        </div>
                    </div>
                }
            </Check>
        </div>
    ));
})

function Agenda() {
    let location = useLocation();
    const agendaProgressId = queryString.parse(location.search).agendaProgressId as string | null;
    const agendaType = queryString.parse(location.search).agendaType as string | null;
    useEffect(() => {
        AgendaVm.load(agendaProgressId);
    }, [])
    useEffect(() => {
        if(AgendaVm.agenda) {
            for (const key in AgendaVm.agenda) {
                AgendaVm.agenda[key].forEach((agenda) => {
                    if(agenda.type === agendaType) {
                        agenda.selectedYn = true
                    }
                })
            }
        }
    }, [AgendaVm.agenda])
    useEffect(() => {
        if(AgendaVm.selectedAgendas.length && AgendaVm.isAllSaved) {
            window.scrollTo({ top:document.body.scrollHeight, behavior: 'smooth' });
        }
    }, [AgendaVm.selectedAgendas.length])
    return useObserver(() => (
        <>
            <PageTitle style><span className={st.pageTitle}>안건 선택</span></PageTitle>
            {AgendaVm.agenda &&
                <div className={st.selectAgenda}>
                    <form onSubmit={(e) => AgendaVm.submitSelectAgenda(e, agendaProgressId)}>
                        <div className={st.content}>
                            <div className={st.vertical} style={{width:"245px"}}>
                                <div className={clsx(st.checkListItem, AgendaVm.highlightText('정기_주주총회') ? st.highlight : null)} style={{height:"270px"}}>
                                    {AgendaVm.highlightText('정기_주주총회') && 
                                        <div className={st.highlightText} dangerouslySetInnerHTML={{ __html: AgendaVm.highlightText('정기_주주총회') as string }}></div>
                                    }
                                    <div className={st.title} style={{backgroundImage:'url(/images/정기_주주총회_Icon_0402.svg)'}}>정기주주총회</div>
                                    <div className={st.tip}>정기주주총회 진행 시, 3가지 안건을 모두 포함하세요.</div>
                                    <div className={st.checkList}>
                                    {AgendaVm.agenda.정기_주주총회.map((item, index) => (
                                        <AgendaSelectCheckbox item={item} index={index} key={index} type={'정기_주주총회'}/>
                                    ))}
                                    </div>
                                    <div className={st.subLink}>
                                        <a href='https://standby.kr/blog/article/정기주주총회의모든것/' target='_blank' className={st.aLink}>정기주주총회의 모든 것</a>
                                    </div>
                                </div>
                                <div className={clsx(st.checkListItem, AgendaVm.highlightText('주소_및_임원_변경') ? st.highlight : null)} style={{height:"200px"}}>
                                    {AgendaVm.highlightText('주소_및_임원_변경') && 
                                        <div className={st.highlightText} dangerouslySetInnerHTML={{ __html: AgendaVm.highlightText('주소_및_임원_변경') as string }}></div>
                                    }
                                    <div className={st.title} style={{"backgroundImage":'url(/images/주소_및_임원_변경_Icon_0402.svg)'}}>임원 &middot; 주소 변경</div>
                                    <div className={st.checkList}>
                                    {AgendaVm.agenda.주소_및_임원_변경.map((item, index) => (
                                        <AgendaSelectCheckbox item={item} index={index} key={index} type={'주소_및_임원_변경'}/>
                                    ))}
                                    </div>
                                </div>
                                <div className={clsx(st.checkListItem, AgendaVm.highlightText('스톡옵션') ? st.highlight : null)} style={{height:"250px"}}>
                                    {AgendaVm.highlightText('스톡옵션') && 
                                        <div className={st.highlightText} dangerouslySetInnerHTML={{ __html: AgendaVm.highlightText('스톡옵션') as string }}></div>
                                    }
                                    <div className={st.title} style={{"backgroundImage":'url(/images/스톡옵션_Icon_0402.svg)'}}>스톡옵션</div>
                                    <div className={st.checkList}>
                                    {AgendaVm.agenda.스톡옵션.map((item, index) => (
                                        <AgendaSelectCheckbox item={item} index={index} key={index} type={'스톡옵션'}/>
                                    ))}
                                    </div>
                                </div>
                            </div>
                            <div className={st.vertical} style={{width:"245px"}}>
                                <div className={clsx(st.checkListItem, AgendaVm.highlightText('신설기업_추천_안건') ? st.highlight : null)} style={{height:"116px"}}>
                                    {AgendaVm.highlightText('신설기업_추천_안건') && 
                                        <div className={st.highlightText} dangerouslySetInnerHTML={{ __html: AgendaVm.highlightText('신설기업_추천_안건') as string }}></div>
                                    }
                                    <div className={st.title} style={{"backgroundImage":'url(/images/신설기업_추천_안건_Icon.svg)'}}>신설기업 추천 안건</div>
                                    <div className={st.checkList}>
                                    {AgendaVm.agenda.신설기업_추천_안건.map((item, index) => (
                                        <AgendaSelectCheckbox item={item} index={index} key={index} type={'신설기업_추천_안건'}/>
                                    ))}
                                    </div>
                                </div>
                                <div className={clsx(st.checkListItem, AgendaVm.highlightText('상호_목적_공고방법') ? st.highlight : null)} style={{height:"200px"}}>
                                    {AgendaVm.highlightText('상호_목적_공고방법') && 
                                        <div className={st.highlightText} dangerouslySetInnerHTML={{ __html: AgendaVm.highlightText('상호_목적_공고방법') as string }}></div>
                                    }
                                    <div className={st.title} style={{"backgroundImage":'url(/images/상호_목적_공고방법_Icon_0402.svg)'}}>상호 &middot; 목적 &middot; 공고방법</div>
                                    <div className={st.checkList}>
                                    {AgendaVm.agenda.상호_목적_공고방법.map((item, index) => (
                                        <AgendaSelectCheckbox item={item} index={index} key={index} type={'상호_목적_공고방법'}/>
                                    ))}
                                    </div>
                                </div>
                                <div className={clsx(st.checkListItem, AgendaVm.highlightText('규정승인') ? st.highlight : null)} style={{height:"200px"}}>
                                    {AgendaVm.highlightText('규정승인') && 
                                        <div className={st.highlightText} dangerouslySetInnerHTML={{ __html: AgendaVm.highlightText('규정승인') as string }}></div>
                                    }
                                    <div className={st.title} style={{"backgroundImage":'url(/images/규정승인_Icon_0402.svg)'}}>규정 승인</div>
                                    <div className={st.checkList}>
                                    {AgendaVm.agenda.규정승인.map((item, index) => (
                                        <AgendaSelectCheckbox item={item} index={index} key={index} type={'규정승인'}/>
                                    ))}
                                    </div>
                                </div>
                                <div className={clsx(st.checkListItem, AgendaVm.highlightText('지점') ? st.highlight : null)} style={{height:"200px"}}>
                                    {AgendaVm.highlightText('지점') && 
                                        <div className={st.highlightText} dangerouslySetInnerHTML={{ __html: AgendaVm.highlightText('지점') as string }}></div>
                                    }
                                    <div className={st.title} style={{"backgroundImage":'url(/images/지점_Icon_0402.svg)'}}>지점</div>
                                    <div className={st.checkList}>
                                    {AgendaVm.agenda.지점.map((item, index) => (
                                        <AgendaSelectCheckbox item={item} index={index} key={index} type={'지점'}/>
                                    ))}
                                    </div>
                                </div>
                            </div>
                            <div className={st.vertical} style={{width:"512px"}}>
                                <div className={st.row}>
                                    <div className={st.infoItem}>
                                        <div className={clsx(st.info, st.capital)}>
                                            <div className={st.title}>
                                                자본금<span className={st.basicInfo}>{addCommas(AgendaVm.capital)} 원</span>
                                            </div>
                                            <div className={st.modifiableInfo}>자본금 10억 원 {AgendaVm.capital < 1000000000 ? '미만' : '이상'}</div>
                                            <div className={st.modifiableInfoSummary}>
                                                <span className={clsx(st.text, AgendaVm.capital < 1000000000 ? st.iconTrue : null)}>
                                                    {AgendaVm.capital < 1000000000 ? '등기절차 간소화 혜택' : '소집통지 절차준수 필수'}
                                                </span>
                                            </div>
                                        </div>
                                        <div className={st.subLink}>
                                            {AgendaVm.capital < 1000000000 ? 
                                                <a href='https://standby.kr/blog/article/자본금10억미만법인혜택/' target='_blank' className={st.aLink}>절차 간소화 혜택 더 알아보기</a>
                                                :
                                                <a href='https://standby.kr/blog/article/주주총회_소집통지_이유/' target='_blank' className={st.aLink}>주주총회 소집통지, 반드시 해주세요!</a>
                                            }
                                        </div>
                                    </div>
                                    <div className={st.infoItem}>
                                        <div className={clsx(st.info, st.executives)}>
                                            <div className={st.title}>
                                                임원<span className={st.basicInfo}>이사 {AgendaVm.totalNumOf_이사} 명{AgendaVm.totalNumOf_감사 !== 0 ? ` , 감사 ${AgendaVm.totalNumOf_감사} 명` : ''}</span>
                                            </div>
                                            {AgendaVm.totalNumOf_이사 < 3 ?
                                                <div className={st.modifiableInfo}>이사 2명 이하</div>
                                                :
                                                <div className={st.modifiableInfo}>이사 3명 이상</div>
                                            }
                                            <div className={st.modifiableInfoSummary}>
                                                <span className={clsx(st.text, AgendaVm.totalNumOf_이사 < 3 ? st.iconFalse : st.iconTrue)}>이사회 존재</span>
                                            </div>
                                        </div>
                                        <div className={st.subLink}>
                                            <a href='https://standby.kr/blog/article/이사2명_3명이상_장단점/' target='_blank' className={st.aLink}>이사 2명 vs 3명↑, 장단점 알아보기</a>
                                        </div>
                                    </div>
                                </div>
                                <div className={clsx(st.checkListItem, AgendaVm.highlightText('주식_액면금') ? st.highlight : null)} style={{height:"196px", marginTop:"16px"}}>
                                    {AgendaVm.highlightText('주식_액면금') && 
                                            <div className={st.highlightText} dangerouslySetInnerHTML={{ __html: AgendaVm.highlightText('주식_액면금') as string }}></div>
                                    }
                                    <div className={st.title} style={{"backgroundImage":'url(/images/주식_액면금_Icon.svg)'}}>주식, 액면금</div>
                                    <div className={clsx(st.checkList, st.multiRow)}>
                                    {AgendaVm.agenda.주식_액면금.map((item, index) => (
                                        <AgendaSelectCheckbox item={item} index={index} key={index} type={'주식_액면금'}/>
                                    ))}
                                    </div>
                                </div>
                                <div className={clsx(st.checkListItem, AgendaVm.highlightText('일반안건') ? st.highlight : null)} style={{height:"324px"}}>
                                    {AgendaVm.highlightText('일반안건') && 
                                            <div className={st.highlightText} dangerouslySetInnerHTML={{ __html: AgendaVm.highlightText('일반안건') as string }}></div>
                                    }
                                    <div className={st.title} style={{"backgroundImage":'url(/images/일반안건_Icon.svg)'}}>일반안건</div>
                                    <div className={clsx(st.checkList, st.multiRow)}>
                                    {AgendaVm.agenda.일반안건.map((item, index) => (
                                        <AgendaSelectCheckbox item={item} index={index} key={index} type={'일반안건'}/>
                                    ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={st.btnArea}>
                            <SquareBtn type="submit" className={st.contentSubmitBtn}>안건 {AgendaVm.selectedAgendaCount}개 선택</SquareBtn>
                        </div>
                    </form>
                </div>
            }
            {AgendaVm.selectedAgendas.length !== 0 && 
                <div className={st.agendaInfoInputs} style={{marginBottom : AgendaVm.isAllSaved ? '40px' : '400px' }}>
                    <div className={st.agendaInputsTitle}>
                        정보입력이 필요한 안건
                        <ToolTip upAndDown="down" leftOrRight="left" tooltipContentStyle={{width:'300px'}} iconType="question" className={st.toolTip}>
                            주주총회, 이사회 절차진행에 필요한 최소한의 정보만 입력하면 돼요. 다만 자유롭게 내용을 정할 수 있는 안건은 전체 내용을 다 입력해야 돼요.
                        </ToolTip>
                    </div>
                    {AgendaVm.selectedAgendas.map((selectedAgenda, index) => (
                        <React.Fragment key={index}>
                            {
                                selectedAgenda.savedDataYn === true || AgendaVm.state.opened === index ? 
                                    <AgendaQuestion pageType="안건질문" selectedAgenda={selectedAgenda} isAllSaved={AgendaVm.isAllSaved} agendaIndex={index} opened={AgendaVm.state.opened === index} changeOpened={() => AgendaVm.changeOpened(index)} openFirst={() => AgendaVm.openFirst()} step="AGENDA" agendaProgressId={agendaProgressId} inputComponent={<AgendaInput agendaIndex={index} agenda={selectedAgenda} openFirst={() => AgendaVm.openFirst()}/>}/>
                                : <></>
                            }
                        </React.Fragment>
                    ))}
                    {AgendaVm.isAllSaved && 
                        <div className={st.allSaved}>
                            {AgendaVm.registrationRequiredYn === false && 
                                <div>
                                    <div className={st.text}>
                                        <div className={st.big}>선택한 안건은 <span className={st.important}>등기가 필요없어요!</span></div>
                                        <div className={st.small}>다음 페이지에서 차근차근 절차를 진행해드릴게요!</div>
                                    </div>
                                    <img src='/images/agemdaFinal_등기불필요.svg'/>
                                </div>
                            }
                            {AgendaVm.registrationRequiredYn === true && 
                                <div>
                                    <div className={st.text}>
                                        <div className={st.big}>선택한 안건에 <span className={st.important}>등기사항이 포함</span>되었네요!</div>
                                        <div className={st.small}>다음 페이지에서 차근차근 절차를 진행해드릴게요!</div>
                                    </div>
                                    <img src='/images/agemdaFinal_등기필요.svg'/>
                                </div>
                            }
                            <div className={st.btnArea}>
                                <SquareBtn type="button" arrow onClick={() => AgendaVm.nextStep(agendaProgressId)} className={st.contentSubmitBtn} >다음 페이지로</SquareBtn>
                            </div>
                        </div>
                    }
                </div>
            }
        </>
    ));
}
export default Agenda;