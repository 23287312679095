import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { useEffect, Fragment } from 'react';
import SendVm from './SendVm';
import st from './Send.module.scss';
import { addCommas } from '@common/module/replaceNumber';
import PopupTable from '@commonComponents/PopupTable/PopupTable';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import AddBtn from '@standby/common-ui/components/atoms/Button/AddBtn/AddBtn';
import MinusBtn from '@standby/common-ui/components/atoms/Button/MinusBtn/MinusBtn';
import Check from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import regExp from '@common/module/regExp';
import useComponentWillMount from '@common/hook/useComponentWillMount';
import Loading from '@commonComponents/Loading/Loading';

let vm;

function Send(props) {

    useComponentWillMount(() => {
        vm = new SendVm;
    })
    
    return useObserver(() => (
        <div className={st.shareholderList}>
            {vm?.state?.loadingYn && <Loading />}
            <div className={st.title}>주주에게 발송하는 경우 아래에서 선택하세요.</div>
            <PopupTable className={st.table}>
                <colgroup>
                    <col width='245px'/>
                    <col width='140px'/>
                    <col />
                    <col />
                    <col width='100px'/>
                    <col width='77px'/>
                </colgroup>
                <thead>
                    <tr>
                        <th>주주명</th>
                        <th>주주유형</th>
                        <th>주식종류</th>
                        <th>주식수</th>
                        <th>지분율</th>
                        <th><Check className={st.checkBox} checked={vm.allChecked} onChange={(e) => vm.setAllChecked(e)}><span className={st.text}>메일</span></Check></th>
                    </tr>
                </thead>
                {vm.shareholders.map((shareholder, idx) => (
                    <tbody key={idx}>
                        {shareholder.stocks.map((stock, stockIdx) => {
                            return (
                                <tr key={stockIdx}>
                                    {stockIdx === 0 && <td rowSpan={shareholder.stocks.length}>{shareholder.name}{shareholder.tag && <span className={st.positionTag}>{shareholder.tag}님</span>}</td>}
                                    {stockIdx === 0 && <td rowSpan={shareholder.stocks.length}>{shareholder.shareholderType}</td>}
                                    <td style={{paddingLeft: '0'}}>{stock.name}</td>
                                    <td style={{textAlign: 'right'}}>{addCommas(stock.number)} 주</td>
                                    <td style={{paddingRight: '20px', textAlign: 'right'}}>{stock.rate}</td>
                                    {stockIdx === 0 && 
                                        <td rowSpan={shareholder.stocks.length} style={{border: '1px solid #D6D8DA', textAlign: 'center'}}>
                                            <Check value={shareholder.id} checked={vm.checked(idx)} onChange={(e) => vm.setChecked(e, idx)} className={st.checkBox} />
                                        </td>
                                    }
                                </tr>
                            )
                        })}
                    </tbody>
                ))}
                <tfoot>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>총 <span className={st.bold}>{addCommas(vm.stockTotal)}</span> 주</td>
                        <td></td>
                        <td></td>
                    </tr>
                </tfoot>
            </PopupTable>
            <form className={st.informationBox} onSubmit={(e) => vm.submit(e, props.sendParams)}>
                {vm.displayEmailInput() && 
                    <div className={st.emailBox}>
                        <div className={st.emailTitle}>
                            이메일 주소가 입력되지 않은 주주가 있어요. 이메일 주소를 입력하세요.
                            <div className={st.explain}>입력하신 이메일 주소는 주주명부에 저장됩니다.</div>
                        </div>
                        <div className={st.emailInfoBox}>
                            {vm.shareholders.map((shareholder, idx) => {
                                if((vm.state.recipient.shareholderIds.includes(shareholder.id) && shareholder.existsEmailYn === false)) {
                                    return (
                                        <div className={st.emailInfo} key={idx}>
                                            <input type="hidden" name={`shareholderUpdateInfoList[${idx}].id`} value={shareholder.id}/>
                                            <div className={st.name}>{shareholder.name}</div>
                                            <div className={st.email}>
                                                <InputText name={`shareholderUpdateInfoList[${idx}].email`} className={st.inputText} style={{width: '300px'}} value={shareholder.email} onChange={(e) => vm.setEmail(e, idx)} size="small" errorText={<Error name={`shareholderUpdateInfoList[${idx}].email`} value={shareholder.email} errorCase={{
                                                    required: '이메일을 입력하세요.',
                                                    pattern: {
                                                        value: regExp.email().test(shareholder.email),
                                                        message: '올바른 이메일을 입력하세요.'
                                                    }
                                                }} />}/>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </div>
                } 
                <div className={st.addEmailBox}>
                    <div className={st.addEmailTitle}>
                        주주 외의 사람에게 발송하는 경우 직접 입력하세요.
                    </div>
                    <div className={st.addEmailInfoBox}>
                        {vm.state.recipient.emails.map((emailInfo, idx) => {
                            return (
                                <div className={st.addEmailInfo} key={idx}>
                                    <InputText name={`recipient.emails[${idx}].name`} explain="이름*" size="small" style={{width:'152px'}} value={emailInfo.name} onChange={(e) => vm.setNewName(e, idx)}/>
                                    <InputText name={`recipient.emails[${idx}].email`} explain="이메일*" size="small" style={{width:'300px'}} className={st.addEmialInputText} value={emailInfo.email} onChange={(e) => vm.setNewEmail(e, idx)} />
                                    {vm.state.recipient.emails.length > 1 && <MinusBtn className={st.minusBtn} onClick={() => vm.deleteEmailInfo(idx)}/>}
                                </div>
                            )
                        })}
                    </div>
                    <div className={st.addBtnArea}>
                        <AddBtn onClick={() => vm.addEmailInfo()}>추가하기</AddBtn>
                    </div>
                </div>
                <div className={st.btnArea}>
                    <SquareBtn type="submit" className={st.sendBtn}>주주명부 발송하기</SquareBtn>
                </div>
            </form>
        </div>
    ));
}

export default Send;