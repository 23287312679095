import { observable } from 'mobx';

const homeModel = observable({
    shareholders: [],
    stockManagementGuides: [],
    capital: null,
    stocks: [],
    stocksNumber: null,
    parValue: null,
    updatedAt: "", 
    setStock(data) {
        if(data.shareholders && data.shareholders.length !== 0) {
            this.shareholders = data.shareholders.map((item, idx) => {
                return {
                    id: item.id || "",
                    name: item.name || "",
                    email: item.email || "",
                    shareholderType: item.shareholderType || "",
                    stocks: item.stocks || [],
                    onlineMusterNoticeStatus: item.onlineMusterNoticeStatus || "",
                    onlineMusterNoticeUrl: item.onlineMusterNoticeUrl || ""
                }
            })
        } else {
            this.shareholders = [];
        }
        if(data.stockManagementGuides && data.stockManagementGuides.length !== 0) {
            this.stockManagementGuides = data.stockManagementGuides.map((item, idx) => {
                return {
                    title: item.title || "",
                    contents: item.contents || "",
                    url: item.url || "" 
                }
            })
        } else {
            this.stockManagementGuides = [];
        }
        this.capital = data.capital || null;
        if(data.stocks && data.stocks.length !== 0) {
            this.stocks = data.stocks.map((item, idx) => {
                return {
                    name: item.name || "",
                    number: item.number || null,
                    content: item.content || ""
                }
            })
        } else {
            this.stocks = [];
        }
        this.stocksNumber = data.stocksNumber || null;
        this.parValue = data.parValue || null;
    },
    setShareholders(data) {
        if(data.shareholders && data.shareholders.length !== 0) {
            this.shareholders = data.shareholders.map((item, idx) => {
                return {
                    id: item.id || "",
                    name: item.name || "",
                    email: item.email || "",
                    shareholderType: item.shareholderType || "",
                    stocks: item.stocks || [],
                    onlineMusterNoticeStatus: item.onlineMusterNoticeStatus || ""
                }
            })
        } else {
            this.shareholders = [];
        }
    }
});

export default homeModel;