import { runInAction } from "mobx";
import corpModel from '@model/Corp/corpModel';
import meetingsStepModel from '@model/Corp/Agenda/Step/meetingsStepModel';

class WrittenResolutionVm {
    주주전원_서면결의Yn(idx: number) {
        return meetingsStepModel.tabList[idx].주주전원_서면결의Yn;
    }
    set주주전원_서면결의Yn(e: React.ChangeEvent<HTMLInputElement>, idx: number) {
        runInAction(() => {
            meetingsStepModel.tabList[idx].주주전원_서면결의Yn = e.target.value === "true";
        })
    }
    get hasCapitalOverBillionYn() {
        return meetingsStepModel.hasCapitalOverBillionYn;
    }
    get companyName() {
        return corpModel.company.companyName;
    }
    boardOfDirectorsAuthYn(idx: number) {
        return meetingsStepModel.tabList[idx].boardOfDirectorsAuthYn;
    }
    hasRegistrationMattersYn(idx: number) {
        return meetingsStepModel.tabList[idx].hasRegistrationMattersYn;
    }
}

export default new WrittenResolutionVm();