import { useObserver } from 'mobx-react';
import vm from './IdListVm';
import st from './IdList.module.scss';
import { Link } from "react-router-dom";
import CopyBtn from '@standby/common-ui/components/atoms/Button/CopyBtn/CopyBtn';

function IdList() {
    return useObserver(() => (
        <div className={st.result}>
            <div className={st.logoWrap}><a href='https://standby.kr/' className={st.logo}><img src='/images/logo_standby.svg?1'/></a></div>
            <div className={st.mainTitle}>고객님의 정보와 일치하는 <em>아이디를 안내</em>드려요.</div>
            <ul className={st.idList}> 
                {vm.users.map((user, index) => (
                    <li key={index}>
                        <CopyBtn className={st.accountNumber} messageClassName={st.accountNumberCopyMessage} copyText={user.id}>{user.id}</CopyBtn>
                        <span className={st.date}>{user.registeredAt.year}-{user.registeredAt.month}-{user.registeredAt.day} 가입</span>
                    </li>
                ))}
            </ul>
            <Link to="/auth/login" className={st.loginBtn}>로그인 하러가기</Link>
            <div className={st.likeBtnBox}>
                <Link to="/auth/findPassword" className={st.likeBtn}>비밀번호 찾기</Link>
            </div>
        </div>
    ));
}
export default IdList;