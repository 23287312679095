import AgendaDashboardService from '@service/AgendaDashboard/AgendaDashboardService';

class CommonAgendaVm {
    title(type : string) {
        switch (type) {
            case "임원_보수_지급규정_승인" : 
                return "임원 보수 지급 규정 승인";
                break;
            case "임원_퇴직금_지급규정_승인" : 
                return "임원 퇴직금 지급 규정 승인"
                break;
            case "임원_상여금_지급규정_승인" :
                return "임원 상여금 지급 규정 승인"
                break;
            case "차등배당" : 
                return "차등배당"
                break;
            case "중간배당" : 
                return "중간배당"
                break;
            case "부동산_매각" : 
                return "부동산 매각"
                break;
            case "자금차입" : 
                return "자금차입"
                break;
            case "자금차입_및_담보제공" :
                return "자금차입 및 담보제공" 
                break;
            case "자회사_설립" : 
                return "자회사 설립"
                break;
            case "이사의_자기거래_승인" :
                return "이사의 자기거래 승인"
                break;
            case "주주총회_안건_자유입력" : 
                return "자유안건"
                break;
            case "이사회_안건_자유입력" : 
                return "자유안건"
            case "임원_무보수_결의" : 
                return "임원 무보수 결의"
                break;
        }
    }
    fileDown(downloadUrl: string) {
        AgendaDashboardService.fileDown(downloadUrl);
    }
    shouldGetFile(agendaType : string) {
        if(agendaType === "임원_무보수_결의") {
            return false;
        }
        return true;
    }
}
export default new CommonAgendaVm();