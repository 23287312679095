class NewStockNoticeVm {
    download(assignmentType: string) {
        if(assignmentType === "제3자") {
            window.location.href = `${process.env.REACT_APP_S3URL}/agenda/%E1%84%80%E1%85%A9%E1%86%BC%E1%84%80%E1%85%A9%E1%84%86%E1%85%AE%E1%86%AB%E1%84%8B%E1%85%A3%E1%86%BC%E1%84%89%E1%85%B5%E1%86%A8(%E1%84%8C%E1%85%A63%E1%84%8C%E1%85%A1%E1%84%87%E1%85%A2%E1%84%8C%E1%85%A5%E1%86%BC+%E1%84%89%E1%85%B5%E1%86%AB%E1%84%8C%E1%85%AE%E1%84%87%E1%85%A1%E1%86%AF%E1%84%92%E1%85%A2%E1%86%BC).docx`;
        } else if(assignmentType === "주주") {
            window.location.href = `${process.env.REACT_APP_S3URL}/agenda/%E1%84%80%E1%85%A9%E1%86%BC%E1%84%80%E1%85%A9%E1%84%86%E1%85%AE%E1%86%AB%E1%84%8B%E1%85%A3%E1%86%BC%E1%84%89%E1%85%B5%E1%86%A8(%E1%84%8C%E1%85%AE%E1%84%8C%E1%85%AE%E1%84%87%E1%85%A2%E1%84%8C%E1%85%A5%E1%86%BC+%E1%84%89%E1%85%B5%E1%86%AB%E1%84%8C%E1%85%AE%E1%84%87%E1%85%A1%E1%86%AF%E1%84%92%E1%85%A2%E1%86%BC).docx`;
        } else if(assignmentType === "혼합") {
            window.location.href = `${process.env.REACT_APP_S3URL}/agenda/%E1%84%80%E1%85%A9%E1%86%BC%E1%84%80%E1%85%A9%E1%84%86%E1%85%AE%E1%86%AB%E1%84%8B%E1%85%A3%E1%86%BC%E1%84%89%E1%85%B5%E1%86%A8(%E1%84%8C%E1%85%A63%E1%84%8C%E1%85%A1%E1%84%87%E1%85%A2%E1%84%8C%E1%85%A5%E1%86%BC%2C+%E1%84%8C%E1%85%AE%E1%84%8C%E1%85%AE%E1%84%87%E1%85%A2%E1%84%8C%E1%85%A5%E1%86%BC).zip`;
        }
    }
}

export default new NewStockNoticeVm();