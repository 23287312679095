import { runInAction, observable, toJS } from "mobx";
import formDataToJson from '@common/module/submit';
import authBusinessRegistrationService from '@service/authBusinessRegistrationService.js';
import loginModel from '@model/AuthBusinessRegistration/loginModel';
import regExp from '@common/module/regExp';

class LoginBusinessRegistrationVm {
    constructor() {
        this.state = observable({
            action: false,
            password : "",
            cancelYn : false
        })
    }
    async submit(e) {
        e.preventDefault();
        if(formDataToJson(e.target).error) {
            return
        } else {
            let param = formDataToJson(e.target).data;
            await authBusinessRegistrationService.login(param);
        }
    }
    async load(token) {
        try {
            await authBusinessRegistrationService.isLoggedIn(token);
            await authBusinessRegistrationService.loadExisting(token);      
        } catch(e) {
            if(e?.response?.status === 403) {
                this.state.cancelYn = true;
            }
        }
    }   
    get name() {
        return loginModel.name;
    }
    get email() {
        return loginModel.email;
    }
    changePassword(e) {
        this.state.password = regExp.deleteSpace(e.target.value);
    }
    
}
export default new LoginBusinessRegistrationVm();