import { useObserver } from 'mobx-react';
import st from '../Agendas.module.scss';
import type {Agenda, IA전환사채_발행} from '@model/AgendaDashboard/AgendaDashboardModel.interface';
import clsx from 'clsx';
import { addCommas } from '@common/module/replaceNumber';

interface Props {
    agendaIndex : number,
    agenda : Agenda<IA전환사채_발행>,
    moreViewYn : boolean
}

function C전환사채_발행(props : Props) {
    return useObserver(() => (
        <div className={clsx(st.agenda, props.moreViewYn ? st.opened : '')}>
            <div className={st.title}>
                <div className={st.number}>{props.agendaIndex + 1}</div>
                <div className={st.text}><span className={st.important}>전환사채 발행</span></div>
                {props.agenda.decisionBody.map((decisionBody, index) => (
                    <div className={st.badge} key={index}>{decisionBody}</div>
                ))}
            </div>
            {props.moreViewYn && 
                <div className={st.info}>
                    <ul className={st.agendaList}>
                        <li>
                            전환사채 총액 : {addCommas(props.agenda.info.totalAmount)} 원
                        </li>
                        <li>
                            전환사채인수인 : {props.agenda.info.publicationTarget}
                        </li>
                        <li>
                            전환사채인수인 숫자 : {props.agenda.info.issueCount} 명
                        </li>
                    </ul>
                </div>
            }
        </div>
    ));
}
export default C전환사채_발행;