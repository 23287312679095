import type { LoadRs, SaveStep1Rq, step2LoadRs, SaveStep2Rq } from './BoardOfDirectorsService.interface';
import { get, post } from '@common/module/httpRequest';
import { runInAction } from 'mobx';
import BoardOfDirectorsModel from "@src/model/Corp/Agenda/Step/FinalCheck/BeforeFinal/BoardOfDirectors/BoardOfDirectorsModel";
import { _alert } from "@model/dialogModel";

class BoardOfDirectorsService {
    async load(companyId : string, agendaProgressId : string, minutesId : string) {
        const res = await get<LoadRs>(`/company/${companyId}/agendaProgress/${agendaProgressId}/beforeFinal/minutes/boardOfDirectors`, { minutesId : minutesId });
        runInAction(() => {
            BoardOfDirectorsModel.step1 = {
                chairmanId : res.data.chairmanId ? (res.data.chairmanId).toString() : '',
                selectableChairmen : res.data.selectableChairmen.map((selectableChairmen) => {
                    return {
                        id : selectableChairmen.id ? (selectableChairmen.id).toString() : '',
                        position : selectableChairmen.position || '',
                        name : selectableChairmen.name || ''
                    }
                }),
                executives : res.data.executives.map((executive) => {
                    return {
                        id : executive.id ? (executive.id).toString() : '',
                        position : executive.position || '',
                        name : executive.name || '',
                        attendMeetingYn : executive.attendMeetingYn || false
                    }
                }),
            }

            BoardOfDirectorsModel.nowStep = '1'
        })
    }
    async step2Load(companyId : string, agendaProgressId : string, minutesId : string) {
        const res = await get<step2LoadRs>(`/company/${companyId}/agendaProgress/${agendaProgressId}/beforeFinal/minutes/boardOfDirectors/agenda`, { minutesId : minutesId });
        runInAction(() => {
            BoardOfDirectorsModel.step2 = {
                agendaList : res.data.agendaList.map((agenda) => {
                    return {
                        type : agenda.type,
                        title : agenda.title || '',
                        executives : agenda.executives.map((executive) => {
                            return {
                                id : executive.id ? (executive.id).toString() : '',
                                name : executive.name || '',
                                position : executive.position || '',
                                status : executive.status || ''
                            }
                        })
                    }
                })
            }
        })
    }
    async saveStep1(param : SaveStep1Rq, companyId : string, agendaProgressId : string) {
        await post(`/company/${companyId}/agendaProgress/${agendaProgressId}/beforeFinal/minutes/boardOfDirectors`, param);
        runInAction(() => {
            BoardOfDirectorsModel.nowStep = '2'
        })
    }
    async saveStep2(param : SaveStep2Rq, companyId : string, agendaProgressId : string) {
        await post(`/company/${companyId}/agendaProgress/${agendaProgressId}/beforeFinal/minutes/boardOfDirectors/agenda`, param);
        await  _alert('선택 완료되었어요', '자동작성된 의사록을 다운로드 받으세요.', undefined, undefined, 'loud');
        window.location.reload();
    }
}
export default new BoardOfDirectorsService();