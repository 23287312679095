import AgendaDashboardModel from '@model/AgendaDashboard/AgendaDashboardModel';

class CapitalVM {
    get capital() {
        return AgendaDashboardModel.agendaProgress.company.capital;
    }
    get additionalCapital() {
        return AgendaDashboardModel.agendaProgress.company.additionalCapital;
    }
    get parValue() {
        return AgendaDashboardModel.agendaProgress.company.parValue;
    }
}
export default new CapitalVM();