import { runInAction, observable } from "mobx";
import dateListModel from "@model/Corp/Agenda/Step/dateListModel";
import type { AgendaType } from '@model/Corp/Agenda/Step/dateListModel.interface';

interface State {
    agendaOpenYn: boolean;
    toastMessageYn: boolean;
    autoChangeIdxList: number[];
}

class DateListVm {
    state: State = observable({
        agendaOpenYn: false,
        toastMessageYn: false,
        autoChangeIdxList: []
    })
    showToastMessage() {
        const timer = setTimeout(() => {
            this.state.toastMessageYn = false;
        }, 3050);
        return () => clearTimeout(timer);
    }
    get 소집통지Yn() {
        const musterNoticeType = dateListModel.musterNoticeType;
        if(musterNoticeType === '온라인' || musterNoticeType === '우편') {
            return true;
        } else {
            return false;
        }
    }
    get onlyRepresentativeChangedAddressYn() {
        return dateListModel.onlyRepresentativeChangedAddressYn;
    }
    certificateYn(idx: number) {
        const hasCapitalOverBillionYn = dateListModel.hasCapitalOverBillionYn;
        const agendas = dateListModel.dateList[idx].agendas;
        let 신주발행Yn = false;
        if(agendas) {
            신주발행Yn = agendas.some(item => item.type === "신주발행_유상증자");
        }
        if(hasCapitalOverBillionYn === false && 신주발행Yn) {
            return true;
        } else {
            return false;
        }
    }   
    get 가수금증자Yn() {
        const newStockResourceTypes = dateListModel.newStockResourceTypes;
        if(newStockResourceTypes.length === 1 && newStockResourceTypes.includes("가수금_증자")) {
            return true;
        } else {
            return false;
        }
    }
    hasRegistrationMattersYn(idx: number) {
        return dateListModel.dateList[idx].hasRegistrationMattersYn;
    }
    get dateList() {
        return dateListModel.dateList;
    }
    displayTitle(type: string, title: string) {
        switch(type) {
            case '주주전원의_서면결의':
                return '주주 전원의\n서면결의';
            case '주주총회_소집통지':
                return '주주총회\n소집통지';
            case '정기주주총회_소집통지':
                return '정기주주총회\n소집통지';
            case '주주총회_개최_이사회':
                return '주주총회 개최를\n정하는 이사회';
            case '주주총회_개최_대표결정':
                return '주주총회 개최를\n정하는 대표결정';
            case '정기주주총회_개최_대표결정':
                return '주주총회 개최를\n정하는 대표결정';
            case '주주총회':
            case '정기주주총회':
            case '이사회':
            case '대표결정':
            case '신주발행통지':
            case '신주공고통지':
            case '신주통지':
                return title;
        }
    }
    displayType(idx: number) {
        let type = dateListModel.dateList[idx]?.type;
        switch(type) {
            case '주주총회_개최_대표결정':
            case '정기주주총회_개최_대표결정':
                return {location: false, date: true, time: false};
            case '주주총회_개최_이사회':
                return {location: true, date: true, time: true};
            case '주주총회_소집통지':
            case '정기주주총회_소집통지':
                return {location: false, date: true, time: false};    
            case '주주총회':
            case '정기주주총회':
                return {location: true, date: true, time: true};
            case '이사회':
                return {location: true, date: true, time: true};
            case '대표결정':
                return {location: false, date: true, time: false};
            case '주주전원의_서면결의':
                return {location: false, date: true, time: false};
            case '신주통지':
            case '신주발행통지':
            case '신주공고통지':
                return {location: false, date: true, time: false};
        }
    }
    decisionAt(idx: number) {
        return dateListModel.dateList[idx].decisionAt;
    }
    decisionDate(idx: number) {
        const year = dateListModel.dateList[idx].decisionAt.year;
        const month = dateListModel.dateList[idx].decisionAt.month;
        const day = dateListModel.dateList[idx].decisionAt.day;

        let dicisionDate = null;
        if(year && month && day) {
            dicisionDate = year + "-" + month + "-" + day;
        }

        return dicisionDate;
    }
    setDecisionDate(e: Date, idx: number) {
        let year = ""; let month = ""; let day = "";
        if(e) {
            year = String(e.getFullYear());
            month = ('0' + (e.getMonth() + 1)).slice(-2);
            day = ('0' + e.getDate()).slice(-2);
        }

        runInAction(() => {
            dateListModel.dateList[idx].decisionAt.year = year;
            dateListModel.dateList[idx].decisionAt.month = month;
            dateListModel.dateList[idx].decisionAt.day = day;
        })

        if(year && month && day) {
            this.blurDate(idx);
        }
    }
    decisionTime(idx: number) {
        const today = new Date();

        const year = today.getFullYear();
        const month = ('0' + (today.getMonth() + 1)).slice(-2);
        const day = ('0' + today.getDate()).slice(-2);
        
        let hour =  dateListModel.dateList[idx].decisionAt.hour;
        let minute =  dateListModel.dateList[idx].decisionAt.minute;
        
        if(hour && minute) {
            minute = minute.replace(/^0(\d)$/, "$1")
            return (new Date(Number(year), Number(month), Number(day), Number(hour), Number(minute)));
        } else {
            return null;
        }
    }
    setDecisionTime(e: Date | null, idx: number) {
        if(e) {
            const hour = ('0' + e.getHours()).slice(-2); 
            const minute = ('0' + e.getMinutes()).slice(-2);

            runInAction(() => {
                dateListModel.dateList[idx].decisionAt.hour = hour;
                dateListModel.dateList[idx].decisionAt.minute = minute;
            })
        }
        this.blurTime(idx);
    }
    blurTime(idx: number) {
        runInAction(() => {
            const type = dateListModel.dateList[idx].type;
            if(type !== '주주총회_개최_대표결정' && type !== '정기주주총회_개최_대표결정') {
                for(let i=0; i< dateListModel.dateList.length; i++) {
                    let newDate =  new Date(`${dateListModel.dateList[idx].decisionAt.year}.
                                            ${dateListModel.dateList[idx].decisionAt.month}.
                                            ${dateListModel.dateList[idx].decisionAt.day}.`);
                    
                    let date = new Date(`${dateListModel.dateList[i].decisionAt.year}.
                                        ${dateListModel.dateList[i].decisionAt.month}.
                                        ${dateListModel.dateList[i].decisionAt.day}.`);
                    

                    if(newDate.getTime() === date.getTime()) {
                        const newDateTime = this.decisionTime(idx);
                        const dateTime = this.decisionTime(i);
                        
                        if(newDateTime && dateTime) {
                            const diffHour = Math.abs(newDateTime.getTime() - dateTime.getTime()) / 3600000;
                            if (idx < i) {
                                if ((newDateTime.getTime() > dateTime.getTime()) || diffHour < 1) {
                                    let tmpDate = new Date(newDateTime.setHours(newDateTime.getHours() + 1));
                                    dateListModel.dateList[i].decisionAt.hour = ('0' + tmpDate.getHours()).slice(-2);
                                    dateListModel.dateList[i].decisionAt.minute = ('0' + tmpDate.getMinutes()).slice(-2);
                                }
                            } else if (idx > i) {
                                if ((newDateTime.getTime() < dateTime.getTime()) || diffHour < 1) {
                                    let tmpDate = new Date(newDateTime.setHours(newDateTime.getHours() - 1));
                                    dateListModel.dateList[i].decisionAt.hour = ('0' + tmpDate.getHours()).slice(-2);
                                    dateListModel.dateList[i].decisionAt.minute = ('0' + tmpDate.getMinutes()).slice(-2);
                                }
                            } else {
                                if ((newDateTime.getTime() < dateTime.getTime())) {
                                    let tmpDate = new Date(newDateTime.setHours(newDateTime.getHours() + 1));
                                    dateListModel.dateList[i].decisionAt.hour = ('0' + tmpDate.getHours()).slice(-2);
                                    dateListModel.dateList[i].decisionAt.minute = ('0' + tmpDate.getMinutes()).slice(-2);
                                }
                            }
                        } 
                    }                
                }
            }
        })
    }
    addZero(text: string) {
        if((text).toString().length < 2) {
            return ('0'+text).toString();
        }
        return (text).toString();
    }
    blurDate(idx: number) {
        this.state.autoChangeIdxList = [];
        runInAction(() => {
            dateListModel.dateList[idx].decisionAt = {
                year: dateListModel.dateList[idx].decisionAt.year,
                month: this.addZero(dateListModel.dateList[idx].decisionAt.month),
                day: this.addZero(dateListModel.dateList[idx].decisionAt.day),
                hour: dateListModel.dateList[idx].decisionAt.hour,
                minute: dateListModel.dateList[idx].decisionAt.minute
            };
    
            let decisionAt = dateListModel.dateList[idx].decisionAt;
            let type =  dateListModel.dateList[idx].type

            if(decisionAt.year && decisionAt.month && decisionAt.day) {
                for(let i=0; i<dateListModel.dateList.length; i++) {
                    let keyValue = this.changeDate(type);
    
                    let newDate = new Date(`${decisionAt.year}.${decisionAt.month}.${decisionAt.day}`);
    
                    let date = new Date(`${dateListModel.dateList[i].decisionAt.year}.
                                        ${dateListModel.dateList[i].decisionAt.month}.
                                        ${dateListModel.dateList[i].decisionAt.day}`);
    
                    let tmpDate = new Date();

                    let dateType = dateListModel.dateList[i].type;
                    let changeValue = this.changeDate(dateType)-keyValue;
                    tmpDate =  new Date(newDate.setDate(newDate.getDate()+changeValue));
                    
                    if(idx > i) {
                        if(tmpDate < date) {
                            dateListModel.dateList[i].decisionAt.year = String(tmpDate.getFullYear());
                            dateListModel.dateList[i].decisionAt.month = this.addZero(String(tmpDate.getMonth()+1));
                            dateListModel.dateList[i].decisionAt.day = this.addZero(String(tmpDate.getDate()));
                            
                            this.state.autoChangeIdxList.push(i);
                            this.state.toastMessageYn = true;
                            this.showToastMessage();

                        }
                    } else {
                        if(tmpDate > date) {
                            dateListModel.dateList[i].decisionAt.year = String(tmpDate.getFullYear());
                            dateListModel.dateList[i].decisionAt.month = this.addZero(String(tmpDate.getMonth()+1));
                            dateListModel.dateList[i].decisionAt.day = this.addZero(String(tmpDate.getDate()));

                            this.state.autoChangeIdxList.push(i);
                            this.state.toastMessageYn = true;
                            this.showToastMessage();
                        }
                    }
                }
            }
        })
    }
    changeDate(type: string) {
        let 소집통지Yn = false;
        if(dateListModel.musterNoticeType === "온라인" || dateListModel.musterNoticeType === "우편") {
            소집통지Yn = true;
        }

        if(소집통지Yn) {
            switch(type) {
                case '주주총회':
                case '정기주주총회':
                case '이사회':
                case '신주공고통지':
                case '신주발행통지':
                case '신주통지':
                case '대표결정':
                    return this.changeDateAuto;
                default:
                    return 0;
            }
        } else {
            return 0;
        }
    }
    get changeDateAuto() {
        if(dateListModel.hasCapitalOverBillionYn) return 15;
        else if(this.shareholdersMeetingNoticePeriodShorteningAOA === "주주총회_소집통지_기간_2주_자본금_10억_미만_10일_단축가능") return 11;
        else if(this.shareholdersMeetingNoticePeriodShorteningAOA === "주주총회_소집통지_기간_2주_고정") return 15;
        else if(this.shareholdersMeetingNoticePeriodShorteningAOA === "주주총회_소집통지_기간_상법에_맞지_않는_규정_있음") return 15;
        else return 0;
    }
    get displayChangeDate() {
        if(this.changeDateAuto > 0) {
            return this.changeDateAuto - 1;
        } else {
            return this.changeDateAuto;
        }
    }
    get shareholdersMeetingNoticePeriodShorteningAOA() {
        return dateListModel.shareholdersMeetingNoticePeriodShorteningAOA;
    }
    의결장소Type(idx: number) {
        return dateListModel.dateList[idx].의결장소Type;
    }
    set의결장소Type(e: React.ChangeEvent<HTMLSelectElement>, idx: number) {
        runInAction(() => {
            dateListModel.dateList[idx].의결장소Type = e.target.value;
        })
    }
    place(idx: number) {
        return dateListModel.dateList[idx].place;
    }
    setPlace(e: React.ChangeEvent<HTMLInputElement>, idx: number) {
        runInAction(() => {
            dateListModel.dateList[idx].place = e.target.value;
        })
    }
    newShareholderPaymentDate(idx: number) {
        const type = dateListModel.dateList[idx].type;
        let _paymentDate = '';
        
        if(type === '신주통지' || type === '신주발행통지' || type === '신주공고통지') {
            const decisionAt = dateListModel.dateList[idx].decisionAt;

            const decisionDate = new Date(`${decisionAt.year}.${decisionAt.month}.${decisionAt.day}.`);
            const paymentDate = new Date(decisionDate.setDate(decisionDate.getDate()+15));

            _paymentDate =  String(paymentDate.getFullYear()) + "-" + this.addZero(String(paymentDate.getMonth()+1)) + "-" + this.addZero(String(paymentDate.getDate()));
        }
        
        return _paymentDate;
    }
    moreView() {
        this.state.agendaOpenYn = !this.state.agendaOpenYn;
    }
    highLightYn(idx: number) {
        let beforeType = '';
        let currentType = '';
        if(idx > 0) {
            beforeType = dateListModel.dateList[idx-1].type;
            currentType = dateListModel.dateList[idx].type;
        }
        if(beforeType === '정기주주총회_소집통지' || currentType === '정기주주총회_소집통지') {
            return true;
        } else {
            return false;
        }
    }
    displayExplinType(type: string) {
        switch(type) {
            case '주주총회':
            case '정기주주총회':
                return '주총과';
            case '이사회':
                return '이사회와';
            case '주주총회_개최_대표결정':
            case '정기주주총회_개최_대표결정':
            case '대표결정':
                return '대표결정과';
            case '주주전원의_서면결의':
                return '서면결의와';
        }
    }
    소집통지Explain(type: string) {
        let displayYn = false;
        if(this.소집통지Yn === false){
            if(type === '주주총회' || type === '정기주주총회') {
                displayYn = true;
            }
        }
        return displayYn;
    }
    신주발행통지Explain(idx: number) {
        let displayYn = false;
        const processStatus = dateListModel.processStatus;
        if(processStatus === "생략") {
            if(dateListModel.dateList.length-1 === idx) {
                displayYn = true;
            }
        }
        return displayYn;
    }
    신주Explain(idx: number) {
        const type = dateListModel.dateList[idx].type;
        if(type === '신주발행통지' || type === "신주공고통지" || type === '신주통지') {
            return true;
        } else {
            return false;
        }
    }
    displayExplainBox(idx: number) {
        const type = dateListModel.dateList[idx].type;

        let hasRegistrationMattersYn = this.hasRegistrationMattersYn(idx);
        let 소집통지Yn = this.소집통지Yn;

        let explainYn = false;
        if(type === '주주총회' || type === '정기주주총회' || type === '이사회' || type === '대표결정' || type === '주주전원의_서면결의') {
            explainYn = true;
        } 

        if(explainYn && (hasRegistrationMattersYn || 소집통지Yn === false)) {
            return true;
        } else if(type === '신주발행통지' || type === "신주공고통지" || type === '신주통지') {
            return true;
        } else {
            return false;
        }
    }
    액면금분할합병Explain(agendas: {type: AgendaType; value: string;}[]) {
        let displayYn = false;
        if(agendas) {
            agendas?.forEach((agenda) => {
                if(agenda.type === '액면금분할_합병') {
                    displayYn = true;
                }
            })
        }
        return displayYn;
    }
    minHeight(idx: number, type: string, agendas: {type: AgendaType; value: string;}[]) {
        let explainCnt = 0;
        if(this.소집통지Explain(type)) explainCnt++;
        if(this.hasRegistrationMattersYn(idx) && this.certificateYn(idx) === false) explainCnt++;
        if(this.신주발행통지Explain(idx)) explainCnt++;
        if(this.신주Explain(idx)) explainCnt++;
        if(this.액면금분할합병Explain(agendas)) explainCnt++;
        if(this.가수금증자Yn) explainCnt++;
        if(this.hasRegistrationMattersYn(idx) && this.certificateYn(idx) && this.가수금증자Yn === false) {
            explainCnt = explainCnt + 2;
        }

        if(explainCnt > 1) {
            return 96 + (21 * (explainCnt - 1) ) + 'px';
        } else {
            return 96 + 'px';
        }
    }
}

export default new DateListVm();