import userDashboardService from '@service/userDashboardService';
import userDashboardModel from '@model/userDashboardModel';
import {runInAction, observable} from 'mobx';
import portalModel from '@model/portalModel';
import BusinessRegistrationModal from './BusinessRegistrationModal/BusinessRegistrationModal';

class UserDashboardVm {
    constructor() {
        this.state = observable({
            moreViewYn: false,
        })
    }
    moreView() {
        this.state.moreViewYn = !this.state.moreViewYn;
    }
    async load() {
        await userDashboardService.load();
        if(userDashboardModel.acceptWaitingCompanies && userDashboardModel.acceptWaitingCompanies.length !== 0) {
            portalModel.content = <BusinessRegistrationModal/>
        }
    }
    get companiesInfo() {
        return userDashboardModel.companiesInfo;
    }
    viewType(company) {
        if(company.companyRegisteredStatus !== "REGISTERED") {
            return "등록중"
        }else if(company.executiveMembers.some(executiveMember => executiveMember.tag === "필수")) {
            return "필수"
        }else if(company.executiveMembers.some(executiveMember => executiveMember.tag === "필요")) {
            return "필요"
        }else if(company.newTags.length !== 0) {
            return "new"
        }else{
            return "일반"
        }
    }
    minDay(executiveMembers) {
        let minExecutiveMember = null;
        executiveMembers.forEach((executiveMember) => {
            if(minExecutiveMember === null || executiveMember.positionExpiredDays < minExecutiveMember.positionExpiredDays) {
                minExecutiveMember = executiveMember
            }
        })
        return minExecutiveMember;
    }
    expiration(executiveMembers) {
        let name = "";
        let count = 0;
        executiveMembers.forEach((executiveMember) => {
            if(executiveMember.tag === "필수") {
                count += 1;
                if(!name) {
                    name = executiveMember.name;
                }
            }
        })
        return {name, count}
    }
    minAddress(address) {
        let _address = ""
        if(address) {
            const addressArr = address.split(" ");
            if(addressArr[0]) {
                _address = _address + addressArr[0];
            }
            if(addressArr[1]) {
                _address = _address + " " + addressArr[1];
            }
            return _address;
        }
        return ""
    }
    representative(executiveMembers) {
        let name = "-";
        let count = 0;
        executiveMembers.forEach((executiveMember) => {
            if(executiveMember.representativeYn) {
                count += 1;
                if(name === "-") {
                    name = executiveMember.name;
                }
            }
        })
        return {name, count}
    }
}
export default new UserDashboardVm();