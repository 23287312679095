import corpModel from '@model/Corp/corpModel';
import corporateTaxModel from '@model/Corp/CorporateTax/corporateTaxModel';
import CorporateTaxService from '@service/Corp/CorporateTax/CorporateTaxService';
import { observable } from 'mobx';
import { _alert, _confirm } from "@model/dialogModel";

interface State {
    corporateTaxesId: number;
}

class StepPageVm {
    state: State = observable({
        corporateTaxesId: -1
    })
    async load() {
        await CorporateTaxService.loadCorporateTax();
        this.state.corporateTaxesId = corporateTaxModel.taxFilingState.selectableCorporateTaxes[0].id;
    }
    get selectableCorporateTaxes() {
        return corporateTaxModel.taxFilingState.selectableCorporateTaxes;
    }
    async setCorporateTaxes(e: React.ChangeEvent<HTMLSelectElement>) {
        this.state.corporateTaxesId = Number(e.target.value);
        await CorporateTaxService.selectCorporateTax(this.state.corporateTaxesId);
    }
    get settlementMonth() { //결산월
        return corporateTaxModel.taxFilingState.settlementMonth;
    }
    get settlementPeriod() { //결산기간
        return corporateTaxModel.taxFilingState.settlementPeriod;
    }
    get taxFilingEndAt() { //신고기간
        return corporateTaxModel.taxFilingState.taxFilingEndAt;
    }
    get faithfulnessTaxPayerYn() { //성실 납세자 여부
        return corporateTaxModel.taxFilingState.faithfulnessTaxPayerYn;
    }
    get steps() { //타임스탬프
        return corporateTaxModel.taxFilingState.steps;
    }
    get nowStep() {
        return corporateTaxModel.taxFilingState.steps[corporateTaxModel.taxFilingState.steps.length-1]?.step;
    }
    materialTitle(): string {
        let title = '';
        corporateTaxModel.taxFilingState.selectableCorporateTaxes.forEach((item) => {
            if(Number(item.id) === Number(this.state.corporateTaxesId)) {
                title = item.title + ' 법인세 신고 준비자료';
            }
        })
        return title;
    }
    get companyName() {
        return corpModel.company.companyName;
    }
    get deadline() {
        return corporateTaxModel.taxFilingPreparationData.deadline;
    }
    get emailToSubmit() {
        return corporateTaxModel.taxFilingPreparationData.emailToSubmit;
    }
    get corporateTaxes() { //법인세 리스트
        return corporateTaxModel.corporateTaxes;
    }
    fileDownload(downloadLink: string) {
        CorporateTaxService.fileDownload(downloadLink);
    }
    displayMaterial(): boolean {
        const nowStep = this.nowStep;
        if(nowStep === '국세_신고완료' || nowStep === '지방세_신고완료' || nowStep === '법인세_신고완료') {
            return false;
        } else {
            return true;
        }
    }
}

export default new StepPageVm();