import { useObserver } from 'mobx-react';
import type { I등기대행 } from '@src/model/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalModel.interface';
import bst from '@pages/Corp/Agenda/Step/FinalCheck/BeforeFinal/BeforeFinal.module.scss';
import st from './RegistrationAgency.module.scss';
import clsx from 'clsx';
import vm from './RegistrationAgencyVm';

interface RegistrationAgencyProps {
    index: number;
    info: I등기대행;
    agendaProgressId: string;
}

function RegistrationAgency(props: RegistrationAgencyProps) {
    return useObserver(() => (
        <>
            <div className={bst.row}>
                <div className={clsx(bst.title, bst.section4)}>{props.index+1}. 등기대행</div>
                <div className={bst.content}>
                    <div className={bst.contentTitleBox}>등기대행을 진행하세요. <span className={bst.light}>(입금 & 전자서명 또는 도장날인 필요)</span></div>
                    <div className={st.statusBox}>
                        <div className={st.registrationStepBox}>
                            <img src={`/images/registrationStep_${vm.status(props.info.steps)}.svg`} />
                            {props.info.steps && 
                            <>
                                {Object.entries(props.info.steps).map(([key, value]) => (
                                    <div className={st.date} data-step={key}>{value}</div>
                                ))}
                            </>
                            }
                        </div>
                        <div className={st.changeRegistration}>
                            <div>{vm.status(props.info.steps) === "등기대행_접수완료" && <span className={st.explain}>곧 등기대행 업체에서 연락을 드릴 거예요.</span>}</div>
                            <button type="button" onClick={() => vm.changeSelf(props.agendaProgressId)}>셀프등기로 전환하기</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={bst.row}>
                <div className={clsx(bst.title, bst.upDate)}>자동 업데이트</div>
                <div className={bst.content}>
                    <div className={st.upDateBox}>
                        <div className={st.upDateTitle}>등기대행이 완료되면, <span className={st.important}>자동 업데이트</span> 되어요!</div>
                        <div className={st.upDateImgBox}>
                            <img src='/images/upDate.svg' />
                            <div className={st.registrationComplete}>등기대행 완료</div>
                            <div className={st.autoUpDate}>신규 등기부, 정관, 주주명부<br/><span className={st.important}>자동 업로드</span></div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </>
    ));
}
export default RegistrationAgency;