import { runInAction, observable, toJS } from "mobx";
import authService from '@service/authService'
import loginModel from '@model/Auth/loginModel';
import formDataToJson from '@common/module/submit';
import regExp from '@common/module/regExp';

class viewModel {
    constructor() {
        this.loginApi = false;
    }
    async submit(e, redirectToken) {
        e.preventDefault();
        if(this.loginApi === false) {
            this.loginApi = true;
            if(formDataToJson(e.target).error) {
                return
            } else {
                let param = formDataToJson(e.target).data;
                try{
                    await authService.login(param, redirectToken);
                    this.loginApi = false;
                }catch(err) {
                    this.loginApi = false;
                }
            }
        }
    }
    get email() {
        return loginModel.email;
    }
    get password() {
        return loginModel.password;
    }
    setEmail(e) {
        runInAction(() => {
            loginModel.email = regExp.deleteSpace(e.target.value);
        })
    }
    setPassword(e) {
        runInAction(() => {
            loginModel.password = regExp.deleteSpace(e.target.value);
        })
    }
}

export default new viewModel();