import { useEffect } from 'react';
import { useObserver } from 'mobx-react';
import st from './AddDate.module.scss';
import vm from './AddDateVm';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import DatePicker from '@standby/common-ui/components/molecules/DatePicker/DatePicker';
import { addCommas } from '@common/module/replaceNumber';

interface AddDateProps {
    agendaProgressId: string;
    newSharesAcquirers: {
        id: number,
        name: string,
        subscriptionAt: string,
        paymentAt: string,
        stocks: {
            stockName: string,
            stockAmount: number,
            paymentAmount: number,
            issuePrice: number
        }[];
    }[];
    defaultSubscriptionAt: string;
}

function AddDate(props: AddDateProps) {
    
    useEffect(() => {
        vm.load(props.newSharesAcquirers);
    }, []);

    return useObserver(() => (
        <div className={st.modalContent}>
            <div className={st.title}>청약일과 주금 납입일을 입력하세요.</div>
            <table className={st.table}>
                <colgroup>
                    <col width="172"/>
                    <col width="112"/>
                    <col width="112"/>
                    <col width="159"/>
                    <col width="133"/>
                    <col width="128"/>
                    <col />
                </colgroup>
                <thead>
                    <tr>
                        <th>신주인수인</th>
                        <th>청약일</th>
                        <th>납입일</th>
                        <th>주식종류</th>
                        <th>투자금</th>
                        <th>주식수</th>
                        <th>발행가액(자동계산)</th>
                    </tr>
                </thead>
                {props.newSharesAcquirers?.map((newSharesAcquirer, idx) => {
                    return (
                        <tbody key={idx}>
                            {newSharesAcquirer.stocks.map((stock, idx2) => (
                                <tr key={idx2}>
                                    {idx2 === 0 && 
                                    <>
                                        <td rowSpan={newSharesAcquirer.stocks.length}><span className={st.index}>{idx+1}</span><span className={st.name}>{newSharesAcquirer.name}</span></td>
                                        <td rowSpan={newSharesAcquirer.stocks.length}>
                                            <DatePicker name="options.subscriptionAt" className={st.datepicker} selected={newSharesAcquirer.subscriptionAt} onChange={(e) => vm.setSubscriptionAt(e, idx, props.defaultSubscriptionAt)} />
                                            {(idx === 0 && props.newSharesAcquirers.length > 1 ) && <button type="button" className={st.same} onClick={() => vm.sameSubscriptionAt()}>동일 일자 전체적용</button>}
                                        </td>
                                        <td rowSpan={newSharesAcquirer.stocks.length}>
                                            <DatePicker name="options.subscriptionAt" className={st.datepicker} selected={newSharesAcquirer.paymentAt} onChange={(e) => vm.setPaymentAt(e, idx)} />
                                            {(idx === 0 && props.newSharesAcquirers.length > 1 ) && <button type="button" className={st.same} onClick={() => vm.samePaymentAt()}>동일 일자 전체적용</button>}
                                        </td>
                                    </>
                                    }
                                    <td className={st.stockName}>{stock.stockName}</td>
                                    <td className={st.paymentAmount}>{addCommas(stock.paymentAmount)} 원</td>
                                    <td className={st.stockAmount}>{addCommas(stock.stockAmount)} 주</td>
                                    <td className={st.issuePrice}>{addCommas(stock.issuePrice)} 원</td>
                                </tr>
                            ))}
                        </tbody>
                    )
                })}
            </table>
            <div className={st.btnArea}>
                <SquareBtn className={st.submitBtn} onClick={() => vm.submit(props.agendaProgressId)}>입력 완료</SquareBtn>
            </div>
        </div>    
    ));
}
export default AddDate;