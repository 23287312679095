import { observable } from 'mobx';

interface State {
    pageStatus: 'Security' | 'Information';
}

class ShareholderMeetingsWaiverConsentAndNewStockIssueVm  {
    state: State = observable({
        pageStatus: 'Security'
    })
    changeInformationPage() {
        this.state.pageStatus = 'Information';
    }
    changeSecurityPage() {
        this.state.pageStatus = 'Security';
    }
}   

export default ShareholderMeetingsWaiverConsentAndNewStockIssueVm;