import { useObserver } from 'mobx-react';
import AgreeStatePopupVm from './AgreeStatePopupVm';
import st from './AgreeStatePopup.module.scss';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import ErrorText from '@standby/common-ui/components/atoms/Text/ErrorText/ErrorText';
import { addCommas } from '@common/module/replaceNumber';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import regExp from '@common/module/regExp';
import QuestionTooltip from '@standby/common-ui/components/molecules/QuestionTooltip/QuestionTooltip';
import useComponentWillMount from '@common/hook/useComponentWillMount';

import type { Option, SendDocumentType, Status } from '@service/Corp/Agenda/Step/FinalCheck/BeforeFinal/documentService.interface';

let vm: AgreeStatePopupVm;

interface AgreeStatePopupProps {
    agendaProgressId: string;
    documentType: SendDocumentType;
    statusType: "수신동의" | "주주동의_주주총회" | "주주동의_주주총회_신주발행" | "기간단축_동의서" | "신주식_인수포기_및_기간_단축_동의서" | null;
    options?: Option;
    page?: string;
    formData: FormData;
}

function AgreeStatePopup(props: AgreeStatePopupProps) {

    useComponentWillMount(() => {
        vm = new AgreeStatePopupVm;
        vm.load(props.agendaProgressId, props.documentType, props.page)
    });

    return useObserver(() => (
        <form className={st.agreeStatePopup} onSubmit={(e) => vm.submit(e, props.agendaProgressId, props.formData, props.page)}>
            <div style={{textAlign: 'right'}}>
                <div className={st.explain}>메일과 알림톡 중 하나만 보내도 되어요.</div>
            </div>
            <table className={st.table}>
                <colgroup>
                    <col width="78" />
                    <col width="78" />
                    <col width="267" />
                    <col width="97" />
                    <col width="97" />
                    <col width="162" />
                    <col width="96" />
                    <col />
                </colgroup>
                <thead>
                    <tr>
                        <th>
                            <CheckBox className={st.checkAll} checked={vm.emailAll} onChange={(e) => vm.setEmailAll(e)}>메일</CheckBox>
                        </th>
                        <th>
                            <CheckBox className={st.checkAll} checked={vm.alimTalkAll} onChange={(e) => vm.setAlimTalkAll(e)}>알림톡</CheckBox>
                        </th>
                        <th>주주명</th>
                        <th>동의여부</th>
                        <th>동의일자</th>
                        <th>주식종류</th>
                        <th>주식수</th>
                        <th>지분율</th>
                    </tr>
                </thead>
                {vm.shareholders.map((shareholder, idx) => (
                    <tbody key={idx}>
                        {shareholder.stocks.map((stock, idx2) => (
                            <tr key={idx2}>
                                {idx2 === 0 && 
                                <>
                                    <td rowSpan={shareholder.stocks.length} className={st.checkbox}>
                                        <CheckBox disabled={shareholder.status === "동의완료"} value={shareholder.id} checked={vm.email(shareholder.id)} onChange={(e) => vm.setEmail(e, shareholder.id)}/>
                                    </td>
                                    <td rowSpan={shareholder.stocks.length} className={st.checkbox}>
                                        <CheckBox disabled={shareholder.status === "동의완료"} value={shareholder.id} checked={vm.alimTalk(shareholder.id)} onChange={(e) => vm.setAlimTalk(e, shareholder.id)}/>
                                    </td>
                                    <td rowSpan={shareholder.stocks.length}><span className={st.index}>{idx+1}</span>{shareholder.name}</td>
                                    <td rowSpan={shareholder.stocks.length}><span data-status={shareholder.status}>{vm.status(props.statusType, shareholder.status as Status)}</span></td>
                                    <td rowSpan={shareholder.stocks.length}>{shareholder.acceptDate}</td>
                                </>
                                }
                                <td className={st.stockName}>{stock.name}</td>
                                <td className={st.stockNumber}>{addCommas(stock.number)} 주</td>
                                <td className={st.stockRate}>{stock.rate}<span className={st.rate}>%</span></td>
                            </tr>
                        ))}
                    </tbody>
                ))}
                <tfoot>
                    <tr>
                        <td colSpan={8}>총<span className={st.stockTotal}>{addCommas(vm.stockTotal)}</span>주</td>
                    </tr>
                </tfoot>
            </table>
            {vm.state.errorYn && 
                <div>
                    <ErrorText>발송할 주주를 선택하세요.</ErrorText>
                </div>
            }
            <QuestionTooltip title="이메일, 알림톡 미리보기" className={st.questionTooltip}>
                <div className={st.content}>
                    {props.statusType === '수신동의' && <img src='/images/알림톡_예시이미지_주주총회_온라인_소집통지_수신동의서.png' />}
                    {(props.statusType === '기간단축_동의서' || props.statusType === '신주식_인수포기_및_기간_단축_동의서') && 
                        <img src='/images/알림톡_예시이미지_주주동의_신주발행_0304.png' />
                    }
                    {props.statusType === '주주동의_주주총회_신주발행' && <img src='/images/알림톡_예시이미지_주주동의_주주총회_신주발행_0304.png' />}
                    {props.statusType === '주주동의_주주총회' && <img src='/images/알림톡_예시이미지_주주총회_소집절차_생략_동의서.png' />}
                </div>
            </QuestionTooltip>
            {vm.state.checkEmail.length !== 0 && 
                <div className={st.shareholderEmailInfo}>
                    <div className={st.title}>주주의 이메일 주소</div>
                    <div className={st.explain}>입력한 내용은 주주 데이터에 자동으로 저장되어요.</div>
                    {vm.shareholders.map((shareholder, idx) => {
                        if(vm.state.checkEmail.includes(shareholder.id)) {
                            return (
                                <div key={idx} className={st.shareholderEmail}>
                                    <div className={st.name}>{shareholder.name}</div>
                                    <input type="hidden" name={`shareholders[${idx}].id`} value={shareholder.id}/>
                                    <InputText name={`shareholders[${idx}].email`} value={shareholder.email} onChange={(e) => vm.setShareholderEmail(e, idx)}  style={{width:'300px'}} errorText={<Error name={`shareholders[${idx}].email`} value={shareholder.email} errorCase={{
                                        required: '이메일 주소를 입력하세요.',
                                        pattern: {
                                            value: regExp.email().test(shareholder.email),
                                            message: '올바른 이메일 형식으로 입력해 주세요.'
                                        }
                                    }}/>}/>
                                </div>
                            )
                        }
                    })}
                </div>
            }
            {vm.state.checkAlimTalk.length !== 0 && 
                <div className={st.shareholderPhoneNumberInfo}>
                    <div className={st.title}>주주의 휴대폰 번호</div>
                    <div className={st.explain}>입력한 내용은 주주 데이터에 자동으로 저장되어요.</div>
                    {vm.shareholders.map((shareholder, idx) => {
                        if(vm.state.checkAlimTalk.includes(shareholder.id)) {
                            return (
                                <div key={idx} className={st.shareholderPhoneNumber}>
                                    <div className={st.name}>{shareholder.name}</div>
                                    {!vm.state.checkEmail.includes(shareholder.id) && <input type="hidden" name={`shareholders[${idx}].id`} value={shareholder.id}/>}
                                    <InputText name={`shareholders[${idx}].phoneNumber`} value={shareholder.phoneNumber} onChange={(e) => vm.setShareholderPhoneNumber(e, idx)} style={{width:'300px'}} errorText={<Error name={`shareholders[${idx}].phoneNumber`} value={shareholder.phoneNumber} errorCase={{
                                        required: '휴대폰 번호를 입력해 주세요.',
                                        pattern: {
                                            value: regExp.phoneNumber().test(shareholder.phoneNumber || ""),
                                            message: '-를 제외하고 숫자만 넣어주세요.'
                                        }
                                    }}/>}/>
                                </div>
                            )
                        }
                    })}
                </div>
            }
            <div className={st.btnArea}>
                <SquareBtn type="submit" className={st.sendBtn} disabled={vm.state.disabledYn}>발송하기</SquareBtn>
            </div> 
        </form>
    ));
}
export default AgreeStatePopup;