import { observable } from 'mobx';
import DashboardModel from '@model/Corp/Dashboard/DashboardModel';
import portalModel from '@model/portalModel';
import {runInAction} from 'mobx';
import AgendaLinkPopup from '@pages/Corp/Dashboard/AgendaLinkPopup/AgendaLinkPopup';

class PurposesVm {
    state : {
        moreviewYn : boolean
    }
    constructor() {
        this.state = observable({
            moreviewYn: false
        })
    }
    get businessPurposes() {
        return DashboardModel.dashboardData?.businessPurposes || [];
    }
    moreview() {
        this.state.moreviewYn = !this.state.moreviewYn;
    }
    agendaLinkModal() {
        runInAction(() => {
            portalModel.title = "목적 변경하기";
            portalModel.content = <AgendaLinkPopup agendaType="목적"/>;
        })
    }
}
export default new PurposesVm();
