import formDataToJson from '@common/module/submit';
import corpModel from '@model/Corp/corpModel';
import bookKeepingContractModel from '@model/Corp/BookKeepingContract/bookKeepingContractModel';
import bookKeepingContractService from '@service/Corp/BookKeepingContract/bookKeepingContractService';
import { observable, runInAction, toJS } from 'mobx';
import portalModel from '@model/portalModel';
import { _alert, _confirm } from "@model/dialogModel";
import ProvideInformationTermsModal from '../ProvideInformationTermsModal/ProvideInformationTermsModal';

class DelegationPopupVm {
    constructor() {
        this.state = observable({
            agreeYn: false
        })
    }
    setAgreeYn(e) {
        this.state.agreeYn = e.target.checked;
    }
    async register() {
        if(!this.state.agreeYn) {
            return _alert('정보제공 동의를 해주세요.');
        } else {
            await bookKeepingContractService.agree(bookKeepingContractModel.term.id);
            runInAction(() => {
                portalModel.title = null;
                portalModel.content = null;
            })
        }
    }
    get delegationRegistrationAgreeYn() {
        return bookKeepingContractModel.delegationRegistrationAgreeYn;
    }
    agreeModal(){
        portalModel.title = '진평회계법인에 대한 정보제공 동의';
        portalModel.content = <ProvideInformationTermsModal content={bookKeepingContractModel.term.content}/>
    }
}
export default new DelegationPopupVm();