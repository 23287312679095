import formDataToJson from '@common/module/submit';
import corpModel from '@model/Corp/corpModel';
import bookKeepingContractModel from '@model/Corp/BookKeepingContract/bookKeepingContractModel';
import bookKeepingContractService from '@service/Corp/BookKeepingContract/bookKeepingContractService';
import { observable, runInAction, toJS } from 'mobx';
import portalModel from '@model/portalModel';
import { _alert, _confirm } from "@model/dialogModel";

class CMSAccountPopupVm {
    constructor() {
        this.state = observable({
            loadingYn: false
        });
    }
    async load(param) {
        let _param = {};
        if(bookKeepingContractModel.bankbooks && bookKeepingContractModel.bankbooks.length !== 0) {
            const bankInfo = bookKeepingContractModel.bankbooks.find(item => String(item.id) === param)
            if(bankInfo) {
                const { bankName, accountNumber } = bankInfo;
                _param.bankName = bankName;
                _param.accountNumber = accountNumber;
            }
        } else {
            param.forEach((value, key) => {
                if(!key.includes('file')) {
                    _param[key]= value;
                }
            });
        }
        await bookKeepingContractService.cmsDirectDebitApplication(_param);
    }
    get cmsDirectDebitApplicationLink() {
        return bookKeepingContractModel.cmsDirectDebitApplicationLink;
    }
    async electronicSignature(param) {
        try {
            this.state.loadingYn = true;

            if(bookKeepingContractModel.bankbooks && bookKeepingContractModel.bankbooks.length !== 0) {
                await bookKeepingContractService.cmsElectronicSignature(param);
            } else {
                await bookKeepingContractService.cmsElectronicSignatureNew(param);
            }

            this.state.loadingYn = false;

            runInAction(() => {
                portalModel.title = null;
                portalModel.content = null;
            })
            await _alert('전자서명이 완료되었어요.', '서명된 PDF파일을 다운로드 받을 수 있어요.');
            window.location.reload();
        } catch(err) {
            this.state.loadingYn = false;
        }
    }   
}

export default new CMSAccountPopupVm();