import { get } from '@common/module/httpRequest';
import { runInAction } from 'mobx'; 
import type {LoadRs} from './AoaDetailService.interface';
import AoaDetailModel from '@model/AgendaDashboard/AoaDetail/AoaDetailModel'; 

class AoaDetailService {
    async load(cssn : string) {
        const res = await get<LoadRs>(`/company/agendaProgress/dashboard/aoa?cssn=${cssn}`);
        runInAction(() => {
            AoaDetailModel.articlesOfAssociations = res.data.articlesOfAssociations.map((articlesOfAssociation) => {
                return {
                    category : articlesOfAssociation?.category || "",
                    questions : articlesOfAssociation?.questions.map((question) => {
                        return {
                            title : question?.title || "",
                            content : question?.content || ""
                        }
                    })
                }
            })
        })
    }
}
export default new AoaDetailService();