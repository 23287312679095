import { useObserver } from 'mobx-react';
import st from '../Agendas.module.scss';
import type {Agenda, IA신주발행_유상증자} from '@model/Corp/Agenda/Step/FinalCheck/BeforeFinal/AgendaView/AgendaViewModel.interface';
import Table from '@standby/common-ui/components/atoms/Table/Table';
import { addCommas } from '@common/module/replaceNumber';
import Cst from './C신주발행_유상증자.module.scss';
import vm from './C신주발행_유상증자Vm';

interface Props {
    agendaIndex : number,
    agenda : Agenda<IA신주발행_유상증자>
}

function C신주발행_유상증자(props : Props) {
    return useObserver(() => (
        <div className={st.agenda}>
            <div className={st.title}>
                <div className={st.iconTitle}>안건</div>
                <div className={st.number}>{props.agendaIndex + 1}</div>
                <div className={st.text}><span className={st.important}>신주발행</span> (유상증자)</div>
                {props.agenda.decisionBody.map((decisionBody, index) => (
                    <div className={st.badge} key={index}>{decisionBody}</div>
                ))}
            </div>
            <div className={st.info}>
                <ul className={st.agendaList}>
                    <li>
                        발행방식 : {props.agenda.info.assignmentType}
                    </li>
                    <li>
                        통지방식 : {props.agenda.info.noticeType}
                    </li>
                </ul>
                <Table className={Cst.table}>
                    <colgroup>
                        <col width="160"/>
                        <col/>
                        <col width="85"/>
                        <col/>
                        <col width="110"/>
                        <col width="110"/>
                        <col width="130"/>
                        <col width="90"/>
                        <col width="90"/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>신주인수인</th>
                            <th>인수자격</th>
                            <th>신주대금<br/>입금방법</th>
                            <th>주식종류</th>
                            <th>투자금</th>
                            <th>주식수</th>
                            <th>발행가액(자동계산)</th>
                            <th>청약일</th>
                            <th>납입일</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.agenda.info.newSharesAcquirers.map((newSharesAcquirer, index) => (
                            newSharesAcquirer.stocks.map((stock, index2) => (
                                index2 === 0 ?
                                <tr key={'p'+index+'s'+index2}>
                                    <td rowSpan={newSharesAcquirer.stocks.length} style={{display:"flex"}}>
                                        <span className={Cst.number}>{index+1}</span>
                                        <span className={Cst.name}>{newSharesAcquirer.name}</span>
                                    </td>
                                    <td rowSpan={newSharesAcquirer.stocks.length} style={{textAlign:"center"}}>
                                        {newSharesAcquirer.인수자격}
                                    </td>
                                    <td rowSpan={newSharesAcquirer.stocks.length} style={{textAlign:"center"}}>
                                        {newSharesAcquirer.resourceType}
                                    </td>
                                    <td>
                                        {stock.stockName}
                                    </td>
                                    <td style={{textAlign:"right"}}>
                                        {addCommas(stock.investment)} 원
                                    </td>
                                    <td style={{textAlign:"right"}}>
                                        {addCommas(stock.number)} 주
                                    </td>
                                    <td style={{textAlign:"right"}}>
                                        {addCommas(stock.issuePrice)} 원
                                    </td>
                                    <td rowSpan={newSharesAcquirer.stocks.length}>
                                        {newSharesAcquirer.subscriptionAt}
                                    </td>
                                    <td rowSpan={newSharesAcquirer.stocks.length}>
                                        {newSharesAcquirer.subscriptionAt}
                                    </td>
                                </tr>
                                :
                                <tr key={'p'+index+'s'+index2}>
                                    <td>
                                        {stock.stockName}
                                    </td>
                                    <td style={{textAlign:"right"}}>
                                        {addCommas(stock.investment)} 원
                                    </td>
                                    <td style={{textAlign:"right"}}>
                                        {addCommas(stock.number)} 주
                                    </td>
                                    <td style={{textAlign:"right"}}>
                                        {addCommas(stock.issuePrice)} 원
                                    </td>
                                </tr>
                            ))
                        ))}
                        <tr className={Cst.sum}>
                            <td colSpan={5}>총 <span className={Cst.important}>{addCommas(vm.sum(props.agenda.info).investment)}</span> 원</td>
                            <td>총 <span className={Cst.important}>{addCommas(vm.sum(props.agenda.info).number)}</span> 주</td>
                            <td colSpan={4}></td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </div>
    ));
}
export default C신주발행_유상증자;