import onlineAcceptancePopupModel from "@model/Corp/Popup/OnlineAcceptancePopup/onlineAcceptancePopupModel";

class OnlineAcceptancePopupVm {
    load() {
        onlineAcceptancePopupModel.nowStep = "1";
    }
    get nowStep() {
        return onlineAcceptancePopupModel.nowStep;
    }
}
export default new OnlineAcceptancePopupVm();