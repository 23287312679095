import { observable, makeObservable } from 'mobx';

class portalModel {
    constructor() {
        makeObservable(this, {
            content : observable,
            title : observable,
            closeBtnDarkYn : observable
        });
    }
    content : JSX.Element | null = null;
    title : string | null = null;
    closeBtnDarkYn : boolean = false;
    closeBtn : boolean = true;
    radius : boolean = false;
}

export default new portalModel();