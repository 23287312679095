import agendaStepModel from '@model/Corp/Agenda/Step/agendaStepModel';
import { removeCommas, onlyNumber } from '@common/module/replaceNumber';
import type {SelectedAgenda, I등기신청을_위한_추가_정관변경} from '@model/Corp/Agenda/Step/agendaStepModel.interface';

class AdditionalAoAChangeVm {
    selectedAgenda(index : number) {
        return agendaStepModel.selectedAgendas[index] as SelectedAgenda<I등기신청을_위한_추가_정관변경>
    }
    보통주_issueLimit(e : React.ChangeEvent<HTMLInputElement>, index : number, index2 : number) {
        this.selectedAgenda(index).agendaDetail.articlesOfAssociations[index2].latest.보통주_issueLimit = removeCommas((onlyNumber(e.target.value)).toString());
    }
    우선주_issueLimit(e : React.ChangeEvent<HTMLInputElement>, index : number, index2 : number) {
        this.selectedAgenda(index).agendaDetail.articlesOfAssociations[index2].latest.우선주_issueLimit = removeCommas((onlyNumber(e.target.value)).toString());
    }
}
export default new AdditionalAoAChangeVm();