
import documentModel from "@model/Corp/Agenda/Step/FinalCheck/BeforeFinal/documentModel";
import documentService from "@service/Corp/Agenda/Step/FinalCheck/BeforeFinal/documentService";
import portalModel from "@model/portalModel";
import { runInAction } from "mobx";
import { _alert } from "@model/dialogModel";
import AgreeStatePopup from "@pages/Corp/Popup/AgreeStatePopup/AgreeStatePopup";
import formDataToJson from '@common/module/submit';

import type { LoadDocumentParam } from "@service/Corp/Agenda/Step/FinalCheck/BeforeFinal/documentService.interface";

class DocumentVm {
    load(agendaProgressId: string) {
        const param = {
            documentType: "소집절차_생략_동의서"
        } as LoadDocumentParam;
        documentService.loadDocument(agendaProgressId, param); 
    }
    next(e: React.FormEvent<HTMLFormElement>, agendaProgressId: string) {
        e.preventDefault();
        if(formDataToJson(e.target).error) {
            return
        } else {
            let param_formData = new FormData(e.target as HTMLFormElement);

            runInAction(() => {
                portalModel.title = '2. 발송할 주주를 선택하세요.';
                portalModel.content = <AgreeStatePopup agendaProgressId={agendaProgressId} documentType="소집절차_생략_동의서" statusType="주주동의_주주총회" formData={param_formData}/>
            })
        }
    }
    get previewLink() {
        return documentModel.previewLink;
    }

}

export default new DocumentVm();