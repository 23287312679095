import { post, get } from '@common/module/httpRequest';
import { runInAction, toJS } from 'mobx'; 
import companyRegistrationModel from '@model/companyRegistrationModel';
import { pageRouter } from '@model/pageModel';

class companyRegistrationService {
    async metroplitanList() { //광역자치단체 목록조회
        const res = await get(`/companyRegister/metropolitanList`);
        const data = res.data;
        runInAction(() => {
            companyRegistrationModel.set광역자치단체s(data);
        })
    }
    async search(companyData) { //법인 검색
        const res = await get(`/companyRegister/search`, companyData, {});
        const data = res.data;
        runInAction(() => {
            companyRegistrationModel.setSearchResults(data);
        })
    }
    async registrationState(registrationStateData) { //법인 등록 여부 조회
        const res = await get(`/companyRegister/isRegistered`, registrationStateData,  {})
        const data = res.data;
        return data;
    }
    async register(data) { //법인 등록
        const res = await post(`/companyRegister`, data, {});
        companyRegistrationModel.company = {
            companyType: "",
            companyName: "",
            address: "",
            office: "",
            registerNum: ""
        };
        pageRouter.replace(`/corp/register?corp=${res.data.companyId}`);
    }
}

export default new companyRegistrationService();
