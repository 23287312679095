
import { observable, runInAction } from "mobx";
import { _alert, _confirm } from "@model/dialogModel";
import corpModel from '@model/Corp/corpModel';
import InternetAssociateService from '@service/Corp/InternetAssociate/InternetAssociateService';

class Step1Vm {
    state : {
        tab : {title : string, content : string, opened : boolean}[]
    }
    constructor() {
        this.state = observable({
            tab: [
                {
                    title : '진행은 어떻게 되나요?',
                    content : '1. [캐시백 가능 상품 확인하기] 버튼을 눌러주세요.<br/>2. 신청을 원하시는 상품을 골라주세요.<br/>3. 상담접수 후, 1 영업일 내 담당자가 전화를 드릴 예정이에요.<br/>4. 전화상담 후 개통이 완료되면 캐시백 수령이 가능해요.',
                    opened : false
                },
                {
                    title : '나중에 상품을 추가할 수 있나요?',
                    content : '네, 가능해요.<br/>추가 건도 캐시백 지급 기준에 부합하다면 캐시백을 받으실 수 있어요!',
                    opened : false
                }
            ]
        })
    }
    toggleTab(index : number) {
        runInAction(() => {
            this.state.tab[index].opened = !this.state.tab[index].opened;
        })
    }
    async moveStep2(guestYn : boolean = false) {
        if(guestYn) {
            if(await _confirm('스탠바이 회원가입 후 신청 가능해요!', '기업용 인터넷·전화 캐시백은 스탠바이 등록법인에게만 제공되는 혜택이에요. 회원가입 후 기업용 인터넷·전화 캐시백과 함께 다양한 무료 법인관리 기능을 이용해보세요.', '신청하기', '닫기')) {
                window.location.href = `/auth/joinmembership`;
            }
        }else{
            await InternetAssociateService.consult();
            window.location.href = `/corp/internetAssociate/application?corp=${corpModel.id}`;
        }
    }
}
export default new Step1Vm();