
import { useObserver } from 'mobx-react';
import vm from './FinalVm';
import { useEffect } from 'react';
import queryString from 'query-string';
import { useLocation } from "react-router-dom";
import st from './Final.module.scss';
import RegistrationAgency from './RegistrationAgency/RegistrationAgency';
import Self from './Self/Self';
import clsx from 'clsx';
import Meetings from './Meetings/Meetings';

function Final() {

    let location = useLocation();
    const agendaProgressId = queryString.parse(location.search).agendaProgressId as string;
    
    useEffect(() => {
        vm.load(agendaProgressId);
    }, []);
    return useObserver(() => (
        <>
            <div className={st.mainTitle}>진행완료</div>
            <div className={st.finalContent}>
                {vm.completed === false && 
                    <div className={st.completedYet}>
                        <div className={st.infoText}>스탠바이에서 내용을 확인하고 있어요.<br/>확인 후 반영처리를 해드릴게요.</div>
                        <div className={st.infoSub}>최대 1영업일 소요</div>
                    </div>
                }
                <div className={st.successNotice}>{vm.agendaPeriod} <span className={st.important}>진행완료</span>된 안건이에요. {vm.registrationAgencyType === "NONE" && "(등기 불필요)"}</div>
                {(vm.registrationAgencyType === "REGISTRATION_AGENCY" || vm.registrationAgencyType === "ALREADY_PROGRESSED_REGISTRATION_AGENCY") && 
                    <RegistrationAgency/>
                }
                {vm.registrationAgencyType === "SELF" && 
                    <Self/>
                }
                {vm.meetingSchedule.length !== 0 &&
                    <div className={clsx(st.meetingSchedule, st.content)}>
                        <div className={st.title} style={{marginBottom : "16px"}}>일정 & 안건</div>
                        {(vm.registrationAgencyType === "REGISTRATION_AGENCY" || vm.registrationAgencyType === "ALREADY_PROGRESSED_REGISTRATION_AGENCY") && 
                            <div className={st.regAlarm} style={{marginBottom : vm.memo ? "12px" : "24px"}}>등기대행업체와 협의하에 변경한 내용이 있다면, 아래는 실제 등기내용과 다를 수 있어요.</div>
                        }
                        {vm.registrationAgencyType === "SELF" && 
                            <div className={st.regAlarm} style={{marginBottom : vm.memo ? "12px" : "24px"}}>등기진행시 변경한 내용이 있다면, 아래는 실제 등기내용과 다를 수 있어요.</div>
                        }
                        {vm.memo && 
                            <div className={st.memo}>
                                <div className={st.title}>헬프미 법률사무소 메모</div>
                                <div className={st.memoContent} dangerouslySetInnerHTML={{ __html: vm.memo }}/>
                            </div>
                        }
                        <Meetings opener="final"/>
                    </div>
                }
            </div>
        </>
    ));
}
export default Final;