
import { useObserver } from 'mobx-react';
import { useEffect, useState } from 'react';
import { reaction } from 'mobx';
import DocumentVm from './DocumentVm';
import st from './Document.module.scss';
import clsx from 'clsx';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import Radio from '@standby/common-ui/components/atoms/Input/Radio/Radio';
import useComponentWillMount from '@common/hook/useComponentWillMount';
import ToolTip from '@standby/common-ui/components/molecules/ToolTip/ToolTip';

let vm: DocumentVm;

interface DocumentProps {
    agendaProgressId: string;
}

function Document(props: DocumentProps) {
    const [moreDocumentYn, setMoreDocumentYn] = useState(true);
    const [scrollActiveYn, setScrollActiveYn] = useState(false);

    const height = document.body.offsetHeight;  
    const half = height - 250;

    useComponentWillMount(() => {
        vm = new DocumentVm;
        vm.load(props.agendaProgressId);
    });

    useEffect(() => {
        reaction(
            () => vm.previewLink,
            (previewLink: string) => {
                if(previewLink) {
                    const previewFrame = document.getElementById('previewFrame') as HTMLIFrameElement;
                    if(previewFrame && previewFrame.contentWindow) {
                        previewFrame.contentWindow.location.replace(previewLink);

                        window.addEventListener('message', function(e) {
                            if(typeof(e.data) === "string") {
                                if(JSON.parse(e.data)?.height) {
                                    const iframeHeight = JSON.parse(e.data).height + 200;
                                    previewFrame.style.height = iframeHeight + 'px';
                                }
                            }
                        });
                    }
                }
            }
        )
    }, []);

    useEffect(() => {
        const scrollableDiv = document.getElementById('scrollableDiv');

        const handleScroll = () => {
            setScrollActiveYn(true)
            const timer = setTimeout(() => {
                setMoreDocumentYn(false)
            }, 900);
            return () => clearTimeout(timer);
        };
    
        if (moreDocumentYn && scrollableDiv) {
            scrollableDiv.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (scrollableDiv) {
                scrollableDiv.removeEventListener('scroll', handleScroll);
            }
        };
    }, [moreDocumentYn]);

    return useObserver(() => (
        <div className={st.document}>
            <div className={st.title}>{vm.isRegular} 주주총회 소집통지서</div>
            <div className={st.contentBox}>
                <div className={st.documentBox}>
                    {moreDocumentYn && <div className={clsx(st.moreDocument, scrollActiveYn && st.scroll)} style={{top: half+'px'}}></div>}
                    <div className={st.content} id="scrollableDiv">
                        <div className={st.iframeDiv}>
                            <iframe id='previewFrame' title="주주총회 소집통지서 미리보기" className={st.iframeDocument}/>
                        </div>
                    </div>
                </div>
                <form className={st.documentOption} onSubmit={(e) => vm.submit(e, props.agendaProgressId)}>
                    <div className={st.optionSeparate}>
                        <div className={st.optionBox}>
                            <div className={st.optionTitle}>글자크기</div>
                            <div className={st.option}>
                                <Radio className={st.radio} name="options.fontSize" value="SMALL" checked={vm.state.options.fontSize === 'SMALL'} onChange={(e) => vm.setFontSize(e, props.agendaProgressId)}>작게</Radio>
                                <Radio className={st.radio} name="options.fontSize" value="MEDIUM" checked={vm.state.options.fontSize === 'MEDIUM'} onChange={(e) => vm.setFontSize(e, props.agendaProgressId)}>중간</Radio>
                                <Radio className={st.radio} name="options.fontSize" value="LARGE" checked={vm.state.options.fontSize === 'LARGE'} onChange={(e) => vm.setFontSize(e, props.agendaProgressId)}>크게</Radio>
                            </div>
                        </div>
                        <div className={st.optionBox}>
                            <div className={st.optionTitle}>줄간격</div>
                            <div className={st.option}>
                                <Radio className={st.radio} name="options.lineSpacing" value="SMALL" checked={vm.state.options.lineSpacing === 'SMALL'} onChange={(e) => vm.setLineSpacing(e, props.agendaProgressId)}>작게</Radio>
                                <Radio className={st.radio} name="options.lineSpacing" value="MEDIUM" checked={vm.state.options.lineSpacing === 'MEDIUM'} onChange={(e) => vm.setLineSpacing(e, props.agendaProgressId)}>중간</Radio>
                                <Radio className={st.radio} name="options.lineSpacing" value="LARGE" checked={vm.state.options.lineSpacing === 'LARGE'} onChange={(e) => vm.setLineSpacing(e, props.agendaProgressId)}>크게</Radio>
                            </div>
                        </div>
                    </div>
                    <div className={st.optionSeparate}>
                        <div className={st.optionBox}>
                            <div className={st.optionTitle}>의결권 대리행사 위임장</div>
                            <div className={st.option}>
                                <Radio className={st.radio} name="options.의결권_대리행사_위임장Yn" value="true" checked={vm.state.options.의결권_대리행사_위임장Yn === true} onChange={(e) => vm.set의결권_대리행사_위임장Yn(e, props.agendaProgressId)}>포함</Radio>
                                <Radio className={st.radio} name="options.의결권_대리행사_위임장Yn" value="false" checked={vm.state.options.의결권_대리행사_위임장Yn === false} onChange={(e) => vm.set의결권_대리행사_위임장Yn(e, props.agendaProgressId)}>미포함</Radio>
                            </div>
                        </div>
                        <div className={st.optionBox}>
                            <div className={st.optionTitle}>주주총회 서면의결서</div>
                            <div className={st.option}>
                                <Radio className={st.radio} name="options.주주전원_서면의결서Yn" value="true" checked={vm.state.options.주주전원_서면의결서Yn === true} onChange={(e) => vm.set주주전원_서면의결서Yn(e, props.agendaProgressId)}>포함</Radio>
                                <Radio className={st.radio} name="options.주주전원_서면의결서Yn" value="false" checked={vm.state.options.주주전원_서면의결서Yn === false} onChange={(e) => vm.set주주전원_서면의결서Yn(e, props.agendaProgressId)}>미포함</Radio>
                            </div>
                        </div>
                        <div className={st.optionBox}>
                            <div className={st.optionTitle}>
                                별지 서류 첨부
                                <ToolTip className={st.toolTip} tooltipContentStyle={{width:'171px'}}>
                                    소집통지서 뒷부분에 첨부할 서류가 있다면 업로드하세요. ex) 신규 취임할 임원의 이력서, 주주총회 장소 안내 지도 등
                                </ToolTip>
                                <div className={st.optionTitleExplain}>첨부 서류 형식은 PDF만 가능해요.</div>
                            </div>
                            <div className={st.option}>
                                <label className={st.fileUpload} htmlFor="fileUpload">
                                    클릭하여 업로드
                                </label>
                                {vm.state.attachmentFiles.length !== 0 && 
                                    <ul className={st.uploadFileList}>
                                        {vm.state.attachmentFiles.map((file, idx) => (
                                            <li key={idx} className={st.file}>
                                                {file.name}
                                                <button type="button" className={st.deleteBtn} onClick={() => vm.fileDelete(idx)}/>
                                            </li>
                                        ))}
                                    </ul>
                                }
                                <input type="file" id="fileUpload" name="attachmentFiles" style={{width:0, height:0, position:"absolute", display: "none"}} onChange={(e) => vm.fileUpload(e)} multiple accept='.pdf' />
                            </div>
                        </div>
                    </div>
                    {vm.showRepresentativeNameSelectionYn && 
                        <div className={st.optionSeparate}>
                            <div className={st.optionBox}>
                                <div className={st.optionTitle}>서류 아래에 기재할 대표님 이름</div>
                                <div className={st.option}>
                                    {vm.representatives?.map((item, idx) => (
                                        <Radio key={idx} className={clsx(st.radio, st.wrap)} name="representativeId" value={item.id} checked={vm.state.representativeId === String(item.id)} onChange={(e) => vm.setRepresentativeId(e, props.agendaProgressId)}>{item.name}님</Radio>
                                    ))}
                                    <Radio className={clsx(st.radio, st.wrap)} name="representativeId" value="ALL" checked={vm.state.representativeId === 'ALL'} onChange={(e) => vm.setRepresentativeId(e, props.agendaProgressId)}>대표님 모두</Radio>
                                </div>
                            </div>
                        </div>
                    }
                    <div className={st.optionSeparate}>
                        <div className={st.optionBox}>
                            <div className={st.optionTitle}>인감날인</div>
                            <div className={st.option}>
                                <Radio className={st.radio} name="options.onlineStampSealYn" value="true" checked={vm.state.options.onlineStampSealYn === true} onChange={(e) => vm.setOnlineStampSealYn(e, props.agendaProgressId)}>포함</Radio>
                                <Radio className={st.radio} name="options.onlineStampSealYn" value="false" checked={vm.state.options.onlineStampSealYn === false} onChange={(e) => vm.setOnlineStampSealYn(e, props.agendaProgressId)}>미포함</Radio>
                            </div>
                            <div className={st.btnArea}>
                                <SquareBtn className={st.nextBtn} type="submit">다음 페이지</SquareBtn>
                            </div>
                        </div>
                    </div>
                    <input type="hidden" name="documentType" value="소집통지서"/>
                </form>
            </div>
            
        </div>
    ));
}
export default Document;