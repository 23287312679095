import { useObserver } from 'mobx-react';
import { useEffect } from 'react';
import bst from '../Basic.module.scss';
import st from './ProgressStatus.module.scss';
import vm from './ProgressStatusVm';
import FileBtn from '@standby/common-ui/components/atoms/Button/FileBtn/FileBtn';
import CopyBtn from '@standby/common-ui/components/atoms/Button/CopyBtn/CopyBtn';

function ProgressStatus() {

    useEffect(() => {
        vm.load();
    }, [vm.businessRegistrationStatuses.length]);

    return useObserver(() => (
        <div className={bst.registraionRow}>
            <div className={bst.registrationTitle}>
                <span className={bst.bold}>사업자등록</span>은<br/>이렇게 <span className={bst.bold}>진행</span>
                {vm.statusType === '정보_입력' && '돼요.'}
                {(vm.statusType === '정보입력_완료' || vm.statusType === '세무서_접수')&& '되고 있어요.'}
                {vm.statusType === '사업자등록_완료' && '되었어요.'}
            </div>
            <div className={bst.registrationContent}>
                <div className={st.progressContent}>
                    <div className={st.progress}>
                        <img src={`/images/${vm.statusType}.svg`} className={st.progressImg} />
                        {vm.businessRegistrationStatuses.map((status, idx) => (
                            <div className={st.statusInfo} style={{left:`${19+(53*(idx)+(idx*97))}px`, bottom:'7px'}} key={idx}>
                                <div className={st.statusStartAt}>{status.startAt}</div>
                                <div className={st.statusText}>
                                    {status.status === '정보_입력' && '입력시작'}
                                    {status.status === '정보입력_완료' && '검토시작'}
                                    {status.status === '세무서_접수' && '접수완료'}
                                    {status.status === '사업자등록_완료' && '등록완료'}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {vm.statusType === '정보입력_완료' && 
                    <div className={st.informationInputComplete}>
                        <div>사업자등록을 위해 회계법인에서 검토를 하고 있어요!</div>
                        <div>검토를 빠르게 마친 후, 회계법인 담당자가 대표님께 연락드릴게요.</div>
                    </div>
                }
                {vm.statusType === '세무서_접수' && 
                    <div className={st.taxOfficeReceive}>
                        <div className={st.title}>세무서 접수증</div>
                        <div className={st.thumbnailFile}>
                            <img src={vm.filingReceiptThumbnailFile} className={st.img}/>
                        </div>
                        <FileBtn btnType="important" className={st.downloadBtn} onClick={() => vm.taxOfficeFileDownload()}>다운로드</FileBtn>
                        <div className={st.explain}>사업자등록이 접수되었어요. 사업자등록이 완료되면 바로 연락드릴게요!</div>
                    </div>
                }
                {vm.statusType === '사업자등록_완료' && 
                    <div className={st.registerComplete}>
                        <div className={st.document}>
                            <div className={st.title}>사업자등록 완료</div>
                            <div className={st.thumbnailFile}>
                                <img src={vm.businessRegistrationThumbnailFile} className={st.img}/>
                            </div>
                            <FileBtn btnType="important" className={st.downloadBtn} onClick={() => vm.businessRegistrationFileDownload()}>다운로드</FileBtn>
                            <div className={st.registerNumber}>
                                사업자등록번호<CopyBtn copyText={vm.businessRegistrationNumber} className={st.copyBtn}>{vm.businessRegistrationNumber}</CopyBtn>
                            </div>
                        </div>
                        <div className={st.sendDocument}>
                            <div className={st.title}>클릭 한 번으로 서류를 보내세요!</div>
                            <button type="button" className={st.documentSendBtn} onClick={() => vm.sendDocument()}>
                                <div className={st.btnText}>사업자등록증 & 통장사본 보내기</div>
                                <div className={st.explain}>이메일, 카카오톡으로 바로 전송가능!</div>
                            </button>
                        </div>
                    </div>
                }
            </div>
        </div>   
    ));
}
export default ProgressStatus;