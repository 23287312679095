import { useObserver } from 'mobx-react';
import vm from './AppplicationCheckVm';
import st from './AppplicationCheck.module.scss';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';

interface ApplicationCheckProps {
    type: "SELF" | "불포함";
    agendaProgressId: string;
    newSharesIncludeYn: boolean;
}

function AppplicationCheck(props: ApplicationCheckProps) {
    return useObserver(() => (
        <div className={st.modalContent}>
            <div>아래 절차를 모두 잘 마치셨다면, 버튼을 눌러주세요.</div>
            <div className={st.processContent}>
                <ul>
                    {props.type === "SELF" && <li>새로운 등기부 업로드</li>}
                    {props.type === "불포함" && <li>도장을 찍은 의사록 업로드</li> }
                    <li>필요한 절차 진행 <span className={st.light}>(주주총회, 이사회, 대표결정 등)</span></li>
                    <li>소집통지 또는 생략동의 <span className={st.light}>(주주총회시)</span></li>
                    {props.newSharesIncludeYn && <li>신주발행통지 또는 생략동의 <span className={st.light}>(신주발행시)</span></li>}
                </ul>
            </div>
            {props.type === "불포함" && <div className={st.explianBox}>종료 처리 후에는 내용변경, 퉁지발송이 불가능해요.</div>}
            <div className={st.btnArea}>
                <SquareBtn className={st.applyBtn} onClick={() => vm.submit(props.agendaProgressId)}>
                    {props.type === "SELF" && '최종 반영하기'}
                    {props.type === "불포함" && '종료 처리하기'}
                </SquareBtn>
                {props.type === "SELF" && <div className={st.explain}>최종 반영 후에는 삭제, 취소가 제한되어요.</div>}
            </div>
        </div>
    ));
}
export default AppplicationCheck;