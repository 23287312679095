import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { Fragment, useEffect } from 'react';
import DownloadVm from './DownloadVm';
import st from './Download.module.scss';
import clsx from 'clsx';
import Radio from '@standby/common-ui/components/atoms/Input/Radio/Radio';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import DatePicker from '@standby/common-ui/components/molecules/DatePicker/DatePicker';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import useComponentWillMount from '@common/hook/useComponentWillMount';
import { reaction } from 'mobx';
import Loading from '@commonComponents/Loading/Loading';

let vm;

function Download(props) {

    useComponentWillMount(() => {
        vm = new DownloadVm;
    })

    useEffect(() => {
        vm.load();
        reaction(
            () => vm.previewLink,
            previewLink => {
                if(previewLink) {
                    window.frames['previewFrame'].location.replace(previewLink);
                }
            }
        )
    }, []);

    return useObserver(() => (
        <form className={st.shareholderList} onSubmit={(e) => vm.submit(e, props.next, props.setSendParams)}>
            {vm.state.loadingYn && <Loading />}
            <div className={st.title}>주주명부</div>
            <div className={st.contentBox}>
                <div className={st.documentBox}>
                    <div className={st.content}>
                        <iframe name='previewFrame' title="주주명부 미리보기" className={st.document}/>
                    </div>
                </div>
                <div className={st.documentOption}>
                    <div className={st.optionSeparate}>
                        <div className={st.optionBox}>
                            <div className={st.optionTitle}>작성일자</div>
                            <div className={st.option}>
                                <DatePicker name="createdAt" className={st.datepicker} selected={vm.state.createdAt} onChange={(e) => vm.setCreatedAt(e)} minDate={vm.minDate}/>
                            </div>
                        </div>
                        <div className={st.optionBox}>
                            <div className={st.optionTitle}>글자크기</div>
                            <div className={st.option}>
                                <Radio className={st.radio} name="fontSize" value="SMALL" checked={vm.state.fontSize === 'SMALL'} onChange={(e) => vm.setFontSize(e)}>작게</Radio>
                                <Radio className={st.radio} name="fontSize" value="MEDIUM" checked={vm.state.fontSize === 'MEDIUM'} onChange={(e) => vm.setFontSize(e)}>중간</Radio>
                                <Radio className={st.radio} name="fontSize" value="LARGE" checked={vm.state.fontSize === 'LARGE'} onChange={(e) => vm.setFontSize(e)}>크게</Radio>
                            </div>
                        </div>
                        <div className={st.optionBox}>
                            <div className={st.optionTitle}>줄간격</div>
                            <div className={st.option}>
                                <Radio className={st.radio} name="lineSpacing" value="SMALL" checked={vm.state.lineSpacing === 'SMALL'} onChange={(e) => vm.setLineSpacing(e)}>작게</Radio>
                                <Radio className={st.radio} name="lineSpacing" value="MEDIUM" checked={vm.state.lineSpacing === 'MEDIUM'} onChange={(e) => vm.setLineSpacing(e)}>중간</Radio>
                                <Radio className={st.radio} name="lineSpacing" value="LARGE" checked={vm.state.lineSpacing === 'LARGE'} onChange={(e) => vm.setLineSpacing(e)}>크게</Radio>
                            </div>
                        </div>
                    </div>
                    <div className={st.optionSeparate}>
                        <div className={st.optionBox}>
                            <div className={st.optionTitle}>주주 이름</div>
                            <div className={st.option}>
                                <Radio className={st.radio} name="showShareholderName" value="ALL_SHOW" checked={vm.state.showShareholderName === 'ALL_SHOW'} onChange={(e) => vm.setShowShareholderName(e)}>공개</Radio>
                                <Radio className={st.radio} name="showShareholderName" value="HALF_SHOW" checked={vm.state.showShareholderName === 'HALF_SHOW'} onChange={(e) => vm.setShowShareholderName(e)}>일부공개</Radio>
                            </div>
                        </div>
                        <div className={st.optionBox}>
                            <div className={st.optionTitle}>주민번호(고유번호)</div>
                            <div className={st.option}>
                                <Radio className={st.radio} name="showShareholderUniqueInfo" value="ALL_SHOW" checked={vm.state.showShareholderUniqueInfo === 'ALL_SHOW'} onChange={(e) => vm.setShowShareholderUniqueInfo(e)}>공개</Radio>
                                <Radio className={clsx(st.radio, st.marginTop)} name="showShareholderUniqueInfo" value="HALF_SHOW" checked={vm.state.showShareholderUniqueInfo === 'HALF_SHOW'} onChange={(e) => vm.setShowShareholderUniqueInfo(e)}>앞자리만<br/>공개</Radio>
                                <Radio className={st.radio} name="showShareholderUniqueInfo" value="NO_SHOW" checked={vm.state.showShareholderUniqueInfo === 'NO_SHOW'} onChange={(e) => vm.setShowShareholderUniqueInfo(e)}>미공개</Radio>
                            </div>
                        </div>
                        {vm.displayCorpUniqueInfo() && 
                            <div className={st.optionBox}>
                                <div className={st.optionTitle}>법인/투자조합 주주 고유번호</div>
                                <div className={st.option}>
                                    <Radio className={clsx(st.radio, st.marginTop)} name="corpUniqueInfo" value="UNIQUE_NUMBER" checked={vm.state.corpUniqueInfo === 'UNIQUE_NUMBER'} onChange={(e) => vm.setCorpUniqueInfo(e)}>법인등록번호/<br/>고유번호</Radio>
                                    <Radio className={st.radio} name="corpUniqueInfo" value="BUSINESS_REGISTRATION_NUMBER" checked={vm.state.corpUniqueInfo === 'BUSINESS_REGISTRATION_NUMBER'} onChange={(e) => vm.setCorpUniqueInfo(e)}>사업자등록번호</Radio>
                                </div>
                            </div>
                        }
                        {vm.displayUniqueInfoInput() && 
                            <div className={st.optionBox}>
                                <div className={st.optionTitle}>
                                    고유번호가 저장되지 않은 주주
                                    <div className={st.optionTitleExplain}>입력하면 주주명부에 자동 저장되어요.</div>
                                </div>     
                                <div className={clsx(st.option, st.uniqueNumber)}>
                                    {vm.shareholders.map((shareholder, idx) => {
                                        if(shareholder.shareholderType === '국내법인' || shareholder.shareholderType === '펀드/투자조합') {
                                            if((vm.state.corpUniqueInfo === 'UNIQUE_NUMBER' && shareholder.existsUniqueInfoYn === false) || vm.state.corpUniqueInfo === 'BUSINESS_REGISTRATION_NUMBER' && shareholder.existBusinessRegistrationNumberYn === false) {
                                                return (
                                                    <div key={idx} className={st.uniqueNumberInfo}>
                                                        <div className={st.name}>
                                                            <div className={st.nameText}>{shareholder.name} <span className={st.uniqueNumberType}>{vm.uniqueNumberType(idx).type}</span></div>
                                                        </div>
                                                        <InputText name={`shareholderUpdateInfoList[${idx}].uniqueNumber`} value={vm.shareholderUniqueNumber(idx)} onChange={(e) => vm.setShareholderUniqueNumber(e, idx)} onBlur={() => vm.setBlur()} className={st.inputText} style={{width: '240px'}} placeholder={vm.uniqueNumberType(idx).placeholder}
                                                        kssn={vm.uniqueNumberType(idx).kssn} ein={vm.uniqueNumberType(idx).ein} errorText={<Error name={`shareholderUpdateInfoList[${idx}].uniqueNumber`} value={vm.shareholderUniqueNumber(idx)} errorCase={{
                                                            required: '고유번호를 입력하세요.',
                                                            validate: vm.uniqueNumberType(idx).validate
                                                        }} />}/>
                                                    </div>
                                                )
                                            }
                                        } else {
                                            if(shareholder.existsUniqueInfoYn === false) {
                                                return (
                                                    <div key={idx} className={st.uniqueNumberInfo}>
                                                        <div className={st.name}>
                                                            <div className={st.nameText}>{shareholder.name} <span className={st.uniqueNumberType}>{vm.uniqueNumberType(idx).type}</span></div>
                                                        </div>
                                                        {(shareholder.shareholderType === '해외성인' || shareholder.shareholderType === '해외미성년' || shareholder.shareholderType === '해외법인') ?
                                                        <>
                                                            <DatePicker name={`shareholderUpdateInfoList[${idx}].uniqueNumber`} className={st.datepicker} selected={vm.shareholderUniqueNumber(idx)} onChange={(e) => vm.setShareholderUniqueNumberBirth(e, idx)} placeholder={vm.uniqueNumberType(idx).placeholder} yearRange="birth" /> 
                                                            <Error name={`shareholderUpdateInfoList[${idx}].uniqueNumber`} value={vm.shareholderUniqueNumber(idx)} errorCase={{
                                                                required: '생년월일을 선택해 주세요.'
                                                            }}/>
                                                        </>
                                                        :
                                                            <InputText name={`shareholderUpdateInfoList[${idx}].uniqueNumber`} value={vm.shareholderUniqueNumber(idx)} onChange={(e) => vm.setShareholderUniqueNumber(e, idx)} onBlur={() => vm.setBlur()} className={st.inputText} style={{width: '240px'}} placeholder={vm.uniqueNumberType(idx).placeholder}
                                                            kssn={vm.uniqueNumberType(idx).kssn} ein={vm.uniqueNumberType(idx).ein} errorText={<Error name={`shareholderUpdateInfoList[${idx}].uniqueNumber`} value={vm.shareholderUniqueNumber(idx)} errorCase={{
                                                                required: '고유번호를 입력하세요.',
                                                                validate: vm.uniqueNumberType(idx).validate
                                                            }} />}/>
                                                        }
                                                    </div>
                                                )
                                            }
                                        }
                                    })}
                                </div>
                            </div>
                        }
                        <div className={st.optionBox}>
                            <div className={st.optionTitle}>주주의 주소</div>
                            <div className={st.option}>
                                <Radio className={st.radio} name="showAddress" value="ALL_SHOW" checked={vm.state.showAddress === 'ALL_SHOW'} onChange={(e) => vm.setShowAddress(e)}>포함</Radio>
                                <Radio className={st.radio} name="showAddress" value="NO_SHOW" checked={vm.state.showAddress === 'NO_SHOW'} onChange={(e) => vm.setShowAddress(e)}>미포함</Radio>
                            </div>
                        </div>
                        {vm.displayAddressInfoInput() && 
                            <div className={st.optionBox}>
                                <div className={st.optionTitle}>
                                    주소가 저장되지 않은 주주
                                    <div className={st.optionTitleExplain}>입력하면 주주명부에 자동 저장되어요.</div>
                                </div> 
                                <div className={clsx(st.option, st.address)}>
                                    {vm.shareholders.map((shareholder, idx) => {
                                        if(shareholder.existsAddressYn === false) {
                                            return (
                                                <div key={idx} className={st.addressInfo}>
                                                    <div className={st.name}>{shareholder.name}</div>
                                                    <InputText name={`shareholderUpdateInfoList[${idx}].address`} value={vm.shareholderAddress(idx)} onChange={(e) => vm.setShareholderAddress(e, idx)} onBlur={() => vm.setBlur()}className={st.inputText} style={{width:'240px'}} errorText={<Error  name={`shareholderUpdateInfoList[${idx}].address`} value={vm.shareholderAddress(idx)} errorCase={{
                                                        required: '주소를 입력하세요.'
                                                    }}/>}/>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            </div>
                        }
                    </div>
                    <div className={st.optionSeparate}>
                        <div className={st.optionBox}>
                            <div className={st.optionTitle}>소액 주주 합치기</div>
                            <div className={st.option}>
                                <div className={st.minority}>
                                    <InputText className={st.inputText} name="minorityShareholder" value={vm.state.minorityShareholder} onChange={(e) => vm.setMinorityShareholder(e)} placeholder="0" style={{width: '63px'}}/>
                                    <div className={st.subText}>% 이하는 <br/>'소액주주'로 표시</div>
                                    <button type="button" className={st.applyBtn} onClick={() => vm.requestPreview()}>적용</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={st.optionSeparate}>
                        <div className={st.optionBox}>
                            <div className={st.optionTitle}>
                                온라인 사용인감 날인
                            </div>
                            <div className={st.option}>
                                <Radio className={st.radio} name="showOnlineStampYn" value={true} checked={vm.state.showOnlineStampYn === true} onChange={(e) => vm.setShwoOnlineStampYn(e)}>날인함</Radio>
                                <Radio className={st.radio} name="showOnlineStampYn" value={false} checked={vm.state.showOnlineStampYn === false} onChange={(e) => vm.setShwoOnlineStampYn(e)}>날인 안 함</Radio>
                            </div>
                        </div>
                        {vm.showRepresentativeNameSelectionYn === true && 
                            <div className={st.optionBox}>
                                <div className={st.optionTitle}>서류 아래에 기재할 대표님 이름</div>
                                <div className={st.option}>
                                    {vm.representatives.map((item, idx) => (
                                        <Radio key={idx} className={clsx(st.radio, st.wrap)} name="representatives" value={item.id} checked={vm.state.representatives === String(item.id)} onChange={(e) => vm.setRepresentatives(e)}>{item.name}님</Radio>
                                    ))}
                                    <Radio className={clsx(st.radio, st.wrap)} name="representatives" value="ALL" checked={vm.state.representatives === 'ALL'} onChange={(e) => vm.setRepresentatives(e)}>대표님 모두</Radio>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className={st.btnArea}>
                <SquareBtn type="submit" name="excelDownload" className={st.downloadBtn} color="white">엑셀 다운로드</SquareBtn>
                <SquareBtn type="submit" name="pdfDownload" className={st.downloadBtn} color="white">PDF 다운로드</SquareBtn>
                <SquareBtn type="submit" name="sendEmail" className={st.submitBtn}>이메일 발송</SquareBtn>
            </div>
        </form>
    ));
}

export default Download;