import { observable, runInAction, when, makeObservable } from 'mobx';

type Title = string | undefined;
type Content = string | undefined;
type BtnText = string | undefined;
type ContentJsx = JSX.Element | undefined;
type Icon = 'exclamationNoti' | 'loud' | undefined;

class DialogModel {
    constructor() {
        makeObservable(this, {
            type: observable,
            title: observable,
            content: observable,
            confirmYn: observable,
            btn: observable,
            alertBtnText: observable,
            confirmBtnText: observable,
            cancelBtnText: observable,
            contentJSX : observable,
            icon: observable,
        });
    }
    type : 'alert' | 'confirm' | undefined = undefined;
    title : Title = undefined;
    content : Content = undefined;
    confirmYn : boolean | undefined = undefined;
    btn : {
        alertBtn : (() => void) | undefined,
        confirmBtn : (() => void) | undefined,
        cancelBtn : (() => void) | undefined
    } = {
        alertBtn : undefined,
        confirmBtn : undefined,
        cancelBtn : undefined
    };
    alertBtnText : BtnText = undefined;
    confirmBtnText : BtnText = undefined;
    cancelBtnText : BtnText = undefined;
    contentJSX : ContentJsx = undefined;
    icon : Icon = undefined;
}
const dialogModel = new DialogModel();

const alertBtn = () => {
    runInAction(() => {
        dialogModel.type = undefined;
        dialogModel.title = undefined;
        dialogModel.content = undefined;
        dialogModel.confirmYn = undefined;
        dialogModel.btn = {
            alertBtn: undefined,
            confirmBtn: undefined,
            cancelBtn: undefined
        };
        dialogModel.alertBtnText = undefined;
        dialogModel.confirmBtnText = undefined;
        dialogModel.cancelBtnText = undefined;
        dialogModel.contentJSX = undefined;
        dialogModel.icon = undefined;
    })
}

const confirmBtn = () => {
    runInAction(() => {
        dialogModel.type = undefined;
        dialogModel.title = undefined;
        dialogModel.content = undefined;
        dialogModel.confirmYn = true;
        dialogModel.btn = {
            alertBtn: undefined,
            confirmBtn: undefined,
            cancelBtn: undefined
        }
        dialogModel.alertBtnText = undefined;
        dialogModel.confirmBtnText = undefined;
        dialogModel.cancelBtnText = undefined;
        dialogModel.contentJSX = undefined;
        dialogModel.icon = undefined;
    })
}

const cancelBtn = () => {
    runInAction(() => {
        dialogModel.type = undefined;
        dialogModel.title = undefined;
        dialogModel.content = undefined;        
        dialogModel.confirmYn = false;
        dialogModel.btn = {
            alertBtn: undefined,
            confirmBtn: undefined,
            cancelBtn: undefined
        }
        dialogModel.alertBtnText = undefined;
        dialogModel.confirmBtnText = undefined;
        dialogModel.cancelBtnText = undefined;
        dialogModel.contentJSX = undefined;
        dialogModel.icon = undefined;
    })
}

const _alert = async (title ?: Title, text ?: Content, alertBtnText ?: BtnText, contentJSX ?: ContentJsx, icon ?: Icon) => {
    runInAction(() => {
        dialogModel.type = 'alert';
        if(title) {
            dialogModel.title = title;
        }
        if(text) {
            dialogModel.content = text;
        }
        if(alertBtnText) {

            dialogModel.alertBtnText = alertBtnText;
        }
        if(contentJSX) {
            dialogModel.contentJSX = contentJSX;
        }
        if(icon) {
            dialogModel.icon = icon;
        }
        dialogModel.btn.alertBtn = alertBtn;
    })
    await when(() => {
        return !dialogModel.type;
    })
}
const _confirm = async(title ?: Title, text ?: Content, confirmBtnText ?: BtnText, cancelBtnText ?: BtnText, contentJSX ?: ContentJsx, icon ?: Icon) => {
    runInAction(() => {
        dialogModel.type = 'confirm';
        if(title) {
            dialogModel.title = title;
        }
        if(text) {
            dialogModel.content = text;
        }
        if(confirmBtnText) {
            dialogModel.confirmBtnText = confirmBtnText;
        }
        if(cancelBtnText) {
            dialogModel.cancelBtnText = cancelBtnText;
        }
        if(contentJSX) {
            dialogModel.contentJSX = contentJSX;
        }
        if(icon) {
            dialogModel.icon = icon;
        }
        dialogModel.btn.confirmBtn = confirmBtn;
        dialogModel.btn.cancelBtn = cancelBtn;
    })
    await when(() => {
        return !dialogModel.type;
    })
    return dialogModel.confirmYn;
}

export { dialogModel, _alert, _confirm, alertBtn, confirmBtn, cancelBtn};