import { useObserver } from 'mobx-react';
import type { I파일_업로드 } from '@src/model/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalModel.interface';
import bst from '@pages/Corp/Agenda/Step/FinalCheck/BeforeFinal/BeforeFinal.module.scss';
import st from './FileUpload.module.scss';
import clsx from 'clsx';
import vm from './FileUploadVm';
import Loading from '@commonComponents/Loading/Loading';

interface FileUploadProps {
    index: number;
    info: I파일_업로드;
    agendaProgressId: string;
}

function FileUpload(props: FileUploadProps) {
    return useObserver(() => (
        <>
            {vm.state.loadingYn && <Loading />}
            <div className={bst.row} id="fileUploadContent">
                <div className={clsx(bst.title, bst.section5)}>{props.index+1}. 파일 업로드</div>
                <div className={bst.content}>
                    {vm.registrationAgencyType === 'SELF' ? 
                        <div className={bst.contentTitleBox}>등기 후, 서류를 업로드 하세요.</div>
                    :
                        <div className={bst.contentTitleBox}>다운 받은 서류에 도장을 찍고 업로드 하세요.</div>
                    }
                    <div className={st.fileuploadContent}>
                        <ul>
                            {props.info.uploadFileList.map((uploadFile, idx) => {
                                return (
                                    <li key={idx}>
                                        <div className={st.uploadFileInfo}>
                                            <div className={st.uploadFileType}>
                                                {uploadFile.name}{uploadFile.required && <span className={st.required}>*</span>}
                                            </div>
                                            {(uploadFile.required && !uploadFile.file) &&
                                                <div className={st.error}>서류를 업로드해주세요.</div>
                                            }
                                            {uploadFile.file &&
                                                <div className={st.uploadFileBox} style={{marginTop:0}}>
                                                    <span onClick={() => vm.fileDownload(uploadFile?.file?.downloadUrl || "")} className={st.fileDownload}>{uploadFile.file.name}</span>
                                                    <button type="button" className={st.deleteBtn} onClick={() => uploadFile.file && vm.fileDelete(props.agendaProgressId, uploadFile.id, uploadFile.file.id, uploadFile.documentType)}>삭제</button>
                                                </div>
                                            }
                                            {uploadFile.files && 
                                            <div>
                                                {uploadFile.files.map((file, idx2) => (
                                                    <div className={st.uploadFileBox} key={idx2}>
                                                        <span onClick={() => vm.fileDownload(file.downloadUrl)} className={st.fileDownload}>{file.name}</span>
                                                        <button type="button" className={st.deleteBtn} onClick={() => vm.fileDelete(props.agendaProgressId, uploadFile.id, file.id, uploadFile.documentType)}>삭제</button>
                                                    </div>
                                                ))}
                                            </div>
                                            }
                                        </div>
                                        <label className={st.fileUpload} htmlFor={`fileUpload${idx}`}>
                                            클릭하여 업로드
                                        </label>
                                        <input type="file" id={`fileUpload${idx}`} style={{width:0, height:0, position:"absolute", display: "none"}} onChange={(e) => vm.fileUpload(e, props.agendaProgressId, uploadFile.id, uploadFile.documentType, uploadFile)} multiple={vm.multipleYn(uploadFile.documentType)} />
                                    </li>
                                )
                            })}
                        </ul>                            
                    </div>
                </div>
            </div>
        </>
    ));
}

export default FileUpload;