import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { useEffect } from 'react';
import vm from './ReceiptPopupVm';
import st from './ReceiptPopup.module.scss';

function ReceiptPopup() {
    return useObserver(() => (
        <div className={st.receiptPopup}>
            <div className={st.title}>기장서비스 상담이 접수되었어요.</div>
            <div className={st.content}>
                진평회계법인에서 02-2088-8750 번호로 곧 전화를 드릴거에요.<br/>
                빠른 상담을 원한다면, 전화를 주시거나, 오른쪽 아래 채팅상담 버튼을 눌러 문의 주세요.
            </div>
            <button type="button" className={st.checkBtn} onClick={() => vm.check()}>확인</button>
        </div>
    ));
}
export default ReceiptPopup;