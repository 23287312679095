import beforeFinalService from "@src/service/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalService";
import beforeFinalModel from "@src/model/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalModel";
import { observable } from "mobx";

class BeforeFinalVm {
    state : {
        documentSubmitYn : boolean
    }
    constructor() {
        this.state = observable({
            documentSubmitYn: false
        })
    }
    setDocumentSubmit = () => {
        this.state.documentSubmitYn = true;
    }
    load(agendaProgressId: string) {
        beforeFinalService.load(agendaProgressId);
    }
    get title() {
        return beforeFinalModel.title
    }
    get sectionList() {
        return beforeFinalModel.sectionList;
    }
    get displayFinalApplicationYn() {
        let displayYn = false;
        const registrationAgencyType = beforeFinalModel.registrationAgencyType;
        
        if(registrationAgencyType === "NONE" || beforeFinalModel.registrationAgencyType === 'SELF') {
            displayYn = true;
        }
        
        return displayYn;
    }
    get meetingSchedule() {
        return beforeFinalModel.meetingSchedule;
    }
}

export default new BeforeFinalVm();