import { useObserver } from 'mobx-react';
import { useEffect } from 'react';
import st from './Step2.module.scss';
import vm from './Step2Vm';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import clsx from 'clsx';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import TextArea from '@standby/common-ui/components/atoms/Input/TextArea/TextArea';
import ErrorText from '@standby/common-ui/components/atoms/Text/ErrorText/ErrorText';
import TextBtn from '@standby/common-ui/components/atoms/Button/TextBtn/TextBtn';
import Radio from '@standby/common-ui/components/atoms/Input/Radio/Radio';
import { addCommas } from '@common/module/replaceNumber';
import PlusBtn from '@standby/common-ui/components/atoms/Button/PlusBtn/PlusBtn';
import MinusBtn from '@standby/common-ui/components/atoms/Button/MinusBtn/MinusBtn';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import regExp from '@common/module/regExp';

function Step23() {
    useEffect(() => {
        vm.load();
        vm.reactionEstimate();
    }, [])
    return useObserver(() => (
        <>
            <div className={st.mainTitle}>필요하신 <span className={st.bold}>인터넷 · 전화 상품</span>을 <span className={st.bold}>선택</span>하세요.</div>
            <div className={st.mainContent}>
                <ul className={st.mainCategory}>
                    <li>
                        <div className={st.title} data-type="통신사">통신사</div>
                        <ul className={st.checks}>
                            <li className={st.selectedKt}><img src="/images/kt_logo_select.svg"/></li>
                        </ul>
                    </li>
                    <li>
                        <div className={st.title} data-type="상품선택">상품선택</div>
                        <ul className={st.checks}>
                            <li><CheckBox className={clsx(st.check, vm.selectedProducts.includes("INTERNET") ? st.checked : null)} textClassName={st.text} value="INTERNET" name="selectedProducts" checked={vm.selectedProducts.includes("INTERNET")} onChange={(e) => vm.setSelectedProducts(e)}>기업용 인터넷</CheckBox></li>
                            <li><CheckBox className={clsx(st.check, vm.selectedProducts.includes("TELEPHONE") ? st.checked : null)} textClassName={st.text} value="TELEPHONE" name="selectedProducts" checked={vm.selectedProducts.includes("TELEPHONE")} onChange={(e) => vm.setSelectedProducts(e)}>전화</CheckBox></li>
                            <li><CheckBox className={clsx(st.check, vm.selectedProducts.includes("TV") ? st.checked : null)} textClassName={st.text} value="TV" name="selectedProducts" checked={vm.selectedProducts.includes("TV")} onChange={(e) => vm.setSelectedProducts(e)}>TV</CheckBox></li>
                        </ul>
                    </li>
                </ul>
                <form className={st.detailInfos} onSubmit={(e) => vm.submit(e)}>
                    <div className={st.detail}>
                        <div className={clsx(st.subCategories, st.box, vm.selectedProducts.length === 0 ? st.empty : null)}>
                            {vm.selectedProducts.length !== 0 &&
                                <>
                                    {(vm.selectedProducts.includes("TELEPHONE") || vm.selectedProducts.includes("INTERNET")) && 
                                        <div className={st.tip}><em>기업용 인터넷</em>과 <em>인터넷 전화 동시 신청 시, 캐시백을 추가</em>로 받으실 수 있어요!</div>
                                    }
                                    <ul className={st.items}>
                                        {vm.selectedProducts.includes("INTERNET") && 
                                            <li className={st.item}>
                                                <div className={clsx(st.itemTitle, st.rightBtn)} data-type="인터넷">
                                                    <span>기업용 인터넷</span>
                                                    <a href='https://standby.kr/blog/article/%EA%B8%B0%EC%97%85%EC%9A%A9_%EC%9D%B8%ED%84%B0%EB%84%B7_%EC%84%A0%ED%83%9D%EC%9D%B4%EC%9C%A0/' target='_blank' className={st.aLink}>법인이 KT 기업용 인터넷을 선택해야 하는 이유</a>
                                                </div>
                                                <ul className={st.radioGroup}>
                                                    {vm.products.INTERNET.map((internet, index) => (
                                                        <Radio name="products.internet" className={clsx(st.radioItem, internet.id === vm.selectedProductsDetail.INTERNET ? st.checked : null)} key={index} checked={internet.id === vm.selectedProductsDetail.INTERNET} value={internet.id} onChange={(e) => vm.internetDetail(e)}>
                                                            {internet.id === "IT2" && 
                                                                <div className={st.badge}>추천</div>
                                                            }
                                                            <div className={st.explanation}>{internet.explanation}</div>
                                                            <div className={st.option}>{internet.option}</div>
                                                            <div className={st.cost}>월 {addCommas(internet.cost)}원 {internet.minimumAmountYn ? '~' : ''}</div>
                                                        </Radio>
                                                    ))}
                                                </ul>
                                            </li>
                                        }
                                        {vm.selectedProducts.includes("TELEPHONE") && 
                                            <li className={st.item}>
                                                <div className={clsx(st.itemTitle, st.rightBtn)} data-type="전화">
                                                    <span>전화</span>
                                                    <button type="button" className={st.tooltipBtn} onClick={() => vm.telephoneTipModal()}><span>전화기 설치 시 꿀팁</span></button>
                                                </div>
                                                {vm.products.TELEPHONE.map((telephone, index) => (
                                                    <div className={st.quantityWrap} key={index}>
                                                        <div className={st.itemInfo}>
                                                            <div className={st.explanation}>{telephone.explanation}</div>
                                                            <div className={st.option}>{telephone.option}</div>
                                                            <div className={st.cost}>월 {addCommas(telephone.cost)}원 {telephone.minimumAmountYn ? '~' : ''}</div>
                                                        </div>
                                                        <div className={st.quantity}>
                                                            <MinusBtn size="large" disabled={vm.quantityMinusDisabled()} onClick={() => vm.setQuantity('minus')}/>
                                                            <div className={st.number}>
                                                                {vm.selectedProductsDetail.TELEPHONE.quantity}
                                                                {vm.state.toastMessageYn && 
                                                                    <div className={st.toastMessage}>1588 등 대표번호 신청 시, 수신전환을 위해 <em>2대 이상의 전화</em>가 필요해요!</div>
                                                                }
                                                            </div>
                                                            <PlusBtn size="large" onClick={() => vm.setQuantity('plus')}/>
                                                        </div>
                                                    </div>
                                                ))}
                                                <ul className={st.checkList}>
                                                    {vm.products.TELEPHONE_NUMBER.map((telephone_number, index) => (
                                                        <li key={index}>
                                                            <CheckBox value={telephone_number.id} name="products.telephoneNumber" checked={vm.selectedProductsDetail.TELEPHONE_NUMBER.includes(telephone_number.id)} onChange={(e) => vm.setTelephoneNumber(e)}>
                                                                {telephone_number.option} <span className={st.won}>(월 {addCommas(telephone_number.cost)}원 {telephone_number.minimumAmountYn ? '~' : ''})</span>
                                                            </CheckBox>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                        }
                                        {vm.selectedProducts.includes("TV") && 
                                            <li className={st.item}>
                                                <div className={st.itemTitle} data-type="TV">TV<span className={st.info}>: KT 인터넷을 쓰고 있는 경우, 개통이 가능해요.</span></div>
                                                <ul className={st.radioGroup}>
                                                    {vm.products.TV.map((tv, index) => (
                                                        <Radio name="products.tv" className={clsx(st.radioItem, tv.id === vm.selectedProductsDetail.TV ? st.checked : null)} key={index} checked={tv.id === vm.selectedProductsDetail.TV} value={tv.id} onChange={(e) => vm.tvDetail(e)}>
                                                            <div className={st.explanation}>{tv.explanation}</div>
                                                            <div className={st.option}>{tv.option}</div>
                                                            <div className={st.cost}>월 {addCommas(tv.cost)}원 {tv.minimumAmountYn ? '~' : ''}</div>
                                                        </Radio>
                                                    ))}
                                                </ul>
                                            </li>
                                        }
                                    </ul>
                                </>
                            }
                        </div>
                        <div className={clsx(st.consultInfo, st.box)}>
                            <div className={st.itemTitle} data-type="상담정보">상담정보</div>
                            <div className={st.inputRows}>
                                <div><label className={clsx(st.inputTitle, st.required)} htmlFor="consultInfo.name">이름</label></div>
                                <InputText style={{width:"452px"}} placeholder="홍길동" name='consultInfo.name' id="consultInfo.name" value={vm.consultInfo.name} onChange={(e) => vm.setConsultInfoName(e)} 
                                    errorText={<Error name='consultInfo.name' value={vm.consultInfo.name} errorCase={{
                                        required: '이름을 입력해 주세요.'
                                    }}
                                    />}
                                />
                            </div>
                            <div className={st.inputRows}>
                                <div><label className={clsx(st.inputTitle, st.required)} htmlFor="consultInfo.phoneNumber">전화번호</label></div>
                                <InputText style={{width:"452px"}} placeholder="01012341234" name='consultInfo.phoneNumber' id="consultInfo.phoneNumber" value={vm.consultInfo.phoneNumber} onChange={(e) => vm.setConsultInfoPhoneNumber(e)} 
                                    errorText={<Error name='consultInfo.phoneNumber' value={vm.consultInfo.phoneNumber} errorCase={{
                                        required: '전화번호를 입력해 주세요.',
                                        pattern: {
                                            value: regExp.phoneNumber().test(vm.consultInfo.phoneNumber),
                                            message: '올바른 전화번호를 입력해 주세요.'
                                        }
                                    }}
                                    />}
                                />
                            </div>
                            <div className={st.inputRows}>
                                <div><label className={clsx(st.inputTitle, st.required)} htmlFor="consultInfo.email">이메일</label></div>
                                <InputText style={{width:"452px"}} name='consultInfo.email' id="consultInfo.email" value={vm.consultInfo.email} onChange={(e) => vm.setConsultInfoEmail(e)} 
                                    errorText={<Error name='consultInfo.email' value={vm.consultInfo.email} errorCase={{
                                        required: '이메일을 입력하세요.',
                                        pattern: {
                                            value: regExp.email().test(vm.consultInfo.email),
                                            message: '올바른 이메일 형식으로 입력해 주세요.'
                                        }
                                    }}
                                    />}
                                />
                            </div>
                            <div className={st.inputRows}>
                                <div><label className={st.inputTitle} htmlFor="consultInfo.address">설치주소</label></div>
                                <InputText fullSize name='consultInfo.address' id="consultInfo.address" value={vm.consultInfo.address} onChange={(e) => vm.setConsultInfoAddress(e)} />
                            </div>
                            <div className={st.inputRows}>
                                <div><label className={st.inputTitle} htmlFor="consultInfo.content">문의내용</label></div>
                                <TextArea className={st.textarea} fullSize placeholder='궁금한 내용을 편하게 입력해 주세요!' name='consultInfo.content' id="consultInfo.content" value={vm.consultInfo.content} onChange={(e) => vm.setConsultInfoContent(e)} />
                            </div>
                            <CheckBox checked={vm.agreeAll} onChange={(e) => vm.setAgreeAll(e)} fontSize="15px" size="large">모두 동의합니다.</CheckBox>
                            <ul className={st.termsList}>
                                {vm.termInfos.map((item, idx) => {
                                    return (
                                        <li key={idx}>
                                            <input type="hidden" name={`awsApplicationTerms[${idx}].id`} value={item.id}/>
                                            <CheckBox name={`awsApplicationTerms[${idx}].agreeYn`} value={String(item.agreeYn)} checked={item.agreeYn} onChange={(e) => vm.setAgreeYn(e, idx)} className={st.check} checkIconClassName={st.checkIcon} textClassName={st.radioText}>
                                                <span className={st.notRequired}>{item.required === true && '(필수) '}</span>{item.title}
                                            </CheckBox>
                                            {item.content && 
                                                <TextBtn className={st.textBtn} onClick={() => vm.termsPopup(item.title, <div className={clsx(st.agreeContent, "terms")} dangerouslySetInnerHTML={{__html: item.content}}></div>)}>상세보기</TextBtn>
                                            }
                                        </li>
                                    )
                                })}
                            </ul>
                            {(vm.state.errorCheckYn && vm.checkBoxError) && 
                                <ErrorText className={st.errorText}>필수 약관에 동의해주세요.</ErrorText>
                            }
                        </div>
                    </div>
                    <div className={st.estimate}>
                        <div className={clsx(st.detail, st.box)}>
                            <div className={st.selected}>
                                <div className={st.title}>선택상품</div>
                                <ul className={st.selectedList}>
                                    <li>
                                        <div className={st.title}>인터넷</div>
                                        <div className={st.content}>{vm.selectedInternetName()}</div>
                                    </li>
                                    <li>
                                        <div className={st.title}>전화</div>
                                        <div className={st.content}>{vm.selectedTelephoneName()}</div>
                                    </li>
                                    <li>
                                        <div className={st.title}>TV</div>
                                        <div className={st.content}>{vm.selectedTvName()}</div>
                                    </li>
                                </ul>
                            </div>
                            <div className={st.price}>
                                <div className={st.priceRow}>
                                    <div className={st.title}>
                                        예상 월 요금 <span className={st.sub}>(vat별도)</span>
                                    </div>
                                    <div className={st.price}>{addCommas(vm.estimate.baseFee - vm.estimate.bundleDiscount)} 원<span className={(vm.estimate.baseFee - vm.estimate.bundleDiscount) ? '' : st.moreHide}> ~</span></div>
                                </div>
                                <div className={st.detailPrice}>
                                    기본요금({addCommas(vm.estimate.baseFee)}원{vm.estimate.baseFee ? ' ~' : ''}) - 결합할인({addCommas(vm.estimate.bundleDiscount)}원)
                                </div>
                                <div className={st.priceRow}>
                                    <div className={st.title}>예상 캐시백</div>
                                    <div className={st.price} style={{marginRight:"14px"}}>{addCommas(vm.estimate.cashback)} 원</div>
                                </div>
                            </div>
                            <SquareBtn type='submit' className={st.submit}>상담 신청하기</SquareBtn>
                        </div>
                        {vm.estimate.bundleDiscount !== 0 && 
                            <div className={st.cashback}>결합 할인&캐시백을 받을 수 있어요!</div>
                        }
                    </div>
                </form>
            </div>
        </>
    ));
}
export default Step23;