import AoaDetailModel from "@model/AgendaDashboard/AoaDetail/AoaDetailModel";
import AoaDetailService from "@service/AgendaDashboard/AoaDetail/AoaDetailService";
import AgendaDashboardModel from "@model/AgendaDashboard/AgendaDashboardModel";

class AoaDetailModalVm {
    load(cssn : string) {
        AoaDetailService.load(cssn);
    }
    get articlesOfAssociations() {
        return AoaDetailModel.articlesOfAssociations;
    }
    get companyName() {
        return AgendaDashboardModel.company.name;
    }
}
export default new AoaDetailModalVm();