import { observable } from 'mobx';
import AgendaDashboardModel from '@model/AgendaDashboard/AgendaDashboardModel';

interface State {
    moreViewYn: boolean;
}
class AgendasVm {
    state: State = observable({
        moreViewYn: false
    })
    get agendas() {
        return AgendaDashboardModel.agendas;
    }
    moreView() {
        this.state.moreViewYn = !this.state.moreViewYn;
    }
    sameCount(agendaIndex : number) {
        const agendas = this.agendas;
        const type = agendas[agendaIndex].type;
        let count = 0;
        let hasSameType = false;
        agendas.forEach((agenda, index) => {
            if(agenda.type === type) {
                if(count === 1) { // 한번돌아서 이미 카운트가 1인데 또있다면 중복 타입이 있는것임
                    hasSameType = true;
                }
                if(index <= agendaIndex) { //자신이 몇번째에 속하는 중복타입인지
                    count += 1;
                }
            }
        })
        return { count, hasSameType }
    }
}
export default new AgendasVm();