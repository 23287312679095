import { observable } from 'mobx';

const userDashboardModel = observable({
    companiesInfo : [],
    acceptWaitingCompanies: [],
    name: "",
    setCompaniesInfo(companiesInfo) {
        if(companiesInfo && companiesInfo.length) {
            this.companiesInfo = companiesInfo.map((company) => {
                return {
                    id : company?.id || null,
                    koreanName : company?.koreanName || "",
                    companyType : company?.companyType || "",
                    demoYn : company?.demoYn || false,
                    prefiexed : company?.prefiexed || false,
                    address : company?.address || "",
                    executiveTags : {
                        필수 : company?.executiveTags?.필수 || 0,
                        필요 : company?.executiveTags?.필요 || 0
                    },
                    stockHolderListRegisteredYn : company?.stockHolderListRegisteredYn || false,
                    executiveMembers : company?.executiveMembers ? 
                        company.executiveMembers.map((executiveMember) => (
                            {
                                name : executiveMember?.name || "",
                                position : executiveMember?.position || "",
                                representativeYn : executiveMember?.representativeYn || false,
                                positionExpiredDays : executiveMember?.positionExpiredDays || null,
                                tag : executiveMember?.tag || null,
                            }
                        )) : [],
                    companyRegisteredStatus : company?.companyRegisteredStatus || "",
                    newTags : company?.newTags ? company?.newTags.map(newTag => newTag || "") : [],
                    liquidationYn : company?.liquidationYn || false
                }
            })
        }else{
            this.companiesInfo = []
        }   
    },
    setAcceptWaitingCompanies(data) {
        this.name = data.name || "";
        this.acceptWaitingCompanies = data.acceptWaitingCompanies || [];
    }
});

export default userDashboardModel;