import { useObserver } from 'mobx-react';
import st from '../Agendas.module.scss';
import type {Agenda, IA임원_보수_한도_승인} from '@model/AgendaDashboard/AgendaDashboardModel.interface';
import clsx from 'clsx';
import { addCommas } from '@common/module/replaceNumber';
import Table from '@standby/common-ui/components/atoms/Table/Table';
import Cst from './C임원_보수_한도_승인.module.scss';

interface Props {
    agendaIndex : number,
    agenda : Agenda<IA임원_보수_한도_승인>,
    moreViewYn : boolean
}

function C임원_보수_한도_승인(props : Props) {
    return useObserver(() => (
        <div className={clsx(st.agenda, props.moreViewYn ? st.opened : '')}>
            <div className={st.title}>
                <div className={st.number}>{props.agendaIndex + 1}</div>
                <div className={st.text}><span className={st.important}>임원 보수 한도 승인</span></div>
                {props.agenda.decisionBody.map((decisionBody, index) => (
                    <div className={st.badge} key={index}>{decisionBody}</div>
                ))}
            </div>
            {props.moreViewYn && 
                <div className={st.info}>
                    <ul className={st.agendaList}>
                        <li>
                            승인기간 : {props.agenda.info.approvalStartAt.year+'-'+props.agenda.info.approvalStartAt.month+'-'+props.agenda.info.approvalStartAt.day+' - '+props.agenda.info.approvalEndAt.year+'-'+props.agenda.info.approvalEndAt.month+'-'+props.agenda.info.approvalEndAt.day}
                        </li>
                        <li>
                            승인방식 : {props.agenda.info.approvalType}
                        </li>
                        {props.agenda.info.salaryLimitOfExecutives.length === 0 &&
                            <>
                                <li>
                                    이사 보수 총 한도 : {addCommas(props.agenda.info.이사SalaryLimit)}원
                                </li>
                                <li>
                                    감사 보수 총 한도 : {addCommas(props.agenda.info.감사SalaryLimit)}원
                                </li>
                            </>
                        }
                        {props.agenda.info.salaryLimitOfExecutives.length !== 0 && 
                            <li>
                                임원별 보수액
                            </li>
                        }
                    </ul>
                    {props.agenda.info.salaryLimitOfExecutives.length !== 0 && 
                        <Table className={Cst.table}>
                            <colgroup>
                                <col/>
                                <col/>
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>임원</th>
                                    <th>퇴직금 포함 총 보수 (1년)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.agenda.info.salaryLimitOfExecutives.map((salaryLimitOfExecutive, index) => (
                                    <tr key={index}>
                                        <td>
                                            {salaryLimitOfExecutive.name} {salaryLimitOfExecutive.position}님
                                            {salaryLimitOfExecutive.newExecutiveYn === true &&
                                                <span className={Cst.new}>신규</span>
                                            }
                                        </td>
                                        <td>
                                            {addCommas(Number(salaryLimitOfExecutive.salary))} 원
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    }
                </div>
            }
        </div>
    ));
}
export default C임원_보수_한도_승인;