import { observable } from 'mobx';

const additionalInformationModel = observable({
    email: "",
    name: "",
    password: "",
    passwordCheck: "",
    countryCode: "82",
    phoneNumber: "",
    setEmail(data) {
        this.email = data || "";
    }
});

export default additionalInformationModel;