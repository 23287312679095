import { observable, runInAction } from "mobx";
import { _alert } from "@model/dialogModel";
import portalModel from "@model/portalModel";
import Document from "../Document/Document";

interface NewSharesAcquirer {
    id: number,
    name: string,
    subscriptionAt: string,
    paymentAt: string,
    stocks: {
        stockName: string,
        stockAmount: number,
        paymentAmount: number,
        issuePrice: number
    }[];
}

interface State {
    newSharesAcquirers: NewSharesAcquirer[];
}

class AddDateVm {
    state: State = observable({
        newSharesAcquirers: []
    })
    load(newSharesAcquirers: NewSharesAcquirer[]) {
        if(newSharesAcquirers && newSharesAcquirers.length !== 0) {
            this.state.newSharesAcquirers = newSharesAcquirers;
        }
    }   
    submit(agendaProgressId: string) {
        runInAction(() => {
            portalModel.title = '신주발행통지서';
            portalModel.content = <Document agendaProgressId={agendaProgressId} dividedSubscriptionYn={true} newSharesAcquirers={this.state.newSharesAcquirers} />
        })
    }
    setSubscriptionAt(e: Date, idx: number, _defaultSubscriptionAt: string) {
        let date = "";
        if(e) {
            const year = e.getFullYear();
            const month = ('0' + (e.getMonth() + 1)).slice(-2);
            const day = ('0' + e.getDate()).slice(-2);

            date = year + '-' + month  + '-' + day;   

            const defaultSubscriptionAt = new Date(_defaultSubscriptionAt || '');
            const subscriptionAt = new Date(date);
            const paymentAt = new Date(this.state.newSharesAcquirers[idx].paymentAt);
            if(defaultSubscriptionAt.getTime() > subscriptionAt.getTime()) {
                return _alert('통지일로부터 청약일은 14일 간격이 있어야해요. (통지당일은 포함X)');
            }
            if(paymentAt.getTime() < subscriptionAt.getTime()) {
                this.state.newSharesAcquirers[idx].paymentAt = date;
            }
            this.state.newSharesAcquirers[idx].subscriptionAt = date;
        }
    }
    setPaymentAt(e: Date, idx: number) {
        let date = "";
        if(e) {
            const year = e.getFullYear();
            const month = ('0' + (e.getMonth() + 1)).slice(-2);
            const day = ('0' + e.getDate()).slice(-2);

            date = year + '-' + month  + '-' + day;   

            const subscriptionAt = new Date(this.state.newSharesAcquirers[idx].subscriptionAt);
            const paymentAt = new Date(date);
            if(subscriptionAt.getTime() > paymentAt.getTime()) {
                return _alert('주금납입일은 청약일과 같거나 이후여야 해요.');
            }
            this.state.newSharesAcquirers[idx].paymentAt = date;
        }
    }
    sameSubscriptionAt() {
        const subscriptionAt = this.state.newSharesAcquirers[0].subscriptionAt;
        this.state.newSharesAcquirers.map((newSharesAcquirer, idx) => {
            if(idx !== 0) {
                newSharesAcquirer.subscriptionAt = subscriptionAt;

                const changeSubscriptionAt = new Date(newSharesAcquirer.subscriptionAt);
                const paymentAt = new Date(newSharesAcquirer.paymentAt);
                if(changeSubscriptionAt.getTime() > paymentAt.getTime()) {
                    newSharesAcquirer.paymentAt = subscriptionAt;
                }
            }
        })
    }
    samePaymentAt() {
        const paymentAt = this.state.newSharesAcquirers[0].paymentAt;
        this.state.newSharesAcquirers.map((newSharesAcquirer, idx) => {
            if(idx !== 0) {
                newSharesAcquirer.paymentAt = paymentAt;

                const changePaymentAt = new Date(newSharesAcquirer.paymentAt);
                const subscriptionAt = new Date(newSharesAcquirer.subscriptionAt);
                if(changePaymentAt.getTime() < subscriptionAt.getTime()) {
                    newSharesAcquirer.subscriptionAt = paymentAt;
                }
            }
        })
    }
}

export default new AddDateVm();