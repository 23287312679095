import corpModel from '@model/Corp/corpModel';

class BeforeRegisterVm {
    get companyRegisterStatus() {
        return corpModel.companyRegisterStatus;
    }
    get companyName() {
        return corpModel.company.companyName;
    }
    get companyAddress() {
        return corpModel.company.companyAddress;
    }
    otherPageData(pathType) {
        switch(pathType) {
            case 'dashboard':
                return {
                    title: '한눈에 보는 우리회사',
                    image: 'dashboard_example',
                    icon: 'companyInfoTitle_Icon',
                    explain: <div>지금 등기부를 발급받고 있어요.<br/>잠시만 기다려주세요.</div>,
                    subExplain: '최대 3분',
                }
            case 'articlesOfAssociation':
                return {
                    title: '정관',
                    image: 'articles_of_association_example',
                    icon: 'companyInfoTitle_Icon',
                    explain: <div>지금 정관을 등록하고 있어요.<br/>등록을 마치고 카톡으로 알림을 드릴게요.</div>,
                    subExplain: '최대 1영업일',
                }
            case 'stock':
                return {
                    title: '주주명부',
                    image: 'stock_example',
                    icon: 'stockHomeTitle_Icon',
                    explain: <div>지금 주주명부를 등록하고 있어요.<br/>등록을 마치고 카톡으로 알림을 드릴게요.</div>,
                    subExplain: '최대 1영업일',
                }
            case 'businessRegistration':
                return {
                    title: '사업자등록',
                    image: 'businessRegistration_example',
                    icon: 'companyInfoTitle_Icon',
                    explain: <div>지금 사업자등록 정보를 입력하고 있어요.<br/>입력을 마치고 카톡으로 알림을 드릴게요!</div>,
                    subExplain: '최대 1영업일',
                }
            default:
                return {
                    title: '',
                    image: '',
                    icon: '',
                    explain: '',
                    subExplain: ''
                }
        } 
    }
    registerPageData(status) {
        switch(status) {
            case 'COPY_ISSUING':
                return {
                    title: '등기부를 발급받고 있어요!',
                    explain: '최대 3분 소요'
                }
            case 'COPY_PARSING':
                return {
                    title: '등기부를 분석하고 있어요!',
                    explain: '최대 1분 소요'
                }
            case 'IROS_ERROR':
                return {                    
                    title: '인터넷등기소와 통신오류가 생겼어요.',                    
                    explain: <div>오류가 해결되는 대로 등기부를 발급받고, 카톡으로 알려드릴게요. :)<br/>최대 1영업일이 걸릴 수 있어요.</div>

                }
            case 'PARSE_ERROR':
                return {
                    title: '등기부 분석 중 오류가 생겼어요.',
                    explain: <div>오류를 해결하는 대로 카톡으로 알려드릴게요. :)<br/>최대 1영업일이 걸릴 수 있어요.</div>
                }
            default: 
                return {
                    title: '',
                    explain: ''
                }
        }
    }
}

export default new BeforeRegisterVm();