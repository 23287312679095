import authService from "@service/authService";
import expiredInvitedMembershipModel from "@model/Auth/expiredInvitedMembershipModel";

class ExpiredInvitedMembershipVm {
    load(token) {
        authService.expiredInvitedMembership(token);
    }   
    get invitedDate() {
        return expiredInvitedMembershipModel.invitedDate;
    }
    get expiredDate() {
        return expiredInvitedMembershipModel.expiredDate;
    }
}
export default new ExpiredInvitedMembershipVm();