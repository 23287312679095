import { observable, runInAction, toJS } from 'mobx';
import formDataToJson from '@common/module/submit';
import unissuedStockCertificateService from '@service/Corp/Stock/UnissuedStockCertificate/unissuedStockCertificateService';
import unissuedStockCertificateModel from '@model/Corp/Stock/UnissuedStockCertificate/unissuedStockCertificateModel';
import regExp from '@common/module/regExp';
import userModel from '@model/userModel';

class DownloadVm {
    constructor() {
        this.state = observable({
            representatives: 'ALL',
            createdAt: userModel.date, 
            shareholderId: "",
            showShareholderUniqueInfo: "NO_SHOW",
            corpUniqueInfo: "UNIQUE_NUMBER",
            showAddress: 'NO_SHOW',
            showOnlineStampYn: true, 
            updateInfo: {
                uniqueNumber: "",
                address: ""
            },
            loadingYn: false
        })
    }
    get minDate () {
        return unissuedStockCertificateModel.minDate;
    }
    get param() {
        let representativeList = [];
        
        if(this.showRepresentativeNameSelectionYn) {
            if(this.state.representatives === 'ALL') {
                representativeList = unissuedStockCertificateModel.representatives;
            } else {
                representativeList = unissuedStockCertificateModel.representatives.filter((elem) => Number(elem.id) === Number(this.state.representatives));
            }
        } else {
            representativeList = unissuedStockCertificateModel.representatives;
        }

        let param = {
            representatives: representativeList,
            createdAt: this.state.createdAt,
            shareholderId: this.state.shareholderId,
            showShareholderUniqueInfo: this.state.showShareholderUniqueInfo,
            corpUniqueInfo: this.state.corpUniqueInfo,
            showAddress: this.state.showAddress,
            showOnlineStampYn: this.state.showOnlineStampYn,
            updateInfo: this.state.updateInfo
        }
        return param;
    }
    async load() {
        await unissuedStockCertificateService.loadSelectionInfo(userModel.date);
        this.state.shareholderId = unissuedStockCertificateModel?.shareholders[0].id;
        this.requestPreview();
    }
    async submit(e, next, setSendParams) {
        e.preventDefault();
        if(formDataToJson(e.target).error) {
            return
        } else {
            if(e.nativeEvent?.submitter?.name === 'download') {
                try {
                    this.state.loadingYn = true;
                    await unissuedStockCertificateService.pdfDownload(this.param);
                    this.state.loadingYn = false;
                } catch(err) {
                    this.state.loadingYn = false;
                }
            } else if(e.nativeEvent?.submitter?.name === 'sendEmail') {
                setSendParams(this.param);
                next();
            }
        }
    }
    async requestPreview() {
        await unissuedStockCertificateService.preview(this.param);
    }
    get previewLink() {
        return unissuedStockCertificateModel.previewLink;
    }
    async setCreatedAt(e) {
        let date = null;
        if(e) {
            const year = e.getFullYear();
            const month = ('0' + (e.getMonth() + 1)).slice(-2);
            const day = ('0' + e.getDate()).slice(-2);

            date = year + '-' + month  + '-' + day;   
        }
        this.state.createdAt = date;
        await unissuedStockCertificateService.loadSelectionInfo(this.state.createdAt);
        this.requestPreview();
    }
    get shareholders() {
        return unissuedStockCertificateModel.shareholders;
    }
    setShareholderId(e) {
        this.state.shareholderId = e.target.value;
        this.requestPreview();
    }
    setShowShareholderUniqueInfo(e) {
        this.state.showShareholderUniqueInfo = e.target.value;
        this.requestPreview();
    }
    displayCorpUniqueInfo() {
        let displayYn = false;
        if(this.state.showShareholderUniqueInfo !== 'NO_SHOW') {
            unissuedStockCertificateModel.shareholders.forEach((item) => {
                if(Number(this.state.shareholderId) === Number(item.id)) {
                    if(item.shareholderType === '국내법인' || item.shareholderType === '펀드/투자조합') {
                        displayYn = true;
                    }
                }
            });
        }
        
        return displayYn;
    }
    setCorpUniqueInfo(e) {
        if(this.state.corpUniqueInfo !== e.target.value) {
            this.state.updateInfo.uniqueNumber = "";                 
        }
        this.state.corpUniqueInfo = e.target.value;
        this.requestPreview();
    }
    displayUniqueInfoInput() {
        let displayYn = false;
        if(this.state.showShareholderUniqueInfo !== 'NO_SHOW') {
            unissuedStockCertificateModel.shareholders.forEach((item) => {
                if(Number(this.state.shareholderId) === Number(item.id)) {
                    if(item.shareholderType === '국내법인' || item.shareholderType === '펀드/투자조합') {
                        if(this.state.corpUniqueInfo === 'UNIQUE_NUMBER' && item.existsUniqueInfoYn === false) {
                            displayYn = true;
                        } else if(this.state.corpUniqueInfo === 'BUSINESS_REGISTRATION_NUMBER' && item.existsBusinessRegistrationNumberYn === false) {
                            displayYn = true;
                        }
                    } else {
                        if(item.existsUniqueInfoYn === false) {
                            displayYn = true;
                        }
                    }
                }
            });
        }
        return displayYn
    }
    get uniqueNumberType() {
        
        const shareholderIdx = Number(unissuedStockCertificateModel.shareholders.findIndex((item) => Number(item.id) === Number(this.state.shareholderId)));
        const shareholderType = unissuedStockCertificateModel.shareholders[shareholderIdx].shareholderType;
        const existsUniqueInfoYn = unissuedStockCertificateModel.shareholders[shareholderIdx].existsUniqueInfoYn;
        const existsBusinessRegistrationNumberYn = unissuedStockCertificateModel.shareholders[shareholderIdx].existsBusinessRegistrationNumberYn;

        if(shareholderType === '국내성인' || shareholderType === '국내미성년자') {
            return {
                type: '주민등록번호',
                placeholder: "111111-1111111",
                kssn: true,
                ein: false,
                validate: {
                    func: regExp.kssn(this.state.updateInfo?.uniqueNumber),
                    message: '올바른 주민등록번호를 입력해 주세요.'
                }
            };
        } else if(shareholderType === '국내법인') {
            if(this.state.corpUniqueInfo === 'UNIQUE_NUMBER' && existsUniqueInfoYn === false) {
                return {
                    type: '법인등록번호',
                    placeholder: "111111-1111111",
                    kssn: true,
                    ein: false,
                    validate: {
                        func: regExp.cssn().test(this.state.updateInfo?.uniqueNumber),
                        message: '올바른 법인등록번호를 입력해 주세요.'
                    }
                };
            } else if(this.state.corpUniqueInfo === 'BUSINESS_REGISTRATION_NUMBER' && existsBusinessRegistrationNumberYn === false) {
                return {
                    type: '사업자등록번호',
                    placeholder: "111-11-11111",
                    kssn: false,
                    ein: true,
                    validate: {
                        func: regExp.ein().test(this.state.updateInfo?.uniqueNumber),
                        message: '올바른 사업자등록번호를 입력해 주세요.'
                    }
                };
            }
        }
        else if(shareholderType === '펀드/투자조합') {
            if(this.state.corpUniqueInfo === 'UNIQUE_NUMBER' && existsUniqueInfoYn === false) {
                return {
                    type: '고유번호',
                    placeholder: "111-11-11111",
                    kssn: false,
                    ein: true,
                    validate: {
                        func: regExp.ein().test(this.state.updateInfo?.uniqueNumber),
                        message: '올바른 고유번호를 입력해 주세요.'
                    }
                };
            } else if(this.state.corpUniqueInfo === 'BUSINESS_REGISTRATION_NUMBER' && existsBusinessRegistrationNumberYn === false) {
                return {
                    type: '사업자등록번호',
                    placeholder: "111-11-11111",
                    kssn: false,
                    ein: true,
                    validate: {
                        func: regExp.ein().test(this.state.updateInfo?.uniqueNumber),
                        message: '올바른 사업자등록번호를 입력해 주세요.'
                    }
                };
            }
        } else if(shareholderType === '해외성인' || shareholderType === '해외미성년') {
            return {
                type: '생년월일',
                placeholder: "0000-00-00",
                kssn: false,
                ein: false,
                validate: {}
            };
        } else if(shareholderType === '해외법인') {
            return {
                type: '설립년월일',
                placeholder: "0000-00-00",
                kssn: false,
                ein: false,
                validate: {}
            };
        }
    }
    get shareholderName() {
        let name = '';
        unissuedStockCertificateModel.shareholders.forEach((item) => {
            if(Number(item.id) === Number(this.state.shareholderId)) {
                name = item.name;
            }
        })
        return name;
    }
    get shareholderType() {
        let shareholderType = '';
        unissuedStockCertificateModel.shareholders.forEach((item) => {
            if(Number(item.id) === Number(this.state.shareholderId)) {
                shareholderType = item.shareholderType;
            }
        })
        return shareholderType;
    }
    setBlur() {
        this.requestPreview();
    }
    setUniqueNumber(e) {
        this.state.updateInfo.uniqueNumber = e.target.value;
    }
    setUniqueNumberBirth(e) {
        let date = null;
        if(e) {
            const year = e.getFullYear();
            const month = e.getMonth() + 1;
            const day = e.getDate();

            date = year + '-' + month  + '-' + day;   
        }
        this.state.updateInfo.uniqueNumber = date;
        this.requestPreview();
    }
    setShowAddress(e) {
        this.state.showAddress = e.target.value;
        this.requestPreview();
    } 
    displayAddressInfoInput() {
        let displayYn = false;
        if(this.state.showAddress === 'ALL_SHOW') {
            unissuedStockCertificateModel.shareholders.forEach((item, idx) => {
                if(Number(item.id) === Number(this.state.shareholderId)) {
                    displayYn = !item.existsAddressYn;
                }
            })
        }
        return displayYn;
    }
    setAddress(e) {
        this.state.updateInfo.address = e.target.value;
    }
    setShwoOnlineStampYn(e) {
        this.state.showOnlineStampYn = e.target.value === "true";
        this.requestPreview();
    }
    get showRepresentativeNameSelectionYn() {
        return unissuedStockCertificateModel.showRepresentativeNameSelectionYn;
    }
    get representatives() {
        return unissuedStockCertificateModel.representatives;
    }
    setRepresentatives(e) {
        this.state.representatives = e.target.value;
        this.requestPreview();
    }
}


export default DownloadVm;