import { post, get, downloadFilePost } from '@common/module/httpRequest';
import { pageRouter } from '@model/pageModel';
import { runInAction, toJS } from 'mobx'; 
import corpModel from '@model/Corp/corpModel';
import userModel from '@model/userModel';
import unissuedStockCertificateModel from '@model/Corp/Stock/UnissuedStockCertificate/unissuedStockCertificateModel';

class UnissuedStockCertificateService {
    async loadSelectionInfo(_date) {
        const res = await get(`company/${corpModel.id}/shareholder/unissuedStockCertificate/paper/selectionInfo`, {
            date: _date
        }, {});
        const data = res.data;
        unissuedStockCertificateModel.setLoadSelectionInfo(data);
    }
    async preview(param) {
        const res = await post(`company/${corpModel.id}/shareholder/unissuedStockCertificate/paper/preview`, param);
        const data = res.data;
        unissuedStockCertificateModel.setPreviewLink(data);
    }
    async pdfDownload(param) {
        await downloadFilePost(`company/${corpModel.id}/shareholder/unissuedStockCertificate/paper/download/pdf`, param, {});
    }
    async send(param) {
        const res = await post(`company/${corpModel.id}/shareholder/unissuedStockCertificate/paper/send`, param);
    }
}

export default new UnissuedStockCertificateService()