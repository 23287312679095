import { useState, useEffect } from 'react';
import { useObserver } from 'mobx-react';
import st from './Timer.module.scss';

interface TimeLeft {
    days: number;
    hours: number;
    minutes: number;
    expired: boolean;
}

function Timer() {
    const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            const newTimeLeft = calculateTimeLeft();
            if (!newTimeLeft.expired) {
                setTimeLeft(newTimeLeft);
            } else {
                clearInterval(timer);
            }
        }, 1000);
    
        return () => clearInterval(timer);
    }, []);

    function calculateTimeLeft(): TimeLeft {
        const now: Date = new Date();
        const year: number = now.getFullYear();
        const month: number = now.getMonth();
        const nextMonth: Date = new Date(year, month + 1, 1, 5, 0, 0); // 다음 달의 첫째 날 0시 0분 0초
        const endOfYear: Date = new Date(2025, 0, 1, 0, 0, 0); // 2025년 1월 1일 0시 0분 0초
    
        if (now >= endOfYear) {
            return { days: 0, hours: 0, minutes: 0, expired: true };
        }
    
        const difference: number = nextMonth.getTime() - now.getTime();
        const days: number = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours: number = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes: number = Math.floor((difference / 1000 / 60) % 60);
    
        return {
            days,
            hours,
            minutes,
            expired: false,
        };
    }
    
    return useObserver(() => (
        <div className={st.timer}>
            {timeLeft.expired ? 
                <div>프로모션이 종료되었습니다.</div>
                :
                <>
                    <div>프로모션 종료까지</div>
                    <div className={st.time}>
                        <span className={st.timeCell}>D</span>
                        <span className={st.timeCell}>-</span>
                        <span className={st.timeCell}>{String(timeLeft.days).padStart(2, '0').substring(0, 1)}</span>
                        <span className={st.timeCell}>{String(timeLeft.days).padStart(2, '0').substring(1, 2)}</span>
                        <span className={st.timeCell} style={{marginLeft:"8px"}}>{String(timeLeft.hours).padStart(2, '0').substring(0, 1)}</span>
                        <span className={st.timeCell}>{String(timeLeft.hours).padStart(2, '0').substring(1, 2)}</span>
                        <span>:</span>
                        <span className={st.timeCell}>{String(timeLeft.minutes).padStart(2, '0').substring(0, 1)}</span>
                        <span className={st.timeCell}>{String(timeLeft.minutes).padStart(2, '0').substring(1, 2)}</span>
                    </div>
                </>
            }
        </div>
    ));
}
export default Timer;