import { useObserver } from 'mobx-react';
import React from 'react';
import vm from './CommonAgendaVm';
import qst from '@pages/Corp/Agenda/Step/AgendaQuestion/commonQuestion.module.scss';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import TextArea from '@standby/common-ui/components/atoms/Input/TextArea/TextArea';
import FileBtn from '@standby/common-ui/components/atoms/Button/FileBtn/FileBtn';
import st from './CommonAgenda.module.scss';
import QuestionTooltip from '@standby/common-ui/components/molecules/QuestionTooltip/QuestionTooltip';
import FileUpload from '@commonComponents/FileUpload/FileUpload';
import type {SelectedAgenda, CommonAgendaDetail} from '@model/Corp/Agenda/Step/agendaStepModel.interface';

interface CommonAgendaProps {
    agenda : SelectedAgenda<CommonAgendaDetail>,
    agendaIndex : number
}
const CommonAgenda = React.memo(function (props : CommonAgendaProps) {
    return useObserver(() => (
        <div className={qst.questionForm}>
            {vm.isFileRequired(props.agenda.agendaType) === true && 
                <div className={qst.qusetion}>
                    <div className={qst.questionTitle}>
                        <QuestionTooltip title="주주총회에서 매년 임원 보수를 정하는 것이 번거롭다면?" style={{width: '460px'}}>
                            <div className={st.tooltipContent}>
                                <div className={st.exTitle}>임원의 보수는 정관 또는 주주총회로 정해야 해요.</div>
                                <ul className={st.exList}>
                                    <li>상법상 이사, 감사, 대표의 보수(급여, 상여금, 퇴직금)은 <span className={st.important}>정관에서 정하지 않으면, 주주총회에서 매번 승인해야</span> 해요. 이렇게 하지 않는다면, 나중에 세무조사 진행시, 임원의 보수에 대한 비용처리가 부인당할 수도 있어요.</li>
                                    <li>하지만 정관에 대표이사의 보수는 5억 원이라고 구체적으로 적어두는 것은 추천하지 않아요. 정관은 비밀문서가 아니고, 외부에 공개할 일이 자주 있기 때문이에요.</li>
                                </ul>
                                <div className={st.exTitle}>정관에 간단한 내용만 넣어두고, 별도로 규정을 만드는 편을 추천해요.</div>
                                <ul className={st.exList}>
                                    <li>
                                        번거로움을 피하기 위한 가장 간단한 방법을 알려드릴게요.
                                        <ul className={st.stepList}>
                                            <li>
                                                <span className={st.step}>STEP1</span>
                                                <div className={st.content}>
                                                    스탠바이에서 [규정승인] 안건을 선택해주세요.<br/>(임원 보수, 상여금, 퇴직금)
                                                </div>
                                            </li>
                                            <li>
                                                <span className={st.step}>STEP2</span>
                                                <div className={st.content}>
                                                    임원 보수, 상여금, 퇴직금 규정을 만들고, 주주총회에서 승인을 받으세요.
                                                    <div className={st.fileBtn}>
                                                        <FileBtn onClick={() => vm.download()}>보수, 상여금, 퇴직금 규정 3종 샘플 다운로드</FileBtn>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        이 때 정관에 “임원의 보수, 상여금, 퇴직금은 주주총회의 승인을 거친 별도 규정으로 정한다.”라는 내용이 포함되어 있어야 해요. 만약 포함되어 있지 않다면, 스탠바이에서 자동으로 정관변경 안건을 추가해드려요.
                                        <ul className={st.stepList}>
                                            <li>
                                                <span className={st.step}>STEP3</span>
                                                <div className={st.content}>
                                                    별도 규정의 내용에 따라 필요하다면, 이사회 승인을 진행하세요.
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </QuestionTooltip>
                    </div>
                </div>
            }
            <div className={qst.qusetion}>
                <div className={qst.questionTitle}>
                    {vm.is자유입력(props.agendaIndex) ? '안건 제목을 입력하세요.' : '안건 제목을 확인하세요.'}
                </div>
                <div>
                    <InputText disabled={!vm.is자유입력(props.agendaIndex)} value={props.agenda.agendaDetail.title} name="agendaDetail.title" onChange={(e) => vm.title(e, props.agendaIndex)} style={{width:"460px"}}
                        errorText={<Error name="agendaDetail.title" value={props.agenda.agendaDetail.title} errorCase={{
                            required: '안건제목을 입력하세요.'
                        }}
                        />}
                    />
                    {!vm.is자유입력(props.agendaIndex) && 
                        <input type="hidden" value={props.agenda.agendaDetail.title} name="agendaDetail.title"/>
                    }
                </div>
            </div>
            <div className={qst.qusetion}>
                <div className={qst.questionTitle}>안건 내용을 입력하세요.</div>
                <div className={st.textarea}>
                    <TextArea name="agendaDetail.content" onChange={(e) => vm.content(e, props.agendaIndex)} value={props.agenda.agendaDetail.content} style={{height:"300px", resize:"none"}}
                    errorText={<Error name="agendaDetail.content" value={props.agenda.agendaDetail.content} errorCase={{
                        required: '안건내용을 입력하세요.'
                    }}
                    />}/>
                </div>
            </div>
            {vm.shouldGetFile(props.agenda.agendaType) === true && 
                <div className={qst.qusetion}>
                    {vm.isFileRequired(props.agenda.agendaType) === true && 
                        <div className={qst.questionTitle}>
                            회의록 뒤에 별지로 첨부할 {props.agenda.title.replace(" 승인", "")} 파일을 업로드하세요.
                            <div style={{marginTop:"10px"}}>
                                <FileBtn style={{width:'183px'}} onClick={() => vm.sampleDownload(props.agenda.title)}>{props.agenda.title.replace(" 승인", "").replace("임원", " ")} 샘플 다운로드</FileBtn>
                            </div>
                        </div>
                    }
                    {vm.isFileRequired(props.agenda.agendaType) === false && 
                        <div className={qst.questionTitle}>
                            <div style={{marginBottom:"10px"}}>회의록 뒤에 별지로 첨부할 파일을 업로드하세요. (선택)</div>
                            <QuestionTooltip title="별지 첨부가 필요한 경우 & 예시">
                                <div className={st.tooltipContent}>
                                    <div className={st.exTitle}>별지 첨부가 필요한 경우</div>
                                    <ul className={st.exList}>
                                        <li>회의를 통해 승인했다는 증거를 확실히 남기고 싶은 경우 추천해요.</li>
                                        <li>일반적인 경우라면, 첨부하지 않아도 괜찮아요.</li>
                                        <li>별지 파일명이 그대로 의사록 등 서류에 기재되니, 파일명을 읽기 좋게 수정하는 편을 추천해요.</li>
                                    </ul>
                                    <div className={st.exTitle}>예시</div>
                                    <div className={st.sampleList}>
                                        <div className={st.sample}>
                                            <img src='/images/proceedings_attachment_ex01.svg'/>
                                        </div>
                                        <div className={st.sample}>
                                            <img src='/images/proceedings_attachment_ex02.svg'/>
                                        </div>
                                    </div>
                                </div>
                            </QuestionTooltip>
                        </div>
                    }
                    <FileUpload name={`agendaDetail.attachmentFile.uploadFiles`} attachmentFilesModel={props.agenda.agendaDetail.attachmentFiles} accept=".pdf"/>
                    <div className={st.ex}>PDF 파일만 업로드 가능</div>
                    {/* {vm.state.deleteFilesIds.map((deleteFilesId, index) => (
                        <input type="hidden" key={index} name={`agendaDetail.attachmentFile.deleteFilesIds[${index}]`} value={deleteFilesId}/>
                    ))} */}
                </div>
            }
        </div>
    ));
})
export default CommonAgenda;