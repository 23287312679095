import ChangeRegistrationAgencyService from "@service/Corp/Agenda/Step/FinalCheck/BeforeFinal/ChangeRegistrationAgency/ChangeRegistrationAgencyService";
import { _confirm } from "@model/dialogModel";

class RegistrationAgencyVm {
    status(steps: {[key in "등기대행_접수완료" | "견적서_발송" | "결제완료" | "등기소_접수" | "등기완료"]: string} | null) {
        if(steps) {
            return Object.keys(steps)[Object.keys(steps).length-1];
        } else {
            return "등기대행_접수완료";
        }
    }   
    async changeSelf(agendaProgressId: string) {
        
        if(await _confirm('등기대행 신청을 취소하고, 셀프등기로 변경하나요?', '셀프등기로 변경하면, 등기부, 주주명부, 정관 변경이 자동으로 반영되지 않아요. 등기가 끝난 후 변경된 서류를 수동으로 업로드 해야 해요.', '네', '아니오')) {
            await ChangeRegistrationAgencyService.load(agendaProgressId);
            window.location.reload();
        }
    }
} 

export default new RegistrationAgencyVm();