import { useObserver } from 'mobx-react';
import { useEffect, useState } from 'react';
import vm from './BankbookVm';
import st from './Bankbook.module.scss';
import clsx from 'clsx';
import PageTitle from '@commonComponents/PageTitle/PageTitle';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import CopyBtn from '@standby/common-ui/components/atoms/Button/CopyBtn/CopyBtn';
import copyText from '@standby/common-ui/module/copyText';
import ToastMessage from '@standby/common-ui/components/atoms/ToastMessage/ToastMessage';

import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort';

interface CopyBtnWithToastProps {
    className: string;
    toastMessageClassName: string;
    copyText: string;
    children: string;
}

function CopyBtnWithToast(props: CopyBtnWithToastProps) {

    const [showYn, setShowYn] = useState<boolean>(false);

    const _onClick = (_copyText: string) => {
        setShowYn(true);
        copyText(_copyText);
        setTimeout(() => setShowYn(false), 2000);
    }

    return useObserver(() => (
        <div className={st.toastMessageBtnBox}>
            {showYn && <ToastMessage className={props.toastMessageClassName}>복사 되었어요!</ToastMessage>}
            <button type="button" className={props.className} onClick={() => _onClick(props.copyText)}>{props.children}</button>           
        </div>
    ))
};

function Bankbook() {

    useEffect(() => {
        vm.load();
    }, []);

    return useObserver(() => (
        <>
            <PageTitle><span className={st.pageTitle}>사업자등록</span></PageTitle> 
            <ContentBox title="통장사본 추가/변경하기" className={st.contentBox} titleStyle={{top:"15px", paddingLeft:"18px", fontSize:"18px"}}>
                <div className={st.floatBtnArea}>
                    <SquareBtn className={st.addBankbookBtn} onClick={() => vm.addBankbook()}>통장사본 추가하기</SquareBtn>
                </div>
                <form className={st.content} onSubmit={(e) => vm.submit(e)}>
                    <div className={st.title}>계좌정보 리스트</div>
                    <div className={st.bankbookBox}>
                        <div className={st.header}>
                            <span>순번</span>
                            <span>은행</span>
                            <span>계좌번호</span>
                            <span>계좌용도</span>
                        </div>
                        <SortableList onSortEnd={(oldIndex, newIndex) => vm.onSortEnd(oldIndex, newIndex)} draggedItemClassName={st.dragged} className={st.bankbookList}>
                            <ul>
                                {vm.bankbooks.map((bankbook, idx) => (
                                    <SortableItem key={idx}>
                                        <li key={idx} className={st.bankbookInfo}>
                                            <div className={st.bankbookInfoContent}>
                                                <div className={st.left}>
                                                    <div className={st.fixedWidth}>
                                                        <input type="hidden" name="bankbookIds" value={Number(bankbook.id)}/>
                                                        <span className={st.index}>{idx+1}</span>
                                                        <span className={clsx(st.bankName, st[bankbook.bankName || ""])}>{bankbook.bankName}</span>
                                                        <CopyBtn className={st.accountNumber} messageClassName={st.accountNumberCopyMessage} copyText={bankbook.accountNumber}>{bankbook.accountNumber}</CopyBtn>
                                                    </div>
                                                    <div className={st.accountUsage}>{bankbook.accountUsage || '-'}</div>
                                                </div>
                                                <div className={st.accoutBox}>
                                                    <div className={st.bankbookBtnArea}>
                                                        <CopyBtnWithToast className={st.accountInfoCopyBtn} toastMessageClassName={st.toastMessage} copyText={vm.accountInfoCopyText(bankbook.bankName, bankbook.accountNumber)}>계좌 정보 복사</CopyBtnWithToast>
                                                            <button type="button" className={st.bankbookDownloadBtn} onClick={() => vm.fileDownload(bankbook.fileDownloadUrl || "")}><span className={st.text}>통장사본 다운로드</span></button>
                                                    </div>
                                                    <div className={st.fixBtnArea}>
                                                        <button type="button" className={st.fixBtn} onClick={() => vm.changeBankbook(bankbook.id, 'Change')}>수정</button>
                                                        <button type="button" className={st.deleteBtn} onClick={() => vm.deleteBankbook(bankbook.id)}>삭제</button>
                                                    </div>
                                                </div>
                                                <SortableKnob><div className={st.dragBtn}></div></SortableKnob>
                                            </div>
                                            <div className={st.line}></div>
                                        </li>
                                    </SortableItem>
                                ))}
                            </ul>
                        
                    </SortableList>
                </div>
                    <div className={st.btnArea}>
                        <SquareBtn type="submit" className={st.sendBtn}>수정사항 저장하기</SquareBtn>
                    </div>
                </form>
            </ContentBox>
        </>
    ));
}
export default Bankbook;