import { makeObservable, observable } from 'mobx';
import type {awsCreditModelInterface, Step2, Step3} from './awsCreditModel.interface';

class awsCreditModel implements awsCreditModelInterface {
    constructor() {
        makeObservable(this, {
            step2 : observable,
            step3 : observable
        })
    }
    step2: Step2 = {
        useAWSYn : null,
        MGCUseYn : null,
        monthCost : '',
        purpose : '',
        cloudServiceUseType : undefined,
        cloudServiceName : '',
        name : '',
        email : '',
        phoneNumber : '',
        responsibility : '',
        termInfos : []
    }
    step3 : Step3 = {
        status : null
    }

}

export default new awsCreditModel();