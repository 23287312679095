
import { removeCommas, onlyNumber } from '@common/module/replaceNumber';
import agendaStepModel from '@model/Corp/Agenda/Step/agendaStepModel';
import type {SelectedAgenda, I액면금분할_합병} from '@model/Corp/Agenda/Step/agendaStepModel.interface';
class ParValueDivisionMergerVm {
    selectedAgenda(index : number) {
        return agendaStepModel.selectedAgendas[index] as SelectedAgenda<I액면금분할_합병>
    }
    afterParValue(e : React.ChangeEvent<HTMLInputElement>, index : number) {
        this.selectedAgenda(index).agendaDetail.afterParValue = removeCommas((onlyNumber(e.target.value)).toString());
    }    
}
export default new ParValueDivisionMergerVm();