import guestModel from "@model/Guest/guestModel";
import guestService from "@service/Guest/guestService";
import type { A주주총회_소집절차_생략동의 } from '@model/Guest/guestModel.interface';
import type { AcceptShareholderMeetingWaiverConsentRs } from '@service/Guest/guestService.interface';

class InformationVm  {
    get authInfo() {
        const authInfo = guestModel.authInfo as A주주총회_소집절차_생략동의;
        return authInfo;
    }
    get companyName() {
        return this.authInfo.companyName;
    }
    get shareholderName() {
        return this.authInfo.shareholderName;
    }
    get documentUrl() {
        return this.authInfo.documentUrl;
    }
    agreeBtn(token: string, changePage: () => void) {
        try {
            guestService.accept<AcceptShareholderMeetingWaiverConsentRs>(token);
            changePage();
        } catch(err) {

        }
    }
}

export default new InformationVm();