import { useObserver } from 'mobx-react';
import st from './Step2.module.scss';
import RadioLabel from '@standby/common-ui/components/molecules/RadioLabel/RadioLabel';
import vm from './Step2Vm';
import { useEffect } from 'react';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import regExp from '@common/module/regExp';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import TextBtn from '@standby/common-ui/components/atoms/Button/TextBtn/TextBtn';
import clsx from 'clsx';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import ErrorText from '@standby/common-ui/components/atoms/Text/ErrorText/ErrorText';

function Step2() {
    let count = 1;
    useEffect(() => {
        vm.load();
    }, [])
    return useObserver(() => (
        <>
            <div className={st.mainTitle}><span className={st.bold}>클라우드 지원금 270만 원</span> 무상제공을 위한 간단 <span className={st.bold}>정보입력</span></div>
            <div className={st.mainContent}>
                <div className={st.registraionRow}>
                    <div className={st.registrationTitle}><span className={st.bold}>지원금</span>은<br/>
                        이렇게 <span className={st.bold}>제공</span>돼요.
                    </div>
                    <div className={st.registrationContent}>
                        <img src="/images/awsCredit_progress.png?1"/>
                        <div className={st.checkInfo}>
                            메가존 클라우드는 AWS 파트너사에요. 메가존 클라우드와 계약을 체결하더라도 <span className={st.bold}>추가비용이 없어요.</span>
                        </div>
                    </div>
                </div>
                <div className={st.registraionRow}>
                    <div className={st.registrationTitle}><span className={st.bold}>정보입력</span></div>
                    <div className={st.registrationContent}>
                        <form className={st.form}  onSubmit={(e) => vm.submit(e)}>
                            <div className={st.question}>
                                <div className={st.title}>{count++}. 현재 {vm.companyName}(은)는 <span className={st.bold}>AWS를 이용 중</span>인가요?</div>
                                <div className={st.info}>AWS를 이용 중이더라도 지원금을 제공해요!</div>
                                <div className={st.answer}>
                                    <RadioLabel name="useAWSYn" value={'true'} checked={vm.data.useAWSYn === true} onChange={(e) => vm.useAWSYn(e)}>네</RadioLabel>
                                    <RadioLabel name="useAWSYn" value={'false'} checked={vm.data.useAWSYn === false} onChange={(e) => vm.useAWSYn(e)}>아니오</RadioLabel>
                                    <div>
                                        <Error name="useAWSYn" value={vm.data.useAWSYn === null ? undefined : String(vm.data.useAWSYn)} errorCase={{
                                                required: `AWS 이용여부를 선택해 주세요.`
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {vm.data.useAWSYn === true && 
                                <div className={st.question}>
                                    <div className={st.title}>{count++}. {vm.companyName}(은)는 <span className={st.bold}>메가존 클라우드의 AWS 파트너십 고객</span>인가요?</div>
                                    <div className={st.info}>클라우드 지원금 270만 원은 메가존의 신규고객에게만 제공돼요.</div>
                                    <div className={st.answer}>
                                        <RadioLabel name="MGCUseYn" value={'true'} checked={vm.data.MGCUseYn === true} onChange={(e) => vm.MGCUseYn(e)}>네</RadioLabel>
                                        <RadioLabel name="MGCUseYn" value={'false'} checked={vm.data.MGCUseYn === false} onChange={(e) => vm.MGCUseYn(e)}>아니오</RadioLabel>
                                        <div>
                                            <Error name="MGCUseYn" value={vm.data.MGCUseYn === null ? undefined : String(vm.data.MGCUseYn)} errorCase={{
                                                    required: `메가존 클라우드의 AWS 파트너십 고객여부를 선택해 주세요.`
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            {vm.data.useAWSYn === false && 
                                <div className={st.question}>
                                    <div className={st.title}>{count++}. 현재 <span className={st.bold}>타 클라우드 서비스</span>를 이용 중인가요?</div>
                                    <div className={st.answer} style={{marginTop:"16px"}}>
                                        <RadioLabel name="cloudServiceUseType" value='아니오' checked={vm.data.cloudServiceUseType === '아니오'} onChange={(e) => vm.cloudServiceUseType(e)}>아니오</RadioLabel>
                                        <RadioLabel name="cloudServiceUseType" value='타_클라우드를_쓰고_있어요' checked={vm.data.cloudServiceUseType === '타_클라우드를_쓰고_있어요'} onChange={(e) => vm.cloudServiceUseType(e)}>타 클라우드를 쓰고 있어요</RadioLabel>
                                        <RadioLabel name="cloudServiceUseType" value='서버를_구입하여_직접_운영하고_있어요' checked={vm.data.cloudServiceUseType === '서버를_구입하여_직접_운영하고_있어요'} onChange={(e) => vm.cloudServiceUseType(e)}>서버를 구입하여 직접 운영하고 있어요</RadioLabel>
                                        <div>
                                            <Error name="cloudServiceUseType" value={vm.data.cloudServiceUseType} errorCase={{
                                                    required: `타 클라우드 서비스 이용여부를 선택해 주세요.`
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            {(vm.data.useAWSYn === false && vm.data.cloudServiceUseType === '타_클라우드를_쓰고_있어요') &&
                                <>
                                    <div className={st.question}>
                                        <div className={st.title}>{count++}. 사용하는 <span className={st.bold}>클라우드 서비스 이름</span>을 알려주세요.</div>
                                        <div className={st.answer}>
                                            <InputText style={{width:"460px"}} placeholder="Azure, Naver Cloud...." name='cloudServiceName' value={vm.data.cloudServiceName} onChange={(e) => vm.cloudServiceName(e)} 
                                                errorText={<Error name={`cloudServiceName`} value={vm.data.cloudServiceName} errorCase={{
                                                    required: '클라우드 서비스 이름을 입력해 주세요.'
                                                }}
                                                />}
                                            />
                                        </div>
                                    </div>
                                    <div className={st.question}>
                                        <div className={st.title}>{count++}. <span className={st.bold}>클라우드 월 비용</span>을 알려주세요.</div>
                                        <div className={st.info}>월비용을 절약하기 위한 컨설팅을 제공해드려요.</div>
                                        <div className={st.answer}>
                                        <InputText style={{width:"460px"}} placeholder="약 월 300만원" name='monthCost' value={vm.data.monthCost} onChange={(e) => vm.monthCost(e)} 
                                            errorText={<Error name={`monthCost`} value={vm.data.monthCost} errorCase={{
                                                required: '클라우드 월 비용을 입력해 주세요.'
                                            }}
                                            />}
                                        />
                                        </div>
                                    </div>
                                    <div className={st.question}>
                                        <div className={st.title}>{count++}. <span className={st.bold}>클라우드 사용 목적</span>을 알려주세요.</div>
                                        <div className={st.answer}>
                                        <InputText style={{width:"460px"}} placeholder="홈페이지 운영, 앱 운영" name='purpose' value={vm.data.purpose} onChange={(e) => vm.purpose(e)} 
                                            errorText={<Error name={`purpose`} value={vm.data.purpose} errorCase={{
                                                required: '클라우드 사용목적을 입력해 주세요'
                                            }}
                                            />}
                                        />
                                        </div>
                                    </div>
                                </>
                            }
                            {(vm.data.useAWSYn === false && vm.data.cloudServiceUseType === '서버를_구입하여_직접_운영하고_있어요') &&
                                <div className={st.question}>
                                    <div className={st.title}>{count++}. <span className={st.bold}>서버 사용 목적</span>을 알려주세요.</div>
                                    <div className={st.answer}>
                                    <InputText style={{width:"460px"}} placeholder="홈페이지 운영, 앱 운영" name='purpose' value={vm.data.purpose} onChange={(e) => vm.purpose(e)} 
                                        errorText={<Error name={`purpose`} value={vm.data.purpose} errorCase={{
                                            required: '서버 사용 목적을 입력해 주세요.'
                                        }}
                                        />}
                                    />
                                    </div>
                                </div>
                            }
                            {(vm.data.useAWSYn === true && vm.data.MGCUseYn === false) &&
                                <>
                                    <div className={st.question}>
                                        <div className={st.title}>{count++}. <span className={st.bold}>AWS 월 비용</span>을 알려주세요.</div>
                                        <div className={st.info}>월비용을 절약하기 위한 컨설팅을 제공해드려요.</div>
                                        <div className={st.answer}>
                                        <InputText style={{width:"460px"}} placeholder="약 월 300만원" name='monthCost' value={vm.data.monthCost} onChange={(e) => vm.monthCost(e)} 
                                            errorText={<Error name={`monthCost`} value={vm.data.monthCost} errorCase={{
                                                required: 'AWS 월 비용을 입력해 주세요.'
                                            }}
                                            />}
                                        />
                                        </div>
                                    </div>
                                    <div className={st.question}>
                                        <div className={st.title}>{count++}. <span className={st.bold}>AWS 사용 목적</span>을 알려주세요.</div>
                                        <div className={st.answer}>
                                        <InputText style={{width:"460px"}} placeholder="홈페이지 운영, 앱 운영" name='purpose' value={vm.data.purpose} onChange={(e) => vm.purpose(e)} 
                                            errorText={<Error name={`purpose`} value={vm.data.purpose} errorCase={{
                                                required: 'AWS 사용목적을 입력해 주세요'
                                            }}
                                            />}
                                        />
                                        </div>
                                    </div>
                                </>
                            }
                            {!(vm.data.useAWSYn === true && vm.data.MGCUseYn === true) &&
                                <>
                                    <div className={st.question}>
                                        <div className={st.title}>{count++}. <span className={st.bold}>클라우드 지원금</span>을 받을 <span className={st.bold}>연락처</span>를 기재하세요.</div>
                                        {/* <div className={st.info}>AWS 담당 개발자가 있다면, 그 연락처를 기재하면 더 편리해요.</div> */}
                                        <div className={st.answer}>
                                            <div className={st.inputRow}>
                                                <InputText explain='이름' style={{width:"460px"}} placeholder="홍길동" name='name' value={vm.data.name} onChange={(e) => vm.name(e)} 
                                                    errorText={<Error name={`name`} value={vm.data.name} errorCase={{
                                                        required: '이름을 입력해 주세요'
                                                    }}
                                                    />}
                                                />
                                            </div>
                                            <div className={st.inputRow}>
                                                <InputText explain='이메일' style={{width:"460px"}} placeholder="example@standby.kr" name='email' value={vm.data.email} onChange={(e) => vm.email(e)} 
                                                    errorText={<Error name={`email`} value={vm.data.email} errorCase={{
                                                        required: '이메일을 입력해 주세요',
                                                        pattern: {
                                                            value: regExp.email().test(vm.data.email),
                                                            message: '올바른 이메일 형식으로 입력해 주세요.'
                                                        }
                                                    }}
                                                    />}
                                                />
                                            </div>
                                            <div className={st.inputRow}>
                                                <InputText explain='전화번호' style={{width:"460px"}} placeholder="01012345678" name='phoneNumber' value={vm.data.phoneNumber} onChange={(e) => vm.phoneNumber(e)} 
                                                    errorText={<Error name={`phoneNumber`} value={vm.data.phoneNumber} errorCase={{
                                                        required: '전화번호를 입력해 주세요.'
                                                    }}
                                                    />}
                                                />
                                            </div>
                                            <div className={st.inputRow}>
                                                <InputText explain='담당직무' style={{width:"460px"}} placeholder="대표" name='responsibility' value={vm.data.responsibility} onChange={(e) => vm.responsibility(e)} 
                                                    errorText={<Error name={`responsibility`} value={vm.data.responsibility} errorCase={{
                                                        required: '담당직무를 입력해 주세요.'
                                                    }}
                                                    />}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={st.question}>
                                        <CheckBox checked={vm.agreeAll} onChange={(e) => vm.setAgreeAll(e)} fontSize="15px" size="large">모두 동의합니다.</CheckBox>
                                        <ul className={st.termsList}>
                                            {vm.data.termInfos.map((item, idx) => {
                                                return (
                                                    <li key={idx}>
                                                        <input type="hidden" name={`awsApplicationTerms[${idx}].id`} value={item.id}/>
                                                        <CheckBox name={`awsApplicationTerms[${idx}].agreeYn`} value={String(item.agreeYn)} checked={item.agreeYn} onChange={(e) => vm.setAgreeYn(e, idx)} className={st.check} checkIconClassName={st.checkIcon} textClassName={st.radioText}>
                                                            <span className={st.notRequired}>{item.required === true && '(필수) '}</span>{item.title}
                                                        </CheckBox>
                                                        {item.content && 
                                                            <TextBtn className={st.textBtn} onClick={() => vm.termsPopup(item.title, <div className={clsx(st.agreeContent, "terms")} dangerouslySetInnerHTML={{__html: item.content}}></div>)}>상세보기</TextBtn>
                                                        }
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                        {(vm.state.errorCheckYn && vm.checkBoxError) && 
                                            <ErrorText className={st.errorText}>필수 약관에 동의해주세요.</ErrorText>
                                        }
                                    </div>
                                </>
                            }
                            <SquareBtn type="submit" className={st.submitBtn}>클라우드 지원금 신청하기 </SquareBtn>
                        </form>
                    </div>
                </div>
            </div>
        </>
    ));
}
export default Step2;