import { useObserver } from 'mobx-react';
import type { I서류자동작성 } from '@model/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalModel.interface';
import bst from '@pages/Corp/Agenda/Step/FinalCheck/BeforeFinal/BeforeFinal.module.scss';
import st from './AutoDocument.module.scss';
import clsx from 'clsx';
import vm from './AutoDocumentVm';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import FileBtn from '@standby/common-ui/components/atoms/Button/FileBtn/FileBtn'

interface AutoDocumentProps {
    index: number;
    info: I서류자동작성;
    documentSubmitYn: boolean;
}

function AutoDocument(props: AutoDocumentProps) {
    return useObserver(() => (
        <div className={bst.row} id="autoDocument">
            <div className={clsx(bst.title, bst.section4)}>{props.index+1}. 서류 자동작성</div>
            <div className={bst.content}>
                <div className={bst.contentTitleBox}>자동작성된 의사록을 다운 받으세요.</div>
                <div className={st.documentContent}>
                    {props.info.documents.map((document, idx) => {
                        return (
                            <div key={idx} className={st.documentBox}>
                                <div className={st.title}>{idx+1}. {document.title}</div>
                                <div className={st.date}>
                                    {document.date}
                                    {document.isActiveButton && <button type="button" className={st.changeBtn} onClick={() => vm.openModal(document.title, document.id)}>{vm.changeBtnText(document.title)}</button>}
                                </div>

                                <div className={st.documentImgBox}>            
                                    {document.downloadUrl ? 
                                    <>
                                        <div className={st.thumbnailFile}>
                                            <img src={document.thumbnailUrl} className={st.img}/>
                                        </div>       
                                        <FileBtn className={st.downloadBtn} btnType="important" onClick={() => vm.fileDownload(document.downloadUrl)} style={{fontWeight:'700'}}><span>{vm.btnText(document.downloadUrl, document.title)}</span></FileBtn>
                                    </>
                                    :
                                    <>
                                        <div className={st.exampleFile}>
                                            <img src={document.thumbnailUrl} className={st.exampleImg}/>
                                        </div>                                        
                                        <SquareBtn className={st.chooseBtn} light arrow onClick={() => vm.openModal(document.title, document.id)}><span>{vm.btnText(document.downloadUrl, document.title)}</span></SquareBtn>
                                    </>
                                    }
                                </div>
                                {(props.documentSubmitYn && !document.downloadUrl) && <div className={st.error}>{vm.text(document.title)}를 선택하세요.</div>}
                            </div>
                            
                        )
                    })}
                </div>
            </div>
        </div>
    ));
}
export default AutoDocument;