import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { useEffect } from 'react';
import vm from './DelegationPopupVm';
import st from './DelegationPopup.module.scss';
import Check from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';

function DelegationPopup() {
    return useObserver(() => (
        <div className={st.delegationPopup}>
            <div className={st.advertisement}>
                <div className={st.title}>진평회계법인</div>
                <div className={st.jinpyeongBox}>
                    <img className={st.img} src='/images/진평_example.png' />        
                    <div className={st.jinpyeong}>
                        <div className={st.jinpyeongTitle}>서울대 경영대 출신의 회계사들이 만든 <span className={st.bold}>스타트업 전문 회계법인</span></div>
                        <div className={st.jinpyeongInfo}><span className={st.boldColor}>서울대 경영대</span> 출신 경력 14년 회계사가 직접 관리</div>
                        <div className={st.jinpyeongInfo}>절세를 고려한 <span className={st.boldColor}>최적의 비용처리 및 세액감면/공제 적용</span></div>
                        <div className={st.jinpyeongInfo}><span className={st.boldColor}>투자유치, 상장</span>을 대비한 지분구조 및 회계처리</div>
                        <img src='/images/logo_진평회계법인.svg' className={st.jinpyeongLogoImg}/>
                    </div>    
                </div>
            </div>
            <div className={st.agreeInfo}>
                <div className={st.question}>
                    진평회계법인에서 기장계약 이용등록 요청을 했어요.<br/>
                    등록을 하면, 앞으로 기장서비스는 스탠바이 시스템을 통해 제공돼요.
                </div>
                <Check className={st.checkBox} name="agreeYn" value={vm.state.agreeYn} onChange={(e) => vm.setAgreeYn(e)}>진평회계법인에 대한  <button type="button" className={st.agreeModal} onClick={vm.agreeModal}>정보제공 동의</button></Check>
            </div>
            <div className={st.btnArea}>
                <SquareBtn className={st.registerBtn} onClick={() => vm.register()}>등록 진행하기</SquareBtn>
            </div>
        </div>
    ));
}
export default DelegationPopup;

