import { get } from '@common/module/httpRequest';
import corpModel from '@model/Corp/corpModel';
import portalModel from '@model/portalModel';
import type { PopupRq } from './popupService.interface';
import { runInAction } from 'mobx';
import AgendaPromotionPopup from '@pages/Corp/Popup/AgendaPromotionPopup/AgendaPromotionPopup';

class popupService {
    async loadPopup() {
        const res = await await get<PopupRq>(`/company/${corpModel.id}/popup`, {}, {});
        const data = res.data;
        runInAction(() => {
            if(data.agendaPopupType === "INIT" || data.agendaPopupType === "EXISTING") {
                portalModel.title = '';
                portalModel.content = <AgendaPromotionPopup agendaPopupType={data.agendaPopupType} corpId={corpModel.id}/>;
                portalModel.closeBtn = false;
            } else {
                return;
            }
        })
    }
}

export default new popupService();