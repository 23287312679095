import { runInAction, observable, toJS } from "mobx";
import businessRegistrationModel from '@model/Corp/BusinessRegistration/businessRegistrationModel';
import businessRegistrationService from '@service/Corp/BusinessRegistration/businessRegistrationService';
import corpModel from '@model/Corp/corpModel';

class BeforeUploadFileVm {
    constructor() {
        this.state = observable({
            loadingYn: false
        })
    }
    get corpId() {
        return corpModel.id;
    }
    get guides() {
        return businessRegistrationModel.guides;
    }
    async fileUpload(e) {
        try {
            this.state.loadingYn = true;
            await businessRegistrationService.fileUpload(e.target.files[0]);
            this.state.loadingYn = false;
            window.location.reload();
        } catch(err) {
            this.state.loadingYn = false;
        }
    }
}

export default new BeforeUploadFileVm();