import { runInAction } from 'mobx';
import {pageRouter} from '@model/pageModel';
import Cookies from 'js-cookie';
import userModel from '@model/userModel';
import userDashboardService from '@service/userDashboardService';
import corpService from '@service/Corp/corpService';
import corpModel from '@model/Corp/corpModel';
import notificationModel from '@model/notificationModel';
import SockJS from 'sockjs-client';
import {Stomp} from '@stomp/stompjs';

class socketService {
    constructor() {
        this.ws = null;
    }
    async load(channelList) {
        const baseURL = process.env.REACT_APP_API_URL || 'empty';
        this.ws =  Stomp.over(new SockJS(`${baseURL}ws`));
        const ws = this.ws;
        ws.connect({}, function(frame){
            channelList.forEach((channel) => {
                switch(channel.type) {
                    //여기서 웹소켓 데이터 처리해야함
                    case "companies" :
                        ws.subscribe(channel.channelId, function(message) {
                            const pathName = pageRouter.location.pathname || pageRouter.location?.location?.pathname;
                            if(pathName.split('/')[1] && pathName.split('/')[1] === "corp") { //법인화면에있을때
                                corpService.loadFrame(corpModel.id);
                            }
                            if(pathName === "/userDashboard") { //유저대시보드에 들어와있을때 회사변경되면 유저대시보드 api새로불러옴
                                if(JSON.parse(message.body)?.id) {
                                    userDashboardService.load();
                                }
                            }
                        });
                    break;
                    case "notification" :
                        notificationModel.setNotifications(channel.data);
                        ws.subscribe(channel.channelId, function(message) {
                            console.log(JSON.parse(message.body))
                            notificationModel.setNotifications(JSON.parse(message.body)?.notifications);
                        });
                    break;
                }
            })
        })
    }
    async disConnect() {
        if(this.ws) {
            this.ws.disconnect();
        }
    }
}
export default new socketService();
