import { useObserver } from 'mobx-react';
import st from './Meetings.module.scss';
import vm from './MeetingsVm';
import { Fragment } from 'react';
import type { 
    I주주총회_개최_이사회 , I주주총회_개최_대표결정 , I소집통지_온라인 , I소집통지_우편 , I주주총회 , 
    I이사회 , I주주전원의_서면결의 , I대표결정 , I신주발행통지 , I신주공고통지 
} from '@model/Corp/Agenda/Step/FinalCheck/Final/FinalModel.interface';
import C주주총회_개최_이사회 from '../MeetingSchedule/C주주총회_개최_이사회/C주주총회_개최_이사회';
import C주주총회_개최_대표결정 from '../MeetingSchedule/C주주총회_개최_대표결정/C주주총회_개최_대표결정';
import C소집통지_온라인 from '../MeetingSchedule/C소집통지_온라인/C소집통지_온라인';
import C소집통지_우편 from '../MeetingSchedule/C소집통지_우편/C소집통지_우편';
import C주주총회 from '../MeetingSchedule/C주주총회/C주주총회';
import C이사회 from '../MeetingSchedule/C이사회/C이사회';
import C주주전원의_서면결의 from '../MeetingSchedule/C주주전원의_서면결의/C주주전원의_서면결의';
import C대표결정 from '../MeetingSchedule/C대표결정/C대표결정';
import C신주발행통지 from '../MeetingSchedule/C신주발행통지/C신주발행통지';
import C신주공고통지 from '../MeetingSchedule/C신주공고통지/C신주공고통지';

interface Props {
    opener : 'final' | 'agendaDashboard'
}

function Meetings(props : Props) {
    return useObserver(() => (
        <div className={st.meetings}>
            {vm.meetingSchedule.map((meetingSchedule, index) => (
                <Fragment key={index}>
                    {meetingSchedule.type === "주주총회_개최_이사회" &&
                        <C주주총회_개최_이사회 meetingSchedule={meetingSchedule as I주주총회_개최_이사회} meetingScheduleIndex={index} opener={props.opener}/>
                    }
                    {meetingSchedule.type === "주주총회_개최_대표결정" &&
                        <C주주총회_개최_대표결정 meetingSchedule={meetingSchedule as I주주총회_개최_대표결정} meetingScheduleIndex={index} opener={props.opener}/>
                    }
                    {meetingSchedule.type === "소집통지_온라인" &&
                        <C소집통지_온라인 meetingSchedule={meetingSchedule as I소집통지_온라인} meetingScheduleIndex={index} opener={props.opener}/>
                    }
                    {meetingSchedule.type === "소집통지_우편" &&
                        <C소집통지_우편 meetingSchedule={meetingSchedule as I소집통지_우편} meetingScheduleIndex={index} opener={props.opener}/>
                    }
                    {meetingSchedule.type === "주주총회" &&
                        <C주주총회 meetingSchedule={meetingSchedule as I주주총회} meetingScheduleIndex={index} opener={props.opener}/>
                    }
                    {meetingSchedule.type === "이사회" &&
                        <C이사회 meetingSchedule={meetingSchedule as I이사회} meetingScheduleIndex={index} opener={props.opener}/>
                    }
                    {meetingSchedule.type === "주주전원의_서면결의" &&
                        <C주주전원의_서면결의 meetingSchedule={meetingSchedule as I주주전원의_서면결의} meetingScheduleIndex={index} opener={props.opener}/>
                    }
                    {meetingSchedule.type === "대표결정" &&
                        <C대표결정 meetingSchedule={meetingSchedule as I대표결정} meetingScheduleIndex={index} opener={props.opener}/>
                    }
                    {meetingSchedule.type === "신주발행통지" &&
                        <C신주발행통지 meetingSchedule={meetingSchedule as I신주발행통지} meetingScheduleIndex={index} opener={props.opener}/>
                    }
                    {meetingSchedule.type === "신주공고통지" &&
                        <C신주공고통지 meetingSchedule={meetingSchedule as I신주공고통지} meetingScheduleIndex={index} opener={props.opener}/>
                    } 
                </Fragment>
            ))}
        </div>
    ));
}
export default Meetings;