import type {CorpModelInterface, Company, Companies, CompanyRegisterStatus, Menu} from './corpModel.interface'

import { makeObservable, observable } from 'mobx';

class corpModel implements CorpModelInterface {
    constructor() {
        makeObservable(this, {
            id : observable,
            company : observable,
            companies : observable,
            companyRegisterStatus : observable,
            stockholderListFileRegisterStatus : observable,
            menu : observable,
            alliancePopupShowYn : observable
        });
    }
    id : number | null = null;
    company : Company = {
        companyName : "",
        companyAddress : "",
        corpType: "",
        prefixedYn: null
    };
    companies : Companies[] = [];
    companyRegisterStatus : CompanyRegisterStatus = null;
    stockholderListFileRegisterStatus : boolean = false;
    menu : Menu ={
        businessRegistration: {
            useYn: null,
            tagName: ""
        },
        articlesOfAssociationTagName: "",
        bookkeepingContractTagName: "",
        copyFileTagName: "",
        stockholderTagName: "",
        corporateTaxTagName: "",
        valueAddedTaxTagName: "",
        dashboardTagName: "",
        agendaTagName: "",
        internetAssociateTagName: ""
    };
    alliancePopupShowYn : boolean = false;
}

export default new corpModel();