import { useObserver } from 'mobx-react';
import st from './ChangeMeetingSchedule.module.scss';
import vm from './ChangeMeetingScheduleVm';
import DateList from '@pages/Corp/Agenda/Step/Meetings/Tab/TabComponent/DateList/DateList';
import { useEffect } from 'react';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';

interface ChangeMeetingScheduleProps {
    agendaProgressId: string;
}

function ChangeMeetingSchedule(props: ChangeMeetingScheduleProps) {

    useEffect(() => {
        vm.load(props.agendaProgressId);
    }, []);

    return useObserver(() => (
        <form className={st.modalContent} onSubmit={(e) => vm.submit(e, props.agendaProgressId)}>
            <DateList name="schedules" />
            <div className={st.btnArea}>
                <SquareBtn className={st.submitBtn} type="submit">변경 내용 저장하기</SquareBtn>
            </div>
        </form>
    ));
}
export default ChangeMeetingSchedule;