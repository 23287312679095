import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { useEffect } from 'react';
import st from './ElectronicSignaturePopup.module.scss';
import vm from './ElectronicSignaturePopupVm';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import Loading from '@commonComponents/Loading/Loading';

function ElectronicSignaturePopup(props) {

    useEffect(() => {
        vm.load();
    }, []);

    return useObserver(() => (
        <div className={st.electronicSignaturePopup}>
            {vm.state.loadingYn && <Loading />}
            <div className={st.documentBox}>
                <div className={st.content}>
                    <iframe title="기장대리 및 회계자문 계약서" className={st.document} src={vm.bookkeepingContractLink}/>
                </div>
            </div>
            <div className={st.btnArea}>
                <SquareBtn className={st.agreeBtn} onClick={() => vm.requestAgree()}>위 내용에 모두 동의합니다.</SquareBtn>
                <div className={st.btnExplain}>버튼을 누르면 회사도장이 날인되어요!</div>
            </div>
            
        </div>
    ));

}
export default ElectronicSignaturePopup;