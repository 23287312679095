import { runInAction, observable, toJS } from "mobx";
import registerService from '@service/Corp/Register/registerService';
import registerModel from '@model/Corp/Register/registerModel';
import corpModel from '@model/Corp/corpModel';

class RegisterVm {
    load() {
        registerService.load();
    }
    get companyCopyList() {
        return registerModel.companyCopyList;
    }
    downLoadUrl(url) {
        registerService.downLoadUrl(url);
    }
    dateFormat(timestamp) {
        const _date = new Date(timestamp);

        const year = _date.getFullYear();
        const month = ('0' + (_date.getMonth() + 1)).slice(-2);
        const day = ('0' + _date.getDate()).slice(-2);

        return year + '-' + month  + '-' + day;
    }
    get corpId() {
        return corpModel.id;
    }  
    get guides() {
        return registerModel.guides;
    } 
}
export default new RegisterVm();