import { useObserver } from 'mobx-react';
import st from './SecurityContent.module.scss';

interface SecurityContentProps {
    children: React.ReactNode;
}

function SecurityContent(props: SecurityContentProps) {
    return useObserver(() => (
        <>
            <div className={st.securityContent}>
                <div className={st.securityContentBox}>
                    <div className={st.logo}>
                        <img src='/images/logo_standby.svg?1'/>
                    </div>
                    <div className={st.content}>
                        {props.children}
                    </div>
                </div>
            </div>
        </>
    ));
}
export default SecurityContent;