import { useObserver } from 'mobx-react';
import { useEffect } from 'react';
import vm from './DirectVm';
import st from '../../OnlineAcceptancePopup.module.scss';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import ErrorText from '@standby/common-ui/components/atoms/Text/ErrorText/ErrorText';
import { addCommas } from '@common/module/replaceNumber';

interface DirectProps {
    agendaProgressId: string;
    page?: string;
}

function Direct(props: DirectProps) {
    useEffect(() => {
        vm.load(props.agendaProgressId, props.page)
    }, [])
    return useObserver(() => (
        <form className={st.direct} onSubmit={(e) => vm.submit(e, props.agendaProgressId, props.page)}>
            <div className={st.title}>이미 동의를 받았다면, 동의여부를 직접 입력하세요.</div>
            <table className={st.table}>
                <colgroup>
                    <col width="103" />
                    <col width="267" />
                    <col width="97" />
                    <col width="97" />
                    <col width="162" />
                    <col width="96" />
                    <col />
                </colgroup>
                <thead>
                    <tr>
                        <th>
                            <CheckBox className={st.agreeAll} checked={vm.agreeAll} onChange={(e) => vm.setAgreeAll(e)}>동의완료</CheckBox>
                        </th>
                        <th>주주명</th>
                        <th>동의여부</th>
                        <th>동의일자</th>
                        <th>주식종류</th>
                        <th>주식수</th>
                        <th>지분율</th>
                    </tr>
                </thead>
                {vm.shareholders.map((shareholder, idx) => (
                    <tbody key={idx}>
                        {shareholder.stocks.map((stock, idx2) => (
                            <tr key={idx2}>
                                {idx2 === 0 && 
                                <>
                                    <td rowSpan={shareholder.stocks.length} className={st.checkbox}>
                                        <CheckBox disabled={shareholder.status === "동의완료"} value={shareholder.id} checked={vm.agree(shareholder.id)} onChange={(e) => vm.setAgree(e)}/>
                                    </td>
                                    <td rowSpan={shareholder.stocks.length}><span className={st.index}>{idx+1}</span>{shareholder.name}</td>
                                    <td rowSpan={shareholder.stocks.length}><span data-status={shareholder.status}>{vm.status(shareholder.status)}</span></td>
                                    <td rowSpan={shareholder.stocks.length}>{shareholder.acceptDate}</td>
                                </>
                                }
                                <td className={st.stockName}>{stock.name}</td>
                                <td className={st.stockNumber}>{addCommas(stock.number)} 주</td>
                                <td className={st.stockRate}>{stock.rate} %</td>
                            </tr>
                        ))}
                    </tbody>
                ))}
            </table>
            {vm.state.errorYn && 
                <div>
                    <ErrorText>동의완료한 주주를 선택하세요.</ErrorText>
                </div>
            }
            <div className={st.btnArea}>
                <SquareBtn type="submit" className={st.submitBtn}>저장하기</SquareBtn>
            </div>
        </form>
    ));
}
export default Direct;