import { observable, runInAction } from 'mobx';
import beforeFinalModel from "@src/model/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalModel";
import beforeFinalService from "@service/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalService";



interface State {
    loadingYn: boolean;
}

interface UploadFile {
    id: number;
    documentType: string;
    name: string;
    required: boolean;
    file?: {
        id: number;
        name: string;
        downloadUrl: string;
    };
    files?: {
        id: number;
        name: string;
        downloadUrl: string;
    }[];
}

class FileUploadVm {
    state: State = observable({
        loadingYn: false
    })
    get registrationAgencyType() {
        return beforeFinalModel.registrationAgencyType;
    }
    async fileUpload(e:React.ChangeEvent<HTMLInputElement>, agendaProgressId: string, uploadFileId: number, documentType: string, uploadFile: UploadFile) {
        if(e.target.files) {
            try {
                this.state.loadingYn = true;
                const data = await beforeFinalService.fileUpload(e.target.files, agendaProgressId, uploadFileId, documentType);
                runInAction(() => {
                    uploadFile.id = data.id;
                    uploadFile.documentType = data.documentType;
                    if(data.documentType === '등기부' || data.documentType === '주주명부' || data.documentType === '정관' ) {
                        uploadFile.file = data.file;
                    } else {
                        if(data.file) {
                            if(uploadFile.files) {
                                uploadFile.files.push(data.file);
                            }
                        }
                        if(data.files) {
                            uploadFile.files = uploadFile.files?.concat(data.files);
                        }
                    }
                })
                this.state.loadingYn = false;
            } catch(err) {
                this.state.loadingYn = false;
            }
        }
    }
    async fileDelete(agendaprogressId: string, fileListId: number, uploadFileId: number, documentType: string) {
        try {
            this.state.loadingYn = true;
            await beforeFinalService.fileDelete(agendaprogressId, fileListId, uploadFileId, documentType);
            this.state.loadingYn = false;
        } catch (err) {
            this.state.loadingYn = false;
        }
    }
    multipleYn(documentType: string) {
        if(documentType === '등기부' || documentType === '주주명부' || documentType === '정관') {
            return false;
        } else {
            return true;
        }
    }
    fileDownload(downloadUrl: string) {
        beforeFinalService.fileDownload(downloadUrl);
    }
}

export default new FileUploadVm();