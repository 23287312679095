
import { get, put, post } from '@common/module/httpRequest';
import { runInAction } from 'mobx';
import corpModel from '@model/Corp/corpModel';
import ChangeRegistrationAgencyModel from '@src/model/Corp/Agenda/Step/FinalCheck/BeforeFinal/ChangeRegistrationAgency/ChangeRegistrationAgencyModel';

import type { LoadRs, RegistrationAgencyParam, TermsRq } from './ChangeRegistrationAgencyService.interface';

class ChangeRegistrationAgencyService {
    async load(agendaProgressId: string) {
        const res = await put<LoadRs>(`/company/${corpModel.id}/agendaProgress/${agendaProgressId}/beforeFinal/registrationAgency`);
        console.log(res.data);
        runInAction(() => {
            ChangeRegistrationAgencyModel.jointCertificate = res.data?.jointCertificate;
            ChangeRegistrationAgencyModel.digitalCertificate = {
                company: {
                    useType: res.data?.digitalCertificate?.company?.useType || null,
                },
                companyShareholderList: (res.data?.digitalCertificate?.companyShareholderList && res.data?.digitalCertificate?.companyShareholderList.map((shareholder) => {
                    return {
                        id: shareholder.id || -1,
                        name: shareholder.name || "",
                        useType: shareholder.useType || null
                    }
                })) || null
            }
            ChangeRegistrationAgencyModel.personInCharge = {
                name: res.data?.personInCharge?.name || "",
                phoneNum: res.data?.personInCharge?.phoneNum || "",
                email: res.data?.personInCharge?.email || "",
            }
        })
    }
    async registrationAgency(agendaProgressId: string, param: RegistrationAgencyParam) {
        await post(`/company/${corpModel.id}/agendaProgress/${agendaProgressId}/beforeFinal/registrationAgency`, param);
    }
    async loadTerms() {
        const res = await await get<TermsRq>(`/term/NORMAL_REGISTER`, {}, {});
        return res.data;
    }
}

export default new ChangeRegistrationAgencyService();