import { useObserver } from 'mobx-react';
import st from './BesinessRegistrationDownloadSelectModal.module.scss';
import vm from './BesinessRegistrationDownloadSelectModalVm';
import ContentBtn from '@standby/common-ui/components/atoms/Button/ContentBtn/ContentBtn';
import FileBtn from '@standby/common-ui/components/atoms/Button/FileBtn/FileBtn';
import clsx from 'clsx';

function BesinessRegistrationDownloadSelectModal() {
    return useObserver(() => (
        <div className={st.modalContent}>
            <ul className={st.downloadList}>
                <li>
                    <img className={st.preview} src='/images/besinessRegistrationPreivew_pdf.png'></img>
                    <div className={st.content}>
                        <div className={st.title}>사업자등록증.pdf</div>
                        <FileBtn className={st.btn} btnType="important" onClick={() => vm.fileTypeDown('PDF')}>PDF 다운로드</FileBtn>
                    </div>
                </li>
                <li>
                    <img className={st.preview} src='/images/besinessRegistrationPreivew_jpg.png'></img>
                    <div className={st.content}>
                        <div className={st.title}>사업자등록증.jpg</div>
                        <FileBtn className={st.btn} btnType="important" onClick={() => vm.fileTypeDown('JPG')}>JPG 다운로드</FileBtn>
                    </div>
                </li>
                <li>
                    <img className={st.preview} src='/images/besinessRegistrationPreivew_sign.png'></img>
                    <div className={st.content}>
                        <div className={st.title}>이메일 등 정보추가 후 다운로드</div>
                        <ContentBtn className={clsx(st.btn, st.arrowBtn)} color="important" onClick={() => vm.openSignModal()}>정보 입력하기</ContentBtn>
                    </div>
                </li>
            </ul>
        </div>
    ));
}
export default BesinessRegistrationDownloadSelectModal;