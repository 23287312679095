import { makeObservable, observable } from 'mobx';
import type {InternetAssociateModelInterface, Step2, Step3} from './InternetAssociateModel.interface';

class InternetAssociateModel implements InternetAssociateModelInterface {
    constructor() {
        makeObservable(this, {
            step2 : observable,
            step3 : observable
        })
    }
    step2: Step2 = {
        products : {
            INTERNET : [] ,
            TELEPHONE : [],
            TELEPHONE_NUMBER : [],
            TV : []
        },
        selectedProducts : [],
        selectedProductsDetail : {
            INTERNET : '',
            TELEPHONE : {
                id : '',
                quantity : 1
            },
            TELEPHONE_NUMBER : [],
            TV : ''
        },
        consultInfo : {
            name : '',
            phoneNumber : '',
            address : '',
            content : '',
            email : ''
        },
        estimate : {
            baseFee : 0,
            bundleDiscount : 0,
            cashback : 0
        },
        termInfos : []
    }
    step3 : Step3 = {
        status : '',
        date : '',
        products : []
    }

}

export default new InternetAssociateModel();