import agendaStepModel from '@model/Corp/Agenda/Step/agendaStepModel';
import { runInAction} from "mobx";
import { removeCommas, onlyNumber } from '@common/module/replaceNumber';
import type {SelectedAgenda, I임원_보수_한도_승인} from '@model/Corp/Agenda/Step/agendaStepModel.interface';

class ApprovalofExecutiveRemunerationLimitVm {
    selectedAgenda(index : number) {
        return agendaStepModel.selectedAgendas[index] as SelectedAgenda<I임원_보수_한도_승인>
    }
    approvalType(e : React.ChangeEvent<HTMLInputElement>, index : number) {
        this.selectedAgenda(index).agendaDetail.approvalType = e.target.value;
    }
    이사SalaryLimit(e : React.ChangeEvent<HTMLInputElement>, index : number) {
        this.selectedAgenda(index).agendaDetail.이사SalaryLimit = removeCommas((onlyNumber(e.target.value)).toString());
    }
    감사SalaryLimit(e : React.ChangeEvent<HTMLInputElement>, index : number) {
        this.selectedAgenda(index).agendaDetail.감사SalaryLimit = removeCommas((onlyNumber(e.target.value)).toString());
    }
    salary(e : React.ChangeEvent<HTMLInputElement>, index : number, index2 : number) {
        this.selectedAgenda(index).agendaDetail.salaryLimitOfExecutives[index2].salary = removeCommas((onlyNumber(e.target.value)).toString());
    }
    approvalStart(index : number) {
        const year = this.selectedAgenda(index).agendaDetail.approvalStartAt.year;
        const month = this.selectedAgenda(index).agendaDetail.approvalStartAt.month;
        const day = this.selectedAgenda(index).agendaDetail.approvalStartAt.day;

        let approvalStart = null;
        if(year && month && day) {
            approvalStart = year + "-" + month + "-" + day;
        }

        return approvalStart;
    }
    setApprovalStart(date : Date, index : number) {
        let year:string ; let month:string ; let day:string;
        if(date) {
            year = (date.getFullYear()).toString();
            month = ('0' + (date.getMonth() + 1)).slice(-2);
            day = ('0' + date.getDate()).slice(-2);
        }

        runInAction(() => {
            this.selectedAgenda(index).agendaDetail.approvalStartAt.year = year;
            this.selectedAgenda(index).agendaDetail.approvalStartAt.month = month;
            this.selectedAgenda(index).agendaDetail.approvalStartAt.day = day;
        })
    }
    approvalEnd(index : number) {
        const year = this.selectedAgenda(index).agendaDetail.approvalEndAt.year;
        const month = this.selectedAgenda(index).agendaDetail.approvalEndAt.month;
        const day = this.selectedAgenda(index).agendaDetail.approvalEndAt.day;

        let approvalEnd = null;
        if(year && month && day) {
            approvalEnd = year + "-" + month + "-" + day;
        }

        return approvalEnd;
    }
    setApprovalEnd(date : Date, index : number) {
        let year:string ; let month:string ; let day:string;
        if(date) {
            year = (date.getFullYear()).toString();
            month = ('0' + (date.getMonth() + 1)).slice(-2);
            day = ('0' + date.getDate()).slice(-2);
        }
        
        runInAction(() => {
            this.selectedAgenda(index).agendaDetail.approvalEndAt.year = year;
            this.selectedAgenda(index).agendaDetail.approvalEndAt.month = month;
            this.selectedAgenda(index).agendaDetail.approvalEndAt.day = day;
        })
    }
    download() {
        window.location.href = `${process.env.REACT_APP_S3URL}/agenda/%5B%E1%84%89%E1%85%B3%E1%84%90%E1%85%A2%E1%86%AB%E1%84%87%E1%85%A1%E1%84%8B%E1%85%B5%5D+%E1%84%87%E1%85%A9%E1%84%89%E1%85%AE%2C+%E1%84%89%E1%85%A1%E1%86%BC%E1%84%8B%E1%85%A7%E1%84%80%E1%85%B3%E1%86%B7%2C+%E1%84%90%E1%85%AC%E1%84%8C%E1%85%B5%E1%86%A8%E1%84%80%E1%85%B3%E1%86%B7+%E1%84%80%E1%85%B2%E1%84%8C%E1%85%A5%E1%86%BC+3%E1%84%8C%E1%85%A9%E1%86%BC+%E1%84%89%E1%85%A2%E1%86%B7%E1%84%91%E1%85%B3%E1%86%AF.zip`;
    }
}
export default new ApprovalofExecutiveRemunerationLimitVm();