import { useObserver } from 'mobx-react';
import { useLocation } from "react-router-dom";
import { useEffect } from 'react';
import MeetingsVm from './MeetingsVm';
import queryString from 'query-string';
import MeetingsQuestion from '../AgendaQuestion/AgendaQuestion';
import Tab from './Tab/Tab';
import st from './Meetings.module.scss';
import PageTitle from '@commonComponents/PageTitle/PageTitle';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';

function Meetings() {
    let location = useLocation();
    const agendaProgressId = String(queryString.parse(location.search).agendaProgressId);

    useEffect(() => {
        MeetingsVm.load(agendaProgressId);
    }, []);

    return useObserver(() => (
        <>
            <PageTitle><span className={st.pageTitle}>{MeetingsVm.title}</span></PageTitle>
            {MeetingsVm.tabList.length !== 0 && 
                <>
                    {MeetingsVm.tabList.map((tab, idx) => (
                        <div key={idx}>
                            {
                                tab.savedDataYn === true || MeetingsVm.state.opened === idx ? 
                                    <MeetingsQuestion pageType={tab.tabType} isAllSaved={MeetingsVm.isAllSaved} tab={tab} agendaIndex={idx} opened={MeetingsVm.state.opened === idx} changeOpened={() => MeetingsVm.changeOpened(idx)} openFirst={() => MeetingsVm.openFirst()}  step="MEETINGS" inputComponent={<Tab index={idx} />} agendaProgressId={String(agendaProgressId)} />
                                : <></>
                            }
                        </div>
                    ))}
                </>
            }
            {(MeetingsVm.tabList.length !== 0 && MeetingsVm.isAllSaved === false) && 
                <div className={st.btnArea}>
                    <button type="button" className={st.prevPage} onClick={() => MeetingsVm.prevPage(agendaProgressId)}><span>안건입력 다시하기</span></button>
                </div>
            }
            {(MeetingsVm.tabList.length !== 0 && MeetingsVm.isAllSaved) && 
            <>
                <div className={st.allSaved}>
                    <div>
                        <div className={st.text}>모두 잘 입력하셨나요?</div>
                        {/* 등기 필요한 경우 */}
                        {MeetingsVm.registrationRequiredYn === true && <img src='/images/meetingsFinal_registration.svg'/>}
                        {/* 등기 필요 없는경우 */}
                        {MeetingsVm.registrationRequiredYn === false && <img src='/images/meetingsFinal.svg'/>}
                        <div className={st.subInfo}>안건과 일자가 확정되었다면, 다음 페이지로 이동하세요.</div>
                    </div>
                    <div className={st.nextBtnArea}>
                        <SquareBtn type="button" onClick={() => MeetingsVm.nextStep(agendaProgressId)} className={st.contentSubmitBtn} arrow>다음 페이지로</SquareBtn>
                    </div>
                </div>
                <div className={st.btnArea}>
                    <button type="button" className={st.prevPage} onClick={() => MeetingsVm.prevPage(agendaProgressId)}><span>안건입력 다시하기</span></button>
                </div>
            </>
            }
        </>
    ));
}
export default Meetings;