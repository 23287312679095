import { useObserver } from 'mobx-react';
import type { I셀프등기 } from '@src/model/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalModel.interface';
import bst from '@pages/Corp/Agenda/Step/FinalCheck/BeforeFinal/BeforeFinal.module.scss';
import st from './RegistrationSelf.module.scss';
import clsx from 'clsx';
import vm from './RegistrationSelfVm';

interface RegistrationSelfProps {
    index: number;
    info: I셀프등기,
    agendaProgressId: string;
}

function RegistrationSelf(props: RegistrationSelfProps) {
    return useObserver(() => (
        <>
            <div className={bst.row}>
                <div className={clsx(bst.title, bst.section4)}>{props.index+1}. 셀프등기</div>
                <div className={bst.content}>
                    <div className={bst.contentTitleBox}>등기를 셀프로 마쳐주세요.</div>
                    <div className={st.statusBox}>
                        <div className={st.status}>
                            <img src={`/images/selfRegistrationStep_셀프등기_선택.svg`}/>
                            <div className={st.statusExplain}>셀프 등기 완료 후, 아래쪽 반영하기 버튼을 누르세요.</div>
                        </div>
                        <div className={st.agency}>
                            <div className={st.agencyTitle}>등기대행이 필요하신가요?</div>
                            <button type="button" className={st.changeRegistrationBtn} onClick={() => vm.openModal(props.agendaProgressId)}>
                                <div className={st.text}>등기대행으로 변경하기</div>
                                <div className={st.explain}>제휴 법률사무소에 맡기면 간단하게 끝!</div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    ));
}
export default RegistrationSelf;