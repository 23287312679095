import { runInAction, observable, toJS } from "mobx";
import corpModel from "@model/Corp/corpModel";
import OnGoingPopup from "./OnGoingPopup/OnGoingPopup";
import portalModel from '@model/portalModel';
import adminModel from '@model/Corp/AdminSetting/adminModel';
import { _alert, _confirm } from "@model/dialogModel";
import st from './Side.module.scss';

class SideVm {
    get id() {
        return corpModel.id;
    }
    get corp() {
        return corpModel.company;
    }
    get companies() {
        return corpModel.companies;
    }
    get corpType() {
        return corpModel.company.corpType;
    }
    authorityYn(type) {
        return adminModel?.authority?.menus?.[type]?.useYn;
    }
    activeType(link) {
        if(link === '/corp/articlesOfAssociation' || link === '/corp/register' || link === '/corp/accounting/businessRegistration') {
            return 'companyInfo';
        } else if(link.includes('corp/stock')) {
            return 'shareholder';
        } else if(link.includes('corp/accounting/bookKeepingContract') || link === '/corp/accounting/corporateTax' || link === '/corp/accounting/vat') {
            return 'tax';
        } else if(link.includes('/corp/awsCredit') || link.includes('/corp/alliance') || link.includes('/corp/internetAssociate')) {
            return 'contract'
        } else if(link === '/corp/dashboard') {
            return 'home'
        } else if(link.includes('agenda')){
            return 'agenda'
        }
        else {
            return null;
        }
    }
    get useYn() { //사업자등록 메뉴 표시 여부
        const useYn = corpModel.menu.businessRegistration.useYn;
        if(useYn) {
            return true;
        } else {
            return false;
        }
    }
    get tagName() { //사업자등록 상태 태그값
        return corpModel.menu.businessRegistration.tagName;
    }
    get menu() {
        return corpModel.menu;
    }
    onGoingClick(e, type, state) {
        const companyRegisterStatus = corpModel.companyRegisterStatus;
        if(companyRegisterStatus === 'REGISTERED' && state === '등록중') {
            switch(type) {
                case '등기부':
                    e.preventDefault();
                    runInAction(() => {
                        portalModel.content = <OnGoingPopup title="등기부 발급을 진행 중이에요." content="발급을 마치면 알림을 보내드릴게요. 발급하는데 3분 정도 걸리지만, 인터넷등기소 통신오류로 지연이 발생할 수도 있는 점 양해 부탁드려요."/>;  
                    })
                    break;
                default:
                    return;
            }
        }
    }
    authorityFalseAlert(menuName) {
        _alert(`[${menuName}]페이지는 접근 권한이 없어요.`, '', '확인', <ul className={st.alertContent}><li>권한이 필요하다면, {this.corp.companyName}의 최고관리자에게 권한을 요청하세요.</li></ul>);
    }
}
export default new SideVm();
