import { observable } from 'mobx';

const onlineMusterModel = observable({
    administrationGuides: [],
    shareholders: [],
    setAdministrationGuides(data) {
        if(data.administrationGuides && data.administrationGuides.length !== 0) {
            this.administrationGuides = data.administrationGuides.map((item, idx) => {
                return {
                    title: item.title || "",
                    content: item.content || "",
                    url: item.url || ""
                }
            })
        }
    },
    setShareholders(data) {
        if(data.shareholders && data.shareholders.length !== 0) {
            this.shareholders = data.shareholders.map((item, idx) => {
                return {
                    id: item.id || "",
                    name: item.name || "",
                    email: item.email || "",
                    phoneNumber: item.phoneNumber || "",
                    shareholderType: item.shareholderType || "",
                    stocks: item.stocks || [],
                    onlineMusterNoticeStatus: item.onlineMusterNoticeStatus || "",
                    onlineMusterNoticeUrl: item.onlineMusterNoticeUrl || "",
                }
            })
        } else {
            this.shareholders = [];
        }
    }
});

export default onlineMusterModel;