import { get, post } from '@common/module/httpRequest';
import { runInAction } from 'mobx';
import corpModel from '@model/Corp/corpModel';
import onlineAcceptancePopupModel from '@model/Corp/Popup/OnlineAcceptancePopup/onlineAcceptancePopupModel';
import type { LoadDirectRs, LoadStockDirectRs } from './onlineAcceptancePopupService.interface';

class onlineAcceptancePopupService {
    async loadDirect(agendaProgressId: string) {
        const res = await get<LoadDirectRs>(`/company/${corpModel.id}/agendaProgress/${agendaProgressId}/beforeFinal/document/onlineMeetingNoticeAgreement/shareholders`);
        runInAction(() => {
            onlineAcceptancePopupModel.direct.shareholders = res.data.shareholders || [];
        })
    }
    async directInput(agendaProgressId: string, shareholders: {shareholders: number[]}) {
        await post(`/company/${corpModel.id}/agendaProgress/${agendaProgressId}/beforeFinal/onlineMeetingNoticeAgreement/directInput`, shareholders);
    }
    async loadStockDirect() {
        const res = await get<LoadStockDirectRs>(`/company/${corpModel.id}/shareholder/onlineMeetingNoticeAgreement/paper/directInput`);
        runInAction(() => {
            onlineAcceptancePopupModel.direct.shareholders = res.data.shareholders || [];
        })
    }
    async stockDirectInput(shareholders: {shareholders: number[]}) {
        await post(`/company/${corpModel.id}/shareholder/onlineMeetingNoticeAgreement/paper/directInput`, shareholders);
    }
}

export default new onlineAcceptancePopupService();