import formDataToJson from '@common/module/submit';
import bookKeepingContractModel from '@model/Corp/BookKeepingContract/bookKeepingContractModel';
import bookKeepingContractService from '@service/Corp/BookKeepingContract/bookKeepingContractService';
import { observable, runInAction, toJS } from 'mobx';
import portalModel from '@model/portalModel';
import { _alert, _confirm } from "@model/dialogModel";

class ElectronicSignaturePopupVm {
    constructor() {
        this.state = observable({
            loadingYn: false
        });
    }
    load() {
        bookKeepingContractService.electronicSignature();
    }
    get bookkeepingContractLink() {
        return bookKeepingContractModel.bookkeepingContractLink;
    }
    async requestAgree() {
        this.state.loadingYn = true;
        try {
            await bookKeepingContractService.agreeElectronicSignature();
            this.state.loadingYn = false;
            runInAction(() => {
                portalModel.title = null;
                portalModel.content = null;
            })
            await _alert('전자서명이 완료되었어요.', '서명된 PDF파일을 다운로드 받을 수 있어요.');
            window.location.reload();
        } catch(error) {
            this.state.loadingYn = false;
        }
    }
}

export default new ElectronicSignaturePopupVm();