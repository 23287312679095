import { runInAction, toJS, observable } from 'mobx'; 
import articlesOfAssociationModel from '@model/Corp/ArticlesOfAssociation/articlesOfAssociationModel';
import articlesOfAssociationService from '@service/Corp/ArticlesOfAssociation/articlesOfAssociationService';
import corpModel from '@model/Corp/corpModel';
import portalModel from '@model/portalModel';
import ArticlesOfAssociationChangeModal from './ArticlesOfAssociationChangeModal/ArticlesOfAssociationChangeModal';
import RegisteringModal from './RegisteringModal/RegisteringModal';
import { _alert, _confirm } from "@model/dialogModel";
import AgendaLinkPopup from '@pages/Corp/Dashboard/AgendaLinkPopup/AgendaLinkPopup';

class ArticlesOfAssociationVm {
    constructor() {
        this.state = observable({
            aoaOpenYn: false,
            aoaHistoryOpenYn: false,
        })
    }
    load() {
        articlesOfAssociationService.load();
    }
    get corpId() {
        return corpModel.id;
    }
    get corpType() {
        return corpModel.company.corpType;
    }
    get guides() {
        return articlesOfAssociationModel.guides;
    }
    get companyRegisterStatus() {
        return corpModel.companyRegisterStatus;
    }   
    get articlesOfAssociationTagName() {
        return corpModel.menu?.articlesOfAssociationTagName;
    }
    get updatedAt() {
        return `${articlesOfAssociationModel.updatedAt.year}-${articlesOfAssociationModel.updatedAt.month}-${articlesOfAssociationModel.updatedAt.day} 업데이트`;
    }
    downLoadFile() {
        if(this.registerStatus === "REGISTERED") {
            articlesOfAssociationService.downLoadFile(this.corpId);
        }else{
            this.registeringModal();
        }
    }
    get articlesOfAssociation() {
        return articlesOfAssociationModel.articlesOfAssociation;
    }
    aoaListOpenState() {
        this.state.aoaOpenYn = !this.state.aoaOpenYn;
    }
    articlesOfAssociationChangeModal() {
        runInAction(() => {
            portalModel.title = "정관 변경하기";
            portalModel.content = <AgendaLinkPopup agendaType="등기가_불필요한_정관변경"/>;
        })
    }
    get registerStatus() {
        return articlesOfAssociationModel.registerStatus;
    }
    get companyName () {
        return corpModel.company.companyName;
    }
    get demoCorpId() {
        let demoId = '';
        corpModel.companies?.forEach((item, idx) => {
            if(item.isDemoYn) {
                demoId = item.companyId;
            }
        })
        return demoId;
    }
    registeringModal() {
        portalModel.content = <RegisteringModal/>
    }
    get histories() {
        return articlesOfAssociationModel.histories;
    }
    aoaHistoryOpenState() {
        this.state.aoaHistoryOpenYn = !this.state.aoaHistoryOpenYn;
    }
    get importantContents() {
        return articlesOfAssociationModel.importantContents;
    }
    get displayStockOption() {
        const state = articlesOfAssociationModel.importantContents.스톡옵션_규정;
        switch(state) {
            case '있음(벤처)':
                return {
                    bool: true,
                    text: '(벤처기업)'
                }
            case '있음(일반)':
                return {
                    bool: true,
                    text: '(상법)'
                }
            case '있음':
                return {
                    bool: true,
                    text: ''
                }
            case '없음':
                return {
                    bool: false,
                    text: ''
                }
        }
    }
}
export default new ArticlesOfAssociationVm();