import { useObserver } from 'mobx-react';
import st from '../Agendas.module.scss';
import type {Agenda, IA신주발행_무상증자} from '@model/AgendaDashboard/AgendaDashboardModel.interface';
import clsx from 'clsx';
import { addCommas } from '@common/module/replaceNumber';
import Cst from './C신주발행_무상증자.module.scss';
import Table from '@standby/common-ui/components/atoms/Table/Table';

interface Props {
    agendaIndex : number,
    agenda : Agenda<IA신주발행_무상증자>,
    moreViewYn : boolean
}

function C신주발행_무상증자(props : Props) {
    return useObserver(() => (
        <div className={clsx(st.agenda, props.moreViewYn ? st.opened : '')}>
            <div className={st.title}>
                <div className={st.number}>{props.agendaIndex + 1}</div>
                <div className={st.text}><span className={st.important}>준비금의 자본전입에 관한 신주식 발행</span> (무상증자)</div>
                {props.agenda.decisionBody.map((decisionBody, index) => (
                    <div className={st.badge} key={index}>{decisionBody}</div>
                ))}
            </div>
            {props.moreViewYn && 
                <div className={st.info}>
                    <ul className={st.agendaList}>
                        <li>
                            무상증자 방식 : {props.agenda.info.freeIssueOfNewSharesType}
                        </li>
                        <li>
                            무상증자금액 : {addCommas(props.agenda.info.increaseAmount)}원
                        </li>
                        <li>
                            무상증자 배정표
                        </li>
                    </ul>
                    <Table className={Cst.table}>
                        <colgroup>
                            <col width="190"/>
                            <col width="120"/>
                            <col width="155"/>
                            <col/>
                        </colgroup>
                        <thead>
                            <tr>
                                <th>주주명</th>
                                <th>지분율(=배당률)</th>
                                <th>신주식 수</th>
                                <th>무상증자할 주식수</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.agenda.info.shareholders.map((shareholder, index) => (
                                <tr key={index}>
                                    <td style={{display:"flex"}}>
                                        <span className={Cst.number}>{index+1}</span>
                                        <span className={Cst.name}>{shareholder.name}</span>
                                    </td>
                                    <td>
                                        {shareholder.rate}
                                    </td>
                                    {index === 0 && 
                                        <td rowSpan={props.agenda.info.shareholders.length}>
                                            {addCommas(props.agenda.info.numberOfNewStocks)}
                                        </td>
                                    }
                                    <td>
                                        {addCommas(shareholder.numberOfStocks)} 주
                                    </td>
                                </tr>
                            ))}
                            <tr className={Cst.sum}>
                                <td colSpan={2}><span className={Cst.important}>100.00</span> %</td>
                                <td colSpan={2}>총 <span className={Cst.important}>{addCommas(props.agenda.info.numberOfNewStocks)}</span> 주</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            }
        </div>
    ));
}
export default C신주발행_무상증자;