import { observable, makeObservable } from 'mobx';
import type { RepresentativeDecisionModelInterface, Executives } from './RepresentativeDecisionModel.interface';

class RepresentativeDecisionModel implements RepresentativeDecisionModelInterface {
    constructor() {
        makeObservable(this, {
            executives : observable,
        })
    }
    executives : Executives[] = []
}

export default new RepresentativeDecisionModel();