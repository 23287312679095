import { useObserver } from 'mobx-react';
import st from './ExistingCustomerModal.module.scss';
import vm from './ExistingCustomerModalVm';

function ExistingCustomerModal() {
    
    return useObserver(() => (
        <div className={st.modal}>
            <div className={st.title}>
                앗, 클라우드 지원금 270만 원은<br/>
                <span className={st.bold}>메가존 클라우드의 신규고객에게만 제공</span>되는 혜택이에요.
            </div>
            <div className={st.content}>
                <p>혜택을 드리지 못해서 아쉽네요.</p>
                <p>혹시 {vm.companyName} 외에 <span className={st.bold}>메가존 클라우드와 파트너십 계약을 맺지 않은 사업체</span>가 있나요? 그렇다면 스탠바이에 법인등록 후 그 계정으로 지원금을 신청하세요!</p>
                <a href='/companyRegistration' target='_blank'>법인등록 하러가기</a>
            </div>
        </div>
    ));
}

export default ExistingCustomerModal;