import { useObserver } from 'mobx-react';
import st from '../Agendas.module.scss';
import type {Agenda, IA재무제표_승인} from '@model/Corp/Agenda/Step/FinalCheck/BeforeFinal/AgendaView/AgendaViewModel.interface';
import vm from './C재무제표_승인Vm';

interface Props {
    agendaIndex : number,
    agenda : Agenda<IA재무제표_승인>
}

function C재무제표_승인(props : Props) {
    return useObserver(() => (
        <div className={st.agenda}>
            <div className={st.title}>
                <div className={st.iconTitle}>안건</div>
                <div className={st.number}>{props.agendaIndex + 1}</div>
                <div className={st.text}><span className={st.important}>결산보고서(재무제표) 승인</span></div>
                {props.agenda.decisionBody.map((decisionBody, index) => (
                    <div className={st.badge} key={index}>{decisionBody}</div>
                ))}
            </div>
            <div className={st.info}>
                <ul className={st.agendaList}>
                    <li>
                        회계연도 : {props.agenda.info.fiscalYear}
                    </li>
                    <li>
                        재무제표 업로드 : 
                        <span style={{marginLeft:"4px"}}>
                            {props.agenda.info.downloadUrl ? 
                                <button type="button" className={st.downloadBtn} onClick={() => vm.fileDown(props.agenda.info.downloadUrl)}>{props.agenda.info.downloadTitle}</button>
                                :
                                "없음"
                            }
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    ));
}
export default C재무제표_승인;