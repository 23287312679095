import { useObserver } from 'mobx-react';
import AdditionalInformationVm from './AdditionalInformationVm';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import st from './AdditionalInformation.module.scss';
import regExp from '@common/module/regExp';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import Select from '@standby/common-ui/components/atoms/Input/Select/Select';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';

function AdditionalInformation(props) {
    let location = useLocation();
    const token = queryString.parse(location.search).token;

    useEffect(() => {
        AdditionalInformationVm.loadEmailData(token);
    },[]);

    return useObserver(() => (
        <form onSubmit={(e) => AdditionalInformationVm.submit(e, token)} className={st.additionalInformationContent}>
            <div className={st.logoWrap}><a href='https://standby.kr/' className={st.logo}><img src='/images/logo_standby.svg?1'/></a></div>
            <div className={st.mainTitle}>
                {AdditionalInformationVm.email}님<br/> 안녕하세요 :) 스탠바이 헬프미에 오신 것을 환영합니다.
            </div>
            <div className={st.newPassword}>
                <div className={st.title}>새 비밀번호</div>
                <InputText name="password" type="password" className={st.inputText} value={AdditionalInformationVm.password} onChange={AdditionalInformationVm.setPassword} fullSize
                errorText={<Error name="password" value={AdditionalInformationVm.password} errorCase={{
                    required: '비밀번호를 입력해 주세요.',
                    pattern: {
                        value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[`~!@#\$%\^&\*\(\)\{\}\[\]\-_=\+\\|;:'"<>,\./\?])[A-Za-z\d`~!@#\$%\^&\*\(\)\{\}\[\]\-_=\+\\|;:'"<>,\./\?]{8,16}$/.test(AdditionalInformationVm.password),
                        message: '올바른 비밀번호 형식으로 입력해 주세요.'
                    }
                }}/>}/>
                <div className={st.passwordAlert}>
                    <div>8글자 이상 입력</div>
                    <div>영문, 숫자, 특수문자 각 1개 이상 사용</div>
                </div>
            </div>
            <div className={st.newPasswordCheck}>
                <div className={st.title}>새 비밀번호 확인</div>
                <InputText name="passwordCheck" type="password" className={st.inputText} value={AdditionalInformationVm.passwordCheck} onChange={AdditionalInformationVm.setPasswordCheck} fullSize
                errorText={<Error name="passwordCheck" value={AdditionalInformationVm.passwordCheck} errorCase={{
                    required: '비밀번호 확인을 입력해 주세요.',
                    validate: {
                        func: AdditionalInformationVm.isSame(),
                        message: '동일한 비밀번호를 입력해주세요.'
                    }
                }}/>}/>
            </div>
            <div className={st.name}>
                <div className={st.title}>이름</div>
                <InputText name="name" className={st.inputText} value={AdditionalInformationVm.name} onChange={AdditionalInformationVm.setName} fullSize
                errorText={<Error name="name" value={AdditionalInformationVm.name} errorCase={{
                    required: '이름을 입력해 주세요.',
                    pattern: {
                        value: regExp.onlyKorEng().test(AdditionalInformationVm.name),
                        message: '한글 또는 영문으로 입력해 주세요.'
                    }
                }}/>} 
                />
                <div className={st.nameExplain}>실명 사용을 추천드려요!</div>
            </div>
            <div className={st.phoneNumber}>
                <div className={st.title}>전화번호</div>
                <div className={st.phoneNumberBox}>
                    <Select name="countryCode" value={AdditionalInformationVm.countryCode} disabled style={{width:'85px'}}>
                        <option value="">-</option>
                        <option value="82">+82</option>
                    </Select>
                    <InputText name="phoneNumber" value={AdditionalInformationVm.phoneNumber} onChange={AdditionalInformationVm.setPhoneNumber} style={{width:'367px'}}
                    errorText={<Error name="phoneNumber" value={AdditionalInformationVm.phoneNumber} errorCase={{
                        required: '전화번호를 입력해 주세요.',
                        pattern: {
                            value: regExp.phoneNumber().test(AdditionalInformationVm.phoneNumber),
                            message: '올바른 전화번호를 입력해 주세요.'
                        }
                    }}/>}
                    />
                </div>
            </div>
            <SquareBtn type="submit" className={st.squareBtn}>스탠바이 바로 시작하기</SquareBtn>
        </form>
    ));
}
export default AdditionalInformation;