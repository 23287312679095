import { post, get } from '@common/module/httpRequest';
import { runInAction } from 'mobx'; 
import type { LoadRs } from './RepresentativeDecisionService.interface';
import RepresentativeDecisionModel from "@src/model/Corp/Agenda/Step/FinalCheck/BeforeFinal/RepresentativeDecision/RepresentativeDecisionModel";
import { _alert } from "@model/dialogModel";

class RepresentativeDecisionService {
    async load(companyId : string, agendaProgressId : string, type : '서면결의서' | '대표결정서') {
        const res = await get<LoadRs>(`/company/${companyId}/agendaProgress/${agendaProgressId}/beforeFinal/document/executives?type=${type}`);
        runInAction(() => {
            RepresentativeDecisionModel.executives = res.data.executives.map((executive) => {
                return {
                    id : executive.id ? (executive.id).toString() : '',
                    selectedYn : executive.selectedYn || false,
                    name : executive.name || ''
                }
            })
        })
    }
    async save(param : {id : string}, companyId : string, agendaProgressId : string, type : '서면결의서' | '대표결정서') {
        await post(`/company/${companyId}/agendaProgress/${agendaProgressId}/beforeFinal/document/executives?type=${type}`, param);
        await  _alert('선택 완료되었어요', '자동작성된 대표결정서를 다운로드 받으세요.', undefined, undefined, 'loud');
        window.location.reload();
    }
}
export default new RepresentativeDecisionService();