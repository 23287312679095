import { useObserver } from 'mobx-react';
import vm from './PurposesVm';
import st from './Purposes.module.scss';
import clsx from 'clsx';

function Purposes() {
    return useObserver(() => (
        <div className={st.wrap}>
            <div className={st.title}>
                <span>등기부상 목적</span>
            </div>
            <button type='button' className={st.openModalBtn} onClick={vm.agendaLinkModal}>변경하기</button>
            <ul className={st.purposes}>
                {(vm.businessPurposes.length < 7 || vm.state.moreviewYn === true) ?
                    vm.businessPurposes.map((businessPurposes, index) => (
                        <li key={index}>{businessPurposes}</li>
                    )) :
                    vm.businessPurposes.slice(0,6).map((businessPurposes, index) => (
                        <li key={index}>{businessPurposes}</li>
                    ))
                }
            </ul>
            {vm.businessPurposes.length > 6 &&
                <button type="button" className={clsx(st.moreView, vm.state.moreviewYn ? st.open : null)} onClick={() => vm.moreview()}>더보기</button>
            }
        </div>
    ));
}
export default Purposes;