import { useObserver } from 'mobx-react';
import st from '../Agendas.module.scss';
import type {Agenda, IA본점의_주소_변경} from '@model/AgendaDashboard/AgendaDashboardModel.interface';
import clsx from 'clsx';

interface Props {
    agendaIndex : number,
    agenda : Agenda<IA본점의_주소_변경>,
    moreViewYn : boolean
}

function C본점의_주소_변경(props : Props) {
    return useObserver(() => (
        <div className={clsx(st.agenda, props.moreViewYn ? st.opened : '')}>
            <div className={st.title}>
                <div className={st.number}>{props.agendaIndex + 1}</div>
                <div className={st.text}><span className={st.important}>본점의 주소이전</span></div>
                {props.agenda.decisionBody.map((decisionBody, index) => (
                    <div className={st.badge} key={index}>{decisionBody}</div>
                ))}
            </div>
            {props.moreViewYn && 
                <div className={st.info}>
                    <ul className={st.agendaList}>
                        <li>
                            {props.agenda.info.jurisdictionYn ? '관내이전' : '관외이전'}
                        </li>
                        <li>
                            신규본점 : {props.agenda.info.selectedMetropolitanType} {props.agenda.info.selectedSigungu}
                        </li>
                    </ul>
                </div>
            }
        </div>
    ));
}
export default C본점의_주소_변경;