import { runInAction, observable, toJS } from "mobx";
import AdminAddModel from '@model/Corp/AdminAdd/AdminAddModel';
import AdminAddService from '@service/Corp/AdminAdd/AdminAddService';
import formDataToJson from '@common/module/submit';
import st from './AdminAdd.module.scss';
import { _alert, _confirm } from "@model/dialogModel";
import regExp from '@common/module/regExp';

class AdminAddVm {
    load() {
        AdminAddService.load();
    }
    get addData() {
        return AdminAddModel.addData;
    }
    changeEmail(e) {
        runInAction(() => {
            this.addData.email =  regExp.deleteSpace(e.target.value);
        })
    }
    changeName(e) {
        runInAction(() => {
            this.addData.name = e.target.value;
        })
    }
    changeRole(e) {
        runInAction(() => {
            this.addData.role = e.target.value;
        })
    }
    changeAuthorityType(e) {
        runInAction(() => {
            this.addData.authorityType = e.target.value;
            for (const authoritiesType in AdminAddModel.addData.authorities) {
                AdminAddModel.addData.authorities[authoritiesType].forEach((authority) => {
                    authority.useYn = e.target.value === "GENERAL_MANAGER" ? true : false;
                })
            }
        })
    }
    authorityCheckedAll(type) {
        let checkAll = true;
        AdminAddModel.addData.authorities[type].forEach((authority) => {
            if(authority.useYn === false) {
                checkAll = false;
            }
        })
        return checkAll; 
    }
    changeUseYn(e, type, authorityIndex) {
        AdminAddModel.addData.authorities[type][authorityIndex].useYn = e.target.checked;
    }
    authorityCheckedAllChange(e, type) {
        AdminAddModel.addData.authorities[type].forEach((authority) => {
            authority.useYn = e.target.checked;
        })
    }
    submit(e) {
        e.preventDefault();
        if(formDataToJson(e.target).error) {
            return
        } else {
            let param = formDataToJson(e.target).data;

            let checkedItem = 0;
            for (const authoritiesType in AdminAddModel.addData.authorities) {
                AdminAddModel.addData.authorities[authoritiesType].forEach((authority) => {
                    checkedItem += authority.useYn ? 1 : 0;
                })
            }
            
            if(checkedItem === 0) {
                _alert('관리자는 최소한 1개의 권한을 가지고 있어야 해요.', '', '확인', <ul className={st.alertContent}><li>권한을 추가한 후 초대버튼을 누르세요.</li></ul>);
            }else{
                AdminAddService.invite(param);
            }

        }
    }
}
export default new AdminAddVm();