import { useObserver } from 'mobx-react';
import st from './FirstPage.module.scss';

function FirstPage() {
    return useObserver(() => (
        <>
            <img src='/images/corporateTax_first_page.png' className={st.image}/>
        </>
    ));
}
export default FirstPage;