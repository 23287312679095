import { useObserver } from 'mobx-react';
import st from './CancelRegModal.module.scss';
import vm from './CancelRegModalVm';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';

function CancelRegModal() {
    return useObserver(() => (
        <div className={st.cancelReg}>
            <div className={st.ex}>헬프미 어드민에서 종료처리는 별도로 진행해주세요.</div>
            <form onSubmit={(e) => vm.submit(e)}>
                <div className={st.row}>
                    <div className={st.title}>취소요청자</div>
                    <div className={st.content}>
                        <input type='text' name='name' className={st.inputText} value={vm.name} onChange={vm.changeName}/>
                    </div>
                </div>
                <div className={st.row}>
                    <div className={st.title}>취소사유</div>
                    <div className={st.content}>
                        <textarea value={vm.reason} name="reason" onChange={vm.changeReason} className={st.textarea}/>
                        <CheckBox className={st.check} value={vm.sendAlimtalkYn.toString()} name="sendAlimtalkYn" checked={vm.sendAlimtalkYn} onChange={vm.changeSendAlimtalkYn}>고객에게 알림톡 함께 발송</CheckBox>
                        <div className={st.checkSubMessage}>
                            (등기대행 → 셀프등기로 자동전환 안내 알림톡)
                        </div> 
                    </div>
                </div>
                <div className={st.btnArea}>
                    <button type="submit">취소하기</button>
                </div>
            </form>
        </div>
    ));
}
export default CancelRegModal;