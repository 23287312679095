import formDataToJson from '@common/module/submit';
import { observable, runInAction, toJS } from 'mobx';
import homeService from '@service/Corp/Stock/Home/homeService';
import corpModel from '@model/Corp/corpModel';
import homeModel from '@model/Corp/Stock/Home/homeModel';
import portalModel from '@model/portalModel';
import { _alert, _confirm } from "@model/dialogModel";
import StockContentPopup from './StockContentPopup/StockContentPopup';
import EmptyShareholders from './EmptyShareholders/EmptyShareholders';
import OnlineMusterPopup from '../OnlineMuster/OnlineMusterPopup/OnlineMusterPopup';
import st from './Home.module.scss';
import { pageRouter } from '@model/pageModel';
import userModel from '@model/userModel';
import OnlineAcceptancePopup from '@pages/Corp/Popup/OnlineAcceptancePopup/OnlineAcceptancePopup';
import AgendaLinkPopup from '@pages/Corp/Dashboard/AgendaLinkPopup/AgendaLinkPopup';

class StockVm {
    constructor() {
        this.state = observable({
            stocksOpenYn: false,
        })
    }
    get corpId() {
        return corpModel.id;
    }
    load() {
        homeService.homeData();
        runInAction(() => {
            homeModel.updatedAt = userModel.date;
        })
    }
    get stockManagementGuides() {
        return homeModel.stockManagementGuides;
    }
    get capital() { //자본금
        return homeModel.capital;
    }
    get stocksNumber() { //전체 주식수
        return homeModel.stocksNumber;
    }
    get parValue() { //액면금
        return homeModel.parValue;
    }
    get stocks() { //주식
        return homeModel.stocks;
    }
    stocksListOpenState() {
        this.state.stocksOpenYn = !this.state.stocksOpenYn;
    }
    stockContent(idx, title) {
        portalModel.title = title;
        portalModel.content = <StockContentPopup idx={idx} />
    }
    get updatedAt() {
        return homeModel.updatedAt;
    }
    setUpdatedAt(e) {
        let year = ""; let month = ""; let day = "";
        if(e) {
            year = e.getFullYear();
            month = ('0' + (e.getMonth() + 1)).slice(-2);
            day = ('0' + e.getDate()).slice(-2);
            
            runInAction(() => {
                homeModel.updatedAt = year + '-' + month  + '-' + day;   
                homeService.stockholderDate(year + '-' + month  + '-' + day);
            })
        }
    }
    get shareholders() { //주주
        return toJS(homeModel.shareholders);
    }
    onlineMusterNoticeState(data) {
        switch(data) {
            case '발송전':
            case '발송중':
            case '동의완료':
            case '발송실패':
                return data;
            case '발송완료':
                return '발송완료\n(대기중)';
        }
    }
    shareholderStocksSum() {
        let sum = 0;
        this.shareholders.forEach((item, idx) => {
            item.stocks.forEach((stock, index) => {
                sum = sum + stock.number; 
            })
        })
        return sum;
    }
    demoAlert() {
        return _alert('샘플컴퍼니에서는 이용할 수 없는 기능이에요.', '', '확인', <ul className={st.alertContent}><li>법인등록을 마친 이후에 기능을 사용해보세요.</li></ul>, 'loud');
    }
    onlineMusterContent() {
        runInAction(() => {
            portalModel.title = '소집통지 온라인 수신동의 받기';
            portalModel.content = <OnlineAcceptancePopup page="stock"/>
        })
    }
    get isDemo() {
        let isDemo = false;
        corpModel.companies?.forEach((item, idx) => {
            if(item.companyId === this.corpId) {
                if(item.isDemoYn) {
                    isDemo = true;
                }
            }
        })
        return isDemo;
    }
    get displayShareholders() {
        if(this.shareholders && this.shareholders.length !== 0) {
            return true;
        } else {
            return false;
        }
    }
    increaseCapital() {
        runInAction(() => {
            portalModel.title = "자본금 증액하기";
            portalModel.content = <AgendaLinkPopup agendaType="신주발행_유상증자"/>;
        })
    }
    transferStocks() {
        return _alert('주식양도 기능은 곧 출시예정이에요.', '', '확인', <ul className={st.alertContent}><li>바로 주식양도가 필요하다면, 오른쪽 밑의 채팅상담으로 문의를 해주세요.</li><li>정확한 상담을 해드릴 수 있는 회계법인을 연결해드릴게요.</li></ul>, 'loud');
        // if(this.isDemo) {
        //     alert('미개발');
        // } else {
        //     runInAction(() => {
        //         portalModel.content = <EmptyShareholders />
        //     })
        // }
    }
    stockHistory() {
        if(this.isDemo) {
            alert('미개발');
        } else {
            runInAction(() => {
                portalModel.content = <EmptyShareholders />
            })
        }
    }
    get companyRegisterStatus() {
        return corpModel.companyRegisterStatus;
    }   
    get stockholderTagName() {
        return corpModel.menu?.stockholderTagName;
    }
}
export default new StockVm();