import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import MainVm from './MainVm';
function Main() {
    return useObserver(() => (
        <>
            <h1>*메인페이지*</h1>
            {MainVm.isLogined() === false && 
                <>
                    <Link to="/auth/login">로그인</Link><br/>
                    <Link to="/auth/joinMembership">회원가입</Link><br/>
                </>
            }
            {MainVm.isLogined() === true &&
                <div>
                    {MainVm.user.name} ({MainVm.user.email})님 환영합니다.

                    <button style={{backgroundColor:"red", color:"#fff"}} type='button' onClick={MainVm.logout}>로그아웃</button>
                </div>
            }
            <br/>
            <Link to="/userDashboard">계정대시보드</Link>
        </>
    ));
}
export default Main;