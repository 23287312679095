import { post, get } from '@common/module/httpRequest';
import { runInAction, toJS } from 'mobx'; 
import joinModel from '@model/AuthBusinessRegistration/joinModel';
import loginModel from '@model/AuthBusinessRegistration/loginModel';
import { pageRouter } from '@model/pageModel';
import Cookies from 'js-cookie';
import presetService from '@service/presetService';
import userModel from '@model/userModel';
import portalModel from '@model/portalModel';
import ReactGA from 'react-ga4';

class authBusinessRegistrationService {
    async loadTerms(type) {
        const res = await get(`/term/${type}`, {}, {});
        return res.data;
    }
    async loadBusinessRegistration(token) {
        const res = await get(`/user/register/businessRegistration`, {}, {
            'Business-Registration-Authorization' : `Bearer ${token}`
        });
        joinModel.setBusinessRegistration(res.data);
    }
    async businessRegistration(param, token) { //회원가입
        const res = await post(`/user/register/businessRegistration`, param, {
            'Business-Registration-Authorization': `Bearer ${token}`
        })
        //마케팅 구글에널리틱스 관련 코드
        ReactGA.gtag('event', 'complete_signup');
        this.loginSuccess(res.data);
        window.location.replace(`/corp/accounting/businessRegistration?corp=${res.data.companyId}`);
    }
    async loadExisting(token) {
        const res = await get(`/user/existing`, {}, {
            'Existing-Customer-Authorization' : `Bearer ${token}`
        })
        loginModel.setExisting(res.data);
    }
    async login(data) { //로그인
        const res = await post(`/login?autoLogin=false`, {
            username : data?.email,
            password : data?.password
        }, {});
        this.loginSuccess(res.data);
        window.location.replace(`/userDashboard`);
    }
    loginSuccess(res) {
        /*
            rememberMeToken으로 자동로그인 여부를 판단함.
        */
        if(res.rememberMeToken) {
            Cookies.remove('accessToken')
            Cookies.remove('refreshToken')
            localStorage.setItem('rememberMeToken', res.rememberMeToken)
            localStorage.setItem('accessToken', res.accessToken)
        }else {
            localStorage.removeItem('accessToken')
            localStorage.removeItem('rememberMeToken')
            Cookies.set('accessToken', res.accessToken)
            Cookies.set('refreshToken', res.refreshToken)
        }
        if(!userModel.user.name || !userModel.user.email) {
            presetService.load();
        }
    }
    async loadDashboard() {
        const res = await get(`/user/dashboard`);
        loginModel.setAcceptWaitingCompanies(res.data.acceptWaitingCompanies);
    }
    async businessRegistrationRouter(token) {
        const res = await get(`/user/businessRegistration/router`, {}, {
            'Business-Registration-Router-Authorization' : 'Bearer ' + token
        })
        if(res.data.redirectUrl) {
            window.location.replace(res.data.redirectUrl);
        }
    }
    async isLoggedIn(existingToken) {
        const res = await get(`/user/existing/isLoggedIn`, {}, {
            'Existing-Customer-Authorization' : 'Bearer ' + existingToken
        })
        if(res.data.redirectUrl) {
            window.location.replace(res.data.redirectUrl);
        }
    }
}

export default new authBusinessRegistrationService();