import meetingsStepModel from '@model/Corp/Agenda/Step/meetingsStepModel';

class TabVm {
    get 주주전원_서면결의Idx() {
        return meetingsStepModel.tabList.findIndex((obj) => (obj.tabType === '주주총회_방식' || obj.tabType === '정기주주총회_방식'));
    }
    displayWrittenResolution(idx: number) { //서면결의방식
        const tabType = meetingsStepModel.tabList[idx].tabType;
        if(tabType === '주주총회_방식' || tabType === '정기주주총회_방식') {
            return true;
        }
        return false;
    }
    displayNotiOfDraft(idx: number) { //소집통지
        const tabType = meetingsStepModel.tabList[idx].tabType;
        if(tabType === '주주총회_정보입력' || tabType === '주주총회_대표결정_정보입력' || tabType === '주주전원서면결의_정보입력' || tabType === '주주총회_이사회_정보입력') { 
            const 주주전원_서면결의Yn = meetingsStepModel.tabList[this.주주전원_서면결의Idx].주주전원_서면결의Yn;
            if(주주전원_서면결의Yn === false) {
                return true;
            }
            return false;
        }
        return false;
    }
    displayDateList(idx: number) { //일자입력
        const tabType = meetingsStepModel.tabList[idx].tabType;

        if(tabType === '이사회_정보입력' || tabType === '대표_결정사항_정보입력') { 
            return true;
        }

        if(tabType === '주주총회_정보입력' || tabType === '주주총회_대표결정_정보입력' || tabType === '주주전원서면결의_정보입력' || tabType === '주주총회_이사회_정보입력') {
            const 주주전원_서면결의Yn = meetingsStepModel.tabList[this.주주전원_서면결의Idx].주주전원_서면결의Yn;
            if(주주전원_서면결의Yn) {
                return true;
            } else {
                const musterNoticeType = meetingsStepModel.tabList[idx].musterNoticeType;
                if(musterNoticeType) return true;
                else if(meetingsStepModel.hasCapitalOverBillionYn) return true;
                else return false;
            }
        }
    }
    displayShareholderList(idx: number) {
        const tabType = meetingsStepModel.tabList[idx].tabType;
        if(tabType === '주주명부') {
            return true;
        }
        return false
    }
    displayRegistrationAgency(idx: number) {
        const tabType = meetingsStepModel.tabList[idx].tabType;
        if(tabType === '등기_방법') {
            return true;
        }
        return false;
    }
    displayNewShareholders(idx: number) {
        const tabType = meetingsStepModel.tabList[idx].tabType;
        if(tabType === '신주발행_방식') {
            return true;
        }
        return false;
    }
}
export default new TabVm();