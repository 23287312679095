import type { InformationBoard, PageType, Status, GuestInfo, AuthInfo } from './guestModel.interface';
import { makeObservable, observable } from 'mobx';

class informationBoardModel implements InformationBoard {
    constructor() {
        makeObservable(this, {
            pageType: observable,
            status: observable,
            companyName: observable,
            guestInfo: observable,
            authInfo: observable,
        })
    }
    pageType: PageType = null;
    status: Status = null;
    companyName: string = "";
    guestInfo: GuestInfo = null;
    authInfo: AuthInfo = null;
}

export default new informationBoardModel();