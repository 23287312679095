import { useObserver } from 'mobx-react';
import st from '../Agendas.module.scss';
import type {Agenda, IA임원_취임_중임_사임_퇴임, ExecutiveModalMembers, ExecutiveModalMember} from '@model/AgendaDashboard/AgendaDashboardModel.interface';
import clsx from 'clsx';
import Table from '@standby/common-ui/components/atoms/Table/Table';
import Cst from './C임원_취임_중임_사임_퇴임.module.scss';

interface Props {
    agendaIndex : number,
    agenda : Agenda<IA임원_취임_중임_사임_퇴임>,
    moreViewYn : boolean
}

function C임원_취임_중임_사임_퇴임(props : Props) {
    return useObserver(() => (
        <div className={clsx(st.agenda, props.moreViewYn ? st.opened : '')}>
            <div className={st.title}>
                <div className={st.number}>{props.agendaIndex + 1}</div>
                <div className={st.text}><span className={st.important}>{props.agenda.info.title}</span> {props.agenda.info.changeExecutive}</div>
                {props.agenda.decisionBody.map((decisionBody, index) => (
                    <div className={st.badge} key={index}>{decisionBody}</div>
                ))}
            </div>
            {props.moreViewYn && 
                <div className={st.info}>
                    <Table className={Cst.table}>
                        <colgroup>
                            <col width="200"/>
                            <col/>
                        </colgroup>
                        <thead>
                            <tr>
                                <th>기존 {props.agenda.info.originExecutiveCount}명 (이사 {props.agenda.info.originDirectorCount}명)</th>
                                <th>변경 <span className={Cst.important}>{props.agenda.info.updateExecutiveCount}</span>명 (이사 <span className={Cst.important}>{props.agenda.info.updateDirectorCount}</span>명)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.agenda.info.executives.map((executive, index) => (
                                'executiveMembers' in executive === true ?
                                    (executive as ExecutiveModalMembers).executiveMembers.map((executiveMember, index2) => (
                                        <tr key={'p'+index+'p2'+index2} className={Cst.samePerson}>
                                            <td className={Cst.new}>
                                                <div className={Cst.name} key={index2}>
                                                    <span className={Cst.number}>{index2 === 0 ? index+1 : ''}</span>
                                                    <span>{executiveMember.originExecutive.name} {executiveMember.originExecutive.position}</span>
                                                </div>
                                            </td>
                                            <td className={Cst.origin}>
                                                <div className={Cst.info} key={index2}>
                                                    <div className={Cst.tags}>
                                                        {executiveMember.changeType.map((changeType, index3) => (
                                                            <div className={Cst.tag} key={index3} data-changetype={changeType}>{changeType.replace('_', '/')}</div>
                                                        ))}
                                                    </div>
                                                    <div className={clsx(Cst.name, executiveMember.changeType.includes('사임_퇴임') ? Cst.erase : null)}>
                                                        {executiveMember.updateExecutive.name} {executiveMember.updateExecutive.position}
                                                    </div>
                                                </div>
                                                {executiveMember.changeType.includes('직급변경') && 
                                                    <div className={Cst.position}>
                                                        <span className={Cst.before}>{executiveMember.originExecutive.position}</span>
                                                        <span className={Cst.after}>{executiveMember.updateExecutive.position}</span>
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                    ))
                                :
                                    <tr key={'p'+index}>
                                        <td className={Cst.new}>
                                            {(executive as ExecutiveModalMember).changeType.includes('신규') === false &&
                                                <div className={Cst.name}>
                                                    <span className={Cst.number}>{index+1}</span>
                                                    <span>{(executive as ExecutiveModalMember).originExecutive.name} {(executive as ExecutiveModalMember).originExecutive.position}</span>
                                                </div>
                                            }
                                        </td>
                                        <td className={Cst.origin}>
                                            <div className={Cst.info}>
                                                <div className={Cst.tags}>
                                                    {(executive as ExecutiveModalMember).changeType.map((changeType, index2) => (
                                                        <div className={Cst.tag} key={index2} data-changetype={changeType}>{changeType.replace('_', '/')}</div>
                                                    ))}
                                                </div>
                                                <div className={clsx(Cst.name, (executive as ExecutiveModalMember).changeType.includes('사임_퇴임') ? Cst.erase : null)}>
                                                    {(executive as ExecutiveModalMember).updateExecutive.name} {(executive as ExecutiveModalMember).updateExecutive.position}
                                                </div>
                                            </div>
                                            {(executive as ExecutiveModalMember).changeType.includes('직급변경') && 
                                                <div className={Cst.position}>
                                                    <span className={Cst.before}>{(executive as ExecutiveModalMember).originExecutive.position}</span>
                                                    <span className={Cst.after}>{(executive as ExecutiveModalMember).updateExecutive.position}</span>
                                                </div>
                                            }
                                        </td>
                                    </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            }
        </div>
    ));
}
export default C임원_취임_중임_사임_퇴임;