import { useObserver } from 'mobx-react';
import React from 'react';
import vm from './IssuanceOfConvertibleBondsVm';
import qst from '@pages/Corp/Agenda/Step/AgendaQuestion/commonQuestion.module.scss';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import RadioLabel from '@standby/common-ui/components/molecules/RadioLabel/RadioLabel';
import { addCommas } from '@common/module/replaceNumber';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import st from './IssuanceOfConvertibleBonds.module.scss';
import type {SelectedAgenda, I전환사채_발행} from '@model/Corp/Agenda/Step/agendaStepModel.interface';

interface IssuanceOfConvertibleBondsProps {
    agenda : SelectedAgenda<I전환사채_발행>,
    agendaIndex : number
}
const IssuanceOfConvertibleBonds = React.memo(function (props : IssuanceOfConvertibleBondsProps) {
    return useObserver(() => (
        <div className={qst.questionForm}>
            <div className={qst.qusetion}>
                <div className={qst.questionTitle}>발행할 전환사채 총액을 입력하세요.</div>
                <div className={qst.subTextInput}>
                    <InputText name="agendaDetail.totalAmount" textAlign="right" placeholder="0" value={addCommas(props.agenda.agendaDetail.totalAmount)} onChange={(e) => vm.totalAmount(e, props.agendaIndex)} style={{width:"220px"}}
                        errorText={<Error name="agendaDetail.totalAmount" value={addCommas(props.agenda.agendaDetail.totalAmount) || ""} errorCase={{
                            required: '총액을 입력하세요.'
                        }}
                        />}
                    /><span className={qst.inputSubText}>원</span>
                </div>
            </div>
            <div className={qst.qusetion}>
                <div className={qst.questionTitle}>전환사채를 누구에게 발행하나요?</div>
                <div style={{width:"460px"}}>
                    <RadioLabel name="agendaDetail.publicationTarget" value="SHAREHOLDERS" checked={props.agenda.agendaDetail.publicationTarget === "SHAREHOLDERS"} onChange={(e) => vm.publicationTarget(e, props.agendaIndex)}>주주</RadioLabel>
                    <RadioLabel name="agendaDetail.publicationTarget" value="OTHERS" checked={props.agenda.agendaDetail.publicationTarget === "OTHERS"} onChange={(e) => vm.publicationTarget(e, props.agendaIndex)}>주주 외의 사람</RadioLabel>
                    <RadioLabel name="agendaDetail.publicationTarget" value="BLEND" checked={props.agenda.agendaDetail.publicationTarget === "BLEND"} onChange={(e) => vm.publicationTarget(e, props.agendaIndex)}>주주 + 주주 외의 사람</RadioLabel>
                    <div>
                        <Error name="agendaDetail.publicationTarget" value={props.agenda.agendaDetail.publicationTarget || ""} errorCase={{
                            required: '발행할 사람을 선택하세요.'
                        }}
                        />
                    </div>
                </div>
            </div>
            <div className={qst.qusetion}>
                <div className={qst.questionTitle}>
                    <div>전환사채를 몇 명이 발행받나요?</div>
                    <div className={st.titleSubInfo}>숫자만 입력 가능해요.</div>
                </div>
                <div className={qst.subTextInput}>
                    <InputText name="agendaDetail.issueCount" textAlign="right" placeholder="0" value={addCommas(props.agenda.agendaDetail.issueCount)} onChange={(e) => vm.issueCount(e, props.agendaIndex)} style={{width:"220px"}}
                        errorText={<Error name="agendaDetail.issueCount" value={addCommas(props.agenda.agendaDetail.issueCount) || ""} errorCase={{
                            required: '발행 받을 분이 몇 명인지 입력하세요.'
                        }}
                        />}
                    /><span className={qst.inputSubText}>명</span>
                </div>
            </div>
        </div>
    ));
})
export default IssuanceOfConvertibleBonds;