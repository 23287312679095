import DashboardModel from '@model/Corp/Dashboard/DashboardModel';
import DashboardService from '@service/Corp/Dashboard/DashboardService';
import adminModel from '@model/Corp/AdminSetting/adminModel';
import { _alert, _confirm } from "@model/dialogModel";
import { runInAction } from 'mobx';
import BesinessRegistrationDownloadSelectModal from '@pages/Corp/BusinessRegistration/BesinessRegistrationDownloadModal/BesinessRegistrationDownloadSelectModal';
import portalModel from '@model/portalModel';

class CompanyFilesVm {
    fileDownload(downloadLink) {
        DashboardService.fileDownload(downloadLink);
    }
    get dashboardData() {
        return DashboardModel.dashboardData;
    }
    authorityYn(type) {
        /**
         * @todo adminModel 수정하면서 ts로 변경해야함
         */
        return adminModel?.authority?.menus?.[type]?.useYn;
    }
    authorityFalse() {
        _alert('주주명부 다운로드 권한이 없어요');
    }
    async downloadBusinessRegistrationFileModal() {
        runInAction(() => {
            portalModel.title = '사업자등록증 다운로드';
            portalModel.content = <BesinessRegistrationDownloadSelectModal/>;
        })
    }
}
export default new CompanyFilesVm();