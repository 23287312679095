import { runInAction, observable, toJS } from "mobx";
import authService from '@service/authService'
import joinMembershipModel from '@model/Auth/joinMembershipModel';
import formDataToJson from '@common/module/submit';
import portalModel from '@model/portalModel';
import TermsModal from '@pages/Auth/TermsModal/TermsModal';
import regExp from '@common/module/regExp';

class viewModel {
    constructor() {
        this.state = observable({
            submitYn: false
        });
    }
    async submit(e) {
        e.preventDefault();
        if(formDataToJson(e.target).error) {
            return
        } else {
            let param = formDataToJson(e.target).data;
            try {
                this.state.submitYn = true;
                await authService.joinMembership(param);
                this.state.submitYn = false;
            } catch(err) {
                this.state.submitYn = false;
            }
        }
    }
    loadTerms() {
        authService.loadTerms('NORMAL_REGISTER');
    }
    get email() {
        return joinMembershipModel.email;
    }
    get userRegistrationTerms() {
        return joinMembershipModel.userRegistrationTerms;
    }
    setEmail(e) {
        runInAction(() => {
            joinMembershipModel.email = regExp.deleteSpace(e.target.value);
        })
    }
    get agreeAll() {
        if(joinMembershipModel.submittedTermRequests.length === 0) {
            return false;
        }
        let agreeAll = true;
        joinMembershipModel.submittedTermRequests.forEach((item) => {
            if(item.agreeYn === false) {
                agreeAll = false;
            }
        })
        return agreeAll;
    }
    setAgreeAll(e) {
        runInAction(() => {
            joinMembershipModel.submittedTermRequests.forEach((item) => {
                item.agreeYn = e.target.checked;
            })
        })
    }
    agreeYn(idx) {
        return joinMembershipModel.submittedTermRequests[idx].agreeYn;
    }
    setAgreeYn(e, idx) {
        runInAction(() => {
            joinMembershipModel.submittedTermRequests[idx].agreeYn = e.target.checked;
        })
    }
    termsPopup(title, content) {
        runInAction(() => {
            portalModel.title = title;
            portalModel.content = <TermsModal content={content}/>;
        })
    }
    get isSavePossible() {
        let agree = true;
        if(joinMembershipModel.submittedTermRequests.length === 0) {
            return false;
        }
        joinMembershipModel.submittedTermRequests.forEach((term) => {
            if(term.agreeYn === false && term.required) {
                agree = false;
            }            
        })
        return agree;
    }
}

export default new viewModel();