import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ArticlesOfAssociationChangeModalVm from './ArticlesOfAssociationChangeModalVm';
function ArticlesOfAssociationChangeModal() {
    return useObserver(() => (
        <>
            <div style={{display:"flex", justifCcontent:"space-between"}}>
                <Link to="" style={{width:"300px", height:"100px", border:"1px solid black", display:"inline-block"}}>기존정관을 변경하고싶어요! 주주총회를 통해 기존 정관을 바꾸려고 하는 경우에 선택해주세요</Link>
                <button type="button" onClick={ArticlesOfAssociationChangeModalVm.articlesOfAssociationChangeForm} style={{width:"300px", height:"100px", border:"1px solid black", display:"inline-block"}}>최초 등록된 정관파일을 바꾸고 싶어요! 잘못된 정관파일이 등록된 경우 선택해주세요</button>
            </div>
        </>
    ));
}
export default ArticlesOfAssociationChangeModal;