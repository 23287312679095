import { useObserver } from 'mobx-react';
import vm from './OnlineAcceptanceVm';
import bst from '@pages/Corp/Agenda/Step/FinalCheck/BeforeFinal/BeforeFinal.module.scss';
import st from './OnlineAcceptance.module.scss';
import clsx from 'clsx';
import QuestionTooltip from '@standby/common-ui/components/molecules/QuestionTooltip/QuestionTooltip';
import Table from '@standby/common-ui/components/atoms/Table/Table';
import ToolTip from '@standby/common-ui/components/molecules/ToolTip/ToolTip';
import type { SectionType, I수신동의 } from '@src/model/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalModel.interface';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import DownloadBtn from '@standby/common-ui/components/atoms/Button/DownloadBtn/DownloadBtn';
import { addCommas } from '@common/module/replaceNumber';

interface OnlineAcceptanceProps {
    index: number;
    type: SectionType;
    info: I수신동의,
    agendaProgressId: string;
}

function OnlineAcceptance(props: OnlineAcceptanceProps) {
    return useObserver(() => (
        <div className={bst.row}>
            <div className={clsx(bst.title, bst.section1)}>{props.index+1}. 수신동의 <span className={bst.light}>(온라인)</span></div>
            <div className={bst.content}>
                <div className={bst.contentTitleBox}>온라인으로 소집통지를 보내려면, 먼저 주주의 동의를 받으세요. <span className={bst.light}>(최초 1회 동의 필요)</span></div>
                <QuestionTooltip title="온라인 소집통지 수신동의 안내" style={{width:'460px'}}>
                    <div className={bst.tooltipContent}>
                        <div className={bst.qna}>
                            <div className={bst.qnaTitle}>주주총회를 열 때마다 10일 ~ 14일 전 우편 소집통지 발송이 원칙</div>
                            <div className={bst.qnaContent}>
                                <ul>
                                    <li>원칙상 정관에 따라 우편으로 소집통지를 보내고, 10~14일을 기다린 후 주주총회를 열 수 있어요.</li>
                                </ul>
                            </div>
                        </div>
                        <div className={bst.qna}>
                            <div className={bst.qnaTitle}>동의를 받으면 우편 외에 온라인으로도 소집통지 발송이 가능해요.</div>
                            <div className={bst.qnaContent}>
                                <ul>
                                    <li>자본금 10억 원 미만의 회사는 주주가 동의하면, 우편이 아니라 온라인으로도 소집통지를 보낼 수가 있어요.</li>
                                    <li>다만 주주의 동의를 받지 않고 온라인으로 소집통지를 보내면, 통지의 효과가 없을 수 있으니 주의가 필요해요.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </QuestionTooltip>
                <Table className={clsx(bst.table, st.table)}>
                    <colgroup>
                        <col width="170" />
                        <col width="107" />
                        <col width="82" />
                        <col width="110" />
                        <col width="152" />
                        <col width="104" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>주주명</th>
                            <th>온라인<br/>소집통지 수신동의</th>
                            <th>동의일자</th>
                            <th style={{textAlign:'center'}} className={clsx(bst.download, props.info?.completed ? bst.active : bst.disabled)}>
                                    <span>다운로드</span>
                                    {props.info?.completed && 
                                        <button type="button" className={bst.allDownloadBtn} onClick={() => vm.allDownload(props.agendaProgressId, props.type)}>모두 다운로드</button>
                                    }
                                </th>
                            <th style={{textAlign:'left'}}>주식종류</th>
                            <th>주식수</th>
                            <th>지분율</th>
                        </tr>
                    </thead>
                    {props.info?.shareholders?.map((shareholder, idx) => {
                        if((idx < 6 || (idx > 5 && vm.state.moreViewYn))) {
                            return (
                                <tbody key={idx}>
                                    {shareholder.stocks.map((stock, idx2) => {
                                        return (
                                            <tr key={idx2}>
                                                {idx2 === 0 &&
                                                <>
                                                    <td rowSpan={shareholder.stocks.length}><span>{idx+1}</span>{shareholder.name}</td>
                                                    <td rowSpan={shareholder.stocks.length}>
                                                        <div className={vm.status(shareholder.status)?._className}>
                                                            {vm.status(shareholder.status)?.text}
                                                            {shareholder.status === "발송실패" && 
                                                                <ToolTip className={bst.tooltip}>
                                                                    일시적 오류가 발생하여 발송에 실패했어요. 이메일, 휴대번호가 정확한지 확인해보고, 재발송을 해주세요. 만약 재발송에도 실패한다면 오른쪽 아래 채팅버튼을 눌러 문의를 남겨주세요.
                                                                </ToolTip>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td rowSpan={shareholder.stocks.length}>{shareholder.completedDate}</td>
                                                    <td rowSpan={shareholder.stocks.length}>
                                                        {vm.displayDownloadBtn(shareholder.status, shareholder.downloadUrl) ? 
                                                            <DownloadBtn disabled={!shareholder.downloadUrl} onClick={() => vm.download(shareholder.downloadUrl)}>동의서 다운로드</DownloadBtn>
                                                        :
                                                            <span className={st.direct}>직접 입력</span>
                                                        }
                                                    </td>
                                                </>
                                                }
                                                <td className={bst.stockName}>{stock.name}</td>
                                                <td className={bst.stockNumber}>{addCommas(stock.number)} 주</td>
                                                <td className={bst.stockRate}>{stock.rate} %</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            )
                        }                        
                    })}
                </Table>
                {props.info?.shareholders?.length > 6 && 
                    <button type="button" className={bst.moreViewBtn} onClick={() => vm.moreView()}><span className={vm.state.moreViewYn ? bst.open : ""}>{vm.state.moreViewYn ? '접기' : '더보기'}</span></button>
                }
                <div className={bst.btnArea}>
                    <SquareBtn className={bst.btn} onClick={() => vm.openModal()}>온라인 소집통지 수신동의 받기</SquareBtn>
                </div>
            </div>
        </div>
    ));
}
export default OnlineAcceptance;