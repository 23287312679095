import { useObserver } from 'mobx-react';
import { Route, withRouter, useLocation } from "react-router-dom";
import { Fragment, useEffect } from 'react';
import queryString from 'query-string';
import StockholderVm from './StockholderVm';
import st from './Stockholder.module.scss';
import clsx from 'clsx';
import PageTitle from '@commonComponents/PageTitle/PageTitle';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Select from '@standby/common-ui/components/atoms/Input/Select/Select';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import TextArea from '@standby/common-ui/components/atoms/Input/TextArea/TextArea';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import CloseBtn from '@standby/common-ui/components/atoms/Button/CloseBtn/CloseBtn';
import AddBtn from '@standby/common-ui/components/atoms/Button/AddBtn/AddBtn';
import regExp from '@common/module/regExp';
import { addCommas, removeCommas, onlyNumber } from '@common/module/replaceNumber';
import ErrorText from '@standby/common-ui/components/atoms/Text/ErrorText/ErrorText';
import Loading from '@commonComponents/Loading/Loading';
import Radio from '@standby/common-ui/components/atoms/Input/Radio/Radio';

function Stockholder() {
    let location = useLocation();
    const shareholderId = queryString.parse(location.search).shareholderId;

    useEffect(() => {
        StockholderVm.load(shareholderId);
    }, [shareholderId]);

    return useObserver(() => (
        <>
            {StockholderVm.state.loadingYn && <Loading />}
            <PageTitle><span className={st.pageTitle}>주주명부</span></PageTitle>
            <ContentBox title={StockholderVm.shareholder.koreaName + ' 주주'} leftSubTitle="주주명부" titleStyle={{top:"15px", paddingLeft:"18px", fontSize:"18px"}} className={st.contentBox}> 
                <form onSubmit={(e) => StockholderVm.submit(e, shareholderId)} className={st.content}>
                    <input type="hidden" name="id" value={StockholderVm.shareholder.id}/>
                    <div className={st.inputRow}>
                        <div className={st.title}>유형</div>
                        <Select name="shareholderType" value={StockholderVm.shareholder.shareholderType} onChange={(e) =>  StockholderVm.setShareholderType(e)} style={{width:'220px'}} errorText={<Error name="shareholderType" value={StockholderVm.shareholder.shareholderType} errorCase={{
                            required: '유형을 선택하세요.'
                        }} />}>
                            <option value="">-</option>
                            <option value="국내_성인">한국인 성인</option>
                            <option value="국내_미성년자">한국인 미성년</option>
                            <option value="국내_법인">국내 법인</option>
                            <option value="펀드_투자조합">펀드/투자조합</option>
                            <option value="해외_성인">외국인 성인</option>
                            <option value="해외_미성년자">외국인 미성년</option>
                            <option value="해외_법인">해외 법인</option>
                        </Select>
                    </div>
                    <div className={st.inputRow}>
                        <div className={st.title}>한글 이름</div>
                        <InputText name="koreanName" value={StockholderVm.shareholder.koreaName} disabled style={{width:'460px'}} />
                    </div>
                    <div className={st.inputRow}>
                        <div className={st.title}>영문 이름</div>
                        <div>
                            <InputText name="englishName" value={StockholderVm.shareholder.englishName} onChange={(e) => StockholderVm.setEnglishName(e)} style={{width:'460px'}} />
                            {(StockholderVm.shareholder.englishName && regExp.onlyEnglish().test(StockholderVm.shareholder.englishName) === false) && <ErrorText>올바른 영문 이름을 입력해주세요.</ErrorText>}
                        </div>
                    </div>
                    {(StockholderVm.shareholder.shareholderType === "해외_성인" || StockholderVm.shareholder.shareholderType === "해외_미성년자") && 
                        <div className={st.inputRow}>
                            <div className={st.title}>국적</div>
                            <div>
                                <Select name="nationalityType" value={StockholderVm.shareholder.nationalityType} onChange={(e) =>  StockholderVm.setNationalityType(e)} style={{width:'140px'}} errorText={<Error name="nationalityType" value={StockholderVm.shareholder.nationalityType} errorCase={{
                                    required: '유형을 선택하세요.'
                                }} />}>
                                    <option value="">-</option>
                                    <option value="한국인">한국인</option>
                                    <option value="일본국인">일본국인</option>
                                    <option value="미합중국인">미합중국인</option>
                                    <option value="영국인">영국인</option>
                                    <option value="중화인민공화국인">중화인민공화국인</option>
                                    <option value="프랑스국인">프랑스국인</option>
                                    <option value="독일국인">독일국인</option>
                                    <option value="호주국인">호주국인</option>
                                    <option value="캐나다국인">캐나다국인</option>
                                    <option value="기타">기타</option>
                                </Select>
                                <InputText name="nationalityOthers" value={StockholderVm.shareholder.nationalityOthers} onChange={(e) => StockholderVm.setNationalityOthers(e)} style={{width:'312px', marginLeft: '8px'}} disabled={StockholderVm.shareholder.nationalityType !== "기타"}/>
                            </div>
                        </div>
                    }
                    {StockholderVm.shareholder.shareholderType === "해외_법인" && 
                        <div className={st.inputRow}>
                            <div className={st.title}>국적</div>
                            <div>
                                <Select name="nationalityType" value={StockholderVm.shareholder.nationalityType} onChange={(e) =>  StockholderVm.setNationalityType(e)} style={{width:'140px'}} errorText={<Error name="nationalityType" value={StockholderVm.shareholder.nationalityType} errorCase={{
                                    required: '유형을 선택하세요.'
                                }} />}>
                                    <option value="">-</option>
                                    <option value="한국법인">한국법인</option>
                                    <option value="일본국법인">일본국법인</option>
                                    <option value="미합중국법인">미합중국법인</option>
                                    <option value="영국법인">영국법인</option>
                                    <option value="중화인민공화국법인">중화인민공화국법인</option>
                                    <option value="프랑스국법인">프랑스국법인</option>
                                    <option value="독일국법인">독일국법인</option>
                                    <option value="호주국법인">호주국법인</option>
                                    <option value="캐나다국법인">캐나다국법인</option>
                                    <option value="기타">기타</option>
                                </Select>
                                <InputText name="nationalityOthers" value={StockholderVm.shareholder.nationalityOthers} onChange={(e) => StockholderVm.setNationalityOthers(e)} style={{width:'312px', marginLeft: '8px'}} disabled={StockholderVm.shareholder.nationalityType !== "기타"}/>
                            </div>
                        </div>
                    }
                    <div className={st.inputRow}>
                        <div className={st.title}>연락처</div>
                        <div>
                            {/* <Select name="countryCode" value={StockholderVm.shareholder.countryCode} disabled style={{width:'85px'}}>
                                <option value="">-</option>
                                <option value="82">+82</option>
                            </Select> */}
                            <div>
                                <InputText name="phoneNumber" value={StockholderVm.shareholder.phoneNumber} onChange={(e) => StockholderVm.setPhoneNumber(e)} style={{width:'220px'}} placeholder="01012345678" className={st.phoneNumber} />
                            </div>
                        </div>
                    </div>
                    <div className={st.inputRow}>
                        <div className={st.title}>이메일</div>
                        <div>
                            <InputText name="email" value={StockholderVm.shareholder.email} onChange={(e) => StockholderVm.setEmail(e)} style={{width:'460px'}} />
                            {(StockholderVm.shareholder.email && regExp.email().test(StockholderVm.shareholder.email) === false) && <ErrorText>올바른 연락처를 입력해주세요.</ErrorText>}
                        </div>
                    </div>
                    {(StockholderVm.shareholder.shareholderType === "해외_성인" || StockholderVm.shareholder.shareholderType === "해외_미성년자") ?
                    <>
                        <div className={st.inputRow}>
                            <div className={st.title}>거소등록<br/>(외국인등록)</div>
                            <div className={st.radioBox}>
                                <Radio name="residenceRegistrationYn" checked={StockholderVm.shareholder.residenceRegistrationYn === true} value={true} onChange={(e) => StockholderVm.setResidenceRegistrationYn(e)}>했음</Radio>
                                <Radio name="residenceRegistrationYn" checked={StockholderVm.shareholder.residenceRegistrationYn === false} value={false} onChange={(e) => StockholderVm.setResidenceRegistrationYn(e)}>안했음</Radio>
                            </div>
                        </div>
                        {StockholderVm.shareholder.residenceRegistrationYn === false && 
                            <div className={st.inputRow}>
                                <div className={st.title}>주소 (로마자)</div>
                                <InputText name="englishAddress" value={StockholderVm.shareholder.englishAddress} onChange={(e) => StockholderVm.setEnglishAddress(e)} style={{width:'460px'}} />
                            </div>
                        }
                        <div className={st.inputRow}>
                            <div className={st.title}>주소 (한글)</div>
                            <InputText name="address" value={StockholderVm.shareholder.address} onChange={(e) => StockholderVm.setAddress(e)} style={{width:'460px'}} />
                        </div>
                    </>
                    : 
                    <div className={st.inputRow}>
                        <div className={st.title}>주소</div>
                        <InputText name="address" value={StockholderVm.shareholder.address} onChange={(e) => StockholderVm.setAddress(e)} style={{width:'460px'}} />
                    </div>
                    }                    
                    <div style={{marginTop: '15px'}}>
                        {StockholderVm.고유번호Type === '주민등록번호' && 
                            <div className={st.inputRow}>
                                <div className={st.title}>주민등록번호</div>
                                <div>
                                    <InputText name="kssn" placeholder="111111-1111111" value={StockholderVm.shareholder.kssn} onChange={(e) => StockholderVm.set고유번호(e, '주민등록번호')} style={{width:'220px'}} kssn />
                                    {(StockholderVm.shareholder.kssn && regExp.kssn(StockholderVm.shareholder.kssn) === false) && <ErrorText>올바른 주민등록번호를 입력해주세요.</ErrorText>}
                                </div>
                            </div>
                        }
                        {StockholderVm.고유번호Type === '법인등록번호' && 
                            <div className={st.inputRow}>
                                <div className={st.title}>법인등록번호</div>
                                <div>
                                    <InputText name="cssn" kssn placeholder="111111-1111111" value={StockholderVm.shareholder.cssn} onChange={(e) => StockholderVm.set고유번호(e, '법인등록번호')} style={{width:'220px'}} />
                                    {(StockholderVm.shareholder.cssn && regExp.cssn().test(StockholderVm.shareholder.cssn) === false) && <ErrorText>올바른 법인등록번호를 입력해주세요.</ErrorText>}
                                </div>
                            </div>
                        }
                        {StockholderVm.고유번호Type === '고유번호' && 
                            <div className={st.inputRow}>
                                <div className={st.title}>고유번호</div>
                                <div>
                                    <InputText name="properNumber" ein placeholder="111-11-11111" value={StockholderVm.shareholder.properNumber} onChange={(e) => StockholderVm.set고유번호(e, '고유번호')} style={{width:'220px'}} />
                                    {(StockholderVm.shareholder.properNumber && regExp.ein().test(StockholderVm.shareholder.properNumber) === false) && <ErrorText>올바른 고유번호를 입력해주세요.</ErrorText>}
                                </div>
                            </div>
                        }
                        {(StockholderVm.고유번호Type === '생년월일' || StockholderVm.고유번호Type === '설립년월일') && 
                        <>
                            <div className={st.inputRow}>
                                <div className={st.title}>{StockholderVm.고유번호Type === '생년월일' ? '생년월일' : '법인설립일'}</div>
                                <div className={st.birthBox}>
                                    <div className={st.year}>
                                        <InputText name="birth.year" style={{width:'80px'}} placeholder="0000" value={StockholderVm.state.birth.year || ""} onChange={(e) => StockholderVm.setBirth(e, 'year')} maxLength="4" /><span className={st.subText}>년</span>
                                    </div>
                                    <div className={st.month}>
                                        <InputText name="birth.month" style={{width:'80px'}} placeholder="00" value={StockholderVm.state.birth.month || ""} onChange={(e) => StockholderVm.setBirth(e, 'month')} maxLength="2" onBlur={(e) => StockholderVm.birthBlur(e,'month')} /><span className={st.subText}>월</span>
                                    </div> 
                                    <div className={st.day}>
                                        <InputText name="birth.day" style={{width:'80px'}} placeholder="00" value={StockholderVm.state.birth.day || ""} onChange={(e) => StockholderVm.setBirth(e, 'day')} maxLength="2" onBlur={(e) => StockholderVm.birthBlur(e, 'day')} /><span className={st.subText}>일</span>
                                    </div>
                                </div>
                            </div>
                            <div style={{marginLeft:'160px'}}>
                                {StockholderVm.birthValidtaion.validation === false && <ErrorText>{StockholderVm.birthValidtaion.text}</ErrorText>}
                            </div>
                        </>
                        }
                    </div>
                    {(StockholderVm.shareholder.shareholderType === '국내_법인' || StockholderVm.shareholder.shareholderType === '펀드_투자조합') && 
                    <>
                        <div className={st.inputRow}>
                            <div className={st.title}>사업자등록번호</div>
                            <div>
                                <InputText name="ein" ein placeholder="111-11-11111" value={StockholderVm.shareholder.ein} onChange={(e) => StockholderVm.setEin(e)} style={{width:'220px'}} />
                                {(StockholderVm.shareholder.ein && regExp.ein().test(StockholderVm.shareholder.ein) === false) && <ErrorText>올바른 사업자등록번호를 입력해주세요.</ErrorText>}
                            </div>
                        </div>
                        <div className={clsx(st.inputRow, st.verticalTop)}>
                            <div className={st.title}>대표 자격, 대표 이름</div>
                            <div>
                                {StockholderVm.shareholder.representatives.map((item, idx) => {
                                    return (
                                        <div key={idx} className={clsx(st.representativeInfo, idx === StockholderVm.shareholder.representatives.length-1 ? st.last : null)}>
                                            <input type="hidden" name={`representatives[${idx}].id`} value={item.id}/>
                                            <div className={st.inputRepresentative}>
                                                <InputText name={`representatives[${idx}].name`} value={item.name} onChange={(e) => StockholderVm.setRepresentativesName(e, idx)} explain="이름*" style={{width:'199px'}} errorText={<Error name={`representatives[${idx}].name`} value={item.name} errorCase={{
                                                    required: '이름을 입력하세요.'
                                                }}/>}/><span className={st.subText}>님</span>
                                            </div>
                                            <Select name={`representatives[${idx}].position`} value={item.position} onChange={(e) => StockholderVm.setRepresentativesPosition(e, idx)} explain="직책*" style={{width:'199px'}} className={st.selectRepresentative} errorText={<Error name={`representatives[${idx}].position`} value={item.position} errorCase={{
                                                required: '직책을 선택하세요.'
                                            }}/>}>
                                                <option value="">-</option>
                                                <option value="대표이사">대표이사</option>
                                                <option value="공동대표이사">공동대표이사</option>
                                                <option value="사내이사">사내이사</option>
                                                <option value="대표_업무집행자">대표 업무집행자</option>
                                                <option value="공동대표_업무집행자">공동대표 업무집행자</option>
                                                <option value="업무집행자">업무집행자</option>
                                                <option value="업무집행조합원">업무집행조합원</option>
                                                <option value="공동업무집행조합원">공동업무집행조합원</option>
                                            </Select>
                                            <CloseBtn onClick={() => StockholderVm.deleteRepresentatives(idx)} className={st.closeBtn}/>
                                            <br/>
                                        </div>
                                    )
                                })}
                                <AddBtn onClick={() => StockholderVm.addRepresentatives()} className={clsx(st.addBtn, StockholderVm.shareholder.representatives.length !== 0 && st.marginTop)}>대표자 추가하기</AddBtn>
                            </div>
                        </div>
                    </>
                    }
                    {StockholderVm.shareholder.shareholderType === '해외_법인' && 
                    <div className={clsx(st.inputRow, st.verticalTop)}>
                        <div className={st.title}>대표 이름</div>
                        <div>
                            {StockholderVm.shareholder.representatives.map((item, idx) => {
                                return (
                                    <div key={idx} className={clsx(st.representativeInfo, idx === StockholderVm.shareholder.representatives.length-1 ? st.last : null)}>
                                        <div className={st.inputRepresentative}>
                                            <InputText name={`representatives[${idx}].englishName`} value={item.englishName} onChange={(e) => StockholderVm.setRepresentativesEnglishName(e, idx)} explain="이름 (로마자)*" style={{width:'226px'}} errorText={<Error name={`representatives[${idx}].englishName`} value={item.englishName} errorCase={{
                                                required: '이름(로마자)을 입력하세요.',
                                                pattern: {
                                                    value: regExp.onlyEnglish().test(item.englishName),
                                                    message: '올바른 영문 이름을 입력하세요.'
                                                }
                                            }}/>}/>
                                            <InputText name={`representatives[${idx}].name`} value={item.name} onChange={(e) => StockholderVm.setRepresentativesName(e, idx)} explain="이름 (한글)*" style={{width:'226px', marginLeft: '8px'}} errorText={<Error name={`representatives[${idx}].name`} value={item.name} errorCase={{
                                                required: '이름(한글)을 입력하세요.'
                                            }}/>}/>
                                        </div>
                                        <CloseBtn onClick={() => StockholderVm.deleteRepresentatives(idx)} className={st.closeBtn}/>
                                        <br/>
                                    </div>
                                )
                            })}
                            <AddBtn onClick={() => StockholderVm.addForeignRepresentatives()} className={clsx(st.addBtn, StockholderVm.shareholder.representatives.length !== 0 && st.marginTop)}>대표자 추가하기</AddBtn>
                        </div>
                    </div>
                    }
                    <div className={st.inputRow}>
                        <div className={st.title}>
                            보유주식
                            <div className={st.explain}>오늘 {StockholderVm.nowDate()} 기준</div>
                        </div>
                        <div className={st.stockInfo}>
                            <div className={st.stockTitle}>
                                <span>일자</span><span>원인</span><span>주식종류</span><span>주식수</span><span>비고</span>
                            </div>
                            <div>
                                {StockholderVm.stock.stocks.map((stock,idx) => {
                                    return (
                                        <div key={idx} className={st.stock}>
                                            <span>{stock.acquisitionAt}</span>
                                            <span>{stock.acquisitionReason}</span>
                                            <span>{stock.stockName}</span>
                                            <span>{addCommas(stock.amount)} 주</span>
                                            <span>
                                            {stock.histories.map((history, idx2) => (
                                                <div>{history.reasonType}인 {history.name} ({addCommas(history.amount)}주)</div>
                                            ))}
                                            {stock.histories.length === 0 &&
                                                <div>-</div>
                                            }
                                            </span>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className={st.stockSum}>
                                <div className={st.sumTitle}>합계</div>
                                <div>
                                    {StockholderVm.stock.classStocks.map((classStock, idx) => {
                                        return (
                                            <div className={st.sum} key={idx}>
                                                <span>{classStock.stockName}</span>
                                                <span>{addCommas(classStock.amount)} <span className={st.light}>주</span></span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={clsx(st.inputRow, st.verticalTop)}>
                        <div className={st.title}>메모</div>
                        <TextArea type="text" name="memo" value={StockholderVm.shareholder.memo} onChange={(e) => StockholderVm.setMemo(e)} style={{width:"460px", minHeight:"64px", resize:"none"}} />
                    </div>
                    <div className={clsx(st.inputRow, st.verticalTop)}>
                        <div className={st.title}>소집통지 온라인<br/>수신동의</div>
                        <InputText name="onlineMusterNoticeStatus" value={StockholderVm.onlineMusterNoticeState() || ''} disabled style={{width:'460px'}} />
                    </div>
                    <div className={clsx(st.inputRow, st.verticalTop)}>
                        <div className={st.title}>동의서 다운로드</div>
                        <div>
                            {StockholderVm.file?.name ? 
                                <a className={st.fileDownload} onClick={() => StockholderVm.fileDownload(StockholderVm.file.downloadUrl)}>{StockholderVm.file.name}</a>
                            : 
                            '-'
                            }
                        </div>
                    </div>
                    <div className={st.btnArea}>
                        <SquareBtn type="submit" className={st.submitBtn}>수정하기</SquareBtn>
                    </div>
                </form>
            </ContentBox>
        </>
        
    ));
}

export default Stockholder;