import { useObserver } from 'mobx-react';
import vm from './RnumberVm';
import Cst from './Rnumber.module.scss';
import st from '../AgendaDashboard.module.scss';
import CopyBtn from '@standby/common-ui/components/atoms/Button/CopyBtn/CopyBtn';
import clsx from 'clsx';

function Rnumber() {
    return useObserver(() => (
        <div className={st.item}>
            <div className={st.title}>헬프미 R 번호</div>
            <div className={st.content} style={{paddingTop:"10px", paddingBottom:"10px"}}>
                <div className={Cst.rNumberList}>
                    {vm.rNumbers.map((rNumber, index) => (
                        <div key={index} className={Cst.rNumberRow}>
                            <div className={Cst.number}>{index + 1}</div>
                            {rNumber.id ? 
                                <>
                                    {rNumber.editYn === false ?
                                        <>
                                            <div className={Cst.copyRnumber}>
                                                <CopyBtn copyText={rNumber.value} className={Cst.copyBtn}>{rNumber.value}</CopyBtn>
                                            </div>
                                            <button type='button' className={Cst.colorBtn} onClick={() => vm.editRnumber(index)}>수정</button>
                                            <button type='button' className={clsx(Cst.colorBtn, Cst.notImportant)} onClick={() => vm.removeRnumber(rNumber.id)}>삭제</button>
                                            {(index + 1 === vm.rNumbers.length && vm.rNumbers.length !== 2) && 
                                                <button type='button' className={clsx(Cst.colorBtn, Cst.notImportant)} onClick={vm.addRnumber}>추가</button>
                                            }
                                        </>
                                        :
                                        <>
                                            <input type='text' className={clsx(Cst.colorInput, Cst.notColor)} value={rNumber.value} onChange={(e) => vm.insertRnumber(e, index)}/>
                                            <button type='button' className={Cst.colorBtn} onClick={() => vm.editSaveRnumber(rNumber.id, rNumber.value)}>저장</button>
                                            {vm.rNumbers.some(rNumber => rNumber.id !== '') &&
                                                <button type='button' className={clsx(Cst.colorBtn, Cst.notImportant)} onClick={() => vm.editRnumber(index)}>취소</button>
                                            }
                                        </>
                                    }
                                </>
                                :
                                <>
                                    <input type='text' className={Cst.colorInput} value={rNumber.value} onChange={(e) => vm.insertRnumber(e, index)}/>
                                    <button type='button' className={Cst.colorBtn} onClick={() => vm.saveRnumber(rNumber.value)}>저장</button>
                                    {vm.rNumbers.some(rNumber => rNumber.id !== '') &&
                                        <button type='button' className={clsx(Cst.colorBtn, Cst.notImportant)} onClick={() => vm.cancelRnumber(index)}>취소</button>
                                    }
                                </>
                            }
                            {(index === 0 && rNumber.id && vm.meetingScheduleChangeLockYn === false) &&   
                                <div className={clsx(Cst.lock, Cst.notLocked)}>
                                    <span className={Cst.lockText}>스탠바이 날짜, 장소수정 잠금해제</span>
                                    <button type='button' className={st.likeLinkBtn} onClick={() => vm.lock("잠금설정 되었습니다.")}>잠금설정</button>
                                </div>
                            }
                            {(index === 0 && rNumber.id && vm.meetingScheduleChangeLockYn === true) &&   
                                <div className={Cst.lock}>
                                    <span className={Cst.lockText}>스탠바이 날짜, 장소수정 잠금</span>
                                    <button type='button' className={st.likeLinkBtn} onClick={() => vm.lock("잠금해제 되었습니다.")}>잠금풀기</button>
                                </div>
                            }
                        </div>
                    ))}
                    {(vm.rNumbers.length === 1 && vm.rNumbers.some(rNumber => rNumber.id)) &&
                        <div className={clsx(st.comment, Cst.comment)}>2번 접수를 위해 진행을 나눈 경우 R 번호를 추가해 주세요.</div>
                    }
                </div>
            </div>
        </div>
    ));
}
export default Rnumber;