import agendaStepModel from '@model/Corp/Agenda/Step/agendaStepModel';
import type {SelectedAgenda, I대표이사_주소_변경} from '@model/Corp/Agenda/Step/agendaStepModel.interface';

class RepresentativeAddressChangeVm {
    selectedAgenda(index : number) {
        return agendaStepModel.selectedAgendas[index] as SelectedAgenda<I대표이사_주소_변경>
    }
    changeAddressExecutives(e : React.ChangeEvent<HTMLInputElement>, agendaIndex : number, index : number) {
        this.selectedAgenda(agendaIndex).agendaDetail.changeAddressExecutives[index].checked = e.target.checked;
    }
}
export default new RepresentativeAddressChangeVm();