import agendaStepModel from '@model/Corp/Agenda/Step/agendaStepModel';
import { runInAction } from "mobx";
import portalModel from '@model/portalModel';
import StockInfoModal from './StockInfoModal/StockInfoModal';
import { addCommas, removeCommas, onlyNumber } from '@common/module/replaceNumber';
import corpModel from "@model/Corp/corpModel";
import type {SelectedAgenda, I신주발행_유상증자, NewSharesAcquirersType} from '@model/Corp/Agenda/Step/agendaStepModel.interface';

class RightsIssueVm {
    selectedAgenda(index : number) {
        return agendaStepModel.selectedAgendas[index] as SelectedAgenda<I신주발행_유상증자>
    }
    openStockContentModal(title : string, stock : number, content : string) {
        runInAction(() => {
            portalModel.title = title;
            portalModel.content = <StockInfoModal stock={stock} content={content}/>;
        })
    }
    setHighType(e: React.ChangeEvent<HTMLSelectElement>, index: number, index2: number) {
        runInAction(() => {
            if(e.target.value === "새로운분") {
                this.selectedAgenda(index).agendaDetail.newSharesAcquirers[index2].highType = e.target.value;
                this.selectedAgenda(index).agendaDetail.newSharesAcquirers[index2].name = "";
                this.selectedAgenda(index).agendaDetail.newSharesAcquirers[index2].type = "";
            } else {
                this.selectedAgenda(index).agendaDetail.newSharesAcquirers[index2].highType = e.target.value;
            }
        })
    }
    personSelect(e : React.ChangeEvent<HTMLSelectElement>, index : number, index2 : number) {
        const newShareholderSelection = this.selectedAgenda(index).agendaDetail.newShareholderSelections.find((newShareholderSelection) => {
            if((newShareholderSelection.id).toString() === e.target.value) {
                return true;
            }
        })
        if(e.target.value === "국내_성인" || e.target.value === "국내_미성년자" || e.target.value === "국내_법인" || e.target.value === "펀드_투자조합" || e.target.value === "해외_성인" || e.target.value === "해외_미성년자" || e.target.value === "해외_법인") {
            const _type = e.target.value as NewSharesAcquirersType;
            runInAction(() => {
                this.selectedAgenda(index).agendaDetail.newSharesAcquirers[index2].type = _type;
                // this.selectedAgenda(index).agendaDetail.newSharesAcquirers[index2].name = ""; 
                this.selectedAgenda(index).agendaDetail.newSharesAcquirers[index2].id = ""; 
                this.selectedAgenda(index).agendaDetail.newSharesAcquirers[index2].newSharesAcquirerId = ""; 
                this.selectedAgenda(index).agendaDetail.newSharesAcquirers[index2].executiveYn = false;
            })
        }else{
            runInAction(() => {
                this.selectedAgenda(index).agendaDetail.newSharesAcquirers[index2].type = '';
                this.selectedAgenda(index).agendaDetail.newSharesAcquirers[index2].newSharesAcquirerId = ""; 
                this.selectedAgenda(index).agendaDetail.newSharesAcquirers[index2].id = e.target.value;
                if(newShareholderSelection) {
                    this.selectedAgenda(index).agendaDetail.newSharesAcquirers[index2].name = newShareholderSelection.name; 
                    this.selectedAgenda(index).agendaDetail.newSharesAcquirers[index2].executiveYn = newShareholderSelection.executiveYn;
                }
            })
        }
    }
    newShareholderSelect(index : number, myId : string) {
        const selectedIds = this.selectedAgenda(index).agendaDetail.newSharesAcquirers.map((newSharesAcquirer) => newSharesAcquirer.id);

        return this.selectedAgenda(index).agendaDetail.newShareholderSelections.filter((newShareholderSelection) => {
            if(selectedIds.includes(newShareholderSelection.id) === false || newShareholderSelection.id === myId) {
                return true;
            }else{
                return false;
            }
        })
    }
    stockHolderName(e : React.ChangeEvent<HTMLInputElement>, index : number, index2 : number) {
        this.selectedAgenda(index).agendaDetail.newSharesAcquirers[index2].name = e.target.value;
    }
    resourceType(e : React.ChangeEvent<HTMLSelectElement>, index : number, index2 : number) {
        this.selectedAgenda(index).agendaDetail.newSharesAcquirers[index2].resourceType = e.target.value;
    }
    stockType(e : React.ChangeEvent<HTMLSelectElement>, index : number, index2 : number, index3 : number) {
        runInAction(() => {
            this.selectedAgenda(index).agendaDetail.newSharesAcquirers[index2].stocks[index3].stockType = e.target.value;
            if(e.target.value === "직접입력") {
                this.selectedAgenda(index).agendaDetail.newSharesAcquirers[index2].stocks[index3].name = "";
            }
        })
    }
    stockName(e : React.ChangeEvent<HTMLInputElement>, index : number, index2 : number, index3 : number) {
        this.selectedAgenda(index).agendaDetail.newSharesAcquirers[index2].stocks[index3].name = e.target.value;
    }
    addStock(index : number, index2 : number, index3 : number) {
        this.selectedAgenda(index).agendaDetail.newSharesAcquirers[index2].stocks.splice(index3+1, 0, {
            "id" : "",
            "stockType": "",
            "name" : "",
            "investment" : "",
            "number" : ""
        })
    }
    deleteStock(index : number, index2 : number, index3 : number) {
        this.selectedAgenda(index).agendaDetail.newSharesAcquirers[index2].stocks.splice(index3, 1)
    }
    investment(e : React.ChangeEvent<HTMLInputElement>, index : number, index2 : number, index3 : number) {
        this.selectedAgenda(index).agendaDetail.newSharesAcquirers[index2].stocks[index3].investment = removeCommas((onlyNumber(e.target.value)).toString());
    }
    number(e : React.ChangeEvent<HTMLInputElement>, index : number, index2 : number, index3 : number) {
        this.selectedAgenda(index).agendaDetail.newSharesAcquirers[index2].stocks[index3].number = removeCommas((onlyNumber(e.target.value)).toString());
    }
    removeSharesAcquirer(index : number, index2 : number) {
        this.selectedAgenda(index).agendaDetail.newSharesAcquirers.splice(index2, 1)
    }
    addSharesAcquirer(index : number) {
        this.selectedAgenda(index).agendaDetail.newSharesAcquirers.push({
            "id" : "",
            "newSharesAcquirerId" : "",
            "executiveYn" : false,
            "name" : "", 
            "type" : '',
            "resourceType": "",
            "stocks" : [{
                "id" : "",
                "stockType": "",
                "name" : "",
                "investment" : "",
                "number" : "",
            }],
            "highType" : ""
        })
    }
    get id() {
        return corpModel.id;
    }
    calculation(investment = 0, number = 0) {
        return addCommas(((investment || 0) / (number  || 0)).toFixed(1).replace('.0',''));
    }
    sum(index : number) {
        let investment = 0;
        let stocks = 0;
        this.selectedAgenda(index).agendaDetail.newSharesAcquirers.forEach((newSharesAcquirer) => {
            newSharesAcquirer.stocks.forEach((stock) => {
                if(stock.investment) {
                    investment += Number(stock.investment)
                }
                if(stock.number) {
                    stocks += Number(stock.number)
                }
            })
        })
        return {investment, stocks}
    }
}
export default new RightsIssueVm();
