import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { useEffect } from 'react';
import DocumentVm from './DocumentVm';

function Document() {
    return useObserver(() => (
        <>
            서류 보는 페이지
        </>
    ));
}
export default Document;