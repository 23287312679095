import { useObserver } from 'mobx-react';
import React from 'react';
import HeadOfficeAddressChangeVm from './HeadOfficeAddressChangeVm';
import qst from '@pages/Corp/Agenda/Step/AgendaQuestion/commonQuestion.module.scss';
import Select from '@standby/common-ui/components/atoms/Input/Select/Select';
import type {SelectedAgenda, I본점의_주소_변경} from '@model/Corp/Agenda/Step/agendaStepModel.interface';

interface HeadOfficeAddressChangeProps {
    agenda : SelectedAgenda<I본점의_주소_변경>,
    agendaIndex : number
}

const HeadOfficeAddressChange = React.memo(function (props : HeadOfficeAddressChangeProps) {
    return useObserver(() => (
        <div className={qst.questionForm}>
            <div className={qst.qusetion}>
                <div className={qst.questionTitle}>신규 본점 주소의 시/군/구를 선택하세요.</div>
                <div>
                    <Select name="agendaDetail.selectedMetropolitanType" value={props.agenda.agendaDetail.selectedMetropolitanType} onChange={(e) => HeadOfficeAddressChangeVm.selectedMetropolitanType(e, props.agendaIndex)} style={{width:"220px"}}>
                        <option value="">시/도</option>
                        {props.agenda.agendaDetail.selectableMetropolitanTypes.map((selectableMetropolitanType, index) => (
                            <option key={index} value={selectableMetropolitanType.metropolitanType}>{selectableMetropolitanType.metropolitanType}</option>
                        ))}
                    </Select>
                    <Select name="agendaDetail.selectedSigungu" value={props.agenda.agendaDetail.selectedSigungu} onChange={(e) => HeadOfficeAddressChangeVm.selectableSigungus(e, props.agendaIndex)} style={{width:"220px", marginLeft:"20px"}}>
                        <option value="">시/군/구</option>
                        {HeadOfficeAddressChangeVm.selectableSigungusList(props.agenda.agendaDetail).map((selectableSigungu, index) => (
                            <option key={index}>{selectableSigungu}</option>
                        ))}
                    </Select>
                </div>
            </div>
        </div>
    ));
})

export default HeadOfficeAddressChange;