import { useObserver } from 'mobx-react';
import HeaderSidebar from '@commonComponents/HeaderSidebar/HeaderSidebar';
import Content from '@commonComponents/Content/Content';
import Box1040 from '@commonComponents/Box1040/Box1040';
import Step1 from '@pages/Corp/InternetAssociate/Step1/Step1';

function InternetAssociate() {

    return useObserver(() => (
        <>  
            <HeaderSidebar companyName='' internetAssociateActiveYn={true}/>
            <Content>
                <Box1040>
                    <Step1 guest={true}/>
                </Box1040>
            </Content>
        </>
    ));
}
export default InternetAssociate;