import { useObserver } from 'mobx-react';
import { Route, Routes } from "react-router-dom";
import Basic from './Basic/Basic';
import Document from './BusinessRegistrationDocument/BusinessRegistrationDocument'


function BusinessRegistration() {
    return useObserver(() => (
        <Routes>
            {/* 사업자등록 */}
            <Route path="/" element={<Basic/>}></Route>
            <Route path="/document/*" element={<Document/>}></Route>
        </Routes>
    ));
}
export default BusinessRegistration;