import { useObserver } from 'mobx-react';
import { Route, Routes, withRouter } from "react-router-dom";
import { useEffect } from 'react';
import StockVm from './StockVm';

import Home from './Home/Home';
import UnissuedStockCertificate from './UnissuedStockCertificate/UnissuedStockCertificate';
import ShareholderList from './ShareholderList/ShareholderList';
import OnlineMusterDirect from './OnlineMuster/OnlineMusterDirect/OnlineMusterDirect';
import OnlineMusterBeforehand from './OnlineMuster/OnlineMusterBeforehand/OnlineMusterBeforehand';
import Stockholder from './Stockholder/Stockholder';

function Stock() {
    return useObserver(() => (
        <Routes>
            {/* 주주/주식 */}
            <Route path="/home" element={<Home/>}></Route>
            {/* 주권미발행확인서*/}
            <Route path="/unissuedStockCertificate" element={<UnissuedStockCertificate/>}></Route>
            {/* 주주명부 */}
            <Route path="/shareholderList" element={<ShareholderList/>}></Route>
            {/* 전자소집통지 동의받기 */}
            <Route path="/onlineMuster/direct" element={<OnlineMusterDirect/>}></Route>
            {/* 온라인 소집통지 동의받기  */}
            <Route path="/onlineMuster/beforehand" element={<OnlineMusterBeforehand/>}></Route>
            {/* 주주 개인정보 */}
            <Route path="/stockholder" element={<Stockholder/>}></Route>
        </Routes>
    ));
}

export default Stock;