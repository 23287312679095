import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { useEffect } from 'react';
import vm from './ProgressPageVm';
import st from './ProgressPage.module.scss';
import clsx from 'clsx';
import Check from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import josa from '@common/module/josa';
import ErrorText from '@standby/common-ui/components/atoms/Text/ErrorText/ErrorText';

function ProgressPage() {
    
    useEffect(() => {
        vm.load();
    }, []);

    return useObserver(() => (
        <>
            <div className={st.row}>
                <div className={st.rowTitle}>
                    <span className={st.bold}>주요계약조건</span>
                </div>
                <div className={st.content}>
                    <div className={st.contract}>
                        <div className={st.contractInfo}>기장계약 회계법인 : {vm.accountingFirmName}</div>
                        <div className={st.contractInfo}>기장계약 시작일 : {vm.bookkeepingServiceStartAt.year}-{vm.bookkeepingServiceStartAt.month}-{vm.bookkeepingServiceStartAt.day}</div>
                        {vm.freeBookkeepingServiceYn && <div className={st.contractInfo}>{vm.freeMonth}개월 무료기장 : <span className={st.bold}>~{vm.freeBookkeepingServiceEndAt.year}-{vm.freeBookkeepingServiceEndAt.month}-{vm.freeBookkeepingServiceEndAt.day} 까지 무료!</span></div>}
                        <div className={st.contractInfo}>월 기장료 : {vm.bookkeepingServiceFee}만 원/월 <span className={st.small}>(부가세 별도)</span></div>
                        {vm.bookkeepingContractFile && 
                            <div className={st.contractInfo}>
                                기장계약서 다운로드 <button type="button" className={st.downloadBtn} onClick={() => vm.fileDownload(vm.bookkeepingContractFile)}>다운로드</button>
                            </div>
                        }
                        {((!vm.bookkeepingContractFile && vm.delegationRegistrationOnlyYn === false) || vm.bookkeepingSignatureActivationYn === true) && 
                        <>
                            <div className={st.contractExplain}>매출액/자산이 5억 원 이상인 경우 월 기장료가 달라질 수 있어요.</div>
                            <button type="button" className={st.electronicSignatureBtn} onClick={() => vm.electronicSignature()}><span className={st.text}>전자서명 하러가기</span></button>
                        </>
                        }
                        {(vm.delegationRegistrationOnlyYn && !vm.delegationRegistrationAgreeYn) && 
                            <div className={st.agreeBox}>
                                <div className={st.agreeContent}>
                                    진평회계법인에서 기장계약 이용등록 요청을 했어요.<br/>
                                    등록을 진행할까요?
                                    <Check className={st.checkBox} name="agreeYn" value={vm.state.agreeYn} onChange={(e) => vm.setAgreeYn(e)}>진평회계법인에 대한 <button type="button" className={st.agreeModal} onClick={vm.agreeModal}>정보제공 동의</button></Check>
                                </div>
                                <SquareBtn type="button" className={st.registerBtn} onClick={() => vm.register()}>등록 진행하기</SquareBtn>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {vm.cmsAccountSignatureActivationYn && 
                <div className={st.row}>
                    <div className={st.rowTitle}>
                        <span className={st.bold}>기장료 결제</span>
                    </div>
                    <div className={st.content}> 
                        <div className={st.payment}>
                            <div className={st.paymentInfo}>기장료는 CMS 계좌자동이체 방식으로 진행되어요.</div>
                            {vm.cmsAccountInputDeadline.year && 
                                <div className={st.paymentInfo}>계좌정보를 {vm.cmsAccountInputDeadline.year}-{vm.cmsAccountInputDeadline.month}-{vm.cmsAccountInputDeadline.day} 까지 등록해주세요.</div>
                            }
                            {vm.cmsDirectDebitApplicationFile ? 
                            <>
                                <div className={st.cmsDownload}>CMS 등록계약서 다운로드<button type="button" className={st.downloadBtn} onClick={() => vm.fileDownload(vm.cmsDirectDebitApplicationFile)}>다운로드</button></div>
                                <button type="button" className={st.changeAccountBtn} onClick={() => vm.accountRegistration()}>CMS 등록계좌 변경하기</button>
                            </>
                            :
                                <button type="button" className={st.accountRegistrationBtn} onClick={() => vm.accountRegistration()}>계좌정보 등록하기</button>    
                            }
                        </div>
                    </div>
                </div>
            }
            <div className={st.row}>
                <div className={st.rowTitle}>
                    <span className={st.bold}>진평회계법인 소개</span>
                </div>
                <div className={st.content}> 
                    <div className={st.advertisement}>
                        <img className={st.img} src='/images/진평_example.png' />        
                        <div className={st.jinpyeong}>
                            <div className={st.jinpyeongTitle}>서울대 경영대 출신의 회계사들이 만든 <span className={st.bold}>스타트업 전문 회계법인</span></div>
                            <div className={st.jinpyeongInfo}><span className={st.boldColor}>서울대 경영대</span> 출신 경력 14년 회계사가 직접 관리</div>
                            <div className={st.jinpyeongInfo}>절세를 고려한 <span className={st.boldColor}>최적의 비용처리 및 세액감면/공제 적용</span></div>
                            <div className={st.jinpyeongInfo}><span className={st.boldColor}>투자유치, 상장</span>을 대비한 지분구조 및 회계처리</div>
                            <img src='/images/logo_진평회계법인.svg' className={st.jinpyeongLogoImg}/>
                            <a type="button" className={st.showInfoLink} href="https://jinpyung.com" target="_blank">진평회계법인 소개</a>
                        </div>                
                    </div>
                </div>
            </div>
            {vm.freeBookkeepingServiceYn && 
                <div className={st.row}>
                    <div className={st.rowTitle}>
                        <span className={st.bold}>{josa(vm.companyName, '을를')}<br/>위한 혜택</span>
                    </div>
                    <div className={st.content}> 
                        <div className={st.companyBenefit}>
                            <div className={st.benefit}>기장계약 시작일 : {vm.bookkeepingServiceStartAt.year}-{vm.bookkeepingServiceStartAt.month}-{vm.bookkeepingServiceStartAt.day}</div>
                            <div className={st.benefit}>{vm.freeMonth}개월 무료기장 : <span className={st.bold}>~{vm.freeBookkeepingServiceEndAt.year}-{vm.freeBookkeepingServiceEndAt.month}-{vm.freeBookkeepingServiceEndAt.day} 까지 무료!</span></div>
                            <div className={st.serviceBox}>
                                <div className={st.serviceBenefitBox}>
                                    <div className={clsx(st.benefitBox, st.helpme)}>
                                        <span className={st.bold}>헬프미</span> 설립 Only
                                    </div>
                                    <div className={clsx(st.benefitBox, st.emptyCalendar)}>
                                        <span className={st.freeMonth}>{vm.freeMonth}</span>
                                        <span className={st.bold}>{vm.freeMonth}개월</span> 무료
                                    </div>
                                    <div className={clsx(st.benefitBox, st.calendar)}>
                                        <span className={st.bold}>언제든</span> 해지 가능
                                    </div>
                                </div>
                                <div className={st.serviceDeadline}>
                                    무료 기장서비스는 헬프미 고객을 위한 특별한 혜택이기 때문에,<br/><span className={st.bold}>법인설립일로부터 10일간</span>만 신청이 가능해요. 양해부탁드려요.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className={st.row}>
                <div className={st.rowTitle}>
                    <span className={st.bold}>서비스 내용</span>
                </div>
                <div className={st.content}> 
                    <div className={st.service}>
                        <div className={st.serviceInfo}>
                            <div className={st.serviceTitle}>4대보험 및 급여 관련</div>
                            <ul className={st.serviceList}>
                                <li>사업장 및 근로자 4대보험 가입</li>
                                <li>급여 계산, 급여대장 작성 및 급여명세서 교부</li>
                                <li>퇴직시 퇴직금 계산 및 정산/4대 보험 상실 처리</li>
                            </ul>
                        </div>
                        <div className={st.serviceInfo}>
                            <div className={st.serviceTitle}>결산 및 재무제표 작성</div>
                            <ul className={st.serviceList}>
                                <li>복식부기에 의한 회계처리</li>
                                <li>분기별/반기별/연도별 결산 후 손익계산서/재무상태표/이익잉여금처분계산서 등 작성</li>
                            </ul>
                        </div>
                        <div className={st.serviceInfo}>
                            <div className={st.serviceTitle}>원천세/부가세/법인세 신고 및 납부</div>
                            <ul className={st.serviceList}>
                                <li>매월 급여 관련 원천세 신고 및 납부</li>
                                <li>분기별 부가세 신고 및 납부</li>
                                <li>연도별 법인세 신고 및 납부</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    ));
}
export default ProgressPage;