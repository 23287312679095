import { runInAction, observable, toJS } from "mobx";
import formDataToJson from '@common/module/submit';
import userSettingService from '@service/userSettingService';
import { _alert, _confirm } from "@model/dialogModel";
import { pageRouter } from '@model/pageModel';
import regExp from '@common/module/regExp';

class ChangeEmailVm {
    constructor() {
        this.state = observable({
            email: ""
        })
    }
    setEmail(e) {
        this.state.email = regExp.deleteSpace(e.target.value);
    }
    async submit(e, token) {
        e.preventDefault();
        if(formDataToJson(e.target).error) {
            return
        } else {
            let param = formDataToJson(e.target).data;
            await userSettingService.changeEmail(param, token);
            await _alert('이메일이 변경되었습니다.');
            pageRouter.replace(`/auth/login`);
        }
    }
}
export default new ChangeEmailVm();