import MeetingOfShareholdersModel from "@src/model/Corp/Agenda/Step/FinalCheck/BeforeFinal/MeetingOfShareholders/MeetingOfShareholdersModel";
import { runInAction } from "mobx";
import formDataToJson from '@common/module/submit';
import { _alert } from "@model/dialogModel";
import MeetingOfShareholdersService from "@src/service/Corp/Agenda/Step/FinalCheck/BeforeFinal/MeetingOfShareholders/MeetingOfShareholdersService";

class Step1Vm {
    async submit(e : React.FormEvent<HTMLFormElement>, companyId : string, agendaProgressId : string) {
        e.preventDefault();
        if(formDataToJson(e.target).error) {
            return
        } else {
            let param = formDataToJson(e.target).data as {
                chairmanId : string,
                attendMeetingShareholderMembers : string | string[],
                attendMeetingExecutiveMembers : string | string[],
                minutesId : string
            };
            if(this.attendMeetingPossibleYn === false) {
                if(this.step1.normalMeetingYn) { // 일반결의일때
                    return _alert('출석한 주주가 부족해요.', '주주총회 일반결의를 하려면, 발행주식총수의 1/4 이상이 출석해야 해요. (의결권 있는 주식 기준)');
                }else { // 특별결의 일때
                    return _alert('출석한 주주가 부족해요.', '주주총회 특별결의를 하려면, 발행주식총수의 1/3이상이 출석해야 해요. (의결권 있는 주식 기준)');
                }
            }
            if(Array.isArray(param.attendMeetingExecutiveMembers) === false) {
                param.attendMeetingExecutiveMembers = [param.attendMeetingExecutiveMembers as string]
            }
            if(Array.isArray(param.attendMeetingShareholderMembers) === false) {
                param.attendMeetingShareholderMembers = [param.attendMeetingShareholderMembers as string]
            }
            await MeetingOfShareholdersService.saveStep1(param, companyId, agendaProgressId)
        }
    }
    get step1() {
        return MeetingOfShareholdersModel.step1; 
    }
    selectableChairmen(e : React.ChangeEvent<HTMLInputElement>) {
        const id = e.target.value
        
        runInAction(() => {
            this.step1.chairmanId = id;
            //참석의장을 춠석임원에 체크함
            MeetingOfShareholdersModel.step1.executiveMembers.forEach((item) => {
                if(item.id === id) {
                    item.attendMeetingYn = true;
                }
            })
            MeetingOfShareholdersModel.step1.shareholders.forEach((item) => {
                if(item.executiveId === id) {
                    item.attendMeetingYn = true;
                }
            })
        })
    }
    get executiveMembersAttendMeetingAll() {
        let isCheckAll = true;
        let agreeCnt = 0;
        MeetingOfShareholdersModel.step1.executiveMembers.forEach((item) => {
            if(item.attendMeetingYn) {
                agreeCnt++;
            } else if(!item.attendMeetingYn) {
                isCheckAll = false;
            }
        })
        if(agreeCnt === 0){
            return false;
        }
        return isCheckAll;
    }
    setExecutiveMembersAttendMeetingAll(e : React.ChangeEvent<HTMLInputElement>) {
        const value = e.target.checked;
        runInAction(() => {
            MeetingOfShareholdersModel.step1.executiveMembers.forEach((item) => {
                item.attendMeetingYn = value;
            })
            if(value === false) {
                this.step1.chairmanId = '';
            }
            this.step1.shareholders.forEach((item) => {
                if(item.executiveId) {
                    item.attendMeetingYn = e.target.checked;
                }
            })
        })
    }
    executiveMembersAttendMeeting(e : React.ChangeEvent<HTMLInputElement>, index : number) {
        const checked = e.target.checked
        const id = (this.step1.executiveMembers[index].id || '').toString()
        runInAction(() => {
            this.step1.executiveMembers[index].attendMeetingYn = checked;
            //임원출석여부를 체크 해제했을때 그사람이 참석 의장이면 의장에서도 체크를 해제함
            if(checked === false && this.step1.chairmanId === id) {
                this.step1.chairmanId = '';
            }
            this.step1.shareholders.forEach((item) => {
                if(item.executiveId === id) {
                    item.attendMeetingYn = e.target.checked;
                }
            })
        })
    }
    get shareholdersAttendMeetingAll() {
        let isCheckAll = true;
        let agreeCnt = 0;
        MeetingOfShareholdersModel.step1.shareholders.forEach((item) => {
            if(item.attendMeetingYn) {
                agreeCnt++;
            } else if(!item.attendMeetingYn) {
                isCheckAll = false;
            }
        })
        if(agreeCnt === 0){
            return false;
        }
        return isCheckAll;
    }
    setShareholdersAttendMeetingAll(e : React.ChangeEvent<HTMLInputElement>) {
        const value = e.target.checked;
        runInAction(() => {
            this.step1.shareholders.forEach((item) => {
                item.attendMeetingYn = value;
            })
            if(value === false) {
                this.step1.chairmanId = '';
            }
            this.step1.executiveMembers.forEach((item) => {
                this.step1.shareholders.forEach((shareholder) => {
                    if(shareholder.executiveId === item.id) {
                        item.attendMeetingYn = value
                    }
                })
            })
        })
    }
    shareholdersAttendMeeting(e : React.ChangeEvent<HTMLInputElement>, index : number) {
        const checked = e.target.checked;
        const id = (this.step1.shareholders[index].executiveId || '').toString();
        runInAction(() => {
            this.step1.shareholders[index].attendMeetingYn = checked;
            //임원출석여부를 체크 해제했을때 그사람이 참석 의장이면 의장에서도 체크를 해제함
            if(checked === false && this.step1.chairmanId === id) {
                this.step1.chairmanId = '';
            }
            this.step1.executiveMembers.forEach((item) => {
                if(item.id === id) {
                    item.attendMeetingYn = checked;
                }
            })
        })
    }
    selectPosition(id : string) {
        let position = '';
        this.step1.executiveMembers.forEach((item) => {
            if((item.id).toString() === (id).toString()) {
                position = item.position;
            }
        })
        return position;
    }
    get attendMeetingTotal() {
        let count = 0;
        let totalCount = 0;
        this.step1.shareholders.forEach((item) => {
            item.stocks.forEach((stock) => {
                if(item.attendMeetingYn === true) {
                    count += stock.amount;
                }
                totalCount += stock.amount;
            })
        })
        return {
            count : count,
            percent : (count / totalCount * 100).toFixed(2).replace('.00',''),
            totalCount : totalCount
        };
    }
    get attendMeetingPossibleYn() {
        let possibleYn = false;
        let totalCount = this.attendMeetingTotal.totalCount;
        let checkTotal = this.attendMeetingTotal.count;
        if(this.step1.normalMeetingYn) { // 일반결의일때
            if(totalCount/4 <= checkTotal) {
                return true
            }
        }else { // 특별결의 일때
            if(totalCount/3 <= checkTotal) {
                return true
            }
        }
        return possibleYn;
    }
}
export default new Step1Vm();