import { observable, makeObservable } from 'mobx';
import type { dateListModelInterface, DateInfo } from './dateListModel.interface';

class dateListModel implements dateListModelInterface {
    constructor() {
        makeObservable(this, {
            musterNoticeType: observable,
            shareholdersMeetingNoticePeriodShorteningAOA: observable,
            processStatus: observable,
            dateList : observable,
            meetingScheduleChangeLockYn : observable,
            newStockResourceTypes: observable
        })
    }
    onlyRepresentativeChangedAddressYn: boolean = false;
    hasCapitalOverBillionYn: boolean = false;
    musterNoticeType: string = ""; 
    shareholdersMeetingNoticePeriodShorteningAOA: string = "";
    processStatus: string = "";
    dateList: DateInfo[] = [];
    meetingScheduleChangeLockYn ?: boolean = false;
    newStockResourceTypes: string[] = [];
}

export default new dateListModel();
