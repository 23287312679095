import { runInAction, observable, toJS } from "mobx";
import authService from '@service/authService'
import additionalInformationModel from '@model/Auth/additionalInformationModel';
import formDataToJson from '@common/module/submit';
import st from './AdditionalInformation.module.scss';
import regExp from '@common/module/regExp';
import { onlyNumber } from '@common/module/replaceNumber';
import ReactGA from 'react-ga4';

class viewModel {
    async submit(e, token) {
        e.preventDefault();
        if(formDataToJson(e.target).error) {
            return
        } else {
            let param = formDataToJson(e.target).data;
            //전화번호 010-1234-1234 or 01012341234 로 넘어온 경우 1012341234 가 될 수 있도록 처리
            const deleteDash = /-/gi;
            param.phoneNumber = param.phoneNumber.replace(deleteDash, "");
            if(param.phoneNumber.charAt(0) === "0") {
                param.phoneNumber = param.phoneNumber.slice(1);
            }
            if(param.passwordCheck) { //비밀번호 입력확인 데이터 삭제
                delete param.passwordCheck;
            }
            await authService.additionalInformation(param, token);
            //마케팅 구글에널리틱스 관련 코드
            ReactGA.gtag('event', 'complete_signup');
        }
    }
    loadEmailData(token) {
        authService.loadEmailData(token);
    }   
    get email() {
        return additionalInformationModel.email;
    }
    get password() {
        return additionalInformationModel.password;
    }
    setPassword(e) {
        runInAction(() => {
            additionalInformationModel.password = regExp.deleteSpace(e.target.value);
        })
    }
    get passwordCheck() {
        return additionalInformationModel.passwordCheck;
    }
    setPasswordCheck(e) {
        runInAction(() => {
            additionalInformationModel.passwordCheck = regExp.deleteSpace(e.target.value);
        })
    }
    get name() {
        return additionalInformationModel.name;
    }
    setName(e) {
        runInAction(() => {
            additionalInformationModel.name = e.target.value;
        })
    }
    get countryCode() {
        return additionalInformationModel.countryCode;
    }
    get phoneNumber() {
        return additionalInformationModel.phoneNumber;
    }
    setPhoneNumber(e) {
        runInAction(() => {
            additionalInformationModel.phoneNumber = regExp.deleteSpace(onlyNumber(e.target.value));
        })
    }
    maxLengthIcon(data) {
        if(data.length && data.length >= 8) {
            return {
                st: st.checkActive,
                icon: 'checkActive'
            };
        } else if(data.length && data.length < 8) {
            return {
                st: st.checkError,
                icon: 'checkError'
            };
        } else {
            return {
                st: st.check,
                icon: 'check'
            };
        }
    }   
    passwordRegExpIcon(data) {
        const passwordRegExp = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[`~!@#\$%\^&\*\(\)\{\}\[\]\-_=\+\\|;:'"<>,\./\?])[A-Za-z\d`~!@#\$%\^&\*\(\)\{\}\[\]\-_=\+\\|;:'"<>,\./\?]{8,16}$/;
        if(data.length && passwordRegExp.test(data)) {
            return {
                st: st.checkActive,
                icon: 'checkActive'
            };
        } else if(data.length && passwordRegExp.test(data) === false) {
            return {
                st: st.checkError,
                icon: 'checkError'
            };
        } else {
            return {
                st: st.check,
                icon: 'check'
            };
        }
    }
    isSame() {
        if(this.password === this.passwordCheck) {
            return true;
        } else {
            return false;
        }
    }
}

export default new viewModel();