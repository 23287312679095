import { runInAction, observable, toJS } from "mobx";
import authBusinessRegistrationService from '@service/authBusinessRegistrationService.js';
import joinModel from '@model/AuthBusinessRegistration/joinModel';
import portalModel from '@model/portalModel';
import formDataToJson from '@common/module/submit';
import TermsModal from '@pages/Auth/TermsModal/TermsModal';
import regExp from '@common/module/regExp';
import { onlyNumber } from '@common/module/replaceNumber';

class JoinBusinessRegistrationVm {
    constructor() {
        this.state = observable({
            action: false,
            name: "",
            phoneNumber: "",
            password : "",
            passwordCheck : "",
            terms : [],
            changeNameState: false,
            changePhoneNumberState: false,
            cancelYn : false,
            loadingYn: false
        })
    }
    async submit(e, token) {
        e.preventDefault();
        if(formDataToJson(e.target).error) {
            return
        } else {
            let param = formDataToJson(e.target).data;
            param.submittedTermRequests = [];
            this.state.terms.forEach((term) => {
                param.submittedTermRequests.push({
                    id: term.id,
                    agreeYn: term.checked
                })
            })
            const deleteDash = /-/gi;
            param.phoneNumber = param.phoneNumber.replace(deleteDash, "");
            if(param.phoneNumber.charAt(0) === "0") {
                param.phoneNumber = param.phoneNumber.slice(1);
            }
            if(param.passwordCheck) { //비밀번호 입력확인 데이터 삭제
                delete param.passwordCheck;
            }
            try {
                this.state.loadingYn = true;
                await authBusinessRegistrationService.businessRegistration(param, token);
                this.state.loadingYn = false;
            } catch(error) {
                this.state.loadingYn = false;
            }
        }
    }
    async load(token) {
        try {
            await authBusinessRegistrationService.loadBusinessRegistration(token);
            this.state.name = this.name;
            this.state.phoneNumber = this.phoneNumber;
        } catch (e) {
            if(e?.response?.status === 403) {
                this.state.cancelYn = true;
            }
        }
    }
    get email() {
        return joinModel.email;
    }
    get name() {
        return joinModel.name;
    }
    setName(e) {
        this.state.name = e.target.value;
    }
    changeName() {
        if(this.state.name) {
            runInAction(() => {
                joinModel.name = this.state.name;
                this.nameState();
            })
        } 
    }
    nameState() {
        this.state.changeNameState = !this.state.changeNameState;
    }
    get phoneNumber() {
        return joinModel.phoneNumber;
    }
    setPhoneNumber(e) {
        this.state.phoneNumber = regExp.deleteSpace(onlyNumber(e.target.value));
    }
    changephoneNumber() {
        runInAction(() => {
            joinModel.phoneNumber = this.state.phoneNumber;
            this.phoneNumberState();
        })
    }
    phoneNumberState() {
        this.state.changePhoneNumberState = !this.state.changePhoneNumberState;
    }
    changePassword(e) {
        this.state.password = regExp.deleteSpace(e.target.value);
    }
    changePasswordCheck(e) {
        this.state.passwordCheck = regExp.deleteSpace(e.target.value);
    }
    async loadTerms() {
        const terms = await authBusinessRegistrationService.loadTerms('HELP_ME_REGISTER');
        terms.userRegistrationTerms.forEach((term) => {
            term.checked = false
        });
        this.state.terms = terms.userRegistrationTerms;
    }
    agreeAll(e) {
        this.state.terms.forEach((term) => {
            term.checked = e.target.checked
        });
    }
    get isAgreeAll() {
        if(this.state.terms.length === 0) {
            return false;
        }
        let checkedAll = true;
        this.state.terms.forEach((term) => {
            if(term.checked === false) {
                checkedAll = false;
            }            
        })
        return checkedAll;
    }
    get isSavePossible() {
        let agree = true;
        if(this.state.terms.length === 0) {
            return false;
        }
        this.state.terms.forEach((term) => {
            if(term.checked === false && term.required) {
                agree = false;
            }            
        })
        return agree;
    }
    agreeCheck(e, index) {
        this.state.terms[index].checked = e.target.checked;
    }
    openAgreeModal(title, content) {
        runInAction(() => {
            portalModel.title = title;
            portalModel.content = <TermsModal content={content}/>;
        })
    }
    
}
export default new JoinBusinessRegistrationVm();