import { observable } from 'mobx';

const registerModel = observable({
    guides : [],
    companyCopyList : [],
    setCompanyCopyList(data) {
        this.guides = data?.guides.map((guide) => {
            return {
                title : guide?.title || "",
                contents : guide?.contents || "",
                url : guide?.url || "",
            }
        })
        this.companyCopyList = data?.companyCopyList.map((companyCopy) => {
            return {
                downloadDateTime : companyCopy?.downloadDateTime || "",
                name : companyCopy?.name || "",
                downloadUrl : companyCopy?.downloadUrl || "",
                firstPageUrl : companyCopy?.firstPageUrl || ""
            }
        })
    }
});

export default registerModel;