import agendaStepModel from '@model/Corp/Agenda/Step/agendaStepModel';
import type {SelectedAgenda, I등기가_불필요한_정관변경} from '@model/Corp/Agenda/Step/agendaStepModel.interface';

class RegistratioUnnecessaryChangeAOAVm {
    selectedAgenda(index : number) {
        return agendaStepModel.selectedAgendas[index] as SelectedAgenda<I등기가_불필요한_정관변경>
    }
    regulationLocation(e : React.ChangeEvent<HTMLInputElement>, index : number, index2 : number) {
        this.selectedAgenda(index).agendaDetail.articlesOfAssociations[index2].regulationLocation = e.target.value;
    }
    content(e : React.ChangeEvent<HTMLTextAreaElement>, index : number, index2 : number) {
        this.selectedAgenda(index).agendaDetail.articlesOfAssociations[index2].content = e.target.value;
    }
    add(index : number) {
        this.selectedAgenda(index).agendaDetail.articlesOfAssociations.push({
            id : null,
            regulationLocation : "",
            content : ""
        })
    }
    delete(index : number, index2 : number) {
        this.selectedAgenda(index).agendaDetail.articlesOfAssociations.splice(index2, 1);
    }
}
export default new RegistratioUnnecessaryChangeAOAVm();