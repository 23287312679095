import type { LoadRs, SaveStep1Rq, step2LoadRs, SaveStep2Rq } from './MeetingOfShareholdersService.interface';
import { get, post } from '@common/module/httpRequest';
import { runInAction } from 'mobx';
import MeetingOfShareholdersModel from "@src/model/Corp/Agenda/Step/FinalCheck/BeforeFinal/MeetingOfShareholders/MeetingOfShareholdersModel";
import { _alert } from "@model/dialogModel";

class MeetingOfShareholdersService {
    async load(companyId : string, agendaProgressId : string, minutesId : string) {
        const res = await get<LoadRs>(`/company/${companyId}/agendaProgress/${agendaProgressId}/beforeFinal/minutes/meetingOfShareholders`, { minutesId : minutesId });
        
        runInAction(() => {
            MeetingOfShareholdersModel.step1 = {
                chairmanId : res.data.chairmanId ? (res.data.chairmanId).toString() : '',
                normalMeetingYn : res.data.normalMeetingYn || false,
                selectableChairmen : res.data.selectableChairmen.map((selectableChairmen) => {
                    return {
                        id : selectableChairmen.id ? (selectableChairmen.id).toString() : '',
                        position : selectableChairmen.position || '',
                        name : selectableChairmen.name || ''
                    }
                }),
                executiveMembers : res.data.executiveMembers.map((executiveMember) => {
                    return {
                        id : executiveMember.id ? (executiveMember.id).toString() : '',
                        position : executiveMember.position || '',
                        name : executiveMember.name || '',
                        attendMeetingYn : executiveMember.attendMeetingYn || false
                    }
                }),
                shareholders : res.data.shareholders.map((shareholder) => {
                    return {
                        id : shareholder.id ? (shareholder.id).toString() : '',
                        executiveId : shareholder.executiveId ? (shareholder.executiveId).toString() : '',
                        name : shareholder.name || '',
                        attendMeetingYn : shareholder.attendMeetingYn || false,
                        stocks : shareholder.stocks.map((stock) => {
                            return {
                                name : stock.name || "",
                                amount : stock.amount || 0,
                                percentageOwnership : stock.percentageOwnership || ""
                            }
                        })
                    }
                })
            }
            MeetingOfShareholdersModel.nowStep = '1'
        })
    }
    async step2Load(companyId : string, agendaProgressId : string, minutesId : string) {
        const res = await get<step2LoadRs>(`/company/${companyId}/agendaProgress/${agendaProgressId}/beforeFinal/minutes/meetingOfShareholders/agenda`, { minutesId : minutesId });
        
        runInAction(() => {
            MeetingOfShareholdersModel.step2 = {
                totalStockAmount : res.data.totalStockAmount || 0,
                agendaList : res.data.agendaList.map((agenda) => {
                    return {
                        type : agenda.type,
                        title : agenda.title || '',
                        normalMeetingYn : agenda.normalMeetingYn || false,
                        shareholders : agenda.shareholders.map((shareholder) => {
                            return {
                                id : shareholder.id ? (shareholder.id).toString() :'',
                                name : shareholder.name || '',
                                position : shareholder.position || '',
                                status : shareholder.status || '',
                                stocks : shareholder.stocks.map((stock) => {
                                    return {
                                        name : stock.name || '',
                                        amount : stock.amount || 0,
                                        percentageOwnership : stock.percentageOwnership || ''
                                    }
                                })
                            }
                        })
                    }
                })
            }
        })
    }
    async saveStep1(param : SaveStep1Rq, companyId : string, agendaProgressId : string) {
        await post(`/company/${companyId}/agendaProgress/${agendaProgressId}/beforeFinal/minutes/meetingOfShareholders`, param);
        runInAction(() => {
            MeetingOfShareholdersModel.nowStep = '2'
        })
    }
    async saveStep2(param : SaveStep2Rq, companyId : string, agendaProgressId : string) {
        await post(`/company/${companyId}/agendaProgress/${agendaProgressId}/beforeFinal/minutes/meetingOfShareholders/agenda`, param);
        await  _alert('선택 완료되었어요', '자동작성된 의사록을 다운로드 받으세요.', undefined, undefined, 'loud');
        window.location.reload();
    }
}
export default new MeetingOfShareholdersService();