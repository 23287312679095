import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { Fragment, useEffect } from 'react';
import OnlineMusterBeforehandVm from './OnlineMusterBeforehandVm';
import { addCommas } from '@common/module/replaceNumber';

function OnlineMusterBeforehand() {

    useEffect(() => {
        OnlineMusterBeforehandVm.load();
    }, []);
    
    return useObserver(() => (
        <form onSubmit={(e) => OnlineMusterBeforehandVm.submit(e)}>
            <div>온라인 소집통지 동의받기(13-7)</div>
            <div>
                전자소집통지 동의를 받을 주주를 선택해 주세요.<br/>
                *아직 동의가 완료되지 않은 주주 목록이에요.<br/>
                <table>
                    <thead>
                        <tr>
                            <th>메일</th>
                            <th>sms</th>
                            <th>주주명</th>
                            <th>주식종류</th>
                            <th>주식수</th>
                            <th>지분율</th>
                        </tr>
                    </thead>
                    <tbody>
                        {OnlineMusterBeforehandVm.shareholders.map((item, idx) => {
                            if(item.onlineMusterNoticeStatus === 'WAITING' || item.onlineMusterNoticeStatus === 'UNCONFIRMED') {
                                return (
                                    <tr key={idx}>
                                        <td>
                                            <input type="checkbox" value={item.id} onChange={(e) => OnlineMusterBeforehandVm.setEmail(e)} />
                                        </td>
                                        <td>
                                            <input type="checkbox" value={item.id} onChange={(e) => OnlineMusterBeforehandVm.setSMS(e)} />
                                        </td>
                                        <td>{item.name}</td>
                                        <td>
                                            {item?.stocks.map((stock, index) => {
                                                return (
                                                    <div key={index}>{stock.name}</div>
                                                )
                                            })}
                                        </td>
                                        <td>
                                            {item?.stocks.map((stock, index) => {
                                                return (
                                                    <div key={index}>{addCommas(stock.number)}</div>
                                                )
                                            })}
                                        </td>
                                        <td>
                                            {item?.stocks.map((stock, index) => {
                                                return (
                                                    <div key={index}>{addCommas(stock.rate)}</div>
                                                )
                                            })}
                                        </td>
                                    </tr>
                                )
                            }                            
                        })}
                    </tbody>
                </table>
            </div>
            {OnlineMusterBeforehandVm.displayEmail && 
                <div style={{marginTop: '15px'}}>
                    이메일 주소가 입력되지 않은 주주가 있어요.<br/>이메일 주소를 입력해주세요.<br/>
                    {OnlineMusterBeforehandVm.shareholders.map((item, idx) => {
                        if(item.onlineMusterNoticeStatus === 'WAITING' || item.onlineMusterNoticeStatus === 'UNCONFIRMED') {
                            if(item.email === '') {
                                return (
                                    <Fragment key={idx}>
                                        <input type="hidden" name={`newShareholderEmails[${idx}].id`} value={item.id} />
                                        {item.name} <input type="text" name={`newShareholderEmails[${idx}].email`} /><br/>
                                    </Fragment>
                                    
                                )
                            }
                        }
                    })}
                </div>
            }
            {OnlineMusterBeforehandVm.displayPhoneNumber && 
                <div style={{marginTop: '15px'}}>
                    전화번호가 입력되지 않은 주주가 있어요.<br/>전화번호를 입력해주세요.<br/>
                    {OnlineMusterBeforehandVm.shareholders.map((item, idx) => {
                        if(item.onlineMusterNoticeStatus === 'WAITING' || item.onlineMusterNoticeStatus === 'UNCONFIRMED') { 
                            if(item.phoneNumber === '') {
                                return (
                                    <Fragment key={idx}>
                                        <input type="hidden" name={`newShareholderSMSs[${idx}].id`} value={item.id} />
                                        {item.name} <input type="text" name={`newShareholderSMSs[${idx}].phoneNumber`} /><br/>
                                    </Fragment>
                                    
                                )
                            }
                        }
                    })}
                </div>
            }
            <button type="submit" style={{border: '1px solid black'}}>전자소집통지 동의 메일 발송하기</button>
        </form>
    ));
}
export default OnlineMusterBeforehand;