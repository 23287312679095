import { useObserver } from 'mobx-react';
import queryString from 'query-string';
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { useEffect } from 'react';
import vm from './InternetAssociateVm';
import Step1 from './Step1/Step1';
import Step2 from './Step2/Step2';
import Step3 from './Step3/Step3';

function InternetAssociate() {
    let location = useLocation();
    const id = queryString.parse(location.search).corp;
    let navigate = useNavigate();
    useEffect(() => {
        const loadStep = async () => {
            const step = await vm.step();
            if(step === 'STEP1') {
                navigate(`/corp/internetAssociate/promotion?corp=${id}`, { replace: true });
            }else if(step === 'STEP2') {
                navigate(`/corp/internetAssociate/application?corp=${id}`, { replace: true });
            }else if(step === 'STEP3') {
                navigate(`/corp/internetAssociate/result?corp=${id}`, { replace: true });
            }
        }
        if(location.pathname === '/corp/internetAssociate') { //처음 진입 했을때만 실행함
            loadStep();
        }
    }, [location.pathname])
    return useObserver(() => (
        <>
            <Routes>
                <Route path="/promotion" element={<Step1/>}/>
                <Route path="/application" element={<Step2/>}/>
                <Route path="/result" element={<Step3/>}/>
            </Routes>
        </>
    ));
}
export default InternetAssociate;