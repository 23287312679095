import { useObserver } from 'mobx-react';
import st from '../BoardOfDirectors.module.scss';
import { useEffect } from 'react';
import vm from './Step2Vm';
import clsx from 'clsx';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import ToolTip from '@standby/common-ui/components/molecules/ToolTip/ToolTip';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import ErrorText from '@standby/common-ui/components/atoms/Text/ErrorText/ErrorText';

interface Step2Props {
    minutesId : string,
    companyId : string,
    agendaProgressId : string
}

function Step2(props : Step2Props) {
    useEffect(() => {
        vm.load(props.companyId, props.agendaProgressId, props.minutesId);
    }, [])
    return useObserver(() => (
        <form className={st.modalContent} onSubmit={(e) => vm.submit(e, props.companyId, props.agendaProgressId)}>
            <div className={st.question}>
                <div className={st.title}>찬성여부를 선택하세요.</div>
                <div className={st.content}>
                    <input type='hidden' value={props.minutesId} name="minutesId"/>
                    {vm.step2.agendaList.map((agenda, index) => (
                        <div className={st.agenda} key={index}>
                            <input type="hidden" value={agenda.type} name={`agendaList[${index}].type`}/>
                            <div className={st.agendaTitle}>
                                <span className={st.agendaIndex}>{index+1}호 안건</span>
                                <span className={st.title}>{agenda.title}</span>
                            </div>
                            <div className={st.agendaTableWrap}>
                                <table className={clsx(st.table, st.attendanceTable)} style={{width:"620px"}}>
                                    <colgroup>
                                        <col width="77"/>
                                        <col width="77"/>
                                        <col width="77"/>
                                        <col/>
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>
                                                <CheckBox checked={vm.attendanceAll('찬성', index)} className={st.checkAll} onChange={(e) => vm.setAttendancell(e, '찬성', index)}>찬성</CheckBox>
                                            </th>
                                            <th>
                                                <CheckBox checked={vm.attendanceAll('반대', index)} className={st.checkAll} onChange={(e) => vm.setAttendancell(e, '반대', index)}>반대</CheckBox>
                                            </th>
                                            <th>
                                                <CheckBox checked={vm.attendanceAll('기권', index)} className={st.checkAll} onChange={(e) => vm.setAttendancell(e, '기권', index)}>기권</CheckBox>
                                            </th>
                                            <th>이름</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {agenda.executives.map((executive, index2) => (
                                            <tr key={index2}>
                                                <td>
                                                    <CheckBox className={st.noneText} checked={executive.status === "찬성"} name={`agendaList[${index}].executives[${index2}].status`} value="찬성" onChange={(e) => vm.status(e, index, index2)}/>
                                                </td>
                                                <td>
                                                    <CheckBox className={st.noneText} checked={executive.status === "반대"} name={`agendaList[${index}].executives[${index2}].status`} value="반대" onChange={(e) => vm.status(e, index, index2)}/>
                                                </td>
                                                <td>
                                                    <CheckBox className={st.noneText} checked={executive.status === "기권"} name={`agendaList[${index}].executives[${index2}].status`} value="기권" onChange={(e) => vm.status(e, index, index2)}/>
                                                </td>
                                                <td>
                                                    <span className={st.index}>{index2 + 1}</span>
                                                    <span className={st.name}>{executive.name}</span>
                                                    <span className={st.position}>{executive.position}</span>
                                                    <input type="hidden" value={executive.id} name={`agendaList[${index}].executives[${index2}].id`}/>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className={st.tableSum} style={{width:"620px"}}>
                                    {vm.attendanceYn(index) ? 
                                        <div className={clsx(st.attendMeetingTotal, st.possible)}>
                                            <img src='/images/possible_icon.svg'/>충분
                                        </div>
                                        :
                                        <div className={st.attendMeetingTotal}>
                                            <img src='/images/impossible_icon.svg'/>부족
                                        </div>
                                    }
                                </div>
                                <div className={st.tooltipWrap} style={{width:"620px"}}>
                                    <ToolTip upAndDown="down" leftOrRight="right" iconType="question" tooltipContentStyle={{width:'225px'}}>
                                        dltk를 선택하면, 상법에 맞는지 자동으로 계산해드려요!
                                    </ToolTip>
                                    <span className={st.tooltipText}>의결정족수 자동계산</span>
                                </div>
                                {((agenda.executives.some((executive) => !executive.status) && vm.state.tryVaild === true) || (index === 0 && vm.step2.agendaList.length > 1 )) && 
                                    <div className={st.tableBottom}>
                                        {(agenda.executives.some((executive) => !executive.status) && vm.state.tryVaild === true) && 
                                            <ErrorText>찬성 여부를 선택하세요.</ErrorText>
                                        }
                                        {(index === 0 && vm.step2.agendaList.length > 1 ) && 
                                            <div className={st.checkAllBtnWrap}>
                                                <button type='button' className={st.checkAllBtn} onClick={() => vm.allCheckAgenda()}><span>아래 안건들에도 동일하게 체크하기</span></button>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className={st.btnArea}>
                <button type='button' className={st.prevBtn} onClick={() => vm.preStep()}>이전 페이지</button>
                <SquareBtn type="submit" className={st.contentSubmitBtn}>선택 완료</SquareBtn>
            </div>
        </form>
    ));
}
export default Step2;