import { useObserver } from 'mobx-react';
import vm from './ProductDetailVm';
import {Step3Product} from '@model/Corp/InternetAssociate/InternetAssociateModel.interface';
import st from './ProductDetail.module.scss';
import TextArea from '@standby/common-ui/components/atoms/Input/TextArea/TextArea';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import CopyBtn from '@standby/common-ui/components/atoms/Button/CopyBtn/CopyBtn';

interface ProductDetailProps {
    product : Step3Product
}

function ProductDetail(props : ProductDetailProps) {
    return useObserver(() => (
        <form className={st.productDetail} onSubmit={(e) => vm.submit(e, props.product.id)}>
            <div className={st.badge}>개통완료</div>
            <div className={st.name}>[인터넷_KT] 오피스넷_에센스(1G)</div>
            <ul className={st.detailList}>
                <li>
                    <div className={st.title}>회선</div>
                    <div className={st.content}>{props.product.circuit}</div>
                </li>
                <li>
                    <div className={st.title}>가입번호</div>
                    <div className={st.content}>
                        <CopyBtn copyText={props.product.serialNumber} className={st.copyBtn} messageClassName={st.copyMessage}>
                            <span className={st.content} title={props.product.serialNumber}>{props.product.serialNumber}</span>
                        </CopyBtn>
                    </div>
                </li>
                <li>
                    <div className={st.title}>개통일</div>
                    <div className={st.content}>{props.product.startDate}</div>
                </li>
                <li>
                    <div className={st.title}>약정기간</div>
                    <div className={st.content}>{props.product.contractYears} {props.product.contractPeriod ? `(${props.product.contractPeriod})` : ''}</div>
                </li>
            </ul>
            <div className={st.memoTitle}>메모</div>
            <TextArea name='memo' className={st.memoTextArea} value={props.product.memo} onChange={(e) => vm.changeMemo(e, props.product.id)} placeholder='추후에 알아두면 좋을 정보를 입력해 보세요!&#10;- 예) 기업은행(13XX)로 자동이체 했으며, ○○팀이 관리 중'></TextArea>
            <SquareBtn type='submit' className={st.memoSubmit}>저장하기</SquareBtn>
        </form>
    ));
}
export default ProductDetail;