import { observable, runInAction, toJS } from 'mobx';
import formDataToJson from '@common/module/submit';
import onlineMusterService from '@service/Corp/Stock/OnlineMuster/onlineMusterService';
import onlineMusterModel from '@model/Corp/Stock/OnlineMuster/onlineMusterModel';

class OnlineMusterDirectVm {
    constructor() {
        this.state = observable({
            checkedShareholderIds: []
        });
    }
    submit(e) {
        e.preventDefault();
        if(formDataToJson(e.target).error) {
            return
        } else {
            let param = formDataToJson(e.target).data;
            param.checkedShareholderIds = this.state.checkedShareholderIds;
            onlineMusterService.onlineMusterDirect(param);
        }
    }
    async load() {
        await onlineMusterService.loadShareholdersData();
        onlineMusterModel.shareholders.forEach((item) => {
            if(item.onlineMusterNoticeStatus === 'ACCEPTANCE' || item.onlineMusterNoticeStatus === 'MANAGER_ACCEPTANCE') {
                this.state.checkedShareholderIds.push(item.id);
            }
        })
    }
    get shareholders() {
        return onlineMusterModel.shareholders;
    }
    checked(id) {
        if(this.state.checkedShareholderIds.includes(id)) {
            return true;
        } else {
            return false;
        }
    }
    setChecked(e) {
        const val = Number(e.target.value);
        if(e.target.checked) {
            if(this.state.checkedShareholderIds.includes(val) === false) {
                this.state.checkedShareholderIds.push(val);
            }
        } else {
            let idx = this.state.checkedShareholderIds.indexOf(val);
            this.state.checkedShareholderIds.splice(idx, 1);
        }
    }
}

export default new OnlineMusterDirectVm();
