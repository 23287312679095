import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { useEffect } from 'react';
import { pageRouter } from '@model/pageModel';
import presetService from '@service/presetService';
function ChannelTalkOnly(props) {
    useEffect(() => {
        if(props.isLogined === false) {
            presetService.load();
        }
    }, [])
    return useObserver(() => (
        <>
            
        </>
    ));
}
export default ChannelTalkOnly;