import agendaModel from '@model/Corp/Agenda/agendaModel';
import agendaService from '@service/Corp/Agenda/agendaService';
import corpModel from '@model/Corp/corpModel';
import { _alert, _confirm } from "@model/dialogModel";
import st from './Progress.module.scss';

class ProgressVm {
    load() {
        agendaService.load();
    }
    get guides() {
        return agendaModel.guides;
    }
    get corpId() {
        return corpModel.id;
    }
    get progressAgendas() {
        return agendaModel.progressAgendas;
    }
    get completedAgendas() {
        return agendaModel.completedAgendas;
    }
    async cancelAgenda(agendaProgressId : string) {
        if(await _confirm('진행을 취소하시겠습니까?','','','',<div className={st.confirmWarn}>취소시 입력내용이 모두 삭제되고, 복구는 불가능해요.</div>)) {
            if(this.corpId) {
                agendaService.cancelAgenda(agendaProgressId, (this.corpId).toString());
            }
        }
    }
    hrefBtn() {
        window.location.href = `/corp/agenda/step/AGENDA/?corp=${this.corpId}`;
    }

}
export default new ProgressVm();