import { useObserver } from 'mobx-react';
import vm from './AddressVm';
import st from './Address.module.scss';
import CopyBtn from '@standby/common-ui/components/atoms/Button/CopyBtn/CopyBtn';
import clsx from 'clsx';

function BranchOfficeList(props : {branchOffice : string}) {
    return useObserver(() => (
        <li>
            <CopyBtn copyText={props.branchOffice} className={st.copyBtn} messageClassName={st.copyMessage}>
                <span className={st.text} title={props.branchOffice}>{props.branchOffice}</span>
            </CopyBtn>
        </li>
    ));
}

function Address() {
    return useObserver(() => (
        <div className={st.wrap}>
            <div className={st.title}>본점, 지점</div>
            <div className={st.addressContent}>
                <div className={st.address} style={{paddingBottom:"35px"}}>
                    <div className={st.title}>본점</div>
                    <div className={st.content}>
                        <CopyBtn copyText={vm.address?.headOffice} className={st.copyBtn} messageClassName={st.copyMessage}>
                            <span className={st.text} title={vm.address?.headOffice}>{vm.address?.headOffice}</span>
                        </CopyBtn>
                        <div style={{marginTop:"6px"}}>
                            <button type='button' className={st.openModalBtn} onClick={vm.agendaLinkModalHeadOffice}>변경하기</button>
                        </div>
                    </div>
                </div>
                <div className={st.address} style={{paddingTop:"15px"}}>
                    <div className={st.title}>지점</div>
                    <div className={st.content}>
                        {vm.address && vm.address.branchOffices.length !== 0 ?
                            <ul>
                                {(vm.address.branchOffices.length < 3 || vm.state.moreviewYn === true) ?
                                    vm.address.branchOffices.map((branchOffice, index) => (
                                        <BranchOfficeList branchOffice={branchOffice} key={index}/>
                                    )) :
                                    vm.address.branchOffices.slice(0,2).map((branchOffice, index) => (
                                        <BranchOfficeList branchOffice={branchOffice} key={index}/>
                                    ))
                                }
                            </ul> : 
                            <div className={st.empty}>등록된 지점 없음</div>
                        }
                        <div style={{marginTop:"6px"}}>
                            <button type='button' className={st.openModalBtn} onClick={vm.agendaLinkModalBranchOffices}>추가, 변경하기</button>
                        </div>
                    </div>
                </div>
            </div>
            {vm.address && vm.address.branchOffices.length > 2 && 
                <button type="button" className={clsx(st.moreView, vm.state.moreviewYn ? st.open : null)} onClick={() => vm.moreview()}>더보기</button>
            }
        </div>
    ));
}
export default Address;