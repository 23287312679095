import type {findIdModelInterface, Step, User} from './findIdModel.interface'

import { makeObservable, observable } from 'mobx';

class findIdModel implements findIdModelInterface {
    constructor() {
        makeObservable(this, {
            name : observable,
            phoneNumber : observable,
            step : observable,
            users : observable
        });
    }
    name : string = '';
    phoneNumber : string = '';
    step : Step = 'findId';
    users : User[] = []
}

export default new findIdModel();