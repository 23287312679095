import { useObserver } from 'mobx-react';
import vm from './CompanyNameVm';
import st from './CompanyName.module.scss';
function CompanyName() {
    return useObserver(() => (
        <div className={st.wrap}>
            <div className={st.title}>상호</div>
            <button type='button' className={st.openModalBtn} onClick={vm.agendaLinkModal}>변경하기</button>
            <div className={st.companyNameContent}>
                <div className={st.companyName} style={{height:"76px"}}>
                    <div className={st.title}>한글</div>
                    <div className={st.content}>
                        <span className={st.text}>{vm.dashboardData?.companyKoreanName}</span>
                    </div>
                </div>
                <div className={st.companyName} style={{paddingTop:"15px"}}>
                    <div className={st.title}>영문</div>
                    <div className={st.content}>
                        {vm.dashboardData?.companyEnglishName ?
                            <span className={st.text}>{vm.dashboardData.companyEnglishName}</span>
                            :
                            <>
                                <div className={st.empty}>없음</div>
                                <div className={st.emptySub}>* 영문상호는 선택사항이에요.</div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    ));
}
export default CompanyName;