import MemoWriteModel from "@model/AgendaDashboard/MemoWrite/MemoWriteModel";
import MemoWriteService from "@service/AgendaDashboard/MemoWrite/MemoWriteService";
import AgendaDashboardModel from "@model/AgendaDashboard/AgendaDashboardModel";
import portalModel from '@model/portalModel';
import { runInAction } from 'mobx';

class MemoWriteModalVm {
    load(memo : string) {
        MemoWriteService.load(memo, AgendaDashboardModel.selectedAgendaProgressId)
    }
    get memo() {
        return MemoWriteModel.memo;
    }
    get templates() {
        return MemoWriteModel.templates;
    }
    setBold(contentEditableRef : React.RefObject<HTMLDivElement>) {
        if (contentEditableRef.current) {
            const selection = window.getSelection();
            const parentElement = selection?.anchorNode?.parentElement;
            if (parentElement?.tagName === 'B') {
                document.execCommand('removeFormat', false, undefined);
            } else {
                document.execCommand('bold', false, undefined);
            }
            contentEditableRef.current.focus();
        }
    }
    get boldActive() {
        return MemoWriteModel.boldActive;
    }
    setBoldActive() {
        const selection = window.getSelection();
        const boldActive = selection?.anchorNode?.parentElement?.tagName === 'B' ||
            selection?.focusNode?.parentElement?.tagName === 'B';
        MemoWriteModel.boldActive = boldActive;
    }
    insertTemplate(content : string, contentEditableRef : React.RefObject<HTMLDivElement>) {
        if (contentEditableRef.current) {
            if (contentEditableRef.current.innerHTML.trim() !== '') {
                contentEditableRef.current.innerHTML += '<br>';
            }
            contentEditableRef.current.innerHTML += content;
        }
    };
    saveMemo(contentEditableRef : React.RefObject<HTMLDivElement>) {
        if (contentEditableRef.current) {
            const cleanedHTML = contentEditableRef.current.innerHTML
            .replace(/<br>$/i, '')
            .replace(/^<br>/i, '')
            .replace(/<div>\s*<\/div>/gi, '');
            MemoWriteService.saveMemo(cleanedHTML, AgendaDashboardModel.selectedAgendaProgressId);
        }
    }
    closeModal() {
        runInAction(() => {
            portalModel.title = "";
            portalModel.content = null;
        })
    }
}
export default new MemoWriteModalVm();