import type { Meetings, Tab, 의결기관Type } from './meetingsStepModel.interface';

import { makeObservable, observable } from 'mobx';

class meetingsStepModel implements Meetings {
    constructor() {
        makeObservable(this, {
            title: observable,
            의결기관Type: observable,
            hasCapitalOverBillionYn: observable,
            tabList: observable
        })
    }
    title: string = "";
    의결기관Type: 의결기관Type = null;
    hasCapitalOverBillionYn: boolean = false;
    tabList: Tab[] = [];
}

export default new meetingsStepModel();
