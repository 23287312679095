import { runInAction, observable, toJS } from "mobx";
import portalModel from '@model/portalModel';
import UserSetting from "@pages/UserSetting/UserSetting";
import notificationModel from '@model/notificationModel';
import userModel from '@model/userModel';
import notificationService from '@service/notificationService';
import { _alert, _confirm } from "@model/dialogModel";

class HeaderVm {
    constructor() {
        this.state = observable({
            openNotify: false
        })
    }
    openUserSetting() {
        runInAction(() => {
            portalModel.content = <UserSetting />;
            portalModel.title = '나의 계정'
        })
    }
    openNotification() {
        this.state.openNotify = !this.state.openNotify;
        if(this.state.openNotify) {
            document.body.style.overflow = 'hidden';
        }else{
            notificationService.readNotification();
            document.body.style.overflow = 'auto';
        }
    }
    get newNotifyYn() {
        let newNotifyYn = false;

        newNotifyYn = notificationModel.notifications.some((notification) => {
            return !notification.readAt
        })
        if(notificationModel.notifications.filter(notification => notification.notificationType === "INVITE").length !== 0) {
            newNotifyYn = true;
        }

        return newNotifyYn;
    }
    get name() {
        return userModel.user.name;
    }
    get notifications() {
        return notificationModel.notifications;
    }
    async InviteTypeAccept(callbackUrl, acceptYn, notificationId) {
    await notificationService.InviteTypeAccept(callbackUrl, acceptYn, notificationId);
        if(acceptYn) {
            await _alert('수락되었습니다.');
        }else{
            await _alert('거절되었습니다.');
        }
        if(this.notifications.length === 0) { //노티가 안남아있으면 닫음
            portalModel.content = null;
        }
    }
}
export default new HeaderVm();