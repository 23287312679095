import { useObserver } from 'mobx-react';
import st from '../AgendaDashboard.module.scss';
import Cst from './BoardOfDirectors.module.scss';
import vm from './BoardOfDirectorsVm';
import clsx from 'clsx';

function BoardOfDirectors() {
    return useObserver(() => (
        <div className={st.item}>
            <div className={st.title}>이사회</div>
            <div className={clsx(st.content, Cst.content)}>
                <div className={Cst.changeItem}>
                    <span className={Cst.prefix}>[등기 전]</span>
                    <span className={clsx(Cst.item, vm.boardOfDirectors.before ? Cst.has : Cst.notHas)}> 이사회 {vm.boardOfDirectors.before ? 'O' : 'X'}</span>
                </div>
                <div className={Cst.changeItem}>
                    <span className={Cst.prefix}>[등기 후]</span>
                    {vm.boardOfDirectors.after === vm.boardOfDirectors.before ? 
                        <span className={Cst.item}>변동없음</span>
                        :
                        <span className={clsx(Cst.item, vm.boardOfDirectors.after ? Cst.has : Cst.notHas)}> 이사회 {vm.boardOfDirectors.after ? 'O' : 'X'}</span>
                    }
                </div>
            </div>
        </div>
    ));
}
export default BoardOfDirectors;