import { useObserver } from 'mobx-react';
import vm from './Step3Vm';
import { useEffect } from 'react';
import st from './Step3.module.scss';
import clsx from 'clsx';
import CopyBtn from '@standby/common-ui/components/atoms/Button/CopyBtn/CopyBtn';
import { Link } from 'react-router-dom';
import Table from '@standby/common-ui/components/atoms/Table/Table';
import ContentBtn from '@standby/common-ui/components/atoms/Button/ContentBtn/ContentBtn';

function Step3() {
    useEffect(() => {
        vm.load();
    }, [])
    return useObserver(() => (
        <>
            <div className={st.top}>
                <span className={st.title}>기업용 인터넷·전화</span>
                <div className={st.btnGroup}>
                    <button type="button" className={st.btn} onClick={() => vm.previewModal()}>혜택 다시보기</button>
                    <button type="button" className={clsx(st.btn, st.color)} onClick={() => vm.moreService()}>추가 신청하기</button>
                </div>
            </div>
            {vm.status === "CONSULT_APPLICATION_COMPLETED" && 
                <div className={st.status}>
                    <div className={st.tag}>상담접수완료</div>
                    <div className={st.statusTitle}>{vm.date} 상담신청이 완료되었어요.</div>
                    <div className={st.statusInfo}>KT 대리점에서 캐시백 상담전화를 드릴 거에요. (1 영업일 내, 010 번호로 전화를 드려요.)</div>
                </div>
            }
            {vm.status === "OPENING_APPLICATION_COMPLETED" && 
                <div className={st.status}>
                    <div className={st.tag}>개통접수완료</div>
                    <div className={st.statusTitle}>{vm.date} 개통접수가 완료되었어요.</div>
                    <div className={st.statusInfo}>신청하신 상품으로 개통 준비중이에요. 개통완료 후 KT 대리점에서 캐시백을 드려요! </div>
                </div>
            }
            {vm.products.length !== 0 && 
                <>
                    <div className={st.circuitList}>
                        <ul className={st.total}>
                            <li data-type="INTERNET">
                                <div className={st.name}>인터넷</div>
                                <div className={st.line}>{vm.totalCircuitCount("INTERNET")} 회선</div>
                            </li>
                            <li data-type="TELEPHONE">
                                <div className={st.name}>전화</div>
                                <div className={st.line}>{vm.totalCircuitCount("TELEPHONE")} 회선</div>
                            </li>
                            <li data-type="ETC">
                                <div className={st.name}>그 외</div>
                                <div className={st.line}>{vm.totalCircuitCount("ETC")} 회선</div>
                            </li>
                        </ul>
                    </div>
                    <Table className={st.productsList}>
                        <colgroup>
                            <col width="45px"/>
                            <col width="70px"/>
                            <col/>
                            <col width="130px"/>
                            <col width="70px"/>
                            <col width="200px"/>
                            <col width="90px"/>
                        </colgroup>
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>상품군</th>
                                <th>상품명 & 옵션</th>
                                <th>상태</th>
                                <th>약정</th>
                                <th>약정 기간</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {vm.products.map((product, index) => (
                                <tr key={index}>
                                    <td>{index+1}</td>
                                    <td>
                                        {product.type === "INTERNET" && "인터넷"}
                                        {product.type === "TELEPHONE" && "전화"}
                                        {product.type === "ETC" && "그 외"}
                                    </td>
                                    <td>
                                        [{product.name}]{product.option}
                                    </td>
                                    <td>개통완료</td>
                                    <td>
                                        {product.contractYears}
                                    </td>
                                    <td>
                                        {product.contractPeriod}
                                    </td>
                                    <td>
                                        <ContentBtn onClick={() => vm.productDetail(product)}>상세</ContentBtn>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </>
            }
            <div className={st.mainContent}>
                {vm.status !== "CONSULT_APPLICATION_COMPLETED" && 
                    <div className={clsx(st.row, st.rowSpaceBetween)}>
                        <div className={st.listContent}>
                            <div className={st.title}>스탠바이랩 전용 KT 콜센터</div>
                            <div className={st.sub}>개통관련 문의사항이 있으신가요? 바로 전화해보세요!</div>
                            <ul className={st.list}>
                                <li>① 우측 [복사하기] 버튼을 클릭해 전화번호를 복사할 수 있어요.</li>
                                <li>② 통화 시 <em>“스탠바이랩 고객 입니다.”</em>라고 꼭 말해주세요.</li>
                            </ul>
                        </div>
                        <div className={clsx(st.copy, st.telephone)}>
                            <div className={st.title}><em>오전 10</em>시부터 <em>오후 6시</em>까지! 전화 시 바로 상담이 가능해요</div>
                            <CopyBtn copyText='1588-9790' className={st.copyArea} messageClassName={st.copyMessage}>
                                <span className={st.copyText}>1588-9790</span><div className={st.likeBtn}>복사하기</div>
                            </CopyBtn>
                        </div>
                    </div>
                }
                <div className={clsx(st.row, st.rowSpaceBetween)}>
                    <div className={st.listContent}>
                        <div className={st.title}>혜택 소개하기</div>
                        <div className={st.sub}>지인분께 기업용 통신요금 캐시백 받는 기회를 나눠보세요!</div>
                        <ul className={st.list}>
                            <li>① 우측 [복사하기] 버튼을 클릭하면, 링크가 복사되어요.</li>
                            <li>② 혜택을 알리고 싶은 지인분께 링크를 전달하세요!</li>
                        </ul>
                    </div>
                    <div className={st.copy}>
                        <div className={st.title}>기업용 통신요금 캐시백 소개 링크</div>
                        <CopyBtn copyText='https://app.standby.kr/guest/internetAssociate' className={st.copyArea} messageClassName={st.copyMessage}>
                            <span className={st.copyText}>app.standby.kr/guest/internetAssociate</span><div className={st.likeBtn}>복사하기</div>
                        </CopyBtn>
                    </div>
                </div>
                <div className={st.row}>
                    <div className={st.title}>제휴혜택 더 알아보기</div>
                    <div className={st.sub}>오직 스탠바이 법인에게만 제공되는 특별한 제휴 혜택!</div>
                    <ul className={st.advertisementCards}>
                        <li>
                            <Link to={`/corp/alliance?corp=${vm.id}`}><img src='/images/internetAssociate_advertisement1.png'/></Link>
                        </li>
                        <li>
                            <button type='button' onClick={() => vm.openUserSetting()}><img src='/images/internetAssociate_advertisement2.png'/></button>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    ));
}
export default Step3;