import InternetAssociateModel from '@model/Corp/InternetAssociate/InternetAssociateModel';
import formDataToJson from '@common/module/submit';
import InternetAssociateService from '@service/Corp/InternetAssociate/InternetAssociateService';

class ProductDetailVm {
    changeMemo(e : React.ChangeEvent<HTMLTextAreaElement>, id : string) {
        InternetAssociateModel.step3.products.forEach((product) => {
            if(product.id === id){
                product.memo = e.target.value;
            }
        })
    }    
    submit(e: React.FormEvent<HTMLFormElement>, id : string) {
        e.preventDefault();
        if(formDataToJson(e.target).error) {
            return
        } else {
            const param = formDataToJson(e.target).data as {memo : string};
            InternetAssociateService.memo(param, id);
        }
    }
}
export default new ProductDetailVm();