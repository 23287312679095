import { useObserver } from 'mobx-react';
import st from './ExecutiveModal.module.scss';
import Table from '@standby/common-ui/components/atoms/Table/Table';
import clsx from 'clsx';
import vm from './ExecutiveModalVm';
import {ExecutiveModalMembers, ExecutiveModalMember} from '@model/Corp/Agenda/Step/ExecutiveModal/ExecutiveModalModel.interface';

interface ExecutiveModalProps {
    openFirst : () => void
}

function ExecutiveModal(props : ExecutiveModalProps) {
    return useObserver(() => (
        <div className={st.executiveModal}>
            <div className={st.title}>임원의 변경사항</div>
            <Table className={st.table}>
                <colgroup>
                    <col width="200"/>
                    <col/>
                </colgroup>
                <thead>
                    <tr>
                        <th>기존 등기부</th>
                        <th>신규 등기부</th>
                    </tr>
                </thead>
                <tbody>
                    {vm.executives.map((executive, index) => (
                        'executiveMembers' in executive === true ?
                            (executive as ExecutiveModalMembers).executiveMembers.map((executiveMember, index2) => (
                                <tr key={'p'+index+'p2'+index2} className={st.samePerson}>
                                    <td className={st.new}>
                                        <div className={st.name} key={index2}>
                                            <span className={st.number}>{index2 === 0 ? index+1 : ''}</span>
                                            <span>{executiveMember.originExecutive.name} {executiveMember.originExecutive.position}</span>
                                        </div>
                                    </td>
                                    <td className={st.origin}>
                                        <div className={st.info} key={index2}>
                                            <div className={st.tags}>
                                                {executiveMember.changeType.map((changeType, index3) => (
                                                    <div className={st.tag} key={index3} data-changetype={changeType}>{changeType.replace('_', '/')}</div>
                                                ))}
                                            </div>
                                            <div className={clsx(st.name, executiveMember.changeType.includes('사임_퇴임') ? st.erase : null)}>
                                                {executiveMember.updateExecutive.name} {executiveMember.updateExecutive.position}
                                            </div>
                                        </div>
                                        {executiveMember.changeType.includes('직급변경') && 
                                            <div className={st.position}>
                                                <span className={st.before}>{executiveMember.originExecutive.position}</span>
                                                <span className={st.after}>{executiveMember.updateExecutive.position}</span>
                                            </div>
                                        }
                                    </td>
                                </tr>
                            ))
                        :
                            <tr key={'p'+index}>
                                <td className={st.new}>
                                    {(executive as ExecutiveModalMember).changeType.includes('신규') === false &&
                                        <div className={st.name}>
                                            <span className={st.number}>{index+1}</span>
                                            <span>{(executive as ExecutiveModalMember).originExecutive.name} {(executive as ExecutiveModalMember).originExecutive.position}</span>
                                        </div>
                                    }
                                </td>
                                <td className={st.origin}>
                                    <div className={st.info}>
                                        <div className={st.tags}>
                                            {(executive as ExecutiveModalMember).changeType.map((changeType, index2) => (
                                                <div className={st.tag} key={index2} data-changetype={changeType}>{changeType.replace('_', '/')}</div>
                                            ))}
                                        </div>
                                        <div className={clsx(st.name, (executive as ExecutiveModalMember).changeType.includes('사임_퇴임') ? st.erase : null)}>
                                            {(executive as ExecutiveModalMember).updateExecutive.name} {(executive as ExecutiveModalMember).updateExecutive.position}
                                        </div>
                                    </div>
                                    {(executive as ExecutiveModalMember).changeType.includes('직급변경') && 
                                        <div className={st.position}>
                                            <span className={st.before}>{(executive as ExecutiveModalMember).originExecutive.position}</span>
                                            <span className={st.after}>{(executive as ExecutiveModalMember).updateExecutive.position}</span>
                                        </div>
                                    }
                                </td>
                            </tr>
                    ))}
                </tbody>
            </Table>
            <div className={st.confirm}>
                <div className={st.title}>{vm.validation.title}</div>
                <div className={st.content}>
                    {vm.validation.content}
                </div>
                {vm.validation.validated === true &&
                    <div className={st.buttonArea}>
                        <button className={st.color} type='button' onClick={() => vm.executiveSubmit(props.openFirst)}>네</button>
                        <button type='button' onClick={() => vm.modalClose()}>아니오</button>
                    </div>
                }
                {vm.validation.validated === false &&
                    <div className={st.buttonArea}>
                        <button className={st.color} type='button' onClick={() => vm.modalClose()}>확인</button>
                    </div>
                }
            </div>
        </div>
    ));
}
export default ExecutiveModal;