
import { runInAction, observable } from "mobx";
import { _alert, _confirm } from "@model/dialogModel";
import corpModel from "@model/Corp/corpModel";
import awsCreditModel from "@model/Corp/AwsCredit/awsCreditModel";
import awsCreditService from "@service/Corp/AwsCredit/awsCreditService";
import { onlyNumber } from '@common/module/replaceNumber';
import portalModel from '@model/portalModel';
import TermsModal from "./TermsModal/TermsModal";
import formDataToJson from '@common/module/submit';
import ExistingCustomerModal from "./ExistingCustomerModal/ExistingCustomerModal";
import {SaveStep2Rq} from '@service/Corp/AwsCredit/awsCreditService.interface';



class Step2Vm {
    state : {
        errorCheckYn : boolean
    }
    constructor() {
        this.state = observable({
            errorCheckYn: false
        })
    }
    load() {
        awsCreditService.loadStep2();
        awsCreditService.loadTerm();
    }
    get companyName() {
        return corpModel.company.companyName;
    }
    get data() {
        return awsCreditModel.step2;
    }
    useAWSYn(e : React.ChangeEvent<HTMLInputElement>) {
        runInAction(() => {
            this.data.useAWSYn = e.target.value === "true";
        })
    }
    MGCUseYn(e : React.ChangeEvent<HTMLInputElement>) {
        runInAction(() => {
            this.data.MGCUseYn = e.target.value === "true";
        })
    }
    monthCost(e : React.ChangeEvent<HTMLInputElement>) {
        runInAction(() => {
            this.data.monthCost = e.target.value
        })
    }
    purpose(e : React.ChangeEvent<HTMLInputElement>) {
        runInAction(() => {
            this.data.purpose = e.target.value
        })
    }
    name(e : React.ChangeEvent<HTMLInputElement>) {
        runInAction(() => {
            this.data.name = e.target.value
        })
    }
    email(e : React.ChangeEvent<HTMLInputElement>) {
        runInAction(() => {
            this.data.email = e.target.value
        })
    }
    phoneNumber(e : React.ChangeEvent<HTMLInputElement>) {
        runInAction(() => {
            this.data.phoneNumber = String(onlyNumber(e.target.value));
        })
    }
    responsibility(e : React.ChangeEvent<HTMLInputElement>) {
        runInAction(() => {
            this.data.responsibility = e.target.value
        })
    }
    cloudServiceUseType(e : React.ChangeEvent<HTMLInputElement>) {
        const cloudServiceUseType = e.target.value as '아니오' | '타_클라우드를_쓰고_있어요' | '서버를_구입하여_직접_운영하고_있어요';
        runInAction(() => {
            this.data.cloudServiceUseType = cloudServiceUseType;
        })
    }
    cloudServiceName(e : React.ChangeEvent<HTMLInputElement>) {
        runInAction(() => {
            this.data.cloudServiceName = e.target.value
        })
    }
    get agreeAll() {
        if(this.data.termInfos.length === 0) {
            return false;
        }
        let agreeAll = true;
        this.data.termInfos.forEach((item) => {
            if(item.agreeYn === false) {
                agreeAll = false;
            }
        })
        return agreeAll;
    }
    setAgreeAll(e : React.ChangeEvent<HTMLInputElement>) {
        runInAction(() => {
            this.data.termInfos.forEach((item) => {
                item.agreeYn = e.target.checked;
            })
        })
    }
    setAgreeYn(e : React.ChangeEvent<HTMLInputElement>, index : number) {
        this.data.termInfos[index].agreeYn = e.target.checked;
    }
    termsPopup(title : string, content : JSX.Element) {
        runInAction(() => {
            portalModel.title = title;
            portalModel.content = <TermsModal content={content}/>;
        })
    }
    get checkBoxError() {
        let error = false;
        this.data.termInfos.forEach((item) => {
            if(item.required && item.agreeYn === false) {
                error = true;
            }
        })
        return error;
    }
    async submit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if(this.data.useAWSYn === true && this.data.MGCUseYn === true) {
            runInAction(() => {
                portalModel.content = <ExistingCustomerModal/>;
            })
            return
        }
        if(formDataToJson(e.target).error || this.checkBoxError) {
            this.state.errorCheckYn = true;
            return
        } else {
            let param = formDataToJson(e.target).data as SaveStep2Rq;
            await awsCreditService.saveStep2(param);
        }
    }
}
export default new Step2Vm();