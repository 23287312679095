import { useObserver } from 'mobx-react';
import type { I주식발행공고 } from '@src/model/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalModel.interface';
import bst from '@pages/Corp/Agenda/Step/FinalCheck/BeforeFinal/BeforeFinal.module.scss';
import clsx from 'clsx';
import vm from './NewStockNoticeVm';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import QuestionTooltip from '@standby/common-ui/components/molecules/QuestionTooltip/QuestionTooltip';

interface NewStockNoticeProps {
    index: number;
    info: I주식발행공고
}

function NewStockNotice(props: NewStockNoticeProps) {
    return useObserver(() => (
        <>
            <div className={bst.row}>
                <div className={clsx(bst.title, bst.section3)}>{props.index+1}. 주식발행공고</div>
                <div className={bst.content}>
                    <div className={bst.contentTitleBox}>신주발행을 위해 공고를 진행하세요. <span className={bst.light}>({props.info.announcedDate} 공고)</span></div>
                    <QuestionTooltip title="신주발행할 때 공고가 필요한 이유" style={{width:'460px'}}>
                        <div className={bst.tooltipContent}>
                            <div className={bst.qna}>
                                <div className={bst.qnaTitle}>원칙상 신주는 주주에게 발행해야 해요.</div>
                                <div className={bst.qnaContent}>
                                    <ul>
                                        <li>주주가 가진 주식수에 따라 신주를 배정받을 권리가 있어요. (상법 제418조 제1항)</li>
                                    </ul>
                                </div>
                            </div>
                            <div className={bst.qna}>
                                <div className={bst.qnaTitle}>다만 정당한 목적이 있는 경우 제3자에게 발행할 수 있어요.</div>
                                <div className={bst.qnaContent}>
                                    <ul>
                                        <li>신기술 도입, 재무구조 개선 등 경영상 목적을 달성하기 위해 필요한 경우에는 제3자에게 발행이 가능해요. (상법 제418조 제2항)</li>
                                    </ul>
                                </div>
                            </div>
                            <div className={bst.qna}>
                                <div className={bst.qnaTitle}>이 경우 주주에게 제3자에게 신주발행을 한다는 사실을 통지 또는 공고해야 해요.</div>
                                <div className={bst.qnaContent}>
                                    <ul>
                                        <li>신주발행을 통해 주주의 지분율이 낮아지므로, 최소 14일 전에 회사는 주주에게 신주발행 사실을 알려주도록 상법이 정하고 있어요. (상법 제418조 제4항)</li>
                                    </ul>
                                </div>
                            </div>
                            <div className={bst.qna}>
                                <div className={bst.qnaTitle}>다만 모든 주주가 동의하면 통지/공고를 생략하고 2주를 기다리지 않아도 돼요.</div>
                                <div className={bst.qnaContent}>
                                    <ul>
                                        <li>통지/공고는 주주의 권리를 보호하기 위한 것이니, 주주들이 동의하면 절차를 생략할 수 있어요.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </QuestionTooltip>
                    <div className={bst.wrap} style={{marginTop:'16px'}}>
                        <div className={bst.wrapTitle}>
                            <span className={bst.important}>주식발행공고 방법</span> 상세 안내
                        </div>
                        <div className={bst.steps}>
                            <div className={bst.stepWrap}>
                                <div className={bst.step}>
                                    <div className={bst.stepTitle}>신주발행 2주 전 공고 필수!</div>
                                    <div className={bst.stepContent}>2주 계산시 공고당일은<br/>불포함</div>
                                </div>
                            </div>
                            <div className={bst.stepWrap}>
                                <div className={bst.step}>
                                    <div className={bst.stepTitle}>
                                        {props.info.announceType === "홈페이지" ? '홈페이지' : '신문사'}에 공고문<br/>업로드 필요!
                                    </div>
                                    <div className={bst.stepContent}>등기부에 따른 조치</div>
                                </div>
                            </div>
                            <div className={bst.stepWrap}>
                                <div className={bst.step}>
                                    <div className={bst.stepTitle}>2주간 공고문 유지 필요!</div>
                                    <div className={bst.stepContent}>삭제하지 않고 그대로<br/>두어도 무방함</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={bst.btnArea}>
                        <SquareBtn className={bst.btn} onClick={() => vm.download(props.info.assignmentType)}>공고문 양식 다운로드</SquareBtn>
                    </div>
                </div>
            </div>
        </>
    ));
}
export default NewStockNotice;