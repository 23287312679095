function addCommas(x : number | string) {
    if(x) {
        return (x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else if(x === 0) {
        return 0;
    } else{
        return '';
    }
}

function removeCommas(x : string) {
    if(x) {   
        return (x).toString().replace(/,/g, "");
    }
    return x
}

function onlyNumber(x : string | number){
    if(x === " "){
        return " ";
    }else{
        if(typeof x === 'string') {
            return x.replace(/[^0-9]/g,"");
        }else{
            return x
        }
    }
}

function percent(x : number){
    return x.toFixed(2)+' %';
}

export {addCommas, removeCommas, onlyNumber, percent};