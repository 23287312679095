import { useObserver } from 'mobx-react';
import vm from './ValidationModalVm';
import st from './ValidationModal.module.scss';

import type { ValidationSchedulesParam } from '@service/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalService.interface';

interface ValidationModalProps {
    agendaProgressId: string;
    param: ValidationSchedulesParam;
}

function ValidationModal(props: ValidationModalProps) {
    return useObserver(() => (
        <div className={st.modalContent}>
            <div className={st.title}>앗! 변경된 내용 때문에 아래 절차를 다시 진행해야 해요. 정말 변경하나요?</div>
            <div className={st.content}>
                <ul>
                    {vm.changedSchedules?.map((schedules, idx) => {
                        return (
                            <li key={idx}>
                                {vm.title(schedules.type, schedules.date || "")}
                                <span className={st.info}>
                                    {vm.infoText(schedules.type, schedules.changedInfo)}
                                </span>
                            </li>
                        )
                    })}
                </ul>
            </div>
            {vm.displayExplain && 
                <div className={st.explain}>
                    <span className={st.bold}>기존 발송기록</span>은 <span className={st.bold}>삭제</span>되어요.
                </div>
            }
            <div className={st.btnArea}>
                <button type="button" className={st.positiveBtn} onClick={(() => vm.submit(props.agendaProgressId, props.param))}>네</button>
                <button type="button" className={st.negativeBtn} onClick={() => vm.modalClose()}>아니오</button>
            </div>
        </div>
    ));
}
export default ValidationModal;
