import { get, post, downloadFilePost } from '@common/module/httpRequest';
import { runInAction } from 'mobx';
import corpModel from '@model/Corp/corpModel';
import documentModel from '@model/Corp/Agenda/Step/FinalCheck/BeforeFinal/documentModel';
import type { LoadDocumentParam, LoadDocumentRs, LoadShareholdersRs, I소집통지서, LoadStockDocumentRs, SendStockDocumentParam, LoadStockShareholdersRs, DefaultInfoRs, SendDocumentType } from './documentService.interface';

class documentService {
    async loadDocument(agendaProgressId: string, param: LoadDocumentParam) {
        const res = await post<LoadDocumentRs>(`/company/${corpModel.id}/agendaProgress/${agendaProgressId}/beforeFinal/document/preview`, param);
        runInAction(() => { 
            documentModel.previewLink = res.data.previewLink;
        })
    }
    async loadShareholders(agendaProgressId: string, _documentType: SendDocumentType) {
        const res = await get<LoadShareholdersRs>(`/company/${corpModel.id}/agendaProgress/${agendaProgressId}/beforeFinal/document/shareholders`, {
            documentType: _documentType
        });
        runInAction(() => {
            documentModel.shareholders = (res.data.shareholders && res.data.shareholders.length !== 0) ? res.data.shareholders.map((shareholder) => {
                return {
                    id: shareholder.id || -1,
                    name: shareholder.name || "",
                    email: shareholder.email || "",
                    phoneNumber: shareholder.phoneNumber || "",
                    status: shareholder.status || null,
                    stocks: shareholder.stocks || [],
                    sendDate: shareholder.sendDate || undefined,
                    acceptDate: shareholder.acceptDate || undefined,
                }
            }) : [];
        })
    }
    async sendDocument(agendaProgressId: string, param: FormData) {
        await post(`/company/${corpModel.id}/agendaProgress/${agendaProgressId}/beforeFinal/document/share`, param, {
            converter: "share-document"
        });
    }
    async onlineMusterNoticeDownload(agendaProgressId: string, param: I소집통지서) {
        await post(`/company/${corpModel.id}/agendaProgress/${agendaProgressId}/beforeFinal/onlineMusterNotice/download`, {
            options: param
        });
    }
    async loadStockDocument() {
        const res = await post<LoadStockDocumentRs>(`/company/${corpModel.id}/shareholder/onlineMeetingNoticeAgreement/paper/preview`);
        runInAction(() => { 
            documentModel.previewLink = res.data.previewLink;
        })
    }
    async sendStockDocument(param: SendStockDocumentParam) {
        await post(`/company/${corpModel.id}/shareholder/onlineMeetingNoticeAgreement/paper/send`, param);
    }
    async loadStockShareholders() {
        const res = await get<LoadStockShareholdersRs>(`/company/${corpModel.id}/shareholder/onlineMeetingNoticeAgreement/paper/send`);
        runInAction(() => {
            documentModel.shareholders = (res.data.shareholders && res.data.shareholders.length !== 0) ? res.data.shareholders.map((shareholder) => {
                return {
                    id: shareholder.id || -1,
                    name: shareholder.name || "",
                    email: shareholder.email || "",
                    phoneNumber: shareholder.phoneNumber || "",
                    status: shareholder.status || null,
                    stocks: shareholder.stocks || [],
                    sendDate: shareholder.sendDate || ""
                }
            }) : [];
        })
    }
    async defaultInfo(agendaProgressId: string, _documentType: string) {
        const res = await get<DefaultInfoRs>(`/company/${corpModel.id}/agendaProgress/${agendaProgressId}/beforeFinal/document/default`, {
            documentType: _documentType
        })
        runInAction(() => {
            if(res.data?.title) {
                documentModel.defaultValue.title = res.data?.title || "";
            }
            if(res.data?.defaultOptions) {
                documentModel.defaultValue.defaultOptions = {
                    subscriptionAt: res.data.defaultOptions?.subscriptionAt || undefined,
                    paymentAt: res.data.defaultOptions?.paymentAt || undefined,
                    representatives: res.data.defaultOptions?.representatives || [],
                    showRepresentativeNameSelectionYn: res.data.defaultOptions?.showRepresentativeNameSelectionYn ?? false,
                    newSharesAcquirers: (res.data.defaultOptions?.newSharesAcquirers && res.data.defaultOptions?.newSharesAcquirers.length !== 0) ? res.data.defaultOptions.newSharesAcquirers.map((item) => {
                        return {
                            id: item.id || -1,
                            name: item.name || "",
                            subscriptionAt: item.subscriptionAt || "",
                            paymentAt: item.paymentAt || "",
                            stocks: item.stocks || []
                        }
                    }) : undefined,
                    dividedSubscriptionYn: res.data.defaultOptions?.dividedSubscriptionYn ?? false
                }
            }    
        })
    }
    async documentDownload(agendaProgressId: string, param: FormData) {
        //@todo httpRequest 속성값으로 수정하기 (로딩여부, 딜레이여부)
        await new Promise(resolve => setTimeout(resolve, 300)); //서류자동작성 로딩 애니메이션 딜레이
        await downloadFilePost(`/company/${corpModel.id}/agendaProgress/${agendaProgressId}/beforeFinal/document/download`, param, {
            converter: "share-document"
        });
    }
}

export default new documentService();