import { observable } from 'mobx';

const vatModel = observable({
    allListUrl: "",
    guides: [],
    statusOfVatReporting: {
        vatReportPeriodType: "",
        reportingExemptYn: null,
        vatList: [],
        reportingPeriod: {
            year: "",
            month: "",
            day: ""
        },
        steps: []
    },
    preparationData: {
        title: "",
        submissionDeadline: "",
        email: "",
        returnReadyDocumentUrl: ""
    },
    vatData: [],
    setGuides(data) {
        this.allListUrl = data.allListUrl || "";
        if(data.guides && data.guides.length !== 0) {
            this.guides = data?.guides.map((guide) => {
                return {
                    title : guide?.title || "",
                    contents : guide?.content|| "",
                    url : guide?.url || "",
                }
            })
        } else {
            this.guides = [];
        }
    },
    setVat(data) {
        this.statusOfVatReporting.vatReportPeriodType = data.statusOfVatReporting.vatReportPeriodType || "";
        this.statusOfVatReporting.reportingExemptYn = data.statusOfVatReporting.reportingExemptYn ?? null;
        if(data.statusOfVatReporting.vatList && data.statusOfVatReporting.vatList.length !== 0) {
            this.statusOfVatReporting.vatList = data.statusOfVatReporting.vatList.map((item) => {
                return  {
                    id: item.id || "",
                    title: item.title || "",
                }
            })
        } else {
            this.statusOfVatReporting.vatList = [];
        }
        this.statusOfVatReporting.reportingPeriod = data.statusOfVatReporting.reportingPeriod || {
            year: "",
            month: "",
            day: ""
        };
        if(data.statusOfVatReporting.steps && data.statusOfVatReporting.steps.length !== 0) {
            this.statusOfVatReporting.steps = data.statusOfVatReporting.steps.map((item) => {
                if(!item.name.includes('부가세')) {
                    item.name = '부가세_' + item.name;
                }
                return {
                    name: item.name || "",
                    content: item.content || ""
                }
            })
        } else {
            this.statusOfVatReporting.steps = [];
        }
        this.preparationData = data.preparationData || {
            title: "",
            submissionDeadline: "",
            email: "",
            returnReadyDocumentUrl: ""
        };
        if(data.vatData && data.vatData.length !== 0) {
            this.vatData = data.vatData.map((item) => {
                return {
                    reportingPeriod: item.reportingPeriod || "",
                    vatReportPeriodType: item.vatReportPeriodType || "",
                    reportProgressStatus: item.reportProgressStatus || "",
                    earlyRefundYn: item.earlyRefundYn ?? null,
                    reportingExemptYn: item.reportingExemptYn ?? null,
                    refundTaxYn: item.refundTaxYn ?? null,
                    card: item.card || {
                        content: item.content || "",
                        salesTax: "",
                        purchaseTax: "",
                        sumOfTheDeductibles: "",
                        additionalTaxPayable: "",
                        amountOfTaxPaid: "",
                        refundTaxAmount: "",
                        estimatedAmountOfNotice: {
                            amount: "",
                            content: ""
                        },
                        statementOfPaymentUrl: "",
                        statementOfVatReturn: {
                            downloadUrl: "",
                            thumbnailUrl: ""
                        },
                        purchaseAndSalesLedgerFile: {
                            downloadUrl: "",
                            thumbnailUrl: ""
                        },
                        etcFile: {
                            downloadUrl: "",
                            thumbnailUrl: ""
                        }
                    }
                }
            })
        } else {
            this.vatData = [];
        }
    },
    setSelectVat(data) {
        this.statusOfVatReporting.vatReportPeriodType = data.statusOfVatReporting.vatReportPeriodType || "";
        this.statusOfVatReporting.reportingExemptYn = data.statusOfVatReporting.reportingExemptYn ?? null;
        if(data.statusOfVatReporting.vatList && data.statusOfVatReporting.vatList.length !== 0) {
            this.statusOfVatReporting.vatList = data.statusOfVatReporting.vatList.map((item) => {
                return  {
                    id: item.id || "",
                    title: item.title || "",
                }
            })
        } else {
            this.statusOfVatReporting.vatList = [];
        }
        this.statusOfVatReporting.reportingPeriod = data.statusOfVatReporting.reportingPeriod || {
            year: "",
            month: "",
            day: ""
        };
        if(data.statusOfVatReporting.steps && data.statusOfVatReporting.steps.length !== 0) {
            this.statusOfVatReporting.steps = data.statusOfVatReporting.steps.map((item) => {
                if(!item.name.includes('부가세')) {
                    item.name = '부가세_' + item.name;
                }
                return {
                    name: item.name || "",
                    content: item.content || ""
                }
            })
        } else {
            this.statusOfVatReporting.steps = [];
        }
        this.preparationData = data.preparationData || {
            title: "",
            submissionDeadline: "",
            email: "",
            returnReadyDocumentUrl: ""
        };
    }
});

export default vatModel;
