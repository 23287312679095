import { useObserver } from 'mobx-react';
import st from '../Agendas.module.scss';
import type {Agenda, IA폼_없음} from '@model/AgendaDashboard/AgendaDashboardModel.interface';
import clsx from 'clsx';

interface Props {
    agendaIndex : number,
    agenda : Agenda<IA폼_없음>,
    moreViewYn : boolean
}

function C폼_없음(props : Props) {
    return useObserver(() => (
        <div className={clsx(st.agenda, props.moreViewYn ? st.opened : '')}>
            <div className={st.title}>
                <div className={st.number}>{props.agendaIndex + 1}</div>
                <div className={st.text}><span className={st.important}>{props.agenda.info.title}</span></div>
                {props.agenda.decisionBody.map((decisionBody, index) => (
                    <div className={st.badge} key={index}>{decisionBody}</div>
                ))}
            </div>
        </div>
    ));
}
export default C폼_없음;