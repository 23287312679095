import { post, get, put, _delete } from '@common/module/httpRequest';
import { runInAction } from 'mobx'; 
import corpModel from '@model/Corp/corpModel';
import adminModel from '@model/Corp/AdminSetting/adminModel';
import { _alert, _confirm } from "@model/dialogModel";

class adminSettingService {
    async load() {
        const res = await get(`/userAuthority/company/${corpModel.id}`);
        adminModel.setManagers(res.data.managers)
    }
    async editRole(userId, role) {
        const res = await put(`/userAuthority/company/${corpModel.id}/user/${userId}/role`,{
            role
        });
        await _alert('수정되었어요.');
        this.load();
    }
    async editAuthorityType(userId, authorityType) {
        const res = await put(`/userAuthority/company/${corpModel.id}/user/${userId}/authorityType`,{
            authorityType
        });
        await _alert('수정되었어요.');
        this.load();
    }
    async inviteAdmin(param) {
        await post(`/userAuthority/company/${corpModel.id}/invite`, param);
    }
    async deleteAdmin(userId) {
        await _delete(`/userAuthority/company/${corpModel.id}/user/${userId}`);
        await _alert('삭제되었어요.');
        this.load();
    }
    async save() {
        const managersList = adminModel.managers.filter((item) => item.inviting === false);
        const invitingManagersList = adminModel.managers.filter((item => item.inviting === true));
        let param = {
            managers : managersList.map((manager) => {
                return {
                    userId : manager.userId,
                    authorities : [...manager.authorities.acct, ...manager.authorities.law, ...manager.authorities.partner].map((authority) => {
                        return {
                            menuCode : authority.menuCode,
                            useYn : authority.useYn
                        }
                    })
                }
            }),
            invitingManagers : invitingManagersList.map((manager) => {
                return {
                    invitationId: manager.userId,
                    authorities : [...manager.authorities.acct, ...manager.authorities.law, ...manager.authorities.partner].map((authority) => {
                        return {
                            menuCode : authority.menuCode,
                            useYn : authority.useYn
                        }
                    })
                }          
            })
        }
        await post(`/userAuthority/company/${corpModel.id}/save`, param);
        await _alert('수정되었어요.');
        this.load();
    }
    async deleteInvitation(userId) {
        await _delete(`/userAuthority/company/${corpModel.id}/invitation/${userId}`);
        await _alert('삭제되었어요.');
        this.load();
    }
}
export default new adminSettingService();
