import type { DashboardModelInterface, DashboardData } from './DashboardModel.interface';
import { observable, makeObservable } from 'mobx';

class DashboardModel implements DashboardModelInterface {
    constructor() {
        makeObservable(this, {
            dashboardData : observable,
        })
    }
    dashboardData : DashboardData = null;
}

export default new DashboardModel();