import { useObserver } from 'mobx-react';
import { useEffect } from 'react';
import useComponentWillMount from '@common/hook/useComponentWillMount';
import CompanyNameSearchVm from './CompanyNameSearchVm';
import st from './CompanyNameSearch.module.scss';
import Select from '@standby/common-ui/components/atoms/Input/Select/Select';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import FixBtn from '@standby/common-ui/components/atoms/Button/FixBtn/FixBtn'
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import Radio from '@standby/common-ui/components/atoms/Input/Radio/Radio';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import ScrollArea from 'react-custom-scrollbars-2';
import Loading from '@commonComponents/Loading/Loading';

let vm;

function CompanyNameSearch(props) {
    
    useComponentWillMount(() => {
        vm = new CompanyNameSearchVm;
    });

    useEffect(() => {
        vm.load();
    }, []);

    const screenSize = window.innerHeight;
    
    let _maxHeight = '437px'

    if(screenSize <= 600) {
        _maxHeight = '106px'
    }

    return useObserver(() => (
        <div className={st.companyNameSearch}>
            {vm.state.loadingYn && <Loading />}
            <form onSubmit={(e) => vm.search(e)} autoComplete="off" className={st.form}>
                <div className={st.row}>
                    <div className={st.title}>회사 본점 소재지</div>
                    <div className={st.content}>
                        <div className={st.contentRow}>   
                            <Select name="광역자치단체" className={st.select} value={vm.state.광역자치단체} onChange={(e) => vm.set광역자치단체(e)} errorText={<Error name="광역자치단체" value={vm.state.광역자치단체} errorCase={{
                                required: '시/도를 선택해주세요.'
                            }}/>}>
                                <option value="">시/도</option>
                                {vm.광역자치단체s.map((item, idx) => (
                                    <option value={item.name} key={idx}>{item.name}</option>
                                ))}
                            </Select>
                            {(vm.state.광역자치단체 && vm.state.광역자치단체 !== '서울특별시' && vm.state.광역자치단체 !== '세종특별자치시') &&
                                <Select name="시군구" className={st.select} value={vm.state.시군구} onChange={(e) => vm.set시군구(e)} disabled={!vm.state.광역자치단체} errorText={vm.state.광역자치단체 ? <Error name="시군구" value={vm.state.시군구} errorCase={{
                                    required: '시/군/구를 선택해주세요.'
                                }}/> : null}>
                                    <option value="">시/군/구</option>
                                    {vm.시군구List.map((item, idx) => (
                                        <option value={item} key={idx}>{item}</option>
                                    ))}
                                </Select>
                            }
                        </div>                        
                    </div>
                </div>
                <div className={st.row}>
                    <div className={st.title}>한글 회사이름</div>
                    <div className={st.content}>
                        <div className={st.contentRow}>
                            <InputText name="companyName" style={{width:'356px'}} value={vm.state.companyName} onChange={(e) => vm.setCompanyName(e)} errorText={<Error name="companyName" value={vm.state.companyName} errorCase={{
                                required: '한글 회사이름을 입력해주세요.'
                            }}/>}/>
                            <FixBtn type="submit" className={st.submitBtn}>조회</FixBtn>
                        </div>
                        <div className={st.explain}>'주식회사'를 제외한 나머지 법인명을 입력하세요.</div>
                    </div>
                </div>
            </form>
            {vm.irosStatusType && 
            <form onSubmit={(e) => vm.submitNextBtn(e, props.companyData)}>
                <div className={st.companyListBox}>
                    {vm.displaySearchResults === 'None' && //검색 리스트가 없는 경우
                        <div className={st.companyListEmpty}>
                            <div className={st.emptyInfo}>
                                <div className={st.empty}>위 주소에서는 "{vm.searchKeyword}"가 등록되어 있지 않아요.</div>
                                <div className={st.emptyExplainBox}>
                                    <div className={st.emptyExplain}>
                                        <span className={st.bold}>회사이름</span>이 <span className={st.bold}>정확</span>한지 확인하세요.
                                        <div className={st.sub}>예) "홍길동컴퍼니" 검색시<br/>홍길동 (X)<span style={{marginLeft:'8px'}}>홍길동컴퍼니 (O)</span></div>
                                    </div>
                                    <div className={st.emptyExplain}>
                                        <span className={st.bold}>본점 소재지</span>가 <span className={st.bold}>정확</span>한지 확인하세요.
                                        <div className={st.sub}>본점 소재지 (O)<span style={{marginLeft:'8px'}}>지점 소재지 (X)</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {vm.displaySearchResults === 'Results' && //검색 리스트가 있는 경우
                        <div className={st.companyList}>
                            <ScrollArea renderView={({style, props}) => <div {...props} style={{...style , position: 'none', maxHeight:`${_maxHeight}` }}/>} renderThumbVertical={props => <div {...props} style={{width:"3px", backgroundColor:'#D6D8DA'}}/>}>
                                <div className={st.companyInfoList}>
                                    {vm.searchResults.map((item, idx) => {
                                        return (
                                            <Radio name="chooseCompany" className={st.companyInfo} key={idx} checked={vm.state.chooseCompanyIdx === String(idx)} value={idx} onChange={(e) => vm.chooseCompany(e)}>
                                                {item.companyName}<span className={st.companyAddress}>{item.address}</span>
                                            </Radio>
                                        )
                                    })}
                                </div>
                            </ScrollArea>
                        </div>
                    }
                    {vm.displaySearchResults === 'IrosError' && //인터넷 등기소 오류
                        <div className={st.companyError}>
                            <Radio name="chooseIrosFailCompany" className={st.companyInfo} checked={vm.state.chooseIrosFailCompany} value={vm.state.chooseIrosFailCompany} onChange={(e) => vm.chooseIrosFailCompany(e)}
                            >
                                {vm.state.companyName}<span className={st.companyAddress}>{vm.state.광역자치단체} {vm.state.시군구}</span>
                            </Radio>
                        </div>
                    }
                </div>
                <div>
                    {vm.displaySearchResults === 'Results' && <Error name="chooseCompany" value={vm.state.chooseCompanyIdx} errorCase={{required:'회사를 선택해주세요.'}}/>}
                    {vm.displaySearchResults === 'IrosError' && <Error name="chooseIrosFailCompany" value={vm.state.chooseIrosFailCompany} errorCase={{required:'회사를 선택해주세요.'}}/>}
                </div>
                {vm.displaySearchResults !== 'None' && 
                    <div className={st.nextBtnArea}>
                        <SquareBtn type="submit" className={st.nextBtn}>다음</SquareBtn>
                    </div>
                }
            </form>
            }
        </div>
    ));
}

export default CompanyNameSearch;