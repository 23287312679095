import { observable } from 'mobx';

const AdminAddModel = observable({
    addData : {
        name : "",
        email : "",
        role : "",
        authorityType : "MANAGER",
        authorities : {
            law : [],
            acct : [],
            partner : []
        }
    },
    setAuthorities(authorities) {
        this.addData.authorities.law = authorities.law ? authorities.law.map((law) => {
            return {
                menuName : law?.menuName || "",
                menuCode : law?.menuCode || "",
                useYn : law?.useYn || false
            }
        }) : []
        this.addData.authorities.acct = authorities.acct ? authorities.acct.map((acct) => {
            return {
                menuName : acct?.menuName || "",
                menuCode : acct?.menuCode || "",
                useYn : acct?.useYn || false
            }
        }) : [] 

        this.addData.authorities.partner = authorities.partner ? authorities.partner.map((partner) => {
            return {
                menuName : partner?.menuName || "",
                menuCode : partner?.menuCode || "",
                useYn : partner?.useYn || false
            }
        }) : [] 
    }
});

export default AdminAddModel;