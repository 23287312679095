
import { useObserver } from 'mobx-react';
import { useEffect } from 'react';
import { reaction } from 'mobx';
import vm from './DocumentVm';
import st from '../../OnlineAcceptancePopup.module.scss';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';

interface DocumentProps {
    agendaProgressId: string;
    page?: string;
}

function Document(props: DocumentProps) {
    useEffect(() => {
        vm.load(props.agendaProgressId, props.page);
        reaction(
            () => vm.previewLink,
            (previewLink: string) => {
                if(previewLink) {
                    const previewFrame = document.getElementById('previewFrame') as HTMLIFrameElement;
                    if(previewFrame && previewFrame.contentWindow) {
                        previewFrame.contentWindow.location.replace(previewLink);
                    }
                }
            }
        )
    }, []);

    return useObserver(() => (
        <div className={st.document}>
            <div className={st.title}>주주총회 온라인 소집통지 수신동의서</div>
            <div className={st.contentBox}>
                <div className={st.documentBox}>
                    <div className={st.content}>
                        <iframe id='previewFrame' title="주주총회 온라인 소집통지 수신동의 미리보기" className={st.iframeDocument}/>
                    </div>
                </div>
                <form className={st.documentOption} onSubmit={(e) => vm.submit(e, props.agendaProgressId, props.page)}>
                    주주님의 성함이 아래에 자동으로<br/>입력되어 발송됩니다.
                    <div className={st.btnArea}>
                        <SquareBtn className={st.nextBtn} type="submit">다음 페이지</SquareBtn>
                    </div>
                    <input type="hidden" name="documentType" value="온라인_수신동의서"/>
                </form>
            </div>
            
        </div>
    ));
}
export default Document;