import { useObserver } from 'mobx-react';
import { useLocation } from "react-router-dom";
import { useEffect } from 'react';
import queryString from 'query-string';
import vm from './TermsVm';
import st from './Terms.module.scss';

function Terms() {
    let location = useLocation();
    const termId = queryString.parse(location.search).termId as string;
    const type = queryString.parse(location.search).type as string;

    useEffect(() => {
        vm.load(type);
    }, []);

    return useObserver(() => (
        <div className={st.terms}>
            <div className={st.title}>{vm.title(termId)}</div>
            <div className={st.content} dangerouslySetInnerHTML={{__html: vm.content(termId) as string}}></div>
        </div>   
    ));
}
export default Terms;