import beforeFinalService from "@service/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalService";
import beforeFinalModel from "@model/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalModel";
import portalModel from "@src/model/portalModel";
import { runInAction } from "mobx";
import AppplicationCheck from "./AppplicationCheck/AppplicationCheck";
import ValidationModal from './ValidationModal/ValidationModal';
import loadingModel from '@model/loadingModel';

import type { I파일_업로드, I서류자동작성 } from '@src/model/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalModel.interface';


class FinalApplicationVm {
    get registrationAgencyType() {
        return beforeFinalModel.registrationAgencyType;
    }
    async openModal(type: string, agendaProgressId: string, setDocumentSubmit: () => void) {        
        let sectionListIdx = beforeFinalModel.sectionList.findIndex(item => item.type === '파일_업로드');
        if(sectionListIdx !== -1) {
            const info = beforeFinalModel.sectionList[sectionListIdx].info as I파일_업로드;
            for(let i=0; i<info.uploadFileList.length; i++) {
                if(info.uploadFileList[i].required) {
                    if(!info.uploadFileList[i].file) {
                        const errorFile = document?.querySelector("#fileUploadContent") as HTMLElement;
                        if(errorFile) {
                            const location = errorFile.offsetTop;
                            window.scrollTo({top: location, behavior: 'smooth'});
                            return;
                        }
                    }
                }
            }
        }

        if(beforeFinalModel.sectionList.some(item => item.type === "서류자동작성")) {
            let sectionListIdx = beforeFinalModel.sectionList.findIndex(item => item.type === '서류자동작성');
            if(sectionListIdx !== -1) {
                const info = beforeFinalModel.sectionList[sectionListIdx].info as I서류자동작성;
                for(let i=0; i<info.documents.length; i++) {
                    if(!info.documents[i].downloadUrl) {
                        setDocumentSubmit();
                        const errorFile = document?.querySelector("#autoDocument") as HTMLElement;
                        if(errorFile) {
                            const location = errorFile.offsetTop;
                            window.scrollTo({top: location, behavior: 'smooth'});
                            return;
                        }
                    }
                }
            }
        }

        try {
            loadingModel.showYn = true;
            await new Promise(resolve => setTimeout(resolve, 300)); //로딩 애니메이션 딜레이
            await beforeFinalService.nextValidation(agendaProgressId);
            loadingModel.showYn = false;

            const validated = beforeFinalModel.submitValidation.validated;

            if(validated) {
                switch(type) {
                case 'SELF':
                    runInAction(() => {
                        portalModel.title = '새로운 등기부 반영';
                        portalModel.content = <AppplicationCheck type={type} agendaProgressId={agendaProgressId} newSharesIncludeYn={beforeFinalModel.submitValidation.newSharesIncludeYn}/>
                    })
                    break;
                case '불포함':
                    runInAction(() => {
                        portalModel.title = '종료처리';
                        portalModel.content = <AppplicationCheck type={type} agendaProgressId={agendaProgressId} newSharesIncludeYn={beforeFinalModel.submitValidation.newSharesIncludeYn}/>
                    })
                    break;
                }
            } else {
                runInAction(() => {
                    portalModel.title = '';
                    portalModel.content = <ValidationModal agendaProgressId={agendaProgressId}/>
                    portalModel.closeBtn = false;
                    portalModel.radius = true;
                })
            }
        } catch(err) {
            loadingModel.showYn = false;
        }
    }
}

export default new FinalApplicationVm();