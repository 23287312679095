import { useObserver } from 'mobx-react';
import { isMobile } from 'react-device-detect';
import SecurityContent from '@src/pages/Guest/Component/SecurityContent/SecurityContent';
import SecurityContentMobile from '@src/pages/Guest/Component/SecurityContent/SecurityContentMobile';
import st from '@pages/Guest/Component/SecurityContent/SecurityContent.module.scss';
import Mst from '@pages/Guest/Component/SecurityContent/SecurityContentMobile.module.scss';
import { useEffect } from 'react';
import vm from './ShareholdersMeetingNoticeVm';

function ShareholdersMeetingNotice(props: {token: string}) {
    useEffect(() => {
        vm.download(props.token);
    }, [])
    return useObserver(() => (
        <>
            {isMobile ? 
                <SecurityContentMobile>
                    <div className={Mst.download}>주주총회 소집통지문을<br/>다운로드 합니다</div>
                </SecurityContentMobile>
                :
                <SecurityContent>
                    <div className={st.download}>주주총회 소집통지문을<br/>다운로드 합니다</div>
                </SecurityContent>
            }
        </>
    ));
}
export default ShareholdersMeetingNotice;