import userModel from '@model/userModel';
import authService from '@service/authService';
class MainVm {
    get user() {
        return userModel.user;
    }
    isLogined() {
        if(this.user.name && this.user.email){
            return true;
        }else{
            return false;
        }
    }
    logout() {
        authService.logout();
    }
}
export default new MainVm();