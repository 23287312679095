import { runInAction, observable, toJS } from "mobx";
import authService from '@service/authService';

class viewModel {
    //회원가입 이메일 전송 관련(emailType: join)
    get joinMembershipData() {
        const joinMembership = JSON.parse(sessionStorage.getItem('joinMembership'));
        return joinMembership;
    }
    sendJoinMembershipEmail() {
        authService.requestJoinMembershipEmail(this.joinMembershipData);
    }
    sendOtherJoinMembershipEmail() {
        authService.requestOtherJoinMembershipEmail();
    }
    //비밀번호 찾기 이메일 전송 관련(emailType: find)
    get findPasswordData() {
        const findPassword = JSON.parse(sessionStorage.getItem('findPassword'));
        return findPassword;
    }
    sendFindPasswordEmail() {
        authService.requestFindPasswordEmail(this.findPasswordData);
    }
    sendOtherFindPasswordEmail() {
        authService.requestOtherFindPasswordEmail();
    }
}

export default new viewModel();