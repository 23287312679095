import ExecutiveModalModel from '@model/Corp/Agenda/Step/ExecutiveModal/ExecutiveModalModel';
import portalModel from '@model/portalModel';
import agendaService from '@service/Corp/Agenda/agendaService';
import { runInAction } from 'mobx';

class ExecutivePopupVm {
    get executives() {
        return ExecutiveModalModel.executives
    }
    get validation() {
        return ExecutiveModalModel.validation;
    }
    modalClose() {
        runInAction(() => {
            ExecutiveModalModel.modalOpened = false;
            portalModel.content = null;
            portalModel.title = '';
            portalModel.closeBtn = true;
            portalModel.radius = false;
        })
    }
    async executiveSubmit(openFirst : () => void) {
        if(ExecutiveModalModel.executiveParams instanceof FormData) {
            await agendaService.saveAgenda(ExecutiveModalModel.executiveParams);
            openFirst();
            this.modalClose();
        }
    }
}
export default new ExecutivePopupVm();