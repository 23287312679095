import { observable } from 'mobx'; 
import DashboardModel from '@model/Corp/Dashboard/DashboardModel';
import portalModel from '@model/portalModel';
import {runInAction} from 'mobx';
import AgendaLinkPopup from '@pages/Corp/Dashboard/AgendaLinkPopup/AgendaLinkPopup';

type Info = {
    position : string,
    termOfOfficeAt : string,
    remainingTermOfOffice : number,
    fullTermOfOffice : number,
    tag : string
}[]
class ExecutivesVm {
    state : {
        moreviewYn : boolean
    }
    constructor() {
        this.state = observable({
            moreviewYn: false,
        })
    }
    moreview() {
        this.state.moreviewYn = !this.state.moreviewYn;
    }
    get dashboardData() {
        return DashboardModel.dashboardData;
    }
    positionIcon(info : Info) {
        const position = info[0].position;
        if(info.length > 2) {
            return "대표이사"
        }else{
            if(position === "사내이사" || position === "사외이사" || position === "감사") {
                return position;
            }else if(position.includes("대표")){
                return "대표이사"
            }else{
                return "기타"
            }
        }
    }
    agendaLinkModal() {
        runInAction(() => {
            portalModel.title = "임원 변경하기";
            portalModel.content = <AgendaLinkPopup agendaType="임원_취임_중임_사임_퇴임"/>;
        })
    }
}
export default new ExecutivesVm();