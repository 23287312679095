import { useObserver } from 'mobx-react';
import React from 'react';
import HeadOfficeAddressChange from './HeadOfficeAddressChange/HeadOfficeAddressChange';
import ApprovaOfFinancialStatements from './ApprovaOfFinancialStatements/ApprovaOfFinancialStatements';
import BonusIssue from './BonusIssue/BonusIssue';
import IssuanceOfConvertibleBonds from './IssuanceOfConvertibleBonds/IssuanceOfConvertibleBonds';
import CommonAgenda from './CommonAgenda/CommonAgenda';
import RegistratioUnnecessaryChange from './RegistratioUnnecessaryChangeAOA/RegistratioUnnecessaryChangeAOA';
import ApprovalofExecutiveRemunerationLimit from './ApprovalofExecutiveRemunerationLimit/ApprovalofExecutiveRemunerationLimit';
import ExecutiveRemunerationDecision from './ExecutiveRemunerationDecision/ExecutiveRemunerationDecision';
import Executives from './Executives/Executives';
import RightsIssue from './RightsIssue/RightsIssue';
import AdditionalAoAChange from './AdditionalAoAChange/AdditionalAoAChange';
import RepresentativeAddressChange from './RepresentativeAddressChange/RepresentativeAddressChange';
import ParValueDivisionMerger from './ParValueDivisionMerger/ParValueDivisionMerger';

import type {
    SelectedAgenda, I본점의_주소_변경 , I재무재표_승인 , I신주발행_무상증자 , I전환사채_발행 , CommonAgendaDetail, I등기가_불필요한_정관변경, 
    I임원_보수_한도_승인, I임원_보수_결정, I임원_취임_중임_사임_퇴임,  I신주발행_유상증자, I등기신청을_위한_추가_정관변경, I대표이사_주소_변경, I액면금분할_합병
} from '@model/Corp/Agenda/Step/agendaStepModel.interface';

interface AgendaInputProps {
    agendaIndex : number,
    agenda : SelectedAgenda,
    openFirst : () => void
}

const AgendaInput = React.memo(function (props : AgendaInputProps) {
    return useObserver(() => (
        <> 
            <input type="hidden" name="agendaDetail.agendaType" value={props.agenda.agendaType}/>
            <input type="hidden" name="id" value={props.agenda.id || undefined}/>
            {props.agenda.agendaType === "본점의_주소_변경" && 
                <HeadOfficeAddressChange agenda={props.agenda as SelectedAgenda<I본점의_주소_변경>} agendaIndex={props.agendaIndex}/>
            }
            {props.agenda.agendaType === "재무제표_승인" && 
                <ApprovaOfFinancialStatements agenda={props.agenda as SelectedAgenda<I재무재표_승인>} agendaIndex={props.agendaIndex}/>
            }
            {props.agenda.agendaType === "대표이사_주소_변경" && 
                <RepresentativeAddressChange agenda={props.agenda as SelectedAgenda<I대표이사_주소_변경>} agendaIndex={props.agendaIndex}/>
            }
            {props.agenda.agendaType === "신주발행_무상증자" && 
                <BonusIssue agenda={props.agenda as SelectedAgenda<I신주발행_무상증자>} agendaIndex={props.agendaIndex}/>
            }
            {props.agenda.agendaType === "전환사채_발행" && 
                <IssuanceOfConvertibleBonds agenda={props.agenda as SelectedAgenda<I전환사채_발행>} agendaIndex={props.agendaIndex}/>
            }
            {(props.agenda.agendaType === "임원_보수_지급규정_승인" || props.agenda.agendaType === "임원_상여금_지급규정_승인" || props.agenda.agendaType === "임원_퇴직금_지급규정_승인" || props.agenda.agendaType === "주주총회_안건_자유입력" || props.agenda.agendaType === "이사회_안건_자유입력" || props.agenda.agendaType === "차등배당" || props.agenda.agendaType === "중간배당" || props.agenda.agendaType === "부동산_매각" || props.agenda.agendaType === "자금차입" || props.agenda.agendaType === "자금차입_및_담보제공" || props.agenda.agendaType === "자회사_설립" || props.agenda.agendaType === "이사의_자기거래_승인" || props.agenda.agendaType === "임원_무보수_결의") && 
                <CommonAgenda agenda={props.agenda as SelectedAgenda<CommonAgendaDetail>} agendaIndex={props.agendaIndex}/>
            }
            {props.agenda.agendaType === "등기가_불필요한_정관변경" && 
                <RegistratioUnnecessaryChange agenda={props.agenda as SelectedAgenda<I등기가_불필요한_정관변경>} agendaIndex={props.agendaIndex}/>
            }
            {props.agenda.agendaType === "임원_보수_한도_승인" && 
                <ApprovalofExecutiveRemunerationLimit agenda={props.agenda as SelectedAgenda<I임원_보수_한도_승인>} agendaIndex={props.agendaIndex}/>
            }
            {props.agenda.agendaType === "임원_보수_결정" && 
                <ExecutiveRemunerationDecision agenda={props.agenda as SelectedAgenda<I임원_보수_결정>} agendaIndex={props.agendaIndex}/>
            }
            {props.agenda.agendaType === "임원_취임_중임_사임_퇴임" && 
                <Executives agenda={props.agenda as SelectedAgenda<I임원_취임_중임_사임_퇴임>} agendaIndex={props.agendaIndex} openFirst={props.openFirst}/>
            }
            {props.agenda.agendaType === "신주발행_유상증자" && 
                <RightsIssue agenda={props.agenda as SelectedAgenda<I신주발행_유상증자>} agendaIndex={props.agendaIndex}/>
            }
            {props.agenda.agendaType === "등기신청을_위한_추가_정관변경" && 
                <AdditionalAoAChange agenda={props.agenda as SelectedAgenda<I등기신청을_위한_추가_정관변경>} agendaIndex={props.agendaIndex}/>
            }
            {props.agenda.agendaType === "액면금분할_합병" && 
                <ParValueDivisionMerger agenda={props.agenda as SelectedAgenda<I액면금분할_합병>} agendaIndex={props.agendaIndex}/>
            }
        </>
    ));
})
export default AgendaInput;