import { observable } from 'mobx';

const joinBusinessRegistrationModel = observable({
    email: '',
    name: '',
    setExisting(data) {
        this.email = data.email || '';
        this.name = data.name || '';
    }
});

export default joinBusinessRegistrationModel;