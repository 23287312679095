import { useState } from 'react';
import { useObserver } from 'mobx-react';
import vm from './BankBookVm';
import bst from '../Basic.module.scss';
import st from './BankBook.module.scss';
import clsx from 'clsx';
import copyText from '@standby/common-ui/module/copyText';
import CopyBtn from '@standby/common-ui/components/atoms/Button/CopyBtn/CopyBtn';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import Table from '@standby/common-ui/components/atoms/Table/Table';
import ToastMessage from '@standby/common-ui/components/atoms/ToastMessage/ToastMessage';

interface CopyBtnWithToastProps {
    className: string;
    toastMessageClassName: string;
    copyText: string;
    children: string;
}

function CopyBtnWithToast(props: CopyBtnWithToastProps) {

    const [showYn, setShowYn] = useState<boolean>(false);

    const _onClick = (_copyText: string) => {
        setShowYn(true);
        copyText(_copyText);
        setTimeout(() => setShowYn(false), 2000);
    }

    return useObserver(() => (
        <div className={st.toastMessageBtnBox}>
            {showYn && <ToastMessage className={props.toastMessageClassName}>복사 되었어요!</ToastMessage>}
            <button type="button" className={props.className} onClick={() => _onClick(props.copyText)}>{props.children}</button>           
        </div>
    ))
};

function BankBook() {
    return useObserver(() => (
        <div className={bst.registraionRow}>
            <div className={bst.registrationTitle}>
                <span className={bst.bold}>통장사본 편리하게<br/>관리하세요!</span>
            </div>
            <div className={bst.registrationContent}>
                <div className={st.bankbook}> 
                    <div className={st.header}>
                        <div className={st.title}>총 {vm.bankbooks.length}개</div>
                        <SquareBtn className={st.addBtn} onClick={() => vm.moveBankbook()}>통장사본 추가/변경하기</SquareBtn>
                    </div>
                    <div className={st.bankbookInfoBox}>
                        {vm.bankbooks.length < 3 ?
                        <>
                            {vm.bankbooks.map((bankbook, idx) => {
                                return (
                                    <div className={st.bankbookBox} key={idx}>
                                        <div className={st.bank}>
                                            <div className={clsx(st.bankName, st[bankbook.bankName || ""])}>{bankbook.bankName}</div>
                                            <div className={st.accountUsage}>{bankbook.accountUsage}</div>
                                        </div>
                                        <div className={st.bankInfo}>
                                            <div className={st.nameBox}>
                                                <div className={st.title}>예금주</div>
                                                <div className={st.name}>{vm.companyName}</div>
                                            </div>
                                            <div className={st.accountBox}>
                                                <div className={st.info}>
                                                    <div className={st.title}>계좌번호</div>
                                                    <div className={st.account}>{bankbook.accountNumber}</div>
                                                </div>
                                                <CopyBtnWithToast className={st.accountInfoCopyBtn} toastMessageClassName={st.toastMessage} copyText={bankbook.accountNumber}>계좌번호 복사</CopyBtnWithToast>
                                            </div>
                                            <div className={st.btnArea}>
                                                <CopyBtnWithToast className={st.accountInfoCopyBtn} toastMessageClassName={st.toastMessage} copyText={vm.accountInfoCopyText(bankbook.bankName, bankbook.accountNumber)}>계좌 정보 복사</CopyBtnWithToast>
                                                <button type="button" className={st.bankbookDownloadBtn} onClick={() => vm.fileDownload(bankbook.fileDownloadUrl || "")}><span className={st.text}>통장사본 다운로드</span></button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                        :
                        <>
                            <Table className={st.table}>
                                <colgroup>
                                    <col width="46"/>
                                    <col width="130"/>
                                    <col/>
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>순번</th>
                                        <th>은행</th>
                                        <th>계좌번호</th>
                                        <th>계좌용도</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {vm.bankbooks.map((bankbook, idx) => {
                                        return (
                                            <tr>
                                                <td>{idx+1}</td>
                                                <td><span className={clsx(st.bankName, st[bankbook.bankName || ""])}>{bankbook.bankName}</span></td>
                                                <td>
                                                    <CopyBtn className={st.accountNumber} messageClassName={st.accountNumberCopyMessage} copyText={bankbook.accountNumber}>{bankbook.accountNumber}</CopyBtn>
                                                </td>
                                                <td className={st.accountInfo}>
                                                    <div className={st.accountUsage}>{bankbook.accountUsage || '-'}</div>
                                                    <div className={st.btnArea}>
                                                        <CopyBtnWithToast className={st.accountInfoCopyBtn} toastMessageClassName={st.toastMessage} copyText={vm.accountInfoCopyText(bankbook.bankName, bankbook.accountNumber)}>계좌 정보 복사</CopyBtnWithToast>
                                                        <button type="button" className={st.bankbookDownloadBtn} onClick={() => vm.fileDownload(bankbook.fileDownloadUrl || "")}><span className={st.text}>통장사본 다운로드</span></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </>
                        }                
                    </div>
                </div>
            </div>  
        </div>
    ));
}
export default BankBook;
