import { useObserver } from 'mobx-react';
import st from './TelephoneTipModal.module.scss';
function TelephoneTipModal() {
    return useObserver(() => (
        <div className={st.telephoneTipModal}>
            <div className={st.info}>02 등 지역번호를 사용하면서 비용을 아껴보세요!</div>
            <ol>
                <li>
                    02 등 지역번호(일반전화)를 추가하고, 인터넷 전화 n대를 선택하세요.
                    <ul>
                        <li>02 등 지역번호는 일반전화 신청 시 받을 수 있는 번호에요.</li>
                    </ul>
                </li>
                <li>
                    개통 후, 일반 전화 1대를 바로 해지하세요.
                    <ul>
                        <li>일반 전화는 개통 후 바로 해지가 가능하며, 해지해도 02 등 지역번호<br/>는 남아있어요.</li>
                        <li>단, 다른 전화 1대가 꼭 필요하기에 인터넷 전화는 남겨두어야해요.</li>
                    </ul>
                </li>
            </ol>
        </div>
    ));
}
export default TelephoneTipModal;