import { useObserver } from 'mobx-react';
import vm from './PersonInChargeVm';
import st from '../AgendaDashboard.module.scss';
import Cst from './PersonInCharge.module.scss';
import CopyBtn from '@standby/common-ui/components/atoms/Button/CopyBtn/CopyBtn';
import clsx from 'clsx';

function PersonInCharge() {
    return useObserver(() => (
        <div className={st.item}>
            <div className={st.title}>고객 담당자</div>
            <div className={clsx(st.content, Cst.personInCharge)}>
                <CopyBtn copyText={vm.agendaProgress.personInCharge.name} className={Cst.copyBtn}>{vm.agendaProgress.personInCharge.name}</CopyBtn>
                <CopyBtn copyText={vm.agendaProgress.personInCharge.phoneNum} className={Cst.copyBtn}>{vm.agendaProgress.personInCharge.phoneNum}</CopyBtn>
                <CopyBtn copyText={vm.agendaProgress.personInCharge.email} className={Cst.copyBtn}>{vm.agendaProgress.personInCharge.email}</CopyBtn>
            </div>
        </div>
    ));
}
export default PersonInCharge;