import type { Banks } from '@model/Corp/BusinessRegistration/businessRegistrationModel.interface';
import formDataToJson from '@common/module/submit';
import businessRegistrationModel from '@model/Corp/BusinessRegistration/businessRegistrationModel';
import businessRegistrationService from '@service/Corp/BusinessRegistration/businessRegistrationService';
import corpModel from '@model/Corp/corpModel';
import { runInAction } from 'mobx';
import portalModel from '@model/portalModel';
import AddAccount from '@pages/Corp/BusinessRegistration/BusinessRegistrationDocument/AddAccount/AddAccount';
import { _alert, _confirm } from '@model/dialogModel';
import clsx from 'clsx';
import st from './Bankbook.module.scss';
import { arrayMoveImmutable } from 'array-move';

class BankbookVm {
    load() {
        businessRegistrationService.documentDetail();
    }
    async submit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if(formDataToJson(e.target).error) {
            return
        } else {
            let param = formDataToJson(e.target).data;
            await businessRegistrationService.sequenceBankbook(param);      
            await _alert('수정되었어요.');
        }
    }
    get bankbooks() {
        return businessRegistrationModel.bankbooks;
    }
    fileDownload(downloadLink: string) {
        businessRegistrationService.fileDownload(downloadLink);
    }
    get companyName() {
        const company = corpModel.company;
        let companyName: string = "";
        
        if(company.prefixedYn) {
            if(company.corpType === '주식회사') {
                companyName = `(주)${company.companyName}`;
            } else if(company.corpType === '유한회사' || company.corpType === '유한책임회사') {
                companyName = `(유)${company.companyName}`;
            }
        } else {
            if(company.corpType === '주식회사') {
                companyName = `${company.companyName}(주)`;
            } else if(company.corpType === '유한회사' || company.corpType === '유한책임회사') {
                companyName = `${company.companyName}(유)`;
            }
        }

        return companyName;
    }
    accountInfoCopyText(bankName: Banks, accountNumber: string) {
        let copyString: string = "";
        copyString = bankName + " " + accountNumber + " " + this.companyName;
        return copyString;
    }
    addBankbook() {
        runInAction(() => {
            portalModel.title = '새로운 계좌 추가하기';
            portalModel.content = <AddAccount />;
        })
    }
    changeBankbook(id: number, state: 'Change') {
        const bankInfo = this.bankbooks.find(item => item.id === id);
        runInAction(() => {
            portalModel.title = '계좌정보 수정';
            portalModel.content = <AddAccount bankbookId={id} state={state} bankName={bankInfo?.bankName || ""} accountUsage={bankInfo?.accountUsage} accountNumber={bankInfo?.accountNumber}/>;
        })
    }
    async deleteBankbook(id: number) {
        const bankInfo = this.bankbooks.find(item => item.id === id);
        
        const DeleteStyleComponents = 
            <div className={st.deleteBankInfo}>
                <div className={st.bankInfo}>
                    <div className={clsx(st.bankName, st[bankInfo?.bankName || ""])}>{bankInfo?.bankName}</div>
                    <div className={st.accountNumber}>{bankInfo?.accountNumber}</div>
                </div>
                {(bankInfo?.viewHistories && bankInfo?.viewHistories.length !== 0) && 
                    <div className={st.viewHistory}>
                        <div className={st.title}>이 통장사본은 아래 분에게 발송된 상태에요. 삭제 후에는 수신인이 열람할 수 없어요.</div>
                        <ul className={st.userList}>
                            {bankInfo?.viewHistories.map((viewHistory, idx) => {
                                return (
                                    <li key={idx}>{viewHistory.userName}님 (열람기간 {viewHistory.expireDate})</li>
                                )
                            })}
                        </ul>
                    </div>
                }
            </div>;

        if(await _confirm('아래 통장사본을 삭제하나요?', '', '', '', DeleteStyleComponents)) {
            await businessRegistrationService.deleteBankbook(id);
        }  
    }
    onSortEnd(oldIndex: number, newIndex: number) {
        businessRegistrationModel.bankbooks = arrayMoveImmutable(businessRegistrationModel.bankbooks, oldIndex, newIndex)
    }
}

export default new BankbookVm();