import userModel from '@model/userModel';
import portalModel from '@model/portalModel';
import { dialogModel } from '@model/dialogModel';
import presetService from '@service/presetService';
import Cookies from 'js-cookie';
import ChannelService from '@common/module/ChannelService';
import { runInAction } from 'mobx';
import applicationModel from '@model/applicationModel';
import socketService from '@service/socketService';
import ReactGA from 'react-ga4'
import loadingModel from '@model/loadingModel';

class AppVm {
    async loginedStart() {
        if(!userModel.user.name || !userModel.user.email) {
            await presetService.load();
        }
    }
    socketDisConnect() {
        socketService.disConnect();
    }
    get user() {
        return userModel.user;
    }
    get isLogined() {
        if((Cookies.get('accessToken') && Cookies.get('refreshToken')) || localStorage.getItem('rememberMeToken')) {
            return true;
        }else{
            return false;
        }
    }
    //popup 관련 함수들
    get popupContent() {
        return portalModel.content;
    }
    get popupTitle() {
        return portalModel.title;
    }
    get popupCloseBtn() {
        return portalModel.closeBtn;
    }
    get popupRadius() {
        return portalModel.radius;
    }
    popupClose() {
        runInAction(() => {
            portalModel.content = null;
            portalModel.title = '';
            portalModel.closeBtnDarkYn = false;
            portalModel.closeBtn = true;
            portalModel.radius = false;
        })
    }
    get popupCloseBtnDarkYn() {
        return portalModel.closeBtnDarkYn;
    }
    get portalModel() {
        return portalModel;
    }
    //dialog 관련 함수들
    get dialogType() {
        return dialogModel.type;
    }
    get dialogTitle() {
        return dialogModel.title;
    }
    get dialogContent() {
        return dialogModel.content;
    }
    get dialogBtn() {
        return dialogModel.btn;
    }
    get dialogAlertBtnText() {
        return dialogModel.alertBtnText;
    }
    get dialogConfirmBtnText() {
        return dialogModel.confirmBtnText;
    }
    get dialogCancelBtnText() {
        return dialogModel.cancelBtnText;
    }
    get dialogContentJSX() {
        return dialogModel.contentJSX;
    }
    get dialogIcon() {
        return dialogModel.icon;
    }
    //로딩 관련 함수들
    get loadingShowYn() {
        return loadingModel.showYn;
    }
    launchChannelService(channelMemberId, urlPath, openChannel) {
        if(urlPath) {
            const type = urlPath.indexOf("accounting") !== -1 ? "accounting" : "standby" ;
            const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            const viewport = document.querySelector("meta[name=viewport]");
            if(type !== applicationModel.nowUrlCharge) {
                applicationModel.setNowUrlCharge(type);
                window.document.head.insertAdjacentHTML("beforeend", `<style>div.ch-mobile-messenger{width:100vW !important; height:100vH !important}</style>`)
                if(applicationModel.nowUrlCharge === "accounting") {
                    ChannelService.boot({
                        "pluginKey": '6ff9325f-da6b-43c8-8b6c-00e56a07ce9a',
                        "memberId": channelMemberId
                    });
                }else{
                    ChannelService.boot({
                        "pluginKey": 'deef52fd-f5e3-446f-82f0-fd0136e787fc',
                        "memberId": channelMemberId
                    });
                }
                if(openChannel === "open") {
                    ChannelService.showMessenger()
                }
                ChannelService.onHideMessenger(() => {
                    viewport.setAttribute('content', 'width=1280, initial-scale=0.1');
                })
                //마케팅 구글에널리틱스 관련 코드
                ChannelService.onShowMessenger(() => {
                    ReactGA.gtag('event', 'request_talk');
                })
            }
        }
    }
}
export default new AppVm();
