import { useObserver } from 'mobx-react';
import React from 'react';
import st from '../../Meetings/Meetings.module.scss';
import { I주주전원의_서면결의 } from '@model/Corp/Agenda/Step/FinalCheck/Final/FinalModel.interface';
import clsx from 'clsx';
import File from '../File/File';
import Table from '@standby/common-ui/components/atoms/Table/Table';
import QuestionTooltip from '@standby/common-ui/components/molecules/QuestionTooltip/QuestionTooltip';
import ToolTip from '@standby/common-ui/components/molecules/ToolTip/ToolTip';
import DownloadBtn from '@standby/common-ui/components/atoms/Button/DownloadBtn/DownloadBtn';
import { addCommas } from '@common/module/replaceNumber';
import { useLocation } from "react-router-dom";
import queryString from 'query-string';
import cst from './C주주전원의_서면결의.module.scss';
import vm from './C주주전원의_서면결의Vm';

interface C주주전원의_서면결의Props {
    meetingSchedule : I주주전원의_서면결의,
    meetingScheduleIndex : number,
    opener : 'final' | 'agendaDashboard'
}

const C주주전원의_서면결의 = React.memo(function (props : C주주전원의_서면결의Props) {
    let location = useLocation();
    const agendaProgressId = queryString.parse(location.search).agendaProgressId as string;

    return useObserver(() => (
        <div className={st.meeting}>
            <div className={st.sideTitle}>{props.meetingScheduleIndex + 1}. {props.meetingSchedule.title}</div>
            <div className={st.meetingInfo}>
                <div className={st.detailTitle}>{props.meetingSchedule.detailTitle}</div>
                <ul className={st.detailList}>
                    <li>
                        <div className={clsx(st.title, st.agenda)}>안건</div>
                        <div className={st.content}>
                            <ul className={st.agendaList}>
                                {props.meetingSchedule.agendas.map((agenda, index) => (
                                    <li key={index}>
                                        {index + 1}. {agenda}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </li>
                    <li>
                        <div className={clsx(st.title, st.minutes)}>결의서</div>
                        <div className={st.content}>
                            <File opener={props.opener} file={props.meetingSchedule.files.서면결의서} id={props.meetingSchedule.id} type='서면결의서'/>
                        </div>
                    </li>
                    <li>
                        <div className={clsx(st.title, st.date)}>일시</div>
                        <div className={st.content}>{props.meetingSchedule.decisionAt}</div>
                    </li>
                </ul>
                {props.meetingSchedule.sectionType !== "NONE" && 
                    <div className={st.personResultList}>
                        {props.meetingSchedule.sectionType === "주주총회" && 
                            <>
                                <div className={st.title}>소집통지 생략을 위한 주주동의 결과</div>
                                <div className={st.qnaWrap}>
                                    <QuestionTooltip title="주주총회 소집통지 생략 안내" style={{width:'460px'}}>
                                        <div className={st.tooltipContent}>
                                            <div className={st.qna}>
                                                <div className={st.qnaTitle}>자본금 10억 미만이라면, 모든 주주가 동의시 소집통지를 생략할 수 있어요.</div>
                                                <div className={st.qnaContent}>
                                                    <ul>
                                                        <li>원칙상 정관에 따라 소집통지를 보내고, 10~14일을 기다린 후 주주총회를 열 수 있어요. 하지만 모든 주주가 생략하면 바로 오늘이라도 주주총회를 할 수 있어요.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className={st.qna}>
                                                <div className={st.qnaTitle}>주주들에게 주주총회 소집통지 생략동의를 클릭 몇 번으로 받아보세요.</div>
                                                <div className={st.qnaContent}>
                                                    <ul>
                                                        <li>스탠바이에서는 클릭 몇 번으로 주주들에게 주주총회 소집통지 생략동의를 받을 수 있어요. 주주들에게 동의서에 직접 도장을 받지 않아도 되니 매우 편리해요!</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </QuestionTooltip>
                                </div>
                            </>
                        }
                        {props.meetingSchedule.sectionType === "주주총회_신주발행" && 
                            <>
                                <div className={st.title}>소집통지 생략 및 신주발행을 위한 주주동의 결과</div>
                                <div className={st.qnaWrap}>
                                    <QuestionTooltip title="주주 동의가 필요한 이유" style={{width:'460px'}}>
                                        <div className={st.tooltipContent}>
                                            <div className={st.qnaUpperTitle}>주주총회 동의</div>
                                            <div className={st.qna}>
                                                <div className={st.qnaTitle}>자본금 10억 미만이라면, 모든 주주가 동의시 소집통지를 생략할 수 있어요.</div>
                                                <div className={st.qnaContent}>
                                                    <ul>
                                                        <li>원칙상 정관에 따라 소집통지를 보내고, 10~14일을 기다린 후 주주총회를 열 수 있어요. 하지만 모든 주주가 생략하면 바로 오늘이라도 주주총회를 할 수 있어요.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className={st.qna}>
                                                <div className={st.qnaTitle}>주주들에게 주주총회 소집통지 생략동의를 클릭 몇 번으로 받아보세요.</div>
                                                <div className={st.qnaContent}>
                                                    <ul>
                                                        <li>스탠바이에서는 클릭 몇 번으로 주주들에게 소집통지 생략동의를 받을 수 있어요. 주주들에게 동의서에 직접 도장을 받지 않아도 되니 매우 편리해요!</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className={clsx(st.qnaUpperTitle, st.borderTop)}>신주발행 동의</div>
                                            <div className={st.qna}>
                                                <div className={st.qnaTitle}>원칙상 신주는 주주에게 발행해야 해요.</div>
                                                <div className={st.qnaContent}>
                                                    <ul>
                                                        <li>주주가 가진 주식수에 따라 신주를 배정받을 권리가 있어요. (상법 제418조 제1항)</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className={st.qna}>
                                                <div className={st.qnaTitle}>다만 정당한 목적이 있는 경우 제3자에게 발행할 수 있어요.</div>
                                                <div className={st.qnaContent}>
                                                    <ul>
                                                        <li>신기술 도입, 재무구조 개선 등 경영상 목적을 달성하기 위해 필요한 경우에는 제3자에게 발행이 가능해요. (상법 제418조 제2항)</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className={st.qna}>
                                                <div className={st.qnaTitle}>이 경우 주주에게 제3자에게 신주발행을 한다는 사실을 통지 또는 공고해야 해요.</div>
                                                <div className={st.qnaContent}>
                                                    <ul>
                                                        <li>신주발행을 통해 주주의 지분율이 낮아지므로, 최소 14일 전에 회사는 주주에게 신주발행 사실을 알려주도록 상법이 정하고 있어요. (상법 제418조 제4항)</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className={st.qna}>
                                                <div className={st.qnaTitle}>다만 모든 주주가 동의하면 통지/공고를 생략하고 2주를 기다리지 않아도 돼요.</div>
                                                <div className={st.qnaContent}>
                                                    <ul>
                                                        <li>통지/공고는 주주의 권리를 보호하기 위한 것이니, 주주들이 동의하면 절차를 생략할 수 있어요.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </QuestionTooltip>
                                </div>
                            </>
                        }
                        {props.meetingSchedule.sectionType === "신주발행" && 
                            <>
                                <div className={st.title}>신주발행을 위한 주주동의 결과</div>
                                <div className={st.qnaWrap}>
                                    <QuestionTooltip title="주주 동의가 필요한 이유" style={{width:'460px'}}>
                                        <div className={st.tooltipContent}>
                                            <div className={st.qna}>
                                                <div className={st.qnaTitle}>원칙상 신주는 주주에게 발행해야 해요.</div>
                                                <div className={st.qnaContent}>
                                                    <ul>
                                                        <li>주주가 가진 주식수에 따라 신주를 배정받을 권리가 있어요. (상법 제418조 제1항)</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className={st.qna}>
                                                <div className={st.qnaTitle}>다만 정당한 목적이 있는 경우 제3자에게 발행할 수 있어요.</div>
                                                <div className={st.qnaContent}>
                                                    <ul>
                                                        <li>신기술 도입, 재무구조 개선 등 경영상 목적을 달성하기 위해 필요한 경우에는 제3자에게 발행이 가능해요. (상법 제418조 제2항)</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className={st.qna}>
                                                <div className={st.qnaTitle}>이 경우 주주에게 제3자에게 신주발행을 한다는 사실을 통지 또는 공고해야 해요.</div>
                                                <div className={st.qnaContent}>
                                                    <ul>
                                                        <li>신주발행을 통해 주주의 지분율이 낮아지므로, 최소 14일 전에 회사는 주주에게 신주발행 사실을 알려주도록 상법이 정하고 있어요. (상법 제418조 제4항)</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className={st.qna}>
                                                <div className={st.qnaTitle}>다만 모든 주주가 동의하면 통지/공고를 생략하고 2주를 기다리지 않아도 돼요.</div>
                                                <div className={st.qnaContent}>
                                                    <ul>
                                                        <li>통지/공고는 주주의 권리를 보호하기 위한 것이니, 주주들의 동의하면 절차를 생략할 수 있어요.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </QuestionTooltip>
                                </div>
                            </>
                        }
                        <Table className={clsx(st.personListTable, cst.table)}>
                            <colgroup>
                                <col width="190" />
                                <col width="89" />
                                <col width="84" />
                                <col width="110" />
                                <col width="152" />
                                <col width="104" />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>주주명</th>
                                    <th>동의여부</th>
                                    <th>동의일자</th>
                                    <th style={{textAlign:'center'}} className={clsx(st.download, props.meetingSchedule?.completed ? st.active : st.disabled)}>
                                        <span>다운로드</span>
                                        {props.meetingSchedule?.completed && 
                                            <button type="button" className={st.allDownloadBtn} onClick={() => vm.allDownload(agendaProgressId, props.meetingSchedule.sectionType, props.opener)}>모두 다운로드</button>
                                        }
                                    </th>
                                    <th style={{textAlign:'left'}}>주식종류</th>
                                    <th>주식수</th>
                                    <th>지분율</th>
                                </tr>
                            </thead>
                            {props.meetingSchedule?.shareholders?.map((shareholder, idx) => {
                                if((idx < 6 || (idx > 5 && vm.state.moreViewYn))) {
                                    return (
                                        <tbody key={idx}>
                                            {shareholder.stocks.map((stock, idx2) => {
                                                return (
                                                    <tr key={idx2}>
                                                        {idx2 === 0 &&
                                                        <>
                                                            <td rowSpan={shareholder.stocks.length}><span>{idx+1}</span>{shareholder.name}</td>
                                                            <td rowSpan={shareholder.stocks.length}>
                                                                <div className={vm.status(shareholder.status)?._className}>
                                                                    {vm.status(shareholder.status)?.text}
                                                                    {shareholder.status === "발송실패" && 
                                                                        <ToolTip className={st.tooltip}>
                                                                            일시적 오류가 발생하여 발송에 실패했어요. 이메일, 휴대번호가 정확한지 확인해보고, 재발송을 해주세요. 만약 재발송에도 실패한다면 오른쪽 아래 채팅버튼을 눌러 문의를 남겨주세요.
                                                                        </ToolTip>
                                                                    }
                                                                    {shareholder.status === "발송실패_알림톡" && 
                                                                        <ToolTip className={st.tooltip}>
                                                                            일시적으로 오류가 발생하여 알림톡 발송에 실패했어요. (이메일은 발송성공) 휴대번호가 정확한지 확인해보고, 재발송을 해주세요. 만약 재발송에도 실패한다면 오른쪽 아래 채팅버튼을 눌러 문의를 남겨주세요.
                                                                        </ToolTip>
                                                                    }
                                                                    {shareholder.status === "발송실패_메일" && 
                                                                        <ToolTip className={st.tooltip}>
                                                                            일시적 오류가 발생하여 메일 발송에 실패했어요. (알림톡은 발송성공) 이메일 주소가 정확한지 확인해보고, 재발송을 해주세요. 만약 재발송에도 실패한다면 오른쪽 아래 채팅버튼을 눌러 문의를 남겨주세요.
                                                                        </ToolTip>
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td rowSpan={shareholder.stocks.length}>{shareholder.completedDate}</td>
                                                            <td rowSpan={shareholder.stocks.length}>
                                                                <DownloadBtn disabled={!shareholder.downloadUrl} onClick={() => vm.download(shareholder.downloadUrl as string)}>동의서 다운로드</DownloadBtn>
                                                            </td>
                                                        </>
                                                        }
                                                        <td className={st.stockName}>{stock.name}</td>
                                                        <td className={st.stockNumber}>{addCommas(stock.number)} 주</td>
                                                        <td className={st.stockRate}>{stock.rate} %</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    )
                                }                        
                            })}
                        </Table>
                        {props.meetingSchedule?.shareholders?.length > 6 && 
                            <button type="button" className={st.moreViewBtn} onClick={() => vm.moreView()}><span className={vm.state.moreViewYn ? st.open : ""}>{vm.state.moreViewYn ? '접기' : '더보기'}</span></button>
                        }
                    </div>
                }
            </div>
        </div>
    ));
})

export default C주주전원의_서면결의;