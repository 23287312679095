import { useObserver } from 'mobx-react';
import vm from './RegistratioUnnecessaryChangeAOAVm';
import qst from '@pages/Corp/Agenda/Step/AgendaQuestion/commonQuestion.module.scss';
import st from './RegistratioUnnecessaryChangeAOA.module.scss';
import clsx from 'clsx';
import QuestionTooltip from '@standby/common-ui/components/molecules/QuestionTooltip/QuestionTooltip';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import TextArea from '@standby/common-ui/components/atoms/Input/TextArea/TextArea';
import CloseBtn from '@standby/common-ui/components/atoms/Button/CloseBtn/CloseBtn';
import AddBtn from '@standby/common-ui/components/atoms/Button/AddBtn/AddBtn';
import React from 'react';
import type {SelectedAgenda, I등기가_불필요한_정관변경} from '@model/Corp/Agenda/Step/agendaStepModel.interface';

interface RegistratioUnnecessaryChangeAOAProps {
    agenda : SelectedAgenda<I등기가_불필요한_정관변경>,
    agendaIndex : number
}

const RegistratioUnnecessaryChangeAOA = React.memo(function (props : RegistratioUnnecessaryChangeAOAProps) {
    return useObserver(() => (
        <div className={qst.questionForm}>
            <div className={qst.qusetion}>
                <div className={qst.questionTitle}>
                    <QuestionTooltip title="정관변경시 등기가 필요한 때는 언제일까?" style={{width:'460px'}}>
                        <div className={st.tooltipContent}>
                            <div className={st.exTitle}>정관과 등기부에 공통으로 들어가는 사항은, 정관변경시 등기가 필요해요.</div>
                            <div className={st.exContent}>예를 들면 상호, 목적, 공고방법, 스톡옵션 규정, 주식양도제한 규정, 본점 소재지, 액면금, 발행할 예정주식 수 등은 정관변경과 등기를 함께 해야 해요.</div>
                            <div className={st.exTitle}>등기부에 나오지 않은 정관 내용을 바꾸는 경우 등기가 필요하지 않아요.</div>
                            <div className={st.exContent}>이 경우 내부적 절차를 잘 진행하고 증빙서류를 마련해두면 되어요.</div>
                            <div className={st.exTitle}>잘 모르겠다면, 문의를 남겨주시면 도와드릴게요.</div>
                            <div className={st.exContent}>어떤 경우에 등기가 필요한지 궁금하다면, 스탠바이 고객센터에 문의를 남겨주세요. 제휴 법률사무소에서 등기가 필요한지 여부를 상담받을 수 있도록 도와드릴게요.</div>
                        </div>
                    </QuestionTooltip>
                </div>
            </div>
            <div className={qst.qusetion}>
                <div className={qst.questionTitle}>변경할 정관을 입력하세요.</div>
                {props.agenda.agendaDetail.articlesOfAssociations.map((articlesOfAssociation, index) => (
                    <div key={index} className={st.articleBox}>
                        <div className={st.articleTitle}>
                            변경할 정관 조항 {index+1}
                            {index !== 0 && <CloseBtn className={st.closeBtn} onClick={() => vm.delete(props.agendaIndex, index)} style={{width:'16px', height:'16px'}}/>}
                        </div>
                        <div className={clsx(qst.subTextInput, st.inputText)}>
                            <InputText name={`agendaDetail.articlesOfAssociations[${index}].regulationLocation`} value={articlesOfAssociation.regulationLocation} onChange={(e) => vm.regulationLocation(e, props.agendaIndex, index)} style={{width:'199px'}} placeholder="0" explain="몇 조*" errorText={<Error name={`agendaDetail.articlesOfAssociations[${index}].regulationLocation`} value={articlesOfAssociation.regulationLocation} style={{width:'260px'}} errorCase={{
                                required: '변경할 정관 조항이 몇 조인지 입력하세요.'
                            }}/>}
                            /><span className={clsx(qst.inputSubText, qst.hasEx)}>조</span>
                        </div>
                        <div className={st.textArea}>
                            <TextArea name={`agendaDetail.articlesOfAssociations[${index}].content`} onChange={(e) => vm.content(e, props.agendaIndex, index)} value={articlesOfAssociation.content} style={{height:"148px", resize:"none"}} explain="변경 후 정관 내용*" placeholder="예시) (2) 상법 제418조 제2항에 따라 본 회사의 경영상 목적을 달성하기 위하여 필요한 다음 각 호의 경우에는 주주 외의 제3자에게 신주를 배정할 수 있다."
                            errorText={<Error name={`agendaDetail.articlesOfAssociations[${index}].content`} value={articlesOfAssociation.content}  errorCase={{
                                required: '변경할 정관 내용을 입력하세요.'
                            }}
                            />}/>
                        </div>
                    </div>
                ))}
                <AddBtn className={st.addBtn} onClick={() => vm.add(props.agendaIndex)}>변경할 정관 조항 추가하기</AddBtn>
            </div>
        </div>
    ));
})
export default RegistratioUnnecessaryChangeAOA;