import { useObserver } from 'mobx-react';
import {Link } from "react-router-dom";
import vm from './StockVm';
import st from './Stock.module.scss';
import { addCommas } from '@common/module/replaceNumber';

function Stock() {
    return useObserver(() => (
        <div className={st.wrap}>
            <div className={st.shareholder}>
                <div className={st.title}>
                    <span>주주</span>
                    {vm.dashboardData?.shareholdersCount && 
                        <div className={st.content}>총 {vm.dashboardData.shareholdersCount}명</div>
                    }
                </div>
                <div className={st.content}>
                {/**
                 * @todo D3.js type에 맞춰 추후 TS로 변경
                 */}
                {vm.dashboardData.shareholders.length !== 0 ?
                    <>
                        <div className={st.chart}>
                            <svg width="100%" height="100%">
                                <g transform={`translate(48 48)`}>
                                    {vm.data.map((d, i) => (
                                        <g key={i} className="arc">
                                            <path className="arc" d={vm.createArc(d)} fill={vm.colors[i]} />
                                        </g>
                                    ))}
                                </g>
                            </svg>
                        </div>
                        <ul className={st.list}>
                            {vm.dashboardData.shareholders.map((shareholder, index) => (
                                <li key={index}>
                                    <span style={{fontWeight : index === 0 ? "500" : "400"}} title={shareholder.name}>{index+1}. {shareholder.name}님</span>
                                    <span>{shareholder.value}%</span>
                                </li>
                            ))}
                        </ul>
                    </> :
                    <div className={st.empty}>
                        주주명부 등록을 마친 후, 확인 가능해요!<br/>(최대 1영업일)
                    </div>
                }
                </div>
                <Link to={`/corp/stock/home?corp=${vm.id}`} className={st.moreView} type='button'>더보기</Link>
            </div>
            <div className={st.stock}>
                <div className={st.title}>
                    <span>주식</span>
                    <button type='button' className={st.openModalBtn} onClick={vm.agendaLinkModal}>자본금 증액하기</button>
                </div>
                <div className={st.content}>총 {addCommas(vm.dashboardData.stocksNumber)} 주 / {vm.dashboardData.numberOfStockTypes} 종류</div>
            </div>
        </div>
    ));
}
export default Stock;