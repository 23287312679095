
import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { useEffect, Fragment, useRef } from 'react';
import useComponentWillMount from '@common/hook/useComponentWillMount';
import ShareholderListVm from './ShareholderListVm';
import st from './ShareholderList.module.scss';
import PageTitle from '@commonComponents/PageTitle/PageTitle';
import ContentBox from '@commonComponents/ContentBox/ContentBox';

import Download from './Download/Download';
import Send from './Send/Send';

let vm;

function UnissuedStockCertificate() {
    
    useComponentWillMount(() => {
        vm = new ShareholderListVm();
    })

    return useObserver(() => (
        <>
            <PageTitle><span className={st.pageTitle}>주주명부</span></PageTitle>
            {vm?.state.pageState === 'download' && 
                <ContentBox title='주주명부 다운로드/이메일 발송' className={st.contentBox} titleStyle={{top:"15px", paddingLeft:"18px", fontSize:"18px"}}>
                    <div className={st.content}>
                        <Download next={() => vm.changeStateSend()} setSendParams={(param) => vm.setSendParams(param)}/>
                    </div>
                </ContentBox>
            }
            {vm?.state.pageState === 'send' && 
                <ContentBox leftSubTitle="주주명부 다운로드/이메일 발송" title='이메일 발송'  className={st.contentBox} titleStyle={{top:"15px", paddingLeft:"18px", fontSize:"18px"}}>
                    <div className={st.content}>
                        <Send sendParams={vm.sendParams} shareholderId={vm.shareholderId}/>
                    </div>
                </ContentBox>   
            }
        </>
    ));
}

export default UnissuedStockCertificate;