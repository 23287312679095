import { Fragment, useEffect } from 'react';
import { useObserver } from 'mobx-react';
import { useLocation } from "react-router-dom";
import queryString from 'query-string';
import bst from './BeforeFinal.module.scss';
import vm from './BeforeFinalVm';
import MeetingSchedule from '@src/pages/Corp/Agenda/Step/FinalCheck/BeforeFinalComponent/MeetingSchedule/MeetingSchedule';
import OnlineAcceptance from '@src/pages/Corp/Agenda/Step/FinalCheck/BeforeFinalComponent/OnlineAcceptance/OnlineAcceptance';
import OnlineMusterNotice from '@src/pages/Corp/Agenda/Step/FinalCheck/BeforeFinalComponent/OnlineMusterNotice/OnlineMusterNotice';
import PostMusterNoitice from '@src/pages/Corp/Agenda/Step/FinalCheck/BeforeFinalComponent/PostMusterNotice/PostMusterNotice';
import NoticeOfDraftOmitShareholders from '@src/pages/Corp/Agenda/Step/FinalCheck/BeforeFinalComponent/NoticeOfDraftOmitShareholders/NoticeOfDraftOmitShareholders';
import NoticeOfDraftOmitNewShareholders from '@src/pages/Corp/Agenda/Step/FinalCheck/BeforeFinalComponent/NoticeOfDraftOmitNewShareholders/NoticeOfDraftOmitNewShareholders';
import OnlineNewShareholdersNotice from '@src/pages/Corp/Agenda/Step/FinalCheck/BeforeFinalComponent/OnlineNewShareholdersNotice/OnlineNewShareholdersNotice';
import NewStockIssue from '@src/pages/Corp/Agenda/Step/FinalCheck/BeforeFinalComponent/NewStockIssue/NewStockIssue';
import NewStockNotice from '@src/pages/Corp/Agenda/Step/FinalCheck/BeforeFinalComponent/NewStockNotice/NewStockNotice';
import RegistrationAgency from '@src/pages/Corp/Agenda/Step/FinalCheck/BeforeFinalComponent/RegistrationAgency/RegistrationAgency';
import RegistrationSelf from '@src/pages/Corp/Agenda/Step/FinalCheck/BeforeFinalComponent/RegistrationSelf/RegistrationSelf';
import AutoDocument from '@src/pages/Corp/Agenda/Step/FinalCheck/BeforeFinalComponent/AutoDocument/AutoDocument';
import FileUpload from '@src/pages/Corp/Agenda/Step/FinalCheck/BeforeFinalComponent/FileUpload/FileUpload';
import FinalApplication from '@src/pages/Corp/Agenda/Step/FinalCheck/BeforeFinalComponent/FinalApplication/FinalApplication';
import AlreadyPrograssedRegistrationAgency from '@src/pages/Corp/Agenda/Step/FinalCheck/BeforeFinalComponent/AlreadyPrograssedRegistrationAgency/AlreadyPrograssedRegistrationAgency';

import type {
    I수신동의, I소집통지_온라인, I소집통지_우편, I주주동의_주주총회, I주주동의_주주총회_신주발행, I신주발행통지, I주주동의_신주발행, I주식발행공고, I등기대행, I셀프등기, I서류자동작성, I파일_업로드, I등기연결
} from '@src/model/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalModel.interface';

function BeforeFinal() {
    let location = useLocation();
    const agendaProgressId = queryString.parse(location.search).agendaProgressId as string;

    useEffect(() => {
        vm.load(agendaProgressId);
    }, [])

    return useObserver(() => (
        <>
            <div className={bst.mainTitle}>{vm.title}</div>
            {(vm.meetingSchedule && vm.meetingSchedule.length !== 0) && <MeetingSchedule agendaProgressId={agendaProgressId}/>}
            <div className={bst.beforeFinal}> 
                {vm.sectionList.map((section, idx) => (
                    <Fragment key={idx}>
                        {section.type === '수신동의' && <OnlineAcceptance index={idx} type={section.type} info={section.info as I수신동의} agendaProgressId={agendaProgressId}/>}
                        {section.type === '소집통지_온라인' && <OnlineMusterNotice index={idx} info={section.info as I소집통지_온라인} agendaProgressId={agendaProgressId}/>}
                        {section.type === '소집통지_우편' && <PostMusterNoitice index={idx} info={section.info as I소집통지_우편} agendaProgressId={agendaProgressId}/>}
                        {section.type === '주주동의_주주총회' && <NoticeOfDraftOmitShareholders index={idx} type={section.type} info={section.info as I주주동의_주주총회} agendaProgressId={agendaProgressId}/>}
                        {section.type === '주주동의_주주총회_신주발행' && <NoticeOfDraftOmitNewShareholders index={idx} type={section.type} info={section.info as I주주동의_주주총회_신주발행} agendaProgressId={agendaProgressId} />}
                        {section.type === '신주발행통지' && <OnlineNewShareholdersNotice index={idx} info={section.info as I신주발행통지} agendaProgressId={agendaProgressId}/>}
                        {section.type === '주주동의_신주발행' && <NewStockIssue index={idx} type={section.type} info={section.info as I주주동의_신주발행} agendaProgressId={agendaProgressId}/>}
                        {section.type === '주식발행공고' && <NewStockNotice index={idx} info={section.info as I주식발행공고} />}
                        {section.type === '등기대행' && <RegistrationAgency index={idx} info={section.info as I등기대행} agendaProgressId={agendaProgressId}/>}
                        {section.type === '등기대행_기진행' && <AlreadyPrograssedRegistrationAgency index={idx} info={section.info as I등기연결} agendaProgressId={agendaProgressId}/>}
                        {section.type === '셀프등기' && <RegistrationSelf index={idx} info={section.info as I셀프등기} agendaProgressId={agendaProgressId}/>}
                        {section.type === '서류자동작성' && <AutoDocument index={idx} info={section.info as I서류자동작성} documentSubmitYn={vm.state.documentSubmitYn}/>}
                        {section.type === '파일_업로드' && <FileUpload index={idx} info={section.info as I파일_업로드} agendaProgressId={agendaProgressId}/>}
                    </Fragment>
                ))}
            </div>
            {vm.displayFinalApplicationYn && <FinalApplication agendaProgressId={agendaProgressId} setDocumentSubmit={vm.setDocumentSubmit}/> }
        </>
    ));
}
export default BeforeFinal;