import { observable, runInAction, toJS } from 'mobx';

class ShareholderListVm {
    constructor() {
        this.state = observable({
            pageState: 'download',
            sendParams: {}
        });
    }
    changeStateSend() {
        this.state.pageState = 'send'
    }
    get sendParams() {
        return this.state.sendParams;
    }
    setSendParams(param) {
        this.state.sendParams = param;
    }
}

export default ShareholderListVm;