import { get, downloadFileGet, post, _delete, put, patch } from '@common/module/httpRequest';
import { runInAction } from 'mobx'; 
import {
    LoadRs, LoadDetailRs, Agenda, AgendaType, AgendaInfo, IA정관변경, IA임원_취임_중임_사임_퇴임, IA재무제표_승인, IA임원_보수_한도_승인,
    IA임원_보수_결정, IA본점의_주소_변경, IA신주발행_유상증자, IA신주발행_무상증자, IA전환사채_발행, IACommonAgenda, ExecutiveModalMembers, ExecutiveModalMember, IA액면금분할_합병, IA폼_없음,
    RnumberValidationRs, IA대표이사_주소_변경
} from './AgendaDashboardService.interface';
import AgendaDashboardModel from '@model/AgendaDashboard/AgendaDashboardModel';
import FinalModel from '@model/Corp/Agenda/Step/FinalCheck/Final/FinalModel';
import FinalService from '@service/Corp/Agenda/Step/FinalCheck/Final/FinalService';
import { _alert } from "@model/dialogModel";
import loadingModel from '@model/loadingModel';

class AgendaDashboardService {
    agendaInfo(agendaType : AgendaType, agendaInfo : AgendaInfo) {
        switch(agendaType){
            case "정관변경":
                {
                    const _agendaInfo = agendaInfo as IA정관변경;
                    return {
                        autoAddArticlesOfAssociations : _agendaInfo.autoAddArticlesOfAssociations.map((autoAddArticlesOfAssociation) => {
                            return {
                                origin : autoAddArticlesOfAssociation.origin,
                                latest : {
                                    category : autoAddArticlesOfAssociation?.latest?.category || "",
                                    content : autoAddArticlesOfAssociation?.latest?.content || "",
                                    description : autoAddArticlesOfAssociation?.latest?.description || "",
                                    issueLimitYn : autoAddArticlesOfAssociation?.latest?.issueLimitYn || false,
                                    보통주_issueLimit : autoAddArticlesOfAssociation?.latest?.보통주_issueLimit ?? null,
                                    우선주_issueLimit : autoAddArticlesOfAssociation?.latest?.우선주_issueLimit ?? null,
                                }
                            }
                        }),
                        directAddArticlesOfAssociations : _agendaInfo.directAddArticlesOfAssociations.map((directAddArticlesOfAssociation) => {
                            return {
                                regulationLocation : directAddArticlesOfAssociation?.regulationLocation || 0,
                                content : directAddArticlesOfAssociation?.content || ""
                            }
                        })
                    } as IA정관변경
                    break;
                }
            case "임원_취임_중임_사임_퇴임":
                {
                    const _agendaInfo = agendaInfo as IA임원_취임_중임_사임_퇴임;
                    return {
                        title : _agendaInfo?.title || "",
                        changeExecutive : _agendaInfo?.changeExecutive || "",
                        originExecutiveCount : _agendaInfo?.originExecutiveCount || 0,
                        originDirectorCount : _agendaInfo?.originDirectorCount || 0,
                        updateDirectorCount : _agendaInfo?.updateDirectorCount || 0,
                        updateExecutiveCount : _agendaInfo?.updateExecutiveCount || 0,
                        executives : _agendaInfo.executives.map((executive) => {
                            if('executiveMembers' in executive) {
                                const _executive = executive as ExecutiveModalMembers;
                                return {
                                    executiveMembers : _executive.executiveMembers.map((executiveMember) => {
                                        return {
                                            changeType : executiveMember?.changeType,
                                            originExecutive : {
                                                name : executiveMember?.originExecutive?.name || "",
                                                position : executiveMember?.originExecutive?.position || ""
                                            },
                                            updateExecutive : {
                                                name : executiveMember?.updateExecutive?.name || "",
                                                position : executiveMember?.updateExecutive?.position || ""
                                            }
                                        }
                                    })
                                } as ExecutiveModalMembers
            
                            }else{
                                const _executive = executive as ExecutiveModalMember;
                                return {
                                    changeType : _executive?.changeType,
                                    originExecutive : {
                                        name : _executive?.originExecutive?.name || "",
                                        position : _executive?.originExecutive?.position || ""
                                    },
                                    updateExecutive : {
                                        name : _executive?.updateExecutive?.name || "",
                                        position : _executive?.updateExecutive?.position || ""
                                    }
                                } as ExecutiveModalMember
                            }
                        })
                    } as IA임원_취임_중임_사임_퇴임
                    break;
                }
            case "재무제표_승인":
                {
                    const _agendaInfo = agendaInfo as IA재무제표_승인;
                    return {
                        fiscalYear : _agendaInfo?.fiscalYear || "",
                        downloadUrl : _agendaInfo?.downloadUrl || "",
                        downloadTitle : _agendaInfo?.downloadTitle || ""
                    } as IA재무제표_승인
                    break;
                }
            case "임원_보수_한도_승인":
                {
                    const _agendaInfo = agendaInfo as IA임원_보수_한도_승인;
                    return {
                        approvalStartAt : {
                            year : _agendaInfo?.approvalStartAt?.year || "",
                            month : _agendaInfo?.approvalStartAt?.month || "",
                            day : _agendaInfo?.approvalStartAt?.day || ""
                        },
                        approvalEndAt : {
                            year : _agendaInfo?.approvalEndAt?.year || "",
                            month : _agendaInfo?.approvalEndAt?.month || "",
                            day : _agendaInfo?.approvalEndAt?.day || ""
                        },
                        approvalType : _agendaInfo?.approvalType || "",
                        이사SalaryLimit : _agendaInfo?.이사SalaryLimit || 0,
                        감사SalaryLimit : _agendaInfo?.감사SalaryLimit || 0,
                        salaryLimitOfExecutives : _agendaInfo?.salaryLimitOfExecutives.map((salaryLimitOfExecutive) => {
                            return {
                                name : salaryLimitOfExecutive?.name || "",
                                position : salaryLimitOfExecutive?.position || "",
                                salary : salaryLimitOfExecutive?.salary || "",
                                newExecutiveYn: salaryLimitOfExecutive?.newExecutiveYn ?? null
                            }
                        })
                    } as IA임원_보수_한도_승인     
                    break;
                }
            case "임원_보수_결정":
                {
                    const _agendaInfo = agendaInfo as IA임원_보수_결정;
                    return {
                        decisionStartAt : {
                            year : _agendaInfo?.decisionStartAt?.year || "",
                            month : _agendaInfo?.decisionStartAt?.month || "",
                            day : _agendaInfo?.decisionStartAt?.day || ""
                        },
                        decisionEndAt : {
                            year : _agendaInfo?.decisionEndAt?.year || "",
                            month : _agendaInfo?.decisionEndAt?.month || "",
                            day : _agendaInfo?.decisionEndAt?.day || ""
                        },
                        salaryOfExecutives : _agendaInfo?.salaryOfExecutives.map((salaryOfExecutive) => {
                            return {
                                name : salaryOfExecutive?.name || "",
                                salary : salaryOfExecutive?.salary || "",
                                position : salaryOfExecutive?.position || "",
                                newExecutiveYn: salaryOfExecutive?.newExecutiveYn ?? false
                            }
                        })
                    } as IA임원_보수_결정       
                    break;
                }
            case "본점의_주소_변경":
                {
                    const _agendaInfo = agendaInfo as IA본점의_주소_변경;
                    return {
                        jurisdictionYn : _agendaInfo?.jurisdictionYn || false,
                        selectedMetropolitanType : _agendaInfo?.selectedMetropolitanType || "",
                        selectedSigungu : _agendaInfo?.selectedSigungu || ""
                    } as IA본점의_주소_변경
                    break;
                }
            case "신주발행_유상증자":
                {
                    const _agendaInfo = agendaInfo as IA신주발행_유상증자;
                    return {
                        assignmentType : _agendaInfo?.assignmentType || "",
                        noticeType : _agendaInfo?.noticeType || "",
                        newSharesAcquirers : _agendaInfo?.newSharesAcquirers.map((newSharesAcquirer) => {
                            return {
                                name : newSharesAcquirer?.name || "",
                                subscriptionAt : newSharesAcquirer?.subscriptionAt || "",
                                paymentAt : newSharesAcquirer?.paymentAt || "",
                                인수자격 : newSharesAcquirer?.인수자격 || "",
                                resourceType : newSharesAcquirer?.resourceType || "",
                                stocks : newSharesAcquirer?.stocks.map((stock) => {
                                    return {
                                        stockName : stock?.stockName || "",
                                        investment : stock?.investment || 0,
                                        number : stock?.number || 0,
                                        issuePrice : stock?.issuePrice || 0
                                    }
                                })
                            }
                        })
                    } as IA신주발행_유상증자
                    break;
                }
            case "신주발행_무상증자":
                {
                    const _agendaInfo = agendaInfo as IA신주발행_무상증자;
                    return {
                        freeIssueOfNewSharesType : _agendaInfo?.freeIssueOfNewSharesType || "",
                        increaseAmount : _agendaInfo?.increaseAmount || 0,
                        numberOfNewStocks : _agendaInfo?.numberOfNewStocks || 0,
                        shareholders : _agendaInfo?.shareholders.map((shareholder) => {
                            return {
                                name : shareholder?.name || "",
                                rate : shareholder?.rate + "%" || "",
                                numberOfStocks : shareholder?.numberOfStocks || 0
                            }
                        })
                    } as IA신주발행_무상증자
                    break;
                }
            case "전환사채_발행":
                {
                    const _agendaInfo = agendaInfo as IA전환사채_발행;
                    return {
                        totalAmount : _agendaInfo?.totalAmount || 0,
                        publicationTarget : _agendaInfo?.publicationTarget || "",
                        issueCount : _agendaInfo?.issueCount || 0
                    } as IA전환사채_발행
                    break;
                }
            case "임원_보수_지급규정_승인":
            case "임원_퇴직금_지급규정_승인":
            case "임원_상여금_지급규정_승인":
            case "차등배당":
            case "중간배당":
            case "부동산_매각":
            case "자금차입":
            case "자금차입_및_담보제공":
            case "자회사_설립":
            case "이사의_자기거래_승인":
            case "주주총회_안건_자유입력":
            case "이사회_안건_자유입력":
            case "임원_무보수_결의":
                {
                    const _agendaInfo = agendaInfo as IACommonAgenda;
                    return {
                        title : _agendaInfo?.title || "",
                        content : _agendaInfo?.content || "",
                        downloadUrl : _agendaInfo?.downloadUrl || "",
                        downloadTitle : _agendaInfo?.downloadTitle || ""
                    } as IACommonAgenda
                    break;
                }
            case "액면금분할_합병":
                {
                    const _agendaInfo = agendaInfo as IA액면금분할_합병;
                    return {
                        beforeParValue : _agendaInfo?.beforeParValue || 0,
                        afterParValue : _agendaInfo?.afterParValue || 0
                    } as IA액면금분할_합병
                    break;
                }
            case "폼_없음":
                {
                    const _agendaInfo = agendaInfo as IA폼_없음;
                    return {
                        title : _agendaInfo?.title || 0
                    } as IA폼_없음
                    break;
                }
            case "대표이사_주소_변경":
                {
                    const _agendaInfo = agendaInfo as IA대표이사_주소_변경;
                    return {
                        representatives : _agendaInfo?.representatives.map((representative) => representative || "")
                    } as IA대표이사_주소_변경
                    break;
                }
        }
    }
    setAgendas(agendas : Agenda[]) {
        AgendaDashboardModel.agendas = agendas.map((agenda) => {
            return {
                type : agenda.type || "",
                decisionBody : agenda.decisionBody.map(decision => decision || ""),
                info : this.agendaInfo(agenda.type, agenda.info)
            }
        })
    }
    async load(rNumber : string, cssn : string) {
        let _rNumber = '';
        if(rNumber) {
            _rNumber = `&rNumber=${rNumber}`
        }
        const res = await get<LoadRs>(`/company/agendaProgress/dashboard?cssn=${cssn}${_rNumber}`);
        runInAction(() => {
            AgendaDashboardModel.accessRoute = res.data?.accessRoute || null;
            AgendaDashboardModel.agendaProgressSelectList = res.data.agendaProgressSelectList.map((agendaProgressSelect) => {
                return {
                    id : agendaProgressSelect?.id ? (agendaProgressSelect.id).toString() : "",
                    name : agendaProgressSelect?.name || ""
                }
            });
            AgendaDashboardModel.selectedAgendaProgressId = res.data?.selectedAgendaProgressId ? (res.data?.selectedAgendaProgressId).toString() : "";
            AgendaDashboardModel.company = {
                name : res.data?.company?.name || "",
                cssn : res.data?.company?.cssn || "",
                pastNames : res.data?.company?.pastNames.map((pastName) => pastName || ""),
                copyOfRegister : {
                    name : res.data?.company?.copyOfRegister?.name || "",
                    downloadUrl : res.data?.company?.copyOfRegister?.downloadUrl || ""
                }
            }
            if(AgendaDashboardModel.selectedAgendaProgressId) {
                this.loadDetail(AgendaDashboardModel.selectedAgendaProgressId);
            }
        })
    }
    fileDown(url : string) {
        downloadFileGet(url);
    }
    async loadDetail(agendaProgressId : string) {
        const urlParams = new URL(window.location.href).searchParams;
        const rNumber = urlParams.get('rNumber');
        const res = await get<LoadDetailRs>(`/company/agendaProgress/dashboard/detail?agendaProgressId=${agendaProgressId}`);
        runInAction(() => {
            AgendaDashboardModel.rNumbers = res.data.rNumbers.length === 0 ?
                [{id : '', value : rNumber || '', editYn : false, originalValue : ''}]
                :
                res.data.rNumbers.map((rNumber) => {
                    return {
                        id : rNumber?.id ? (rNumber.id).toString() : "",
                        originalValue : rNumber?.value || "",
                        value : rNumber?.value || "",
                        editYn : false
                    }
                });
            AgendaDashboardModel.files = {
                aoa : {
                    downloadUrl : res.data?.files?.aoa?.downloadUrl || ''
                },
                shareholderList : {
                    name : res.data?.files?.shareholderList?.name || '',
                    downloadUrl : res.data?.files?.shareholderList?.downloadUrl || ''
                },
                shareholderListDate : res.data?.files?.shareholderListDate || null
            };
            AgendaDashboardModel.agendaProgress = {
                company : {
                    capital : res.data?.agendaProgress?.company?.capital || 0,
                    additionalCapital : res.data?.agendaProgress?.company?.additionalCapital || 0,
                    parValue : res.data?.agendaProgress?.company?.parValue || 0,
                    shareholders : res.data?.agendaProgress?.company?.shareholders.map((shareholder) => {
                        return {
                            type : shareholder?.type || '',
                            count : shareholder?.count || 0
                        }
                    }),
                },
                registrationAgency : {
                    jointCertificateUseYn : res.data?.agendaProgress?.registrationAgency?.jointCertificateUseYn ?? null,
                    digitalCertificate : {
                        company : {
                            status : res.data?.agendaProgress?.registrationAgency?.digitalCertificate?.company?.status || '',
                            date : res.data?.agendaProgress?.registrationAgency?.digitalCertificate?.company?.date || '',
                        },
                        shareholders : res.data?.agendaProgress?.registrationAgency?.digitalCertificate?.shareholders.map((shareholder) => {
                            return {
                                name : shareholder?.name || '',
                                status : shareholder?.status || null
                            }
                        }),
                    },
                    requirements : res.data?.agendaProgress?.registrationAgency?.requirements || "",
                    regAgencyCancelInfo : res.data?.agendaProgress?.registrationAgency?.regAgencyCancelInfo || "",
                },
                personInCharge : {
                    name : res.data?.agendaProgress?.personInCharge?.name || '',
                    phoneNum : res.data?.agendaProgress?.personInCharge?.phoneNum || '',
                    email : res.data?.agendaProgress?.personInCharge?.email || '',
                    telephoneCounselingYn : res.data?.agendaProgress?.personInCharge?.telephoneCounselingYn || false
                },
                boardOfDirectors : {
                    before : res.data?.agendaProgress?.boardOfDirectors?.before || false,
                    after : res.data?.agendaProgress?.boardOfDirectors?.after || false
                },
                decisionBody : res.data?.agendaProgress?.decisionBody.map(decisionBody => decisionBody || '')
            };
            AgendaDashboardModel.memo = res.data?.memo || '';
            AgendaDashboardModel.transmissionStatus = {
                totalCount : res.data?.transmissionStatus?.totalCount || 0,
                shareholderMeeting : {
                    title : res.data?.transmissionStatus?.shareholderMeeting?.title || "",
                    type : res.data?.transmissionStatus?.shareholderMeeting?.type || "",
                    count : res.data?.transmissionStatus?.shareholderMeeting?.count || 0
                },
                newStockAcquire : {
                    title : res.data?.transmissionStatus?.newStockAcquire?.title || "",
                    type : res.data?.transmissionStatus?.newStockAcquire?.type || "",
                    count : res.data?.transmissionStatus?.newStockAcquire?.count || 0
                }
            };
            AgendaDashboardModel.meetingScheduleChangeLockYn = res.data?.meetingScheduleChangeLockYn || false;
            AgendaDashboardModel.registrationAgencyType = res.data?.registrationAgencyType || null;
            this.setAgendas(res.data.agendas);
            FinalModel.meetingSchedule = res.data.meetingSchedule.map((meetingSchedule) => {
                return FinalService.setMeetingSchedule(meetingSchedule)
            })
        })
    }
    async rNumberValidation(rNumber : string) {
        const res = await post<RnumberValidationRs>('/company/agendaProgress/dashboard/rNumber/validate', {rNumber});
        return res.data;
    }
    async saveRnumber(rNumber : string, cssn : string, agendaProgressId : string) {
        await post('/company/agendaProgress/dashboard/rNumber', {
            rNumber, cssn, agendaProgressId
        });
        await _alert('r번호가 저장되었습니다.');
        this.loadDetail(agendaProgressId);
    }
    async removeRnumber(id : string, deleteFileYn : boolean, cssn : string, agendaProgressId : string) {
        await _delete('/company/agendaProgress/dashboard/rNumber', {
            id, cssn, deleteFileYn, agendaProgressId
        });
        await _alert('r번호가 삭제되었습니다.');
        await this.loadDetail(agendaProgressId);
    }
    async editRnumber(id : string, deleteFileYn : boolean, cssn : string, agendaProgressId : string, newRNumber : string) {
        await put('/company/agendaProgress/dashboard/rNumber', {
            id, cssn, deleteFileYn, agendaProgressId, newRNumber
        });
        await _alert('r번호가 수정되었습니다.');
        await this.loadDetail(agendaProgressId);
    }
    async lock(agendaProgressId : string, message : string) {
        await patch(`/company/agendaProgress/dashboard/meetingSchedule/lock?agendaProgressId=${agendaProgressId}`);
        await _alert(message);
        await this.loadDetail(agendaProgressId);
    }
    async changeRegistrationAgency(agendaProgressId : string){ 
        loadingModel.showYn = true;
        await patch(`/company/agendaProgress/dashboard/registrationAgency?agendaProgressId=${agendaProgressId}`);
        loadingModel.showYn = false;
        await _alert('변경되었습니다.');
        await this.loadDetail(agendaProgressId);
    }
}
export default new AgendaDashboardService();