
import { useObserver } from 'mobx-react';
import { useEffect } from 'react';
import { reaction } from 'mobx';
import DocumentVm from './DocumentVm';
import st from './Document.module.scss';
import clsx from 'clsx';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import Radio from '@standby/common-ui/components/atoms/Input/Radio/Radio';
import useComponentWillMount from '@common/hook/useComponentWillMount';
import DatePicker from '@standby/common-ui/components/molecules/DatePicker/DatePicker';
import AddBtn from '@standby/common-ui/components/atoms/Button/AddBtn/AddBtn';

let vm: DocumentVm;

interface NewSharesAcquirer {
    id: number,
    name: string,
    subscriptionAt: string,
    paymentAt: string,
    stocks: {
        stockName: string,
        stockAmount: number,
        paymentAmount: number,
        issuePrice: number
    }[];
}

interface DocumentProps {
    agendaProgressId: string;
    dividedSubscriptionYn?: boolean;
    newSharesAcquirers?: NewSharesAcquirer[];
}

function Document(props: DocumentProps) {

    useComponentWillMount(() => {
        vm = new DocumentVm;
        vm.load(props.agendaProgressId, props.dividedSubscriptionYn, props.newSharesAcquirers as NewSharesAcquirer[]);
    });

    useEffect(() => {
        reaction(
            () => vm.previewLink,
            (previewLink: string) => {
                if(previewLink) {
                    const previewFrame = document.getElementById('previewFrame') as HTMLIFrameElement;
                    if(previewFrame && previewFrame.contentWindow) {
                        previewFrame.contentWindow.location.replace(previewLink);
                    }
                }
            }
        )
    }, []);

    return useObserver(() => (
        <div className={st.document}>
            <div className={st.title}>신주발행통지서</div>
            <div className={st.contentBox}>
                <div className={st.documentBox}>
                    <div className={st.content}>
                        <iframe id='previewFrame' title="신주발행통지서 미리보기" className={st.iframeDocument}/>
                    </div>
                </div>
                <form className={st.documentOption} onSubmit={(e) => vm.submit(e, props.agendaProgressId)}>
                    <div className={st.optionSeparate}>
                        <div className={st.optionBox}>
                            <div className={st.optionTitle}>글자크기</div>
                            <div className={st.option}>
                                <Radio className={st.radio} name="options.fontSize" value="SMALL" checked={vm.state.options.fontSize === 'SMALL'} onChange={(e) => vm.setFontSize(e, props.agendaProgressId)}>작게</Radio>
                                <Radio className={st.radio} name="options.fontSize" value="MEDIUM" checked={vm.state.options.fontSize === 'MEDIUM'} onChange={(e) => vm.setFontSize(e, props.agendaProgressId)}>중간</Radio>
                                <Radio className={st.radio} name="options.fontSize" value="LARGE" checked={vm.state.options.fontSize === 'LARGE'} onChange={(e) => vm.setFontSize(e, props.agendaProgressId)}>크게</Radio>
                            </div>
                        </div>
                        <div className={st.optionBox}>
                            <div className={st.optionTitle}>줄간격</div>
                            <div className={st.option}>
                                <Radio className={st.radio} name="options.lineSpacing" value="SMALL" checked={vm.state.options.lineSpacing === 'SMALL'} onChange={(e) => vm.setLineSpacing(e, props.agendaProgressId)}>작게</Radio>
                                <Radio className={st.radio} name="options.lineSpacing" value="MEDIUM" checked={vm.state.options.lineSpacing === 'MEDIUM'} onChange={(e) => vm.setLineSpacing(e, props.agendaProgressId)}>중간</Radio>
                                <Radio className={st.radio} name="options.lineSpacing" value="LARGE" checked={vm.state.options.lineSpacing === 'LARGE'} onChange={(e) => vm.setLineSpacing(e, props.agendaProgressId)}>크게</Radio>
                            </div>
                        </div>
                    </div>
                    <div className={st.optionSeparate}>
                        {vm.state.options.dividedSubscriptionYn ? 
                        <>
                            <div className={st.optionBox}>
                                <div className={st.optionTitle}>청약일 및 주금 납입일</div>
                                <div className={st.option}>
                                    <button type="button" className={st.changeBtn} onClick={() => vm.separateDate(props.agendaProgressId)}>청약일, 납입일 변경하기</button>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className={st.optionBox}>
                                <div className={st.optionTitle}>청약일</div>
                                <div className={st.option}>
                                    <DatePicker name="options.subscriptionAt" className={st.datepicker} selected={vm.state.options.subscriptionAt} onChange={(e) => vm.setSubscriptionAt(e, props.agendaProgressId)} />
                                </div>
                            </div>
                            <div className={st.optionBox}>
                                <div className={st.optionTitle}>주금 납입일</div>
                                <div className={st.option}>
                                    <DatePicker name="options.paymentAt" className={st.datepicker} selected={vm.state.options.paymentAt} onChange={(e) => vm.setPaymentAt(e, props.agendaProgressId)} />
                                </div>
                            </div>
                            {vm.state.options.newSharesAcquirers.length > 1 && 
                                <div className={st.addBtnArea}>
                                    <AddBtn onClick={() => vm.separateDate(props.agendaProgressId)}>일자 추가하기</AddBtn>
                                    <div className={st.explain}>여러 번에 나누어 청약하는 경우</div>
                                </div>
                            }
                        </>
                        }
                    </div>
                    {vm.showRepresentativeNameSelectionYn && 
                        <div className={st.optionSeparate}>
                            <div className={st.optionBox}>
                                <div className={st.optionTitle}>서류 아래에 기재할 대표님 이름</div>
                                <div className={st.option}>
                                    {vm.representatives?.map((item, idx) => (
                                        <Radio key={idx} className={clsx(st.radio, st.wrap)} name="representativeId" value={item.id} checked={vm.state.representativeId === String(item.id)} onChange={(e) => vm.setRepresentativeId(e, props.agendaProgressId)}>{item.name}님</Radio>
                                    ))}
                                    <Radio className={clsx(st.radio, st.wrap)} name="representativeId" value="ALL" checked={vm.state.representativeId === 'ALL'} onChange={(e) => vm.setRepresentativeId(e, props.agendaProgressId)}>대표님 모두</Radio>
                                </div>
                            </div>
                        </div>
                    }
                    <div className={st.optionSeparate}>
                        <div className={st.optionBox}>
                            <div className={st.optionTitle}>인감날인</div>
                            <div className={st.option}>
                                <Radio className={st.radio} name="options.onlineStampSealYn" value="true" checked={vm.state.options.onlineStampSealYn === true} onChange={(e) => vm.setOnlineStampSealYn(e, props.agendaProgressId)}>포함</Radio>
                                <Radio className={st.radio} name="options.onlineStampSealYn" value="false" checked={vm.state.options.onlineStampSealYn === false} onChange={(e) => vm.setOnlineStampSealYn(e, props.agendaProgressId)}>미포함</Radio>
                            </div>
                            <div className={st.btnArea}>
                                <SquareBtn className={st.nextBtn} type="submit" name="next">다음 페이지</SquareBtn>
                                <SquareBtn className={st.downloadBtn} type="submit" name="download" color="white">다운로드</SquareBtn>
                            </div>
                        </div>
                    </div>
                    <input type="hidden" name="documentType" value="신주발행통지"/>
                </form>
            </div>
        </div>
    ));
}
export default Document;