import { downloadFileGet, _delete, post, get } from '@common/module/httpRequest';
import { runInAction } from 'mobx';
import FinalModel from '@model/Corp/Agenda/Step/FinalCheck/Final/FinalModel';
import corpModel from '@model/Corp/corpModel';
import AgendaDashboardModel from '@model/AgendaDashboard/AgendaDashboardModel';

import type { 
    LoadRs, MeetingSchedule, I주주총회_개최_이사회, I주주총회_개최_대표결정, I소집통지_온라인, I소집통지_우편, I주주총회, I이사회,
    I주주전원의_서면결의, I대표결정, I신주발행통지, I신주공고통지
} from './FinalService.interface';

class FinalService {
    //@ts-ignore
    async load(agendaProgressId : string) {
        const res = await get<LoadRs>(`/company/${corpModel.id}/agendaProgress/${agendaProgressId}/final`);

        runInAction(() => {
            const data = res.data;
            FinalModel.agendaPeriod = data.agendaPeriod || "";
            FinalModel.completed = data.completed || false;
            FinalModel.registrationAgencyType = data.registrationAgencyType || "NONE";
            FinalModel.memo = data?.memo || "";
            FinalModel.stepsCompletedDate = {
                등기대행_접수완료 : data?.stepsCompletedDate?.등기대행_접수완료 || "",
                견적서_발송 : data?.stepsCompletedDate?.견적서_발송 || "",
                결제_완료 : data?.stepsCompletedDate?.결제_완료 || "",
                등기소_접수 : data?.stepsCompletedDate?.등기소_접수 || "",
                셀프등기_선택 : data?.stepsCompletedDate?.셀프등기_선택 || "",
                고객_통지_동의 : data?.stepsCompletedDate?.고객_통지_동의 || "",
                스탠바이_데이터전송 : data?.stepsCompletedDate?.스탠바이_데이터전송 || "",
                법률사무소_절차_진행 : data?.stepsCompletedDate?.법률사무소_절차_진행 || "",
                등기완료 : data?.stepsCompletedDate?.등기완료 || ""
            }
            FinalModel.documents = {
                shareholderListFile: { // 주주명부 파일
                    issuedDate: data.documents?.shareholderListFile?.issuedDate || ""
                },
                companyCopyFile: { // 등기부 파일
                    issuedDate: data.documents?.companyCopyFile?.issuedDate || ""
                },
                aoaFile: { // 정관 파일
                    issuedDate: data.documents?.aoaFile?.issuedDate || ""
                },
                receiptFile: { // 등기대행 영수증
                    downloadUrl: data.documents?.receiptFile?.downloadUrl || ""
                },
                estimateFile: { // 등기대행 최종 견적서
                    downloadUrl: data.documents?.estimateFile?.downloadUrl || ""
                },
                minutesFile: { // 등기대행 최종 견적서
                    downloadUrl: data.documents?.minutesFile?.downloadUrl || ""
                }
            }
            FinalModel.meetingSchedule = data.meetingSchedule.map((meetingSchedule) => {
                return this.setMeetingSchedule(meetingSchedule)
            })
        })
    }
    setMeetingSchedule(meetingSchedule : MeetingSchedule) {
        const type = meetingSchedule.type;
        switch(type) {
            case "주주총회_개최_이사회" : {
                const _meetingSchedule = meetingSchedule as I주주총회_개최_이사회
                    return {
                        id : _meetingSchedule?.id || "",
                        type : _meetingSchedule?.type || "",
                        title : _meetingSchedule?.title || "",
                        detailTitle : _meetingSchedule?.detailTitle || "",
                        agendas : _meetingSchedule?.agendas || [],
                        place : _meetingSchedule?.place || "",
                        decisionAt : _meetingSchedule?.decisionAt || ""
                    } as I주주총회_개최_이사회
                break;
            }
            case "주주총회_개최_대표결정" : {
                const _meetingSchedule = meetingSchedule as I주주총회_개최_대표결정
                    return {
                        id : _meetingSchedule?.id || "",
                        type : _meetingSchedule?.type || "",
                        title : _meetingSchedule?.title || "",
                        detailTitle : _meetingSchedule?.detailTitle || "",
                        agendas : _meetingSchedule?.agendas || [],
                        decisionAt : _meetingSchedule?.decisionAt || ""
                    } as I주주총회_개최_대표결정
                break;
            }
            case "소집통지_온라인" : {
                const _meetingSchedule = meetingSchedule as I소집통지_온라인
                    return {
                        id : _meetingSchedule?.id || "",
                        type : _meetingSchedule?.type || "",
                        title : _meetingSchedule?.title || "",
                        detailTitle : _meetingSchedule?.detailTitle || "",
                        files : {
                            "소집통지서": {
                                name: _meetingSchedule?.files?.소집통지서?.name || "",
                                downloadUrl: _meetingSchedule?.files?.소집통지서?.downloadUrl || "",
                                modifiable: _meetingSchedule?.files?.소집통지서?.modifiable || false
                            }
                        },
                        decisionAt : _meetingSchedule?.decisionAt || "",
                        shareholders : _meetingSchedule?.shareholders || []
                    } as I소집통지_온라인
                break;
            }
            case "소집통지_우편" : {
                const _meetingSchedule = meetingSchedule as I소집통지_우편
                    return {
                        id : _meetingSchedule?.id || "",
                        type : _meetingSchedule?.type || "",
                        title : _meetingSchedule?.title || "",
                        detailTitle : _meetingSchedule?.detailTitle || "",
                        files : {
                            "소집통지서": {
                                name: _meetingSchedule?.files?.소집통지서?.name || "",
                                downloadUrl: _meetingSchedule?.files?.소집통지서?.downloadUrl || "",
                                modifiable: _meetingSchedule?.files?.소집통지서?.modifiable || false
                            },
                            "우편발송_영수증": {
                                name: _meetingSchedule?.files?.우편발송_영수증?.name || "",
                                downloadUrl: _meetingSchedule?.files?.우편발송_영수증?.downloadUrl || "",
                                modifiable: _meetingSchedule?.files?.우편발송_영수증?.modifiable || false
                            }
                        },
                        decisionAt : _meetingSchedule?.decisionAt || "",
                    } as I소집통지_우편
                break;
            }
            case "주주총회" : {
                const _meetingSchedule = meetingSchedule as I주주총회
                    return {
                        id : _meetingSchedule?.id || "",
                        type : _meetingSchedule?.type || "",
                        title : _meetingSchedule?.title || "",
                        agendas : _meetingSchedule?.agendas || [],
                        detailTitle : _meetingSchedule?.detailTitle || "",
                        place : _meetingSchedule?.place || "",
                        sectionType : _meetingSchedule?.sectionType || "",
                        completed : _meetingSchedule?.completed || false,
                        files : {
                            "주주총회_의사록": {
                                name: _meetingSchedule?.files?.주주총회_의사록?.name || "",
                                downloadUrl: _meetingSchedule?.files?.주주총회_의사록?.downloadUrl || "",
                                modifiable: _meetingSchedule?.files?.주주총회_의사록?.modifiable || false
                            }
                        },
                        decisionAt : _meetingSchedule?.decisionAt || "",
                        shareholders : _meetingSchedule?.shareholders || []
                    } as I주주총회
                break;
            }
            case "이사회" : {
                const _meetingSchedule = meetingSchedule as I이사회
                    return {
                        id : _meetingSchedule?.id || "",
                        type : _meetingSchedule?.type || "",
                        title : _meetingSchedule?.title || "",
                        agendas : _meetingSchedule?.agendas || [],
                        detailTitle : _meetingSchedule?.detailTitle || "",
                        place : _meetingSchedule?.place || "",
                        sectionType : _meetingSchedule?.sectionType || "",
                        completed : _meetingSchedule?.completed || false,
                        files : {
                            "이사회_의사록": {
                                name: _meetingSchedule?.files?.이사회_의사록?.name || "",
                                downloadUrl: _meetingSchedule?.files?.이사회_의사록?.downloadUrl || "",
                                modifiable: _meetingSchedule?.files?.이사회_의사록?.modifiable || false
                            }
                        },
                        decisionAt : _meetingSchedule?.decisionAt || "",
                        shareholders : _meetingSchedule?.shareholders || []
                    } as I이사회
                break;
            }
            case "주주전원의_서면결의" : {
                const _meetingSchedule = meetingSchedule as I주주전원의_서면결의
                    return {
                        id : _meetingSchedule?.id || "",
                        type : _meetingSchedule?.type || "",
                        title : _meetingSchedule?.title || "",
                        agendas : _meetingSchedule?.agendas || [],
                        detailTitle : _meetingSchedule?.detailTitle || "",
                        files : {
                            "서면결의서": {
                                name: _meetingSchedule?.files?.서면결의서?.name || "",
                                downloadUrl: _meetingSchedule?.files?.서면결의서?.downloadUrl || "",
                                modifiable: _meetingSchedule?.files?.서면결의서?.modifiable || false
                            }
                        },
                        decisionAt : _meetingSchedule?.decisionAt || "",
                        sectionType : _meetingSchedule?.sectionType || "",
                        completed : _meetingSchedule?.completed || false,
                        shareholders : _meetingSchedule?.shareholders || []
                    } as I주주전원의_서면결의
                break;
            }
            case "대표결정" : {
                const _meetingSchedule = meetingSchedule as I대표결정
                    return {
                        id : _meetingSchedule?.id || "",
                        type : _meetingSchedule?.type || "",
                        title : _meetingSchedule?.title || "",
                        agendas : _meetingSchedule?.agendas || [],
                        detailTitle : _meetingSchedule?.detailTitle || "",
                        decisionAt : _meetingSchedule?.decisionAt || ""
                    } as I대표결정
                break;
            }
            case "신주발행통지" : {
                const _meetingSchedule = meetingSchedule as I신주발행통지
                    return {
                        id : _meetingSchedule?.id || "",
                        type : _meetingSchedule?.type || "",
                        title : _meetingSchedule?.title || "",
                        detailTitle : _meetingSchedule?.detailTitle || "",
                        files : {
                            "신주발행통지": {
                                name: _meetingSchedule?.files?.신주발행통지?.name || "",
                                downloadUrl: _meetingSchedule?.files?.신주발행통지?.downloadUrl || "",
                                modifiable: _meetingSchedule?.files?.신주발행통지?.modifiable || false
                            }
                        },
                        decisionAt : _meetingSchedule?.decisionAt || "",
                        shareholders : _meetingSchedule?.shareholders || []
                    } as I신주발행통지
                break;
            }
            case "신주공고통지" : {
                const _meetingSchedule = meetingSchedule as I신주공고통지
                    return {
                        id : _meetingSchedule?.id || "",
                        type : _meetingSchedule?.type || "",
                        title : _meetingSchedule?.title || "",
                        detailTitle : _meetingSchedule?.detailTitle || "",
                        files : {
                            "공고문양식": {
                                name: _meetingSchedule?.files?.공고문양식?.name || "",
                                downloadUrl: _meetingSchedule?.files?.공고문양식?.downloadUrl || "",
                                modifiable: _meetingSchedule?.files?.공고문양식?.modifiable || false
                            },
                            "공고스캔": {
                                name: _meetingSchedule?.files?.공고스캔?.name || "",
                                downloadUrl: _meetingSchedule?.files?.공고스캔?.downloadUrl || "",
                                modifiable: _meetingSchedule?.files?.공고스캔?.modifiable || false
                            }
                        },
                        decisionAt : _meetingSchedule?.decisionAt || ""
                    } as I신주공고통지
                break;
            }
        }
    }
    fileDown(url : string) {
        downloadFileGet(url);
    }
    async deleteFile(companyId : string, agendaProgressId : string, id : string, type : string) {
        await _delete(`/company/${companyId}/agendaProgress/${agendaProgressId}/final/document/file?id=${id}&type=${type}`);
    }
    async uploadFile(file : FileList, companyId : string, agendaProgressId : string, id : string, type : string) {
        let formData = new FormData();
        formData.append('id', id);
        formData.append('documentType', type);
        for(let i=0; i<file.length; i++) {
            formData.append("file", file[i]);
        }
        const res = await post<{downloadUrl : string}>(`/company/${companyId}/agendaProgress/${agendaProgressId}/final/document/file`, formData);
        return res.data
    }
    async fileAllDownload(agendaProgressId: string, type: string, opener : 'final' | 'agendaDashboard') {
        if(opener === 'final') {
            await downloadFileGet(`/company/${corpModel.id}/agendaProgress/${agendaProgressId}/final/document/allDownload?type=${type}`);
        }else{
            agendaProgressId = AgendaDashboardModel.selectedAgendaProgressId;
            await downloadFileGet(`/company/agendaProgress/dashboard/document/allDownload?agendaProgressId=${agendaProgressId}&type=${type}`);
        }
    }
}
export default new FinalService();