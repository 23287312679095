
import { _alert, _confirm } from "@model/dialogModel";
import corpModel from "@model/Corp/corpModel";



class ExistingCustomerModalVm {
    get companyName() {
        return corpModel.company.companyName;
    }
}
export default new ExistingCustomerModalVm();