import type { Meetings, AgendaProgressId, ShareholdersMeetingMethodParam, ShareholdersMeetingInfoParam, RegistrationAgencyParam, BoardOfDirectorsInfoParam, MeetingsInfoParam, NextStepRs, NewStockInfoParam, TermsRq, ShareholderListParam } from './meetingsService.interface';

import { post, get, put } from '@common/module/httpRequest';
import { runInAction } from 'mobx';
import corpModel from '@model/Corp/corpModel';
import meetingsStepModel from '@model/Corp/Agenda/Step/meetingsStepModel';
import dateListModel from '@model/Corp/Agenda/Step/dateListModel';
import { pageRouter } from '@model/pageModel';
import { removeCommas } from '@common/module/replaceNumber';

class meetingsService {
    setMeetings(data: Meetings) {
        runInAction(() => {
            meetingsStepModel.title = data.title || "";
            meetingsStepModel.의결기관Type = data.의결기관Type || null;
            meetingsStepModel.hasCapitalOverBillionYn = data.hasCapitalOverBillionYn ?? false;
            if(data.tabList && data.tabList.length !== 0) {
                meetingsStepModel.tabList = data.tabList.map((item) => {
                    if(item.meetingDetailList && item.meetingDetailList.length) {
                        item.meetingDetailList.forEach((meetingDetail) => {
                            if(meetingDetail.decisionAt?.year === null && meetingDetail.decisionAt?.month === null && meetingDetail.decisionAt?.day === null && meetingDetail.decisionAt?.hour === null && meetingDetail.decisionAt?.minute === null) {
                                meetingDetail.decisionAt.year = item.today.year;
                                meetingDetail.decisionAt.month = item.today.month;
                                meetingDetail.decisionAt.day = item.today.day;
                                if(meetingDetail.type === '이사회') {
                                    meetingDetail.decisionAt.hour = '11';
                                meetingDetail.decisionAt.minute = '30';
                                } else if(meetingDetail.type === '주주총회' || meetingDetail.type === '정기주주총회') {
                                    meetingDetail.decisionAt.hour = '10';
                                    meetingDetail.decisionAt.minute = '00';
                                } else if(meetingDetail.type === '주주총회_개최_이사회') {
                                    meetingDetail.decisionAt.hour = '09';
                                    meetingDetail.decisionAt.minute = '00';
                                } else {
                                    meetingDetail.decisionAt.hour = '13';
                                    meetingDetail.decisionAt.minute = '00';
                                }
                            }
                        })

                        //일자 데이터
                        dateListModel.onlyRepresentativeChangedAddressYn = item.onlyRepresentativeChangedAddressYn ?? false;
                        dateListModel.hasCapitalOverBillionYn = data.hasCapitalOverBillionYn ?? false;
                        dateListModel.musterNoticeType = item.musterNoticeType || "";
                        dateListModel.shareholdersMeetingNoticePeriodShorteningAOA = item.shareholdersMeetingNoticePeriodShorteningAOA || "";
                        dateListModel.processStatus = item.processStatus || "";
                        dateListModel.dateList = item.meetingDetailList || [];
                        dateListModel.newStockResourceTypes = item.newStockResourceTypes || [];
                    }

                    return {
                            title: item.title || "",
                            tabType: item.tabType || "",
                            savedYn: item.savedYn || false,
                            savedDataYn: item.savedYn || false,
                            boardOfDirectorsAuthYn: item.boardOfDirectorsAuthYn ?? false,
                            hasRegistrationMattersYn: item.hasRegistrationMattersYn || false, //등기사항 안건 유무
                            musterNoticeType: item.musterNoticeType || "",
                            주주전원_서면결의Yn: item.주주전원_서면결의Yn ?? null,
                            shareholdersMeetingNoticePeriodShorteningAOA: item.shareholdersMeetingNoticePeriodShorteningAOA || "",
                            meetingDetailList: item.meetingDetailList || [], 
                            corpRegProgressType: item.corpRegProgressType || "",
                            requestRequirement: item.requestRequirement || "", //등기대행 요청사항
                            personInCharge: item.personInCharge || { //담당자
                                name: "",
                                phoneNum: "",
                                email: ""
                            },
                            telephoneCounselingYn: item.telephoneCounselingYn || false, //견적 받기 전 전화상담 요청 여부
                            informationProvideYn: item.informationProvideYn || null,  //등기 대행을 위한 제3자 정보제공 동의 여부
                            today: item.today || {
                                year: "",
                                month: "",
                                day: ""
                            },
                            regularYn: item.regularYn || null,
                            jointCertificate: item.jointCertificate || null,
                            digitalCertificate: {
                                company: {
                                    useType: item.digitalCertificate?.company?.useType || "",
                                },
                                companyShareholderList: item.digitalCertificate?.companyShareholderList ? item.digitalCertificate?.companyShareholderList.map((item) => {
                                    return {
                                        id: item.id || -1,
                                        name: item.name || "",
                                        useType: item.useType || ""
                                    }
                                }) : null
                            },
                            shareholderList: item.shareholderList || null,
                            announceType: item.announceType || "",
                            newShareholderAssignReason: item.newShareholderAssignReason || "",
                            processStatus: item.processStatus || "",        
                            onlyRepresentativeChangedAddressYn: item.onlyRepresentativeChangedAddressYn ?? false,                  
                            newStockResourceTypes: item.newStockResourceTypes || [],
                            changedShareholders : item?.changedShareholders || [], 
                            decisionDate : item?.decisionDate || "",
                            decisionDatePhrases : item?.decisionDatePhrases || "",
                            matchedYn : item?.matchedYn ?? null,
                            nearestShareholderListDate : item?.nearestShareholderListDate || "",
                            shareholders : item?.shareholders || [], 
                            stocks : item?.stocks || [], 
                        }   
                })
            } else {
                meetingsStepModel.tabList = [];
            }
        })
    }

    async load(agendaProgressId: AgendaProgressId) {
        const res = await get<Meetings>(`/company/${corpModel.id}/agendaProgress/${agendaProgressId}/meetings`);
        this.setMeetings(res.data);
    }
    async saveShareholdersMeetingMethod(param: ShareholdersMeetingMethodParam, agendaProgressId: AgendaProgressId) { //주주총회 방식 결정
        const res = await post<Meetings>(`/company/${corpModel.id}/agendaProgress/${agendaProgressId}/meetings/saveShareholdersMeetingMethod`, param);
        this.setMeetings(res.data);
    }
    async saveShareholdersMeetingInfo(param: ShareholdersMeetingInfoParam, agendaProgressId: AgendaProgressId) { //주주총회 정보입력
        if(param.writtenResolutionDate && param.meetingDetailList) {
            param.meetingDetailList.unshift(param.writtenResolutionDate);
            delete param.writtenResolutionDate;
        }
        if(param.writtenResolutionDate && !param.meetingDetailList) {
            param.meetingDetailList = [];
            param.meetingDetailList.push(param.writtenResolutionDate);
            delete param.writtenResolutionDate;
        }
        if(param.decisionDate) delete param.decisionDate;
        if(param.decisionTime) delete param.decisionTime;
        const res = await post<Meetings>(`/company/${corpModel.id}/agendaProgress/${agendaProgressId}/meetings/saveShareholdersMeetingInfo`, param);
        this.setMeetings(res.data);
    }
    async saveRegistrationAgency(param: RegistrationAgencyParam, agendaProgressId: AgendaProgressId) { //주주총회 정보입력
        const res = await post<Meetings>(`/company/${corpModel.id}/agendaProgress/${agendaProgressId}/meetings/saveRegistrationAgency`, param);
        this.setMeetings(res.data);
    }
    async saveShareholderList(param: ShareholderListParam, agendaProgressId: AgendaProgressId) { //주주명부 확인 정보입력
        if(param.changedShareholders && param.changedShareholders.length) {
            param.changedShareholders.forEach((changedShareholder) => {
                changedShareholder.stocks.forEach((stock) => {
                    stock.number = removeCommas(stock.number)
                })
            })
        }
        const res = await put<Meetings>(`/company/${corpModel.id}/agendaProgress/${agendaProgressId}/meetings/shareholderList`, param);
        this.setMeetings(res.data);
    }
    async saveBoardOfDirectorsInfo(param: BoardOfDirectorsInfoParam, agendaProgressId: AgendaProgressId) { //이사회 정보입력
        if(param.decisionDate) delete param.decisionDate;
        if(param.decisionTime) delete param.decisionTime;
        const res = await post<Meetings>(`/company/${corpModel.id}/agendaProgress/${agendaProgressId}/meetings/saveBoardOfDirectorsInfo`, param);
        this.setMeetings(res.data);
    }
    async saveMeetingsInfo(param: MeetingsInfoParam, agendaProgressId: AgendaProgressId) { //주주총회,이사회 정보입력
        const res = await post<Meetings>(`/company/${corpModel.id}/agendaProgress/${agendaProgressId}/meetings/saveMeetingsInfo`, param);
        this.setMeetings(res.data);
    }
    async nextStep(agendaProgressId: AgendaProgressId) {
        const res = await post<NextStepRs>(`/company/${corpModel.id}/agendaProgress/${agendaProgressId}/meetings/nextStep`);
        pageRouter.replace(`/corp/agenda/step/${res.data.step}/?corp=${corpModel.id}&agendaProgressId=${agendaProgressId}`);
    }
    async saveNewStockInfo(param: NewStockInfoParam, agendaProgressId: AgendaProgressId) {
        const res = await post<Meetings>(`/company/${corpModel.id}/agendaProgress/${agendaProgressId}/meetings/saveNewStockInfo`, param);
        this.setMeetings(res.data);
    }
    async loadTerms() {
        const res = await await get<TermsRq>(`/term/AGENDA_PROGRESS`, {}, {});
        return res.data;
    }
}

export default new meetingsService();