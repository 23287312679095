import DashboardModel from '@model/Corp/Dashboard/DashboardModel';
import portalModel from '@model/portalModel';
import {runInAction} from 'mobx';
import AgendaLinkPopup from '@pages/Corp/Dashboard/AgendaLinkPopup/AgendaLinkPopup';

class NoticeVm {
    get notice() {
        return DashboardModel.dashboardData?.notice;
    }
    agendaLinkModal() {
        runInAction(() => {
            portalModel.title = "공고방법 변경하기";
            portalModel.content = <AgendaLinkPopup agendaType="공고방법"/>;
        })
    }
}
export default new NoticeVm();
