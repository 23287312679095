import type { Token, LoadRs, BusinessRegistrationLoadRs, ShareholderMeetingWaiverConsentLoadRs, OnlineMeetingNoticeAgreementLoadRs, ShareholderMeetingsWaiverConsentAndNewStockIssueLoadRs, ShortenPeriodAgreementLoadRs, PageType, AuthParam, AuthBusinessRegistrationRs, AuthShareholderMeetingWaiverConsentRs, AuthOnlineMeetingNoticeAgreementRs, AuthShortenPeriodAgreementRs, AuthShareholderMeetingsWaiverConsentAndNewStockIssueRs, AuthRs, AcceptRs, AcceptShareholderMeetingWaiverConsentRs, AcceptOnlineMeetingNoticeAgreementRs, AcceptShareholderMeetingsWaiverConsentAndNewStockIssuRs, AcceptShortenPeriodAgreementRs, FileDownloadRs } from './guestService.interface';
import type { G사업자등록, G주주총회_소집절차_생략동의, G온라인_소집통지_수신동의, G기간단축동의, G주주총회생략동의_신주발행동의, A사업자등록, A주주총회_소집절차_생략동의, A온라인_소집통지_수신동의, A주주총회생략동의_신주발행동의, A기간단축동의, AuthInfo } from '@model/Guest/guestModel.interface';

import { get, post, downloadFileGet } from '@common/module/httpRequest';
import { runInAction } from 'mobx'; 
import guestModel from '@src/model/Guest/guestModel';

class GuestService {
    getPageDetail(pageType: PageType, data: LoadRs) {
        switch(pageType) {
            case 'BUSINESS_REGISTRATION':
                {
                    const _data = data as BusinessRegistrationLoadRs;
                    return {
                        shareType: _data.shareType || null,
                        documentType: _data.documentType || null
                    } as G사업자등록;
                }
            break;
            case 'SHAREHOLDER_MEETING_WAIVER_CONSENT':
                {
                    const _data = data as ShareholderMeetingWaiverConsentLoadRs;
                    return {
                        shareType: _data.shareType || null,
                        shareholderName: _data.shareholderName || "",
                        regularYn: _data.regularYn || false,
                        decisionAt: _data.decisionAt || ""
                    } as G주주총회_소집절차_생략동의;
                }
            break;
            case 'ONLINE_MEETING_NOTICE_AGREEMENT':
                {
                    const _data = data as OnlineMeetingNoticeAgreementLoadRs;
                    return {
                        shareType: _data.shareType || null,
                        shareholderName: _data.shareholderName || "",
                    } as G온라인_소집통지_수신동의;
                }
            break;
            case 'SHORTEN_PERIOD_AGREEMENT':
            case 'RENUNCIATION_NEWSHARE_SUBSCRIPTION_AND_SHORTEN_PERIOD_AGREEMENT':
                {
                    const _data = data as ShortenPeriodAgreementLoadRs;
                    return {
                        shareType: _data.shareType || null,
                        shareholderName: _data.shareholderName || "",
                    } as G기간단축동의;
                }
            break;
            case 'SHAREHOLDER_MEETING_WAIVER_CONSENT_AND_SHORTEN_PERIOD_AGREEMENT':
            case 'SHAREHOLDER_MEETING_WAIVER_CONSENT_AND_ABANDONED_AND_SHORTEN_PERIOD_AGREEMENT':
                {
                    const _data = data as ShareholderMeetingsWaiverConsentAndNewStockIssueLoadRs;
                    return {
                        shareType: _data.shareType || null,
                        shareholderName: _data.shareholderName || "",
                    } as G주주총회생략동의_신주발행동의;
                }
            break;
            default:
                return null;
        }
    }
    setAuthInfo(pageType: PageType, data: AuthRs): AuthInfo {
        switch(pageType) {
            case 'BUSINESS_REGISTRATION':
                {
                    const _data = data as AuthBusinessRegistrationRs;
                    return {
                        documentType: _data.documentType || null,
                        userName: _data.userName || "",
                        expireDate: _data.expireDate || "",
                        company: {
                            companyName: _data.company?.companyName || "",
                            companyType: _data.company?.companyType || "",
                            prefixed: _data.company?.prefixed ?? null,
                            representativeName: _data.company?.representativeName || "",
                        },
                        businessRegistration: {
                            businessRegistrationNumber: _data.businessRegistration?.businessRegistrationNumber || "",
                            taxInvoiceIssuanceEmail: _data.businessRegistration?.taxInvoiceIssuanceEmail || "",
                            issuedAt: _data.businessRegistration?.issuedAt || "",
                            fileDownloadUrl: _data.businessRegistration?.fileDownloadUrl || ""
                        },
                        bankbook: {
                            id: _data.bankbook?.id || -1,
                            bankName: _data.bankbook?.bankName || null,
                            accountNumber: _data.bankbook?.accountNumber || "",
                            fileDownloadUrl: _data.bankbook?.fileDownloadUrl || ""
                        }
                    } as A사업자등록;
                };
            break;
            case 'SHAREHOLDER_MEETING_WAIVER_CONSENT':
                {
                    const _data = data as AuthShareholderMeetingWaiverConsentRs;
                    return {
                        companyName: _data.companyName || "",
                        shareholderName: _data.shareholderName || "",
                        acceptYn: _data.acceptYn ?? false,
                        documentUrl: _data.documentUrl || ""
                    } as A주주총회_소집절차_생략동의;
                };
            break;
            case 'ONLINE_MEETING_NOTICE_AGREEMENT':
                {
                    const _data = data as AuthOnlineMeetingNoticeAgreementRs;
                    return {
                        companyName: _data.companyName || "",
                        shareholderName: _data.shareholderName || "",
                        acceptYn: _data.acceptYn ?? false,
                        documentUrl: _data.documentUrl || ""
                    } as A온라인_소집통지_수신동의;
                }
            break;
            case 'SHORTEN_PERIOD_AGREEMENT':
            case 'RENUNCIATION_NEWSHARE_SUBSCRIPTION_AND_SHORTEN_PERIOD_AGREEMENT':
                {
                    const _data = data as AuthShortenPeriodAgreementRs;
                    return {
                        companyName: _data.companyName || "",
                        shareholderName: _data.shareholderName || "",
                        acceptYn: _data.acceptYn ?? false,
                        documentUrl: _data.documentUrl || ""
                    } as A기간단축동의;
                }
            break;
            case 'SHAREHOLDER_MEETING_WAIVER_CONSENT_AND_SHORTEN_PERIOD_AGREEMENT':
            case 'SHAREHOLDER_MEETING_WAIVER_CONSENT_AND_ABANDONED_AND_SHORTEN_PERIOD_AGREEMENT':
                {
                    const _data = data as AuthShareholderMeetingsWaiverConsentAndNewStockIssueRs;
                    return {
                        companyName: _data.companyName || "",
                        shareholderName: _data.shareholderName || "",
                        acceptYn: _data.acceptYn ?? false,
                        documentUrl: _data.documentUrl || ""
                    } as A주주총회생략동의_신주발행동의;
                }
            break;
            default:
                return null;
        }
    }
    setAcceptInfo(pageType: PageType, data: AcceptRs) {
        switch(pageType) {
            case 'SHAREHOLDER_MEETING_WAIVER_CONSENT':
                {
                    const _data = data as AcceptShareholderMeetingWaiverConsentRs;
                    return {
                        shareholderName: _data.shareholderName || "",
                        companyName: _data.companyName || "",
                        regularYn: _data.regularYn ?? false,
                        decisionAt: _data.decisionAt || ""
                    } as G주주총회_소집절차_생략동의;
                };
            break;
            case 'ONLINE_MEETING_NOTICE_AGREEMENT':
                {
                    const _data = data as AcceptOnlineMeetingNoticeAgreementRs;
                    return {
                        shareholderName: _data.shareholderName || "",
                    } as G온라인_소집통지_수신동의;
                }
            break;
            case 'SHORTEN_PERIOD_AGREEMENT':
            case 'RENUNCIATION_NEWSHARE_SUBSCRIPTION_AND_SHORTEN_PERIOD_AGREEMENT':
                {
                    const _data = data as AcceptShortenPeriodAgreementRs;
                    return {
                        shareholderName: _data.shareholderName || "",
                    } as G기간단축동의;
                }
            break;
            case 'SHAREHOLDER_MEETING_WAIVER_CONSENT_AND_SHORTEN_PERIOD_AGREEMENT':
            case 'SHAREHOLDER_MEETING_WAIVER_CONSENT_AND_ABANDONED_AND_SHORTEN_PERIOD_AGREEMENT':
                {
                    const _data = data as AcceptShareholderMeetingsWaiverConsentAndNewStockIssuRs;
                    return {
                        shareholderName: _data.shareholderName || "",
                    } as G주주총회생략동의_신주발행동의;
                }
            break;
            default:
                return null;
        }
    }
    async load(token : Token) {
        const res = await get<LoadRs>(`/company/guest`, {}, {
            "Guest-Authorization" : `Bearer ${token}`
        });
        runInAction(() => {
            guestModel.pageType = res.data?.pageType || null;
            guestModel.status = res.data?.status || null;
            guestModel.companyName = res.data?.companyName || "";
            guestModel.guestInfo = this.getPageDetail(res.data?.pageType, res.data);
        })
    }
    async auth<T>(token: string, param: AuthParam) {
        const res = await post<T>(`/company/guest/auth`, param, {
            "Guest-Authorization" : `Bearer ${token}`
        });
        runInAction(() => {
            guestModel.authInfo = this.setAuthInfo(guestModel.pageType, res.data as AuthRs);      
        })
    }
    async accept<T>(token: string) {
        const res = await post<T>(`/company/guest/accept`, {}, {
            "Guest-Authorization" : `Bearer ${token}`
        });
        runInAction(() => {
            guestModel.status = "ACCEPTED";
            guestModel.guestInfo = this.setAcceptInfo(guestModel.pageType, res.data as AcceptRs)
        })  
    }
    async fileDownload(downloadLink: string) {
        await downloadFileGet(downloadLink);
    }
    async download(token: string) {
        const res = await post<FileDownloadRs>(`/company/guest/download`, {}, {
            "Guest-Authorization" : `Bearer ${token}`
        });
        window.location.href = res.data?.downloadUrl
    }
}
export default new GuestService();