import { useObserver } from 'mobx-react';
import React from 'react';
import vm from './ExecutiveRemunerationDecisionVm';
import qst from '@pages/Corp/Agenda/Step/AgendaQuestion/commonQuestion.module.scss';
import st from './ExecutiveRemunerationDecision.module.scss';
import QuestionTooltip from '@standby/common-ui/components/molecules/QuestionTooltip/QuestionTooltip';
import FileBtn from '@standby/common-ui/components/atoms/Button/FileBtn/FileBtn';
import DatePicker from '@standby/common-ui/components/molecules/DatePicker/DatePicker';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import { addCommas} from '@common/module/replaceNumber';
import Table from '@standby/common-ui/components/atoms/Table/Table';
import type {SelectedAgenda, I임원_보수_결정} from '@model/Corp/Agenda/Step/agendaStepModel.interface';

interface ExecutiveRemunerationDecisionProps {
    agenda : SelectedAgenda<I임원_보수_결정>,
    agendaIndex : number
}

const ExecutiveRemunerationDecision = React.memo(function (props : ExecutiveRemunerationDecisionProps) {
    return useObserver(() => (
        <div className={qst.questionForm}>
            {vm.displayQuestionTooltip && 
                <div className={qst.qusetion}>
                    <div className={qst.questionTitle}>
                    <QuestionTooltip title="주주총회에서 매년 임원 보수를 정하는 것이 번거롭다면?" style={{width: '460px'}}>
                        <div className={st.tooltipContent}>
                            <div className={st.exTitle}>임원의 보수는 정관 또는 주주총회로 정해야 해요.</div>
                            <ul className={st.exList}>
                                <li>상법상 이사, 감사, 대표의 보수(급여, 상여금, 퇴직금)은 <span className={st.important}>정관에서 정하지 않으면, 주주총회에서 매번 승인해야</span> 해요. 이렇게 하지 않는다면, 나중에 세무조사 진행시, 임원의 보수에 대한 비용처리가 부인당할 수도 있어요.</li>
                                <li>하지만 정관에 대표이사의 보수는 5억 원이라고 구체적으로 적어두는 것은 추천하지 않아요. 정관은 비밀문서가 아니고, 외부에 공개할 일이 자주 있기 때문이에요.</li>
                            </ul>
                            <div className={st.exTitle}>정관에 간단한 내용만 넣어두고, 별도로 규정을 만드는 편을 추천해요.</div>
                            <ul className={st.exList}>
                                <li>
                                    번거로움을 피하기 위한 가장 간단한 방법을 알려드릴게요.
                                    <ul className={st.stepList}>
                                        <li>
                                            <span className={st.step}>STEP1</span>
                                            <div className={st.content}>
                                                스탠바이에서 [규정승인] 안건을 선택해주세요.<br/>(임원 보수, 상여금, 퇴직금)
                                            </div>
                                        </li>
                                        <li>
                                            <span className={st.step}>STEP2</span>
                                            <div className={st.content}>
                                                임원 보수, 상여금, 퇴직금 규정을 만들고, 주주총회에서 승인을 받으세요.
                                                <div className={st.fileBtn}>
                                                    <FileBtn onClick={() => vm.download()}>보수, 상여금, 퇴직금 규정 3종 샘플 다운로드</FileBtn>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    이 때 정관에 “임원의 보수, 상여금, 퇴직금은 주주총회의 승인을 거친 별도 규정으로 정한다.”라는 내용이 포함되어 있어야 해요. 만약 포함되어 있지 않다면, 스탠바이에서 자동으로 정관변경 안건을 추가해드려요.
                                    <ul className={st.stepList}>
                                        <li>
                                            <span className={st.step}>STEP3</span>
                                            <div className={st.content}>
                                                별도 규정의 내용에 따라 필요하다면, 이사회 승인을 진행하세요.
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </QuestionTooltip>
                    </div>
                </div>
            }
            <div className={qst.qusetion}>
                <div className={qst.questionTitle}>결정 기간을 입력하세요.</div>
                <div className={st.datePickerBox}>
                    <div>
                        <input type="hidden" name="agendaDetail.decisionStartAt.year" value={props.agenda.agendaDetail.decisionStartAt.year} />
                        <input type="hidden" name="agendaDetail.decisionStartAt.month" value={props.agenda.agendaDetail.decisionStartAt.month} />
                        <input type="hidden" name="agendaDetail.decisionStartAt.day" value={props.agenda.agendaDetail.decisionStartAt.day} />
                        <DatePicker name="agendaDetail.decisionStartAt" selected={vm.decisionStart(props.agendaIndex)} onChange={(e) => vm.setDecisionStart(e, props.agendaIndex)} explain="시작일" />
                        <div>
                            <Error name="agendaDetail.decisionStartAt" value={vm.decisionStart(props.agendaIndex) || ""} errorCase={{
                                required: '결정 기간을 입력해주세요.'
                            }}
                            />
                        </div>
                    </div>
                    <div style={{marginLeft:'20px'}}>
                        <input type="hidden" name="agendaDetail.decisionEndAt.year" value={props.agenda.agendaDetail.decisionEndAt.year} />
                        <input type="hidden" name="agendaDetail.decisionEndAt.month" value={props.agenda.agendaDetail.decisionEndAt.month} />
                        <input type="hidden" name="agendaDetail.decisionEndAt.day" value={props.agenda.agendaDetail.decisionEndAt.day} />
                        <DatePicker name="agendaDetail.decisionEndAt" selected={vm.decisionEnd(props.agendaIndex)} onChange={(e) => vm.setDecisionEnd(e, props.agendaIndex)} explain="시작일" />
                        <div>
                            <Error name="agendaDetail.decisionEndAt" value={vm.decisionEnd(props.agendaIndex) || ""} errorCase={{
                                required: '결정 기간을 입력해주세요.'
                            }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={qst.qusetion}>
                <div className={qst.questionTitle}>임원별 정해진 보수를 입력하세요.</div>
                <Table style={{width:'460px'}}>
                    <thead>
                        <tr>
                            <th>임원</th>
                            <th style={{textAlign:'right', padding:'0 20px'}}>퇴직금 포함 총 보수(1년)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.agenda.agendaDetail.salaryOfExecutives.map((salaryOfExecutive, index) => (
                            <tr key={index}>
                                <td>{salaryOfExecutive.name}{salaryOfExecutive.newExecutiveYn && <span className={st.newTag}>신규</span>}</td>
                                <td style={{textAlign:'right'}}>
                                    <input type="hidden" name={`agendaDetail.salaryOfExecutives[${index}].id`} value={salaryOfExecutive.id}/>
                                    <div className={qst.subTextInput}>
                                        <InputText name={`agendaDetail.salaryOfExecutives[${index}].salary`} textAlign="right" placeholder="0" value={addCommas(salaryOfExecutive.salary)} onChange={(e) => vm.salary(e, props.agendaIndex, index)} style={{width:"201px"}} size="small"
                                        errorText={<Error name={`agendaDetail.salaryOfExecutives[${index}].salary`} value={addCommas(salaryOfExecutive.salary) || ""} errorCase={{
                                            required: '보수를 입력하세요.'
                                        }}
                                        />}
                                        /><span className={qst.inputSubText}>원</span>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
    ));
})
export default ExecutiveRemunerationDecision;