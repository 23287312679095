import { post, get, downloadFileGet } from '@common/module/httpRequest';
import { runInAction } from 'mobx';
import corpModel from '@model/Corp/corpModel';
import vatModel from '@model/Corp/Vat/vatModel';

class VatService {
    async loadGuides() {
        const res = await get(`/vat/home`);
        vatModel.setGuides(res.data);
    }
    async loadVat() {
        const res = await get(`/company/${corpModel.id}/vat`); 
        vatModel.setVat(res.data);
    }
    async vat(vatId) {
        const res = await get(`/company/${corpModel.id}/vat/${vatId}`);
        vatModel.setSelectVat(res.data);
    }
    async fileDownload(downloadLink) {
        await downloadFileGet(downloadLink);
    }
}

export default new VatService();