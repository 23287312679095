import { useObserver } from 'mobx-react';
import { Route, withRouter, useLocation } from "react-router-dom";
import { useEffect } from 'react';
import RedirectPageVm from './RedirectPageVm';
import queryString from 'query-string';

function RedirectPage() {

    let location = useLocation();
    const id = queryString.parse(location.search).corp;

    useEffect(() => {
        RedirectPageVm.load(id);
    }, []);

    return useObserver(() => (
        <>
        
        </>
    ));
}
export default RedirectPage;