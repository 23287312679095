import DashboardModel from '@model/Corp/Dashboard/DashboardModel';
import corpModel from "@model/Corp/corpModel";
import portalModel from '@model/portalModel';
import {runInAction} from 'mobx';
import AgendaLinkPopup from '@pages/Corp/Dashboard/AgendaLinkPopup/AgendaLinkPopup';

class AoaVm {
    get aoa() {
        return DashboardModel.dashboardData?.aoa;
    }
    get aoaRegistrationYn() {
        return DashboardModel.dashboardData?.aoaRegistrationYn;
    }
    get displayStockOption() {
        const state = this.aoa?.contents.스톡옵션_규정;
        switch(state) {
            case '있음(벤처)':
                return {
                    bool: true,
                    text: '(벤처기업)'
                }
            case '있음(일반)':
                return {
                    bool: true,
                    text: '(상법)'
                }
            case '있음':
                return {
                    bool: true,
                    text: ''
                }
            case '없음':
                return {
                    bool: false,
                    text: ''
                }
        }
    }
    get id() {
        return corpModel.id;
    }
    agendaLinkModal() {
        runInAction(() => {
            portalModel.title = "정관 변경하기";
            portalModel.content = <AgendaLinkPopup agendaType="등기가_불필요한_정관변경"/>;
        })
    }
}
export default new AoaVm();
