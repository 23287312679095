import { post, get, put, _delete } from '@common/module/httpRequest';
import { runInAction } from 'mobx';
import agendaModel from '@model/Corp/Agenda/agendaModel';
import agendaStepModel from '@model/Corp/Agenda/Step/agendaStepModel';
import corpModel from '@model/Corp/corpModel';
import { pageRouter } from '@model/pageModel';
import { removeCommas } from '@common/module/replaceNumber';
import { _alert, _confirm } from "@model/dialogModel";
import ExecutiveModalModel from '@model/Corp/Agenda/Step/ExecutiveModal/ExecutiveModalModel';
import type {
    LoadRs, Guide, ProgressAgenda, CompletedAgenda, LoadAgendaStepRs, Agenda, SelectedAgenda, AgendaDetail, AgendaType,
    I본점의_주소_변경, I재무재표_승인, I신주발행_무상증자, I전환사채_발행, CommonAgendaDetail, 
    I등기가_불필요한_정관변경, I임원_보수_한도_승인, I임원_보수_결정, I임원_취임_중임_사임_퇴임, I신주발행_유상증자, I등기신청을_위한_추가_정관변경, I대표이사_주소_변경, I액면금분할_합병,
    SelectAgendaRs, SelectAgendaRq, SaveAgendaRs, NextStepRs, ExecutiveModalRs, ExecutiveModalMember, ExecutiveModalMembers, GetIncreaseAmountDataRs, Highlight
} from './agendaService.interface';
import type { AxiosResponse } from 'axios';

class agendaService {
    setGuides(guides : Guide[]) {
        agendaModel.guides = guides.map((guide) => {
            return {
                title : guide?.title || "",
                contents : guide?.contents || "",
                url : guide?.url || ""
            }
        })
    }
    setProgressAgendas(progressAgendas : ProgressAgenda[]) {
        agendaModel.progressAgendas = progressAgendas.map((progressAgenda) => {
            return {
                id : progressAgenda?.id || "",
                title : progressAgenda?.title || "",
                date : progressAgenda?.date || "",
                registrationType : progressAgenda?.registrationType || "",
                결정기관s : progressAgenda?.결정기관s.map((결정기관) => 결정기관 || ""),
                createdAt : progressAgenda?.createdAt || "",
                step : progressAgenda?.step || ""
            }
        })
    }
    setCompletedAgendas(completedAgendas : CompletedAgenda[]) {
        agendaModel.completedAgendas = completedAgendas.map((completedAgenda) => {
            return {
                id : completedAgenda?.id || "",
                title : completedAgenda?.title || "",
                date : completedAgenda?.date || "",
                registrationType : completedAgenda?.registrationType || "",
                결정기관s : completedAgenda?.결정기관s.map((결정기관) => 결정기관 || ""),
                agreements : completedAgenda?.agreements.map((agreement) => agreement || ""),
                step : completedAgenda?.step || ""
            }
        })
    }
    setHasBoardOfDirectors(hasBoardOfDirectors : boolean) {
        agendaModel.hasBoardOfDirectors = hasBoardOfDirectors || false;
    }
    setRegistrationRequiredYn(registrationRequiredYn : boolean) {
        agendaModel.registrationRequiredYn = registrationRequiredYn || false
    }
    setAgenda(agenda : Agenda){
        agendaModel.agenda = agenda;
    }
    setHighlights(highlights : Highlight[]) {
        agendaModel.highlights = highlights || []
    }
    agendaDetail(agendaType : AgendaType, agendaDetail : AgendaDetail) {
        switch(agendaType){
            case "본점의_주소_변경":
                {
                    const _agendaDetail = agendaDetail as I본점의_주소_변경;
                    return {
                        selectedMetropolitanType : _agendaDetail?.selectedMetropolitanType || "",
                        selectedSigungu : _agendaDetail?.selectedSigungu || "",
                        selectableMetropolitanTypes : _agendaDetail?.selectableMetropolitanTypes.map((selectableMetropolitanType) => {
                            return {
                                metropolitanType : selectableMetropolitanType.metropolitanType || "",
                                selectableSigungus : selectableMetropolitanType?.selectableSigungus.map((selectableSigungu) => {
                                    return selectableSigungu || ""
                                })
                            }
                        }),
                    } as I본점의_주소_변경
                    break;
                }
            case "재무제표_승인":
                {
                    const _agendaDetail = agendaDetail as I재무재표_승인;
                    return {
                        fiscalYears : _agendaDetail?.fiscalYears.map((fiscalYear) => {
                            return {
                                term : fiscalYear.term || "",
                                content : fiscalYear.content || "",
                            }
                        }),
                        selectedTerm : _agendaDetail?.selectedTerm || "",
                        autoAdd재무제표승인Yn : _agendaDetail?.autoAdd재무제표승인Yn || false,
                        attachmentFiles : _agendaDetail?.attachmentFiles.map((fiscalYear) => {
                            return {
                                id : fiscalYear.id || "",
                                fileName : fiscalYear.fileName || "",
                                downloadUrl : fiscalYear.downloadUrl || "",
                            }
                        })
                    } as I재무재표_승인
                    break;
                }
            case "신주발행_무상증자":
                {
                    const _agendaDetail = agendaDetail as I신주발행_무상증자;
                    let existsShareholderListYn = false;
                    if(_agendaDetail?.increaseDate?.year && _agendaDetail?.freeIssueOfNewSharesType) {
                        existsShareholderListYn = true;
                    }
                    return {
                        freeIssueOfNewSharesType : _agendaDetail?.freeIssueOfNewSharesType || "",
                        beforeIncreaseAmount : _agendaDetail?.beforeIncreaseAmount || "",
                        increaseAmount : _agendaDetail?.increaseAmount || "",
                        parValue : _agendaDetail?.parValue || "",
                        capital : _agendaDetail?.capital || "",
                        increaseDate : {
                            year : _agendaDetail?.increaseDate?.year || "",
                            month : _agendaDetail?.increaseDate?.month || "",
                            day : _agendaDetail?.increaseDate?.day || ""
                        },
                        existsShareholderListYn
                    } as I신주발행_무상증자
                    break;
                }
            case "전환사채_발행":
                {
                    const _agendaDetail = agendaDetail as I전환사채_발행;
                    return {
                        totalAmount : _agendaDetail?.totalAmount || "",
                        publicationTarget : _agendaDetail?.publicationTarget || null,
                        issueCount : _agendaDetail?.issueCount || ""
                    } as I전환사채_발행
                    break;
                }
            case "임원_보수_지급규정_승인":
            case "임원_상여금_지급규정_승인":
            case "임원_퇴직금_지급규정_승인":
            case "주주총회_안건_자유입력":
            case "이사회_안건_자유입력":
            case "차등배당":
            case "중간배당":
            case "부동산_매각":
            case "자금차입":
            case "자금차입_및_담보제공":
            case "자회사_설립":
            case "이사의_자기거래_승인":
            case "임원_무보수_결의":
                {
                    const _agendaDetail = agendaDetail as CommonAgendaDetail;
                    return {
                        title : _agendaDetail?.title || "",
                        content : _agendaDetail?.content || "",
                        attachmentFiles : _agendaDetail?.attachmentFiles.map((fiscalYear) => {
                            return {
                                id : fiscalYear.id || "",
                                fileName : fiscalYear.fileName || "",
                                downloadUrl : fiscalYear.downloadUrl || "",
                            }
                        })
                    } as CommonAgendaDetail
                    break;
                }
            case "등기가_불필요한_정관변경":
                {
                    const _agendaDetail = agendaDetail as I등기가_불필요한_정관변경;
                    return {
                        articlesOfAssociations : _agendaDetail.articlesOfAssociations.length === 0 ?
                            [{
                                id : null,
                                regulationLocation : "",
                                content : ""
                            }] :
                            _agendaDetail.articlesOfAssociations.map((articlesOfAssociation) => {
                                return {
                                    id : articlesOfAssociation?.id || null,
                                    regulationLocation : articlesOfAssociation?.regulationLocation || "",
                                    content : articlesOfAssociation?.content || ""
                                }
                            })
                    } as I등기가_불필요한_정관변경
                    break;
                }
            case "임원_보수_한도_승인":
                {
                    const _agendaDetail = agendaDetail as I임원_보수_한도_승인;
                    return {
                        approvalStartAt : {
                            year : _agendaDetail?.approvalStartAt?.year || "",
                            month : _agendaDetail?.approvalStartAt?.month || "",
                            day : _agendaDetail?.approvalStartAt?.day || ""
                        },
                        approvalEndAt : {
                            year : _agendaDetail?.approvalEndAt?.year || "",
                            month : _agendaDetail?.approvalEndAt?.month || "",
                            day : _agendaDetail?.approvalEndAt?.day || ""
                        },
                        approvalType : _agendaDetail?.approvalType || "",
                        이사SalaryLimit : _agendaDetail?.이사SalaryLimit || "",
                        감사SalaryLimit : _agendaDetail?.감사SalaryLimit || "",
                        isExists_감사 : _agendaDetail?.isExists_감사 || false,
                        salaryLimitOfExecutives : _agendaDetail?.salaryLimitOfExecutives.map((salaryLimitOfExecutive) => {
                            return {
                                id : salaryLimitOfExecutive?.id || "",
                                name : salaryLimitOfExecutive?.name || "",
                                salary : salaryLimitOfExecutive?.salary || "",
                                newExecutiveYn: salaryLimitOfExecutive?.newExecutiveYn ?? null
                            }
                        })
                    } as I임원_보수_한도_승인       
                    break;
                }
            case "임원_보수_결정":
                {
                    const _agendaDetail = agendaDetail as I임원_보수_결정;
                    return {
                        decisionStartAt : {
                            year : _agendaDetail?.decisionStartAt?.year || "",
                            month : _agendaDetail?.decisionStartAt?.month || "",
                            day : _agendaDetail?.decisionStartAt?.day || ""
                        },
                        decisionEndAt : {
                            year : _agendaDetail?.decisionEndAt?.year || "",
                            month : _agendaDetail?.decisionEndAt?.month || "",
                            day : _agendaDetail?.decisionEndAt?.day || ""
                        },
                        salaryOfExecutives : _agendaDetail?.salaryOfExecutives.map((salaryOfExecutive) => {
                            return {
                                id : salaryOfExecutive?.id || "",
                                name : salaryOfExecutive?.name || "",
                                salary : salaryOfExecutive?.salary || "",
                                newExecutiveYn: salaryOfExecutive?.newExecutiveYn ?? false
                            }
                        })
                    } as I임원_보수_결정       
                    break;
                }
            case "임원_취임_중임_사임_퇴임":
                {
                    const _agendaDetail = agendaDetail as I임원_취임_중임_사임_퇴임;
                    const _newExecutives =  
                    _agendaDetail?.newExecutives.length ? 
                        _agendaDetail?.newExecutives.map((newExecutive) => {
                            return {
                                executiveId : newExecutive?.executiveId || "",
                                name : newExecutive?.name || "",
                                position : newExecutive?.position || null,
                                simpleNationalityType : newExecutive?.simpleNationalityType || null,
                                nationalityType : newExecutive?.nationalityType || "",
                                nationalityOthers : newExecutive?.nationalityOthers || "",
                                shareholderId : (!newExecutive?.shareholderId && newExecutive?.executiveId) ? "0" : (newExecutive?.shareholderId).toString(),
                            }
                        })
                        : [{
                            executiveId : "",
                            name : "",
                            position : null,
                            simpleNationalityType :  null,
                            nationalityType :  "",
                            nationalityOthers : "",
                            shareholderId : "",
                        }];
                    return {
                        newExecutives : _newExecutives,
                        newExecutivesExistsYn : _agendaDetail?.newExecutivesExistsYn ?? null,
                        nationalityTypes : _agendaDetail?.nationalityTypes.map((nationalityType) => {
                            return {
                                name : nationalityType?.name || "",
                                value : nationalityType?.value || "",
                            }
                        }),
                        originExecutives : _agendaDetail?.originExecutives.map((originExecutive) => {
                            return {
                                separateInputYn : originExecutive?.separateInputYn || false,
                                name : originExecutive?.name || "",
                                info : originExecutive.info.map((info) => {
                                    return {
                                        id : info.id || "",
                                        changeType : info.changeType || null,
                                        fullTermOfOffice : info.fullTermOfOffice || 0,
                                        newPosition : info.newPosition || null,
                                        position : info.position || "",
                                        registrationRequiredYn : info.registrationRequiredYn || false,
                                        remainderOfTermOfOffice : info.remainderOfTermOfOffice || 0,
                                        tag : info.tag,
                                        termOfOfficeAt : info.termOfOfficeAt || false,
                                        remainingTermOfOffice : info.remainingTermOfOffice || 0,
                                    }
                                })
                            }
                        }),
                        changeAddressExecutives : _agendaDetail?.changeAddressExecutives.map((changeAddressExecutive) => {
                            return {
                                name : changeAddressExecutive?.name || "",
                                checked : changeAddressExecutive?.checked || false,
                                id : changeAddressExecutive?.id || ""
                            }
                        }),
                        shareholders : _agendaDetail?.shareholders.map((shareholder) => {
                            return {
                                name : shareholder?.name || "",
                                nationalityType : shareholder?.nationalityType || null,
                                id : shareholder?.id || "",
                                stock : shareholder?.stock || 0,
                            }
                        })
                    } as I임원_취임_중임_사임_퇴임
                    break;
                }
            case "대표이사_주소_변경":
                {
                        const _agendaDetail = agendaDetail as I대표이사_주소_변경;
                        return {
                            changeAddressExecutives : _agendaDetail?.changeAddressExecutives.map((changeAddressExecutive) => {
                                return {
                                    name : changeAddressExecutive?.name || "",
                                    checked : changeAddressExecutive?.checked || false,
                                    id : changeAddressExecutive?.id || ""
                                }
                            })
                        } as I대표이사_주소_변경
                    break;
                }
            case "신주발행_유상증자":
                {
                    const _agendaDetail = agendaDetail as I신주발행_유상증자;
                    return {
                        sharesOnRegister : {
                            totalNumOfSharesOnRegister : _agendaDetail?.sharesOnRegister?.totalNumOfSharesOnRegister || 0,
                            totalCapitalStock : _agendaDetail?.sharesOnRegister?.totalCapitalStock || 0,
                            classStocksOnRegister : _agendaDetail?.sharesOnRegister?.classStocksOnRegister.map((item) => {
                                return {
                                    stockName : item?.stockName || "",
                                    numberOfShares : item?.numberOfShares || 0,
                                    parValue : item?.parValue || 0,
                                    capitalStock : item?.capitalStock || 0,
                                    content : item?.content || ""
                                }
                            }),
                        },
                        selectableClassStocks : _agendaDetail?.selectableClassStocks.map((selectableClassStock) => {
                            return {
                                name : selectableClassStock.name,
                                type : selectableClassStock.type
                            }
                        }),
                        newShareholderSelections : _agendaDetail?.newShareholderSelections.map((newShareholderSelection) => {
                            return {
                                id : newShareholderSelection?.id ? (newShareholderSelection?.id).toString() : "",
                                executiveYn : newShareholderSelection?.executiveYn || false,
                                name : newShareholderSelection?.name || "",
                            }
                        }),
                        newSharesAcquirers : _agendaDetail?.newSharesAcquirers.length !== 0 ?
                            _agendaDetail?.newSharesAcquirers.map((item) => {
                                let _highType = "";
                                if(item.id) {
                                    _highType = "기존주주"
                                }
                                if(item.type) {
                                    _highType = "새로운분"
                                }
                                return {
                                    id : item?.id ? (item?.id).toString() : "",
                                    newSharesAcquirerId : item?.newSharesAcquirerId || "",
                                    executiveYn : item?.executiveYn || false,
                                    name : item?.name || "",
                                    type : item?.type || '',
                                    resourceType : item?.resourceType || "",
                                    stocks : item?.stocks.map((stock) => {
                                        return {
                                            id : stock?.id || "",
                                            stockType : stock?.stockType || "",
                                            name : stock?.name || "",
                                            investment : stock?.investment || "",
                                            number : stock?.number || ""
                                        }
                                    }),
                                    highType: _highType
                                }
                            })
                            : [{
                                "id" : "",
                                "name" : "",
                                "newSharesAcquirerId" : "",
                                "executiveYn" : false,
                                "type" : '',
                                "resourceType": "",
                                "stocks" : [{
                                    "stockType": "",
                                    "name" : "",
                                    "investment" : "",
                                    "number" : "",
                                }],
                                "highType" : ""
                            }]
                    } as I신주발행_유상증자
                    break;
                }
            case "등기신청을_위한_추가_정관변경":
                {
                    const _agendaDetail = agendaDetail as I등기신청을_위한_추가_정관변경;
                    return {
                        articlesOfAssociations : _agendaDetail.articlesOfAssociations.map((articlesOfAssociation) => {
                            return {
                                origin : articlesOfAssociation?.origin || "",
                                latest : {
                                    content : articlesOfAssociation?.latest?.content || "",
                                    description : articlesOfAssociation?.latest?.description || "",
                                    보통주_issueLimit : articlesOfAssociation?.latest?.보통주_issueLimit || "",
                                    우선주_issueLimit : articlesOfAssociation?.latest?.우선주_issueLimit || "",
                                    category : articlesOfAssociation?.latest?.category || "",
                                    issueLimitYn : articlesOfAssociation?.latest?.issueLimitYn ?? false
                                }
                            }
                        })
                    } as I등기신청을_위한_추가_정관변경;
                    break;
                }
            case "액면금분할_합병":
                {
                    const _agendaDetail = agendaDetail as I액면금분할_합병;
                    return {
                        afterParValue : _agendaDetail?.afterParValue ? (_agendaDetail?.afterParValue).toString() : "",
                        beforeParValue : _agendaDetail?.beforeParValue
                    } as I액면금분할_합병;
                    break;
                }
        }
    }
    setSelectedAgendas(selectedAgenda : SelectedAgenda[]) {
        agendaStepModel.selectedAgendas = selectedAgenda.map((agenda)  => {
            return {
                id : agenda.id || null,
                agendaType : agenda.agendaType,
                의결기관 : agenda.의결기관 || null,
                savedYn : agenda.savedYn || false,
                savedDataYn : agenda.savedYn || false,
                title : agenda?.title || "",
                registrationRequiredYn : agenda?.registrationRequiredYn || false,
                agendaDetail : this.agendaDetail(agenda.agendaType, agenda.agendaDetail)
            }
        })
    }
    async load() {
        const res = await get<LoadRs>(`/company/${corpModel.id}/agendaProgress`);
        
        runInAction(() => {
            this.setGuides(res.data.guides);
            this.setProgressAgendas(res.data.progressAgendas);
            this.setCompletedAgendas(res.data.completedAgendas);
        })
    }
    async loadAgendaStep(agendaProgressId : string | null) {
        let res : AxiosResponse<LoadAgendaStepRs>;
        if (agendaProgressId) {
            res = await get<LoadAgendaStepRs>(`/company/${corpModel.id}/agendaProgress/agenda?agendaProgressId=${agendaProgressId}`);
        } else {
            res = await get<LoadAgendaStepRs>(`/company/${corpModel.id}/agendaProgress/agenda`);
        }

        let registrationRequiredYn = false;
        for(let agendaKey in res.data.agenda) {
            res.data.agenda[agendaKey].forEach((agenda) => {
                if(agenda.registrationRequiredYn && agenda.selectedYn) {
                    registrationRequiredYn = true;
                }
            })
        }
        runInAction(() => {
            this.setRegistrationRequiredYn(registrationRequiredYn);
            this.setHasBoardOfDirectors(res.data.hasBoardOfDirectors);
            this.setAgenda(res.data.agenda);
            this.setHighlights(res.data.highlights);
            this.setSelectedAgendas(res.data.selectedAgendas);
            agendaModel.capital = res.data.capital || 0;
            agendaModel.totalNumOf_감사 = res.data.totalNumOf_감사 || 0;
            agendaModel.totalNumOf_이사 = res.data.totalNumOf_이사 || 0;
            agendaModel.agendaCounts = res.data.agendaCounts || [];
        })
    }
    async selectAgenda(param : SelectAgendaRq) {
        const res = await post<SelectAgendaRs>(`/company/${corpModel.id}/agendaProgress/agenda`, param);
        if (res.data.selectedAgendas.length === 0) {
            await this.nextStep(res.data.agendaProgressId);
        } else {
            if (!param.agendaProgressId) {
                pageRouter.push({
                    search: `?corp=${corpModel.id}&agendaProgressId=${res.data.agendaProgressId}`
                })
            }

            let registrationRequiredYn = false;
            for(let agendaKey in agendaModel.agenda) {
                agendaModel.agenda[agendaKey].forEach((agenda) => {
                    if(agenda.registrationRequiredYn && agenda.selectedYn) {
                        registrationRequiredYn = true;
                    }
                })
            }
            runInAction(() => {
                this.setSelectedAgendas(res.data.selectedAgendas);
                this.setRegistrationRequiredYn(registrationRequiredYn);
            })
        }
    }
    async executiveModal(param : FormData) {
        const res = await post<ExecutiveModalRs>(`/company/${corpModel.id}/agendaProgress/agenda/executive`, param, { Converter: 'agenda-update' });
        const data = res.data;
        runInAction(() => {
            ExecutiveModalModel.validation = {
                title : data?.validation?.title || "",
                content : data?.validation?.content || "",
                validated : data?.validation?.validated ?? null
            }
            ExecutiveModalModel.modalOpened = true;
            ExecutiveModalModel.executives = data.executives.map((executive) => {
                if('executiveMembers' in executive) {
                    const _executive = executive as ExecutiveModalMembers;
                    return {
                        executiveMembers : _executive.executiveMembers.map((executiveMember) => {
                            return {
                                changeType : executiveMember?.changeType,
                                originExecutive : {
                                    name : executiveMember?.originExecutive?.name || "",
                                    position : executiveMember?.originExecutive?.position || ""
                                },
                                updateExecutive : {
                                    name : executiveMember?.updateExecutive?.name || "",
                                    position : executiveMember?.updateExecutive?.position || ""
                                }
                            }
                        })
                    } as ExecutiveModalMembers

                }else{
                    const _executive = executive as ExecutiveModalMember;
                    return {
                        changeType : _executive?.changeType,
                        originExecutive : {
                            name : _executive?.originExecutive?.name || "",
                            position : _executive?.originExecutive?.position || ""
                        },
                        updateExecutive : {
                            name : _executive?.updateExecutive?.name || "",
                            position : _executive?.updateExecutive?.position || ""
                        }
                    } as ExecutiveModalMember
                }
            })
        })
    }
    async saveAgenda(param : FormData, agendaProgressId ?: string | null, beforeSend ?: boolean) {
        if(param.get("agendaDetail.agendaType") === "전환사채_발행") {
            param.set("agendaDetail.totalAmount", removeCommas(param.get("agendaDetail.totalAmount") as string))
            param.set("agendaDetail.issueCount", removeCommas(param.get("agendaDetail.issueCount") as string))
        }
        if(param.get("agendaDetail.agendaType") === "임원_취임_중임_사임_퇴임") {
            let changeAddress = false
            agendaStepModel.selectedAgendas.forEach((selectedAgenda) => {
                const _agendaDetail = selectedAgenda.agendaDetail as I임원_취임_중임_사임_퇴임;
                if(selectedAgenda.agendaType === param.get("agendaDetail.agendaType") && _agendaDetail.changeAddressExecutives.length !== 0 ) {
                    changeAddress = true;
                }
            })
            if(changeAddress && !param.get("agendaDetail.changeAddressExecutives[]")) {
                return _alert(`집주소 변경이 필요한 대표님을 선택해 주세요.`);
            }
            if(typeof agendaProgressId === "string") {
                param.append('agendaDetail.agendaProgressId', agendaProgressId);
            }
            
            // 저장 후 다음버튼 누를땐 beforeSend가 true로 넘어오면서 저장하지 않고 팝업을 띄운다.
            // 팝업에서 네 누를경우 beforeSend를 넘기지않고 저장해두었던 param을 사용하여 실제로 저장함.
            if(beforeSend === true) { 
                ExecutiveModalModel.executiveParams = param;
                return this.executiveModal(param)
            }
        }
        if(param.get("agendaDetail.agendaType") === "대표이사_주소_변경") {
            if(!param.get("agendaDetail.changeAddressExecutives[]")) {
                return _alert(`집주소 변경이 필요한 대표님을 선택해 주세요.`);
            }
        }
        if(param.get("agendaDetail.agendaType") === "임원_퇴직금_지급규정_승인" || param.get("agendaDetail.agendaType") === "임원_상여금_지급규정_승인" || param.get("agendaDetail.agendaType") === "임원_보수_지급규정_승인") {
            let isFileUpload = false;
            agendaStepModel.selectedAgendas.forEach((selectedAgenda) => {
                const _agendaDetail = selectedAgenda.agendaDetail as CommonAgendaDetail;
                if(selectedAgenda.agendaType === param.get("agendaDetail.agendaType") && _agendaDetail.attachmentFiles.length !== 0) {
                    isFileUpload = true;
                }
            })
            for(let [key] of param) {
                if(key.includes('agendaDetail.attachmentFile.uploadFiles')) {
                    isFileUpload = true;
                }
            }
            if(!isFileUpload) {
                const agendaDetailTitle = param.get("agendaDetail.title") as string;
                return _alert(`${agendaDetailTitle.replace(" 승인의 건", "")} 파일을 업로드해주세요`);
            }
        }
        
        if(param.get("agendaDetail.agendaType") === "임원_보수_한도_승인") {
            const regExp = /\[\d+\]/g;
            for(let [key] of param) {
                if(key.includes('agendaDetail.salaryLimitOfExecutive')) {
                    let newKey = key.replace(regExp, '');
                    if(newKey === 'agendaDetail.salaryLimitOfExecutives.salary') {
                        param.set(key, removeCommas(param.get(key) as string))
                    }
                }
            }
            param.set("agendaDetail.이사SalaryLimit", removeCommas(param.get("agendaDetail.이사SalaryLimit") as string))
            param.set("agendaDetail.감사SalaryLimit", removeCommas(param.get("agendaDetail.감사SalaryLimit") as string))
            param.delete('agendaDetail.approvalStartAt');
            param.delete('agendaDetail.approvalEndAt');
        }
        if(param.get("agendaDetail.agendaType") === "임원_보수_결정") {
            const regExp = /\[\d+\]/g;
            for(let [key] of param) {
                if(key.includes('agendaDetail.salaryOfExecutives')) {
                    let newKey = key.replace(regExp, '');
                    if(newKey === 'agendaDetail.salaryOfExecutives.salary') {
                        param.set(key, removeCommas(param.get(key) as string))
                    }
                }
            }
            param.delete('agendaDetail.decisionStartAt');
            param.delete('agendaDetail.decisionEndAt');
        }
        if(param.get("agendaDetail.agendaType") === "신주발행_유상증자") {
            const regExp = /\[\d+\]/g;
            for(let [key] of param) {
                if(key.includes('agendaDetail.newSharesAcquirers')) {
                    let newKey = key.replace(regExp, '');
                    if(newKey === 'agendaDetail.newSharesAcquirers.stocks.investment') {
                        param.set(key, removeCommas(param.get(key) as string));
                    }
                    if(newKey === 'agendaDetail.newSharesAcquirers.stocks.number') {
                        param.set(key, removeCommas(param.get(key) as string));
                    }
                }
                if(key.includes('highType')) {
                    param.delete(key)
                }
            }
        }
        if(param.get("agendaDetail.agendaType") === "신주발행_무상증자") {
            if(!param.get("agendaDetail.increaseAmount")) {
                return _alert('단주 조정하기를 클릭해주세요.');
            }
            param.set("agendaDetail.increaseAmount", removeCommas(param.get("agendaDetail.increaseAmount") as string))
            param.set("agendaDetail.beforeIncreaseAmount", removeCommas(param.get("agendaDetail.beforeIncreaseAmount") as string))
        }
        if(param.get("agendaDetail.agendaType") === "등기신청을_위한_추가_정관변경") {
            param.set("agendaDetail.보통주_issueLimit", removeCommas(param.get("agendaDetail.보통주_issueLimit") as string))
            param.set("agendaDetail.우선주_issueLimit", removeCommas(param.get("agendaDetail.우선주_issueLimit") as string))
        }
        if(param.get("agendaDetail.agendaType") === "액면금분할_합병") {
            param.set("agendaDetail.parValue", removeCommas(param.get("agendaDetail.parValue") as string))
        }

        const res = await put<SaveAgendaRs>(`/company/${corpModel.id}/agendaProgress/agenda`, param, { Converter: 'agenda-update' });

        if(param.get("agendaDetail.agendaType") === "본점의_주소_변경") {
            let index = res.data.selectedAgendas.findIndex((obj) => obj.agendaType === '본점의_주소_변경');
            const _본점의_주소_변경AgendaDetail = res.data.selectedAgendas[index].agendaDetail as I본점의_주소_변경;
            if(_본점의_주소_변경AgendaDetail.jurisdictionYn) {
                await _alert('관내이전(관할 내에서 이전하는 경우)에 해당해요.', '관내이전 절차에 맞게 진행해드릴게요!');
            } else {
                await _alert('관외이전(관할 밖으로 이전하는 경우)에 해당해요', '관외이전 절차에 맞게 진행해드릴게요!');
            }
        }
        
        this.setSelectedAgendas(res.data.selectedAgendas);
    }
    async nextStep(agendaProgressId : string) {
        const res = await post<NextStepRs>(`/company/${corpModel.id}/agendaProgress/agenda/next`, { agendaProgressId: agendaProgressId });
        if(res?.data?.messages?.content) {
            await _alert(res?.data?.messages?.content, res?.data?.messages?.subContent);
        }
        pageRouter.replace(`/corp/agenda/step/${res.data.step}/?corp=${corpModel.id}&agendaProgressId=${agendaProgressId}`);
    }
    async getIncreaseAmountData(agendaProgressId : string, beforeIncreaseAmount : number, corp : string, increaseDate : string) {
        const res = await get<GetIncreaseAmountDataRs>(`/company/${corp}/agendaProgress/${agendaProgressId}/agenda/stock`, { increaseAmount: beforeIncreaseAmount, increaseDate });
        if(res.data.info.length === 0) {
            _alert('무상증자할 자본금을 조정해주세요.', '입력한 자본금이 너무 작아서 모든 주주들에게 공평하게 배정할 수 없어요.');
        }
        return res.data.info;
    }
    async cancelAgenda(agendaProgressId : string, companyId : string) {
        await _delete(`/company/${companyId}/agendaProgress/${agendaProgressId}`);
        window.location.reload();
    }
    async shareHolderIncreaseDateValidate(agendaProgressId : string, increaseDate : string, corp : string) {
        const res = await get<{existsShareholderListYn : boolean}>(`/company/${corp}/agendaProgress/${agendaProgressId}/agenda/stock/shareholder/validate`, { increaseDate });
        return res.data.existsShareholderListYn;
    }
}
export default new agendaService();