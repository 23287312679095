import type { LoadBRStatusRs, LoadInfoInputRs, RosterListRs, taxOfficeRegistrationRs, CompleteRs, CompleteHomeRs, infoInputRs, documentRs, documentShare, addBankbookRs, changeBankbookRs, deleteBankbookRs, sequenceBankbookRS, FileExtensionType, LoadBesinessRegistrationEmailSignModalRs, FileCustomDownloadRq } from './BusinessRegistrationService.interface';

import { post, get, downloadFileGet, put, _delete, downloadFilePost } from '@common/module/httpRequest';
import { runInAction } from 'mobx';
import corpModel from '@model/Corp/corpModel';
import businessRegistrationModel from '@model/Corp/BusinessRegistration/businessRegistrationModel';
import { _alert } from '@model/dialogModel';
import portalModel from '@model/portalModel';
import BesinessRegistrationEmailSignModalModel from "@src/model/Corp/BusinessRegistration/BesinessRegistrationEmailSignModal/BesinessRegistrationEmailSignModalModel";
import loadingModel from '@model/loadingModel';

class businessRegistrationService {
    async loadBusinessRegistrationStatus() {
        const res = await get<LoadBRStatusRs>(`/company/${corpModel.id}/businessRegistration`);
        runInAction(() => {
            if(res.data.businessRegistrationStatuses && res.data.businessRegistrationStatuses.length !== 0) {
                businessRegistrationModel.businessRegistrationStatuses = res.data.businessRegistrationStatuses.map((status) => {
                    return {
                        status: status.status || "",
                        startAt: status.startAt || ""
                    }    
                })
            } else {
                businessRegistrationModel.businessRegistrationStatuses = [];
            }
            businessRegistrationModel.viewingPeriodFinishedYn = res.data?.viewingPeriodFinishedYn ?? false;
            businessRegistrationModel.pageType = res.data?.pageType || null;
            businessRegistrationModel.businessRegistrationFile = res.data?.businessRegistrationFile || "";
            businessRegistrationModel.guides = res.data?.guides ? res.data?.guides.map((guide) => {
                return {
                    title : guide?.title || "",
                    contents : guide?.contents || "",
                    url : guide?.url || ""
                }
            }) : [];
            if(res.data.bankbooks && res.data.bankbooks.length !== 0) {
                businessRegistrationModel.bankbooks = res.data.bankbooks.map((bankbook) => {
                    return {
                        id: bankbook.id || -1,
                        bankName: bankbook.bankName || null,
                        accountNumber: bankbook.accountNumber || "",
                        accountUsage: bankbook.accountUsage || "",
                        fileDownloadUrl: bankbook.fileDownloadUrl || ""
                    }
                })
            } else {
                businessRegistrationModel.bankbooks = [];
            }
        })
    }
    async loadInfoInput() {
        const res = await get<LoadInfoInputRs>(`/company/${corpModel.id}/businessRegistration/infoInput`);
        runInAction(() => {
            businessRegistrationModel.companyName = res.data.companyName || "";
            businessRegistrationModel.establishedAt = res.data.establishedAt || "";
            businessRegistrationModel.dateOfBusinessCommencement = res.data.dateOfBusinessCommencement || "";
            if(res.data.representatives && res.data.representatives.length !== 0) {
                businessRegistrationModel.representatives = res.data.representatives.map((representative) => {
                    return {
                        id: representative.id || "",
                        name: representative.name || "",
                        representativeIDFiles: representative.representativeIDFiles || []
                    }
                })
            } else {
                businessRegistrationModel.representatives = [];
            }
            if(res.data.corporateLeaseAgreementFiles && res.data.corporateLeaseAgreementFiles.length !== 0) {
                businessRegistrationModel.corporateLeaseAgreementFiles = res.data.corporateLeaseAgreementFiles.map((file) => {
                    return {
                        id: file.id || "",
                        fileName: file.fileName || "",
                        downloadUrl: file.downloadUrl || ""
                    }
                })
            } else {
                businessRegistrationModel.corporateLeaseAgreementFiles = [];
            }
            if(res.data.representativeFamilyRelationships && res.data.representativeFamilyRelationships.length !== 0) {
                businessRegistrationModel.representativeFamilyRelationships = res.data.representativeFamilyRelationships.map((familyRelation) => {
                    return {
                        id: familyRelation.id || "",
                        name: familyRelation.name || "",
                        relationship: familyRelation.relationship || ""
                    }
                })
            } else {
                businessRegistrationModel.representativeFamilyRelationships = null;
            }
            businessRegistrationModel.selectableBusinessPurposes = res.data.selectableBusinessPurposes || [];
            if(res.data.typeOfBusinesses && res.data.typeOfBusinesses.length !== 0) {
                businessRegistrationModel.typeOfBusinesses = res.data.typeOfBusinesses.map((type) => {
                    return {
                        id: type.id || "",
                        businessPurpose: type.businessPurpose || "",
                        description: type.description || ""
                    }
                })
            } else {
                businessRegistrationModel.typeOfBusinesses = [];
            }
            if(res.data.permitFiles && res.data.permitFiles.length !== 0) {
                businessRegistrationModel.permitFiles = res.data.permitFiles.map((permitFile) => {
                    return {
                        id: permitFile.id || "",
                        fileName: permitFile.fileName || "",
                        downloadUrl : permitFile.downloadUrl || ""
                    }
                })
            } else {
                businessRegistrationModel.permitFiles = [];
            }
            businessRegistrationModel.hometaxDelegationAcceptanceYn = res.data.hometaxDelegationAcceptanceYn ?? null;
            businessRegistrationModel.bookkeepingServiceApplyYn = res.data.bookkeepingServiceApplyYn ?? null;
            businessRegistrationModel.cssn = res.data?.cssn || "";
            businessRegistrationModel.freeMonth = res.data?.freeMonth || "";
        })
    }
    async infoInputTemp(param: FormData) {
        const res = await post<LoadInfoInputRs>(`/company/${corpModel.id}/businessRegistration/infoInput/temp`, param);
        runInAction(() => {
            businessRegistrationModel.companyName = res.data.companyName || "";
            businessRegistrationModel.establishedAt = res.data.establishedAt || "";
            businessRegistrationModel.dateOfBusinessCommencement = res.data.dateOfBusinessCommencement || "";
            if(res.data.representatives && res.data.representatives.length !== 0) {
                businessRegistrationModel.representatives = res.data.representatives.map((representative) => {
                    return {
                        id: representative.id || "",
                        name: representative.name || "",
                        representativeIDFiles: representative.representativeIDFiles || []
                    }
                })
            } else {
                businessRegistrationModel.representatives = [];
            }
            if(res.data.corporateLeaseAgreementFiles && res.data.corporateLeaseAgreementFiles.length !== 0) {
                businessRegistrationModel.corporateLeaseAgreementFiles = res.data.corporateLeaseAgreementFiles.map((file) => {
                    return {
                        id: file.id || "",
                        fileName: file.fileName || "",
                        downloadUrl: file.downloadUrl || ""
                    }
                })
            } else {
                businessRegistrationModel.corporateLeaseAgreementFiles = [];
            }
            if(res.data.representativeFamilyRelationships && res.data.representativeFamilyRelationships.length !== 0) {
                businessRegistrationModel.representativeFamilyRelationships = res.data.representativeFamilyRelationships.map((familyRelation) => {
                    return {
                        id: familyRelation.id || "",
                        name: familyRelation.name || "",
                        relationship: familyRelation.relationship || ""
                    }
                })
            } else {
                businessRegistrationModel.representativeFamilyRelationships = null;
            }
            businessRegistrationModel.selectableBusinessPurposes = res.data.selectableBusinessPurposes || [];
            if(res.data.typeOfBusinesses && res.data.typeOfBusinesses.length !== 0) {
                businessRegistrationModel.typeOfBusinesses = res.data.typeOfBusinesses.map((type) => {
                    return {
                        id: type.id || "",
                        businessPurpose: type.businessPurpose || "",
                        description: type.description || ""
                    }
                })
            } else {
                businessRegistrationModel.typeOfBusinesses = [];
            }
            if(res.data.permitFiles && res.data.permitFiles.length !== 0) {
                businessRegistrationModel.permitFiles = res.data.permitFiles.map((permitFile) => {
                    return {
                        id: permitFile.id || "",
                        fileName: permitFile.fileName || "",
                        downloadUrl : permitFile.downloadUrl || ""
                    }
                })
            } else {
                businessRegistrationModel.permitFiles = [];
            }
            businessRegistrationModel.hometaxDelegationAcceptanceYn = res.data.hometaxDelegationAcceptanceYn ?? null;
            businessRegistrationModel.bookkeepingServiceApplyYn = res.data.bookkeepingServiceApplyYn ?? null;
            businessRegistrationModel.cssn = res.data?.cssn || "";
        })
    }
    async infoInput(param: FormData) {
        const res = await post<infoInputRs>(`/company/${corpModel.id}/businessRegistration/infoInput`, param);
        runInAction(() => {
            if(res.data.businessRegistrationStatuses && res.data.businessRegistrationStatuses.length !== 0) {
                businessRegistrationModel.businessRegistrationStatuses = res.data.businessRegistrationStatuses.map((status) => {
                    return {
                        status: status.status || "",
                        startAt: status.startAt || ""
                    }    
                })
            } else {
                businessRegistrationModel.businessRegistrationStatuses = [];
            }
        })
    }
    async taxOfficeRegistration() {
        const res = await get<taxOfficeRegistrationRs>(`/company/${corpModel.id}/businessRegistration/taxOfficeRegistration`);
        runInAction(() => {
            businessRegistrationModel.filingReceiptFile = res.data.filingReceiptFile || "";
            businessRegistrationModel.filingReceiptThumbnailFile = res.data.filingReceiptThumbnailFile || ""         
        })
    }
    async businessRegistrationCompleted() {
        const res = await get<CompleteRs>(`/company/${corpModel.id}/businessRegistration/completed`);
        runInAction(() => {
            businessRegistrationModel.businessRegistrationFile = res.data.businessRegistrationFile || "";
            businessRegistrationModel.businessRegistrationThumbnailFile = res.data.businessRegistrationThumbnailFile || "";
            businessRegistrationModel.businessRegistrationNumber = res.data.businessRegistrationNumber || ""
        })
    }   
    async fileDownload(downloadLink: string) {
        await downloadFileGet(downloadLink);
    }
    async rosterList() {
        const res = await get<RosterListRs>(`/company/${corpModel.id}/businessRegistration/rosterList`);
        runInAction(() => {
            businessRegistrationModel.rosterListDownloadLink = res.data.rosterListDownloadLink || "";
        })
    }
    async downLoadUrl(url: string) {
        await downloadFileGet(url);
    }
    async fileUpload(file: File, detailUrl: string = '/uploadFile') {
        let formData = new FormData();
        formData.append("businessRegistrationFile", file)
        await post(`/company/${corpModel.id}/businessRegistration${detailUrl}`, formData);
    }
    async completeHome() {
        const res = await get<CompleteHomeRs>(`/company/${corpModel.id}/businessRegistration/completeHome`);
        runInAction(() => {
            businessRegistrationModel.fileDownloadUrl = res.data?.fileDownloadUrl || "";
            businessRegistrationModel.issuedAt = res.data?.issuedAt || "";
            businessRegistrationModel.information.businessRegistrationNumber = res.data?.information?.businessRegistrationNumber || "";
            businessRegistrationModel.information.establishedAt = res.data?.information?.establishedAt || "";
            businessRegistrationModel.information.businessStartAt = res.data?.information?.businessStartAt || "";
            businessRegistrationModel.information.businessYear = res.data?.information?.businessYear|| "";
            businessRegistrationModel.businessTypeAndItemList = res.data?.businessTypeAndItemList ? res.data?.businessTypeAndItemList.map((businessTypeAndItem) => {
                return {
                    type: businessTypeAndItem.type || "",
                    item: businessTypeAndItem.item || ""
                }
            }) : [];
            if(res.data.bankbooks && res.data.bankbooks.length !== 0) {
                businessRegistrationModel.bankbooks = res.data.bankbooks.map((bankbook) => {
                    return {
                        id: bankbook.id || -1,
                        bankName: bankbook.bankName || null,
                        accountNumber: bankbook.accountNumber || "",
                        accountUsage: bankbook.accountUsage || "",
                        fileDownloadUrl: bankbook.fileDownloadUrl || ""
                    }
                })
            } else {
                businessRegistrationModel.bankbooks = [];
            }

        })
    }
    async document() {
        const res = await get<documentRs>(`/company/${corpModel.id}/businessRegistration/document`);
        runInAction(() => {
            businessRegistrationModel.businessRegistration.companyName = res.data.businessRegistration.companyName || "";
            businessRegistrationModel.businessRegistration.businessRegistrationNumber = res.data.businessRegistration.businessRegistrationNumber || "";
            if(res.data.bankbooks && res.data.bankbooks.length !== 0) {
                businessRegistrationModel.bankbooks = res.data.bankbooks.map((bankbook) => {
                    return {
                        id: bankbook.id || -1,
                        bankName: bankbook.bankName || null,
                        accountUsage: bankbook.accountUsage || "",
                        accountNumber: bankbook.accountNumber || "",
                    }
                })
            } else {
                businessRegistrationModel.bankbooks = [];
            }
        })
    }
    async documentShare(param: documentShare) {
        await post(`/company/${corpModel.id}/businessRegistration/document/share`, param);
    }
    async documentDetail() {
        const res = await get<documentRs>(`/company/${corpModel.id}/businessRegistration/document/detail`);
        runInAction(() => {
            businessRegistrationModel.businessRegistration.companyName = res.data.businessRegistration.companyName || "";
            businessRegistrationModel.businessRegistration.businessRegistrationNumber = res.data.businessRegistration.businessRegistrationNumber || "";
            if(res.data.bankbooks && res.data.bankbooks.length !== 0) {
                businessRegistrationModel.bankbooks = res.data.bankbooks.map((bankbook) => {
                    return {
                        id: bankbook.id || -1,
                        bankName: bankbook.bankName || null,
                        accountNumber: bankbook.accountNumber || "",
                        accountUsage: bankbook.accountUsage || "",
                        fileDownloadUrl: bankbook.fileDownloadUrl || "",
                        viewHistories: (bankbook.viewHistories && bankbook.viewHistories.length !== 0 ) ? bankbook.viewHistories.map((viewHistory) => {
                            return {
                                userName: viewHistory.userName || "",
                                expireDate: viewHistory.expireDate || ""
                            }
                        }) : []
                    }
                })
            } else {
                businessRegistrationModel.bankbooks = [];
            }
        })
    }
    async addBankbook(param: FormData) {
        await post<addBankbookRs>(`/company/${corpModel.id}/businessRegistration/document/bankbook`, param);
        await _alert('계좌정보가 저장되었어요.');
        runInAction(() => {
            portalModel.title = null;
            portalModel.content = null;
        })
        window.location.reload();
    }     
    async changeBankbook(param: FormData, bankbookId: number) {
        await put<changeBankbookRs>(`/company/${corpModel.id}/businessRegistration/document/bankbook/${bankbookId}`, param);
        await _alert('계좌정보가 수정되었어요.');
        runInAction(() => {
            portalModel.title = null;
            portalModel.content = null;
        })
        window.location.reload();
    }
    async deleteBankbook(bankbookId: number) {
        await _delete<deleteBankbookRs>(`/company/${corpModel.id}/businessRegistration/document/bankbook/${bankbookId}`)
        window.location.reload();
    }
    async sequenceBankbook(param: sequenceBankbookRS) {
        await post<sequenceBankbookRS>(`company/${corpModel.id}/businessRegistration/document/bankbook/sequence`, param);
    }
    async fileTypeDown(type: FileExtensionType) {
        runInAction(async () => {
            try {
                loadingModel.showYn = true;
                await downloadFileGet(`/company/${corpModel.id}/businessRegistration/document/file/download?type=${type}`);
                loadingModel.showYn = false;
            } catch(err) {
                loadingModel.showYn = false;
            }
        })
    }
    async loadBesinessRegistrationEmailSignModal() {
        const res = await get<LoadBesinessRegistrationEmailSignModalRs>(`/company/${corpModel.id}/businessRegistration/document/additionalInfo`);
        runInAction(() => {
            BesinessRegistrationEmailSignModalModel.content = res.data?.content || '';
            BesinessRegistrationEmailSignModalModel.email = res.data?.email || '';
            BesinessRegistrationEmailSignModalModel.fileExtensionType = res.data?.fileType || 'JPG';
        })
    }
    async fileCustomDownload(param: FileCustomDownloadRq) {
        await downloadFilePost(`/company/${corpModel.id}/businessRegistration/document/file/download/custom`, param);
    }
}

export default new businessRegistrationService();