import { observable, makeObservable } from 'mobx';

class loadingModel {
    constructor() {
        makeObservable(this, {
            showYn : observable,
        });
    }
    showYn: boolean = false;
}

export default new loadingModel();