import corpModel from '@model/Corp/corpModel';
import { pageRouter } from '@model/pageModel';
import corpService from '@service/Corp/corpService';
import userModel from '@model/userModel';
import popupService from '@service/popupService';
import {toJS} from 'mobx';

class Corpvm {
    load(id) {
        if(id) {
            corpModel.id = Number(id) || null;
            corpService.loadFrame(id);
        }else{
            corpModel.id = null;
            alert('올바른 법인 주소가 아닙니다.');
            pageRouter.replace(`/userDashboard`);
        }
    }
    async popupLoad() {
        await popupService.loadPopup();
    }
    removeCorp() {
        corpModel.id = null;
    }
    get id() {
        return corpModel.id;
    }
    get companyRegisterStatus() {
        return corpModel.companyRegisterStatus;
    }
    get nowDate() {
        return userModel.date;
    }
    get isDemo() {
        return corpModel.companies.find((company) => (company.companyId).toString() === (this.id).toString())?.isDemoYn;
    }
    get liquidationYn() {
        return corpModel.companies.find((company) => (company.companyId).toString() === (this.id).toString())?.liquidationYn;
    }
    get alliancePopupShowYn() {
        return corpModel?.alliancePopupShowYn;
    }
    alliancePopupClose() {
        corpService.alliancePopupClose();
    }
    alliancePopupShowPath(pathName) {
        const pathList = ['/accounting/businessRegistration', '/register', '/articlesOfAssociation', '/agenda', '/stock', '/corp/dashboard'];
        return pathList.some((path) => {
            return pathName.includes(path)
        })
    }
}

export default new Corpvm();