import agendaStepModel from '@model/Corp/Agenda/Step/agendaStepModel';
import { removeCommas, onlyNumber } from '@common/module/replaceNumber';
import type {SelectedAgenda, I전환사채_발행} from '@model/Corp/Agenda/Step/agendaStepModel.interface';

class IssuanceOfConvertibleBondsVm {
    selectedAgenda(index : number) {
        return agendaStepModel.selectedAgendas[index] as SelectedAgenda<I전환사채_발행>
    }
    publicationTarget(e : React.ChangeEvent<HTMLInputElement>, index : number) {
        this.selectedAgenda(index).agendaDetail.publicationTarget = e.target.value;
    }
    totalAmount(e : React.ChangeEvent<HTMLInputElement>, index : number) {
        this.selectedAgenda(index).agendaDetail.totalAmount = removeCommas((onlyNumber(e.target.value)).toString());
    }
    issueCount(e : React.ChangeEvent<HTMLInputElement>, index : number) {
        this.selectedAgenda(index).agendaDetail.issueCount = removeCommas((onlyNumber(e.target.value)).toString());
    }
}
export default new IssuanceOfConvertibleBondsVm();