import AgendaDashboardModel from '@model/AgendaDashboard/AgendaDashboardModel';
import portalModel from '@model/portalModel';
import { runInAction } from 'mobx';
import MemoWriteModal from './MemoWriteModal/MemoWriteModal';

class MemoVm {
    get memo() {
        return AgendaDashboardModel.memo
    }
    openModal(memo : string) {
        runInAction(() => {
            portalModel.title = "입력하기";
            portalModel.content = <MemoWriteModal memo={memo}/>;
        })
    }
}
export default new MemoVm();