import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { Fragment, useEffect } from 'react';
import DownloadVm from './DownloadVm';
import st from './Download.module.scss';
import Radio from '@standby/common-ui/components/atoms/Input/Radio/Radio';
import Select from '@standby/common-ui/components/atoms/Input/Select/Select';
import ToolTip from '@standby/common-ui/components/molecules/ToolTip/ToolTip';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import DatePicker from '@standby/common-ui/components/molecules/DatePicker/DatePicker';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import regExp from '@common/module/regExp';
import clsx from 'clsx';
import useComponentWillMount from '@common/hook/useComponentWillMount';
import { reaction } from 'mobx';
import Loading from '@commonComponents/Loading/Loading';

let vm;

function Download(props) {

    useComponentWillMount(() => {
        vm = new DownloadVm;
    })

    useEffect(() => {
        vm.load();
        reaction(
            () => vm.previewLink,
            previewLink => {
                if(previewLink) {
                    window.frames['previewFrame'].location.replace(previewLink);
                }
            }
        )
    }, []);

    return useObserver(() => (
        <form onSubmit={(e) => vm.submit(e, props.next, props.setSendParams)} className={st.unissuedStockCertificate}>
            {vm.state.loadingYn && <Loading />}
            <div className={st.title}>주권미발행확인서</div>
            <div className={st.contentBox}>
                <div className={st.documentBox}>
                    <div className={st.content}>
                        <iframe name='previewFrame' title="주권미발행확인서 미리보기" className={st.document}/>
                    </div>
                </div>
                <div className={st.documentOption}>
                    <div className={st.optionSeparate}>
                        <div className={st.optionBox}>
                            <div className={st.optionTitle}>작성일자</div>
                            <div className={st.option}>
                                <DatePicker className={st.datepicker} selected={vm.state.createdAt} onChange={(e) => vm.setCreatedAt(e)} minDate={vm.minDate}/>
                            </div>
                        </div>
                    </div>
                    <div className={st.optionSeparate}>
                        <div className={st.optionBox}>
                            <div className={st.optionTitle}>발행할 주주 선택</div>
                            <div className={st.option}>
                                <Select name="shareholderId" value={vm.state.shareholderId} onChange={(e) => vm.setShareholderId(e)} style={{width: '240px'}} className={st.select}>
                                    {vm.shareholders.map((shareholder, idx) => {
                                        return (
                                            <option value={shareholder.id} key={idx}>{shareholder.name}</option>
                                        )                        
                                    })}
                                </Select>
                            </div>
                        </div>
                        <div className={st.optionBox}>
                            <div className={st.optionTitle}>주민번호(고유번호)</div>
                            <div className={st.option}>
                                <Radio className={st.radio} name="showShareholderUniqueInfo" value="ALL_SHOW" checked={vm.state.showShareholderUniqueInfo === 'ALL_SHOW'} onChange={(e) => vm.setShowShareholderUniqueInfo(e)}>공개</Radio>
                                <Radio className={clsx(st.radio, st.marginTop)} name="showShareholderUniqueInfo" value="HALF_SHOW" checked={vm.state.showShareholderUniqueInfo === 'HALF_SHOW'} onChange={(e) => vm.setShowShareholderUniqueInfo(e)}>앞자리만<br/>공개</Radio>
                                <Radio className={st.radio} name="showShareholderUniqueInfo" value="NO_SHOW" checked={vm.state.showShareholderUniqueInfo === 'NO_SHOW'} onChange={(e) => vm.setShowShareholderUniqueInfo(e)}>미공개</Radio>
                            </div>
                        </div>
                        {vm.displayCorpUniqueInfo() && 
                            <div className={st.optionBox}>
                                <div className={st.optionTitle}>법인/투자조합 주주 고유번호</div>
                                <div className={st.option}>
                                    <Radio className={clsx(st.radio, st.marginTop)} name="corpUniqueInfo" value="UNIQUE_NUMBER" checked={vm.state.corpUniqueInfo === 'UNIQUE_NUMBER'} onChange={(e) => vm.setCorpUniqueInfo(e)}>법인등록번호/<br/>고유번호</Radio>
                                    <Radio className={st.radio} name="corpUniqueInfo" value="BUSINESS_REGISTRATION_NUMBER" checked={vm.state.corpUniqueInfo === 'BUSINESS_REGISTRATION_NUMBER'} onChange={(e) => vm.setCorpUniqueInfo(e)}>사업자등록번호</Radio>
                                </div>
                            </div>
                        }
                        {vm.displayUniqueInfoInput() && 
                            <div className={st.optionBox}>
                            <div className={st.optionTitle}>
                                {vm.shareholderName}님의 {vm.uniqueNumberType.type}
                                <div className={st.optionTitleExplain}>입력하면 주주명부에 자동 저장되어요.</div>
                            </div>
                            <div className={st.option}>
                                {(vm.shareholderType === '해외성인' || vm.shareholderType === '해외미성년' || vm.shareholderType === '해외법인') ?
                                <>
                                    <DatePicker name="uniqueNumber" className={st.datepicker} selected={vm.state.updateInfo?.uniqueNumber} onChange={(e) => vm.setUniqueNumberBirth(e)} placeholder={vm.uniqueNumberType.placeholder} yearRange="birth" /> 
                                    <Error name="uniqueNumber" value={vm.state.updateInfo?.uniqueNumber} errorCase={{
                                        required: '생년월일을 선택해 주세요.'
                                    }}/>
                                </>
                                :
                                    <InputText name="uniqueNumber" value={vm.state.updateInfo?.uniqueNumber} onChange={(e) => vm.setUniqueNumber(e)} onBlur={() => vm.setBlur()} className={st.inputText} kssn={vm.uniqueNumberType.kssn} ein={vm.uniqueNumberType.ein} style={{width: '240px'}} placeholder={vm.uniqueNumberType.placeholder} errorText={<Error name="uniqueNumber" value={vm.state.updateInfo?.uniqueNumber} errorCase={{
                                        required: '주민번호(고유번호)를 입력하세요.',
                                        validate: vm.uniqueNumberType.validate
                                    }} />}/>
                                }
                            </div>
                        </div>
                        }
                        <div className={st.optionBox}>
                            <div className={st.optionTitle}>주주의 주소</div>
                            <div className={st.option}>
                                <Radio className={st.radio} name="showAddress" value="ALL_SHOW" checked={vm.state.showAddress === 'ALL_SHOW'} onChange={(e) => vm.setShowAddress(e)}>포함</Radio>
                                <Radio className={st.radio} name="showAddress" value="NO_SHOW" checked={vm.state.showAddress === 'NO_SHOW'} onChange={(e) => vm.setShowAddress(e)}>미포함</Radio>
                            </div>
                        </div>
                        {vm.displayAddressInfoInput() && 
                            <div className={st.optionBox}>
                                <div className={st.optionTitle}>
                                    {vm.shareholderName}님의 주소
                                    <div className={st.optionTitleExplain}>입력하신 주소는 주주명부에 저장됩니다.</div>
                                </div>
                                <div className={st.option}>
                                    <InputText name="address" value={vm.state.updateInfo?.address} onChange={(e) => vm.setAddress(e)} onBlur={() => vm.setBlur()}  className={st.inputText} style={{width:'240px'}} errorText={<Error  name="address" value={vm.state.updateInfo?.address} errorCase={{
                                        required: '주소를 입력하세요.'
                                    }}/>}/>
                                </div>
                            </div>
                        }
                    </div>
                    <div className={st.optionSeparate}>
                        <div className={st.optionBox}>
                            <div className={st.optionTitle}>
                                온라인 도장 날인
                            </div>
                            <div className={st.option}>
                                <Radio className={st.radio} name="showOnlineStampYn" value={true} checked={vm.state.showOnlineStampYn === true} onChange={(e) => vm.setShwoOnlineStampYn(e)}>날인함</Radio>
                                <Radio className={st.radio} name="showOnlineStampYn" value={false} checked={vm.state.showOnlineStampYn === false} onChange={(e) => vm.setShwoOnlineStampYn(e)}>날인 안 함</Radio>
                            </div>
                        </div>
                        {vm.showRepresentativeNameSelectionYn === true && 
                            <div className={st.optionBox}>
                                <div className={st.optionTitle}>서류 아래에 기재할 대표님 이름</div>
                                <div className={st.option}>
                                    {vm.representatives.map((item, idx) => (
                                        <Radio key={idx} className={clsx(st.radio, st.wrap)} name="representatives" value={item.id} checked={vm.state.representatives === String(item.id)} onChange={(e) => vm.setRepresentatives(e)}>{item.name}님</Radio>
                                    ))}
                                    <Radio className={clsx(st.radio, st.wrap)} name="representatives" value="ALL" checked={vm.state.representatives === 'ALL'} onChange={(e) => vm.setRepresentatives(e)}>대표님 모두</Radio>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className={st.btnArea}>
                <SquareBtn type="submit" name="download" className={st.downloadBtn} color="white">다운로드</SquareBtn>
                <SquareBtn type="submit" name="sendEmail" className={st.submitBtn}>이메일 발송</SquareBtn>
            </div>
        </form>
    ));
}
export default Download;