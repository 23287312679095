import { observable, runInAction } from 'mobx';
import bst from '@pages/Corp/Agenda/Step/FinalCheck/BeforeFinal/BeforeFinal.module.scss';
import beforeFinalService from '@src/service/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalService';
import portalModel from '@model/portalModel';
import OnlineAcceptancePopup from '@pages/Corp/Popup/OnlineAcceptancePopup/OnlineAcceptancePopup';

import type { SectionType } from '@src/model/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalModel.interface';

interface State {
    moreViewYn: boolean;
}

class OnlineAcceptanceVm {
    state: State = observable({
        moreViewYn: false
    })
    status(_status: string | null) {
        switch(_status) {
            case "발송전":
                return {
                    _className: bst.secon200,
                    text: '발송 전'
                }
            case "발송중":
                return {
                    _className: bst.secon200,
                    text: '발송 중'
                }
            case "발송완료":
                return {
                    _className: bst.secon200,
                    text: '발송완료(대기중)'
                }
            case "동의완료":
                return {
                    _className: bst.sub300,
                    text: '동의완료'
                }
            case "발송실패":
                return {
                    _className: bst.warning200,
                    text: '발송실패'
                }
        }
    }
    moreView() {
        this.state.moreViewYn = !this.state.moreViewYn;
    }
    openModal() {
        runInAction(() => {
            runInAction(() => {
                portalModel.title = "온라인 소집통지 수신동의 받기";
                portalModel.content = <OnlineAcceptancePopup />;
            })
        })
    }
    allDownload(agendaProgressId: string, type: SectionType) {
        beforeFinalService.fileAllDownload(agendaProgressId, type);
    }
    download(downloadUrl: string) {
        beforeFinalService.fileDownload(downloadUrl);
    }
    displayDownloadBtn(status: string | null, downloadUrl: string) {
        if(status === "동의완료" && !downloadUrl) {
            return false;
        } else {
            return true;
        }
    }
}

export default new OnlineAcceptanceVm();