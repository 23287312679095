import type {LoadRs} from './DashboardService.interface';

import { get, downloadFileGet, _delete } from '@common/module/httpRequest';
import { runInAction } from 'mobx';
import corpModel from '@model/Corp/corpModel';
import DashboardModel from '@model/Corp/Dashboard/DashboardModel';

class DashboardService {
    async load() {
        const res = await get<LoadRs>(`/company/${corpModel.id}/home`);
        const data = res.data;
        runInAction(() => {
            DashboardModel.dashboardData = {
                companyKoreanName : data?.companyKoreanName || "",
                companyEnglishName : data?.companyEnglishName || "",
                cssn : data?.cssn || "",
                establishedAt : data?.establishedAt || "",
                businessYear : data?.businessYear || "",
                businessRegistration : {
                    businessRegistrationNumber : data?.businessRegistration?.businessRegistrationNumber || "",
                    businessStartAt : data?.businessRegistration?.businessStartAt || "",
                    businessRegistrationStatus : data?.businessRegistration?.businessRegistrationStatus || ""
                },
                capital : data?.capital || "",
                parValue : data?.parValue || "",
                stocksNumber : data?.stocksNumber || "",
                numberOfStockTypes : data?.numberOfStockTypes || "",
                shareholdersCount : data?.shareholdersCount || "",
                shareholders : data?.shareholders ? data?.shareholders.map((shareholder) => {
                    return {
                        name : shareholder?.name || "",
                        value : shareholder?.value || ""
                    }
                }) : [],
                files : {
                    companyCopyFile : {
                        createdAt : data?.files?.companyCopyFile?.createdAt || "",
                        url : data?.files?.companyCopyFile?.url || ""
                    },
                    businessRegistrationFile : {
                        createdAt : data?.files?.businessRegistrationFile?.createdAt || "",
                        url : data?.files?.businessRegistrationFile?.url || ""
                    },
                    aoaFile : {
                        createdAt : data?.files?.aoaFile?.createdAt || "",
                        url : data?.files?.aoaFile?.url || ""
                    },
                    shareholderListFile : {
                        createdAt : data?.files?.shareholderListFile?.createdAt || "",
                        url : data?.files?.shareholderListFile?.url || ""
                    }
                },
                executiveMembers : data?.executiveMembers ? data?.executiveMembers.map((executiveMember) => {
                    return {
                        name : executiveMember?.name || "",
                        info : executiveMember?.info.map((info) => {
                            return {
                                position : info?.position || "",
                                termOfOfficeAt : info?.termOfOfficeAt || "",
                                remainingTermOfOffice : info?.remainingTermOfOffice || 0,
                                fullTermOfOffice : info?.fullTermOfOffice || 1200,
                                tag : info?.tag || "",
                            }
                        })
                    }
                }) : [],
                aoaRegistrationYn : data?.aoaRegistrationYn || false,
                aoa : {
                    contents : {
                        "결산기간": data?.aoa?.contents?.결산기간 || "",
                        "제3자_신주발행규정Yn": data?.aoa?.contents?.제3자_신주발행규정Yn || false,
                        "스톡옵션_규정": data?.aoa?.contents?.스톡옵션_규정 || "",
                        "우선주_발행_근거규정Yn": data?.aoa?.contents?.우선주_발행_근거규정Yn || false,
                        "제3자_주식_양도_제한Yn": data?.aoa?.contents?.제3자_주식_양도_제한Yn || false
                    },
                    detail : {
                        stockOption : {
                            existYn : data?.aoa?.detail?.stockOption?.existYn || false,
                            content : data?.aoa?.detail?.stockOption?.content || "",
                            additionalInformation : data?.aoa?.detail?.stockOption?.additionalInformation || "",
                        },
                        stockTransferRestrictions : {
                            existYn : data?.aoa?.detail?.stockTransferRestrictions?.existYn || false,
                            content : data?.aoa?.detail?.stockTransferRestrictions?.content || "",
                        }
                    }
                },
                schedule : {
                    "정기_주주총회": data?.schedule?.정기_주주총회 || "",
                    "이사회": data?.schedule?.이사회 || false,
                    "법인세": data?.schedule?.법인세 || ""
                },
                address : {
                    headOffice : data?.address?.headOffice || "",
                    branchOffices : data?.address.branchOffices ? data?.address.branchOffices.map(branchOffice => branchOffice || "") : []
                },
                notice : data?.notice ? data?.notice.map((notice) => {
                    return {
                        type : notice?.type || "",
                        content : notice?.content || ""
                    }
                }) : [],
                businessPurposes : data?.businessPurposes ? data?.businessPurposes.map(businessPurposes => businessPurposes || "") : []
            }
        })
    }
    async fileDownload(downloadLink : string) {
        await downloadFileGet(downloadLink);
    }
}
export default new DashboardService();