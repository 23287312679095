import { useObserver } from 'mobx-react';
import vm from './NoticeVm';
import st from './Notice.module.scss';

function Notice() {
    return useObserver(() => (
        <div className={st.wrap}>
            <div className={st.title}>공고방법</div>
            <button type='button' className={st.openModalBtn} onClick={vm.agendaLinkModal}>변경하기</button>
            {vm.notice && vm.notice.length !== 0 ? 
                <>
                    <div className={st.noticeContent}>
                        {vm.notice.map((notice, index) => (
                            <div className={st.notice} style={{height:"76px"}} key={index}>
                                <div className={st.title}>{index+1}순위</div>
                                <div className={st.content}>
                                    <span className={st.text}><span style={{fontWeight:"400"}}>{notice.content}</span>에 공고</span>
                                </div>
                            </div>
                        ))}
                    </div>
                    <span className={st.info}>
                        * <a href='https://standby.kr/blog/article/%EC%A4%91%EC%9A%94%ED%95%9C%EB%B3%80%EA%B2%BD%EA%B3%B5%EA%B3%A0/' target="_blank">중요변경</a>이 있는 경우에 공고해요.
                    </span>
                </>
                :
                <div className={st.empty}>회사 등록을 마친 후,<br/>확인 가능해요!<br/>(최대 1영업일)</div>
            }
        </div>
    ));
}
export default Notice;