import { useObserver } from 'mobx-react';
import { useEffect } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import vm from './AgendaDashboardVm';
import st from './AgendaDashboard.module.scss';
import TopWarning from './TopWarning/TopWarning';
import Rnumber from './Rnumber/Rnumber';
import Header from './Header/Header';
import PersonInCharge from './PersonInCharge/PersonInCharge';
import Agendas from './Agendas/Agendas';
import Decision from './Decision/Decision';
import Registration from './Registration/Registration';
import BoardOfDirectors from './BoardOfDirectors/BoardOfDirectors';
import Capital from './Capital/Capital';
import ShareholderList from './ShareholderList/ShareholderList';
import Aoa from './Aoa/Aoa';
import Memo from './Memo/Memo';
import Schedule from './Schedule/Schedule';
import Customer from './Customer/Customer';

function AgendaDashboard() {
    let location = useLocation();
    const rNumber = queryString.parse(location.search).rNumber as string;
    const cssn = queryString.parse(location.search).cssn as string;

    useEffect(() => {
        vm.load(rNumber, cssn);
    }, [])
    return useObserver(() => (
        vm.accessRoute ?
        <div className={st.agendaDashboard}>
            <Header/>
            {vm.agendaProgress.company.capital !== 0 && 
                <div className={st.agendaBody}>
                    <TopWarning/>
                    <div className={st.horizontal}>
                        <Rnumber/>
                        <PersonInCharge/>
                    </div>
                    <div className={st.horizontal}>
                        <Agendas/>
                    </div>
                    <div className={st.horizontal}>
                        <Decision/>
                        <Customer/>
                    </div>
                    <div className={st.horizontal}>
                        <Registration/>
                    </div>
                    <div className={st.horizontal}>
                        <BoardOfDirectors/>
                        <Capital/>
                    </div>
                    <div className={st.horizontal}>
                        <ShareholderList/>
                        <Aoa/>
                    </div>
                    <div className={st.horizontal}>
                        <Memo/>
                    </div>
                    <div className={st.horizontal}>
                        <Schedule/>
                    </div>
                </div>
            }
        </div>
        : <></>
    ));
}
export default AgendaDashboard;