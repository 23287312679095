
import documentModel from "@model/Corp/Agenda/Step/FinalCheck/BeforeFinal/documentModel";
import documentService from "@service/Corp/Agenda/Step/FinalCheck/BeforeFinal/documentService";
import portalModel from "@model/portalModel";
import { runInAction } from "mobx";
import { _alert } from "@model/dialogModel";
import AgreeStatePopup from "@pages/Corp/Popup/AgreeStatePopup/AgreeStatePopup";
import formDataToJson from '@common/module/submit';

import type { LoadDocumentParam } from "@service/Corp/Agenda/Step/FinalCheck/BeforeFinal/documentService.interface";

class DocumentVm {
    load(agendaProgressId: string, page?: string) {
        const param = {
            documentType: "온라인_수신동의서"
        } as LoadDocumentParam;
        runInAction(() => {
            portalModel.title = '1. 자동 작성된 주주총회 온라인 소집통지 수신동의서를 확인하세요.';
            if(page === "stock") {
                documentService.loadStockDocument(); 
            } else {
                documentService.loadDocument(agendaProgressId, param); 
            }            
        })
    }
    submit(e: React.FormEvent<HTMLFormElement>, agendaProgressId: string, page?: string) {
        e.preventDefault();
        if(formDataToJson(e.target).error) {
            return
        } else {
            let param_formData = new FormData(e.target as HTMLFormElement);

            runInAction(() => {
                portalModel.title = '2. 발송할 주주를 선택하세요.';
                portalModel.content = <AgreeStatePopup agendaProgressId={agendaProgressId} documentType="온라인_수신동의서" statusType="수신동의" page={page} formData={param_formData}/>
            })
        }
    }
    get previewLink() {
        return documentModel.previewLink;
    }

}

export default new DocumentVm();