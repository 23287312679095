import agendaModel from '@model/Corp/Agenda/agendaModel';
import agendaStepModel from '@model/Corp/Agenda/Step/agendaStepModel';
import corpModel from '@model/Corp/corpModel';
import agendaService from '@service/Corp/Agenda/agendaService';
import formDataToJson from '@common/module/submit';
import { observable, runInAction } from "mobx";
import { _alert, _confirm } from "@model/dialogModel";

class AgendaVm {
    state : {
        opened : number | null
    }
    constructor() {
        this.state = observable({
            opened: null
        })
    }
    async load(agendaProgressId : string | null = null) {
        await agendaService.loadAgendaStep(agendaProgressId);
        this.openFirst();
    }
    get isAllSaved() {
        return !this.selectedAgendas.some((selectedAgenda) => {
            return selectedAgenda.savedYn === false
        })
    }
    disabledAgenda(type : string) {
        if(type === "스톡옵션_부여" || type === "스톡옵션_취소" || type === "스톡옵션_행사") {
            return true
        }
        return false;
    }
    openFirst() {
        let isSelected = false;
        this.selectedAgendas.forEach((selectedAgenda, index) => {
            if((selectedAgenda.savedDataYn === false || selectedAgenda.savedYn === false) && isSelected === false) {
                this.state.opened = index;
                isSelected = true;
            }
        })
        if(isSelected === false) {
            this.state.opened = null;
        }
    }
    get hasBoardOfDirectors() {
        return agendaModel.hasBoardOfDirectors;
    }
    get agenda() {
        return agendaModel.agenda;
    }
    get selectedAgendas() {
        return agendaStepModel.selectedAgendas;
    }
    get companyName() {
        return corpModel.company.companyName;
    }
    async agendaTypeSelect(e : React.ChangeEvent<HTMLInputElement>, type : string, index : number) {
        let has신주발행_무상증자 = false;
        let has신주발행_유상증자 = false;
        let has액면금분할_합병 = false;
        let has재무제표_승인 = false;
        for(let key in this.agenda) {
            this.agenda[key].forEach((agenda) => {
                if(agenda.type === "신주발행_무상증자" && agenda.selectedYn) {
                    has신주발행_무상증자 = true;
                }
                if(agenda.type === "신주발행_유상증자" && agenda.selectedYn) {
                    has신주발행_유상증자 = true;
                }
                if(agenda.type === "액면금분할_합병" && agenda.selectedYn) {
                    has액면금분할_합병 = true;
                }
                if(agenda.type === "재무제표_승인" && agenda.selectedYn) {
                    has재무제표_승인 = true;
                }
            })
        }
        if(this.agenda) {
            if(this.agenda[type][index].type === "신주발행_무상증자" && has액면금분할_합병) {
                return _alert("무상증자와 액면금 분할 합병은 함께 진행하기 어려워요. 별도로 진행해주세요.");
            }
            if(this.agenda[type][index].type === "액면금분할_합병" && has신주발행_무상증자) {
                return _alert("무상증자와 액면금 분할 합병은 함께 진행하기 어려워요. 별도로 진행해주세요.");
            }

            if(this.agenda[type][index].type === "신주발행_유상증자" && has액면금분할_합병) {
                return _alert("유상증자와 액면금 분할 합병은 함께 진행하기 어려워요. 별도로 진행해주세요.");
            }
            if(this.agenda[type][index].type === "액면금분할_합병" && has신주발행_유상증자) {
                return _alert("유상증자와 액면금 분할 합병은 함께 진행하기 어려워요. 별도로 진행해주세요.");
            }

            if(this.agenda[type][index].type === "신주발행_무상증자" && has신주발행_유상증자) {
                return _alert("유상증자와 무상증자는 함께 진행하기 어려워요. 별도로 진행해주세요.");
            }
            if(this.agenda[type][index].type === "신주발행_유상증자" && has신주발행_무상증자) {
                return _alert("유상증자와 무상증자는 함께 진행하기 어려워요. 별도로 진행해주세요.");
            }
            
            if(this.agenda[type][index].type === "신주발행_무상증자" && has재무제표_승인) {
                return _alert("재무제표 승인과 무상증자는 함께 진행하기 어려워요. 별도로 진행해주세요.");
            }
            if(this.agenda[type][index].type === "재무제표_승인" && has신주발행_무상증자) {
                return _alert("재무제표 승인과 무상증자는 함께 진행하기 어려워요. 별도로 진행해주세요.");
            }

            if(this.agenda[type][index].type === "주주총회_안건_자유입력" || this.agenda[type][index].type === "이사회_안건_자유입력") {
                if(e.target.checked) {
                    if(this.agendaTypeCount(this.agenda[type][index].type) === 0) {
                        this.setPlusBtn(this.agenda[type][index].type)
                    }
                } else {
                    const findIndex = agendaModel.agendaCounts.findIndex(item => item.agendaType === this.agenda?.[type][index].type);
                    agendaModel.agendaCounts[findIndex].count = 0;
                }
            }

            this.agenda[type][index].selectedYn = e.target.checked;
        }
    }
    get selectedAgendaCount() {
        let count = 0;
        for(let key in this.agenda) {
            this.agenda[key].forEach((agenda) => {
                if(agenda.selectedYn) {
                    count+=1;
                }
            })
        }
        return count;
    }
    async submitSelectAgenda(e : React.FormEvent<HTMLFormElement>, agendaProgressId : string | null = null) {
        e.preventDefault();
        if(formDataToJson(e.target).error) {
            return
        } else {
            interface Param {
                selectedAgendaTypes : string | string[] | {type: string; count: number}[],
                agendaProgressId : string | null
            }
            let param : Param = formDataToJson(e.target).data as Param;
            if(param.selectedAgendaTypes && !Array.isArray(param.selectedAgendaTypes)) {
                param.selectedAgendaTypes = [param.selectedAgendaTypes];
            }
            if(agendaProgressId) {
                param.agendaProgressId = agendaProgressId;
            }
            if(Array.isArray(param.selectedAgendaTypes)) {
                param.selectedAgendaTypes = param.selectedAgendaTypes.map((type) => {
                    let _count = 1;
                    if(type === "주주총회_안건_자유입력" || type === "이사회_안건_자유입력") {
                        _count = Number(this.agendaTypeCount(type));
                    }
                    if (typeof type === 'string') {
                        return {
                            type: type,
                            count: _count
                        }
                    }
                    return type;
                })
            }   

            const menuState = corpModel.menu;
            if(agendaProgressId === null && (menuState?.articlesOfAssociationTagName === "등록중" || menuState?.copyFileTagName === "등록중" || menuState?.stockholderTagName === "등록중")) {
                await _alert('앗! 법인등록 끝난 이후에 기능을 쓸 수 있어요.', '등록을 마친 후 카톡으로 알림을 드릴게요. (최대 1영업일)');
            } else {
                if(!param.selectedAgendaTypes) {
                    return _alert('안건을 선택해주세요');
                }
                this.state.opened = null;
                await agendaService.selectAgenda(param);
                this.openFirst();
            }
        }
    }
    changeOpened(index : number) {
        if(this.state.opened === null || this.selectedAgendas[this.state.opened].savedDataYn === false) {
            this.selectedAgendas[index].savedYn = !this.selectedAgendas[index].savedYn;
            this.state.opened = index;
        }else{
            _alert('앗! 현재 수정 중인 안건에서 저장버튼을 먼저 눌러주세요.', '안건 2개를 동시에 수정할 수 없어요. ');
        }
    }
    async nextStep(agendaProgressId : string | null) {
        if(agendaProgressId){
            await agendaService.nextStep(agendaProgressId);
        }
    }

    get registrationRequiredYn() {
        return agendaModel.registrationRequiredYn;
    }
    get capital() {
        return agendaModel.capital;
    }
    get totalNumOf_감사() {
        return agendaModel.totalNumOf_감사;
    }
    get totalNumOf_이사() {
        return agendaModel.totalNumOf_이사;
    }
    highlightText(type : string) {
        return agendaModel.highlights.find((highlight) => (
            highlight.type === type
        ))?.text
    }
    get agendaCounts() {
        return agendaModel.agendaCounts;
    }
    agendaTypeCount(type: string) {
        return agendaModel.agendaCounts.find(item => item.agendaType === type)?.count; 
    }
    setMinusBtn(type: string) {
        runInAction(() => {
            const count = Number(agendaModel.agendaCounts.find(item => item.agendaType === type)?.count);
            const findIndex = agendaModel.agendaCounts.findIndex(item => item.agendaType === type);
            if(count > 1) {
                agendaModel.agendaCounts[findIndex].count --;
            }
        })
    }
    setPlusBtn(type: string) {
        runInAction(() => {
            const count = Number(agendaModel.agendaCounts.find(item => item.agendaType === type)?.count);
            const findIndex = agendaModel.agendaCounts.findIndex(item => item.agendaType === type);
            if(count < 5) {
                agendaModel.agendaCounts[findIndex].count ++;
            }
        })    
    }
    title(type: string, title: string) {
        if(type === "임원_무보수_결의") {
            return `임원 무보수 결의(건강보험 제출\n용)`;
        } else {
            return title
        }
    }
}
export default new AgendaVm();