import formDataToJson from '@common/module/submit';
import bookKeepingContractModel from '@model/Corp/BookKeepingContract/bookKeepingContractModel';
import bookKeepingContractService from '@service/Corp/BookKeepingContract/bookKeepingContractService';
import { observable, runInAction, toJS } from 'mobx';

class BookKeepingContractVm {
    constructor() {
        this.state = observable({
            loadYn: false
        })
    }
    async load() {
        await bookKeepingContractService.loadBookkeepingContract();
        this.state.loadYn = true;
    }
    get pageType() {
        return bookKeepingContractModel.pageType;
    }
}

export default new BookKeepingContractVm();