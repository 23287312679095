import awsCreditModel from '@model/Corp/AwsCredit/awsCreditModel';
import { runInAction } from 'mobx';
import { get, post, downloadFileGet } from '@common/module/httpRequest';
import {LoadStep2Rs, loadTermRs, LoadStepRs, SaveStep2Rq, LoadStep3Rs} from './awsCreditService.interface';
import corpModel from '@model/Corp/corpModel';

class awsCreditService {
    async loadTerm() {
        const res = await get<loadTermRs>(`/term/AWS_CREDIT_APPLICATION`);
        runInAction(() => {
            awsCreditModel.step2.termInfos = res.data.userRegistrationTerms.map((userRegistrationTerms) => {
                return {
                    id : userRegistrationTerms.id,
                    title : userRegistrationTerms?.title || '',
                    content : userRegistrationTerms?.content || '',
                    required : userRegistrationTerms?.required ?? false,
                    agreeYn : false
                }
            });
        })
    }
    async loadStep2() {
        const res = await get<LoadStep2Rs>(`/company/${corpModel.id}/aws/credit/step2`);
        runInAction(() => {
            awsCreditModel.step2.name = res.data.name || "";
            awsCreditModel.step2.email = res.data.email || "";
            awsCreditModel.step2.phoneNumber = res.data.phoneNumber || "";
            awsCreditModel.step2.responsibility = res.data.responsibility || ""
        })
    }
    async loadStep() {
        const res = await get<LoadStepRs>(`/company/${corpModel.id}/aws/credit/step`);
        return res.data.step;
    }
    async saveStep2(param : SaveStep2Rq) {
        await post(`/company/${corpModel.id}/aws/credit`, param);
        window.location.href = `/corp/awsCredit/result?corp=${corpModel.id}`;
    }
    async loadStep3() {
        const res = await get<LoadStep3Rs>(`/company/${corpModel.id}/aws/credit/step3`);
        runInAction(() => {
            awsCreditModel.step3.status = res.data.status || null
        })
    }
    async consult() {
        await post(`/company/${corpModel.id}/aws/credit/consult`);
    }
    fileDown(type : string) {
        downloadFileGet(`/company/${corpModel.id}/aws/credit/file/${type}`);
    }
}

export default new awsCreditService();