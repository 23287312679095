import { useObserver } from 'mobx-react';
import React from 'react';
import st from '../../Meetings/Meetings.module.scss';
import { I소집통지_우편 } from '@model/Corp/Agenda/Step/FinalCheck/Final/FinalModel.interface';
import clsx from 'clsx';
import File from '../File/File';

interface C소집통지_우편Props {
    meetingSchedule : I소집통지_우편,
    meetingScheduleIndex : number,
    opener : 'final' | 'agendaDashboard'
}

const C소집통지_우편 = React.memo(function (props : C소집통지_우편Props) {
    return useObserver(() => (
        <div className={st.meeting}>
            <div className={st.sideTitle}>{props.meetingScheduleIndex + 1}. {props.meetingSchedule.title}</div>
            <div className={st.meetingInfo}>
                <div className={st.detailTitle}>{props.meetingSchedule.detailTitle}</div>
                <ul className={st.detailList}>
                    <li>
                        <div className={clsx(st.title, st.notificationMethod)}>방식</div>
                        <div className={st.content}>우편 소집통지</div>
                    </li>
                    <li>
                        <div className={clsx(st.title, st.notice)}>통지문</div>
                        <div className={st.content}>
                            <File file={props.meetingSchedule.files.소집통지서} opener={props.opener} id={props.meetingSchedule.id} type='소집통지서'/>
                        </div>
                    </li>
                    <li>
                        <div className={clsx(st.title, st.receipt)}>우편발송 영수증</div>
                        <div className={st.content}>
                            <File file={props.meetingSchedule.files.우편발송_영수증} opener={props.opener} id={props.meetingSchedule.id} type='우편발송_영수증'/>
                        </div>
                    </li>
                    <li>
                        <div className={clsx(st.title, st.date)}>일시</div>
                        <div className={st.content}>{props.meetingSchedule.decisionAt}</div>
                    </li>
                </ul>
            </div>
        </div>
    ));
})

export default C소집통지_우편;