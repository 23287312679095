import DashboardModel from '@model/Corp/Dashboard/DashboardModel';
import DashboardService from '@service/Corp/Dashboard/DashboardService';

class DashboardVm {
    load() {
        DashboardService.load();
    }
    get dashboardData() {
        return DashboardModel.dashboardData;
    }
    fileDownload(downloadLink:string) {
        DashboardService.fileDownload(downloadLink);
    }
}
export default new DashboardVm();