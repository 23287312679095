import type { BesinessRegistrationEmailSignModalModelInterface, FileExtensionType } from './BesinessRegistrationEmailSignModalModel.interface';
import { observable, makeObservable } from 'mobx';

class BesinessRegistrationEmailSignModalModel implements BesinessRegistrationEmailSignModalModelInterface {
    constructor() {
        makeObservable(this, {
            email : observable,
            fileExtensionType : observable,
            content : observable
        })
    }
    email : string = '';
    fileExtensionType : FileExtensionType = 'JPG';
    content : string = '';
}

export default new BesinessRegistrationEmailSignModalModel();