import { observable } from 'mobx';

const userModel = observable({
    user : {
        email : "",
        name : null,
        id : null,
        channelMemberId : null
    },
    date: "",
    setUser(data) {
        this.user.email = data?.email || "";
        this.user.name = data?.name || null;
        this.user.id = data?.id || null;
        this.user.channelMemberId = data?.channelMemberId || null;
    },
    removeUser() {
        this.user.email = null;
        this.user.name = null;
        this.user.id = null;
        this.user.channelMemberId = null;
    },
    setNowDate(data) {
        this.date = data;
    }
});

export default userModel;