import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { useEffect } from 'react';
import vm from './OnGoingPopupVm';
import st from './OnGoingPopup.module.scss';

function OnGoingPopup(props) {
    return useObserver(() => (
        <div className={st.onGoingPopup}>
            <div className={st.title}>{props.title}</div>
            <div className={st.content}>{props.content}</div>
            <button type="button" className={st.checkBtn} onClick={() => vm.check()}>확인</button>
        </div>
    ));
}
export default OnGoingPopup;