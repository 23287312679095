import { runInAction, observable, toJS } from "mobx";
import authService from '@service/authService'
import setupNewPasswordModel from '@model/Auth/setupNewPasswordModel';
import formDataToJson from '@common/module/submit';
import regExp from '@common/module/regExp';

class viewModel {
    submit(e, token) {
        e.preventDefault();
        if(formDataToJson(e.target).error) {
            return
        } else {
            let param = formDataToJson(e.target).data;
            if(param.passwordCheck) { //비밀번호 입력확인 데이터 삭제
                delete param.passwordCheck;
            }
            authService.setupNewPassword(param, token);
        }
    }
    get password() {
        return setupNewPasswordModel.password;
    }
    setPassword(e) {
        runInAction(() => {
            setupNewPasswordModel.password = regExp.deleteSpace(e.target.value);
        })
    }
    get passwordCheck() {
        return setupNewPasswordModel.passwordCheck;
    }
    setPasswordCheck(e) {
        runInAction(() => {
            setupNewPasswordModel.passwordCheck = regExp.deleteSpace(e.target.value);
        })
    }
    isSame() {
        if(this.password === this.passwordCheck) {
            return true;
        } else {
            return false;
        }
    }
}

export default new viewModel();