import { observable, makeObservable } from 'mobx';
import type { beforeFinalInterface, MeetingSchedule, SectionList, SubmitValidation, ChangedSchedule, NewStockAcquirerInfo } from './beforeFinalModel.interface';

class beforeFinalModel implements beforeFinalInterface {
    constructor() {
        makeObservable(this, {
            title: observable,
            registrationAgencyType: observable,
            meetingSchedule: observable,
            sectionList: observable,
            submitValidation: observable,
            changedSchedule: observable,
            isRegular: observable,
            showAgendaPopUpButton : observable
        })
    }
    title: string = "";
    registrationAgencyType: "SELF" | "REGISTRATION_AGENCY" | "ALREADY_PROGRESSED_REGISTRATION_AGENCY" | "NONE" | null = null;
    meetingSchedule: MeetingSchedule[] = [];
    sectionList: SectionList[] = [];
    submitValidation: SubmitValidation = {
        validated: false,
        unValidatedList: [],
        newSharesIncludeYn: false
    };
    changedSchedule: ChangedSchedule = {
        changedSchedules: [],
        validated: false,
    };
    newStockAcquirerInfo?: NewStockAcquirerInfo;
    isRegular: boolean = false;
    showAgendaPopUpButton : boolean = false;
    representativeAddressChangeIsHide: boolean = false;
    
}

export default new beforeFinalModel();