import { runInAction, observable, toJS } from "mobx";
import invitedModel from '@model/Auth/invitedModel';
import authService from '@service/authService';
import formDataToJson from '@common/module/submit';
import joinMembershipModel from '@model/Auth/joinMembershipModel';
import TermsModal from '@pages/Auth/TermsModal/TermsModal';
import portalModel from '@model/portalModel';
import regExp from '@common/module/regExp';
import { onlyNumber } from '@common/module/replaceNumber';

class InvitedVm {
    constructor() {
        this.state = observable({
            errorCheckYn: false
        })
    }
    load(token) {
        authService.loadInvitedAuthorizzation(token);
        authService.loadTerms('NORMAL_REGISTER');
    }
    get email() {
        return invitedModel.email;
    }
    get companyName() {
        return invitedModel.companyName;
    }
    get password() {
        return invitedModel.password;
    }
    setPassword(e) {
        runInAction(() => {
            invitedModel.password = regExp.deleteSpace(e.target.value);
        })
    }
    get passwordCheck() {
        return invitedModel.passwordCheck;
    }
    setPasswordCheck(e) {
        runInAction(() => {
            invitedModel.passwordCheck = regExp.deleteSpace(e.target.value);
        })
    }
    get name() {
        return invitedModel.name;
    }
    setName(e) {
        runInAction(() => {
            invitedModel.name = e.target.value;
        })
    }
    get countryCode() {
        return invitedModel.countryCode;
    }
    get phoneNumber() {
        return invitedModel.phoneNumber;
    }
    setPhoneNumber(e) {
        runInAction(() => {
            invitedModel.phoneNumber = regExp.deleteSpace(onlyNumber(e.target.value));
        })
    }
    isSame() {
        if(this.password === this.passwordCheck) {
            return true;
        } else {
            return false;
        }
    }
    get agreeAll() {
        let isCheckAll = true;
        let agreeCnt = 0;
        joinMembershipModel.submittedTermRequests.forEach((item, idx) => {
            if(item.agreeYn) {
                agreeCnt++;
            } else if(!item.agreeYn) {
                isCheckAll = false;
            }
        })
        if(agreeCnt === 0){
            return false;
        }
        return isCheckAll;
    }
    setAgreeAll(e) {
        const value = e.target.checked;
        runInAction(() => {
            joinMembershipModel.submittedTermRequests.forEach((item) => {
                item.agreeYn = value;
            })
        })
    }
    setAgreeYn(e, idx) {
        runInAction(() => {
            if(joinMembershipModel.submittedTermRequests[idx].agreeYn) {
                joinMembershipModel.submittedTermRequests[idx].agreeYn = false;
            } else {
                joinMembershipModel.submittedTermRequests[idx].agreeYn = true;
            }
        })
    }
    agreeYn(idx) {
        return joinMembershipModel.submittedTermRequests[idx].agreeYn;
    }
    get userRegistrationTerms() {
        return joinMembershipModel.userRegistrationTerms;
    }
    get submittedTermRequests() {
        return joinMembershipModel.submittedTermRequests;
    }
    termsPopup(title, content) {
        runInAction(() => {
            portalModel.title = title;
            portalModel.content = <TermsModal content={content}/>;
        })
    }
    get checkBoxError() {
        let error = false;
        let requiredCheckCnt = 0;
        joinMembershipModel.submittedTermRequests.forEach((item, idx) => {
            if(item.required && item.agreeYn === false) {
                error = true;
            }
        })
        return error;
    }
    async submit(e, token) {
        e.preventDefault();
        if(formDataToJson(e.target).error  || this.checkBoxError) {
            this.state.errorCheckYn = true;
            return
        } else {
            let param = formDataToJson(e.target).data;
            const deleteDash = /-/gi;
            param.phoneNumber = param.phoneNumber.replace(deleteDash, "");
            if(param.phoneNumber.charAt(0) === "0") {
                param.phoneNumber = param.phoneNumber.slice(1);
            }
            if(param.passwordCheck) { //비밀번호 입력확인 데이터 삭제
                delete param.passwordCheck;
            }
            delete param.text;
            authService.invitedRegistration(param, token);
        }
    }
}
export default new InvitedVm();