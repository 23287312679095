import { useObserver } from 'mobx-react';
import st from './Step1.module.scss';
import Timer from './Timer/Timer';
import vm from './Step1Vm';
import clsx from 'clsx';

interface Step1Props {
    preview ?: boolean,
    guest ?: boolean
}

function Step1({preview = false, guest = false} : Step1Props) {
    return useObserver(() => (
        <>  
            {preview === false && 
                <div className={st.top}>
                    <span className={st.title}>클라우드 지원금 270만 원</span>
                    <button type="button" className={st.moveStep2} onClick={() => vm.moveStep2(guest)}>클라우드 지원금 신청하기</button>
                </div>
            }
            <div className={clsx(st.promotion, preview === true ? st.preview : null)}>
                {preview === false ?
                    <>
                        <div className={st.timer}>
                            <Timer/>
                        </div>
                        <img src='/images/awsCreditPromotion.png?3'/>
                    </>
                    :
                    <img src='/images/awsCreditPromotion_preview.png?3'/>
                }
                <div className={st.faq}>
                    <div className={st.faqTop}>
                        <div>고민 되시나요?<br/>지원금 270만 원은 오직 스탠바이만 가능해요.</div>
                        <div className={st.sub}>다른 곳에서도 Activate 파트너나 AWS Lift를 통해 크레딧을 받으실 수 있어요.<br/><em>다만 클라우드 지원금 270만 원과 Activate, Lift가 모두 가능한 곳은 스탠바이 뿐이에요.</em></div>
                    </div>
                    <div className={st.content}>
                        <div className={st.title}>자주 묻는 질문</div>
                        <ul>
                            {vm.state.tab.map((tab, index) => (
                                <li key={index} className={tab.opened ? st.opened : st.closed} onClick={() => vm.toggleTab(index)}>
                                    <div className={st.title}>{tab.title}</div>
                                    {tab.opened === true && 
                                        <div className={st.content} dangerouslySetInnerHTML={ {__html: tab.content} }></div>
                                    }
                                </li>                                
                            ))}
                        </ul>
                    </div>
                </div>
                {preview === false &&
                    <button type="button" className={st.moveStep2} onClick={() => vm.moveStep2(guest)}>
                        <span className={st.btnContent}>클라우드 지원금 신청하기</span>
                    </button>
                }
            </div>
        </>
    ));
}
export default Step1;