import { get, downloadFileGet } from '@common/module/httpRequest';
import { runInAction } from 'mobx'; 
import AgendaViewModel from '@model/Corp/Agenda/Step/FinalCheck/BeforeFinal/AgendaView/AgendaViewModel';
import {
    LoadRs, Agenda, IA정관변경, IA임원_취임_중임_사임_퇴임, IA재무제표_승인, IA임원_보수_한도_승인,
    IA임원_보수_결정, IA본점의_주소_변경, IA신주발행_유상증자, IA신주발행_무상증자, IA전환사채_발행, IACommonAgenda, ExecutiveModalMembers, ExecutiveModalMember, IA액면금분할_합병, IA폼_없음,
    IA대표이사_주소_변경, AgendaType, AgendaInfo
} from './AgendaViewService.interface';
import corpModel from '@model/Corp/corpModel';

class AgendaViewService {

    agendaInfo(agendaType : AgendaType, agendaInfo : AgendaInfo) {
        switch(agendaType){
            case "정관변경":
                {
                    const _agendaInfo = agendaInfo as IA정관변경;
                    return {
                        autoAddArticlesOfAssociations : _agendaInfo.autoAddArticlesOfAssociations.map((autoAddArticlesOfAssociation) => {
                            return {
                                origin : autoAddArticlesOfAssociation.origin,
                                latest : {
                                    category : autoAddArticlesOfAssociation?.latest?.category || "",
                                    content : autoAddArticlesOfAssociation?.latest?.content || "",
                                    description : autoAddArticlesOfAssociation?.latest?.description || "",
                                    issueLimitYn : autoAddArticlesOfAssociation?.latest?.issueLimitYn || false,
                                    보통주_issueLimit : autoAddArticlesOfAssociation?.latest?.보통주_issueLimit ?? null,
                                    우선주_issueLimit : autoAddArticlesOfAssociation?.latest?.우선주_issueLimit ?? null,
                                }
                            }
                        }),
                        directAddArticlesOfAssociations : _agendaInfo.directAddArticlesOfAssociations.map((directAddArticlesOfAssociation) => {
                            return {
                                regulationLocation : directAddArticlesOfAssociation?.regulationLocation || 0,
                                content : directAddArticlesOfAssociation?.content || ""
                            }
                        })
                    } as IA정관변경
                    break;
                }
            case "임원_취임_중임_사임_퇴임":
                {
                    const _agendaInfo = agendaInfo as IA임원_취임_중임_사임_퇴임;
                    return {
                        title : _agendaInfo?.title || "",
                        changeExecutive : _agendaInfo?.changeExecutive || "",
                        originExecutiveCount : _agendaInfo?.originExecutiveCount || 0,
                        originDirectorCount : _agendaInfo?.originDirectorCount || 0,
                        updateDirectorCount : _agendaInfo?.updateDirectorCount || 0,
                        updateExecutiveCount : _agendaInfo?.updateExecutiveCount || 0,
                        executives : _agendaInfo.executives.map((executive) => {
                            if('executiveMembers' in executive) {
                                const _executive = executive as ExecutiveModalMembers;
                                return {
                                    executiveMembers : _executive.executiveMembers.map((executiveMember) => {
                                        return {
                                            changeType : executiveMember?.changeType,
                                            originExecutive : {
                                                name : executiveMember?.originExecutive?.name || "",
                                                position : executiveMember?.originExecutive?.position || ""
                                            },
                                            updateExecutive : {
                                                name : executiveMember?.updateExecutive?.name || "",
                                                position : executiveMember?.updateExecutive?.position || ""
                                            }
                                        }
                                    })
                                } as ExecutiveModalMembers
            
                            }else{
                                const _executive = executive as ExecutiveModalMember;
                                return {
                                    changeType : _executive?.changeType,
                                    originExecutive : {
                                        name : _executive?.originExecutive?.name || "",
                                        position : _executive?.originExecutive?.position || ""
                                    },
                                    updateExecutive : {
                                        name : _executive?.updateExecutive?.name || "",
                                        position : _executive?.updateExecutive?.position || ""
                                    }
                                } as ExecutiveModalMember
                            }
                        })
                    } as IA임원_취임_중임_사임_퇴임
                    break;
                }
            case "재무제표_승인":
                {
                    const _agendaInfo = agendaInfo as IA재무제표_승인;
                    return {
                        fiscalYear : _agendaInfo?.fiscalYear || "",
                        downloadUrl : _agendaInfo?.downloadUrl || "",
                        downloadTitle : _agendaInfo?.downloadTitle || ""
                    } as IA재무제표_승인
                    break;
                }
            case "임원_보수_한도_승인":
                {
                    const _agendaInfo = agendaInfo as IA임원_보수_한도_승인;
                    return {
                        approvalStartAt : {
                            year : _agendaInfo?.approvalStartAt?.year || "",
                            month : _agendaInfo?.approvalStartAt?.month || "",
                            day : _agendaInfo?.approvalStartAt?.day || ""
                        },
                        approvalEndAt : {
                            year : _agendaInfo?.approvalEndAt?.year || "",
                            month : _agendaInfo?.approvalEndAt?.month || "",
                            day : _agendaInfo?.approvalEndAt?.day || ""
                        },
                        approvalType : _agendaInfo?.approvalType || "",
                        이사SalaryLimit : _agendaInfo?.이사SalaryLimit || 0,
                        감사SalaryLimit : _agendaInfo?.감사SalaryLimit || 0,
                        salaryLimitOfExecutives : _agendaInfo?.salaryLimitOfExecutives.map((salaryLimitOfExecutive) => {
                            return {
                                name : salaryLimitOfExecutive?.name || "",
                                position : salaryLimitOfExecutive?.position || "",
                                salary : salaryLimitOfExecutive?.salary || "",
                                newExecutiveYn: salaryLimitOfExecutive?.newExecutiveYn ?? null
                            }
                        })
                    } as IA임원_보수_한도_승인     
                    break;
                }
            case "임원_보수_결정":
                {
                    const _agendaInfo = agendaInfo as IA임원_보수_결정;
                    return {
                        decisionStartAt : {
                            year : _agendaInfo?.decisionStartAt?.year || "",
                            month : _agendaInfo?.decisionStartAt?.month || "",
                            day : _agendaInfo?.decisionStartAt?.day || ""
                        },
                        decisionEndAt : {
                            year : _agendaInfo?.decisionEndAt?.year || "",
                            month : _agendaInfo?.decisionEndAt?.month || "",
                            day : _agendaInfo?.decisionEndAt?.day || ""
                        },
                        salaryOfExecutives : _agendaInfo?.salaryOfExecutives.map((salaryOfExecutive) => {
                            return {
                                name : salaryOfExecutive?.name || "",
                                salary : salaryOfExecutive?.salary || "",
                                position : salaryOfExecutive?.position || "",
                                newExecutiveYn: salaryOfExecutive?.newExecutiveYn ?? false
                            }
                        })
                    } as IA임원_보수_결정       
                    break;
                }
            case "본점의_주소_변경":
                {
                    const _agendaInfo = agendaInfo as IA본점의_주소_변경;
                    return {
                        jurisdictionYn : _agendaInfo?.jurisdictionYn || false,
                        selectedMetropolitanType : _agendaInfo?.selectedMetropolitanType || "",
                        selectedSigungu : _agendaInfo?.selectedSigungu || ""
                    } as IA본점의_주소_변경
                    break;
                }
            case "신주발행_유상증자":
                {
                    const _agendaInfo = agendaInfo as IA신주발행_유상증자;
                    return {
                        assignmentType : _agendaInfo?.assignmentType || "",
                        noticeType : _agendaInfo?.noticeType || "",
                        newSharesAcquirers : _agendaInfo?.newSharesAcquirers.map((newSharesAcquirer) => {
                            return {
                                name : newSharesAcquirer?.name || "",
                                subscriptionAt : newSharesAcquirer?.subscriptionAt || "",
                                paymentAt : newSharesAcquirer?.paymentAt || "",
                                인수자격 : newSharesAcquirer?.인수자격 || "",
                                resourceType : newSharesAcquirer?.resourceType || "",
                                stocks : newSharesAcquirer?.stocks.map((stock) => {
                                    return {
                                        stockName : stock?.stockName || "",
                                        investment : stock?.investment || 0,
                                        number : stock?.number || 0,
                                        issuePrice : stock?.issuePrice || 0
                                    }
                                })
                            }
                        })
                    } as IA신주발행_유상증자
                    break;
                }
            case "신주발행_무상증자":
                {
                    const _agendaInfo = agendaInfo as IA신주발행_무상증자;
                    return {
                        freeIssueOfNewSharesType : _agendaInfo?.freeIssueOfNewSharesType || "",
                        increaseAmount : _agendaInfo?.increaseAmount || 0,
                        numberOfNewStocks : _agendaInfo?.numberOfNewStocks || 0,
                        shareholders : _agendaInfo?.shareholders.map((shareholder) => {
                            return {
                                name : shareholder?.name || "",
                                rate : shareholder?.rate + "%" || "",
                                numberOfStocks : shareholder?.numberOfStocks || 0
                            }
                        })
                    } as IA신주발행_무상증자
                    break;
                }
            case "전환사채_발행":
                {
                    const _agendaInfo = agendaInfo as IA전환사채_발행;
                    return {
                        totalAmount : _agendaInfo?.totalAmount || 0,
                        publicationTarget : _agendaInfo?.publicationTarget || "",
                        issueCount : _agendaInfo?.issueCount || 0
                    } as IA전환사채_발행
                    break;
                }
            case "임원_보수_지급규정_승인":
            case "임원_퇴직금_지급규정_승인":
            case "임원_상여금_지급규정_승인":
            case "차등배당":
            case "중간배당":
            case "부동산_매각":
            case "자금차입":
            case "자금차입_및_담보제공":
            case "자회사_설립":
            case "이사의_자기거래_승인":
            case "주주총회_안건_자유입력":
            case "이사회_안건_자유입력":
            case "임원_무보수_결의":
                {
                    const _agendaInfo = agendaInfo as IACommonAgenda;
                    return {
                        title : _agendaInfo?.title || "",
                        content : _agendaInfo?.content || "",
                        downloadUrl : _agendaInfo?.downloadUrl || "",
                        downloadTitle : _agendaInfo?.downloadTitle || "",
                    } as IACommonAgenda
                    break;
                }
            case "액면금분할_합병":
                {
                    const _agendaInfo = agendaInfo as IA액면금분할_합병;
                    return {
                        beforeParValue : _agendaInfo?.beforeParValue || 0,
                        afterParValue : _agendaInfo?.afterParValue || 0
                    } as IA액면금분할_합병
                    break;
                }
            case "폼_없음":
                {
                    const _agendaInfo = agendaInfo as IA폼_없음;
                    return {
                        title : _agendaInfo?.title || 0
                    } as IA폼_없음
                    break;
                }
            case "대표이사_주소_변경":
                {
                    const _agendaInfo = agendaInfo as IA대표이사_주소_변경;
                    return {
                        representatives : _agendaInfo?.representatives.map((representative) => representative || "")
                    } as IA대표이사_주소_변경
                    break;
                }
        }
    }

    setAgendas(agendas : Agenda[]) {
        AgendaViewModel.agendas = agendas.map((agenda) => {
            return {
                type : agenda.type || "",
                decisionBody : agenda.decisionBody.map(decision => decision || ""),
                info : this.agendaInfo(agenda.type, agenda.info)
            }
        })
    }

    async load(agendaProgressId : string) {
        const res = await get<LoadRs>(`/company/${corpModel.id}/agendaProgress/${agendaProgressId}/beforeFinal/agenda`);
        runInAction(() => {
            AgendaViewModel.shareholdersMeetingType = {
                meetingType : res.data?.shareholdersMeetingType.meetingType || "",
                musterNoticeType : res.data?.shareholdersMeetingType.musterNoticeType || "",
            }
            AgendaViewModel.newStockType = {
                배정_Type : res.data?.newStockType.배정_Type || "",
                발행절차_Type : res.data?.newStockType.발행절차_Type || "",
            }
            this.setAgendas(res.data.agendas);
            AgendaViewModel.stockholderInfos = {
                criteriaDate : res.data?.stockholderInfos?.criteriaDate || "",
                addedShareholder : res.data?.stockholderInfos?.addedShareholder || [] 
            }
        })
    }
    fileDown(url : string) {
        downloadFileGet(url);
    }
}
export default new AgendaViewService();