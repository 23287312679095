import { useObserver } from 'mobx-react';
import { useEffect } from 'react';
import vm from './SendBRAndBankbookVm';
import st from './SendBRAndBankbook.module.scss';
import clsx from 'clsx';
import PageTitle from '@commonComponents/PageTitle/PageTitle';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import RadioLabel from '@standby/common-ui/components/molecules/RadioLabel/RadioLabel';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import ErrorText from '@standby/common-ui/components/atoms/Text/ErrorText/ErrorText';
import regExp from '@common/module/regExp';
import AddBtn from '@standby/common-ui/components/atoms/Button/AddBtn/AddBtn';
import Loading from '@commonComponents/Loading/Loading';

function SendBRAndBankbook() {

    useEffect(() => {
        vm.load();
    }, []);

    return useObserver(() => (
        <>
            {vm.state.loadingYn && <Loading />}
            <PageTitle><span className={st.pageTitle}>사업자등록</span></PageTitle> 
            <ContentBox title="사업자등록증 & 통장사본 보내기" className={st.contentBox} titleStyle={{top:"15px", paddingLeft:"18px", fontSize:"18px"}}>
                <div className={st.floatBtnArea}>
                    <SquareBtn className={st.changeBankbookBtn} color="white" onClick={() => vm.changeBankbook()}>통장사본 변경하기</SquareBtn>
                    <SquareBtn className={st.addBankbookBtn} onClick={() => vm.addBankbook()}>통장사본 추가하기</SquareBtn>
                </div>
                <form className={st.content} onSubmit={(e) => vm.submit(e)}>
                    <div className={st.row}>
                        <div className={st.title}>서류 선택</div>
                        <div className={st.rowContent}>
                            <div className={st.documentBox}>
                                <CheckBox name="businessRegistration" className={st.check} checked={vm.state.businessRegistrationYn} value={String(vm.state.businessRegistrationYn)} onChange={(e) => vm.setBusinessRegistration(e)}>사업자등록증</CheckBox>  
                                {vm.state.businessRegistrationYn && <div className={st.businessRegistrationInfo}>{vm.businessRegistration.companyName} {vm.businessRegistration.businessRegistrationNumber}</div>}           
                            </div>
                            <div className={st.documentBox}>
                                <CheckBox name="bankbook" className={st.check} checked={vm.state.bankbookYn} value={String(vm.state.bankbookYn)} onChange={(e) => vm.setBankbook(e)}>통장사본</CheckBox>
                                {vm.state.bankbookYn && 
                                    <>
                                        {(vm.bankbooks && vm.bankbooks.length !== 0) ? 
                                            <div className={st.bankList}>
                                                {vm.bankbooks.map((bankbook, idx) => {
                                                    return (
                                                        <RadioLabel key={idx} name="bankbookId" value={bankbook.id} checked={vm.state.bankbookId === bankbook.id} onChange={(e) => vm.setBankbookId(e)}>
                                                            <div className={st.bankbooksRadio}>
                                                                <span className={st.bankName}>
                                                                    {bankbook.bankName}<span style={{marginLeft:'4px'}}>{bankbook.accountNumber}</span>
                                                                </span>
                                                                <span className={st.accountUsage}>{bankbook.accountUsage}</span>
                                                            </div>
                                                        </RadioLabel>
                                                    )
                                                })}
                                                <div><Error name="bankbookId" value={String(vm.state.bankbookId)} errorCase={{required: '통장사본을 선택하세요.'}} /></div>
                                            </div>
                                            :
                                            <div className={st.emptyBankList}>앗! 저장된 통장사본이 없네요. 오른쪽 상단 버튼을 눌러 추가하세요.</div>
                                        }
                                    </>
                                    
                                }
                            </div>
                            {vm.state.documentErrorCheckYn && <ErrorText>서류를 선택하세요.</ErrorText>}
                        </div>
                    </div>
                    {(vm.state.businessRegistrationYn || vm.state.bankbookYn) && 
                        <div className={st.row}>
                            <div className={st.title}>세금계산서 발급받을<br/>이메일 <span className={st.explain}>(선택)</span></div>
                            <div className={st.rowContent}>
                                <InputText name="taxInvoiceIssuanceEmail" fullSize value={vm.state.email} onChange={(e) => vm.setUserEmail(e)} />       
                                {(vm.state.email && regExp.email().test(vm.state.email) === false) && <ErrorText>올바른 이메일을 입력해주세요.</ErrorText>}                     
                            </div>
                        </div>
                    }
                    <div className={st.row}>
                        <div className={st.title}>받을 분</div>
                        <div className={st.rowContent}>
                            {vm.state.recipients.map((recipient, idx) => {
                                return (
                                    <table className={clsx(st.table, vm.state.recipients.length-1 === idx ? st.lastTable : null)} key={idx}>
                                        <colgroup>
                                            <col width="116"/>
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <td colSpan={2}>
                                                    받을 분 {idx+1}
                                                    {vm.state.recipients.length > 1 && <button type="button" className={st.removeBtn} onClick={() => vm.removeRecipient(idx)}/>}
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>발송방법 선택</td>
                                                <td className={st.checkBoxTd}>
                                                    <CheckBox className={st.check} checked={vm.allCheck(idx)} onChange={(e) => vm.setAgreeAll(e, idx)}>전체 선택</CheckBox>  
                                                    <CheckBox className={st.check} checked={recipient.emailCheckYn} onChange={(e) => vm.setEmailCheck(e, idx)}>이메일</CheckBox>  
                                                    <CheckBox className={st.check} checked={recipient.phoneNumberYn} onChange={(e) => vm.setPhoneNumberCheck(e, idx)}>휴대폰 번호</CheckBox>  
                                                </td>
                                            </tr>
                                            {(recipient.emailCheckYn || recipient.phoneNumberYn) && 
                                                <tr>
                                                    <td colSpan={2}>
                                                        <InputText name={`recipients[${idx}].name`} fullSize value={recipient.name} explain="이름*" maxLength={20}onChange={(e) => vm.setName(e, idx)} errorText={<Error name={`recipients[${idx}].name`} value={recipient.name} errorCase={{
                                                                required: '이름을 입력해 주세요.'
                                                            }}/>}/>
                                                        {recipient.emailCheckYn &&  
                                                            <InputText name={`recipients[${idx}].email`} fullSize value={recipient.email} explain="이메일*" className={st.emailInput} onChange={(e) => vm.setEmail(e, idx)} errorText={<Error name={`recipients[${idx}].email`} value={recipient.email} errorCase={{
                                                                required: '이메일을 입력해 주세요.',
                                                                pattern: {
                                                                    value: regExp.email().test(recipient.email || ""),
                                                                    message: '올바른 이메일 형식으로 입력해 주세요.'
                                                                }
                                                            }}/>}/>
                                                        }
                                                        {recipient.phoneNumberYn &&  
                                                            <InputText name={`recipients[${idx}].phoneNumber`} fullSize value={recipient.phoneNumber} explain="휴대폰 번호*" className={st.phoneNumberInput} onChange={(e) => vm.setPhoneNumber(e, idx)} errorText={<Error name={`recipients[${idx}].phoneNumber`} value={recipient.phoneNumber} errorCase={{
                                                                required: '휴대폰 번호를 입력해 주세요.',
                                                                pattern: {
                                                                    value: regExp.phoneNumber().test(recipient.phoneNumber || ""),
                                                                    message: '올바른 휴대폰 번호를 입력해 주세요.'
                                                                }
                                                            }}/>}/>
                                                        }
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                )
                            })}
                            {vm.state.sendMethodErrorCheckYn && <ErrorText>발송방법을 선택하세요.</ErrorText>}
                            <div className={st.addPersonBox}>
                                <AddBtn className={st.addBtn} onClick={() => vm.addRecipient()}>받을 분 추가하기</AddBtn>
                                <div className={st.explain}>받는 분은 발송 후 1달 동안 사업자등록증 & 통장사본을 다운받을 수 있어요.</div>
                                <button type="button" className={st.freeViewBtn} onClick={() => vm.sendPreview()}>발송화면 미리보기</button>
                            </div>
                        </div>
                    </div>
                    <div className={st.btnArea}>
                        <SquareBtn type="submit" className={st.sendBtn}>발송하기</SquareBtn>
                    </div>
                </form>
            </ContentBox>
        </>
    ));
}
export default SendBRAndBankbook;