
import { get } from '@common/module/httpRequest';

import type { TermsRq } from './termsService.interface';

class termsService {
    async loadTerms(type: string) {
        const res = await await get<TermsRq>(`/term/${type}`, {}, {});
        return res.data;
    }
}

export default new termsService();