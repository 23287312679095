import { useObserver } from 'mobx-react';
import { Route, withRouter, Link } from "react-router-dom";
import { useEffect } from 'react';
import st from './ProvideInformationTermsModal.module.scss';
import clsx from 'clsx';

function ProvideInformationTermsModal(props) {
    return useObserver(() => (
        <div className={clsx(st.termsContent, "terms")} dangerouslySetInnerHTML={{__html: props.content}}></div>
    ));
}
export default ProvideInformationTermsModal;