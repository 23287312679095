import { useObserver } from 'mobx-react';
import { useEffect, useState } from 'react';
import CompleteHomeVm from './CompleteHomeVm';
import st from './CompleteHome.module.scss';
import clsx from 'clsx';
import PageTitle from '@commonComponents/PageTitle/PageTitle';
import Guide from '@commonComponents/Guide/Guide';
import GuideList from '@commonComponents/GuideList/GuideList';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import FileBtn from '@standby/common-ui/components/atoms/Button/FileBtn/FileBtn'
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import AddBtn from '@standby/common-ui/components/atoms/Button/AddBtn/AddBtn';
import ToastMessage from '@standby/common-ui/components/atoms/ToastMessage/ToastMessage';
import Table from '@standby/common-ui/components/atoms/Table/Table';
import CopyBtn from '@standby/common-ui/components/atoms/Button/CopyBtn/CopyBtn';
import copyText from '@standby/common-ui/module/copyText';

interface CopyBtnWithToastProps {
    className: string;
    toastMessageClassName: string;
    copyText: string;
    children: string;
}

function CopyBtnWithToast(props: CopyBtnWithToastProps) {

    const [showYn, setShowYn] = useState<boolean>(false);

    const _onClick = (_copyText: string) => {
        setShowYn(true);
        copyText(_copyText);
        setTimeout(() => setShowYn(false), 2000);
    }

    return useObserver(() => (
        <div className={st.toastMessageBtnBox}>
            {showYn && <ToastMessage className={props.toastMessageClassName}>복사 되었어요!</ToastMessage>}
            <button type="button" className={props.className} onClick={() => _onClick(props.copyText)}>{props.children}</button>           
        </div>
    ))
};

function CompleteHome() {

    useEffect(() => {
        CompleteHomeVm.load();
    }, []);

    return useObserver(() => (
        <>
            <PageTitle><span className={st.pageTitle}>사업자등록</span></PageTitle> 
            <Guide title={<strong>사업자등록 가이드</strong>} localStorageKey="businessRegistration" corpId={CompleteHomeVm.corpId}>
                <GuideList items={CompleteHomeVm.guides} fullLink="https://standby.kr/blog/category/%EC%82%AC%EC%97%85%EC%9E%90%EB%93%B1%EB%A1%9D%EC%A6%9D/"/>
            </Guide>
            <div className={st.documentBox}>
                <div className={st.title}>이제 <span className={st.bold}>클릭 한 번</span>으로 서류를 보내세요!</div>
                <div className={st.explain}><span className={st.bold}>이메일, 카카오톡</span>으로 바로 전송가능!</div>
                <button type="button" className={st.documentSendBtn} onClick={() => CompleteHomeVm.sendDocument()}><span className={st.text}>사업자등록증 & 통장사본 보내기</span></button>
            </div>
            <ContentBox title="사업자등록" className={st.contentBox} titleStyle={{top:"15px", paddingLeft:"18px", fontSize:"18px"}}>
                <div className={st.floatBtnArea}>
                    <SquareBtn className={st.updateBRBtn} color="white" onClick={() => CompleteHomeVm.updateDocument()}>사업자등록증 교체</SquareBtn>
                    <SquareBtn className={st.sednBRBtn} onClick={() => CompleteHomeVm.sendDocument()}>사업자등록증 보내기</SquareBtn>
                </div>
                <div className={st.content}>
                    <div className={st.businessBox}>
                        <div className={st.downloadBox}>
                            <div className={st.imgDownload}>
                                <img src='/images/businessRegistration_sample.svg'/>
                                <FileBtn btnType="important" className={st.downloadBtn} onClick={() => CompleteHomeVm.downLoadFile()}>사업자등록증 다운로드</FileBtn>
                            </div>
                            <div className={st.updatedAt}>{CompleteHomeVm.issuedAt} 발급</div>
                        </div>
                        <div className={st.informationBox}>
                            <div className={st.title}>기본정보</div>
                            <div className={st.infoBox}>
                                <div className={clsx(st.infoTitle, st.businessRegistrationNumber)}>사업자등록번호</div>
                                <div className={st.info}>{CompleteHomeVm.information?.businessRegistrationNumber}</div>
                            </div>
                            <div className={st.infoBox}>
                                <div className={clsx(st.infoTitle, st.establishedAt)}>법인설립일</div>
                                <div className={st.info}>{CompleteHomeVm.information?.establishedAt}</div>
                            </div>
                            <div className={st.infoBox}>
                                <div className={clsx(st.infoTitle, st.businessStartAt)}>개업 연월일</div>
                                <div className={st.info}>{CompleteHomeVm.information?.businessStartAt}</div>
                            </div>
                            <div className={st.infoBox}>
                                <div className={clsx(st.infoTitle, st.businessYear)}>사업연도</div>
                                <div className={st.info}>{CompleteHomeVm.information?.businessYear}</div>
                            </div>
                        </div>  
                    </div>
                    <div className={st.businessPurposeBox}>
                        <div className={st.businessTypes}>
                            <div className={st.businessTypesTitle}>업태</div>
                            <ul>
                                {CompleteHomeVm.businessTypeAndItemList.map((businessType, index) => (
                                    <li key={index} className={clsx(st.listCard, index === 0 ? st.important : null)}><span>{businessType.type}</span></li>
                                ))}
                            </ul>
                        </div>
                        <div className={st.businessItems}>
                            <div className={st.businessItemsTitle}>종목</div>
                            <ul>
                                {CompleteHomeVm.businessTypeAndItemList.map((businessItem, index) => (
                                    <li key={index} className={clsx(st.listCard, index === 0 ? st.important : null)}><span>{businessItem.item}</span></li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </ContentBox>
            <ContentBox title="통장사본" className={st.contentBox} titleStyle={{top:"15px", paddingLeft:"18px", fontSize:"18px"}}>
                <div className={st.floatBtnArea}>
                    <SquareBtn className={st.changeBankbookBtn} color="white" onClick={() => CompleteHomeVm.addBankbook()}>통장사본 추가/변경하기</SquareBtn>
                    <SquareBtn className={st.addBankbookBtn} disabled={CompleteHomeVm.bankbooks.length === 0 ? true : false} onClick={() => CompleteHomeVm.sendDocument()}>통장사본 보내기</SquareBtn>
                </div>
                <div className={st.content}>
                    {/* @todo 컴포넌트로 분리하기 */}
                    {CompleteHomeVm.bankbooks.length === 0 &&
                        <img src='/images/bankbook_explain.svg' alt="통장사본 안내"/>
                    }
                    {CompleteHomeVm.bankbooks.length !== 0 &&
                        <div className={st.bankbook}> 
                            <div className={st.title}>총 {CompleteHomeVm.bankbooks.length}개</div>
                            <div className={st.bankbookInfoBox}>
                                {CompleteHomeVm.bankbooks.length < 5 ?
                                <>
                                    {CompleteHomeVm.bankbooks.map((bankbook, idx) => {
                                        return (
                                            <div className={st.bankbookBox} key={idx}>
                                                <div className={st.bank}>
                                                    <div className={clsx(st.bankName, st[bankbook.bankName || ""])}>{bankbook.bankName}</div>
                                                    {bankbook.accountUsage && 
                                                        <div className={st.accountUsage}>{bankbook.accountUsage}</div>
                                                    }
                                                </div>
                                                <div className={st.bankInfo}>
                                                    <div className={st.nameBox}>
                                                        <div className={st.title}>예금주</div>
                                                        <div className={st.name}>{CompleteHomeVm.companyName}</div>
                                                    </div>
                                                    <div className={st.accountBox}>
                                                        <div className={st.infoBox}>
                                                            <div className={st.info}>
                                                                <div className={st.title}>계좌번호</div>
                                                                <div className={st.account}>{bankbook.accountNumber}</div>
                                                            </div>
                                                            <CopyBtnWithToast className={st.accountInfoCopyBtn} toastMessageClassName={st.toastMessage} copyText={bankbook.accountNumber}>계좌번호 복사</CopyBtnWithToast>
                                                        </div>
                                                    </div>
                                                    <div className={st.btnArea}>
                                                        <CopyBtnWithToast className={st.accountInfoCopyBtn} toastMessageClassName={st.toastMessage} copyText={CompleteHomeVm.accountInfoCopyText(bankbook.bankName, bankbook.accountNumber)}>계좌 정보 복사</CopyBtnWithToast>
                                                        <button type="button" className={st.bankbookDownloadBtn} onClick={() => CompleteHomeVm.fileDownload(bankbook.fileDownloadUrl || "")}><span className={st.text}>통장사본 다운로드</span></button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    {CompleteHomeVm.bankbooks.length === 1 && 
                                        <AddBtn className={st.addBankBook} onClick={() => CompleteHomeVm.addAccount()}>통장사본 추가하기</AddBtn>
                                    }
                                </>
                                :
                                <>
                                    <Table className={st.table}>
                                        <colgroup>
                                            <col width="46"/>
                                            <col width="150"/>
                                            <col width="180"/>
                                            <col/>
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>순번</th>
                                                <th>은행</th>
                                                <th>계좌번호</th>
                                                <th>계좌용도</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {CompleteHomeVm.bankbooks.map((bankbook, idx) => {
                                                return (
                                                    <tr>
                                                        <td>{idx+1}</td>
                                                        <td><span className={clsx(st.bankName, st[bankbook.bankName || ""])}>{bankbook.bankName}</span></td>
                                                        <td>
                                                            <CopyBtn className={st.accountNumber} messageClassName={st.accountNumberCopyMessage} copyText={bankbook.accountNumber}>{bankbook.accountNumber}</CopyBtn>
                                                        </td>
                                                        <td className={st.accountInfo}>
                                                            <div className={st.accountUsage}>{bankbook.accountUsage || '-'}</div>
                                                            <div className={st.btnArea}>
                                                                <CopyBtnWithToast className={st.accountInfoCopyBtn} toastMessageClassName={st.toastMessage} copyText={CompleteHomeVm.accountInfoCopyText(bankbook.bankName, bankbook.accountNumber)}>계좌 정보 복사</CopyBtnWithToast>
                                                                <button type="button" className={st.bankbookDownloadBtn} onClick={() => CompleteHomeVm.fileDownload(bankbook.fileDownloadUrl || "")}><span className={st.text}>통장사본 다운로드</span></button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </>
                            }                
                            </div>
                        </div>
                    }
                </div>
            </ContentBox>
            <ContentBox title="사업자등록 FAQ" className={st.contentBox} titleStyle={{top:"15px", paddingLeft:"18px", fontSize:"18px"}}>
                <div className={st.content}>
                    <div className={st.businessFAQBox}>
                        <div className={st.faq}>
                            <div className={st.question}>사업자등록증은 어떻게 재발급하나요?</div>
                            <div className={st.answer}>홈택스 로그인 후 1)민원증명 <span className={st.arrowIcon}/> 2)사업자등록증 재발급 버튼을 눌러서 재발급하면 되어요.</div>
                        </div>
                        <div className={st.faq}>
                            <div className={st.question}>사업자등록증은 발급비용이 얼마인가요?</div>
                            <div className={st.answer}>사업자등록증은 무료로 발급되어요. (법인등기부등본은 700~1,000원 지불)</div>
                        </div>
                        <div className={st.faq}>
                            <div className={st.question}>사업자등록증에는 유효기간이 있나요?</div>
                            <div className={st.answer}>사업자등록증은 유효기간이 없어요. (법인등기부등본은 유효기간 3개월)</div>
                        </div>
                        <div className={st.faq}>
                            <div className={st.question}>사업자등록증은 언제 변경해야 해요?</div>
                            <div className={st.answer}>회사명, 본점주소, 대표자, 주로 운영하는 업종(업태, 종목)이 바뀌었을 때 변경해요.</div>
                        </div>
                        <div className={st.faq}>
                            <div className={st.question}>사업자등록증은 어떻게 변경해요?</div>
                            <div className={st.answer}>홈택스에서 직접 변경할 수 있어요. 만약 담당 회계법인/세무법인이 있다면 변경을 요청하세요.</div>
                        </div>
                    </div>
                </div>
            </ContentBox>
        </>
    ));
}
export default CompleteHome;