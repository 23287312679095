import { useObserver } from 'mobx-react';
import React from 'react';
import QuestionTooltip from '@standby/common-ui/components/molecules/QuestionTooltip/QuestionTooltip';
import qst from '@pages/Corp/Agenda/Step/AgendaQuestion/commonQuestion.module.scss';
import st from './ApprovalofExecutiveRemunerationLimit.module.scss';
import FileBtn from '@standby/common-ui/components/atoms/Button/FileBtn/FileBtn';
import DatePicker from '@standby/common-ui/components/molecules/DatePicker/DatePicker';
import RadioLabel from '@standby/common-ui/components/molecules/RadioLabel/RadioLabel';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import { addCommas} from '@common/module/replaceNumber';
import Table from '@standby/common-ui/components/atoms/Table/Table'
import vm from './ApprovalofExecutiveRemunerationLimitVm';
import clsx from 'clsx';
import type {SelectedAgenda, I임원_보수_한도_승인} from '@model/Corp/Agenda/Step/agendaStepModel.interface';

interface ApprovalofExecutiveRemunerationLimitProps {
    agenda : SelectedAgenda<I임원_보수_한도_승인>,
    agendaIndex : number
}

const ApprovalofExecutiveRemunerationLimit = React.memo(function (props : ApprovalofExecutiveRemunerationLimitProps) {
    return useObserver(() => (
        <div className={qst.questionForm}>
            <div className={qst.qusetion}>
                <div className={qst.questionTitle}>
                    <QuestionTooltip title="주주총회에서 매년 임원 보수를 정하는 것이 번거롭다면?" style={{width: '460px'}}>
                        <div className={st.tooltipContent}>
                            <div className={st.exTitle}>임원의 보수는 정관 또는 주주총회로 정해야 해요.</div>
                            <ul className={st.exList}>
                                <li>상법상 이사, 감사, 대표의 보수(급여, 상여금, 퇴직금)은 <span className={st.important}>정관에서 정하지 않으면, 주주총회에서 매번 승인해야</span> 해요. 이렇게 하지 않는다면, 나중에 세무조사 진행시, 임원의 보수에 대한 비용처리가 부인당할 수도 있어요.</li>
                                <li>하지만 정관에 대표이사의 보수는 5억 원이라고 구체적으로 적어두는 것은 추천하지 않아요. 정관은 비밀문서가 아니고, 외부에 공개할 일이 자주 있기 때문이에요.</li>
                            </ul>
                            <div className={st.exTitle}>정관에 간단한 내용만 넣어두고, 별도로 규정을 만드는 편을 추천해요.</div>
                            <ul className={st.exList}>
                                <li>
                                    번거로움을 피하기 위한 가장 간단한 방법을 알려드릴게요.
                                    <ul className={st.stepList}>
                                        <li>
                                            <span className={st.step}>STEP1</span>
                                            <div className={st.content}>
                                                스탠바이에서 [규정승인] 안건을 선택해주세요.<br/>(임원 보수, 상여금, 퇴직금)
                                            </div>
                                        </li>
                                        <li>
                                            <span className={st.step}>STEP2</span>
                                            <div className={st.content}>
                                                임원 보수, 상여금, 퇴직금 규정을 만들고, 주주총회에서 승인을 받으세요.
                                                <div className={st.fileBtn}>
                                                    <FileBtn onClick={() => vm.download()}>보수, 상여금, 퇴직금 규정 3종 샘플 다운로드</FileBtn>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    이 때 정관에 “임원의 보수, 상여금, 퇴직금은 주주총회의 승인을 거친 별도 규정으로 정한다.”라는 내용이 포함되어 있어야 해요. 만약 포함되어 있지 않다면, 스탠바이에서 자동으로 정관변경 안건을 추가해드려요.
                                    <ul className={st.stepList}>
                                        <li>
                                            <span className={st.step}>STEP3</span>
                                            <div className={st.content}>
                                                별도 규정의 내용에 따라 필요하다면, 이사회 승인을 진행하세요.
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </QuestionTooltip>
                </div>
            </div>
            <div className={qst.qusetion}>
                <div className={qst.questionTitle}>승인 기간을 입력하세요.</div>
                <div className={st.datePickerBox}>
                    <div>
                        <input type="hidden" name="agendaDetail.approvalStartAt.year" value={props.agenda.agendaDetail.approvalStartAt.year} />
                        <input type="hidden" name="agendaDetail.approvalStartAt.month" value={props.agenda.agendaDetail.approvalStartAt.month} />
                        <input type="hidden" name="agendaDetail.approvalStartAt.day" value={props.agenda.agendaDetail.approvalStartAt.day} />
                        <DatePicker name="agendaDetail.approvalStartAt" selected={vm.approvalStart(props.agendaIndex)} onChange={(e) => vm.setApprovalStart(e, props.agendaIndex)} explain="시작일" />
                        <div>
                            <Error name="agendaDetail.approvalStartAt" value={vm.approvalStart(props.agendaIndex) || ""} errorCase={{
                                required: '승인 기간을 입력하세요.'
                            }}
                            />
                        </div>
                    </div>
                    <div style={{marginLeft:'20px'}}>
                        <input type="hidden" name="agendaDetail.approvalEndAt.year" value={props.agenda.agendaDetail.approvalEndAt.year} />
                        <input type="hidden" name="agendaDetail.approvalEndAt.month" value={props.agenda.agendaDetail.approvalEndAt.month} />
                        <input type="hidden" name="agendaDetail.approvalEndAt.day" value={props.agenda.agendaDetail.approvalEndAt.day} />
                        <DatePicker name="agendaDetail.approvalEndAt" selected={vm.approvalEnd(props.agendaIndex)} onChange={(e) => vm.setApprovalEnd(e, props.agendaIndex)} explain="종료일" />
                        <div>
                            <Error name="agendaDetail.approvalEndAt" value={vm.approvalEnd(props.agendaIndex) || ""} errorCase={{
                                required: '승인 기간을 입력하세요.'
                            }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={qst.qusetion}>
                <div className={qst.questionTitle}>승인 방식을 선택하세요.</div>
                <div style={{width:"460px"}}>
                    <RadioLabel className={clsx(qst.radio, st.radio)} name="agendaDetail.approvalType" value="TOTAL" checked={props.agenda.agendaDetail.approvalType === "TOTAL"} onChange={(e) => vm.approvalType(e, props.agendaIndex)}>
                        총 한도 승인
                        <div className={qst.radioExplain}>전체 임원의 보수를 한꺼번에 승인하는 방식</div>
                    </RadioLabel>
                    <RadioLabel className={qst.radio} name="agendaDetail.approvalType" value="INDIVIDUAL" checked={props.agenda.agendaDetail.approvalType === "INDIVIDUAL"} onChange={(e) => vm.approvalType(e, props.agendaIndex)}>
                        임원별 한도 승인
                        <div className={qst.radioExplain}>인원 개인별로 보수를 승인하는 방식</div>
                    </RadioLabel>
                    <div>
                        <Error name="agendaDetail.approvalType" value={props.agenda.agendaDetail.approvalType} errorCase={{
                            required: '승인 방식을 선택하세요.'
                        }}
                        />
                    </div>
                </div>
            </div>
            {props.agenda.agendaDetail.approvalType === "TOTAL" && 
                <>
                    <div className={qst.qusetion}>
                        <div className={qst.questionTitle}>
                            <div>이사 보수 총 한도를 입력하세요.</div>
                            <div className={qst.titleSubInfo}>* 퇴직금을 포함한 액수를 기재하세요.</div>
                        </div>
                        <div className={qst.subTextInput}>
                            <InputText name="agendaDetail.이사SalaryLimit" textAlign="right" placeholder="0" value={addCommas(props.agenda.agendaDetail.이사SalaryLimit)} onChange={(e) => vm.이사SalaryLimit(e, props.agendaIndex)} style={{width:"220px"}}
                                errorText={<Error name="agendaDetail.이사SalaryLimit" value={addCommas(props.agenda.agendaDetail.이사SalaryLimit) || ""} errorCase={{
                                    required: '한도를 입력하세요.'
                                }}
                                />}
                            /><span className={qst.inputSubText}>원</span>
                        </div>
                    </div>
                    {props.agenda.agendaDetail.isExists_감사 && 
                        <div className={qst.qusetion}>
                            <div className={qst.questionTitle}>
                                <div>감사 보수 총 한도를 입력하세요.</div>
                                <div className={qst.titleSubInfo}>* 퇴직금을 포함한 액수를 기재하세요.</div>
                            </div>
                            <div className={qst.subTextInput}>
                                <InputText name="agendaDetail.감사SalaryLimit" textAlign="right" placeholder="0" value={addCommas(props.agenda.agendaDetail.감사SalaryLimit)} onChange={(e) => vm.감사SalaryLimit(e, props.agendaIndex)} style={{width:"220px"}}
                                    errorText={<Error name="agendaDetail.감사SalaryLimit" value={addCommas(props.agenda.agendaDetail.감사SalaryLimit) || ""} errorCase={{
                                        required: '한도를 입력하세요.'
                                    }}
                                    />}
                                /><span className={qst.inputSubText}>원</span>
                            </div>
                        </div>
                    }
                </>
            }
            {props.agenda.agendaDetail.approvalType === "INDIVIDUAL" && 
                <div className={qst.qusetion}>
                    <div className={qst.questionTitle}>
                        <div>임원별 보수 총 한도를 입력해주세요.</div> 
                        <div className={qst.titleSubInfo}>* 퇴직금을 포함한 액수를 기재하세요.</div>
                    </div>
                    <Table style={{width:'460px'}}>
                        <thead>
                            <tr>
                                <th>임원</th>
                                <th style={{textAlign:'right', padding:'0 20px'}}>퇴직금 포함 총 보수(1년)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.agenda.agendaDetail.salaryLimitOfExecutives.map((salaryLimitOfExecutive, index) => (
                                <tr key={index}>
                                    <td>{salaryLimitOfExecutive.name}{salaryLimitOfExecutive.newExecutiveYn && <span className={st.newTag}>신규</span>}</td>
                                    <td style={{textAlign:'right'}}>
                                        <div className={qst.subTextInput}>
                                            <input type="hidden" name={`agendaDetail.salaryLimitOfExecutives[${index}].id`} value={salaryLimitOfExecutive.id}/>
                                            <InputText name={`agendaDetail.salaryLimitOfExecutives[${index}].salary`} textAlign="right" placeholder="0" value={addCommas(salaryLimitOfExecutive.salary)} onChange={(e) => vm.salary(e, props.agendaIndex, index)} style={{width:"201px"}} size="small"
                                            errorText={<Error name={`agendaDetail.salaryLimitOfExecutives[${index}].salary`} value={addCommas(salaryLimitOfExecutive.salary) || ""} errorCase={{
                                                required: '보수를 입력하세요.'
                                            }}
                                            />}
                                            /><span className={qst.inputSubText}>원</span>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            }
        </div>
    ));
})
export default ApprovalofExecutiveRemunerationLimit;