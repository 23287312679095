import { useObserver } from 'mobx-react';
import st from './TermsModal.module.scss';
import clsx from 'clsx';

interface TermsModalProps {
    content : JSX.Element
}

function TermsModal(props : TermsModalProps) {
    return useObserver(() => (
        <div className={clsx(st.termsContent, "terms")}>
            {props.content}
        </div>
    ));
}
export default TermsModal;