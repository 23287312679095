import { useObserver } from 'mobx-react';
import React from 'react';
import st from  './HeaderSidebar.module.scss';
import clsx from 'clsx';
import ScrollArea from 'react-custom-scrollbars-2';

interface HeaderSidebarProps {
    companyName: string;
    businessRegistrationActiveYn?: boolean;
    awsCreditActiveYn?: boolean;
    internetAssociateActiveYn?:boolean;
}

function HeaderSidebar(props: HeaderSidebarProps) {
    return useObserver(() => (
        <>  
            <header className={st.header}>
                <img src="/images/logo.svg?1" alt='스탠바이 서비스 로고' title='스탠바이 서비스 로고'/>                
            </header> 
            <div className={st.side}>
                {props.companyName && 
                    <div className={st.companyName}>{props.companyName}</div>
                }
                <div className={st.joinStandby}>
                    <a className={st.joinStandbyLink} href='/auth/joinMembership' target='_blank'>스탠바이 가입하기</a>
                </div>
                <ScrollArea className={st.scrollArea} renderView={({style, props}) => <div {...props} style={{...style, overflow:"auto", marginBottom:"0"}}/>} renderThumbVertical={props => <div {...props} style={{width:"3px", backgroundColor:'#D6D8DA'}}/>}>
                    <nav>
                        <NavBox title="Home" imgLink='/images/sideNavIcon_Home'>
                            <SideButton>한 눈에 보는 우리회사</SideButton>
                        </NavBox>
                        <NavBox title="회사정보" imgLink={`/images/sideNavIcon_회사정보${props.businessRegistrationActiveYn ? '_active' : ''}`} active={props.businessRegistrationActiveYn}>
                            <SideButton>정관</SideButton>
                            <SideButton>등기부</SideButton>
                            <SideButton active={props.businessRegistrationActiveYn}>사업자등록</SideButton>
                        </NavBox>
                        <NavBox title="주주/주식" imgLink='/images/sideNavIcon_주주_주식'>
                            <SideButton>주주명부</SideButton>
                        </NavBox>
                        <NavBox title="세금" imgLink='/images/sideNavIcon_세금'>
                            <SideButton>기장계약<span className={st.small}>with</span>회계법인</SideButton>
                            <SideButton>부가세</SideButton>
                            <SideButton>법인세</SideButton>
                        </NavBox>
                        <NavBox title="스탠바이가 보증하는 특별혜택" imgLink={`/images/sideNavIcon_전문가_서비스${(props.awsCreditActiveYn || props.internetAssociateActiveYn) ? '_active' : ''}`} active={(props.awsCreditActiveYn || props.internetAssociateActiveYn)} letterNarrow>
                            <SideButton publicLink='/guest/internetAssociate' active={props.internetAssociateActiveYn}>기업용 인터넷·전화 캐시백</SideButton>
                            <SideButton publicLink='/guest/awsCredit' active={props.awsCreditActiveYn}>클라우드 지원금 270만원</SideButton>
                        </NavBox>
                    </nav>
                </ScrollArea>
                <div className={st.blogWrap}>
                    <div className={st.blogContent}>
                        <ul className={st.guideList}>
                            <li>
                                <div className={st.guideTitle}>꼭 알아야 할</div>
                                <ul className={st.linkList}>
                                    <li><a href="https://standby.kr/blog/category/%EB%B2%95%EC%9D%B8%EC%84%A4%EB%A6%BD%20%EC%A7%81%ED%9B%84%20%ED%95%A0%20%EC%9D%BC/" target='_blank'>법인 운영 기본 가이드</a></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    ));
}

interface SideButtonProps {
    active?: boolean;
    children: React.ReactNode;
    publicLink?: string;
}

function SideButton(props: SideButtonProps) {
    return useObserver(() => (
        props.publicLink ? 
        <a href={props.publicLink} target='_blank' type="button" className={clsx(st.sideButton, props.active ? st.active : "", props.publicLink ? st.publicLink : "")}> 
            {props.children}
        </a>
        :
        <button type="button" className={clsx(st.sideButton, props.active ? st.active : "")}> 
            {props.children}
        </button>
    ))
}

interface NavBoxProps {
    title: string;
    imgLink: string;
    active?: boolean;
    children: React.ReactNode;
    letterNarrow?: boolean;
}

function NavBox(props: NavBoxProps) {
    return useObserver(() => (
        <div className={st.navBox}>
            <div className={clsx(st.linkTitle, props.active ? st.active : "", props.letterNarrow ? st.narrow : '')} style={{"backgroundImage":`url(${props.imgLink}.svg)`}}>{props.title}</div>
            <div className={st.sideLinkList}>
                {props.children}
            </div>
        </div>
    ))
}

export default HeaderSidebar;