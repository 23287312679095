
import corpModel from '@model/Corp/corpModel';
import portalModel from '@model/portalModel';
import ChangeRegistrationAgencyModel from "@model/Corp/Agenda/Step/FinalCheck/BeforeFinal/ChangeRegistrationAgency/ChangeRegistrationAgencyModel";
import ChangeRegistrationAgencyService from "@service/Corp/Agenda/Step/FinalCheck/BeforeFinal/ChangeRegistrationAgency/ChangeRegistrationAgencyService";
import { runInAction, observable } from "mobx";
import regExp from '@common/module/regExp';
import formDataToJson from '@common/module/submit';
import { _alert } from '@model/dialogModel';

import type { CompanyUseType, CompanyShareholderListUseType, RegistrationAgencyParam } from '@service/Corp/Agenda/Step/FinalCheck/BeforeFinal/ChangeRegistrationAgency/ChangeRegistrationAgencyService.interface'

interface State {
    requestRequirementYn: boolean;
    termsRequiredCheckYn: boolean;
    terms: {
        id: number;
        required: boolean;
        title: string;
        content: string;
    }[];
}

class ChangeRegistrationAgencyVm {
    state: State = observable({
        requestRequirementYn: false,
        termsRequiredCheckYn: false,
        terms: []
    })
    async load(agendaProgressId: string) {
        await ChangeRegistrationAgencyService.load(agendaProgressId);
        const data = await ChangeRegistrationAgencyService.loadTerms();
        this.state.terms = data.userRegistrationTerms.map((item) => {
            return {
                id: item.id || -1,
                required: item.required || false,
                title: item.title || "",
                content: item.content || ""
            };
        })
    }      
    async submit(e: React.FormEvent<HTMLFormElement>, agendaProgressId: string) {
        e.preventDefault();
        if(formDataToJson(e.target).error) {
            return
        } else {
            let param = formDataToJson(e.target).data as RegistrationAgencyParam;
            param.corpRegProgressType = 'REGISTRATION_AGENCY';
            await ChangeRegistrationAgencyService.registrationAgency(agendaProgressId, param);
            await _alert('등기 대행신청이 잘 접수되었습니다', '', undefined, undefined, 'loud');
            runInAction(() => {
                portalModel.title = '';
                portalModel.content = null;
            })
            window.location.reload();
        }
    }  
    get jointCertificate() {
        return ChangeRegistrationAgencyModel.jointCertificate;
    }
    setJointCertificateUseYn(e: React.ChangeEvent<HTMLInputElement>) {
        const jointCertificate = ChangeRegistrationAgencyModel.jointCertificate;

        if (jointCertificate) {
            runInAction(() => {
                jointCertificate.useYn = e.target.value === "true";
            });
        }
    }
    get companyShareholderList() {
        return ChangeRegistrationAgencyModel.digitalCertificate.companyShareholderList;
    }
    setCompanyUseType(e: React.ChangeEvent<HTMLInputElement>, idx: number) {
        const companyShareholderList = ChangeRegistrationAgencyModel.digitalCertificate?.companyShareholderList;

        if(companyShareholderList) {
            runInAction(() => {
                companyShareholderList[idx].useType = e.target.value as CompanyShareholderListUseType;
            })
        }
    }  
    get companyName() {
        const company = corpModel.company;
        let companyName = "";   
        
        if(company.prefixedYn) {
            if(company.corpType === '주식회사') {
                companyName = `(주)${company.companyName}`;
            } else if(company.corpType === '유한회사' || company.corpType === '유한책임회사') {
                companyName = `(유)${company.companyName}`;
            }
        } else {
            if(company.corpType === '주식회사') {
                companyName = `${company.companyName}(주)`;
            } else if(company.corpType === '유한회사' || company.corpType === '유한책임회사') {
                companyName = `${company.companyName}(유)`;
            }
        }

        return companyName;
    }
    get digitalCertificateUseType() {
        return ChangeRegistrationAgencyModel.digitalCertificate.company.useType;
    }
    setDigitalCertificateUseType(e: React.ChangeEvent<HTMLInputElement>) {
        runInAction(() => {
            ChangeRegistrationAgencyModel.digitalCertificate.company.useType = e.target.value as CompanyUseType;
        })
    }
    openRequirement() {
        this.state.requestRequirementYn = !this.state.requestRequirementYn;
    }
    get requestRequirement() {
        return ChangeRegistrationAgencyModel.requestRequirement;
    }
    setRequestRequirement(e: React.ChangeEvent<HTMLTextAreaElement>) {
        runInAction(() => {
            ChangeRegistrationAgencyModel.requestRequirement = e.target.value;
        })
    }
    get personInCharge() {
        return ChangeRegistrationAgencyModel.personInCharge;
    }
    setPersonInCharge(e: React.ChangeEvent<HTMLInputElement>, type: string) {
        runInAction(() => {
            if(type === 'name') {
                ChangeRegistrationAgencyModel.personInCharge.name = e.target.value;
            } else if(type === 'phoneNum') {
                ChangeRegistrationAgencyModel.personInCharge.phoneNum = e.target.value;
            } else if(type === 'email') {
                ChangeRegistrationAgencyModel.personInCharge.email = regExp.deleteSpace(e.target.value);
            }
        })
    }
    get informationProvideYn() {
        return ChangeRegistrationAgencyModel.informationProvideYn;
    }
    setInformationProvideYn(e: React.ChangeEvent<HTMLInputElement>) {
        runInAction(() => {
            ChangeRegistrationAgencyModel.informationProvideYn = e.target.checked;
        })
    }
    get telephoneCounselingYn() {
        return ChangeRegistrationAgencyModel.telephoneCounselingYn;
    }
    setTelephoneCounselingYn(e: React.ChangeEvent<HTMLInputElement>) {
        runInAction(() => {
            ChangeRegistrationAgencyModel.telephoneCounselingYn = e.target.checked;
        })
    }
    openTermsWindow() {
        const findIdx = this.state.terms.findIndex((item) => item.title === '개인정보 수집 및 이용 동의');
        const termId = this.state.terms[findIdx].id;
        window.open(window.location.origin + `/terms?termId=${termId}&type=NORMAL_REGISTER`, `terms${termId}`, `width=800, height=${window.screen.height}, toolbar=0,location=0,menubar=0`);
    }
}

export default new ChangeRegistrationAgencyVm();