import adminSettingService from '@service/Corp/AdminSetting/adminSettingService';
import adminModel from '@model/Corp/AdminSetting/adminModel';
import userModel from '@model/userModel';
import formDataToJson from '@common/module/submit';
import { _alert, _confirm } from "@model/dialogModel";
import portalModel from '@model/portalModel';
import { runInAction, observable, toJS } from "mobx";
import st from './AdminSetting.module.scss'
import corpModel from '@model/Corp/corpModel';

class AdminSettingVm {
    load() {
        adminSettingService.load();
    }
    get id() {
        return corpModel.id;
    }
    get managers() {
        return adminModel.managers;
    }
    get userId() {
        return userModel.user.id;
    }
    async changeAuthorityType(e, index, userId) {
        if(this.isGenerManager) {
            await adminSettingService.editAuthorityType(userId, e.target.value);
            adminModel.managers[index].authorityType = e.target.value;
        }else{
            return _alert('최고 관리자만 관리자 권한 변경이 가능해요.', '', '확인', <ul className={st.alertContent}><li>관리자 권한 변경이 필요하다면 최고 관리자에게 문의하세요.</li></ul>);
        }
    }
    async authorityDelete(userId) {
        if(this.isGenerManager) {
            if(await _confirm('정말 삭제하나요? 삭제 후에는 해당 관리자의 접근이 차단됩니다.', '', '네', '아니오')) {
                await adminSettingService.deleteAdmin(userId);
            }
        }else{
            return _alert('최고 관리자만 다른 관리자를 삭제할 수 있어요.', '', '확인', <ul className={st.alertContent}><li>권한 삭제가 필요하다면 최고 관리자에게 문의하세요.</li></ul>);
        }
    }
    async invitationDelete(userId) {
        await adminSettingService.deleteInvitation(userId);
    }
    changeUseYn(e, userId, type, authorityIndex) {
        adminModel.managers.forEach((manager) => {
            if(manager.userId === userId) {
                manager.authorities[type][authorityIndex].useYn = e.target.checked;
            }
        })
    }
    get isGenerManager() {
        return adminModel.authority.authorityType === "GENERAL_MANAGER";
    }
    authorityCheckedAll(userId, type) {
        let checkAll = true;
        adminModel.managers.forEach((manager) => {
            if(manager.userId === userId) {
                manager.authorities[type].forEach((authority) => {
                    if(authority.useYn === false) {
                        checkAll = false;
                    }
                })
            }
        })
        return checkAll; 
    }
    authorityCheckedAllChange(e, userId, type) {
        adminModel.managers.forEach((manager) => {
            if(manager.userId === userId) {
                manager.authorities[type].forEach((authority) => {
                    authority.useYn = e.target.checked;
                })
            }
        })
    }
    save() {
        if(!this.isGenerManager) {
            return _alert('최고 관리자만 관리자 권한 변경이 가능해요.', '', '확인', <ul className={st.alertContent}><li>관리자 권한 변경이 필요하다면 최고 관리자에게 문의하세요.</li></ul>);
        }
        let notAllowLength = false;
        adminModel.managers.forEach((manager) => {
            if(manager.authorityType !== "GENERAL_MANAGER") { 
                let checkedItem = 0;
                for (const authoritiesType in manager.authorities) {
                    manager.authorities[authoritiesType].forEach((authority) => {
                        checkedItem += authority.useYn ? 1 : 0;
                    })
                }
                if(checkedItem === 0) {
                    notAllowLength = true;
                }
            }
        })
        if(notAllowLength) {
            _alert('관리자는 최소한 1개의 권한을 가지고 있어야 해요.', '', '확인', <ul className={st.alertContent}><li>권한을 추가한 후 저장버튼을 누르세요.</li></ul>);
        }else{
            adminSettingService.save();
        }
    }
}
export default new AdminSettingVm();