import beforeFinalService from "@service/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalService";
import beforeFinalModel from "@model/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalModel";
import st from './ValidationModal.module.scss';
import portalModel from "@model/portalModel";
import { _alert } from '@model/dialogModel';
import { runInAction } from "mobx";

import type { ValidationSchedulesParam } from '@service/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalService.interface';

class ValidationModalVm {
    async submit(agendaProgressId: string, param: ValidationSchedulesParam) {
        await beforeFinalService.submitSchedules(agendaProgressId, param);
        await _alert('변경되었어요. 동의 또는 통지를 다시 발송해주세요.');
        this.modalClose();
        window.location.reload();
    }
    modalClose() {
        runInAction(() => {
            portalModel.title = '';
            portalModel.content = null;
            portalModel.closeBtn = true;
            portalModel.radius = false;
        })
    }
    get changedSchedules() {
        return beforeFinalModel.changedSchedule.changedSchedules;
    }
    get displayExplain() {
        const deleteCaseList = this.changedSchedules?.filter((item) => item.type !== '주주총회_의사록' && item.type !== '이사회_의사록');

        if(deleteCaseList && deleteCaseList.length > 0){
            return true;
        } else {
            return false;
        }
    }
    title(type: string, date: string) {
        switch(type) {
            case '소집통지_온라인':
                return <span>주주총회 소집통지<span className={st.type}> (온라인)</span></span>;
            case '주주동의_주주총회':
                return <span>주주동의<span className={st.type}> (주주총회)</span></span>;
            case '주주동의_주주총회_신주발행':
                return <span>주주동의<span className={st.type}> (주주총회, 신주발행)</span></span>;
            case '신주발행통지':
                return <span>신주발행통지</span>;
            case '주주총회_의사록':
                return <span>{date}자 주주총회 의사록</span>;
            case '이사회_의사록':
                return <span>{date}자 이사회 의사록</span>;
            
        }
    }
    infoText(title: string, changedInfo: {date: boolean, time: boolean, place: boolean} ) {
        let explain = '';
        explain = `${changedInfo.place ? `장소${(changedInfo.place && (changedInfo.date || changedInfo.time)) ? ',' : ''}` : ''} 
                ${changedInfo.date ? `날짜${(changedInfo.date && changedInfo.time) ? ',' : ''} ` : ''}
                ${changedInfo.time ? '시간' : ''}`;

        switch(title) {
            case '소집통지_온라인':
            case '주주동의_주주총회':
            case '주주동의_주주총회_신주발행':
                return '주주총회 ' + explain + ' 변경으로 인해 재발송 필요';
            case '신주발행통지':
                return '통지 일자 변경으로 인해 재발송 필요';
            case '주주총회_의사록':
                return '주주총회 ' + explain + ' 변경으로 인해 다시 다운로드 필요';
            case '이사회_의사록':
                return '이사회 ' + explain + ' 변경으로 인해 다시 다운로드 필요';
        }
    }
}

export default new ValidationModalVm();