import { runInAction, observable, toJS } from "mobx";
import articlesOfAssociationService from '@service/Corp/ArticlesOfAssociation/articlesOfAssociationService';
import { _alert, _confirm } from "@model/dialogModel";
import portalModel from '@model/portalModel';
class ArticlesOfAssociationChangeFormVm {
    constructor() {
        this.state = observable({
            aoaFiles: [{}],
        })
    }
    aoaFileUpload(e,idx) {
        this.state.aoaFiles[idx] = e.target.files;
    }
    aoaFilesAdd() {
        this.state.aoaFiles.push({});
    }
    aoaFilesDelete(idx) {
        this.state.aoaFiles.splice(idx, 1);
    }
    async submit(e) {
        e.preventDefault();
        if(await _confirm('제출 확인 confirm창')) {
            const param = new FormData(e.target);
            await articlesOfAssociationService.articlesOfAssociationChange(param);
            await _alert('정관 반영을 요청했습니다. 변경된 정관은 영업일 기준 1~2일 내에 스탠바이 시스템에 반영됩니다.');
            portalModel.content = null
        }
    }
}
export default ArticlesOfAssociationChangeFormVm;