import meetingsStepModel from '@model/Corp/Agenda/Step/meetingsStepModel';
import {ShareholderType, Shareholder} from '@model/Corp/Agenda/Step/meetingsStepModel.interface';
import { removeCommas, onlyNumber } from '@common/module/replaceNumber';
import { runInAction } from 'mobx';

class ShareholderListVm {
    nearestShareholderListDate(idx: number) {
        return meetingsStepModel.tabList[idx].nearestShareholderListDate;
    }
    decisionDate(idx: number) {
        return meetingsStepModel.tabList[idx].decisionDate;
    }
    decisionDatePhrases(idx: number) {
        return meetingsStepModel.tabList[idx].decisionDatePhrases;
    }
    shareholders(idx: number) {
        return meetingsStepModel.tabList[idx].shareholders;
    }
    changedShareholders(idx: number) {
        return meetingsStepModel.tabList[idx].changedShareholders;
    }
    stocks(idx: number) {
        return meetingsStepModel.tabList[idx].stocks;
    }
    matchedYn(idx: number) {
        return meetingsStepModel.tabList[idx].matchedYn;
    }
    setMatchedYn(e : React.ChangeEvent<HTMLInputElement>, idx: number) {
        meetingsStepModel.tabList[idx].matchedYn = e.target.value === "true";
    }
    setName(e : React.ChangeEvent<HTMLInputElement>, idx: number, shareholderIndex : number) {
        let changedShareholders = this.changedShareholders(idx) as Shareholder[]
        runInAction(() => {
            changedShareholders[shareholderIndex].name = e.target.value;
        })
    }
    setShareholderType(e : React.ChangeEvent<HTMLSelectElement>, idx: number, shareholderIndex : number) {
        let changedShareholders = this.changedShareholders(idx) as Shareholder[]
        runInAction(() => {
            changedShareholders[shareholderIndex].shareholderType = e.target.value as ShareholderType;
        })
    }
    setStock(e : React.ChangeEvent<HTMLSelectElement>, idx: number, shareholderIndex : number, stockIndex : number) {
        let changedShareholders = this.changedShareholders(idx) as Shareholder[]
        runInAction(() => {
            changedShareholders[shareholderIndex].stocks[stockIndex].name = e.target.value;
        })
    }
    setNumber(e : React.ChangeEvent<HTMLInputElement>, idx: number, shareholderIndex : number, stockIndex : number) {
        let changedShareholders = this.changedShareholders(idx) as Shareholder[]
        changedShareholders[shareholderIndex].stocks[stockIndex].number = removeCommas((onlyNumber(e.target.value)).toString());
    }
    addStock(idx: number, shareholderIndex : number, stockIndex : number) {
        let changedShareholders = this.changedShareholders(idx) as Shareholder[]
        changedShareholders[shareholderIndex].stocks.splice(stockIndex+1, 0, {
            "id" : "",
            "name" : "",
            "number" : ""
        })
    }
    deleteStock(idx: number, shareholderIndex : number, stockIndex : number) {
        let changedShareholders = this.changedShareholders(idx) as Shareholder[]
        changedShareholders[shareholderIndex].stocks.splice(stockIndex, 1)
    }
    changedShareholder(index : number, index2 : number) {
        if(this.changedShareholders && this.changedShareholders.length !== 0) {
            this.changedShareholders(index)?.splice(index2, 1);
        }
    }
    shareholdersSum(idx: number) {
        let sum = 0;
        meetingsStepModel.tabList[idx].shareholders?.forEach((shareholder) => {
            shareholder.stocks.forEach((stock) => {
                sum += Number(stock.number);
            })
        });
        return sum;
    }
    changedShareholdersSum(idx: number) {
        let sum = 0;
        meetingsStepModel.tabList[idx].changedShareholders?.forEach((shareholder) => {
            shareholder.stocks.forEach((stock) => {
                sum += Number(stock.number);
            })
        });
        return sum;
    }
    switchShareholderType(shareholderType : ShareholderType) {
        switch(shareholderType) {
            case "국내_미성년자":
                return "한국인 미성년";
                break;
            case "국내_법인":
                return "한국 법인";
                break;
            case "펀드_투자조합":
                return "한국 펀드, 투자조합";
                break;
            case "해외_성인":
                return "외국인 성인";
                break;
            case "해외_미성년자":
                return "외국인 미성년";
                break;
            case "해외_법인":
                return "외국 법인";
                break;
            case "국내_성인":
                return "한국인 성인";
                break;
            default :
                return "";
                break
        }
    }
    addChangedShareholder(index : number) {
        let changedShareholders = this.changedShareholders(index) as Shareholder[]
        changedShareholders.push({
            "id" : "",
            "name" : "", 
            "newYn" : true,
            "shareholderType": "",
            "stocks" : [{
                "id" : "",
                "name" : "",
                "number" : ""
            }]
        })
    }
    changedShareholdersTotalLength(index : number) {
        let changedShareholders = this.changedShareholders(index) as Shareholder[]
        let length = 0;
        changedShareholders.forEach((changedShareholder) => {
            length += 1;
            changedShareholder.stocks.forEach(() => {
                length += 1;
            });
        })
        return length;
    }
    totalStockLength(index : number) {
        let changedShareholders = this.changedShareholders(index) as Shareholder[]
        let length = 0;
        changedShareholders.forEach((changedShareholder) => {
            changedShareholder.stocks.forEach(() => {
                length += 1;
            });
        })
        return length;
    }
}
export default new ShareholderListVm();