import guestModel from "@model/Guest/guestModel";
import guestService from "@service/Guest/guestService";

class SecurityCheckVm  {
    async load(token: string) {
        await guestService.load(token);
    }
    get pageType() {
        return guestModel.pageType;
    }
    get status() {
        return guestModel.status;
    }
}

export default new SecurityCheckVm();