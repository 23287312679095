import React from 'react';
import formDataToJson from '@common/module/submit';
import businessRegistrationService from '@service/Corp/BusinessRegistration/businessRegistrationService';
import corpModel from '@model/Corp/corpModel';
import { observable } from 'mobx';
import { _alert } from "@model/dialogModel";

interface State {
    bankName: string | null;
    accountNumber: string;
    accountUsage?: string;
    bankBoxOpenYn: boolean;
}

class AddAccountVm {
    state: State = observable({
        bankName: '',
        accountNumber: '',
        accountUsage: '',
        bankBoxOpenYn: false
    })
    changeSetStateDate(bankName: string, accountNumber: string, accountUsage ?: string) {
        this.state.bankName = bankName;
        this.state.accountNumber = accountNumber
        this.state.accountUsage = accountUsage
    }
    async submit(e: React.FormEvent<HTMLFormElement>, state?: 'Change', bankbookId?: number) {
        e.preventDefault();
        if(formDataToJson(e.target).error) {
            return
        } else {
            let param = new FormData(e.currentTarget);

            const regExp = /\[\d+\]/g;
            let _key: {
                key: string;
                value: FormDataEntryValue | string;
            }[] = [];
            for(let [key, value] of param) {
                _key.push({ key: key.replace(regExp, ''), value: value });
            }
            
            let fileObject = _key.find(item => item.key === "file");

            try{
                if(state === 'Change' && bankbookId) {
                    if(fileObject) {
                        if(fileObject?.value instanceof File) {
                            if(!fileObject?.value.name) {
                                param.delete('file')
                            }
                        }
                    }
                    await businessRegistrationService.changeBankbook(param, bankbookId);
                } else {
                    
                    if(fileObject) {
                        if(fileObject.value instanceof File) {
                            if(!fileObject.value.name) {
                                await _alert('통장사본을 업로드하세요.');
                                return;
                            }
                        }
                    } else {
                        await _alert('통장사본을 업로드하세요.');
                        return;
                    }
                    await businessRegistrationService.addBankbook(param);
                }
            } catch(err) {

            }
        }
    }
    get bankList() { //@todo: 영수님 db마이그레이션 후, 백엔드에서 bankList 받아오기
        return ["신한은행", "국민은행", "기업은행", "농협은행", "우리은행", "하나은행", "산업은행", "수협은행", "신협은행", "부산은행", "전북은행", "광주은행", "대구은행", "새마을금고", "SC은행", "경남은행", "한국씨티은행", "우체국", "카카오뱅크", "토스뱅크", "케이뱅크", "SBI저축은행", "산림조합", "제주은행"];
    }
    chooseBtn() {
        this.state.bankBoxOpenYn = !this.state.bankBoxOpenYn;
    }
    get companyName() {
        const company = corpModel.company;
        let companyName = "";   
        
        if(company.prefixedYn) {
            if(company.corpType === '주식회사') {
                companyName = `(주)${company.companyName}`;
            } else if(company.corpType === '유한회사' || company.corpType === '유한책임회사') {
                companyName = `(유)${company.companyName}`;
            }
        } else {
            if(company.corpType === '주식회사') {
                companyName = `${company.companyName}(주)`;
            } else if(company.corpType === '유한회사' || company.corpType === '유한책임회사') {
                companyName = `${company.companyName}(유)`;
            }
        }

        return companyName;
    }
    setBankName(e: React.ChangeEvent<HTMLInputElement>) {
        this.state.bankName = e.target.value;
        this.state.bankBoxOpenYn = false;
    }
    setAccountNumber(e: React.ChangeEvent<HTMLInputElement>) {
        let accountNumber = e.target.value.replace(/[^0-9-]/g, '');
        this.state.accountNumber = accountNumber;
    }
    setAccountUsage(e: React.ChangeEvent<HTMLInputElement>) {
        this.state.accountUsage = e.target.value;
    }
}

export default AddAccountVm;