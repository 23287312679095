import authService from '@service/authService';
import { observable } from "mobx";
import userSettingService from '@service/userSettingService';
import userSettingModel from '@model/userSettingModel';
import { _alert, _confirm } from "@model/dialogModel";
import formDataToJson from '@common/module/submit';
import portalModel from '@model/portalModel';
import MarketingTermsModal from './MarketingTermsModal/MarketingTermsModal';
import regExp from '@common/module/regExp';
import { onlyNumber } from '@common/module/replaceNumber';

class UserSettingVm {
    state : {
        activeTab : string,
        password : string,
        newPassword : string,
        newPasswordCheck : string
    }
    constructor(activeTab = '이메일') {
        this.state = observable({
            activeTab : activeTab,
            password: '',
            newPassword: '',
            newPasswordCheck: ''
        });
    }
    get tabList() {
        // return ['이메일', '비밀번호', '회원정보', '약관동의', '회원탈퇴'];
        return ['이메일', '비밀번호', '회원정보', '약관동의'];
    }
    logout() {
        portalModel.content = null;
        authService.logout();
    }
    changeTab(tab : string) {
        this.state.activeTab = tab;
    }
    load() {
        userSettingService.load();
    }
    get userSetData() {
        return userSettingModel.userSetData;
    }
    async changeEmail(email : string) {
        await userSettingService.sendChangeEmail();
        portalModel.content = null;
        await _alert(`${email}로 변경이메일이 발송되었습니다. 이메일 변경 후 다시 로그인해주세요.`);
        authService.logout();
    }
    async passwordChangeSubmit(e:React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if(formDataToJson(e.target).error) {
            return
        } else {
            let param = formDataToJson(e.target).data;
            await userSettingService.changePassword(param)
            portalModel.content = null;
            await _alert('비밀번호가 변경되었습니다.', '보안정책상 다시 로그인 해주세요.');
            authService.logout();
        }
    }
    async nameChangeSubmit(e:React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if(formDataToJson(e.target).error) {
            return
        } else {
            let param = formDataToJson(e.target).data;
            await userSettingService.changeName(param)
            await _alert('이름이 변경되었습니다.');
        }
    }
    async phoneNumberChangeSubmit(e:React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if(formDataToJson(e.target).error) {
            return
        } else {
            let param = formDataToJson(e.target).data as {
                phoneNumber : string
            };
            //전화번호 010-1234-1234 or 01012341234 로 넘어온 경우 1012341234 가 될 수 있도록 처리
            const deleteDash = /-/gi;
            param.phoneNumber = param.phoneNumber.replace(deleteDash, "");
            if(param.phoneNumber.charAt(0) === "0") {
                param.phoneNumber = param.phoneNumber.slice(1);
            }
            await userSettingService.changePhoneNumber(param)
            await _alert('전화번호가 변경되었습니다.');
        }
    }
    setPassword(e:React.ChangeEvent<HTMLInputElement>) {
        this.state.password = regExp.deleteSpace(e.target.value);
    }
    setNewPassword(e:React.ChangeEvent<HTMLInputElement>) {
        this.state.newPassword = regExp.deleteSpace(e.target.value);
    }
    setNewPasswordCheck(e:React.ChangeEvent<HTMLInputElement>) {
        this.state.newPasswordCheck = regExp.deleteSpace(e.target.value);
    }
    isSame() {
        if(this.state.newPassword === this.state.newPasswordCheck) {
            return true;
        } else {
            return false;
        }
    }
    changeName(e:React.ChangeEvent<HTMLInputElement>) {
        userSettingModel.userSetData.name = e.target.value;
    }
    changePhoneNumber(e:React.ChangeEvent<HTMLInputElement>) {
        userSettingModel.userSetData.phoneNumber = regExp.deleteSpace(String(onlyNumber(e.target.value)));
    }
    changeCountryCode(e:React.ChangeEvent<HTMLSelectElement>) {
        userSettingModel.userSetData.countryCode = e.target.value;
    }
    async changeMarketingAcceptYn(e:React.ChangeEvent<HTMLInputElement>) {
        userSettingModel.userSetData.marketingAcceptYn = e.target.checked 
        if(e.target.checked === false) {
            if(await _confirm('법인운영에 유용한 뉴스레터 등 프로모션 정보 수신 동의를 철회 하시겠습니까?')) {
                userSettingModel.userSetData.marketingAcceptYn = false;
                userSettingService.changeMarketing(false);
            }else{
                userSettingModel.userSetData.marketingAcceptYn = true;
            }
        }else{
            userSettingService.changeMarketing(true);
            await _alert('법인운영에 유용한 뉴스레터 등 프로모션 정보 수신 동의 완료되었습니다.', '- 이메일 수신 동의 완료\n- SMS, 카톡 동의 완료');
        }
    }
    async changeMarketingAcceptEmailYn(e:React.ChangeEvent<HTMLInputElement>) {
        userSettingModel.userSetData.marketingAcceptEmailYn = e.target.checked
        userSettingService.changeMarketingDetail();
        if(e.target.checked === true) {
            await _alert('법인운영에 유용한 뉴스레터 등 프로모션 정보 수신 동의 완료되었습니다.', '- 이메일 수신 동의 완료');
        }
    }
    async changeMarketingAcceptSmsYn(e:React.ChangeEvent<HTMLInputElement>) {
        userSettingModel.userSetData.marketingAcceptSmsYn = e.target.checked
        userSettingService.changeMarketingDetail();
        if(e.target.checked === true) {
            await _alert('법인운영에 유용한 뉴스레터 등 프로모션 정보 수신 동의 완료되었습니다.', '- SMS, 카톡 동의 완료');
        }
    }
    marketingTermContentModal() {
        portalModel.title = '법인운영에 유용한 뉴스레터 등 프로모션 정보 수신 동의';
        portalModel.content = <MarketingTermsModal content={userSettingModel.userSetData.marketingTermContent}/>
    }
}
export default UserSettingVm;