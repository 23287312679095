import { useObserver } from 'mobx-react';
import type { I소집통지_온라인 } from '@src/model/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalModel.interface';
import vm from './OnlineMusterNoticeVm';
import bst from '@pages/Corp/Agenda/Step/FinalCheck/BeforeFinal/BeforeFinal.module.scss';
import st from './OnlineMusterNotice.module.scss';
import clsx from 'clsx';
import Table from '@standby/common-ui/components/atoms/Table/Table';
import ToolTip from '@standby/common-ui/components/molecules/ToolTip/ToolTip';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import QuestionTooltip from '@standby/common-ui/components/molecules/QuestionTooltip/QuestionTooltip';
import { addCommas } from '@common/module/replaceNumber';

interface OnlineMusterNoticeProps {
    index: number;
    info: I소집통지_온라인
    agendaProgressId: string;
}

function OnlineMusterNotice(props: OnlineMusterNoticeProps) {
    return useObserver(() => (
        <>
            <div className={bst.row}>
                <div className={clsx(bst.title, bst.section2)}>{props.index+1}. 소집통지 <span className={bst.light}>(온라인)</span></div>
                <div className={bst.content}>
                    <div className={bst.contentTitleBox}>온라인으로 소집통지를 발송하세요. <span className={bst.light}>({props.info.date} 발송)</span></div>
                    <QuestionTooltip title="주주총회 소집통지 안내" style={{width:'460px'}}>
                        <div className={bst.tooltipContent}>
                            <div className={bst.qna}>
                                <div className={bst.qnaTitle}>주주총회 열 때마다 10~14일 전에 소집통지를 발송하고, 근거를 보관하세요.</div>
                                <div className={bst.qnaContent}>
                                    <ul>
                                        <li>정관에 따라 소집통지를 보낸 후 10~14일이 지나야 주주총회를 열 수 있어요. 단 통지 당일은 일자계산에서 제외되어요.</li>
                                        <li>소집통지를 발송한 증거를 잘 보관하세요. 스탠바이를 통해 온라인 발송을 하면, 발송된 근거가 모두 자동보관되어요!</li>
                                    </ul>
                                </div>
                            </div>
                            <div className={bst.qna}>
                                <div className={bst.qnaTitle}>소집통지는 우편 발송이 원칙이고, 주주 동의를 받으면 온라인 발송도 가능해요.</div>
                                <div className={bst.qnaContent}>
                                    <ul>
                                        <li>자본금 10억 원 미만의 회사는 주주가 동의하면, 우편이 아니라 온라인으로도 소집통지를 보낼 수가 있어요.</li>
                                        <li>다만 주주의 동의를 받지 않고 온라인으로 소집통지를 보내면, 통지의 효과가 없을 수 있으니 주의가 필요해요.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </QuestionTooltip>
                    <Table className={clsx(bst.table, st.table)}>
                        <colgroup>
                            <col width="264" />
                            <col width="117" />
                            <col width="90" />
                            <col width="154" />
                            <col width="104" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>주주명</th>
                                <th>발송여부</th>
                                <th>발송일자</th>
                                <th>주식종류</th>
                                <th>주식수</th>
                                <th>지분율</th>
                            </tr>
                        </thead>
                        {props.info?.shareholders?.map((shareholder, idx) => {
                            if((idx < 6 || (idx > 5 && vm.state.moreViewYn))) {
                                return (
                                    <tbody key={idx}>
                                        {shareholder.stocks.map((stock, idx2) => {
                                            return (
                                                <tr key={idx2}>
                                                    {idx2 === 0 &&
                                                    <>
                                                        <td rowSpan={shareholder.stocks.length}><span>{idx+1}</span>{shareholder.name}</td>
                                                        <td rowSpan={shareholder.stocks.length}>
                                                            <div className={vm.status(shareholder.status)?._className}>
                                                                {vm.status(shareholder.status)?.text}
                                                                {shareholder.status === "발송실패" && 
                                                                    <ToolTip className={bst.tooltip}>
                                                                        일시적 오류가 발생하여 발송에 실패했어요. 이메일, 휴대번호가 정확한지 확인해보고, 재발송을 해주세요. 만약 재발송에도 실패한다면 오른쪽 아래 채팅버튼을 눌러 문의를 남겨주세요.
                                                                    </ToolTip>
                                                                }
                                                                {shareholder.status === "발송실패_알림톡" && 
                                                                    <ToolTip className={bst.tooltip}>
                                                                        일시적으로 오류가 발생하여 알림톡 발송에 실패했어요. (이메일은 발송성공) 휴대번호가 정확한지 확인해보고, 재발송을 해주세요. 만약 재발송에도 실패한다면 오른쪽 아래 채팅버튼을 눌러 문의를 남겨주세요.
                                                                    </ToolTip>
                                                                }
                                                                {shareholder.status === "발송실패_메일" && 
                                                                    <ToolTip className={bst.tooltip}>
                                                                        일시적 오류가 발생하여 메일 발송에 실패했어요. (알림톡은 발송성공) 이메일 주소가 정확한지 확인해보고, 재발송을 해주세요. 만약 재발송에도 실패한다면 오른쪽 아래 채팅버튼을 눌러 문의를 남겨주세요.
                                                                    </ToolTip>
                                                                }
                                                            </div>
                                                        </td>
                                                        <td rowSpan={shareholder.stocks.length}>{shareholder.completedDate}</td>
                                                    </>
                                                    }
                                                    <td className={bst.stockName}>{stock.name}</td>
                                                    <td className={bst.stockNumber}>{addCommas(stock.number)} 주</td>
                                                    <td className={bst.stockRate}>{stock.rate} %</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                )
                            }                        
                        })}
                    </Table>
                    {props.info?.shareholders?.length > 6 && 
                        <button type="button" className={bst.moreViewBtn} onClick={() => vm.moreView()}><span className={vm.state.moreViewYn ? bst.open : ""}>{vm.state.moreViewYn ? '접기' : '더보기'}</span></button>
                    }
                    <div className={bst.btnArea}>
                        <SquareBtn className={bst.btn} onClick={() => vm.openModal(props.agendaProgressId)}>주주총회 소집통지 자동발송</SquareBtn>
                    </div>
                </div>
            </div>
        </>
    ));
}
export default OnlineMusterNotice;