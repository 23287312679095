import { useObserver } from 'mobx-react';
import { Route, withRouter, useLocation } from "react-router-dom";
import { useEffect, useRef } from 'react';
import vm from './InformationInputVm';
import bst from '../Basic.module.scss';
import st from './InformationInput.module.scss';
import DatePicker from '@standby/common-ui/components/molecules/DatePicker/DatePicker';
import QuestionTooltip from '@standby/common-ui/components/molecules/QuestionTooltip/QuestionTooltip';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import RadioLabel from '@standby/common-ui/components/molecules/RadioLabel/RadioLabel';
import Select from '@standby/common-ui/components/atoms/Input/Select/Select';
import TextArea from '@standby/common-ui/components/atoms/Input/TextArea/TextArea';
import AddBtn from '@standby/common-ui/components/atoms/Button/AddBtn/AddBtn';
import FileUpload from '@commonComponents/FileUpload/FileUpload';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import FileBtn from '@standby/common-ui/components/atoms/Button/FileBtn/FileBtn';
import CloseBtn from '@standby/common-ui/components/atoms/Button/CloseBtn/CloseBtn';
import ErrorText from '@standby/common-ui/components/atoms/Text/ErrorText/ErrorText';
import Loading from '@commonComponents/Loading/Loading';
import clsx from 'clsx';
import ReactGA from 'react-ga4';

function InformationInput() {

    const formRef = useRef(null);

    useEffect(() => {
        vm.load();
        return () => {
            //마케팅 구글에널리틱스 관련 코드
            ReactGA.gtag('event', 'exit_on_business_registration_form');
        };
    }, []);   

    return useObserver(() => (
        <>
            <div className={bst.registraionRow}>
                {vm.state.loadingYn && <Loading />}
                <div className={bst.registrationTitle}><span className={bst.bold}>사업자등록</span>은<br/>
                    <span className={bst.bold}>스탠바이</span>와 <span className={bst.bold}>진평회계법인</span>이<br/>
                    담당해요.
                </div>
                <div className={bst.registrationContent}>
                    <div className={st.advertisement}>
                        <img src='/images/standby_example.jpg' className={st.img}/>
                        <div className={st.adBox}>
                            <div className={st.adTitle}>원스톱 법인관리 시스템, <span className={st.bold}>스탠바이 헬프미</span></div>
                            <div className={st.adSub}>등기, 세금, 주식, 스톡옵션을 원스톱 관리</div>
                            <div className={st.adSub}>정관 내용을 한 눈에 정리</div>
                        </div>
                    </div>
                    <div className={st.advertisement}>
                        <img src='/images/진평_example.png' className={st.img}/>
                        <div className={st.adBox}>
                            <div className={st.adTitle}><span className={st.light}>서울대 경영대 출신의 회계사들이 만든</span><br/><span className={st.lightBold}>스타트업 전문 회계법인</span></div>
                            <div className={st.adSub}>사업자등록 전과정 밀착 상담제공</div>
                            <div className={st.adSub}>절세에 특화된 상담 제공</div>
                            <img src='/images/logo_진평회계법인.svg' className={st.jinpyeongLogoImg}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={bst.registraionRow}>
                <div className={bst.registrationTitle}>
                    <span className={bst.bold}>사업자등록 정보입력</span>
                    <div className={bst.registrationSubTitle}>입력을 빨리 마치면,<br/>사업자등록이 빨라져요.</div>
                </div>
                <div className={bst.registrationContent}>
                    <form className={st.form} onSubmit={(e) => vm.submit(e)} ref={formRef}>
                        <div className={st.contentTitle}><span className={st.bold}>{vm.companyName}</span>의 사업자등록 정보</div>
                        <div className={st.contentBox}>
                            <div className={st.title}>
                                1. 사업개시일을 입력하세요.
                                <QuestionTooltip title="사업개시일 입력 팁" className={st.tooltip}>
                                    <div className={st.tooltipContent}>
                                        <div className={st.tooltipBox}>
                                            <div className={st.tooltipTitle}>1. {vm.companyName}의 설립일({vm.establishedAt})과 같은 날을 추천드려요!</div>
                                        </div>
                                        <div className={st.tooltipBox}>
                                            <div className={st.tooltipTitle}>2. 사업개시일로부터 20일 내에 사업자등록을 꼭 마쳐야 해요!</div>
                                        </div>
                                    </div>
                                </QuestionTooltip>    
                            </div>
                            <div className={st.inputRow}>
                                <DatePicker className={st.datepicker} name="dateOfBusinessCommencement" selected={vm.dateOfBusinessCommencement} onChange={(e) => vm.setDateOfBusinessCommencement(e)}/>
                                <Error name="dateOfBusinessCommencement" value={vm.dateOfBusinessCommencement} errorCase={{
                                    required:'사업개시일을 입력하세요.',
                                    validate: {
                                        func: vm.checkDateOfBusinessCommencement(),
                                        message: '신설법인의 사업개시일은 설립일 또는 그 후의 날로 선택할 수 있어요.'
                                    }
                                }}/>
                            </div>
                        </div>
                        <div className={st.contentBox}>
                            <div className={st.title}>
                                2. 대표님의 신분증 앞뒤면을 업로드하세요. <span className={st.ex}>(세무서 제출용)</span>
                            </div>
                            <div className={st.inputRow}>
                                {vm.representatives.map((representative, idx) => {
                                    return (
                                        <div className={st.fileUploadBox} key={idx}>  
                                            {vm.representatives.length !== 1 && <div className={st.fileExplain}>[{representative.name} 대표님 신분증 앞뒷면 업로드]</div>}
                                            <input type="hidden" name={`representatives[${idx}].id`} value={representative.id}/>
                                            <FileUpload explain="대표님 본인의 신분증이 필요해요! (대주주X, 실무자X)" attachmentFilesModel={representative.representativeIDFiles} name={`representatives[${idx}].uploadFiles`} deleteFileName={`representatives[${idx}].deleteFileIds`} />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className={st.contentBox}>
                            <div className={st.title}>
                                3. 법인 명의의 임대차계약서를 업로드하세요.
                                <QuestionTooltip title="임대차계약서 준비방법 & 양식 다운로드" className={st.tooltip}>
                                    <div className={st.tooltipContent}>
                                        <div className={st.cssn}>{vm.companyName}의 법인등록번호 : {vm.cssn}</div>
                                        <div className={st.tooltipBox}>
                                            <div className={st.tooltipTitle}>1. 임대차계약서는 회사 명의로 작성하세요.</div>
                                            <div className={st.tooltipExplain}>대표님 개인 명의라면 재작성이 필요해요.</div>
                                        </div>
                                        <div className={st.tooltipBox}>
                                            <div className={st.tooltipTitle}>2. 법인등록번호를 꼭 써주세요.</div>
                                            <div className={st.tooltipExplain}>법인등록번호가 없으면 사업자등록이 거절돼요.</div>
                                        </div>
                                        <div className={st.tooltipBox}>
                                            <div className={st.tooltipTitle}>3. 양식이 없다면 다운받으세요!</div>
                                            <div className={st.tooltipExplain}>
                                                <a href={`${process.env.REACT_APP_S3URL}/accountingFirm/jinpeong/%EC%9E%84%EB%8C%80%EC%B0%A8%EA%B3%84%EC%95%BD%EC%84%9C_4%EC%A2%85.zip`} download>
                                                    <FileBtn className={st.fileBtn} style={{width:'202px'}}>임대차 계약서 4종 다운로드</FileBtn>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </QuestionTooltip>    
                            </div>
                            <div className={st.inputRow}>
                                <FileUpload explain="법인등록번호가 기재된 임대차계약서 필수!" attachmentFilesModel={vm.corporateLeaseAgreementFiles} name="corporateLeaseAgreementFiles.uploadFiles" deleteFileName="corporateLeaseAgreementFiles.deleteFileIds" />
                            </div>
                        </div>
                        {vm.representativeFamilyRelationships && vm.representativeFamilyRelationships.length !== 0 && 
                            <div className={st.contentBox}>
                                <div className={st.title} style={{paddingLeft:'19px'}}>
                                    <span className={st.num}>4.</span> 대표님과 {vm.text}{(vm.corpType === '유한회사' || vm.corpType === '유한책임회사') && '(=지분권자)'}들의 가족관계를 설명해주세요. <span className={st.ex}>(세무서 제출용)</span>
                                </div>
                                <div className={st.inputRow}>
                                    <table className={st.table}>
                                        <colgroup>
                                            <col width="92px" />
                                            <col />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>{vm.text}{(vm.corpType === '유한회사' || vm.corpType === '유한책임회사') && <span><br/>(=지분권자)</span>}</th>
                                                <th>
                                                    {vm.representatives.map((representative, idx) => (
                                                        <span key={idx}>{idx !== 0 && ', '}{representative.name}{idx === vm.representatives.length-1 && ' '}</span>
                                                    ))}
                                                    대표님과 가족관계
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {vm.representativeFamilyRelationships.map((relationship, idx) => (
                                                <tr key={idx}>
                                                    <td>
                                                        <input type="hidden" name={`representativeFamilyRelationships[${idx}].id`} value={relationship.id}/>
                                                        <input type="hidden" name={`representativeFamilyRelationships[${idx}].name`} value={relationship.name}/>
                                                        {relationship.name}
                                                    </td>
                                                    <td>
                                                        <InputText fullSize size="small" name={`representativeFamilyRelationships[${idx}].relationship`} value={relationship.relationship} placeholder="ex) 홍길동 대표의 자녀, 가족관계 없음" onChange={(e) => vm.setRepresentativeFamilyRelationships(e, idx)} errorText={<Error name={`representativeFamilyRelationships[${idx}].relationship`} value={relationship.relationship} errorCase={{
                                                            required: '가족관계를 설명해주세요.'
                                                        }}/>}/>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                        <div className={st.contentBox}>
                            <div className={st.title}>
                                {!vm.representativeFamilyRelationships ? '4' : '5'}. 사업자등록을 할 업종을 입력하세요.
                                <div className={st.openTooltipBox}>
                                    <div className={st.tooltipBoxTitle}>업종선택 팁</div>
                                    <div className={st.title}>1. 당장 시작할 업종만 입력하세요.</div>
                                    <div className={st.explain}>
                                        사업자등록을 할 때, 업종별로 인허가증이 필요한 경우가 많아요. 나중에 할 사업은 실제로 시작할 때 사업자등록증에 넣으면 되어요. (비용X)
                                    </div>
                                    <div className={st.title}>2. 구체적으로 설명을 해주세요!</div>
                                    <div className={st.explain}>회계법인 전문 상담직원이 설명을 확인하고 가장 적합한 업종코드를 골라드릴 거에요.</div>
                                    <div className={st.title}>3. 희망하는 업종코드가 있다면 직접 입력하셔도 되어요.</div>
                                    <div className={st.explain}>
                                        <a className={st.aLink} href="https://teht.hometax.go.kr/websquare/websquare.html?w2xPath=/ui/rn/z/UTERNAAZ61.xml" target="_blank">업종코드 직접 검색하기</a><br/>
                                        위의 링크를 클릭해 업종 칸에 희망업종을 입력 후, 검색버튼을 누르시면, 업종코드를 직접 고를 수 있어요.
                                    </div>
                                </div>
                            </div>
                            <div className={st.inputRow}>
                                {vm.typeOfBusinesses.map((typeOfBusiness, idx) => (
                                    <div key={idx} className={st.businessBox}>
                                        <div className={st.businessTitle}>{idx+1}순위 사업</div>
                                        {idx !== 0 && <CloseBtn className={st.closeBtn} onClick={() => vm.deleteBusiness(idx)} style={{width:'16px', height:'16px'}}/>}
                                        <input type="hidden" name={`typeOfBusinesses[${idx}].id`} value={typeOfBusiness.id} />
                                        <Select name={`typeOfBusinesses[${idx}].businessPurpose`} fullSize className={st.select} value={typeOfBusiness.businessPurpose} onChange={(e) => vm.setBusinessPurpose(e, idx)} errorText={<Error name={`typeOfBusinesses[${idx}].businessPurpose`} value={typeOfBusiness.businessPurpose} errorCase={{
                                            required: '등기부상 관련 목적을 선택하세요.'
                                        }}/>}>
                                            <option value="">등기부상 관련 목적 선택</option>
                                            {vm.selectableBusinessPurposes.map((purpose, idx2) => (
                                                <option value={purpose} key={idx2}>{purpose}</option>
                                            ))}           
                                        </Select>
                                        <TextArea type="text" name={`typeOfBusinesses[${idx}].description`} onChange={(e) => vm.setDescription(e, idx)} value={typeOfBusiness.description} style={{width:"460px", minHeight:"90px", resize:"none"}} explain="업종 설명*" placeholder="예시) 옷가게를 시작하는데, 온라인으로도 바로 판매할 거에요." errorText={<Error name={`typeOfBusinesses[${idx}].description`} value={typeOfBusiness.description} errorCase={{
                                            required: '업종 설명을 입력하세요.'
                                        }}/>}/>
                                    </div>
                                ))}
                                <div className={st.btnArea}>
                                    <AddBtn className={st.addBtn} onClick={() => vm.addBusiness()}>업종 추가하기</AddBtn>                  
                                </div>
                            </div>
                        </div>
                        <div className={st.contentBox}>
                            <div className={st.title}>
                                {!vm.representativeFamilyRelationships ? '5' : '6'}. (필요시) 인허가증을 업로드하세요.
                                <QuestionTooltip title="인허가증이 필요한 경우" className={st.tooltip}>
                                    <div className={st.tooltipContent}>
                                        <ul>
                                            <li>요식업, 대부업, 여행업, 식품관련업종, 금융업, 학원업, 중고차 판매업 등 일부 업종의 경우 유관기관의 인허가증이 필요해요.</li>
                                            <li>위 업종에 해당한다면, 인허가증을 업로드해주세요. 해당하지 않는다면 업로드하지 않아도 되어요.</li>
                                            <li>인허가증 관련 문의사항이 있으면 채팅상담으로 문의주세요!</li>
                                        </ul>
                                    </div>
                                </QuestionTooltip>   
                            </div>
                            <div className={st.inputRow}>
                                <FileUpload attachmentFilesModel={vm.permitFiles} name="permitFiles.uploadFiles" deleteFileName="permitFiles.deleteFileIds" />
                            </div>
                        </div>
                        <div className={st.contentBox}>
                            <div className={st.title}>{!vm.representativeFamilyRelationships ? '6' : '7'}. 보다 빠른 사업자등록을 위해, 홈택스 수임동의 방식으로 진행할까요?</div>
                            <div className={st.informationRow}>
                                <QuestionTooltip title="홈택스 수임동의 방식의 장단점" className={st.tooltip}>
                                    <div className={st.tooltipContent}>
                                        <div className={st.tooltipBox}>
                                            <div className={st.tooltipTitle}>1. 홈택스 수임동의 방식의 장점</div>
                                            <div className={clsx(st.tooltipExplain, st.hyphen)}>
                                                온라인으로 신청하기 때문에 오프라인 접수보다 빠르게 처리가 가능해요.
                                            </div>
                                        </div>
                                        <div className={st.tooltipBox}>
                                            <div className={st.tooltipTitle}>2. 홈택스 수임동의 방식의 단점</div>
                                            <div className={clsx(st.tooltipExplain, st.hyphen)}>
                                                나중에 법인 대표님이 홈택스에 공동인증서 또는 금융인증서 로그인 후 수임동의 버튼을 눌러주셔야 해요.<br/><a className={st.aLink} href="https://standby.kr/blog/article/홈택스수임동의/" target="_blank" style={{marginTop:"4px"}}>홈텍스 수임등록을 통해 사업자등록을 빨리 진행하는 방법</a>
                                            </div>
                                            <div className={clsx(st.tooltipExplain, st.hyphen)}>
                                                대표님이 개인사업자 세무대리인인 있는 경우, 홈택스 수임동의를 하면 기존 연결이 해지될 수 있어요. 이 경우에는 시간이 더 걸리더라도 오프라인 진행을 추천드려요.
                                            </div>
                                        </div>
                                    </div>
                                </QuestionTooltip>    
                                <RadioLabel className={st.radioLabel} name="hometaxDelegationAcceptanceYn" value={true} checked={vm.hometaxDelegationAcceptanceYn === true} onChange={(e) => vm.setHometaxDelegationAcceptanceYn(e)}>네 온라인 홈택스 수임동의를 할게요. (통상 영업일 2~3일 소요)</RadioLabel>
                                <RadioLabel name="hometaxDelegationAcceptanceYn" value={false} checked={vm.hometaxDelegationAcceptanceYn === false} onChange={(e) => vm.setHometaxDelegationAcceptanceYn(e)}>아니오 오프라인 접수를 원해요. (통상 영업일 5~6일 소요)</RadioLabel>
                                <div>
                                    <Error name="hometaxDelegationAcceptanceYn" value={vm.hometaxDelegationAcceptanceYn} errorCase={{
                                        required: '홈택스 수임동의 진행 여부를 선택하세요.'
                                    }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={st.contentBox}>
                            <div className={st.title}>{!vm.representativeFamilyRelationships ? '7' : '8'}. {vm.freeMonth}개월 무료 '법인기장 서비스'를 신청하나요?</div>
                            <div className={st.informationRow}>
                                <div className={st.explainBox}>
                                    <div className={st.serviceExplain}>
                                        <div className={st.title}>법인기장 서비스 간단 설명</div>
                                        <div className={st.serviceExplainBox}>
                                            <div className={st.serviceBox}>
                                                <div className={clsx(st.serviceBoxTitle, st.calculator)}><span className={st.bold}>법인기장</span>이란?</div>
                                                <div className={st.serviceBoxContent}>입출금, 매출 등 <span className={st.bold}>모든 내역</span>을 <span className={st.bold}>복식부기</span> 방식으로<br/>기록하는 것!</div>
                                            </div>
                                            <div className={st.serviceBox}>
                                                <div className={clsx(st.serviceBoxTitle, st.document)}>법인기장 <span className={st.bold}>필요성</span>?</div>
                                                <div className={st.serviceBoxContent}>법인은 복식부기 <span className={st.bold}>위반시<br/>세금폭탄</span> 발생</div>
                                            </div>
                                            <div className={st.serviceBox}>
                                                <div className={clsx(st.serviceBoxTitle, st.handshake)}><span className={st.bold}>효율적</span> 해결법?</div>
                                                <div className={st.serviceBoxContent}>성장에 집중하고<br/><span className={st.bold}>관리는 회계법인</span>에<br/>맡기는 것이 훨씬 <span className={st.bold}>효율적!</span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={st.serviceExplain}>
                                        <div className={st.title}>법인기장 서비스 혜택</div>
                                        <div className={st.serviceBenefitBox}>
                                            <div className={clsx(st.benefitBox, st.helpme)}>
                                                <span className={st.bold}>헬프미</span> 설립 Only
                                            </div>
                                            <div className={clsx(st.benefitBox, st.emptyCalendar)}>
                                                <span className={st.freeMonth}>{vm.freeMonth}</span>
                                                <span className={st.bold}>{vm.freeMonth}개월</span> 무료
                                            </div>
                                            <div className={clsx(st.benefitBox, st.calendar)}>
                                                <span className={st.bold}>언제든</span> 해지 가능
                                            </div>
                                        </div>
                                    </div>
                                    <div className={st.serviceExplain}>
                                        <div className={st.title}>서비스 신청기한</div>
                                        <div className={st.serviceDeadline}>
                                            무료 기장서비스는 헬프미 고객을 위한 특별한 혜택이기 때문에, <span className={st.bold}>법인설립일로부터 10일간</span>만 신청이 가능해요. 양해부탁드려요.
                                        </div>
                                    </div>
                                    <div className={st.jinpyeongExplain}>with 서울대 경영대 출신 회계사들이 만든 <img src='/images/logo_진평회계법인.svg' className={st.logo}/></div>
                                </div>
                                <QuestionTooltip title={`무료 ${vm.freeMonth}개월 기장 혜택 더 알아보기`} className={st.tooltip}>
                                    <div className={st.tooltipContent}>
                                        <div className={st.tooltipBox}>
                                            <div className={st.tooltipTitle}>1. 헬프미 설립고객들에게 제공되는 특별한 혜택입니다.</div>
                                            <div className={st.tooltipExplain}>
                                                헬프미를 통한 설립고객들에게만 제공되는 혜택이에요. 아쉽지만 그 전에 설립을 마쳤거나, 다른 경로로 기장계약을 체결한 분들에게는 혜택이 제공되지 않아요.
                                            </div>
                                        </div>
                                        <div className={st.tooltipBox}>
                                            <div className={st.tooltipTitle}>2. {vm.freeMonth}개월 무료라도, 모든 기장 서비스가 다 제공되어요.</div>
                                            <div className={st.tooltipExplain}>
                                                무료라고 해서 대충 제공되는 기장 서비스가 아니에요. 초기 세무상담, 절세전략 상담, 4대보험신고, 원천세신고 등 모든 기장 서비스가 다 제공되어요.
                                            </div>
                                        </div>
                                        <div className={st.tooltipBox}>
                                            <div className={st.tooltipTitle}>3. 언제든지 해지가 가능해요.</div>
                                            <div className={st.tooltipExplain}>
                                                순수한 무료 혜택이에요. 언제든 해지가 가능하고, 위약금은 0원이에요. 1년 이용 조건이 강제로 붙은 무료혜택과는 완전히 달라요!
                                            </div>
                                        </div>
                                        <div className={st.tooltipBox}>
                                            <div className={st.tooltipTitle}>4. 서울대 경영대 출신의 회계사들이 담당해요.</div>
                                            <div className={st.tooltipExplain}>
                                                서울대 경영대 출신, 대형 회계법인(삼일, 삼정 회계법인) 출신 회계사들이 담당하는 기장 서비스에요. 스타트업 전문 컨설팅 한번 받아보세요!
                                                <br/>
                                                <a className={st.aLink} href="https://jinpyung.com" target="_blank" style={{marginTop:"4px"}}>진평회계법인 소개보기</a>
                                                {/* <FileBtn className={st.fileBtn} style={{width:'214px'}}>진평회계법인 안내서 다운로드</FileBtn> */}
                                            </div>
                                        </div>
                                    </div>
                                </QuestionTooltip>    
                                <RadioLabel className={st.radioLabel} name="bookkeepingServiceApplyYn" value={true} checked={vm.bookkeepingServiceApplyYn === true} onChange={(e) => vm.setBookkeepingServiceApplyYn(e)}>
                                    네 신청합니다.
                                    <div className={st.radioExplain}>진평에서 안내전화를 드려요. 전화상담 후 최종결정을 하면 되어요.</div>
                                </RadioLabel>
                                <RadioLabel name="bookkeepingServiceApplyYn" value={false} checked={vm.bookkeepingServiceApplyYn === false} onChange={(e) => vm.setBookkeepingServiceApplyYn(e)}>아니오 신청하지 않습니다.</RadioLabel>
                                <div>
                                    <Error name="bookkeepingServiceApplyYn" value={vm.bookkeepingServiceApplyYn} errorCase={{
                                        required: `무료 ${vm.freeMonth}개월 기장 혜택 신청 여부를 선택하세요.`
                                    }}
                                    />
                                </div>
                            </div>
                            
                        </div>
                        <div className={st.btnArea}>
                            <SquareBtn type="submit" className={st.submitBtn}>사업자등록정보 제출하기</SquareBtn>
                        </div>
                        <div className={st.stickyArea} style={{height: `${formRef?.current?.offsetHeight}`+'px'}}>
                            <button type="button" className={st.tempBtn} onClick={() => vm.tempSubmit(formRef.current)}>임시저장하기</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    ));
}

export default InformationInput;