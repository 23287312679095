import { useObserver } from 'mobx-react';
import vm from './Step1Vm';
import RadioLabel from '@standby/common-ui/components/molecules/RadioLabel/RadioLabel';
import clsx from 'clsx';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import st from '../BoardOfDirectors.module.scss';
import ToolTip from '@standby/common-ui/components/molecules/ToolTip/ToolTip';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';

interface Step1Props {
    minutesId : string,
    companyId : string,
    agendaProgressId : string
}

function Step1(props : Step1Props) {
    return useObserver(() => (
        <form className={st.modalContent} onSubmit={(e) => vm.submit(e, props.companyId, props.agendaProgressId)}>
            <div className={st.question}>
                <div className={st.title}>
                    이사회 의장을 선택하세요.
                    <div className={st.titleSub}>
                        <div className={st.ex}>대표님 중 한 분을 의장으로 선택하세요.</div>
                        <div className={st.ex} style={{marginTop:"2px"}}>대표님이 아닌 분이 의장이 되려면 추가적인 이사회 결의를 해야 하는 경우가 많아요.</div>
                    </div>
                </div>
                <div className={st.content}>
                    {vm.step1.selectableChairmen.map((selectableChairmen, index) => (
                        <RadioLabel key={index} name="chairmanId" value={selectableChairmen.id} checked={selectableChairmen.id === vm.step1.chairmanId} onChange={(e) => vm.selectableChairmen(e)} className={clsx(st.radio, selectableChairmen.position.includes("대표") ? st.recommend : null)}>{selectableChairmen.name} {selectableChairmen.position}</RadioLabel>
                    ))}
                    <div>
                        <Error name="chairmanId" value={vm.step1.chairmanId} errorCase={{
                            required: '의장을 선택하세요.'
                        }}
                        />
                    </div>
                    <input type='hidden' value={props.minutesId} name="minutesId"/>
                </div>
            </div>
            <div className={st.question}>
                <div className={st.title}>임원의 출석 여부를 선택하세요.</div>
                <div className={st.content}>
                    <table className={clsx(st.table, st.executiveMembersTable)} style={{width:"460px"}}>
                        <colgroup>
                            <col width="77"/>
                            <col/>
                        </colgroup>
                        <thead>
                            <tr>
                                <th>
                                    <CheckBox checked={vm.executiveMembersAttendMeetingAll} className={st.checkAll} onChange={(e) => vm.setExecutiveMembersAttendMeetingAll(e)}>출석</CheckBox>
                                </th>
                                <th>이름</th>
                            </tr>
                        </thead>
                        <tbody>
                            {vm.step1.executives.map((executive, index) => (
                                <tr key={index}>
                                    <td>
                                        <CheckBox className={st.noneText} checked={executive.attendMeetingYn} name="attendMeetingExecutiveMembers" value={executive.id} onChange={(e) => vm.executiveMembersAttendMeeting(e, index)}/>
                                    </td>
                                    <td>
                                        <span className={st.index}>{index + 1}</span>
                                        <span className={st.name}>{executive.name}</span>
                                        <span className={st.position}>{executive.position}</span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className={st.tableSum} style={{width:"460px"}}>
                        {vm.attendMeetingPossibleYn ? 
                            <div className={clsx(st.attendMeetingTotal, st.possible)}>
                                <img src='/images/possible_icon.svg'/>충분
                            </div>
                            :
                            <div className={st.attendMeetingTotal}>
                                <img src='/images/impossible_icon.svg'/>부족
                            </div>
                        }
                    </div>
                    <div className={st.tooltipWrap} style={{width:"460px"}}>
                        <ToolTip upAndDown="down" leftOrRight="right" iconType="question" tooltipContentStyle={{width:'225px'}}>
                            이사를 선택하면, 상법에 맞는지 자동으로 계산해드려요!
                        </ToolTip>
                        <span className={st.tooltipText}>의사정족수 자동계산</span>
                    </div>
                </div>
            </div>
            <div className={st.btnArea}>
                <SquareBtn type="submit" className={st.contentSubmitBtn}>선택 완료</SquareBtn>
            </div>
        </form>
    ));
}
export default Step1;