import { runInAction } from "mobx";
import findIdModel from '@model/Auth/findId/findIdModel';
import formDataToJson from '@common/module/submit';
import authService from '@service/authService';
import regExp from '@common/module/regExp';
import { onlyNumber } from '@common/module/replaceNumber';

class viewModel {
    submit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if(formDataToJson(e.target).error) {
            return
        } else {
            let param = formDataToJson(e.target).data;
            authService.sendVerificationToken(param);
        }
    }
    get name() {
        return findIdModel.name;
    }
    get phoneNumber() {
        return findIdModel.phoneNumber;
    }
    get step() {
        return findIdModel.step;
    }
    setName(e: React.ChangeEvent<HTMLInputElement>) {
        runInAction(() => {
            findIdModel.name = e.target.value;
        })
    }
    setPhoneNumber(e: React.ChangeEvent<HTMLInputElement>) {
        runInAction(() => {
            findIdModel.phoneNumber = regExp.deleteSpace((onlyNumber(e.target.value)).toString());
        })
    }
    verificationToken() {
        authService.verificationToken();
    }
}

export default new viewModel();