import formDataToJson from '@common/module/submit';
import { addCommas, removeCommas, onlyNumber } from '@common/module/replaceNumber';
import adminSettingService from '@service/Corp/AdminSetting/adminSettingService';
import adminModel from '@model/Corp/AdminSetting/adminModel';
import { _alert, _confirm } from "@model/dialogModel";
import st from './ModalEdit.module.scss';

class ModalEditVm {
    get isGenerManager() {
        return adminModel.authority.authorityType === "GENERAL_MANAGER";
    }
    async submit(e, userId) {
        e.preventDefault();
        let value = formDataToJson(e.target).data.value;
        
        if(this.isGenerManager) {
            adminSettingService.editRole(userId, value)
        }else{
            return _alert('최고 관리자만 역할을 수정할 수 있어요.', '', '확인', <ul className={st.alertContent}><li>역할 수정이 필요하다면 최고 관리자에게 문의하세요.</li></ul>,);
        }
    }
}
export default new ModalEditVm();