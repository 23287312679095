import BoardOfDirectorsService from "@src/service/Corp/Agenda/Step/FinalCheck/BeforeFinal/BoardOfDirectors/BoardOfDirectorsService";
import BoardOfDirectorsModel from "@src/model/Corp/Agenda/Step/FinalCheck/BeforeFinal/BoardOfDirectors/BoardOfDirectorsModel";

class BoardOfDirectorsVm {
    load(companyId : string, agendaProgressId : string, minutesId : string) {
        BoardOfDirectorsService.load(companyId, agendaProgressId, minutesId);
    }
    get nowStep() {
        return BoardOfDirectorsModel.nowStep;
    }
}
export default new BoardOfDirectorsVm();