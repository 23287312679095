
import type {LoadGuidesRs, LoadCorporateTaxRs, SelectCorporateTaxRs} from './CorporateTaxService.interface';

import { downloadFileGet, get } from '@common/module/httpRequest';
import corporateTaxModel from '@model/Corp/CorporateTax/corporateTaxModel';
import corpModel from '@model/Corp/corpModel';
import { runInAction } from 'mobx';

class CorporateTaxService {
    async loadGuides() {
        const res = await get<LoadGuidesRs>(`/company/${corpModel.id}/corporateTax/home`);
        runInAction(() => {
            if(res.data.guides && res.data.guides.length !== 0) {
                corporateTaxModel.guides = res.data.guides.map((guide) => {
                    return {
                        title : guide?.title || "",
                        contents : guide?.contents || "",
                        url : guide?.url || "",
                    }
                })
            } else {
                corporateTaxModel.guides = [];
            }
        })
    }
    async loadCorporateTax() {
        let res = await get<LoadCorporateTaxRs>(`/company/${corpModel.id}/corporateTax`);
        runInAction(() => {
            if(res.data.taxFilingState?.selectableCorporateTaxes && res.data.taxFilingState?.selectableCorporateTaxes.length !== 0) {
                corporateTaxModel.taxFilingState.selectableCorporateTaxes = res.data.taxFilingState.selectableCorporateTaxes.map((item) => {
                    return {
                        id: item.id || -1,
                        title : item.title || ""
                    }
                })
            } else {
                corporateTaxModel.taxFilingState.selectableCorporateTaxes = [];
            }
            if(res.data.taxFilingState?.steps && res.data.taxFilingState?.steps.length !== 0) {
                corporateTaxModel.taxFilingState.steps = res.data.taxFilingState.steps.map((step) => {
                    return {
                        step: step.step || "",
                        startAt: step.startAt || ""
                    }
                }) 
            } else {
                corporateTaxModel.taxFilingState.steps = [];
            }
            corporateTaxModel.taxFilingState.faithfulnessTaxPayerYn = res.data.taxFilingState?.faithfulnessTaxPayerYn ?? null;
            corporateTaxModel.taxFilingState.settlementMonth = res.data.taxFilingState?.settlementMonth || "";
            corporateTaxModel.taxFilingState.settlementPeriod = res.data.taxFilingState?.settlementPeriod || {
                startAt: {
                    year: "",
                    month: "",
                    day: ""
                },
                endAt: {
                    year: "",
                    month: "",
                    day: ""
                }
            };
            corporateTaxModel.taxFilingState.taxFilingEndAt = res.data.taxFilingState?.taxFilingEndAt || {
                year: "",
                month: "",
                day: ""
            };
            corporateTaxModel.taxFilingPreparationData.deadline = res.data.taxFilingPreparationData?.deadline || {
                year: "",
                month: "",
                day: ""
            };
            corporateTaxModel.taxFilingPreparationData.emailToSubmit = res.data.taxFilingPreparationData?.emailToSubmit || "";
            if(res.data.corporateTaxes && res.data.corporateTaxes.length !== 0) {
                corporateTaxModel.corporateTaxes = res.data.corporateTaxes.map((item) => {
                    return {
                        title: item.title || "",
                        instalmentYn: item.instalmentYn ?? null,
                        nationalTax: item.nationalTax || {
                            finalTaxLiability: 0,
                            prePaidTax: 0,
                            additionalTaxPayable: 0,
                            taxPayable: 0,
                            specialTaxForRuralDevelopment: 0,
                            instalmentNationalTaxes: [],
                            dueDateForPayment: {
                                year: "",
                                month: "",
                                day: ""
                            },
                            taxRefundAmount: 0,
                            billFile: ""
                        },
                        localTax: item.localTax || {
                            taxAmount: 0,
                            taxRefundAmount: 0,
                            dueDateForPayment: {
                                year: "",
                                month: "",
                                day: ""
                            },
                            billFile: ""
                        },
                        statementOfTaxAdjustmentFile: item.statementOfTaxAdjustmentFile || "",
                        statementOfTaxAdjustmentThumbnailFile: item.statementOfTaxAdjustmentThumbnailFile || "",
                        financialStatementsConfirmationFile: item.financialStatementsConfirmationFile || "",
                        financialStatementsConfirmationThumbnailFile: item.financialStatementsConfirmationThumbnailFile || "",
                        etcFile: item.etcFile || "",
                    }
                })
            } else {
                corporateTaxModel.corporateTaxes = [];
            }
        })
    }
    async selectCorporateTax(corporateTaxId: number) {
        const res = await get<SelectCorporateTaxRs>(`/company/${corpModel.id}/corporateTax/${corporateTaxId}`);
        runInAction(() => {
            if(res.data.taxFilingState?.selectableCorporateTaxes && res.data.taxFilingState?.selectableCorporateTaxes.length !== 0) {
                corporateTaxModel.taxFilingState.selectableCorporateTaxes = res.data.taxFilingState.selectableCorporateTaxes.map((item) => {
                    return {
                        id: item.id || -1,
                        title : item.title || ""
                    }
                })
            } else {
                corporateTaxModel.taxFilingState.selectableCorporateTaxes = [];
            }
            if(res.data.taxFilingState?.steps && res.data.taxFilingState?.steps.length !== 0) {
                corporateTaxModel.taxFilingState.steps = res.data.taxFilingState.steps.map((step) => {
                    return {
                        step: step.step || "",
                        startAt: step.startAt || ""
                    }
                }) 
            } else {
                corporateTaxModel.taxFilingState.steps = [];
            }
            corporateTaxModel.taxFilingState.faithfulnessTaxPayerYn = res.data.taxFilingState?.faithfulnessTaxPayerYn ?? null;
            corporateTaxModel.taxFilingState.settlementMonth = res.data.taxFilingState?.settlementMonth || "";
            corporateTaxModel.taxFilingState.settlementPeriod = res.data.taxFilingState?.settlementPeriod || {
                startAt: {
                    year: "",
                    month: "",
                    day: ""
                },
                endAt: {
                    year: "",
                    month: "",
                    day: ""
                }
            };
            corporateTaxModel.taxFilingState.taxFilingEndAt = res.data.taxFilingState?.taxFilingEndAt || {
                year: "",
                month: "",
                day: ""
            };
            corporateTaxModel.taxFilingPreparationData.deadline = res.data.taxFilingPreparationData?.deadline || {
                year: "",
                month: "",
                day: ""
            };
            corporateTaxModel.taxFilingPreparationData.emailToSubmit = res.data.taxFilingPreparationData?.emailToSubmit || "";
        })
    }
    async fileDownload(downloadLink: string) {
        await downloadFileGet(downloadLink);
    }
}

export default new CorporateTaxService();