import formDataToJson from '@common/module/submit';
import { observable, runInAction, toJS } from 'mobx';
import portalModel from '@model/portalModel';

class OnGoingPopupVm {
    check() {
        runInAction(() => {
            portalModel.content = null;
        })
    }
}

export default new OnGoingPopupVm();