import { useObserver } from 'mobx-react';
import st from '../Agendas.module.scss';
import type {Agenda, IA액면금분할_합병} from '@model/AgendaDashboard/AgendaDashboardModel.interface';
import clsx from 'clsx';
import { addCommas } from '@src/common/module/replaceNumber';

interface Props {
    agendaIndex : number,
    agenda : Agenda<IA액면금분할_합병>,
    moreViewYn : boolean
}

function C액면금분할_합병(props : Props) {
    return useObserver(() => (
        <div className={clsx(st.agenda, props.moreViewYn ? st.opened : '')}>
            <div className={st.title}>
                <div className={st.number}>{props.agendaIndex + 1}</div>
                <div className={st.text}><span className={st.important}>액면금 변경</span></div>
                {props.agenda.decisionBody.map((decisionBody, index) => (
                    <div className={st.badge} key={index}>{decisionBody}</div>
                ))}
            </div>
            {props.moreViewYn && 
                <div className={st.info}>
                    <ul className={st.agendaList}>
                        <li>
                            액면금 : {addCommas(props.agenda.info.beforeParValue)} 원 → {addCommas(props.agenda.info.afterParValue)} 원
                        </li>
                    </ul>
                </div>
            }
        </div>
    ));
}
export default C액면금분할_합병;