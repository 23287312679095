import { runInAction, observable, toJS } from "mobx";
import businessRegistrationModel from '@model/Corp/BusinessRegistration/businessRegistrationModel';
import businessRegistrationService from '@service/Corp/BusinessRegistration/businessRegistrationService';
import corpModel from '@model/Corp/corpModel';

class OnlyFileVm {
    get corpId() {
        return corpModel.id;
    }
    get guides() {
        return businessRegistrationModel.guides;
    }
    get businessRegistrationFile() {
        return businessRegistrationModel.businessRegistrationFile;
    }
    downLoadUrl(url) {
        businessRegistrationService.downLoadUrl(url);
    }
}
export default new OnlyFileVm();