import formDataToJson from '@common/module/submit';
import corpModel from '@model/Corp/corpModel';
import vatModel from '@model/Corp/Vat/vatModel';
import VatService from '@service/Corp/Vat/VatService';
import { observable, runInAction, toJS } from 'mobx';
import { _alert, _confirm } from "@model/dialogModel";

class VatVm {
    constructor() {
        this.state = observable({
            vatId: '',
        })
    }
    async load() {
        this.state.vatId = vatModel.statusOfVatReporting?.vatList[0].id;
    }
    get vatList() {
        return vatModel.statusOfVatReporting?.vatList;
    }
    async setVatList(e) {
        this.state.vatId = e.target.value;
        await VatService.vat(this.state.vatId);
    }
    get reportingPeriod() { //납부기한
        return vatModel.statusOfVatReporting.reportingPeriod;
    }
    get steps() { //타임스탬프
        return vatModel.statusOfVatReporting.steps;
    }
    get nowStep() {
        return vatModel.statusOfVatReporting.steps[vatModel.statusOfVatReporting.steps.length-1]?.name;
    }
    get reportingExemptYn() {
        return vatModel.statusOfVatReporting?.reportingExemptYn;
    }
    get displayReportPeriodInfo() { //예정신고X_예정고지X 일 때, 안내 문구
        const vatReportPeriodType = vatModel.statusOfVatReporting.vatReportPeriodType;
        const year = Number(vatModel.statusOfVatReporting?.reportingPeriod?.year);
        switch(vatReportPeriodType) {
            case '_1기_예정':
                return  `${year}년 1기 예정신고는 안해도 되어요. ${year}년 7월에 1기 확정신고만 하면 되어요!`;
            case '_2기_예정':
                return `${year}년 2기 예정신고는 안해도 되어요. ${year+1}년 1월에 2기 확정신고만 하면 되어요!`;
        }
    }    
    get displayMaterial() { //신고 준비자료 표시 여부
        let displayYn = false;
        const steps = vatModel.statusOfVatReporting.steps;
        const nowStep = steps[steps.length-1]?.name;
        if(steps.length && steps.length !== 0) {
            if(nowStep !== '부가세_신고완료' && nowStep !== '부가세_납부_안내') {
                displayYn = true;
            }
        }
        return displayYn
    }
    get preparationData() {
        return vatModel.preparationData;
    }
    get companyName() {
        return corpModel.company.companyName;
    }
    get vatData() {
        return vatModel.vatData;
    }
    highlight(idx, type) {
        let highLightYn = false;
        const vatData = vatModel.vatData[idx];
        if(idx === 0) {
            if(type === '납부세액') {
                if(!vatData.card?.refundTaxAmount) {
                    highLightYn = true;
                }
                if(vatData.reportProgressStatus === '예정신고O' && vatData.earlyRefoundYn === false) {
                    highLightYn = true;
                }
            } else if(type === '환급세액') {
                if(vatData.reportProgressStatus === '확정신고') {
                    highLightYn = true;
                }
                if(vatData.reportProgressStatus === '예정신고O' && vatData.earlyRefoundYn) {
                    highLightYn = true;
                }
                if(vatData.card?.amountOfTaxPaid === 0) {
                    highLightYn = true;
                }
            } else if(type === '예정고지액') {
                if(vatData.card?.estimatedAmountOfNotice?.amount) {
                    highLightYn = true;
                }
            }

        }
        return highLightYn;
    }
    displayRefundTaxAmount(idx) { //환급세액 표시 여부
        let displayYn = false;
        const vatData = vatModel.vatData[idx];
        if(vatData.card?.refundTaxAmount) {
            if(vatData.reportProgressStatus === '예정신고O' && vatData.earlyRefoundYn === false) {
                displayYn = false;
            } else {
                displayYn = true;
            }
        }
        return displayYn;
    }
    vatReportPeriodType(type) {
        switch(type) {
            case '_1기_예정':
                return '4월';
            case '_1기_확정':
                return '7월';
            case '_2기_예정':
                return '10월';
            case '_2기_확정':
                return '1월';
        }
    }
    fileDownload(downloadLink) {
        VatService.fileDownload(downloadLink);
    }
}

export default new VatVm();