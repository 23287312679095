export enum josaType {
    은는 = '은는',
    이가 = '이가',
    을를 = '을를',
    와과 = '와과'
}

function josa(_data: string, _type: josaType): string {
    const 받침없는한글 = '가갸거겨고교구규그기개걔게계과괘궈궤괴귀긔까꺄꺼껴꼬꾜꾸뀨끄끼깨꺠께꼐꽈꽤꿔꿰꾀뀌끠나냐너녀노뇨누뉴느니내냬네녜놔놰눠눼뇌뉘늬다댜더뎌도됴두듀드디대댸데뎨돠돼둬뒈되뒤듸따땨떠뗘또뚀뚜뜌뜨띠때떄떼뗴똬뙈뚸뛔뙤뛰띄라랴러려로료루류르리래럐레례롸뢔뤄뤠뢰뤼릐마먀머며모묘무뮤므미매먜메몌뫄뫠뭐뭬뫼뮈믜바뱌버벼보뵤부뷰브비배뱨베볘봐봬붜붸뵈뷔븨빠뺘뻐뼈뽀뾰뿌쀼쁘삐빼뺴뻬뼤뽜뽸뿨쀄뾔쀠쁴사샤서셔소쇼수슈스시새섀세셰솨쇄숴쉐쇠쉬싀싸쌰써쎠쏘쑈쑤쓔쓰씨쌔썌쎄쎼쏴쐐쒀쒜쐬쒸씌아야어여오요우유으이애얘에예와왜워웨외위의자쟈저져조죠주쥬즈지재쟤제졔좌좨줘줴죄쥐즤짜쨔쩌쪄쪼쬬쭈쮸쯔찌째쨰쩨쪠쫘쫴쭤쮀쬐쮜쯰차챠처쳐초쵸추츄츠치채챼체쳬촤쵀춰췌최취츼카캬커켜코쿄쿠큐크키캐컈케켸콰쾌쿼퀘쾨퀴킈타탸터텨토툐투튜트티태턔테톄톼퇘퉈퉤퇴튀틔파퍄퍼펴포표푸퓨프피패퍠페폐퐈퐤풔풰푀퓌픠하햐허혀호효후휴흐히해햬헤혜화홰훠훼회휘희2459';

    function _josa(type: josaType): string {
        const regExp = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
        let has한글 = false;
        let t = '';
        for(let i=_data.length-1; i>=0; i--) {
            t = _data.charAt(i);
            if(regExp.test(t)) {
                has한글 = true;
                break;
            }
        }

        if(has한글 === false) return type;

        const 받침여부 = (받침없는한글.indexOf(t) === -1);
        switch(type) {
            case josaType.은는 : return `${_data}${받침여부 ? '은' : '는'}`;
            case josaType.이가 : return `${_data}${받침여부 ? '이' : '가'}`;
            case josaType.을를 : return `${_data}${받침여부 ? '을' : '를'}`;
            case josaType.와과 : return `${_data}${받침여부 ? '과' : '와'}`;
            default : return type;
        }
    }

    return _josa(_type);
}

export default josa;