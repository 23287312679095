import agendaStepModel from '@model/Corp/Agenda/Step/agendaStepModel';
import type {SelectedAgenda, I본점의_주소_변경} from '@model/Corp/Agenda/Step/agendaStepModel.interface';

class HeadOfficeAddressChangeVm {
    selectedAgenda(index : number) {
        return agendaStepModel.selectedAgendas[index] as SelectedAgenda<I본점의_주소_변경>
    }
    selectedMetropolitanType(e : React.ChangeEvent<HTMLSelectElement>, index : number) {
        this.selectedAgenda(index).agendaDetail.selectedMetropolitanType = e.target.value;
        this.selectedAgenda(index).agendaDetail.selectedSigungu = "";
    }
    selectableSigungus(e : React.ChangeEvent<HTMLSelectElement>, index : number) {
        this.selectedAgenda(index).agendaDetail.selectedSigungu = e.target.value;
    }
    selectableSigungusList(agendaDetail : I본점의_주소_변경) {
        const selectableMetropolitanTypes = agendaDetail.selectableMetropolitanTypes;
        const selectedMetropolitanType = agendaDetail.selectedMetropolitanType;
        let list : string[] = [];
        selectableMetropolitanTypes.forEach((selectableMetropolitanType) => {
            if(selectableMetropolitanType.metropolitanType === selectedMetropolitanType) {
                list = selectableMetropolitanType.selectableSigungus;
            }
        })
        return list;
    }
}
export default new HeadOfficeAddressChangeVm();