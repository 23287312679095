import { observable, makeObservable } from 'mobx';
import type { FinalModelInterface, RegistrationAgencyType, StepsCompletedDate, Documents, MeetingSchedule } from './FinalModel.interface';

class FinalModel implements FinalModelInterface {
    constructor() {
        makeObservable(this, {
            agendaPeriod : observable,
            completed : observable,
            registrationAgencyType : observable,
            stepsCompletedDate : observable,
            documents : observable,
            meetingSchedule : observable,
            memo : observable
        })
    }
    agendaPeriod : string = "";
    completed : boolean = true;
    registrationAgencyType : RegistrationAgencyType = "NONE";
    memo : string = "";
    stepsCompletedDate : StepsCompletedDate = {
        등기대행_접수완료 : "",
        견적서_발송 : "",
        결제_완료 : "",
        등기소_접수 : "",
        셀프등기_선택 : "",
        등기완료 : "",
        고객_통지_동의 : "",
        스탠바이_데이터전송 : "",
        법률사무소_절차_진행 : "",
    };
    documents : Documents = {
        shareholderListFile : {
            issuedDate : ""
        },
        companyCopyFile : {
            issuedDate : ""
        },
        aoaFile : {
            issuedDate : ""
        },
        receiptFile : {
            downloadUrl : ""
        },
        estimateFile : {
            downloadUrl : ""
        },
        minutesFile : {
            downloadUrl : ""
        }
    };
    meetingSchedule : MeetingSchedule[] = [];
}

export default new FinalModel();