import { observable } from 'mobx'; 
import DashboardModel from '@model/Corp/Dashboard/DashboardModel';
import portalModel from '@model/portalModel';
import {runInAction} from 'mobx';
import AgendaLinkPopup from '@pages/Corp/Dashboard/AgendaLinkPopup/AgendaLinkPopup';

class AddressVm {

    state : {
        moreviewYn : boolean
    }

    constructor() {
        this.state = observable({
            moreviewYn: false,
        })
    }
    moreview() {
        this.state.moreviewYn = !this.state.moreviewYn;
    }
    get address() {
        return DashboardModel.dashboardData?.address;
    }
    agendaLinkModalHeadOffice() {
        runInAction(() => {
            portalModel.title = "본점 변경하기";
            portalModel.content = <AgendaLinkPopup agendaType="본점주소_변경"/>;
        })
    }
    agendaLinkModalBranchOffices() {
        runInAction(() => {
            portalModel.title = "지점 추가, 변경하기";
            portalModel.content = <AgendaLinkPopup agendaType="지점"/>;
        })
    }
}
export default new AddressVm();