import { useObserver } from 'mobx-react';
import vm from './TopWarningVm';
import st from '../AgendaDashboard.module.scss';
import Cst from './TopWarning.module.scss';

function TopWarning() {
    return useObserver(() => (
        <>
            { (vm.rNumbers.some(rNumber => rNumber.id !== '') === false || vm.regAgencyCancelInfo) &&
                <div className={st.horizontal}>
                    <div className={Cst.content}>
                        {vm.rNumbers.some(rNumber => rNumber.id !== '') === false && 
                            <div className={Cst.topWarning}>R번호가 맞는지 확인후 저장을 누르세요.</div>
                        }
                        {vm.regAgencyCancelInfo && 
                            <div className={Cst.topWarning}>{vm.regAgencyCancelInfo}</div>
                        }
                    </div>
                </div>
            }
        </>
    ));
}
export default TopWarning;