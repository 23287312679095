import InternetAssociateService from '@service/Corp/InternetAssociate/InternetAssociateService';
import InternetAssociateModel from '@model/Corp/InternetAssociate/InternetAssociateModel';
import {SelectedProduct, SelectedProductsDetail} from '@model/Corp/InternetAssociate/InternetAssociateModel.interface';
import { runInAction, observable, reaction, toJS } from "mobx";
import portalModel from '@model/portalModel';
import TermsModal from "./TermsModal/TermsModal";
import TelephoneTipModal from './TelephoneTipModal/TelephoneTipModal';
import React from 'react';
import formDataToJson from '@common/module/submit';
import { onlyNumber } from '@common/module/replaceNumber';

class Step2Vm {
    state : {
        errorCheckYn : boolean;
        toastMessageYn: boolean;
    }
    constructor() {
        this.state = observable({
            errorCheckYn: false,
            toastMessageYn: false
        })
    }
    load() {
        InternetAssociateService.loadStep2();
        InternetAssociateService.loadTerm();
    }
    showToastMessage() {
        this.state.toastMessageYn = true;
        const timer = setTimeout(() => {
            this.state.toastMessageYn = false;
        }, 3050);
        return () => clearTimeout(timer);
    }
    get selectedProducts() {
        return InternetAssociateModel.step2.selectedProducts
    }
    get products() {
        return InternetAssociateModel.step2.products
    }
    get selectedProductsDetail() {
        return InternetAssociateModel.step2.selectedProductsDetail;
    }
    setSelectedProducts(e:React.ChangeEvent<HTMLInputElement>) {
        const value = e.target.value as SelectedProduct;
        const index = this.selectedProducts.indexOf(value);
        runInAction(() => {
            if (index !== -1) {
                this.selectedProducts.splice(index, 1);
            } else {
                this.selectedProducts.push(value);
            }
        })
        
        
    }
    get consultInfo() {
        return InternetAssociateModel.step2.consultInfo
    }
    setConsultInfoName(e:React.ChangeEvent<HTMLInputElement>) {
        this.consultInfo.name = e.target.value;
    }
    setConsultInfoPhoneNumber(e:React.ChangeEvent<HTMLInputElement>) {
        this.consultInfo.phoneNumber = String(onlyNumber(e.target.value));
    }
    setConsultInfoEmail(e:React.ChangeEvent<HTMLInputElement>) {
        this.consultInfo.email = e.target.value;
    }
    setConsultInfoAddress(e:React.ChangeEvent<HTMLInputElement>) {
        this.consultInfo.address = e.target.value;
    }
    setConsultInfoContent(e:React.ChangeEvent<HTMLTextAreaElement>) {
        this.consultInfo.content = e.target.value;
    }
    get termInfos() {
        return InternetAssociateModel.step2.termInfos;
    }
    get agreeAll() {
        if(this.termInfos.length === 0) {
            return false;
        }
        let agreeAll = true;
        this.termInfos.forEach((item) => {
            if(item.agreeYn === false) {
                agreeAll = false;
            }
        })
        return agreeAll;
    }
    setAgreeAll(e : React.ChangeEvent<HTMLInputElement>) {
        runInAction(() => {
            this.termInfos.forEach((item) => {
                item.agreeYn = e.target.checked;
            })
        })
    }
    setAgreeYn(e : React.ChangeEvent<HTMLInputElement>, index : number) {
        this.termInfos[index].agreeYn = e.target.checked;
    }
    termsPopup(title : string, content : JSX.Element) {
        runInAction(() => {
            portalModel.title = title;
            portalModel.content = <TermsModal content={content}/>;
        })
    }
    get checkBoxError() {
        let error = false;
        this.termInfos.forEach((item) => {
            if(item.required && item.agreeYn === false) {
                error = true;
            }
        })
        return error;
    }
    internetDetail(e:React.ChangeEvent<HTMLInputElement>) {
        this.selectedProductsDetail.INTERNET = e.target.value;
    }
    tvDetail(e:React.ChangeEvent<HTMLInputElement>) {
        this.selectedProductsDetail.TV = e.target.value;
    }
    setQuantity(type:'minus'|'plus') {
        if(type === 'minus') {
            this.selectedProductsDetail.TELEPHONE.quantity -= 1;
        }
        if(type === 'plus') {
            this.selectedProductsDetail.TELEPHONE.quantity += 1;
        }
    }
    setTelephoneNumber(e:React.ChangeEvent<HTMLInputElement>) {
        const index = this.selectedProductsDetail.TELEPHONE_NUMBER.indexOf(e.target.value);
        if (index !== -1) {
            this.selectedProductsDetail.TELEPHONE_NUMBER.splice(index, 1);
        } else {
            this.selectedProductsDetail.TELEPHONE_NUMBER.push(e.target.value);
        }
        if(this.selectedProductsDetail.TELEPHONE_NUMBER.includes('TN2') && this.selectedProductsDetail.TELEPHONE.quantity < 2) {
            this.showToastMessage();
            this.selectedProductsDetail.TELEPHONE.quantity = 2;
        }
    }
    quantityMinusDisabled() {
        if(this.selectedProductsDetail.TELEPHONE_NUMBER.includes('TN2')) {
            return this.selectedProductsDetail.TELEPHONE.quantity < 3;
        }else{
            return this.selectedProductsDetail.TELEPHONE.quantity < 2;
        }
    }
    telephoneTipModal() {
        runInAction(() => {
            portalModel.title = '전화기 설치 시 꿀팁';
            portalModel.content = <TelephoneTipModal/>;
        })
    }
    reactionEstimate() {
        reaction(
            () => ({
                selectedProductsDetail : toJS(this.selectedProductsDetail),
                selectedProducts : toJS(this.selectedProducts)
            }),
            ({selectedProductsDetail , selectedProducts}) => {
                this.estimateData(selectedProductsDetail , selectedProducts);
            }
        )
    }
    productsParam(selectedProductsDetail : SelectedProductsDetail, selectedProducts : SelectedProduct[]) {
        let param : { 
            internet ?: SelectedProductsDetail['INTERNET'],
            telephone ?: SelectedProductsDetail['TELEPHONE'],
            telephoneNumber ?: SelectedProductsDetail['TELEPHONE_NUMBER'],
            tv ?: SelectedProductsDetail['TV']
        } = {};
        if(selectedProducts.includes("INTERNET")) {
            param.internet = selectedProductsDetail.INTERNET;
        }
        if(selectedProducts.includes("TELEPHONE")) {
            param.telephone = selectedProductsDetail.TELEPHONE;
            param.telephoneNumber = selectedProductsDetail.TELEPHONE_NUMBER;
        }
        if(selectedProducts.includes("TV")) {
            param.tv = selectedProductsDetail.TV;
        }
        return param;
    }
    estimateData(selectedProductsDetail : SelectedProductsDetail, selectedProducts : SelectedProduct[]) {
        const param = this.productsParam(selectedProductsDetail , selectedProducts);
        InternetAssociateService.calc(param);
    }
    selectedInternetName() {
        const id = this.selectedProductsDetail.INTERNET;
        const selectedName = this.products.INTERNET.find((internet) => {
            return internet.id === id;
        })?.option;
        let name = '미신청';
        if(this.selectedProducts.includes("INTERNET") && selectedName) {
            name = selectedName;
        }
        return name;
    }
    selectedTvName() {
        const id = this.selectedProductsDetail.TV;
        const selectedName = this.products.TV.find((tv) => {
            return tv.id === id;
        })?.option;
        let name = '미신청';
        if(this.selectedProducts.includes("TV") && selectedName) {
            name = selectedName;
        }
        return name;
    }
    selectedTelephoneName() {
        let name = '미신청';
        if(this.selectedProducts.includes("TELEPHONE")) {
            name = `인터넷(${this.selectedProductsDetail.TELEPHONE.quantity}대)`;
        }
        if(this.selectedProductsDetail.TELEPHONE_NUMBER.includes('TN1')) {
            name += ', 지역번호'
        }
        if(this.selectedProductsDetail.TELEPHONE_NUMBER.includes('TN2')) {
            name += ', 대표번호'
        }
        return name;
    }
    get estimate() {
        return InternetAssociateModel.step2.estimate;
    }
    submit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if(formDataToJson(e.target).error || this.checkBoxError) {
            this.state.errorCheckYn = true;
            if(this.state.errorCheckYn) {
                window.scrollTo(0, document.body.scrollHeight);
            }
            return
        } else {
            const products = this.productsParam(this.selectedProductsDetail , this.selectedProducts);
            const consultInfo = this.consultInfo;
            const terms = this.termInfos.filter(termInfo => termInfo.agreeYn).map(termInfoFilter => termInfoFilter.id);
            InternetAssociateService.step2Submit({products, consultInfo, terms});
        }
    }
}
export default new Step2Vm();