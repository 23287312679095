import { useObserver } from 'mobx-react';
import type { SectionType, I주주동의_주주총회 } from '@src/model/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalModel.interface';
import vm from './NoticeOfDraftOmitShareholdersVm';
import st from './NoticeOfDraftOmitShareholders.module.scss';
import bst from '@pages/Corp/Agenda/Step/FinalCheck/BeforeFinal/BeforeFinal.module.scss';
import clsx from 'clsx';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import QuestionTooltip from '@standby/common-ui/components/molecules/QuestionTooltip/QuestionTooltip';
import Table from '@standby/common-ui/components/atoms/Table/Table';
import ToolTip from '@standby/common-ui/components/molecules/ToolTip/ToolTip';
import DownloadBtn from '@standby/common-ui/components/atoms/Button/DownloadBtn/DownloadBtn';
import { addCommas } from '@common/module/replaceNumber';

interface NoticeOfDraftOmitShareholdersProps {
    index: number;
    type: SectionType;
    info: I주주동의_주주총회;
    agendaProgressId: string;
}

function NoticeOfDraftOmitShareholders(props: NoticeOfDraftOmitShareholdersProps) {
    return useObserver(() => (
        <>
            <div className={bst.row}>
                <div className={clsx(bst.title, bst.section2)}>{props.index+1}. 주주동의 <span className={bst.light}>(주주총회)</span></div>
                <div className={bst.content}>
                    <div className={bst.contentTitleBox}>소집통지 생략을 위해 주주 동의를 받으세요. <span className={bst.light}>(기간단축 동의)</span></div>
                    <QuestionTooltip title="주주총회 소집통지 생략 안내" style={{width:'460px'}}>
                        <div className={bst.tooltipContent}>
                            <div className={bst.qna}>
                                <div className={bst.qnaTitle}>자본금 10억 미만이라면, 모든 주주가 동의시 소집통지를 생략할 수 있어요.</div>
                                <div className={bst.qnaContent}>
                                    <ul>
                                        <li>원칙상 정관에 따라 소집통지를 보내고, 10~14일을 기다린 후 주주총회를 열 수 있어요. 하지만 모든 주주가 생략하면 바로 오늘이라도 주주총회를 할 수 있어요.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className={bst.qna}>
                                <div className={bst.qnaTitle}>주주들에게 주주총회 소집통지 생략동의를 클릭 몇 번으로 받아보세요.</div>
                                <div className={bst.qnaContent}>
                                    <ul>
                                        <li>스탠바이에서는 클릭 몇 번으로 주주들에게 주주총회 소집통지 생략동의를 받을 수 있어요. 주주들에게 동의서에 직접 도장을 받지 않아도 되니 매우 편리해요!</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </QuestionTooltip>
                    <Table className={clsx(bst.table, st.table)}>
                        <colgroup>
                            <col width="190" />
                            <col width="89" />
                            <col width="84" />
                            <col width="110" />
                            <col width="152" />
                            <col width="104" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>주주명</th>
                                <th>동의여부</th>
                                <th>동의일자</th>
                                <th style={{textAlign:'center'}} className={clsx(bst.download, props.info?.completed ? bst.active : bst.disabled)}>
                                    <span>다운로드</span>
                                    {props.info?.completed && 
                                        <button type="button" className={bst.allDownloadBtn} onClick={() => vm.allDownload(props.agendaProgressId, props.type)}>모두 다운로드</button>
                                    }
                                </th>
                                <th style={{textAlign:'left'}}>주식종류</th>
                                <th>주식수</th>
                                <th>지분율</th>
                            </tr>
                        </thead>
                        {props.info?.shareholders?.map((shareholder, idx) => {
                            if((idx < 6 || (idx > 5 && vm.state.moreViewYn))) {
                                return (
                                    <tbody key={idx}>
                                        {shareholder.stocks.map((stock, idx2) => {
                                            return (
                                                <tr key={idx2}>
                                                    {idx2 === 0 &&
                                                    <>
                                                        <td rowSpan={shareholder.stocks.length}><span>{idx+1}</span>{shareholder.name}</td>
                                                        <td rowSpan={shareholder.stocks.length}>
                                                            <div className={vm.status(shareholder.status)?._className}>
                                                                {vm.status(shareholder.status)?.text}
                                                                {shareholder.status === "발송실패" && 
                                                                    <ToolTip className={bst.tooltip}>
                                                                        일시적 오류가 발생하여 발송에 실패했어요. 이메일, 휴대번호가 정확한지 확인해보고, 재발송을 해주세요. 만약 재발송에도 실패한다면 오른쪽 아래 채팅버튼을 눌러 문의를 남겨주세요.
                                                                    </ToolTip>
                                                                }
                                                                {shareholder.status === "발송실패_알림톡" && 
                                                                    <ToolTip className={bst.tooltip}>
                                                                        일시적으로 오류가 발생하여 알림톡 발송에 실패했어요. (이메일은 발송성공) 휴대번호가 정확한지 확인해보고, 재발송을 해주세요. 만약 재발송에도 실패한다면 오른쪽 아래 채팅버튼을 눌러 문의를 남겨주세요.
                                                                    </ToolTip>
                                                                }
                                                                {shareholder.status === "발송실패_메일" && 
                                                                    <ToolTip className={bst.tooltip}>
                                                                        일시적 오류가 발생하여 메일 발송에 실패했어요. (알림톡은 발송성공) 이메일 주소가 정확한지 확인해보고, 재발송을 해주세요. 만약 재발송에도 실패한다면 오른쪽 아래 채팅버튼을 눌러 문의를 남겨주세요.
                                                                    </ToolTip>
                                                                }
                                                            </div>
                                                        </td>
                                                        <td rowSpan={shareholder.stocks.length}>{shareholder.completedDate}</td>
                                                        <td rowSpan={shareholder.stocks.length}>
                                                            <DownloadBtn disabled={!shareholder.downloadUrl} onClick={() => vm.download(shareholder.downloadUrl)}>동의서 다운로드</DownloadBtn>
                                                        </td>
                                                    </>
                                                    }
                                                    <td className={bst.stockName}>{stock.name}</td>
                                                    <td className={bst.stockNumber}>{addCommas(stock.number)} 주</td>
                                                    <td className={bst.stockRate}>{stock.rate} %</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                )
                            }                        
                        })}
                    </Table>
                    {props.info?.shareholders?.length > 6 && 
                        <button type="button" className={bst.moreViewBtn} onClick={() => vm.moreView()}><span className={vm.state.moreViewYn ? bst.open : ""}>{vm.state.moreViewYn ? '접기' : '더보기'}</span></button>
                    }
                    <div className={bst.btnArea}>
                        <SquareBtn className={bst.btn} onClick={() => vm.openModal(props.agendaProgressId)}>소집통지 생략 동의 요청</SquareBtn>
                    </div>
                </div>
            </div>
        </>
    ));
}
export default NoticeOfDraftOmitShareholders;